import React, { CSSProperties } from 'react';
import Select from 'react-select';
import EnumCellProps from './props';
import style from './style.module.scss';

const selectStyles = (item: any, column: any, customStyle: any) => {
    return {
        container: (provided: any) => {
            return {
                ...provided,
                borderWidth: 0,
                width: '100%',
                minHeight: 0,
            };
        },
        control: (provided: any) => {
            return {
                ...provided,
                borderWidth: 0,
                width: '100%',
                minHeight: 0,
            };
        },
        indicatorSeparator: (provided: any) => {
            return {
                ...provided,
                display: 'none',
            };
        },
        singleValue: (provided: any, state: any) => {
            let customStyleValue = customStyle;
            if (typeof customStyle === 'function') {
                customStyleValue = customStyle({
                    column,
                    item,
                    value: state.data.value,
                });
            }
            return {
                ...provided,
                ...customStyleValue,
            };
        },
        dropdownIndicator: (provided: any) => {
            return { ...provided, padding: 0 };
        },
        option: (provided: any, state: any) => {
            let customStyleValue = customStyle;
            if (typeof customStyle === 'function') {
                customStyleValue = customStyle({
                    column,
                    item,
                    value: state.data.value,
                });
            }

            return {
                ...provided,
                ...customStyleValue,
            };
        },
    };
};
const CellEnum = ({
    value,
    column,
    item,
    isUpdateProcessing,
    updateInPlace,
    updateValue,
}: EnumCellProps) => {
    let representation = value;

    if (value && column.values) {
        const item = column.values.find((item: any) => item.value === value);
        if (item) {
            representation = item.label;
        }
    }

    if (value && Array.isArray(value)) {
        if (value.length === 0) {
            representation = '';
        } else if (value.length === 1) {
            representation = value[0];
        } else {
            representation = 'Multiple';
        }
    }

    if (!representation) {
        representation = 'NONE';
    }

    let customStyle: CSSProperties | undefined = (column.style as any) || {};

    if ((value && Array.isArray(value)) || !updateInPlace) {
        if (typeof column.style === 'function') {
            customStyle = column.style({
                column,
                item,
                value,
            });
        }
        return (
            <div
                // className={enumValues.includes(value) ? style.cell : style.cellError}
                className={style.enumCell}
                style={customStyle}
            >
                {value && <span>{representation}</span>}
                {!value && <span>&nbsp;</span>}
            </div>
        );
    }

    const _handleChange = ({ value }: any) => {
        updateValue(value);
    };

    return (
        <div
            // className={enumValues.includes(value) ? style.cell : style.cellError}
            className={style.enumCell}
            onClick={(e) => e.stopPropagation()}
        >
            <Select
                value={column.values.find((item) => item.value === value)}
                options={column.values}
                onChange={_handleChange}
                styles={selectStyles(item, column, customStyle)}
                isLoading={isUpdateProcessing}
            />
        </div>
    );
};

export default React.memo(CellEnum);
