import React, { CSSProperties } from 'react';
import PropsCellWhatsapp from './props';
import style from './style.module.scss';

const getNumericsFromString = (str: string) => {
    if (typeof str !== 'string') {
        return '';
    }
    return str.replace(/[^0-9]/gim, '');
};

const CellWhatsapp = ({ column, value, item }: PropsCellWhatsapp) => {
    let calculatedStyle: CSSProperties | undefined;

    if (column.style && typeof column.style === 'function') {
        calculatedStyle = column.style!({ column, item, value });
    } else {
        calculatedStyle = column.style;
    }

    return (
        <div className={style.phoneCell} onClick={(e) => e.stopPropagation()}>
            {value && (
                <a
                    className={style.linkCell}
                    href={`https://wa.me/${getNumericsFromString(value)}`}
                    target="_blank"
                    style={calculatedStyle}
                    rel="noopener noreferrer"
                >
                    {value}
                </a>
            )}
        </div>
    );
};

export default React.memo(CellWhatsapp);
