import React from 'react';
import { Link } from 'react-router-dom';
import logo from '../../../assets/images/logo-image.png';
import style from './style.module.scss';

const LinksColumn = () => {
    return (
        <div className={style.container}>
            <img className={style.logoImage} src={logo} alt="" />
            <div className={style.linksContainer}>
                <Link to="/terms">Terms</Link>
                <div className={style.bullet}>•</div>
                <Link to="/wiki">Wiki</Link>
                <div className={style.bullet}>•</div>
                <Link to="/contacts">Contact</Link>
            </div>
        </div>
    );
};

export default LinksColumn;
