import { SerializedSelection } from '../../../utils/Selection';
import {
    DataSourcePath,
    DialogData,
    HandlerValue,
    ValueOrHandler,
} from '../context/types';

export default interface PropsInnerItems {
    id: string;
    isHidden?: ValueOrHandler<boolean>;
    isSelectable?: boolean;
    dataSource?: DataSourcePath<any[]>;
    items?: any[];
    onChange?: (newItems: any[]) => void;
    columns: any[];
    onClickItem?: HandlerValue<(item: any) => void>;
    selection?: SerializedSelection;
    onChangeSelection?: HandlerValue<
        (newSelection: SerializedSelection) => void
    >;
    selectionDataSource?: DataSourcePath<SerializedSelection>;
    rowClassName?: HandlerValue<() => string>;
    rowStyle?: any | Function;
    onBeforeLoadReferences?: string | Function;
    label: string;
    dialogData: DialogData;
}

export const defaultProps: Partial<PropsInnerItems> = {};
