import lodash from 'lodash';
import {
    AppContext,
    FormFunctionGetHandlers,
} from '../../../components/form/context/types';
import ViewTypes from '../../../components/form/ViewTypes';
import apiClient from '../../../requests/api';
import { saveTextAsFile } from '../../../utils';
import Selection from '../../../utils/Selection';
import defaultHandlers from '../defaultHandlers';

const handlers: FormFunctionGetHandlers = function (this: AppContext) {
    const onBeforeOpen = async () => {
        const { siteId, endpointId } = this.form.params;

        const endpointMetaInfo = this.getEndpointMetaInfo(siteId, endpointId);

        const fieldsViews: any[] = [];
        const fieldsValues: any = {};

        const { properties } = endpointMetaInfo;

        Object.keys(properties).forEach((propertyId) => {
            const propertyData = properties[propertyId];
            const { name, isHidden, upload } = propertyData;

            if (isHidden || upload) {
                return;
            }

            fieldsValues[propertyId] = true;
            fieldsViews.push({
                id: `field_${propertyId}`,
                type: ViewTypes.BooleanInput,
                label: name,
                sourceFormat: 'boolean',
                dataSource: `fieldsValues.${propertyId}`,
            });
        });

        this.form.fieldsValues = fieldsValues;
        this.form.views.fieldsGroup.items = fieldsViews;
    };

    const onApply = async () => {
        const selectedColumns = lodash
            .toPairs(this.form.fieldsValues)
            .filter(([_, isSelected]) => isSelected)
            .map(([columnId]) => columnId);

        const { siteId, endpointId, filter, searchPhrase } = this.form.params;
        const formSelection = this.form.params.selection;
        const selection = Selection.unserealizeFromObject(formSelection);

        this.form.isLoading = true;

        const includeIds = selection.isInfinite()
            ? []
            : selection.getSelectedItems();
        const excludeIds = selection.isInfinite()
            ? selection.getExcludedItems()
            : [];

        let queryFilter = [];

        if (includeIds.length > 0 || excludeIds.length > 0) {
            queryFilter.push({
                field: '_id',
                include: includeIds,
                exclude: excludeIds,
            });
        }

        if (filter.length > 0) {
            queryFilter.length > 0 && queryFilter.push('AND');
            queryFilter = [...queryFilter, ...filter];
        }

        let serverCSV = await apiClient.getCsvFromTable(
            siteId,
            endpointId,
            [
                {
                    field: '_id',
                    direction: 'asc',
                },
            ],
            queryFilter,
            0,
            9000,
            searchPhrase,
            `scroll=y&_source=_id,${selectedColumns.join(',')}`
        );

        serverCSV = serverCSV.replace('_id', 'METHOD,ID');
        const splitted = serverCSV.split('\n');

        for (let i = 0; i < splitted.length; i++) {
            if (i === 0 || !splitted[i].trim()) {
                continue;
            }

            splitted[i] = `update,${splitted[i]}`;
        }

        const fileName = 'export.csv';
        saveTextAsFile(fileName, 'csv/plain', splitted.join('\n'));
        this.form.notify({ text: 'Export success!', lifetimeMs: 2000 });
        (this.form.handlers as any).onClose();
    };

    return {
        ...defaultHandlers.call(this),
        onBeforeOpen,
        onApply,
    };
};

export default handlers;
