import React, { useEffect, useState } from 'react';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import {
    useComponentWillMount,
    useEffectWithOldValues,
    useUnmountedFlag,
} from '../../utils';
import ItemForm from '../item-form';
import ListForm from '../list-form';
import AutoCompleteInput from './auto-complete-input';
import BooleanInput from './boolean-input';
import Button from './button';
import CodeEditor from './code-editor';
import ColorInput from './color-input';
import { ReactAppContext, useAppContextCreator } from './context';
import { AppContext, View as IView } from './context/types';
import Counter from './counter';
import DataTable from './data-table';
import DateInput from './date-input';
import EnumInput from './enum-input';
import FormHeader from './form-header';
import HStack from './horizontal-group';
import HtmlInput from './html-input';
import InnerItems from './inner-items';
import IntervalInput from './interval-input';
import List from './list';
import MediaField from './media-field';
import MemoryTable from './memory-table';
import NumberInput from './number-input';
import ProcessingStub from './processing-stub';
import PropsForm, { defaultProps } from './props';
import ReferenceInput from './reference-input-view';
import SearchInput from './search-input';
import style from './style.module.scss';
import TabPage from './tab-page';
import TabsGroup from './tabs-group';
import Text from './text';
import TextInput from './text-input';
import UploadInput from './upload-input';
import UrlInputWithParams from './url-input-with-params';
import VStack from './vertical-group';
import ViewTypes from './ViewTypes';
const ViewsComponents: any = {
    BooleanInput,
    Counter,
    DataTable,
    DateInput,
    EnumInput,
    HtmlInput,
    MediaField,
    NumberInput,
    ProcessingStub,
    ReferenceInput,
    SearchInput,
    TabsGroup,
    TabPage,
    Text,
    TextInput,
    VStack,
    HStack,
    Button,
    MemoryTable,
    UploadInput,
    IntervalInput,
    List,
    UrlInputWithParams,
    AutoCompleteInput,
    ColorInput,
    ItemForm,
    ListForm,
    InnerItems,
    CodeEditor,
};

export function renderContent(
    node: IView,
    context: AppContext,
    key: any
): JSX.Element {
    let ContentComponent: React.ComponentType<any> =
        ViewsComponents[node.type as string]!;
    const props: any = {
        ...node,
        id: node.id,
        context,
        key,
    };

    switch (node.type) {
        case ViewTypes.CustomComponent:
            ContentComponent = (node as any).component;
            break;
        case ViewTypes.Form:
            props.parentContext = props.context;
            delete props.context;
            break;
    }

    if (!ContentComponent) {
        console.error(`Wrong view type: ${node.type}`, node);
        throw new Error(`Wrong view type : ${node.type}`);
    }

    return <ContentComponent {...props} />;
}

export const ViewItem = React.memo(({ node }: { node: IView }) => {
    let ContentComponent: React.ComponentType<any> =
        ViewsComponents[node.type as string]!;
    const props: any = {
        ...node,
        id: node.id,
    };

    switch (node.type) {
        case ViewTypes.CustomComponent:
            ContentComponent = (node as any).component;
            break;
        case ViewTypes.Form:
            props.parentContext = props.context;
            delete props.context;
            break;
    }

    if (!ContentComponent) {
        console.error(`Wrong view type: ${node.type}`, node);
        throw new Error(`Wrong view type : ${node.type}`);
    }

    return <ContentComponent {...props} />;
});

const Form = (props: PropsForm & RouteComponentProps<any>) => {
    const { executeIfNotUnmounted } = useUnmountedFlag();

    const [isOpened, setIsOpened] = useState(false);
    const [isMounted, setIsMounted] = useState(false);

    useComponentWillMount(() => {
        setIsMounted(true);
    });

    const callbacks = useAppContextCreator(props);
    const { context } = callbacks;

    const { isLoading, id, progressText, commands } = context.form;

    useEffect(() => {
        if (!isMounted) {
            return;
        }

        executeIfNotUnmounted(async () => {
            const { onBeforeOpen } = context.form.handlers ?? {};
            onBeforeOpen && (await onBeforeOpen.call(context));

            executeIfNotUnmounted(() => {
                setIsOpened(true);
            });
        });
    }, [isMounted, executeIfNotUnmounted, context]);

    useEffect(() => {
        if (!isOpened) {
            return;
        }

        const { onAfterOpen } = context.form.handlers ?? {};

        executeIfNotUnmounted(() => {
            onAfterOpen && onAfterOpen.call(context);
        });

        return () => {
            const { onBeforeClose } = context.form.handlers ?? {};
            onBeforeClose && onBeforeClose.call(context);
        };
    }, [executeIfNotUnmounted, context, isOpened]);

    useEffectWithOldValues(
        (oldValues) => {
            if (!oldValues) {
                return;
            }

            const [prevParams] = oldValues;
            const { onChangeParams } = context.form.handlers ?? {};
            onChangeParams &&
                onChangeParams.call(context, props.params, prevParams);
        },
        [props, context]
    );

    if (isLoading || !isMounted || !isOpened) {
        return (
            <div className={style.form}>
                <ProcessingStub progressText={progressText} />
            </div>
        );
    }

    return (
        <ReactAppContext.Provider value={callbacks}>
            <div className={style.form} data-form-id={id} key={id}>
                {commands && (commands?.length ?? 0) > 0 && <FormHeader />}
                <div
                    className={props.contentClass ?? defaultProps.contentClass}
                >
                    <ViewItem node={context.form.rootView} />
                </div>
            </div>
        </ReactAppContext.Provider>
    );
};

ViewsComponents.Form = Form;

export default withRouter(Form);
