import React from 'react';
import mapMarkerIcon from '../../../../../../../../core/assets/icons/map-marker.svg';
import PropsMarker from './props';
import style from './style.module.scss';

const Marker = (props: PropsMarker) => {
    const { name, address, description, isActive } = props;
    if (!isActive) {
        return (
            <div className={style.marker}>
                <img src={mapMarkerIcon} alt="" />
            </div>
        );
    }

    return (
        <div className={style.markerActive}>
            <div className={style.bubble}>
                <div className={style.content}>
                    <div>{name}</div>
                    <div>{address}</div>
                    <div>{description}</div>
                    <i className="ion ion-ios-close" />
                </div>
            </div>
        </div>
    );
};

export default React.memo(Marker);
