import ViewTypes from '../../../../core/components/form/ViewTypes';
import handlers from './handlers';

export default {
    title: 'Songvote Dashboard',
    handlers,
    fields: {
        playedGamesNumber: '',
        numberOfPlayers: '',
        numberOfActiveUsers: '',
        topTeams: [],
        topTeamsTableSort: [{ columnId: 'objects', direction: 'desc' }],
        topSongs: [],
        topUsersInTeamTableSort: [{ columnId: 'objects', direction: 'desc' }],
        startDate: new Date(new Date().getFullYear(), new Date().getMonth(), 1),
        endDate: new Date().toISOString(),
        topUsersInTeamTitle: 'Top songs',
    },
    content: {
        id: 'rootGroup',
        type: ViewTypes.VStack,
        items: [
            {
                id: 'filterGroup',
                type: ViewTypes.HStack,
                style: { marginBottom: 30 },
                items: [
                    {
                        id: 'endDate',
                        type: 'DateInput',
                        label: 'End date',
                        style: { width: 350, marginLeft: 10 },
                        showClearButton: true,
                        dataSource: 'endDate',
                        onChangeValue: 'onChangeEndDate',
                    },
                    {
                        id: 'roundInput',
                        type: ViewTypes.ReferenceInput,
                        siteId: 'songvote',
                        endpointId: 'round',
                        label: 'Round',
                        dataSource: 'roundId',
                        representationDataSource: 'name',
                        onChangeValue: 'onChangeRound',
                    },
                ],
            },
            {
                id: 'countersGroup',
                type: ViewTypes.HStack,
                style: { marginBottom: 30 },
                items: [
                    {
                        id: 'numberOfVotes',
                        type: 'Counter',
                        label: 'Number Of Votes',
                        style: { marginLeft: 10 },
                        dataSource: 'numberOfVotes',
                    },
                ],
            },
            {
                id: 'tablesGroup',
                type: ViewTypes.VStack,
                items: [
                    {
                        id: 'topUsersInTeamTitle',
                        type: ViewTypes.Text,
                        style: {
                            fontSize: 30,
                            fontWeight: 'bold',
                            marginTop: 30,
                            direction: 'ltr',
                            textAlign: 'right',
                        },
                        dataSource: 'topUsersInTeamTitle',
                    },
                    {
                        id: 'topSongsTable',
                        type: ViewTypes.MemoryTable,
                        isSelectable: false,
                        dataSource: 'topSongs',
                        // sortDataSource: 'topSongsTableSort',
                        pageDataSource: 'url.queryParams.topSongsPage.replace',
                        columns: [
                            {
                                id: 'songId',
                                title: 'Song',
                                type: 'reference',
                                siteId: 'songvote',
                                endpointId: 'song',
                                fieldName: 'songId',
                                representationDataSource: 'title',
                            },
                            {
                                id: 'value',
                                title: 'value',
                                type: 'number',
                                fieldName: 'value',
                            },
                        ],
                    },
                ],
            },
        ],
    },
};
