import { CustomSiteConfig } from '../../../core/config-builder/types';
import item from './item';
import itempreset from './itempreset';
import list from './list';
import preset from './preset';
const wiki: CustomSiteConfig = {
    title: 'רשימת ציוד',
    dashboard: { commands: [] },
    endpoints: {
        preset,
        list,
        item,
        itempreset,
    },
};

export default wiki;
