import React, { useRef } from 'react';
import ContextMenu from '../../context-menu';
import PropsTableBody from './props';
import Row from './row';
import style from './style.module.scss';

const Body = (props: PropsTableBody) => {
    const {
        idField,
        selection,
        tableId,
        isLoading,
        isSelectable,
        items,
        columns,
        pageReferenceValues,
        contextMenu,
        rowClassName,
        rowStyle,
        onUpdateItemField,
        onSetQuickEditCell,
        quickEditCell,
        page,
        pageSize,
        onChangeSelection,
    } = props;

    const refClickTimeout = useRef<NodeJS.Timeout | null>(null);

    const handleClickRow = (item: any) => (e: any) => {
        if (refClickTimeout.current) {
            clearTimeout(refClickTimeout.current);
            refClickTimeout.current = null;
            return;
        }

        if (e.altKey) {
            return;
        }

        refClickTimeout.current = setTimeout(() => {
            e.stopPropagation();
            refClickTimeout.current = null;

            if (props.onClickRow) {
                props.onClickRow(item);
            }
        }, 300);
    };

    return (
        <tbody className={style.body}>
            {!isLoading &&
                items.map((item, index) => (
                    <Row
                        tableId={tableId}
                        idField={idField}
                        key={index}
                        isSelectable={isSelectable}
                        isSelected={selection.isItemSelected(item.id)}
                        item={item}
                        columns={columns}
                        pageReferenceValues={pageReferenceValues}
                        onChangeSelection={onChangeSelection}
                        onClick={handleClickRow(item)}
                        contextMenu={contextMenu}
                        className={rowClassName}
                        style={rowStyle}
                        onUpdateItemField={onUpdateItemField}
                        index={index}
                        onSetQuickEditCell={onSetQuickEditCell}
                        quickEditCell={quickEditCell}
                        page={page}
                        pageSize={pageSize}
                    />
                ))}
            {contextMenu && (
                <ContextMenu tableId={tableId} contextMenu={contextMenu} />
            )}
        </tbody>
    );
};

export default React.memo(Body);
