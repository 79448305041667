import React from 'react';
import { getValue } from '../../../utils';
import NativeList from '../../native/list';
import { useAppContextObserver } from '../context';
import PropsList from './props';

const List = (props: PropsList) => {
    const { dataSource, onChange, label, placeholder } = props;
    const [{ isHidden, isDisabled, displayError, value }, getContext] =
        useAppContextObserver((context) => {
            let value: string[] = props.value ?? [];
            if (dataSource) {
                value = context.form.getDataSourceValue(dataSource)!;
            }

            return {
                isHidden: getValue(props.isHidden, context),
                isDisabled: getValue(props.isDisabled, context),
                displayError: getValue(props.displayError, context),
                value,
            };
        });

    if (isHidden) {
        return null;
    }

    const onChangeValue = ({ value }: any) => {
        if (dataSource) {
            getContext().form.setDataSourceValue(dataSource, value);
        }

        if (!onChange) {
            return;
        }

        if (typeof onChange === 'string') {
            (getContext().form as any).handlers[onChange](value);
            return;
        }

        onChange.call(getContext(), value);
    };

    return (
        <NativeList
            label={label}
            isDisabled={isDisabled}
            placeholder={placeholder}
            value={value ?? []}
            onChange={onChangeValue}
            displayError={displayError}
        />
    );
};

export default React.memo(List);
