import React, { CSSProperties } from 'react';
import PropsCellPhone from './props';
import style from './style.module.scss';

const getNumericsFromString = (str: string) => {
    if (typeof str !== 'string') {
        return '';
    }
    return str.replace(/[^0-9]/gim, '');
};

const CellPhone = ({ value, column, item }: PropsCellPhone) => {
    let calculatedStyle: CSSProperties | undefined;

    if (column.style && typeof column.style === 'function') {
        calculatedStyle = column.style!({ column, item, value });
    } else {
        calculatedStyle = column.style;
    }

    return (
        <div className={style.phoneCell} onClick={(e) => e.stopPropagation()}>
            {value && (
                <a
                    className={style.linkCell}
                    href={`tel:${getNumericsFromString(value)}`}
                    style={calculatedStyle}
                >
                    {value}
                </a>
            )}
        </div>
    );
};

export default React.memo(CellPhone);
