import { useEffect, useMemo, useState } from 'react';
import PropsPhotoGalleryView from './props';

const getProgressText = (
    isProcessing: boolean,
    saveProgressState: string,
    saveProgressStep: number,
    saveProgressTotal: number
): string => {
    if (!isProcessing) {
        return '';
    }

    switch (saveProgressState) {
        case 'uploadItems':
            return `Uploading photos ${saveProgressStep}/${saveProgressTotal}...`;

        case 'deleteOldItems':
            return `Deleting old photos ${saveProgressStep}/${saveProgressTotal}...`;

        case 'saveOrder':
            return 'Saving photos order...';

        default:
            throw new Error(`not implemented ${saveProgressState}`);
    }
};

const usePhotoGalleryViewState = (props: PropsPhotoGalleryView) => {
    const {
        isProcessing,
        saveProgressState,
        saveProgressStep,
        saveProgressPercent,
        saveProgressItemId,
        saveProgressTotal,
        apiServerAddress,
        errors,
    } = props;

    const [isLightboxOpen, setIsLightboxOpen] = useState(false);
    const [isInEditMode, setIsInEditMode] = useState(() => props.isInEditMode);
    const [currentItem, setCurrentItem] = useState(0);
    const [processText, setProcessText] = useState(() =>
        getProgressText(
            isProcessing,
            saveProgressState,
            saveProgressStep,
            saveProgressTotal
        )
    );

    useEffect(() => {
        setProcessText(
            getProgressText(
                isProcessing,
                saveProgressState,
                saveProgressStep,
                saveProgressTotal
            )
        );
        if (errors.length === 0) {
            setIsInEditMode(false);
        }
    }, [
        isProcessing,
        errors.length,
        saveProgressState,
        saveProgressStep,
        saveProgressPercent,
        saveProgressTotal,
    ]);

    const _handleSortEnd = ({ oldIndex, newIndex }: any) => {
        const { length } = props.items;
        // hack rtl support
        props.moveItem(length - 1 - oldIndex, length - 1 - newIndex);
    };

    const _handleToggleEditMode = () => {
        setIsInEditMode((prev) => !prev);
    };

    const _handleSelectItem = (itemId: string) => {
        const index = props.items.findIndex((item) => item.id === itemId);
        setCurrentItem(index);
        setIsLightboxOpen(true);
    };

    const _handleCloseLightbox = () => {
        setCurrentItem(0);
        setIsLightboxOpen(false);
    };

    const _handleGotoPrevious = () => {
        setCurrentItem((prev) => prev - 1);
    };

    const _handleGoToNext = () => {
        setCurrentItem((prev) => prev + 1);
    };

    const _handleGoToImage = (index: number) => {
        setCurrentItem(index);
    };

    const _handleSave = () => {
        props.save();
    };

    const items = useMemo(() => {
        if (isProcessing) {
            return props.items;
        }

        return props.items.map((item: any) => {
            const itemId = item.id;
            const uploadError = errors.find(
                (error) =>
                    error.state === 'uploadItems' && error.itemId === itemId
            );
            if (uploadError) {
                return {
                    ...item,
                    isUploadError: true,
                };
            }
            if (itemId !== saveProgressItemId) {
                return item;
            }
            return {
                ...item,
                isUploading: true,
                progress: saveProgressPercent,
            };
        });
    }, [props.items, isProcessing]);

    const imageLinks = useMemo(() => {
        return items.map((item) => {
            return {
                src: item.URL.startsWith('/')
                    ? apiServerAddress + item.URL
                    : item.URL,
            };
        });
    }, [items]);

    return {
        isLightboxOpen,
        isInEditMode,
        currentItem,
        processText,
        imageLinks,
        _handleSortEnd,
        _handleToggleEditMode,
        _handleSelectItem,
        _handleCloseLightbox,
        _handleGotoPrevious,
        _handleGoToNext,
        _handleGoToImage,
        _handleSave,
    };
};

export default usePhotoGalleryViewState;
