import ViewTypes from '../../../../components/form/ViewTypes';
import handlers from './handlers';

const form = {
    id: 'ledgerForm',
    type: 'Form',
    fields: {
        endpointId: '',
        selection: 'none',
    },
    handlers,
    content: {
        id: 'rootGroup',
        type: ViewTypes.VStack,
        items: [
            {
                id: `commandPanel`,
                type: ViewTypes.HStack,
                items: [
                    {
                        id: `createButton`,
                        type: ViewTypes.Button,
                        text: 'Create',
                        onClick: 'onCreateNewItem',
                    },
                    {
                        id: `searchInput`,
                        type: ViewTypes.SearchInput,
                        dataSource: 'url.queryParams.search.replace',
                    },
                    {
                        id: `deleteButton`,
                        type: ViewTypes.Button,
                        text: 'Delete items',
                        onClick: 'onOpenDeleteItemsDialog',
                        role: 'notActive',
                        style: { marginRight: 10 },
                    },
                ],
            },
            {
                id: 'table',
                type: ViewTypes.DataTable,
                endpointId: 'product',
                pageSize: 15,
                sortDataSource: 'url.queryParams.sort.replace',
                filterDataSource: 'url.queryParams.filter.replace',
                searchDataSource: 'url.queryParams.search.replace',
                selectionDataSource: 'selection',
                pageDataSource: 'url.queryParams.page.replace',
                isSelectable: true,
                onClickItem: 'onClickTableItem',

                columns: [
                    {
                        id: 'productStatus',
                        fieldName: 'productStatus',
                        title: 'Status',
                        type: 'enum',
                        values: [
                            {
                                value: 'enabled',
                                label: 'Enabled',
                            },
                            {
                                value: 'disabled',
                                label: 'Disabled',
                            },
                        ],
                    },
                    {
                        id: 'name',
                        type: 'text',
                        title: 'Name',
                        fieldName: 'name',
                    },
                    {
                        id: 'siteId',
                        type: 'text',
                        title: 'Site Id',
                        fieldName: 'siteId',
                    },
                    {
                        id: 'productType',
                        type: 'enum',
                        title: 'Product type',
                        fieldName: 'productType',
                        values: [
                            {
                                value: 'default',
                                label: 'Default',
                            },
                            {
                                value: 'subscription',
                                label: 'Subscription',
                            },
                        ],
                    },
                    {
                        id: 'createdAt',
                        fieldName: 'createdAt',
                        type: 'date',
                        title: 'Created At',
                    },
                    {
                        id: 'updateddAt',
                        fieldName: 'updatedAt',
                        type: 'date',
                        title: 'Updated At',
                    },
                    {
                        id: 'subsriptionInterval',
                        fieldName: 'subscriptionInterval',
                        type: 'text',
                        title: 'Subscription interval',
                    },
                    {
                        id: 'firstIntervalFree',
                        fieldName: 'firstIntervalFree',
                        type: 'boolean',
                        title: 'First interval free',
                    },
                    {
                        id: 'price',
                        type: 'number',
                        title: 'Price',
                        fieldName: 'price',
                    },
                    {
                        id: 'productId',
                        type: 'text',
                        title: 'Product Id',
                        fieldName: 'productId',
                    },
                ],
            },
        ],
    },
};

export default form;
