import { CustomEndpointConfig } from '../../../../core/config-builder/types';

const advertiser: CustomEndpointConfig = {
    title: 'לקוחות',
    metaInfo: {
        properties: {
            name: {
                name: 'שם הלקוח',
                isRequired: true,
            },
            logoBackgroundColor: {
                name: 'צבע מאחורי הלוגו',
            },
            coverPhoto: {
                name: 'תמונת רקע',
            },
            logo: {
                name: 'לוגו',
            },
            createdAt: {
                name: 'נוצר ב',
            },
        },
    },
    forms: {
        item: {
            views: {
                name: {
                    label: 'שם הלקוח',
                },
                logoBackgroundColor: {
                    label: 'צבע מאחורי הלוגו',
                },
                coverPhoto: {
                    label: 'תמונת רקע',
                },
                logo: {
                    label: 'לוגו',
                },
                createdAt: {
                    label: 'נוצר ב',
                },
            },
        },
    },
};

export default advertiser;
