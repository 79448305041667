import { DialogData } from '../../../../../components/form/context/types';
import content from './content';
import handlers from './handlers';

const propertyDialog: DialogData = {
    id: 'propertyDialog',
    content,
    handlers,
    fields: {
        dialogTitle: '',
        schema: {
            enum: [],
        },
    },
};

export default propertyDialog;
