import lodash from 'lodash';
import React, { CSSProperties } from 'react';
import TextCellProps from './props';
import style from './style.module.scss';
import UpdateInPlace from './update-in-place';

const CellText = (props: TextCellProps) => {
    const { column, item, isUpdateInPlaceProcessing, updateValue } = props;
    let value = props.value;
    const { updateInPlace } = column;

    const UpdateInPlaceComponent =
        column.UpdateInPlaceComponent ?? UpdateInPlace;
    if (updateInPlace) {
        return (
            <UpdateInPlaceComponent
                value={value}
                column={column}
                isUpdateInPlaceProcessing={isUpdateInPlaceProcessing}
                item={item}
                updateValue={updateValue}
                updateInPlace={updateInPlace}
            />
        );
    }

    let calculatedStyle: CSSProperties | undefined;

    if (column.style && typeof column.style === 'function') {
        calculatedStyle = column.style!({ column, item, value });
    } else {
        calculatedStyle = column.style;
    }

    if (value && lodash.isObjectLike(value)) {
        console.error('Wrong value', { column, value });
        value = JSON.stringify(value);
    }

    return (
        <div
            className={style.textCell}
            style={calculatedStyle}
            data-update-in-place={!!updateInPlace}
        >
            {value && <span>{value}</span>}
            {!value && <span>&nbsp;</span>}
        </div>
    );
};

export default React.memo(CellText);
