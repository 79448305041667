import React from 'react';
import { useAppContextObserver } from '../../../../../components/form/context';
import { getValue } from '../../../../../utils';
import PropsLandingPreview from './props';
import style from './style.module.scss';

const LandingPreview = (props: PropsLandingPreview) => {
    let { url, name, width, height } = props;
    const [isHidden] = useAppContextObserver((context) =>
        getValue(props.isHidden, context)
    );

    if (isHidden) {
        return null;
    }
    return (
        <div className={style.landingPreview}>
            <iframe
                width={`${width}px`}
                height={`${height}px`}
                frameBorder={0}
                src={url}
                name={name}
                title="landing"
            />
        </div>
    );
};

export default LandingPreview;
