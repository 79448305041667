import { AppContext } from '../../../../../components/form/context/types';
import {
    ItemFormFunctionGetHandlers,
    ItemFormHandlers,
} from '../../../../../components/item-form/types';
import apiClient from '../../../../../requests/api';

const handlers: ItemFormFunctionGetHandlers = function (
    this: AppContext
): ItemFormHandlers {
    const getParams = () => {
        return {
            siteId: undefined,
            endpointId: 'product',
            objectId: undefined,
        };
    };

    const onBeforeOpen = async () => {
        debugger;
        const { siteId } = this.form.url.params;

        if (!this.form.object.productId) {
            this.form.object.siteId = siteId;
        }

        this.form.views.media.isHidden = true;
    };

    const onBeforeSave = async () => {
        const { siteId } = this.form.url.params;

        this.form.object.siteId = siteId;

        if (this.form.object.productType !== 'subscription') {
            this.form.object.subscriptionInterval = '';
            this.form.object.firstIntervalFree = false;
        }
    };

    const onLoadObject = async () => {
        const { objectId } = this.form.url.params;
        const data = await apiClient.getItemFromTable(
            undefined,
            'product',
            objectId
        );
        return data;
    };

    const onAfterSave = async () => {
        const { siteId, objectId } = this.form.url.params;
        this.form.url.replace(`/${siteId}/ledger-product/${objectId}/info`);
    };

    return {
        onLoadObject,
        onBeforeOpen,
        onBeforeSave,
        getParams,
        onAfterSave,
    };
};

export default handlers;
