import { motion } from 'framer-motion';
import React from 'react';
import propsWithDefaultValues from '../../../utils/propsWithDefaultValues';
import PropsDialog, { defaultProps } from './props';
import style from './style.module.scss';

const Dialog = (props: PropsDialog) => {
    props = propsWithDefaultValues(props, defaultProps);
    const { id, openedClass, closedClass, isOpened, title, contentClass } =
        props;

    const onClickBackdrop = (e: any) => {
        if (e.target.className === 'backdrop' && props.onClickBackdrop) {
            e.stopPropagation();
            props.onClickBackdrop();
        }
    };

    const dialogClass = isOpened ? openedClass : closedClass;
    return (
        <motion.div
            id={id}
            className={style.dialogBackdrop}
            onClick={onClickBackdrop}
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
        >
            <motion.div
                className={dialogClass}
                initial={{ scale: 1.1, opacity: 0 }}
                animate={{ scale: 1, opacity: 1 }}
                exit={{ scale: 0, opacity: 0 }}
            >
                <div className={title ? style.header : style.headerInvisible}>
                    {title}
                </div>
                <div className={contentClass}>{props.children}</div>
            </motion.div>
        </motion.div>
    );
};

export default React.memo(Dialog);
