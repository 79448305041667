import moment from 'moment';
import React from 'react';
import {
    AppContext,
    FormFunctionGetHandlers,
} from '../../../../components/form/context/types';
import apiClient from '../../../../requests/api';
import style from '../style.module.scss';
import { getHistoryFromStore, saveHistory } from './history';
import getTemplateCode from './template';

const CREATE_NEW_SCRIPT_ID = 'create_new_script';

const handlers: FormFunctionGetHandlers = function (this: AppContext) {
    const { appConfig } = this;

    const getParams = () => {
        // FIXME: Check me!!!!!
        const { siteId, endpointId } = this.form.url.params;
        const { scriptId } = this.form.url.queryParams;
        return {
            siteId,
            endpointId,
            scriptId,
            isNew: scriptId === CREATE_NEW_SCRIPT_ID,
        };
    };

    const updateViews = () => {
        const { siteId, endpointId, scriptId } = getParams();
        const endpointConfig = this.getEndpointConfig(siteId, endpointId);
        const siteConfig = this.getSiteConfig(siteId);

        const updateEditorTitle = () => {
            if (!siteConfig || !endpointConfig) {
                return;
            }
            this.form.views.editor.title = `Job Editor: ${siteConfig.title}/${endpointConfig.title}/${scriptId}`;
        };

        const updateSiteIdInputValues = () => {
            this.form.views.siteIdInput.values = (appConfig.sites || []).map(
                (item: any) => ({
                    label: item.id,
                    value: item.id,
                })
            );
        };

        const updateEndpointIdInputValues = () => {
            if (!siteConfig) {
                return;
            }

            this.form.views.endpointIdInput.values = siteConfig.endpoints.map(
                (item: any) => ({
                    label: item.id,
                    value: item.id,
                })
            );
        };

        const updateScriptIdInputValues = async () => {
            let jobs = [];

            if (siteId && endpointId) {
                try {
                    jobs = await apiClient.getJobsScripts(siteId, endpointId);
                } catch (e) {}
            }

            this.form.views.scriptIdInput.values = [
                {
                    label: 'CREATE NEW SCRIPT',
                    value: CREATE_NEW_SCRIPT_ID,
                },
                ...jobs.map((item: any) => ({
                    label: item,
                    value: item,
                })),
            ];
        };

        updateEditorTitle();
        updateSiteIdInputValues();
        updateEndpointIdInputValues();
        updateScriptIdInputValues();
        updateNewFlag();
    };

    const onBeforeClose = () => {};

    const onInsertTemplate = () => {
        const { siteId, endpointId } = getParams();
        this.form.code = getTemplateCode({
            siteId: siteId || 'YOUR_SITE_ID',
            endpointId,
        } as any);
    };

    const loadHistory = () => {
        const { siteId, endpointId, scriptId } = getParams();
        const items = getHistoryFromStore(siteId, endpointId, scriptId);

        this.form.views.historyGroup.items = items
            .slice()
            .reverse()
            .map((item: any, index: number) => ({
                id: `historyItem${index}`,
                type: 'CustomComponent',
                component: () => (
                    <div
                        className={style.historyItem}
                        onClick={handleClickHistoryItem(item)}
                    >
                        <span>
                            {moment(item.date).format('DD.MM.YYYY hh:mm:ss')}{' '}
                        </span>
                        &nbsp;
                        <span>{item.title}</span>
                    </div>
                ),
            }));

        // form.views.history.items = items.slice().reverse();
    };

    const loadScriptDataIfExist = async () => {
        const { scriptId, siteId, endpointId, isNew } = getParams();
        this.form.code = '';
        this.form.originalCode = '';

        if (!scriptId || isNew) {
            return;
        }

        this.form.views.tabContent.isLoading = true;

        // try {
        this.form.isNew = false;
        // FIXME
        const code = await apiClient.getJobScript(siteId, endpointId, scriptId);
        this.form.code = code;
        this.form.originalCode = code;

        this.form.isNew = true;
        // } catch (e) {
        // 	if (e === 404) {
        // 		this.form.isNew = true;
        // 		this.form.views.tabContent.isLoading = false;
        // 	} else {
        // 		console.log("Error", e);
        // 		this.form.notify({
        // 			text: "Connection error please reload the page",
        // 			type: "error"
        // 		});
        // 	}
        // }

        this.form.views.tabContent.isLoading = false;
    };

    const onInsertScriptFromHistory = (text: string) => async () => {
        this.form.code = text;
        this.form.closeDialog();
    };

    const handleClickHistoryItem = (item: any) => (e: any) => {
        e.stopPropagation();
        this.form.pushDialog('yesno', {
            text: 'Do you want to replace script code?',
            yesLabel: 'Replace',
            yesAction: onInsertScriptFromHistory(item.text),
            noLabel: 'Cancel',
        });
    };

    const onToggleHistory = () => {
        const isOpened = !this.form.isHistoryOpened;
        this.form.isHistoryOpened = isOpened;
        this.form.views.historyGroupContainer.className = isOpened
            ? style.historyGroupOpen
            : style.historyGroup;
    };

    const onChangeSiteId = async () => {
        const { isNew } = getParams();
        this.form.isNew = isNew;

        this.form.setDataSourceValue(
            'url.params.endpointId.replace',
            'undefined'
        );
        this.form.code = '';
        this.form.views.editor.isDisabled = true;
        updateViews();
    };

    const onChangeEndpointId = async () => {
        const { isNew } = getParams();
        this.form.isNew = isNew;

        this.form.code = '';
        this.form.views.editor.isDisabled = false;
        this.form.views.scriptIdInput.value = CREATE_NEW_SCRIPT_ID;
        updateViews();
        await loadScriptDataIfExist();
    };

    const onChangeScriptId = async (scriptId: string) => {
        this.form.isNew = scriptId === CREATE_NEW_SCRIPT_ID;

        this.form.code = '';
        this.form.views.editor.isDisabled = false;
        updateViews();
        await loadScriptDataIfExist();
    };

    const onDeleteScript = async () => {
        const { siteId, endpointId, scriptId } = getParams();
        this.form.views.tabContent.isLoading = true;

        await apiClient.deleteJobScript(siteId, endpointId, scriptId);
        this.form.views.tabContent.isLoading = false;
        this.form.closeDialog();

        const nextQueryParams = {
            ...this.form.url.queryParams,
        };

        delete nextQueryParams.scriptId;
        this.form.url.replace({
            path: this.form.url.value,
            queryParams: nextQueryParams,
        });
    };

    const onDelete = async () => {
        this.form.pushDialog('yesno', {
            text: 'Delete script?',
            yesAction: onDeleteScript,
            yesLabel: 'Delete',
            noLabel: 'Cancel',
        });
    };

    const onSave = async () => {
        const { newJobName, code } = this.form;
        let { siteId, endpointId, scriptId, isNew } = getParams();

        if (!siteId || !endpointId) {
            return;
        }

        this.form.hideNotification('JOB_SAVE_ERROR');
        this.form.views.newJobNameInput.displayError = false;

        if (isNew) {
            scriptId = newJobName;
            if (!scriptId) {
                this.form.views.newJobNameInput.displayError = true;
                this.form.notify(
                    {
                        type: 'error',
                        text: 'Name is required',
                        lifetimeMs: 3000,
                    },
                    'JOB_SAVE_ERROR'
                );
                return;
            }
        }

        if (!endpointId) {
            this.form.notify({
                text: 'Set endpoint id!',
                type: 'error',
                lifetimeMs: 3000,
            });
            return;
        }

        this.form.views.tabContent.isLoading = true;

        try {
            await apiClient.updateJobScript(siteId, endpointId, scriptId, code);
            const history = getHistoryFromStore(siteId, endpointId, scriptId);

            const date = new Date().toISOString();
            history.push({
                date,
                title: 'Before change',
                text: this.form.originalCode,
            });

            history.push({
                date,
                title: 'After change',
                text: code,
            });

            saveHistory(newJobName, siteId, endpointId, history);
            loadHistory();
            this.form.notify({
                text: 'The script is saved!',
                lifetimeMs: 3000,
            });

            this.form.newJobName = '';
            this.form.url.replace({
                path: this.form.url.value,
                queryParams: {
                    ...this.form.url.queryParams,
                    scriptId,
                },
            });
            updateNewFlag();
        } catch (e) {
            console.error('SAVE ERROR', e);
            this.form.notify({
                text: 'Save error',
                type: 'error',
            });
        }
        this.form.views.tabContent.isLoading = false;
        updateNewFlag();
    };

    const updateNewFlag = () => {};

    const onBeforeOpen = async () => {
        const { isNew } = getParams();
        this.form.isNew = isNew;

        updateViews();
        updateNewFlag();

        loadHistory();
        await loadScriptDataIfExist();
    };

    const onChangeUrl = () => {
        updateViews();
    };

    const isCodeEditorHidden = () => {
        const { siteId, endpointId, scriptId } = getParams();

        return !siteId || !endpointId || !scriptId;
    };

    const isEditorStubHidden = () => {
        const { siteId, endpointId, scriptId } = getParams();

        return siteId && endpointId && scriptId;
    };

    return {
        onBeforeOpen,
        onBeforeClose,
        onDelete,

        onToggleHistory,
        onChangeSiteId,
        onChangeEndpointId,
        onChangeScriptId,
        onSave,
        onInsertScriptFromHistory,
        onInsertTemplate,
        onChangeUrl,

        isCodeEditorHidden,
        isEditorStubHidden,
    };
};

export default handlers;
