import { CSSProperties } from 'react';
import apiClient, { TableItem, UpdateData } from '../../../requests/api';
// import StateTable from './state';
import { FieldName } from '../reference-input/props';
import { CallbackRowParams } from './body/row/props';
import { Selection } from './dependencies';
import { FilterData } from './header/column-filter/props';
import { ColumnsFilter } from './header/props';
import { Column, TableItemId } from './types';

export type ContextMenuItem = import('../context-menu/props').ContextMenuItem;

const updateItem = apiClient.updateTableItem;
const searchItems = apiClient.getItemsFromTable;

export type SortDirection = 'asc' | 'desc';

export interface SortItem {
    columnId: string;
    direction: SortDirection;
}
export interface FetchParams {
    siteId: string | undefined;
    endpointId: string | undefined;
    sort: { field: string; direction: SortDirection }[];
    filter: FilterItem[];
    from: number;
    limit: number;
    searchPhrase: string;
    searchFields?: FieldName[];
    queryParams?: string;
}
export interface FilterItemObject {
    field: FieldName;
    includeAll?: string[];
    excludeAll?: string[];
    include?: string[];
    exclude?: string[];
}

export type FilterItemRawString = string;

export type FilterItem = FilterItemObject | FilterItemRawString;

export type RawFilter = string;

export interface FunctionSearchItemsResult {
    items: TableItem[];
    total: number;
    aggregations: any;
}

// export interface FunctionSearchItems {
//     (
//         siteId: string | undefined,
//         endpointId: string,
//         sorted: SortItem[],
//         filter: FilterItem[],
//         from: number,
//         limit: number,
//         searchPhrase?: string,
//         searchFilter?: string,
//         queryParams?: string
//     ): Promise<FunctionSearchItemsResult>;
// }

export interface FunctionSearchItems {
    (...args: any[]): Promise<FunctionSearchItemsResult>;
}

type Color = string;

export interface Statistic {
    value: null;
    title: string;
    color: Color;
    getValue: (aggregations: any) => null;
}

export interface UpdateItemFieldParams {
    column: Column;
    item: any;
    value: any;
    error?: any;
}

export interface LoadReferencesParams {
    items: any[];
    total: number;
}

export interface PropsTableDefault {
    isSelectable: boolean;
    searchItems: FunctionSearchItems;
    updateItem: (
        siteId: string | undefined,
        endpointId: string,
        itemId: TableItemId,
        data: UpdateData
    ) => void;

    selection: Selection;
    searchPhrase: string;
    queryParams: string;
    queryFilter: FilterItem[];

    page: number;
    sort: SortItem[];
    defaultSort: SortItem[];
    filter: ColumnsFilter;
    idField?: string;
}

export interface PropsTableCustom {
    id: string;
    isSelectable: boolean;
    onClickRow?: (item: any) => void;
    contextMenu?: ContextMenuItem[];
    rowClassName?: string | ((params: Readonly<CallbackRowParams>) => string);
    rowStyle?:
        | CSSProperties
        | ((params: Readonly<CallbackRowParams>) => CSSProperties);
    siteId?: string;
    endpointId?: string;
    isLoading?: boolean;
    page?: number;
    items?: any[];
    pageSize: number;
    columns: Column[];
    onChangeSelection?: (selection: Selection) => void;
    onChangeFilter?: (
        newFilter: ColumnsFilter,
        filter: ColumnsFilter | undefined,
        columnId: string,
        filterData: FilterData | undefined
    ) => void;
    onChangePage?: (newPage: number) => void;
    // onBeforeFetch?: (state: StateTable, fetchParams: FetchParams) => void; //FIXME
    onBeforeFetch?: (state: any, fetchParams: FetchParams) => void;
    onChangeSort?: (sort: SortItem[]) => void;
    onBeforeUpdateItemField?: (params: UpdateItemFieldParams) => void;
    onAfterUpdateItemField?: (params: UpdateItemFieldParams) => void;
    statistics?: Statistic[];
    onBeforeLoadReferences?: (params: LoadReferencesParams) => void;
    onMapItems?: (params: { items: any[]; total: number }) => Promise<any[]>;
    queryParams?: string;
    searchFields?: FieldName[];
    idField?: string;
}

export const defaultProps: PropsTableDefault = {
    filter: {},
    isSelectable: false,
    selection: new Selection(),
    searchPhrase: '',
    queryParams: '',
    page: 0,
    sort: [],
    defaultSort: [],
    queryFilter: [],
    searchItems,
    updateItem,
    idField: '_id',
};

type PropsTable = PropsTableCustom & Partial<PropsTableDefault>;

export default PropsTable;
