import React, { CSSProperties, useEffect, useState } from 'react';
import ComponentProps from './props';
import style from './style.module.scss';

const UpdateInPlace = (props: ComponentProps) => {
    const { isUpdateInPlaceProcessing, column, item, updateValue } = props;
    const [value, setValue] = useState(props.value);

    useEffect(() => {
        setValue(props.value);
    }, [props.value]);

    let calculatedStyle: CSSProperties | undefined;

    if (column.style && typeof column.style === 'function') {
        calculatedStyle = column.style!({ column, item, value });
    } else {
        calculatedStyle = column.style;
    }

    const _handleKeyUp = (e: any) => {
        if (e.key === 'Enter') {
            e.preventDefault();
            updateValue(e.target.value);
        }
    };

    const _handleChange = (e: any) => {
        setValue(e.target.value);
    };

    return (
        <div className={style.textCell} onClick={(e) => e.stopPropagation()}>
            <input
                value={value}
                disabled={!!isUpdateInPlaceProcessing}
                className={style.input}
                style={calculatedStyle}
                onKeyUp={_handleKeyUp}
                onChange={_handleChange}
                data-processing={!!isUpdateInPlaceProcessing}
                onBlur={() => setValue(props.value)}
            />
        </div>
    );
};

export default UpdateInPlace;
