import {
    AppContext,
    FormFunctionGetHandlers,
} from '../../../components/form/context/types';
import { convertToQueryFilter } from '../../../components/native/table/filter';

const handlers: FormFunctionGetHandlers = function (this: AppContext) {
    const onDeleteSuccess = () => {
        this.form.selection = 'none';
        this.form.views.table.weakCallbacks.update &&
            this.form.views.table.weakCallbacks.update();
    };

    const onOpenDeleteItemsDialog = () => {
        const { selection } = this.form;
        const { search, filter: tableFilter } = this.form.url.queryParams;

        if (selection === 'none') {
            this.form.notify({
                text: 'No selected items',
                type: 'error',
                lifetimeMs: 2000,
            });
            return;
        }

        const filter = convertToQueryFilter(
            this.form.views.table.columns,
            tableFilter || []
        );

        this.form.pushDialog('deleteItem', {
            siteId: undefined,
            endpointId: 'landings',
            selection,
            filter,
            searchPhrase: search,
            onSuccess: onDeleteSuccess,
        });
    };

    return {
        onOpenDeleteItemsDialog,
    };
};

export default handlers;
