import PropsForm from '../form/props';
import { ItemFormFunctionGetHandlers } from './types';

export default interface PropsItemForm extends Omit<PropsForm, 'handlers'> {
    handlers?: ItemFormFunctionGetHandlers;
}

export const defaultProps: Partial<PropsItemForm> = {
    title: 'onGetTitle',
};
