import { CustomSiteConfig } from '../../../core/config-builder/types';
import orders from './orders';
import products from './products';

const pazamshop: CustomSiteConfig = {
    title: 'מוצרי פז״מ',
    endpoints: {
        products,
        orders,
    },
};

export default pazamshop;
