import React, { useMemo } from 'react';
import defaultItemFormHandlersFunction from '../../config-builder/forms/item/handlers';
import { usePropsWithDefaultValues } from '../../utils/propsWithDefaultValues';
import Form from '../form';
import {
    AppContext,
    CustomHandlers,
    FormFunctionGetHandlers,
    FormHandlers,
} from '../form/context/types';
import itemFormHandlers from './handlers';
import PropsItemForm, { defaultProps } from './props';
import { ItemFormFunctionGetHandlers } from './types';

const mergeHandlers = (
    customItemFormHandlers: ItemFormFunctionGetHandlers | undefined
): FormFunctionGetHandlers => {
    return function (this: AppContext): FormHandlers {
        let calculatedCustomHandlers: CustomHandlers = {};
        if (customItemFormHandlers) {
            calculatedCustomHandlers = customItemFormHandlers.call(this);
        }

        const defaultItemFormHandlers =
            defaultItemFormHandlersFunction.call(this);

        return {
            ...defaultItemFormHandlers,
            ...calculatedCustomHandlers,
            ...itemFormHandlers.call(this, {
                customHandlers: {
                    ...defaultItemFormHandlers,
                    ...calculatedCustomHandlers,
                },
            }),
        };
    };
};

const ItemForm = (props: PropsItemForm) => {
    props = usePropsWithDefaultValues(props, defaultProps);
    const {
        id,
        content,
        handlers,
        // mediaManagers,
        ...restBaseFormProps
    } = props;

    const mergedHandlers = useMemo(() => mergeHandlers(handlers), [handlers]);
    return (
        <Form
            {...restBaseFormProps}
            id={id}
            key={id}
            content={content}
            handlers={mergedHandlers}
        />
    );
};

export default React.memo(ItemForm);
