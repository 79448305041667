import moment from 'moment';
import {
    AppContext,
    FormFunctionGetHandlers,
    FormHandlers,
} from '../../../../../core/components/form/context/types';
import apiClient from '../../../../../core/requests/api';

const DEFAULT_TOP_USERS_IN_TEAMS_TITLE = 'Top users in team';

const convertDateFilterToQuery = (
    field: string,
    { startDate, endDate }: any
): string => {
    if (!startDate && !endDate) {
        return '';
    }

    const startMoment = moment(startDate, 'YYYY-MM-DDTHH:mm:ss');
    const endMoment = moment(endDate, 'YYYY-MM-DDTHH:mm:ss');

    const dateQueryFormat = 'YYYY-MM-DDTHH:mm:ss';

    if (startDate && endDate) {
        return `${field}:[${startMoment.format(
            dateQueryFormat
        )} TO ${endMoment.format(dateQueryFormat)}]`;
    }

    if (startDate) {
        return `${field}:[${startMoment.format(dateQueryFormat)} TO *]`;
    }

    return `${field}:[* TO ${endMoment.format(dateQueryFormat)}]`;
};

const handlers: FormFunctionGetHandlers = function (
    this: AppContext
): FormHandlers {
    const loadTopUsers = async () => {
        this.form.topUsers = [];

        const sort = this.form.topUsersTableSort;
        const sortBy = {
            field: 'gameScore',
            direction: 'desc',
        };

        if (sort.length > 0) {
            const sortItem = sort[0];
            sortBy.field = sortItem.columnId;
            sortBy.direction = sortItem.direction;
        }

        const aggs = {
            aggs: {
                users: {
                    terms: {
                        field: 'wordGameUserId',
                        size: 10,
                        order: { [sortBy.field]: sortBy.direction },
                    },
                    aggs: {
                        score: {
                            max: {
                                field: 'gameScore',
                            },
                        },
                        scoreSum: {
                            sum: {
                                field: 'gameScore',
                            },
                        },
                    },
                },
            },
        };

        const { startDate, endDate } = this.form;
        const dateFilter = convertDateFilterToQuery('gameCreatedAt', {
            startDate,
            endDate,
        });
        let q = '';
        if (dateFilter) {
            q = dateFilter;
        }

        const queryParams = `size=0&sort=gameScore:desc&aggs=${JSON.stringify(
            aggs
        )}&q=${q}`;
        const response = await apiClient.callSearchRequest(
            'cbc',
            'wordgamescore',
            queryParams
        );

        const rows = response.aggregations.users.buckets.map((item: any) => {
            return {
                userId: item.key,
                score: item.score.value,
                scoreSum: item.scoreSum.value,
            };
        });

        this.form.topUsers = rows;
    };

    const loadNumberOfPlayedGames = async () => {
        this.form.playedGamesNumber = '';

        const aggs = {
            aggs: {
                games_count: {
                    value_count: {
                        field: 'gameCreatedAt',
                    },
                },
            },
        };

        const { startDate, endDate } = this.form;
        const q = convertDateFilterToQuery('gameUpdatedAt', {
            startDate,
            endDate,
        });
        const queryParams = `size=0&sort=distance:desc&aggs=${JSON.stringify(
            aggs
        )}&q=${q}`;
        const response = await apiClient.callSearchRequest(
            'cbc',
            'wordgamescore',
            queryParams
        );

        this.form.playedGamesNumber = response.aggregations.games_count.value;
    };

    const loadNumberOfCompletedWords = async () => {
        this.form.numberOfCompletedWords = '';

        const aggs = {
            aggs: {
                games_score: {
                    sum: {
                        field: 'gameScore',
                    },
                },
            },
        };

        const { startDate, endDate } = this.form;
        const q = convertDateFilterToQuery('gameUpdatedAt', {
            startDate,
            endDate,
        });
        const queryParams = `size=0&sort=distance:desc&aggs=${JSON.stringify(
            aggs
        )}&q=${q}`;
        const response = await apiClient.callSearchRequest(
            'cbc',
            'wordgamescore',
            queryParams
        );

        const kWordPoints = 50;
        this.form.numberOfCompletedWords = Number(
            response.aggregations.games_score.value / kWordPoints
        ).toFixed();
    };

    const loadNumberOfPlayers = async () => {
        this.form.numberOfPlayers = '';

        const aggs = {
            aggs: {
                players_count: {
                    value_count: {
                        field: 'wordGameUserId',
                    },
                },
            },
        };

        const queryParams = `size=0&aggs=${JSON.stringify(aggs)}`;
        const response = await apiClient.callSearchRequest(
            'cbc',
            'wordgamescore',
            queryParams
        );

        this.form.numberOfPlayers = response.aggregations.players_count.value;
    };

    const loadNumberOfActiveUsers = async () => {
        this.form.numberOfActiveUsers = '';

        const aggs = {
            aggs: {
                active_users_count: {
                    cardinality: {
                        field: 'wordGameUserId',
                        precision_threshold: 1,
                    },
                },
            },
        };

        const { startDate, endDate } = this.form;
        const q = convertDateFilterToQuery('gameUpdatedAt', {
            startDate,
            endDate,
        });
        const queryParams = `size=0&sort=gameScore:desc&aggs=${JSON.stringify(
            aggs
        )}&q=${q}`;
        const response = await apiClient.callSearchRequest(
            'cbc',
            'wordgamescore',
            queryParams
        );

        this.form.numberOfActiveUsers =
            response.aggregations.active_users_count.value;
    };

    const updateTopUsersInTeamTitle = async (teamId: string) => {
        this.form.topUsersInTeamTitle = DEFAULT_TOP_USERS_IN_TEAMS_TITLE;
        const teamData = await apiClient.getItemFromTable(
            'cbc',
            'flappyteam',
            teamId
        );
        this.form.topUsersInTeamTitle = `${DEFAULT_TOP_USERS_IN_TEAMS_TITLE} (${teamData.title})`;
    };

    const loadTeamsData = async () => {
        this.form.topUsersInTeam = [];
        await loadTopUsers();
    };

    const loadData = () => {
        loadNumberOfPlayedGames();
        loadNumberOfCompletedWords();
        loadNumberOfPlayers();
        loadNumberOfActiveUsers();
        loadTeamsData();
    };

    const onBeforeOpen = async () => loadData();

    const onChangeStartDate = (newStartDate: string) => {
        this.form.startDate = newStartDate;
        loadData();
    };

    const onChangeEndDate = async (newEndDate: string) => {
        this.form.endDate = newEndDate;
        await loadData();
    };

    const onSelectTopTeamRow = async (selectedRow: any) => {
        const selectedTeamId: string = selectedRow.teamId;
        this.form.topTeams = this.form.topTeams.map((row: any) => ({
            ...row,
            isSelected: row.teamId === selectedTeamId,
        }));

        await updateTopUsersInTeamTitle(selectedTeamId);
        await loadTopUsers();
    };

    const onOpenUserCard = async (item: any) => {
        const { items } = await apiClient.getItemsFromTable(
            'cbc',
            'flappyplayer',
            [
                {
                    direction: 'asc',
                    field: 'userId',
                },
            ],
            [
                {
                    field: 'userId',
                    include: [item.userId],
                },
            ],
            0,
            1,
            undefined
        );

        if (items.length < 0) {
            return;
        }

        this.form.url.push(`/user/common/item/${items[0].userId}/info`);
    };

    const onChangeTopTeamsTableSort = (newSort: any) => {
        loadTeamsData();
    };

    return {
        onBeforeOpen,
        onChangeStartDate,
        onChangeEndDate,
        onSelectTopTeamRow,
        onOpenUserCard,
        onChangeTopTeamsTableSort,
    };
};

export default handlers;
