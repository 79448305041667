import { CSSProperties } from 'react';
import { SerializedSelection } from '../../../utils/Selection';
import PropsTable, {
    FilterItem,
    LoadReferencesParams,
    SortItem,
} from '../../native/table/props';
import {
    AppContext,
    DataSourcePath,
    HandlerValue,
    ValueOrHandler,
} from '../context/types';
import { Column } from '../form-table-types';

export interface ContextMenuItem extends Omit<any, 'onClick'> {
    isHidden?: ValueOrHandler<boolean>;
    onClick: HandlerValue | ((this: AppContext, data: any) => void);
}
export default interface PropsDataTable extends Pick<PropsTable, 'sort'> {
    id: string;
    isHidden?: ValueOrHandler<boolean>;
    siteId?: string;
    endpointId: string;
    isSelectable: boolean;
    columns: Column[];
    sort?: SortItem[];
    sortDataSource?: DataSourcePath<SortItem[]>;
    onClickItem: HandlerValue<(item: any) => void>;
    onChangeSort?: HandlerValue<(newSort: SortItem[]) => void>;
    onChangeFilter?: HandlerValue<(newFilter: FilterItem[]) => void>;
    onChangePage?: HandlerValue<(page: number) => void>;
    selection?: SerializedSelection;
    onChangeSelection?: HandlerValue<(selection: SerializedSelection) => void>;
    selectionDataSource?: string;
    filter?: FilterItem[];
    filterDataSource?: DataSourcePath<FilterItem[]>;
    defaultSort?: SortItem[];
    page?: number;
    pageSize?: number;
    pageDataSource?: DataSourcePath<number>;
    rowClassName?: HandlerValue<() => string>;
    rowStyle?: HandlerValue<() => CSSProperties>;
    contextMenu?: ContextMenuItem[];
    queryFilter?: any;
    queryFilterDataSource?: ValueOrHandler<FilterItem[] | undefined>;
    onBeforeLoadReferences?: HandlerValue<
        (params: LoadReferencesParams) => void
    >;
    onMapItems?: HandlerValue<
        (params: { items: any[]; total: number }) => Promise<any[]>
    >;
    searchPhrase?: string;
    searchDataSource?: HandlerValue;
    searchFields?: string[];
}

export const defaultProps: Partial<PropsDataTable> = {
    pageSize: 10,
    defaultSort: [],
};
