import {
    AppContext,
    VStack,
} from '../../../../../core/components/form/context/types';
import ViewTypes from '../../../../../core/components/form/ViewTypes';
import ChangePasswordDialogWrapper from './change-password-dialog';

const infoTabContent: VStack = {
    id: 'infoTabContentGroup',
    type: ViewTypes.VStack,
    style: { padding: 15, position: 'relative', paddingTop: 0 },
    items: [
        {
            id: 'commandPanel',
            type: ViewTypes.HStack,
            style: {
                position: 'absolute',
                height: 'unset',
                width: 'unset',
                background: 'white',
                padding: 10,
                zIndex: 100,
                left: 0,
                right: 0,
                top: 0,
                minHeight: 60,
            },
            items: [
                {
                    id: 'createButton',
                    type: ViewTypes.Button,
                    role: 'active',
                    text: 'Create',
                    onClick: 'onSave',
                    isHidden(this: AppContext) {
                        const isSystemSection =
                            this.form.url.params.usersSection === 'system';
                        if (!isSystemSection) {
                            return true;
                        }

                        const { objectId } = (
                            this.form.handlers as any
                        ).getParams();
                        return !!objectId;
                    },
                },
                {
                    id: 'saveButton',
                    type: ViewTypes.Button,
                    role: 'active',
                    text: 'Save',
                    onClick: 'onSave',
                    isHidden(this: AppContext) {
                        const isSystemSection =
                            this.form.url.params.usersSection === 'system';
                        if (!isSystemSection) {
                            return true;
                        }

                        const { objectId } = (
                            this.form.handlers as any
                        ).getParams();

                        return !objectId;
                    },
                },
            ],
        },
        {
            id: 'fieldsGroup',
            type: ViewTypes.VStack,
            style: { paddingTop: 70, paddingBottom: 30, paddingRight: 10 },
            items: [
                {
                    id: 'username',
                    type: ViewTypes.TextInput,
                    label: 'User Name',
                    dataSource: 'object.username',
                    ltr: true,
                    isDisabled(this: AppContext) {
                        return this.form.url.params.usersSection === 'common';
                    },
                },
                {
                    id: 'email',
                    type: ViewTypes.TextInput,
                    label: 'Email',
                    dataSource: 'object.email',
                    ltr: true,
                    isDisabled(this: AppContext) {
                        return this.form.url.params.usersSection === 'common';
                    },
                },
                {
                    id: 'siteId',
                    type: ViewTypes.EnumInput,
                    label: 'Sites',
                    isMultiple: true,
                    dataSource: 'object.siteId',
                    // style: { width: '100%', maxWidth: 'unset' },
                    values: [],
                    isDisabled(this: AppContext) {
                        return this.form.url.params.usersSection === 'common';
                    },
                },
                {
                    isHidden: true,
                    id: 'passwordGroup',
                    type: ViewTypes.HStack,
                    style: { marginBottom: 10 },
                    items: [
                        {
                            id: 'password',
                            type: ViewTypes.TextInput,
                            label: 'Password',
                            dataSource: 'object.password',
                        },
                        {
                            id: 'renewPasswordButton',
                            type: ViewTypes.Button,
                            role: 'notActive',
                            text: 'Generate',
                            style: { marginRight: 15 },
                            onClick: 'onGenerateNewPassword',
                        },
                    ],
                },
                {
                    id: 'roles',
                    type: ViewTypes.EnumInput,
                    label: 'Roles',
                    isMultiple: true,
                    dataSource: 'object.roles',
                    // style: { width: '100%', maxWidth: 'unset' },
                    values: [],
                },
                {
                    id: 'passwordChangeRequired',
                    type: ViewTypes.BooleanInput,
                    label: 'Reset password on next login',
                    dataSource: 'object.passwordChangeRequired',
                    isHidden: true,
                },
                {
                    id: 'changePasswordButton',
                    type: ViewTypes.Button,
                    text: 'Change password',
                    role: 'notActive',
                    style: {
                        width: 200,
                    },
                    onClick: 'onOpenChangePasswordDialog',
                },
                {
                    id: 'changePasswordDialog',
                    isHidden: true,
                    type: ViewTypes.CustomComponent,
                    component: ChangePasswordDialogWrapper,
                    onClose: 'onCloseChangePasswordDialog',
                },
            ],
        },
    ],
};

const content: VStack = {
    id: `rootGroup`,
    type: ViewTypes.VStack,
    items: [
        {
            id: 'content',
            type: ViewTypes.VStack,
            items: [
                {
                    id: 'tabs',
                    type: ViewTypes.TabsGroup,
                    activeTabIdSource: 'url.params.tabId.replace',
                    defaultTabId: 'info',
                    tabs: [
                        {
                            id: 'info',
                            title: 'Info',
                            type: ViewTypes.TabPage,
                            content: infoTabContent,
                        },
                    ],
                },
            ],
        },
    ],
};

export default content;
