import getAppConfig, { UserData } from '../../config-builder';
import { CustomAppConfig } from '../../config-builder/types';
import apiClient from '../../requests/api';
import { Observable } from '../definition';

const actions = {
    updateAppConfigFromRemoteMetaData: (data: {
        userData: UserData;
        customConfig: CustomAppConfig;
    }) => {
        return async (store: Observable) => {
            store.notifications.dispatch.pushNotification({
                data: {
                    text: `Loading...`,
                },
                id: 'APP_LOADING',
            });

            const remoteMetaInfo: any = {
                sites: await apiClient.getSites(true),
            };

            store.app.dispatch.setRemoteMetaInfo({ remoteMetaInfo });

            const appConfig = getAppConfig(
                remoteMetaInfo,
                data.customConfig,
                data.userData
            );
            appConfig.isLoaded = true;
            store.app.dispatch.updateAppConfigSuccess({ config: appConfig });

            store.notifications.dispatch.hideNotification({
                id: 'APP_LOADING',
            });
        };
    },

    updateAppConfig: (data: {
        userData?: UserData | null;
        customConfig: CustomAppConfig;
    }) => {
        return async (store: Observable) => {
            window._CUSTOM_APP_CONFIG = data.customConfig;
            window._CONFIG_PARAMS = data.customConfig.params;

            if (data.userData) {
                store.app.dispatch.updateAppConfigFromRemoteMetaData({
                    userData: data.userData,
                    customConfig: data.customConfig,
                });
            } else {
                const defaultConfig = getAppConfig(
                    null,
                    data.customConfig,
                    data.userData ?? undefined
                );
                store.app.dispatch.updateAppConfigSuccess({
                    config: defaultConfig,
                });
            }
        };
    },

    changeCurrentSite: (data: { siteId: string }) => {
        return async (store: Observable) => {
            store.app.dispatch.siteMetaRequest({ siteId: data.siteId });
        };
    },
};

export default actions;
