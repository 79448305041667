import { initialState, LoginPageState } from './state';

const reducers = {
    resetData:
        () =>
        (state: LoginPageState): LoginPageState => {
            return initialState;
        },

    loginRequest:
        () =>
        (state: LoginPageState): LoginPageState => {
            return {
                ...state,
                loginState: 'processing',
            };
        },

    loginSuccess:
        () =>
        (state: LoginPageState): LoginPageState => {
            return {
                ...state,
                loginState: 'success',
                errors: [],
            };
        },

    loginError:
        (data: { loginType?: 'phone'; errors: any[] }) =>
        (state: LoginPageState): LoginPageState => {
            if (data.loginType === 'phone') {
                return {
                    ...state,
                    loginState: 'wait_code',
                    errors: data.errors,
                };
            }

            return {
                ...state,
                loginState: 'start',
                errors: data.errors,
            };
        },
};

export default reducers;
