import React from 'react';
import { MenuItem as ReactMenuItem } from 'react-contextmenu';
import PropsMenuItem from './props';
import style from './style.module.scss';

const attributes = {
    className: style.contextMenuItem,
};

const MenuItem = (props: PropsMenuItem) => {
    const { onClick, title } = props;
    return (
        <ReactMenuItem attributes={attributes} onClick={onClick}>
            {title}
        </ReactMenuItem>
    );
};

export default React.memo(MenuItem);
