import style from './style.module.scss';

interface PropsInputBasic {
    id: string;
    isMultiLine?: boolean;
    displayError?: boolean;
    type?: string;
    label?: string;
    placeholder?: string;
    value: any;
    required?: boolean;
    disabled?: boolean;
    autocomplete?: string;
    ltr?: boolean;
    classContainer?: string;
    classInput?: string;
    classLabel?: string;
}

type PropsInput =
    | (PropsInputBasic & React.InputHTMLAttributes<HTMLInputElement>)
    | (PropsInputBasic & React.TextareaHTMLAttributes<HTMLTextAreaElement>);

export const defaultProps: Partial<PropsInput> = {
    isMultiLine: false,
    ltr: false,
    displayError: false,
    required: false,
    placeholder: '',
    type: 'text',
    autocomplete: '',
    disabled: false,
    classContainer: style.inputContainer,
    classInput: style.input,
    classLabel: style.label,
};

export default PropsInput;
