import {
    AppContext,
    FormFunctionGetHandlers,
} from '../../../../core/components/form/context/types';

const handlers: FormFunctionGetHandlers = function (this: AppContext) {
    const onBeforeOpen = async () => {
        const { currentUserData } = this;

        if (!currentUserData) {
            return;
        }

        const { roles } = currentUserData;
        if (roles.includes('admin')) {
            return;
        }

        const { siteId } = this.form.params;

        if (roles.includes(`${siteId}Admin`)) {
            return;
        }

        this.form.views.createButton.isHidden = true;

        this.form.commands.forEach((item) => {
            if (item.id === 'refresh') return;

            item.isHidden = true;
        });
    };
    const onClickWhatsapp = (item: any) => {
        // Here formContext
        let { phone } = item;
        if (phone.charAt(0) === '0') phone = phone.slice(1);

        // onSelectLeadRow(item);

        const copyText = `https://wa.me/972${phone}?text=היי ${item.fullName}, אוהב/ת צילום?
בהמשך להרשמתך להרצאה *איך הופכים את תחביב הצילום למקצוע מבוקש* בכנס המשתחררים של פז"מ, 
"גליץ" בית ספר לצילום מעניק לך *הטבת רישום מיוחדת לזמן מוגבל*! (7 ימים בלבד)
        
לקבלת פרטים על ההטבה והקורס פשוט תשלחו כאן הודעה
        
אם טרם הספקת לצפות בפרק, זה הקישור:
https://www.youtube.com/watch?v=sAqTJOzA9TY&ab_channel=PazamTV`;

        const textField = document.createElement('textarea');
        textField.innerText = encodeURI(copyText);
        const parentElement = document.getElementById('root')!;
        parentElement.appendChild(textField);
        textField.select();
        document.execCommand('copy');
        parentElement.removeChild(textField);

        this.form.notify(
            {
                text: 'הודעת וואטסאפ הועתקה',
                lifetimeMs: 3000,
            },
            'GALITZ_LEAD_COPY'
        );
    };

    return {
        onClickWhatsapp,
        onBeforeOpen,
    };
};

export default handlers;
