import { CustomSiteConfig } from '../../../core/config-builder/types';
import category from './category';
import comment from './comment';
import item from './item';
import rating from './rating';
import suggestion from './suggestion';
const knowledgebase: CustomSiteConfig = {
    title: 'מידע שימושי',
    dashboard: { commands: [] },
    endpoints: {
        category,
        comment,
        item,
        rating,
        suggestion,
    },
};

export default knowledgebase;
