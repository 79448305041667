import { DialogData } from '../../components/form/context/types';
import deleteItem from './deleteItem';
import exportDialog from './export';
import importDialog from './import';
import prompt from './prompt';
import yesno from './yesno';

const dialogs: Record<string, DialogData> = {
    deleteItem,
    export: exportDialog,
    import: importDialog,
    yesno,
    prompt,
};

export default dialogs;
