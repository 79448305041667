import React, { useCallback } from 'react';
import { getValue } from '../../../utils';
import DateInputNative from '../../native/date-input';
import { useAppContextObserver } from '../context';
import PropsDateInput from './props';

const DateInput = (props: PropsDateInput) => {
    const {
        id,
        label,
        placeholder,
        dataSource,
        showTimePicker,
        showClearButton,
        onChangeValue,
    } = props;

    const [{ isHidden, isDisabled, displayError, value }, getContext] =
        useAppContextObserver((context) => {
            let value = props.value;
            if (dataSource) {
                value = context.form.getDataSourceValue(dataSource);
            }

            return {
                isHidden: getValue(props.isHidden, context),
                displayError: getValue(props.displayError, context),
                isDisabled: getValue(props.isDisabled, context),
                value,
            };
        });

    const handleChangeValue = useCallback(
        (value: any) => {
            if (dataSource) {
                getContext().form.setDataSourceValue(dataSource, value);
            }

            if (!onChangeValue) {
                return;
            }

            if (typeof onChangeValue === 'string') {
                //FIXME: Check type
                (getContext().form.handlers as any)[onChangeValue](value);
                return;
            }

            onChangeValue.call(getContext(), value);
        },
        [getContext, dataSource, onChangeValue]
    );

    if (isHidden) {
        return null;
    }

    return (
        <DateInputNative
            id={id}
            label={label}
            displayError={displayError}
            disabled={isDisabled}
            placeholder={placeholder}
            value={value}
            showTimePicker={showTimePicker}
            showClearButton={showClearButton}
            showDatePickerAs="auto"
            onChange={handleChangeValue}
        />
    );
};

export default React.memo(DateInput);
