import * as _ from 'lodash';
import { getUserName } from '../../app/config/commonFunctions';
import { toTitleCase } from '../utils';
import getEndpointForms from './forms';
import {
    CustomEndpointConfig,
    CustomMetaInfoEndpoint,
    CustomMetaInfoProperty,
    CustomSiteConfig,
    EndpointConfig,
    EnumValues,
    LocalMetaInfoEndpoint,
    LocalMetaInfoEndpoints,
    LocalMetaInfoProperties,
    LocalMetaInfoProperty,
    LocalMetaInfoPropertyEnum,
    LocalMetaInfoPropertyReference,
    RemoteMetaInfoEndpoint,
    RemoteMetaInfoEndpoints,
    RemoteMetaInfoProperty,
} from './types';
import { sortIds } from './utils';

let delayedMetaInfoCalculations: Function[] = [];
function delayCalculation(calculation: Function) {
    delayedMetaInfoCalculations.push(calculation);
}

function clearDelayedMetaInfoCalculations() {
    delayedMetaInfoCalculations = [];
}

function mapRemotePropertyMetaInfoToLocal(
    siteId: string,
    endpointId: string,
    propertyId: string,
    remotePropertyMetaInfo: RemoteMetaInfoProperty,
    customPropertyMetaInfo: CustomMetaInfoProperty | undefined,
    endpointsIds: string[]
): LocalMetaInfoProperty {
    const result: LocalMetaInfoProperty = {
        type: remotePropertyMetaInfo.type,
        isHidden:
            remotePropertyMetaInfo.options &&
            remotePropertyMetaInfo.options.hidden,
        description: remotePropertyMetaInfo.description,
        name: remotePropertyMetaInfo.name || toTitleCase(propertyId),
        format: remotePropertyMetaInfo.format,
        isRequired:
            [
                'title',
                'name',
                'validFrom',
                'validTo',
                'startDate',
                'endDate',
            ].includes(propertyId) ??
            remotePropertyMetaInfo.required ??
            customPropertyMetaInfo?.isRequired,
        upload:
            (remotePropertyMetaInfo.options &&
                remotePropertyMetaInfo.options.upload) ??
            false,
        allowedExtensionsWithDot:
            remotePropertyMetaInfo.options &&
            (remotePropertyMetaInfo.options.allowed ?? []).map(
                (item) => `.${item}`
            ),
        defaultValue: remotePropertyMetaInfo.default,
    };

    switch (remotePropertyMetaInfo.type) {
        case 'string':
            if (remotePropertyMetaInfo.format === 'datetime-local') {
                result.format = 'datetime';
            } else if (
                remotePropertyMetaInfo.enum &&
                remotePropertyMetaInfo.enum.length > 0
            ) {
                const resultEnum = result as LocalMetaInfoPropertyEnum;
                resultEnum.format = 'enum';
                resultEnum.values = remotePropertyMetaInfo.enum.reduce(
                    (acc: EnumValues, value: string) => {
                        acc[value] = toTitleCase(value);
                        return acc;
                    },
                    {} as EnumValues
                );

                if (remotePropertyMetaInfo.default) {
                    resultEnum.defaultValue = remotePropertyMetaInfo.default;
                }
            } else if (propertyId.endsWith('Id')) {
                const customPropertyMetaInfoReference =
                    customPropertyMetaInfo as
                        | Partial<LocalMetaInfoPropertyReference>
                        | undefined;
                const resultReference =
                    result as LocalMetaInfoPropertyReference;
                const referenceEndpointId = propertyId.replace(/Id$/gm, '');
                if (
                    endpointsIds.includes(referenceEndpointId) ||
                    referenceEndpointId === 'user'
                ) {
                    resultReference.siteId = siteId;
                    resultReference.representationDataSource = undefined; // delay calc
                    resultReference.idField = '_id';
                    resultReference.sortListByField = '_id';

                    if (propertyId === 'userId') {
                        if (endpointsIds.includes('user')) {
                            if (
                                !customPropertyMetaInfoReference ||
                                !customPropertyMetaInfoReference.idField
                            ) {
                                delayCalculation(
                                    (
                                        endpointsMetaInfo: LocalMetaInfoEndpoints
                                    ) => {
                                        const endpointData =
                                            endpointsMetaInfo.user;
                                        const properities =
                                            endpointData.properties;

                                        if (
                                            Object.getOwnPropertyNames(
                                                properities
                                            ).includes('userId')
                                        ) {
                                            resultReference.idField = 'userId';
                                        }
                                        return true;
                                    }
                                );
                            }
                        } else {
                            resultReference.siteId = undefined;
                        }

                        resultReference.representationDataSource = getUserName;
                        resultReference.searchByField = [
                            'email',
                            'firstname',
                            'lastname',
                            'phone',
                        ];
                    }

                    resultReference.endpointId = referenceEndpointId;
                    resultReference.format = 'reference';

                    if (!remotePropertyMetaInfo.name) {
                        resultReference.name = toTitleCase(referenceEndpointId);
                    }
                }
            }
            break;
        case 'boolean':
            result.defaultValue = result.defaultValue === 'true';
            break;
        case 'number':
        case 'integer':
            if (remotePropertyMetaInfo.type === 'integer') {
                result.type = 'number';
            }
            result.defaultValue = 0;
            try {
                const value = Number(remotePropertyMetaInfo.default);

                if (!isNaN(value)) {
                    result.defaultValue = value;
                }
            } catch (e) {}
            break;
    }

    _.merge(result, customPropertyMetaInfo || {});

    {
        const resultReference = result as LocalMetaInfoPropertyReference;
        if (
            resultReference.format === 'reference' &&
            !resultReference.representationDataSource &&
            resultReference.representationDataSource !== null
        ) {
            delayCalculation((endpointsMetaInfo: LocalMetaInfoEndpoints) => {
                const referenceEndpointMetaInfo:
                    | LocalMetaInfoPropertyReference
                    | undefined = _.get(
                    endpointsMetaInfo,
                    `${resultReference.endpointId}`
                ) as any;

                if (!referenceEndpointMetaInfo) {
                    // FIXME
                    return true;
                }

                const properities =
                    ((referenceEndpointMetaInfo as any)?.properties as any) ??
                    {};

                if (properities.hasOwnProperty('title')) {
                    resultReference.representationDataSource = 'title';
                } else if (properities.hasOwnProperty('name')) {
                    resultReference.representationDataSource = 'name';
                } else {
                    resultReference.representationDataSource = '_id';
                    // console.warn(`No representationDataSource for endpoint: ${siteId}/${endpointId}`);
                }

                resultReference.sortListByField =
                    referenceEndpointMetaInfo.sortListByField;
                return true;
            });
        }
    }

    return result;
}

export function mapRemoteEndpointMetaInfoToLocal(
    siteId: string,
    endpointId: string,
    remoteEndpointMetaInfo: RemoteMetaInfoEndpoint,
    customEndpointMetaInfo: CustomMetaInfoEndpoint | undefined,
    endpointsIds: string[]
): LocalMetaInfoEndpoint {
    const endpointName =
        (customEndpointMetaInfo && customEndpointMetaInfo.name) ||
        toTitleCase(endpointId);
    const propertiesOrder =
        (customEndpointMetaInfo && customEndpointMetaInfo.propertiesOrder) ||
        'remote';
    const remotePropertiesInfo = remoteEndpointMetaInfo.schema.properties;
    const propertiesIds = Object.keys(remotePropertiesInfo);

    const propertiesCustomConfig =
        customEndpointMetaInfo && customEndpointMetaInfo.properties;

    if (propertiesCustomConfig) {
        // const remotePropertiesIds = Object.getOwnPropertyNames(
        //     remotePropertiesInfo
        // );
        // const customPropertiesIds = Object.getOwnPropertyNames(
        //     propertiesCustomConfig
        // );
        // customPropertiesIds.forEach((propertyId) => {
        // 	if (
        // 		!Object.getOwnPropertyNames(remotePropertiesInfo).includes(propertyId)
        // 	) {
        // 		console.warning(
        // 			`CONFIG BUILDER (mapRemoteEndpointMetaInfoToLocal): The property doesn't exist ${propertyId}`,
        // 			{
        // 				siteId,
        // 				endpointId,
        // 				propertyId,
        // 				remotePropertiesIds
        // 			}
        // 		);
        // 	}
        // });
    }

    const result: LocalMetaInfoEndpoint = {
        name: endpointName,
        propertiesOrder,
        properties: propertiesIds.reduce((result, propertyId) => {
            const propertyCustomConfig =
                propertiesCustomConfig && propertiesCustomConfig[propertyId];
            result[propertyId] = mapRemotePropertyMetaInfoToLocal(
                siteId,
                endpointId,
                propertyId,
                {
                    ...(remotePropertiesInfo[propertyId] || {}),
                    required: (
                        remoteEndpointMetaInfo.schema.required || []
                    ).includes(propertyId),
                },
                propertyCustomConfig,
                endpointsIds
            );
            return result;
        }, {} as LocalMetaInfoProperties),
    };

    result.representationDataSource =
        customEndpointMetaInfo &&
        customEndpointMetaInfo.representationDataSource;

    if (!result.representationDataSource) {
        if (result.properties.hasOwnProperty('title')) {
            result.representationDataSource = 'title';
        } else if (result.properties.hasOwnProperty('name')) {
            result.representationDataSource = 'name';
        } else {
            // result.representationDataSource = '_id';
            // console.warn(`No representationDataSource for endpoint: ${siteId}/${endpointId}`);
        }
    }

    return result;
}

function getEndpointConfig(
    siteId: string,
    endpointId: string,
    remoteEndpointMetaInfo: RemoteMetaInfoEndpoint,
    endpointMetaInfo: LocalMetaInfoEndpoint,
    endpointCustomConfig: CustomEndpointConfig | undefined
): EndpointConfig {
    return {
        id: endpointId,
        defaultForm: 'list',
        isAllowed:
            endpointCustomConfig &&
            endpointCustomConfig.hasOwnProperty('isAllowed')
                ? Array.isArray(endpointCustomConfig.isAllowed)
                    ? endpointCustomConfig.isAllowed
                    : !!endpointCustomConfig.isAllowed
                : true,
        title:
            (endpointCustomConfig && endpointCustomConfig.title) ||
            toTitleCase(endpointId),
        metaInfo: endpointMetaInfo,
        forms: getEndpointForms(
            siteId,
            endpointId,
            endpointMetaInfo,
            endpointCustomConfig
        ),
    };
}

function computeDelayedMetaInfoCalculations(
    endpointsMetaInfo: LocalMetaInfoEndpoints
) {
    while (delayedMetaInfoCalculations.length > 0) {
        const delayedCalc = delayedMetaInfoCalculations.shift()!;
        const success = delayedCalc(endpointsMetaInfo);
        if (!success) {
            delayedMetaInfoCalculations.push(delayedCalc);
        }
    }
}

function getEndpointsMetaInfo(
    siteId: string,
    endpointsIds: string[],
    endpoints: RemoteMetaInfoEndpoints,
    customSiteConfig: CustomSiteConfig | undefined
): LocalMetaInfoEndpoints {
    const endpointsMetaInfo: LocalMetaInfoEndpoints = {};

    const endpointsCustomConfig =
        customSiteConfig && customSiteConfig.endpoints;

    endpointsIds.forEach((endpointId) => {
        const remoteEndpointMetaInfo = endpoints[endpointId];

        const endpointCustomConfig =
            endpointsCustomConfig && endpointsCustomConfig[endpointId];

        const endpointMetaInfo = mapRemoteEndpointMetaInfoToLocal(
            siteId,
            endpointId,
            remoteEndpointMetaInfo,
            endpointCustomConfig && endpointCustomConfig.metaInfo,
            endpointsIds
        );

        endpointsMetaInfo[endpointId] = endpointMetaInfo;
    });

    return endpointsMetaInfo;
}

function getEndpointsConfig(
    siteId: string,
    endpoints: RemoteMetaInfoEndpoints,
    customSiteConfig: CustomSiteConfig | undefined
): EndpointConfig[] {
    const endpointsIds = sortIds(
        Object.keys(endpoints),
        customSiteConfig && customSiteConfig.endpointsOrder
    );

    clearDelayedMetaInfoCalculations();

    const localEndpointsMetaInfo = getEndpointsMetaInfo(
        siteId,
        endpointsIds,
        endpoints,
        customSiteConfig
    );
    computeDelayedMetaInfoCalculations(localEndpointsMetaInfo);

    const endpointsCustomConfig =
        customSiteConfig && customSiteConfig.endpoints;

    if (endpointsCustomConfig) {
        Object.getOwnPropertyNames(endpointsCustomConfig).forEach(
            (endpointId) => {
                if (
                    !Object.getOwnPropertyNames(endpoints).includes(endpointId)
                ) {
                    console.error(
                        `CONFIG BUILDER: Endpoint doesn't exist - "${endpointId}"`
                    );
                }
            }
        );
    }
    return endpointsIds.map((endpointId) => {
        const remoteEndpointMetaInfo = endpoints[endpointId];

        const endpointCustomConfig =
            endpointsCustomConfig && endpointsCustomConfig[endpointId];

        const endpointMetaInfo = localEndpointsMetaInfo[endpointId];

        return getEndpointConfig(
            siteId,
            endpointId,
            remoteEndpointMetaInfo,
            endpointMetaInfo,
            endpointCustomConfig
        );
    });
}

export default getEndpointsConfig;
