import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { arrayMove } from 'react-sortable-hoc';
import { ReactComponent as IconDown } from '../../../assets/icons/active-sort-arrow-asc.svg';
import { ReactComponent as IconUp } from '../../../assets/icons/active-sort-arrow-desc.svg';
import { addContextToHandlers, getHandlerWithContext } from '../../../utils';
import { getValue, useHandlersWithContext } from '../../../utils/context';
import Selection from '../../../utils/Selection';
import { useAppContextObserver } from '../context';
import { AppContext } from '../context/types';
import { Column, ColumnButton } from '../form-table-types';
import PropsInnerItems from './props';
import style from './style.module.scss';

const addContextToColumnFunctions = (columns: any[], context: AppContext) => {
    return columns.map((column) => addContextToHandlers(column, context));
};

const useMemoryTableState = (props: PropsInnerItems) => {
    const {
        dataSource,
        isSelectable,
        selectionDataSource,
        onChange,
        dialogData,
    } = props;

    const [
        {
            isHidden,
            rowStyle,
            rowClassName,
            onBeforeLoadReferences,
            selection,
            items,
        },
        getContext,
    ] = useAppContextObserver((context) => {
        const isHidden = getValue(props.isHidden, context);

        const rowStyle =
            !isHidden &&
            props.rowStyle &&
            getHandlerWithContext(props.rowStyle, context, true);
        const rowClassName =
            !isHidden &&
            props.rowClassName &&
            getHandlerWithContext(props.rowClassName, context, false);

        const onBeforeLoadReferences =
            props.onBeforeLoadReferences &&
            getHandlerWithContext(props.onBeforeLoadReferences, context, false);

        const selection: Selection | undefined = (() => {
            if (!isSelectable) {
                return;
            }

            if (props.selection) {
                return Selection.unserealizeFromObject(props.selection);
            }

            if (!selectionDataSource) {
                return;
            }

            const dataSourceValue = getValue(
                props.selectionDataSource,
                context,
                false
            );

            return Selection.unserealizeFromObject(dataSourceValue ?? null);
        })();

        let items = props.items;
        if (dataSource) {
            items = context.form.getDataSourceValue(dataSource) ?? [];
        }

        return {
            isHidden,
            rowStyle,
            rowClassName,
            onBeforeLoadReferences,
            selection,
            items,
        };
    });

    useMemo(() => {
        getContext().registerCustomDialog(dialogData);
    }, [getContext, dialogData]);

    const onChangeItems = useCallback(
        (newItems: any) => {
            if (dataSource) {
                getContext().form.setDataSourceValue(dataSource, newItems);
            }

            if (onChange) {
                onChange(newItems);
            }
        },
        [getContext, dataSource, onChange]
    );

    const onDeleteItem = useCallback(
        (deletedItem: any) => {
            const indexOfDeletedItem = items?.findIndex(
                (item) => item === deletedItem
            );

            if (indexOfDeletedItem === -1) {
                debugger;
                return;
            }

            const newItems = (items ?? []).filter(
                (item, index) => index !== indexOfDeletedItem
            );

            onChangeItems(newItems);
        },
        [items, onChangeItems]
    );

    const onMoveItemDown = useCallback(
        (itemToMove: any) => {
            const indexOfMovedItem = items!.findIndex(
                (item) => item === itemToMove
            );

            if (indexOfMovedItem === -1) {
                debugger;
                return;
            }

            if (indexOfMovedItem + 1 === items!.length) {
                return;
            }

            const newItems = arrayMove(
                items!.slice(),
                indexOfMovedItem,
                indexOfMovedItem + 1
            );

            onChangeItems(newItems);
        },
        [items, onChangeItems]
    );

    const onMoveItemUp = useCallback(
        (itemToMove: any) => {
            const indexOfMovedItem = items!.findIndex(
                (item) => item === itemToMove
            );

            if (indexOfMovedItem === -1) {
                debugger;
                return;
            }

            if (indexOfMovedItem === 0) {
                return;
            }

            const newItems = arrayMove(
                items!.slice(),
                indexOfMovedItem,
                indexOfMovedItem - 1
            );

            onChangeItems(newItems);
        },
        [items, onChangeItems]
    );

    const refDefaultColumnsMethods = useRef<any>();
    refDefaultColumnsMethods.current = {
        onDeleteItem,
        onMoveItemUp,
        onMoveItemDown,
    };

    const defaultColumns = useMemo(() => {
        const moveDownButton: ColumnButton = {
            id: 'moveDownButton',
            title: '',
            type: 'button',
            text: 'Order',
            fieldName: 'stub',
            onClick: () => {},
            CellComponent: () => (
                <div className={style.orderButtons}>
                    <IconDown
                        onClick={function (item: any) {
                            refDefaultColumnsMethods.current.onMoveItemDown(
                                item
                            );
                        }}
                    />
                    <IconUp
                        onClick={function (item: any) {
                            refDefaultColumnsMethods.current.onMoveItemUp(item);
                        }}
                    />
                </div>
            ),
            // onClick: function (item: any) {
            //     refDefaultColumnsMethods.current.onMoveItemDown(item);
            // },
        };

        const deleteButton: ColumnButton = {
            id: 'deleteButton',
            title: 'Delete',
            type: 'button',
            text: 'Delete',
            fieldName: 'stub',
            className: style.deleteButton,
            onClick: function (item: any) {
                refDefaultColumnsMethods.current.onDeleteItem(item);
            },
        };

        const columns: Column[] = [moveDownButton, deleteButton];

        return columns;
    }, []);

    const [columns, setColumns] = useState(() => [
        ...addContextToColumnFunctions(props.columns, getContext()),
        ...defaultColumns,
    ]);

    const handlersWithContext = useHandlersWithContext(props, getContext());

    useEffect(() => {
        setColumns([
            ...addContextToColumnFunctions(props.columns, getContext()),
            ...defaultColumns,
        ]);
    }, [props.columns, getContext, defaultColumns]);

    const onChangeSelection = useCallback(
        (newSelection: Selection) => {
            const serializedSelection = newSelection.serializeToObject();

            if (selectionDataSource) {
                getContext().form.setDataSourceValue(
                    selectionDataSource,
                    serializedSelection
                );
            }

            if (!props.onChangeSelection) {
                return;
            }

            const handler = getHandlerWithContext(
                props.onChangeSelection,
                getContext(),
                false
            );

            if (handler) {
                handler(serializedSelection);
            }
        },
        [getContext, props.onChangeSelection, selectionDataSource]
    );

    const onAddNewItem = useCallback(() => {
        debugger;
        getContext().form.pushDialog(dialogData.id, {
            onCancel: () => getContext().form.closeDialog(),
            onSave: (newItem: any) => {
                getContext().form.closeDialog();
                onChangeItems([...items!, newItem]);
            },
        });
    }, [getContext, dialogData?.id, items, onChangeItems]);

    const onClickItem = useCallback(
        (clickedItem: any) => {
            const clickedIndex = items!.findIndex(
                (item) => item === clickedItem
            );

            debugger;

            getContext().form.pushDialog(dialogData.id, {
                item: clickedItem,
                onCancel: () => getContext().form.closeDialog(),
                onSave: (changedItem: any) => {
                    getContext().form.closeDialog();

                    onChangeItems(
                        items!.map((item, index) => {
                            if (index === clickedIndex) {
                                return changedItem;
                            }

                            return item;
                        })
                    );
                },
            });
        },
        [getContext, dialogData?.id, items, onChangeItems]
    );

    return {
        columns,
        items,
        isHidden,
        handlersWithContext,
        selection,
        onChangeSelection,
        rowStyle,
        rowClassName,
        onBeforeLoadReferences,
        onAddNewItem,
        onClickItem,
    };
};

export default useMemoryTableState;
