import React from 'react';
import PropsCellProgress from './props';
import style from './style.module.scss';

const ProgressBar: any = require('react-progressbar').default;

const CellProgress = ({
    item,
    column,
    totalValue,
    currentValue,
}: PropsCellProgress) => {
    totalValue = totalValue || 0;
    currentValue = currentValue || 0;
    let availablePercent = 0;

    if (totalValue === 0 && currentValue === 0) {
        availablePercent = 0;
    } else if (totalValue === 0) {
        availablePercent = 0;
    } else if (currentValue === 0) {
        availablePercent = 100;
    } else {
        availablePercent = (totalValue - currentValue) / (totalValue / 100);
    }

    return (
        <div className={style.cell}>
            <div className={style.content}>
                <div className={style.progressContainer}>
                    <ProgressBar
                        className={style.progressBar}
                        completed={100 - availablePercent}
                    />
                    <span className={style.numbers}>
                        {currentValue}/{totalValue}
                    </span>
                </div>
            </div>
        </div>
    );
};

export default React.memo(CellProgress);
