import { VStack } from '../../../../../components/form/context/types';
import ViewTypes from '../../../../../components/form/ViewTypes';
import style from './style.module.scss';

export function getUserName(profileData: any) {
    if (!profileData) {
        return 'NOT FOUND';
    }

    if (profileData.firstname) {
        if (!profileData.lastname) {
            return profileData.firstname;
        }

        return `${profileData.firstname} ${profileData.lastname}`;
    }
    if (profileData.fullName) {
        return profileData.fullName;
    }

    if (profileData.email) {
        return profileData.email;
    }

    if (profileData.phone) {
        return profileData.phone;
    }

    return profileData.username;
}

const content: VStack = {
    id: 'rootGroup',
    type: ViewTypes.VStack,
    items: [
        {
            id: 'dialogTitle',
            type: ViewTypes.Text,
            value: 'Job',
            className: style.title,
        },
        {
            id: 'fieldsGroup',
            type: ViewTypes.VStack,
            className: style.fieldsGroup,
            items: [
                {
                    id: 'name',
                    type: ViewTypes.TextInput,
                    isDisabled: true,
                    label: 'Name',
                    dataSource: 'object.name',
                },
                {
                    id: 'siteid',
                    type: ViewTypes.TextInput,
                    isDisabled: true,
                    label: 'Site Id',
                    dataSource: 'object.siteId',
                },
                {
                    id: 'productType',
                    type: ViewTypes.EnumInput,
                    isDisabled: true,
                    label: 'Product type',
                    dataSource: 'object.productType',
                    values: [
                        {
                            value: 'default',
                            label: 'Default',
                        },
                        {
                            value: 'subscription',
                            label: 'Subscription',
                        },
                    ],
                },
                {
                    id: 'price',
                    type: ViewTypes.NumberInput,
                    isDisabled: true,
                    label: 'Price',
                    dataSource: 'object.price',
                },
                {
                    id: 'productId',
                    type: ViewTypes.ReferenceInput,
                    isDisabled: true,
                    label: 'Product',
                    dataSource: 'object.productId',
                    endpointId: 'product',
                    representationDataSource: 'name',
                },
                {
                    id: 'transactionId',
                    type: ViewTypes.TextInput,
                    label: 'Transaction Id',
                    isDisabled: true,
                    dataSource: 'object.transactionId',
                },
                {
                    id: 'userId',
                    type: ViewTypes.ReferenceInput,
                    label: 'User',
                    dataSource: 'object.userId',
                    endpointId: 'user',
                    isDisabled: true,
                    representationDataSource: getUserName,
                },
            ],
        },
        {
            id: 'footer',
            type: ViewTypes.HStack,
            items: [
                {
                    id: 'cancelButton',
                    type: ViewTypes.Button,
                    role: 'notActive',
                    text: 'Cancel',
                    onClick: 'onClose',
                },
            ],
        },
    ],
};

export default content;
