export type Key = string | number;

/**
 * Sets value and returns shallow copy of holder
 * @param {*} holder
 * @param {*} pathKeys
 * @param {*} value
 */
export default function shallowUpdate(
    holder: any[] | any,
    path: string,
    value: any,
    createPathIfNotExist = true
): any {
    const _shallowUpdate = (
        holder: any[] | any,
        pathKeys: Key[],
        value: any,
        createPathIfNotExist = true
    ): any => {
        const [key, ...nextKeys] = pathKeys;
        const isLeaf = !nextKeys || nextKeys.length === 0;

        if (Array.isArray(holder) || typeof key === 'number') {
            if (!holder && createPathIfNotExist) {
                holder = [];
            }
            return holder.map((item: any, index: number) => {
                if (isLeaf) {
                    return index === key ? value : item;
                }

                return index === key
                    ? _shallowUpdate(item, nextKeys, value)
                    : item;
            });
        }

        if (!holder && createPathIfNotExist) {
            holder = {};
        }

        return {
            ...holder,
            [key]: isLeaf
                ? value
                : _shallowUpdate(holder[key], nextKeys, value),
        };
    };

    const keys: (string | number)[] = path.split('.');
    for (let i = 0; i < keys.length; i++) {
        const key = keys[i];
        const isContainsArrayAccessor = (key as string).match(/\[\d+\]/);

        if (isContainsArrayAccessor) {
            const holderText = (key as string).split('[')[0]!;
            const accessorText = (key as string).match(/\[\d+\]/)![0];
            const numberText = accessorText.replace('[', '').replace(']', '');

            keys[i] = holderText;
            const number = Number(numberText);
            keys.splice(i + 1, 0, number);
            i += 1;
        }
    }

    return _shallowUpdate(holder, keys, value, createPathIfNotExist);
}
