import style from './style.module.scss';

interface PropsDateInput {
    id: string;
    showDatePickerAs?: 'dropdown' | 'dialog' | 'auto';
    classContainer?: string;
    classInput?: string;
    classLabel?: string;
    label?: string;
    renderDialogInto?: string;
    showClearButton?: boolean;
    showTimePicker?: boolean;
    value: string | any;
    onChange: (newValue: any) => void;
    disabled?: boolean;
    displayError?: boolean;
    placeholder?: string;
}

export default PropsDateInput;

export const defaultProps: Partial<PropsDateInput> = {
    showDatePickerAs: 'auto',
    renderDialogInto: 'body',
    showClearButton: false,
    showTimePicker: true,
    classContainer: style.inputContainer,
    classInput: style.input,
    classLabel: style.label,
};
