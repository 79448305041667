import { ItemFormConfig } from '../../../../core/config-builder/forms/item/types';

const item: ItemFormConfig = {
    title: 'פריטים',
    forms: {
        list: {
            // content of the list form
            defaultUrlQueryParams: {
                sort: [
                    {
                        columnId: 'createdAt',
                        direction: 'desc',
                    },
                ],
            },
        },
        item: {
            contentPartialChange: {
                contactName: {
                    label: 'Contact name',
                },
                contactPhone: {
                    label: 'Contact phone',
                },
            },
        },
    },
};

export default item;
