export interface Translation {
    value: string;
    translation: string;
}

const translations: Translation[] = [
    {
        value: 'user.firstName',
        translation: 'שם פרטי',
    },
    {
        value: 'user.lastName',
        translation: 'שם משפחה',
    },
    {
        value: 'user.age',
        translation: 'גיל',
    },
    {
        value: 'formatDate user.soldier.giusAt',
        translation: 'תאריך הגיוס',
    },
    {
        value: 'formatDate user.soldier.releaseAt',
        translation: 'תאריך השחרור',
    },
    {
        value: 'user.soldier.rank.title',
        translation: 'דרגה',
    },
    {
        value: 'user.soldier.barret.title',
        translation: 'כומתה',
    },
    {
        value: 'user.soldier.tag.title',
        translation: 'תג',
    },
    {
        value: 'user.soldier.job.title',
        translation: 'תפקיד',
    },
];

export default translations;
