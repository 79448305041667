import { useCallback, useState } from 'react';
import PropsHeader from './props';

function useHeaderState(props: PropsHeader) {
    const { onChangeSelection, onChangeSort, onChangeFilter } = props;
    const [openedFilterId, setOpenedFilterId] = useState<string | null>(null);

    const onToggleOpen = useCallback((columnId: string) => {
        setOpenedFilterId((openedFilterId) =>
            openedFilterId === columnId ? null : columnId
        );
    }, []);

    const onToggleAllItems = useCallback(
        (value: any) => {
            onChangeSelection(undefined, value === 'checked');
        },
        [onChangeSelection]
    );

    return {
        openedFilterId,
        onChangeFilter,
        onChangeSort,
        onToggleOpen,
        onToggleAllItems,
    };
}

export default useHeaderState;
