import {
    AppContext,
    FormFunctionGetHandlers,
} from '../../../../../components/form/context/types';
import ViewTypes from '../../../../../components/form/ViewTypes';
import apiClient from '../../../../../requests/api';

const parseUrlSearchParams = (
    urlSearchParams: string
): { key: string; value: string }[] => {
    if (urlSearchParams.startsWith('?')) {
        urlSearchParams = urlSearchParams.substring('?'.length);
    }

    return urlSearchParams.split('&').map((item) => {
        const [key, value] = item.split('=');
        return {
            key,
            value: decodeURIComponent(value),
        };
    });
};

const parseQueryString = (queryParams: string): string[] | undefined => {
    const params = parseUrlSearchParams(queryParams);
    let orderData: string | undefined;
    params.some(({ key, value }: any) => {
        if (key.toLowerCase() === 'order') {
            orderData = value;
            return true;
        }
        return false;
    });

    if (!orderData) {
        return;
    }

    return orderData.split('{!}');
};

const parsedOrderIdFromQueryString = (queryString: string) => {
    const [url] = parseQueryString(queryString)!;

    const urlSearchParamsText = url.split('?')[1];
    const urlSearchParams = parseUrlSearchParams(urlSearchParamsText);
    let parsedOrderId;

    urlSearchParams.some(({ key, value }: any) => {
        if (key === 'orderId') {
            parsedOrderId = value;
            return true;
        }
        return false;
    });
    return parsedOrderId;
};

const handlers: FormFunctionGetHandlers = function (this: AppContext) {
    const updateViews = () => {
        const params = new URLSearchParams(this.form.object.queryString);

        const fields: any[] = [];
        params.forEach((value, key) => {
            fields.push({
                id: `queryString${key}`,
                type: ViewTypes.TextInput,
                label: key,
                isDisabled: true,
                value,
            });

            if (key.toLowerCase() === 'order') {
                fields.push({
                    id: `queryString${key}orderId`,
                    type: ViewTypes.TextInput,
                    label: 'Order Id',
                    isDisabled: true,
                    value: parsedOrderIdFromQueryString(
                        this.form.object.queryString
                    ),
                });
            }
        });

        this.form.views.fieldsGroup.items = [
            ...this.form.views.fieldsGroup.items,
            {
                id: 'queryStringParseLabel',
                type: ViewTypes.Text,
                value: 'Parsed query string',
                style: { fontWeight: 'bold' },
            },
            ...fields,
            {
                id: 'queryString',
                type: ViewTypes.TextInput,
                isMultiLine: true,
                isDisabled: true,
                label: 'Raw query string',
                ltr: true,
                dataSource: 'object.queryString',
            },
        ];
    };
    const onBeforeOpen = async () => {
        const { params } = this.form;
        const { itemId } = params;

        const response = await apiClient.getItemFromTable(
            undefined,
            'ledger',
            itemId
        );

        this.form.object = response;
        updateViews();
    };

    const onClose = () => {
        this.form.closeDialog();
    };

    return {
        onBeforeOpen,
        onClose,
    };
};

export default handlers;
