import React from 'react';
import Lottie from 'react-lottie';
import animationData from '../../../../assets/animations/loading.json';
import Input from '../../../../components/native/input';
import useLoginFormState from './hooks';
import PropsLogingForm from './props';
import style from './style.module.scss';

const lottieOptions = {
    loop: true,
    autoplay: true,
    animationData: animationData,
    rendererSettings: {
        preserveAspectRatio: 'xMidYMid slice',
    },
};

const LoginForm = (props: PropsLogingForm) => {
    const {
        isProcessing,
        login,
        password,
        rememberMe,
        emailError,
        passwordError,
        onLogin,
        onPasswordKeyPress,
        onClickRememberMe,
        onChangeLogin,
        onChangePassword,
    } = useLoginFormState(props);

    return (
        <form>
            <div className={style.inputs}>
                <Input
                    id="email"
                    onChange={onChangeLogin}
                    type="email"
                    label="Email"
                    value={login}
                    placeholder="Enter your email"
                    disabled={isProcessing}
                    displayError={emailError}
                    ltr={true}
                    autocomplete="username"
                />
                <Input
                    id="password"
                    onChange={onChangePassword}
                    type="password"
                    label="Password"
                    value={password}
                    placeholder="•••••••••"
                    minLength={8}
                    pattern="^[a-zA-Z0-9@*#]+$"
                    disabled={isProcessing}
                    onKeyPress={onPasswordKeyPress}
                    displayError={!!passwordError}
                    autocomplete="current-password"
                    ltr={true}
                />
            </div>
            <div className={style.loginCheckbox}>
                <div className={style.submitButton} onClick={onLogin}>
                    <span>Login</span>
                </div>
                <label className={style.rememberMe}>
                    <input
                        onChange={onClickRememberMe}
                        type="checkbox"
                        disabled={isProcessing}
                        checked={rememberMe}
                    />
                    Remember me
                </label>
            </div>
            {isProcessing && (
                <div className={style.processingContainer}>
                    <Lottie options={lottieOptions} height={100} width={100} />
                </div>
            )}
        </form>
    );
};

export default React.memo(LoginForm);
