import moment from 'moment';
import {
    AppContext,
    FormFunctionGetHandlers,
    FormHandlers,
} from '../../../../../core/components/form/context/types';
import apiClient from '../../../../../core/requests/api';

const convertDateFilterToQuery = (
    field: string,
    { startDate, endDate }: any
): string => {
    if (!startDate && !endDate) {
        return '';
    }

    const startMoment = moment(startDate, 'YYYY-MM-DDTHH:mm:ss');
    const endMoment = moment(endDate, 'YYYY-MM-DDTHH:mm:ss');

    const dateQueryFormat = 'YYYY-MM-DDTHH:mm:ss';

    if (startDate && endDate) {
        return `${field}:[${startMoment.format(
            dateQueryFormat
        )} TO ${endMoment.format(dateQueryFormat)}]`;
    }

    if (startDate) {
        return `${field}:[${startMoment.format(dateQueryFormat)} TO *]`;
    }

    return `${field}:[* TO ${endMoment.format(dateQueryFormat)}]`;
};

const handlers: FormFunctionGetHandlers = function (
    this: AppContext
): FormHandlers {
    const loadTopUsers = async () => {
        this.form.topUsers = [];

        const sort = this.form.topUsersTableSort;
        const sortBy = {
            field: 'isLikedSum',
            direction: 'desc',
        };

        if (sort.length > 0) {
            const sortItem = sort[0];
            sortBy.field = sortItem.columnId;
            sortBy.direction = sortItem.direction;
        }

        const aggs = {
            aggs: {
                users: {
                    terms: {
                        field: 'gazarteaprofileId',
                        size: 10,
                        order: { [sortBy.field]: sortBy.direction },
                    },
                    aggs: {
                        isLikedSum: {
                            sum: {
                                field: 'gazarteavotesValue',
                            },
                        },
                    },
                },
            },
        };

        const { startDate, endDate } = this.form;
        const dateFilter = convertDateFilterToQuery('gazarteavotesCreatedAt', {
            startDate,
            endDate,
        });
        let q = '';
        if (dateFilter) {
            q = dateFilter;
        }

        const queryParams = `size=0&aggs=${JSON.stringify(aggs)}&q=${q}`;

        try {
            const response = await apiClient.callSearchRequest(
                'cbc',
                'gazarteavotes',
                queryParams
            );

            const rows = response.aggregations.users.buckets.map(
                (item: any) => {
                    console.log(item);

                    return {
                        profileId: item.key,
                        isLikedSum: item.isLikedSum.value,
                    };
                }
            );

            this.form.topUsers = rows;
        } catch (e) {
            console.log(e);
        }
    };

    const loadTeamsData = async () => {
        this.form.topUsersInTeam = [];
        await loadTopUsers();
    };

    const loadData = () => {
        loadTeamsData();
    };

    const onBeforeOpen = async () => loadData();

    const onChangeStartDate = (newStartDate: string) => {
        this.form.startDate = newStartDate;
        loadData();
    };

    const onChangeEndDate = async (newEndDate: string) => {
        this.form.endDate = newEndDate;
        await loadData();
    };

    return {
        onBeforeOpen,
        onChangeStartDate,
        onChangeEndDate,
    };
};

export default handlers;
