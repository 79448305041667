import React from 'react';
import Lottie from 'react-lottie';
import animationData from '../../../assets/animations/loading.json';
import propsWithDefaultValues from '../../../utils/propsWithDefaultValues';
import Body from './body';
import Footer from './footer';
import Header from './header';
import useTableState from './hooks';
import PropsTable, {
    defaultProps,
    PropsTableCustom,
    PropsTableDefault,
} from './props';
import Statistics from './statistics';
import style from './style.module.scss';

const lottieOptions = {
    loop: true,
    autoplay: true,
    animationData,
    rendererSettings: {
        preserveAspectRatio: 'xMidYMid slice',
    },
};

const Table = (p: PropsTable, ref: any) => {
    const props: PropsTableDefault & PropsTableCustom = propsWithDefaultValues(
        p,
        defaultProps
    ) as any;

    const {
        id,
        isSelectable,
        onClickRow,
        contextMenu,
        rowClassName,
        rowStyle,
        searchItems,
        pageSize,
        idField,
    } = props;

    const {
        isLoading,
        items,
        statistics,
        pageReferenceValues,
        quickEditCell,
        page,
        total,
        sort,
        filter,
        selection,
        numberOfPages,
        columns,

        onChangePage,
        onChangeSelection,
        onChangeFilter,
        onChangeSort,
        onSetQuickEditCell,
        onUpdateItemField,
    } = useTableState(props, ref);

    return (
        <div className={style.outterTableContainer}>
            {props.statistics && <Statistics statistics={statistics!} />}
            <div className={style.tableContainer}>
                <table className={style.table}>
                    <Header
                        isSelectable={isSelectable}
                        selection={selection}
                        onChangeSelection={onChangeSelection}
                        columns={columns}
                        sort={sort}
                        onChangeSort={onChangeSort}
                        onChangeFilter={onChangeFilter}
                        searchItems={searchItems}
                        filter={filter}
                    />
                    <Body
                        tableId={id}
                        idField={idField!}
                        isLoading={isLoading}
                        isSelectable={isSelectable}
                        selection={selection}
                        onChangeSelection={onChangeSelection}
                        columns={columns}
                        items={items}
                        onClickRow={onClickRow ?? (() => {})}
                        contextMenu={contextMenu}
                        pageReferenceValues={pageReferenceValues ?? []}
                        rowClassName={rowClassName}
                        rowStyle={rowStyle}
                        onUpdateItemField={onUpdateItemField}
                        page={page}
                        pageSize={pageSize}
                        onSetQuickEditCell={onSetQuickEditCell}
                        quickEditCell={quickEditCell}
                    />
                </table>
            </div>
            {(isLoading || (items && items.length === 0)) && (
                <div className={style.spacer}>
                    {isLoading && (
                        <Lottie
                            options={lottieOptions}
                            height={100}
                            width={100}
                        />
                    )}
                    {!isLoading && items.length === 0 && (
                        <span className={style.notFoundBlock}>
                            ITEMS NOT FOUND
                        </span>
                    )}
                </div>
            )}

            <Footer
                total={total}
                page={page}
                numberOfPages={numberOfPages}
                pageSize={pageSize}
                onChangePage={onChangePage}
            />
        </div>
    );
};

export default React.memo(React.forwardRef(Table));
