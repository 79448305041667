import { arrayMove } from 'react-sortable-hoc';
import apiClient from '../../../requests/api';
import { blobToDataURL, sleep } from '../../../utils';
import { AppContext } from '../context/types';

interface MediaManagerItem {
    id: string;
    URL: string;
    data?:
        | any
        | {
              URL: string;
              created: string;
              ext: string;
              id: string;
              mime: string;
              modified: string;
              nameId: string;
          };
    isAdded?: boolean;
}
const _addBaseUrlToItem = (item: MediaManagerItem): MediaManagerItem => {
    let url = item.URL;
    const isRelativeUrl = url.startsWith('/');
    if (isRelativeUrl) {
        // FIXME!
        url = `${window._CONFIG_PARAMS.baseApiUrl.replace(
            '/v1',
            ''
        )}${url}?token=${window._AUTH_TOKEN}`;
    }
    return {
        id: item.id,
        URL: url,
        data: item,
    };
};

const getItems = async (
    siteId: string | undefined,
    endpointId: string,
    fieldName: string,
    objectId: string,
    isMultiple: boolean
): Promise<MediaManagerItem[]> => {
    // try {
    const tableItem = await apiClient.getItemFromTable(
        siteId,
        endpointId,
        objectId
    );
    let items = [];

    if (isMultiple) {
        items = tableItem[fieldName] || [];
    } else {
        const url = tableItem[fieldName];
        if (url) {
            const fileData = (tableItem.files ?? []).find(
                (item: MediaManagerItem) =>
                    item.URL === url || url.endsWith(item.URL)
            );
            if (fileData) {
                items = [fileData];
            } else {
                items = [];
                // alert("The files aren't consistent");
            }
        }
    }

    return (items || []).map(_addBaseUrlToItem);
    // } catch (e) {}
};

const copyItemsFrom = async (
    siteId: string | undefined,
    endpointId: string,
    fieldName: string,
    objectId: string,
    isMultiple: boolean
): Promise<MediaManagerItem[]> => {
    const items = await getItems(
        siteId,
        endpointId,
        fieldName,
        objectId,
        isMultiple
    );
    const promises: Promise<string>[] = items.map((item) =>
        fetch(item.URL)
            .then((response) => response.blob())
            .then((blob) => blobToDataURL(blob))
    );
    const images: string[] = await Promise.all(promises);
    const result: MediaManagerItem[] = images.map(
        (data: string, index: number): MediaManagerItem => {
            return {
                id: `${index}${Date.now()}`,
                isAdded: true,
                URL: data,
            };
        }
    );

    return result;
};

class MediaManager {
    public isMultiple?: boolean;

    private siteId: string | undefined;

    private endpointId: string;

    private objectId: string;

    private fieldName: string;

    private uploadUrlParams: string | undefined;

    private deletedItems: MediaManagerItem[];

    private addedItemsId: string[];

    private items: MediaManagerItem[];

    private numberOfChanges: number;

    private errors: any[];

    private isLoading: boolean;

    private isProcessing: boolean;

    private saveProgressItemId: null | string;

    private saveProgressPercent: number;

    private saveProgressStep: number;

    private saveProgressState:
        | null
        | 'deleteOldItems'
        | 'saveOrder'
        | 'uploadItems';

    private saveProgressTotal: number;

    private context: AppContext;

    private notificationId: string;

    private notificationText: (mediaManager: MediaManager) => string;

    private onBeforeSave?: (
        objectId: string,
        mediaManager: MediaManager
    ) => void;

    constructor(
        context: AppContext,
        siteId: string | undefined,
        endpointId: string,
        objectId: string,
        fieldName: string,
        uploadUrlParams: string | undefined,
        isMultiple: boolean | undefined,
        notificationId: string,
        notificationText: (mediaManager: MediaManager) => string,
        onBeforeSave?: (objectId: string, mediaManager: MediaManager) => void
    ) {
        this.isMultiple = isMultiple;
        this.siteId = siteId;
        this.endpointId = endpointId;
        this.objectId = objectId;
        this.fieldName = fieldName;
        this.uploadUrlParams = uploadUrlParams;
        this.isMultiple = isMultiple;

        this.deletedItems = [];
        this.addedItemsId = [];
        this.items = [];
        this.numberOfChanges = 0;
        this.errors = [];
        this.isLoading = false;
        this.isProcessing = false;
        this.saveProgressItemId = null;
        this.saveProgressPercent = 0;
        this.saveProgressStep = 0;
        this.saveProgressState = null;
        this.saveProgressTotal = 0;
        this.context = context;
        this.notificationId = notificationId;
        this.notificationText = notificationText;
        this.onBeforeSave = onBeforeSave;

        if (this.objectId) {
            this.loadItems(this.objectId);
        }
    }

    updateForm = () => {
        this.context && this.context.form.update();
        if (this.notificationId) {
            if (!this.isProcessing) {
                this.context.form.hideNotification(this.notificationId);
                return;
            }

            this.context.form.notify(
                {
                    text: this.notificationText(this),
                },
                this.notificationId
            );
        }
    };

    loadItems = async (objectId: string) => {
        this.isLoading = true;
        this.updateForm();

        const items = await getItems(
            this.siteId,
            this.endpointId,
            this.fieldName,
            objectId,
            this.isMultiple ?? false
        );
        this.items = items || [];
        this.isLoading = false;
        this.updateForm();
    };

    copyFrom = async (
        siteId: string | undefined,
        endpointId: string,
        fieldName: string,
        objectId: string,
        isMultiple = false
    ) => {
        this.isLoading = true;
        this.updateForm();
        const items = await copyItemsFrom(
            siteId,
            endpointId,
            fieldName,
            objectId,
            isMultiple
        );
        this.items = items || [];
        this.addedItemsId = this.items.map((item) => item.id);
        this.isLoading = false;
        this.updateForm();
    };

    _uploadItem = async (
        objectId: string,
        image: string,
        progressCallBack: (completedPercent: number) => void
    ) => {
        const data = await apiClient.uploadMedia(
            this.siteId,
            this.endpointId,
            objectId,
            image,
            this.isMultiple ? '' : this.fieldName,
            progressCallBack,
            this.uploadUrlParams
        );

        return data;
    };

    _deleteItems = async (objectId: string) => {
        let newDeletedItems = this.deletedItems.slice();
        const total = newDeletedItems.length;

        let step = 0;
        while (newDeletedItems.length > 0) {
            step++;

            const item = newDeletedItems[0];
            const itemId = item.id;

            this.isProcessing = true;
            this.saveProgressState = 'deleteOldItems';
            this.saveProgressStep = step;
            this.saveProgressPercent = 0;
            this.saveProgressItemId = itemId;
            this.saveProgressTotal = total;
            this.updateForm();

            try {
                await apiClient.deleteMedia(
                    this.siteId,
                    this.endpointId,
                    objectId,
                    itemId,
                    this.isMultiple ? '' : this.fieldName
                );

                newDeletedItems = newDeletedItems.filter(
                    (item) => item.id !== itemId
                );
                this.deletedItems = newDeletedItems;
                this.saveProgressPercent = 100;
                this.updateForm();
            } catch (e) {
                return {
                    state: 'deleteOldItems',
                    itemId,
                    error: e,
                };
            }
        }

        return null;
    };

    _saveOrder = async (objectId: string, newItems: MediaManagerItem[]) => {
        this.isProcessing = true;
        this.saveProgressState = 'saveOrder';
        this.saveProgressPercent = 0;
        this.updateForm();

        try {
            const newOrder = newItems.map((item) => item.data);
            await apiClient.updateTableItemField(
                this.siteId,
                this.endpointId,
                objectId,
                this.fieldName,
                newOrder
            );

            this.saveProgressState = 'saveOrder';
            this.saveProgressPercent = 100;
            this.updateForm();
        } catch (e) {
            return {
                state: 'saveOrder',
                error: e,
            };
        }

        return null;
    };

    _uploadItems = async (
        objectId: string
    ): Promise<{ newItems?: MediaManagerItem[]; error?: any }> => {
        let newAddedItemsId = this.items
            .filter((item) => this.addedItemsId.includes(item.id))
            .map((item) => item.id);
        const total = newAddedItemsId.length;

        let newItems = this.items.slice();
        let step = 0;

        while (newAddedItemsId.length > 0) {
            step++;
            const addedItemId = newAddedItemsId[0];
            const addedItem = newItems.find((item) => item.id === addedItemId)!;
            const image = addedItem.URL;

            this.saveProgressState = 'uploadItems';
            this.saveProgressStep = step;
            this.saveProgressItemId = addedItemId;
            this.saveProgressTotal = total;
            this.saveProgressPercent = 0;
            this.isProcessing = true;
            this.updateForm();

            try {
                const uploadedImageData = await this._uploadItem(
                    objectId,
                    image,
                    (progress) => {
                        this.saveProgressPercent = progress;
                        this.updateForm();
                    }
                );

                newItems = newItems.map((item) => {
                    if (item.id !== addedItemId) {
                        return item;
                    }

                    return _addBaseUrlToItem({
                        id: addedItemId,
                        URL: uploadedImageData.URL,
                        data: uploadedImageData,
                    });
                });

                newAddedItemsId = newAddedItemsId.filter(
                    (id) => id !== addedItemId
                );
                this.items = newItems;
                this.addedItemsId = newAddedItemsId;
                this.saveProgressPercent = 100;
                this.updateForm();
            } catch (e) {
                return {
                    error: {
                        state: 'uploadItems',
                        itemId: addedItemId,
                        step,
                        total,
                        error: e,
                    },
                };
            }
        }

        return { newItems };
    };

    addItem = (data: string) => {
        const id = Date.now().toString();
        const newItems = this.items.slice();
        newItems.push({
            id,
            URL: data,
            isAdded: true,
        });

        const newAddedItemsId = this.addedItemsId.slice();
        newAddedItemsId.push(id);

        this.items = newItems;
        this.addedItemsId = newAddedItemsId;
        this.updateForm();
    };

    undo = async () => {
        this.isLoading = true;
        this.items = [];
        this.deletedItems = [];
        this.addedItemsId = [];
        this.updateForm();

        if (this.objectId) {
            const items = await getItems(
                this.siteId,
                this.endpointId,
                this.fieldName,
                this.objectId,
                this.isMultiple ?? false
            );
            if (items) {
                this.items = items;
                this.updateForm();
            }
        }

        this.isLoading = false;
        this.updateForm();
    };

    deleteItem = (itemId: string) => {
        // FIXME: ??WHY
        // if (props.items) {
        //     onDeleteItem && onDeleteItem(itemId);
        //     return;
        // }

        const index = this.items.findIndex((item) => item.id === itemId);
        const deletedItem = this.items[index];

        const newItems = this.items.slice();
        newItems.splice(index, 1);

        const newDeletedItems = this.deletedItems.slice();

        if (!deletedItem.isAdded) {
            newDeletedItems.push(deletedItem);
        }

        this.items = newItems;
        this.deletedItems = newDeletedItems;
        this.updateForm();
    };

    moveItem = (oldIndex: number, newIndex: number) => {
        // if (props.items) {
        //     onMoveItem(this.items, oldIndex, newIndex);
        //     return;
        // }

        this.items = arrayMove(this.items.slice(), oldIndex, newIndex);
        this.updateForm();
    };

    save = async (objectId: string) => {
        objectId = objectId || this.objectId;
        if (this.onBeforeSave) {
            this.onBeforeSave(objectId, this);
        }

        if (!objectId) {
            return;
        }

        let { newItems, error } = await this._uploadItems(objectId);
        if (error) {
            this.isProcessing = false;
            this.errors = [error];
            this.updateForm();
            return;
        }

        if (this.isMultiple) {
            error = await this._saveOrder(objectId, newItems!);
            if (error) {
                this.isProcessing = false;
                this.errors = [error];
                this.updateForm();
                return;
            }
        }

        error = await this._deleteItems(objectId);
        if (error) {
            this.isProcessing = false;
            this.errors = [error];
            this.updateForm();
            return;
        }

        this.isLoading = true;
        this.updateForm();
        await sleep(1000);
        const items = await getItems(
            this.siteId,
            this.endpointId,
            this.fieldName,
            objectId,
            this.isMultiple ?? false
        );
        if (items) {
            this.items = items;
        }

        this.isLoading = false;
        this.isProcessing = false;
        this.saveProgressState = null;
        this.errors = [];
        this.updateForm();
    };
}

export default MediaManager;
