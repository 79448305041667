import { CustomEndpointConfig } from '../../../../core/config-builder/types';
import { getUserName } from '../../commonFunctions';

const gazarteaprofile: CustomEndpointConfig = {
    metaInfo: {
        properties: {
            gazarteaprofileCreatedAt: {
                name: 'Created At',
            },
            gazarteaprofileUpdatedAt: {
                name: 'Updated At',
            },
            elistmentPeriod: {
                name: 'Elistment Period',
                isRequired: true,
            },
            creditsTo: {
                name: 'Credits To',
                isRequired: true,
            },
            gazarteaprofilePhoto: {
                name: 'Photo',
            },
            gazarteaprofileActive: {
                name: 'Active',
            },
            gazarteaprofileUserId: {
                name: 'User',
                type: 'string',
                format: 'reference',
                siteId: 'cbc',
                endpointId: 'profile',
                idField: 'userId',
                sortListByField: 'firstname',
                searchByField: ['email', 'firstname', 'lastname', 'phone'],
                representationDataSource: getUserName,
            },
        },
        propertiesOrder: [
            'gazarteaprofileCreatedAt',
            'gazarteaprofileUpdatedAt',
            'gazarteaprofileActive',
            'gazarteaprofileUserId',
        ],
    },
    forms: {
        item: {
            views: {
                gazarteaprofileCreatedAt: {
                    isDisabled: true,
                },

                gazarteaprofileUpdatedAt: {
                    isDisabled: true,
                },
            },
        },
    },
};

export default gazarteaprofile;
