import copy from 'copy-to-clipboard';
import { CustomEndpointConfig } from '../../../../core/config-builder/types';
const getLink = (item: any) => {
    return `https://miniapps.pazamapp.com/caveret/#/category/${item.categoryId}/product/${item._id}`;
};

const product: CustomEndpointConfig = {
    title: 'מוצרים',
    forms: {
        list: {
            columns: {
                openProductsButton: {
                    id: 'copyPrductUrl',
                    type: 'button',
                    text: 'העתק לינק',
                    onClick(item: any) {
                        copy(getLink(item));

                        this.form.notify({
                            text: 'Link copied!',
                            lifetimeMs: 3000,
                        });
                    },
                },
            },
        },
    },
};

export default product;
