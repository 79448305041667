import { CustomEndpointConfig } from '../../../../core/config-builder/types';
import forms from './forms';

const cover: CustomEndpointConfig = {
    title: 'קאברים',
    metaInfo: {
        propertiesOrder: [
            'photo',
            'startDate',
            'endDate',
            'url',
            'editionId',
            'ordering',
        ],
        properties: {
            startDate: {
                name: 'Start date',
                isRequired: true,
            },
            endDate: {
                name: 'End date',
                isRequired: true,
            },
        },
    },

    forms,
};

export default cover;
