import { CustomEndpointConfig } from '../../../../core/config-builder/types';

const food: CustomEndpointConfig = {
    metaInfo: {
        properties: {
            photo: {
                type: 'string',
                format: 'imageUrl',
            },
            active: {
                type: 'boolean',
            },
        },
    },
};

export default food;
