import React from 'react';
import propsWithDefaultValues from '../../../utils/propsWithDefaultValues';
import Table from '../../native/table';
import dialogData from '../url-input-with-params/params-dialog';
import useMemoryTableState from './hooks';
import PropsInnerItems, { defaultProps } from './props';
import style from './style.module.scss';
const TableComponent: any = Table; //FIXME: Hack

const InnerItems = (props: PropsInnerItems) => {
    props = propsWithDefaultValues(props, defaultProps);
    const {
        columns,
        items,
        isHidden,
        selection,
        onChangeSelection,
        onBeforeLoadReferences,
        rowStyle,
        rowClassName,
        onAddNewItem,
        onClickItem,
    } = useMemoryTableState({ ...props, dialogData });

    const { isSelectable } = props;

    if (isHidden) {
        return null;
    }

    return (
        <div className={style.innerItems}>
            <div className={style.commandPanel}>
                <div className={style.label}>{props.label}</div>
                <div className={style.buttons}>
                    <button className={style.addButton} onClick={onAddNewItem}>
                        Add a subitem
                    </button>
                </div>
            </div>
            <div className={style.tableContainer}>
                <TableComponent
                    isSelectable={isSelectable}
                    columns={columns}
                    pageSize={Number.MAX_VALUE}
                    selection={selection}
                    page={0}
                    onClickRow={onClickItem}
                    onChangeSelection={onChangeSelection}
                    items={items}
                    rowStyle={rowStyle}
                    dialogData={dialogData}
                    rowClassName={rowClassName}
                    onBeforeLoadReferences={onBeforeLoadReferences}
                />
            </div>
        </div>
    );
};

export default React.memo(InnerItems);
