export interface Action<Data = any, Store = any> {
    (data: Data): (store: Store) => Promise<void>;
}

export interface Reducer<State = any> {
    (data: any): (prevState: State) => State;
}

export interface SubstoreDefinition {
    actions?: Record<string, Action>;
    state: any;
    reducers?: Record<string, Reducer>;
}

export interface GeneralStoreDefinition {
    [key: string]: SubstoreDefinition;
}

export type StoreInstance<Definition extends GeneralStoreDefinition> = {
    [SubStoreKey in keyof Definition]: {
        name: string;
        state: Definition[SubStoreKey]['state'];
        getState: () => Definition[SubStoreKey]['state'];
        dispatch: Definition[SubStoreKey]['actions'] &
            Definition[SubStoreKey]['reducers'];
    };
};

export function initStore<
    StoreDefinition extends GeneralStoreDefinition,
    Store extends Record<keyof StoreDefinition, any>
>(
    definition: StoreDefinition,
    refDispatch: React.MutableRefObject<any>,
    refLastStore: React.MutableRefObject<Store>,
    refHistory: React.MutableRefObject<any>
): Store {
    const isDebugging = process.env.NODE_ENV !== 'production';
    const store: any = {
        routerHistory: {
            state: null,
            name: 'routerHistory',
            dispatch: {
                push: (path: string) => refHistory.current.push(path),
                replace: (path: string) => refHistory.current.replace(path),
                goBack: () => refHistory.current.goBack(),
            },
        },
    };

    const mapReducersToDispatchers = (
        substoreKey: string,
        subStoreDefinition: SubstoreDefinition,
        refDispatch: any,
        refLastStore: { current: Store }
    ) => {
        return Object.getOwnPropertyNames(
            subStoreDefinition.reducers ?? {}
        ).reduce((acc: any, reducerKey: string) => {
            const substoreReducer = subStoreDefinition.reducers![reducerKey];

            if (typeof substoreReducer !== 'function') {
                debugger;
            }

            acc[reducerKey] = (data: any) => {
                const dispatch = refDispatch.current;
                const generatedReducer = (prevStore: any): Store => {
                    const prevState = refLastStore.current;
                    const oldSubstoreState = prevStore[substoreKey].state;
                    const nextState = substoreReducer(data)(oldSubstoreState);
                    const nextStore = {
                        ...prevState,
                        [substoreKey]: {
                            ...prevState[substoreKey],
                            state: nextState,
                        },
                    };

                    refLastStore.current = nextStore;
                    if (isDebugging) {
                        console.group(
                            `%cSTORE->REDUCER: ${substoreKey}.${reducerKey}`,
                            'font-weigh: bold; text-decoration: underline'
                        );
                        console.log(
                            '%cprevState',
                            'color: #F44336; font-weight: bold',
                            prevState
                        );
                        console.log(
                            '%cparams',
                            'color: #2196F3; font-weight: bold',
                            data
                        );
                        console.log(
                            '%cnextState',
                            'color: #4CAF50; font-weight: bold',
                            nextStore
                        );
                        console.groupEnd();
                    }
                    return nextStore;
                };

                dispatch(generatedReducer);
            };
            return acc;
        }, {});
    };

    const mapThunksToDispatchers = (
        subStoreDefinition: SubstoreDefinition,
        substoreKey: any,
        store: any
    ) => {
        return Object.getOwnPropertyNames(
            subStoreDefinition.actions ?? {}
        ).reduce((acc: any, thunkKey: string) => {
            const thunk = subStoreDefinition.actions![thunkKey];
            if (typeof thunk !== 'function') {
                debugger;
            }

            acc[thunkKey] = async (data: any) => {
                if (isDebugging) {
                    console.group(
                        `%cSTORE->THUNK: ${substoreKey}.${thunkKey}`,
                        'font-weight: bold; text-decoration: underline;',
                        data
                    );
                }
                await thunk(data)(store);
                if (isDebugging) {
                    console.groupEnd();
                }
            };
            return acc;
        }, {});
    };

    Object.getOwnPropertyNames(definition).forEach((substoreKey) => {
        const subStoreDefinition = definition[substoreKey];

        store[substoreKey] = {
            name: substoreKey,
            state: subStoreDefinition.state,
            getState: () => {
                const subStore = refLastStore.current[substoreKey];
                return subStore.state;
            },
            dispatch: {
                ...mapReducersToDispatchers(
                    substoreKey,
                    subStoreDefinition,
                    refDispatch,
                    refLastStore
                ),
                ...mapThunksToDispatchers(
                    subStoreDefinition,
                    substoreKey,
                    store
                ),
            },
        };
    });

    return store;
}
