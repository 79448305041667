import { CustomSiteConfig } from '../../../core/config-builder/types';
import video from './video';

const pazamtv: CustomSiteConfig = {
    title: 'Pazam TV',
    endpoints: {
        video,
    },
};

export default pazamtv;
