import { CustomListFormConfig } from '../../../../../../core/config-builder/forms/list/types';
import handlers from './handlers';

const listFormConfig: CustomListFormConfig = {
    handlers,
    columns: {
        city: {
            updateInPlace: false,
        },
        subLocation: {
            updateInPlace: false,
        },
        mapCoords: {
            updateInPlace: false,
        },
    },
};

export default listFormConfig;
