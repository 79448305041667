import React from 'react';
import { useGlobalStoreObserver } from '../../globalStore/definition';
import ChangePasswordDialog from './change-password-dialog';
import FormColumn from './form-column';
import LinksColumn from './links-column';
import PropsLoginPage from './props';
import style from './style.module.scss';

const LoginPage = (props: PropsLoginPage) => {
    const { title } = props;

    const [{ changePassword, isChangePasswordDialogOpened, token }] =
        useGlobalStoreObserver((store) => {
            const changePassword = store.account.dispatch.changePassword;
            const isChangePasswordDialogOpened =
                !!store.account.state.tokenForChangingPassword;
            const token = store.account.state.tokenForChangingPassword;

            return {
                changePassword,
                isChangePasswordDialogOpened,
                token,
            };
        });

    return (
        <div className={style.pageContainer}>
            <LinksColumn />
            <FormColumn title={title} />
            {isChangePasswordDialogOpened && (
                <ChangePasswordDialog
                    changePassword={changePassword}
                    token={token}
                />
            )}
        </div>
    );
};

export default LoginPage;
