import { CSSProperties } from 'react';
import {
    HandlerFunction,
    HandlerValue,
    ValueOrHandler,
} from '../context/types';
import style from './style.module.scss';
interface PropsButton {
    id: string;
    style?: CSSProperties;
    isHidden?: boolean | string | HandlerFunction;
    isDisabled?: boolean | string | HandlerFunction;
    role?: 'active' | 'notActive';
    text: ValueOrHandler<string>;
    onClick: HandlerValue<(viewId: string) => void>;
    image?: string;
    className?: string;
}

export default PropsButton;

export const defaultProps: Partial<PropsButton> = {
    role: 'active',
    style: {},
    className: style.button,
};
