import { CSSProperties } from 'react';
import { ValueOrHandler, View } from '../context/types';
import style from './style.module.scss';

interface PropsVStack {
    id: string;
    items: View[];
    style?: CSSProperties;
    className?: string;
    isHidden?: ValueOrHandler<boolean>;
    isLoading?: ValueOrHandler<boolean>;
    progressText?: string;
}

export default PropsVStack;

export const defaultProps: Partial<PropsVStack> = {
    className: style.verticalGroup,
    isLoading: false,
};
