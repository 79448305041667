import { AppContext, Form } from '../../../../components/form/context/types';
import ViewTypes from '../../../../components/form/ViewTypes';
import handlers from './handlers';

export function getUserName(profileData: any) {
    if (!profileData) {
        return 'NOT FOUND';
    }

    if (profileData.firstname) {
        if (!profileData.lastname) {
            return profileData.firstname;
        }

        return `${profileData.firstname} ${profileData.lastname}`;
    }
    if (profileData.fullName) {
        return profileData.fullName;
    }

    if (profileData.email) {
        return profileData.email;
    }

    if (profileData.phone) {
        return profileData.phone;
    }

    return profileData.username;
}

const ledgerForm: Form = {
    id: 'ledgerForm',
    type: ViewTypes.Form,
    fields: {
        endpointId: '',
        selection: 'none',
    },
    handlers,
    content: {
        id: 'rootGroup',
        type: ViewTypes.VStack,
        items: [
            {
                id: `commandPanel`,
                type: ViewTypes.HStack,
                items: [
                    {
                        id: `searchInput`,
                        type: ViewTypes.SearchInput,
                        dataSource: 'url.queryParams.search.replace',
                    },
                    {
                        id: `deleteButton`,
                        type: ViewTypes.Button,
                        text: 'Delete items',
                        onClick: 'onOpenDeleteItemsDialog',
                        role: 'notActive',
                        style: { marginRight: 10 },
                        isHidden(this: AppContext) {
                            const { currentUserData } = this;

                            const roles = ['admin', /[a-zA-Z0-9]+Admin$/];

                            if (!currentUserData || !currentUserData.roles) {
                                return true;
                            }

                            return !currentUserData.roles.some((role: string) =>
                                roles.some((expression: string | RegExp) => {
                                    if (expression instanceof RegExp) {
                                        return role.match(expression);
                                    }

                                    return role === expression;
                                })
                            );
                        },
                    },
                ],
            },
            {
                id: 'table',
                type: ViewTypes.DataTable,
                endpointId: 'ledger',
                pageSize: 15,
                sortDataSource: 'url.queryParams.sort.replace',
                filterDataSource: 'url.queryParams.filter.replace',
                searchDataSource: 'url.queryParams.search.replace',
                selectionDataSource: 'selection',
                pageDataSource: 'url.queryParams.page.replace',
                isSelectable: true,
                onClickItem: 'onClickItem',
                columns: [
                    {
                        id: 'siteid',
                        type: 'text',
                        title: 'Site Id',
                        fieldName: 'siteId',
                    },
                    {
                        id: 'transactionId',
                        type: 'text',
                        title: 'Transaction Id',
                        fieldName: 'transactionId',
                    },
                    {
                        id: 'name',
                        type: 'text',
                        title: 'Name',
                        fieldName: 'name',
                    },
                    {
                        id: 'productType',
                        type: 'enum',
                        title: 'Product type',
                        fieldName: 'productType',
                        values: [
                            {
                                value: 'default',
                                label: 'Default',
                            },
                            {
                                value: 'subscription',
                                label: 'Subscription',
                            },
                        ],
                    },
                    {
                        id: 'price',
                        type: 'number',
                        title: 'Price',
                        fieldName: 'price',
                    },
                    {
                        id: 'productId',
                        type: 'reference',
                        title: 'Product Id',
                        fieldName: 'productId',
                        endpointId: 'product',
                        representationDataSource: 'name',
                        getLink: (itemId: string, item: any) =>
                            `/${item.siteId}/ledger-product/${item.productId}/info`,
                    },
                    {
                        id: 'queryString',
                        type: 'text',
                        title: 'Query string',
                        fieldName: 'queryString',
                        isHidden: true,
                    },
                    {
                        id: 'userId',
                        type: 'reference',
                        title: 'User Id',
                        fieldName: 'userId',
                        endpointId: 'user',
                        representationDataSource: getUserName,
                        getLink: (itemId: string, item: any) =>
                            `/user/common/item/${item.userId}/info`,
                    },
                ],
            },
        ],
    },
};

export default ledgerForm;
