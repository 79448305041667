import { CustomItemFormConfig } from '../../../../../../core/config-builder/forms/item/types';

const item: CustomItemFormConfig = {
    contentPartialChange: {
        publishedAt: {
            label: 'Published At',
        },
    },
};
export default item;
