import React from 'react';
import ColumnFilter from './column-filter';
import useHeaderState from './hooks';
import PropsHeader from './props';
import SelectHeader from './select-header';
import style from './style.module.scss';

const defaultSelectColumnStyle = { width: 50, maxWidth: 50 };
const selectHeaderStyle = { width: 50, maxWidth: 50 };

const Header = (props: PropsHeader) => {
    const { isSelectable, selection, columns, sort, filter, searchItems } =
        props;

    const {
        openedFilterId,
        onChangeSort,
        onChangeFilter,
        onToggleAllItems,
        onToggleOpen,
    } = useHeaderState(props);

    return (
        <thead className={style.header}>
            <tr className={style.titleHeader}>
                {isSelectable && (
                    <td
                        key="col_title_select"
                        className={style.title}
                        style={defaultSelectColumnStyle}
                        // width={50}
                    />
                )}
                {columns.map((column, index) => (
                    <td
                        key={`col_title_${column.id}`}
                        // style={column.style}
                        className={style.title}
                        data-column-id={column.id}
                    >
                        {column.title}
                    </td>
                ))}
            </tr>
            <tr className={style.filterHeader}>
                {isSelectable && (
                    <td
                        style={selectHeaderStyle}
                        // width="50"
                    >
                        <SelectHeader
                            selection={selection}
                            onToggleAllItems={onToggleAllItems}
                        />
                    </td>
                )}
                {columns.map((column, columnIndex) => {
                    const columnId = column.id;
                    const columnSort = sort.find(
                        (item) => item.columnId === columnId
                    );
                    const filterItem = filter[columnId];

                    const isLastColumn = columnIndex === columns.length;
                    const isRightSide = isLastColumn;

                    return (
                        <ColumnFilter
                            key={`col_filter_${columnId}`}
                            sortDirection={columnSort && columnSort.direction}
                            column={column}
                            isRightSide={isRightSide}
                            isOpened={columnId === openedFilterId}
                            onToggleOpen={onToggleOpen}
                            onChangeSort={onChangeSort}
                            onChangeFilter={onChangeFilter}
                            filterData={filterItem}
                            searchItems={searchItems}
                        />
                    );
                })}
            </tr>
        </thead>
    );
};

export default React.memo(Header);
