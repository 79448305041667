import { AppContext } from '../../../../../../core/components/form/context/types';
import ViewTypes from '../../../../../../core/components/form/ViewTypes';
import {
    ItemFormFunctionGetHandlers,
    ItemFormHandlers,
} from '../../../../../../core/components/item-form/types';
import apiClient from '../../../../../../core/requests/api';

const handlers: ItemFormFunctionGetHandlers = function (
    this: AppContext
): ItemFormHandlers {
    const parseProductIdFromUrl = (url: string) => {
        if (!url) {
            return {
                isExternalLink: false,
                productId: null,
            };
        }

        const regex = /\/category\/[\S]+\/product\/([\S]+)/;
        const match = url.match(regex);

        const productId = match && match[1];
        return {
            isExternalLink: !regex.test(url),
            productId,
        };
    };

    const getProductUrlFrom = async (productId: string) => {
        const productData = await apiClient.getItemFromTable(
            'caveret',
            'product',
            productId
        );
        return `/category/${productData.categoryId}/product/${productId}`;
    };

    const initViews = () => {
        // Here item data already loaded
        const { productId, isExternalLink } = parseProductIdFromUrl(
            (this.form.object || {}).url
        );
        this.form.productId = productId;
        this.form.isExternalLink = isExternalLink;

        this.form.views.infoFieldsGroup.items = [
            ...this.form.views.infoFieldsGroup.items.filter(
                (item: any) => item.id !== 'url'
            ),
            {
                id: 'isExternalLink',
                type: ViewTypes.BooleanInput,
                dataSource: 'isExternalLink',
                label: 'Is External Link',
                onChangeValue: 'onChangeExternalLinkFlag',
            },
            {
                isHidden: isExternalLink,
                id: 'productInput',
                type: ViewTypes.ReferenceInput,
                siteId: 'caveret',
                endpointId: 'product',
                label: 'Product',
                dataSource: 'productId',
                representationDataSource: 'title',
                onChangeValue: 'onChangeProduct',
                isDisabled(this: AppContext) {
                    return !this.form.object.editionId;
                },
                rawSearchFilter(this: AppContext) {
                    return `editionId:"${this.form.object.editionId}"`;
                },
            },
            {
                isHidden: !isExternalLink,
                id: 'url',
                type: ViewTypes.TextInput,
                label: 'External link',
                ltr: true,
                dataSource: 'object.url',
            },
        ];
    };

    const onBeforeOpen = async () => {
        initViews();
    };

    const onChangeProduct = async (newProductId: string) => {
        this.form.productId = newProductId;
        this.form.object.url = await getProductUrlFrom(newProductId);

        this.form.views.productInput.isHidden = false;
        this.form.views.url.isHidden = true;
    };

    const onChangeExternalLinkFlag = (isExternalLink: boolean) => {
        this.form.views.url.isHidden = !isExternalLink;
        this.form.views.productInput.isHidden = isExternalLink;
    };

    return {
        onBeforeOpen,
        onChangeExternalLinkFlag,
        onChangeProduct,
    };
};

export default handlers;
