import { ItemFormConfig } from 'core/config-builder/forms/item/types';

const preset: ItemFormConfig = {
    title: 'רשימות פז״מ',
    isAllowed: ['admin', /[a-zA-Z0-9]+Admin$/],
    metaInfo: {
        properties: {
            title: {
                name: 'כותרת',
            },
            userId: {
                name: 'שם משתמש',
            },
            image: {
                name: 'תמונה',
            },
            itemCount: {
                name: 'מספר פריטים',
            },
            description: {
                name: 'פרטים',
            },
            createdAt: {
                name: 'נוצר ב',
            },
        },
    },
};

export default preset;
