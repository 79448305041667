import { SerializedSelection } from '../../../../../../utils/Selection';
import { ColumnBooleanFilterData } from '../props';

interface PropsColumnBooleanFilter {
    columnId: string;
    isOpened: boolean;
    isRightSide: boolean;
    filterData: ColumnBooleanFilterData | undefined;
    onToggleOpen: (columnId: string) => void;
    onChangeSelection: (
        columnId: string,
        newSelection: SerializedSelection
    ) => void;
}

export const values = [
    {
        value: 'true',
        label: 'True',
    },
    { value: 'false', label: 'False' },
];

export default PropsColumnBooleanFilter;
