import { CustomSiteConfig } from '../../../core/config-builder/types';
import article from './article';

const shakuf: CustomSiteConfig = {
    title: 'שקוף',
    endpoints: {
        article,
    },
};

export default shakuf;
