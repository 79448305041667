import React, { useEffect } from 'react';
import Input from '../../../../core/components/native/input';
import propsWithDefaultValues from '../../../../core/utils/propsWithDefaultValues';
import { getValue } from '../../../utils';
import { useAppContextObserver } from '../context';
import minisitesDialog from './minisites-dialog';
import paramsDialog from './params-dialog';
import PropsUrlInputWithParams, { defaultProps } from './props';
import style from './style.module.scss';

const UrlInputWithParams = (props: PropsUrlInputWithParams) => {
    props = propsWithDefaultValues(props, defaultProps);

    let {
        id,
        placeholder,
        label,
        displayError,
        onChangeValue,
        dataSource,
        paramsOptions,
        showMinisiteOptions,
    } = props;

    const [{ isHidden, isDisabled, value }, getContext] = useAppContextObserver(
        (context) => {
            let value = props.value;
            if (dataSource) {
                value = context.form.getDataSourceValue(dataSource);
            }

            return {
                value,
                isHidden: getValue(props.isHidden, context),
                isDisabled: getValue(props.isDisabled, context),
            };
        }
    );

    useEffect(() => {
        getContext().registerCustomDialog(paramsDialog);
        getContext().registerCustomDialog(minisitesDialog);
    }, [getContext]);

    if (isHidden) {
        return null;
    }

    const handleChangeValue = (value: string) => {
        const context = getContext();

        if (dataSource) {
            context.form.setDataSourceValue(dataSource, value);
        }

        if (!onChangeValue) {
            return;
        }

        if (typeof onChangeValue === 'string') {
            (context.form.handlers as any)[onChangeValue](value);
            return;
        }

        onChangeValue.call(context, value);
    };

    const onStartSelect = () => {
        getContext().form.pushDialog(paramsDialog.id, {
            url: value,
            paramsOptions,
            onClose: () => {
                getContext().form.closeDialog();
            },
            onSave: (url: string) => {
                getContext().form.closeDialog();
                handleChangeValue(url);
            },
        });
    };

    const onStartSelectMinisites = () => {
        getContext().form.pushDialog(minisitesDialog.id, {
            url: value,
            paramsOptions,
            onClose: () => {
                getContext().form.closeDialog();
            },
            onSave: (url: string) => {
                getContext().form.closeDialog();
                handleChangeValue(url);
            },
        });
    };

    return (
        <div className={style.urlInputWithParams} data-role="view">
            <Input
                id={id}
                ltr={true}
                isMultiLine={false}
                label={label}
                disabled={isDisabled}
                placeholder={placeholder}
                value={value || ''}
                onChange={(e: any) => {
                    handleChangeValue(e.target.value);
                }}
                displayError={displayError}
            />
            <div className={style.buttons}>
                <button className={style.selectButton} onClick={onStartSelect}>
                    Edit params
                </button>
                {showMinisiteOptions && (
                    <button
                        className={style.selectButton}
                        onClick={onStartSelectMinisites}
                    >
                        Minisites
                    </button>
                )}
            </div>
        </div>
    );
};

export default React.memo(UrlInputWithParams);
