import lodash from 'lodash';
import { RemoteMetaInfoSite } from '../types';

// FIXME: Rename to some local endpointData or delete this type
export interface EasyReadEndpointData {
    endpointId: string;
    constraintMethods: string[];
    anonymousType: 'block';
    schema: any;
    libs: any;
}

export function getSiteUpdateObject(
    siteData: SiteData,
    endpointData: EasyReadEndpointData // FIXME: Add definition
): RemoteMetaInfoSite {
    const {
        siteId,
        isUsersCanRegister,
        adminEmail,
        adminPassword,
        JWTSecret,
        registrationTypes,
    } = siteData;

    const { endpointId, constraintMethods, anonymousType, schema, libs } =
        endpointData;

    const data = {
        index: siteId,
        type: endpointId,
        constraint: {
            methods: constraintMethods,
        },
        admin: {
            email: adminEmail,
            password: adminPassword,
        },
        config: {
            libs,
            anonymousType,
        },
        siteConfig: {
            JWTSecret,
            protect: !isUsersCanRegister,
            registrationTypes,
        },
        schema: {
            ...schema,
        },
    };

    if (schema && schema.required && schema.required.length === 0) {
        delete data.schema.required;
    }

    // hack delete wrong data
    if (schema && schema.optioins) {
        delete data.schema.optioins;
    }

    return JSON.parse(JSON.stringify(data));
}

export interface SiteData {
    siteId: string;
    isUsersCanRegister: boolean;
    adminEmail?: string;
    adminPassword?: string;
    JWTSecret?: string;
    registrationTypes: string[];
}

export function getSiteDataFromMetaInfo(
    siteMetaInfo: RemoteMetaInfoSite
): SiteData {
    return {
        siteId: siteMetaInfo.indexId,
        isUsersCanRegister: !siteMetaInfo.protect,
        adminEmail: siteMetaInfo.admin && siteMetaInfo.admin.email,
        adminPassword: siteMetaInfo.admin && siteMetaInfo.admin.password,
        JWTSecret: siteMetaInfo.JWTSecret,
        registrationTypes: siteMetaInfo.registrationTypes,
    };
}

// FIXME: Add types
export function getEndpointDataFromMetaInfo(
    siteMetaInfo: RemoteMetaInfoSite,
    endpointId: string
) {
    const endpointMetaInfo = siteMetaInfo.endpoints[endpointId];
    const { constraint, anonymousType, libs, schema } = endpointMetaInfo;
    return {
        endpointId,
        constraintMethods: (constraint && constraint.methods) || [],
        anonymousType,
        schema,
        libs,
    };
}

// FIXME: Add types
export const getDefaultEndpointData = (endpointId: string): any => {
    const title = lodash.upperCase(endpointId);
    const data = {
        endpointId,
        constraintMethods: [],
        anonymousType: 'block',
        schema: {
            title,
            description: title,
            properties: {},
        },
    };

    addDefaultValuesToEndpointData(data);

    return data;
};

// FIXME: Add types
export const addDefaultValuesToEndpointData = (endpointData: any): any => {
    endpointData.schema = endpointData.schema || {};
    endpointData.schema.properties = endpointData.schema.properties || {};

    if (!endpointData.schema.options) {
        endpointData.schema.options = {
            disable_properties: true,
            disable_edit_json: true,
            disable_collapse: true,
        };
    }

    // if (!endpointData.schema.properties.createdAt) {
    //     endpointData.schema.properties.createdAt = {
    //         title: 'createdAt',
    //         type: 'string',
    //         format: 'datetime-local',
    //     };
    // }

    // if (!endpointData.schema.properties.updatedAt) {
    //     endpointData.schema.properties.updatedAt = {
    //         title: 'updatedAt',
    //         type: 'string',
    //         format: 'datetime-local',
    //     };
    // }

    if (!endpointData.schema.properties.files) {
        endpointData.schema.properties.files = {
            title: 'files',
            type: 'array',
            format: 'string',
            options: {
                hidden: true,
            },
            items: {
                type: 'object',
                properties: {
                    ext: {
                        type: 'string',
                    },
                    mime: {
                        type: 'string',
                    },
                    nameId: {
                        type: 'string',
                    },
                    id: {
                        type: 'string',
                    },
                    URL: {
                        type: 'string',
                    },
                },
            },
        };
    }
};
