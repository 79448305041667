import React from 'react';
import Lottie from 'react-lottie';
import animationData from '../../../../assets/animations/loading.json';
import style from './style.module.scss';

const lottieOptions = {
    loop: true,
    autoplay: true,
    animationData,
    rendererSettings: {
        preserveAspectRatio: 'xMidYMid slice',
    },
};

const LoadingStub = () => (
    <div className={style.loadingStub}>
        <Lottie options={lottieOptions} height={100} width={100} />
    </div>
);

export default React.memo(LoadingStub);
