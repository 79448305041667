import React from 'react';
import HamburgerMenu from 'react-hamburger-menu';
import { matchPath, useHistory, useLocation } from 'react-router-dom';
import { version } from '../../../../package.json';
import { useGlobalStoreObserver } from '../../globalStore/definition';
import LogOutButton from './log-out';
import Logo from './logo';
import PropsSidebar from './props';
import style from './style.module.scss';

function getUserName({ firstName, lastName, username }: any): string {
    if (firstName || lastName) {
        return `${firstName} ${lastName}`.trim();
    }

    return username;
}

function isPathMatch(
    pathname: string,
    match: any,
    exactMatch: boolean
): boolean {
    let path = pathname.replace(/^#/g, '');
    if (path.includes('?')) {
        path = path.split('?')[0];
    }

    if (!path.endsWith('/')) {
        path += '/';
    }

    const matchData = matchPath(path, {
        path: match,
        exact: exactMatch,
    });

    return !!matchData;
}

const Sidebar = (props: PropsSidebar) => {
    const history = useHistory();
    const location = useLocation();

    const [
        { logout, toggleSidebar, currentUserData, isSidebarOpened },
        getStore,
    ] = useGlobalStoreObserver((store) => {
        const { logout } = store.account.dispatch;
        const { toggleSidebar } = store.app.dispatch;
        const currentUserData = store.account.state;
        const { isSidebarOpened } = store.app.state;

        return { logout, toggleSidebar, currentUserData, isSidebarOpened };
    });

    const userName = getUserName(currentUserData);

    const { items } = props;

    const onOpenNewSiteForm = (e: any) => {
        e.stopPropagation();
        history.push('/new/site-config-editor/settings');
    };

    const onLogout = () => {
        logout({ redirectToLogin: true });
    };

    const displayItems = items.map((siteItem, index) => {
        const menuItems = [];

        let isSiteVisible = true;
        if (siteItem.isVisible && typeof siteItem.isVisible === 'function') {
            isSiteVisible = siteItem.isVisible.call({
                currentUserData,
                store: getStore(),
            });
        }

        const isEndpointVisible = (endpointItem: any) => {
            if (
                endpointItem.isVisible &&
                typeof endpointItem.isVisible === 'function'
            ) {
                return endpointItem.isVisible.call({
                    currentUserData,
                    store: getStore(),
                });
            }

            return true;
        };

        const visibleEndpointItems =
            siteItem?.items?.filter(isEndpointVisible) ?? [];

        isSiteVisible &&
            menuItems.push(
                <MenuLink
                    key={index}
                    className={
                        siteItem.isLoading
                            ? style.siteItemLoading
                            : style.siteItem
                    }
                    to={siteItem.to}
                    label={siteItem.title}
                    exactMatch={siteItem.exact}
                    match={siteItem.match}
                    refreshListFromSidebar={siteItem.refreshListFromSidebar}
                    toggleSidebar={
                        visibleEndpointItems.length > 0
                            ? undefined
                            : toggleSidebar
                    }
                />
            );

        isSiteVisible &&
            isPathMatch(location.pathname, siteItem.match, siteItem.exact) &&
            visibleEndpointItems &&
            visibleEndpointItems.length > 0 &&
            menuItems.push(
                <ul className={style.endpoints} key={`endpoint-item-${index}`}>
                    {siteItem.items
                        .filter(isEndpointVisible)
                        .map((endpointItem: any, index: number) => (
                            <MenuLink
                                key={index}
                                className={style.endpointItem}
                                to={endpointItem.to}
                                label={endpointItem.title}
                                exactMatch={endpointItem.exact}
                                match={endpointItem.match}
                                refreshListFromSidebar={
                                    endpointItem.refreshListFromSidebar
                                }
                                toggleSidebar={toggleSidebar}
                            />
                        ))}
                </ul>
            );

        return menuItems;
    });

    return (
        <>
            <div className={style.sidebar} data-is-opened={!!isSidebarOpened}>
                <Logo userName={userName} />
                <div className={style.items}>
                    <div>{displayItems}</div>
                </div>
                {currentUserData &&
                    currentUserData.roles.some(
                        (role: string) => role === 'admin'
                    ) && (
                        <button
                            className={style.createNewSiteButton}
                            onClick={onOpenNewSiteForm}
                        >
                            Create new site
                        </button>
                    )}
                <LogOutButton onClick={onLogout} />
                <div className={style.version}>version: {version}</div>
            </div>
            <div className={style.hamburgerMenu}>
                <HamburgerMenu
                    isOpen={isSidebarOpened}
                    menuClicked={toggleSidebar}
                    width={18}
                    height={12}
                    strokeWidth={2}
                    rotate={0}
                    color="black"
                    borderRadius={0}
                    animationDuration={0.5}
                />
            </div>
        </>
    );
};

const MenuLink = React.memo(
    ({
        id,
        label,
        to,
        className = style.siteItem,
        match,
        exactMatch,
        refreshListFromSidebar,
        toggleSidebar,
    }: any) => {
        const history = useHistory();
        const location = useLocation();
        const isMatch = isPathMatch(location.pathname, match, exactMatch);
        return (
            <li
                className={className}
                data-active={isMatch}
                id={`sidebar-item-${id}`}
                data-path={to}
                onClick={(e) => {
                    if (
                        window._refreshList &&
                        isMatch &&
                        refreshListFromSidebar
                    ) {
                        e && e.stopPropagation();
                        e && e.preventDefault();
                        window._refreshList();
                    } else {
                        history.push(to);
                        toggleSidebar && toggleSidebar();
                    }
                }}
            >
                <div>
                    <span>{label}</span>
                </div>
            </li>
        );
    }
);

export default React.memo(Sidebar);
