import React from 'react';
import Select, { components } from 'react-select';
import propsWithDefaultValues from '../../../utils/propsWithDefaultValues';
import PropsSelectInput, { defaultProps } from './props';

const CreatableSelect = require('react-select').Creatable;

const DropdownIndicator = (props: any) => {
    return (
        components.DropdownIndicator && (
            <components.DropdownIndicator {...props}>
                <i className="ion ion-ios-arrow-down" />
            </components.DropdownIndicator>
        )
    );
};

const dropDownIndicator = { DropdownIndicator };

const SelectInput = (props: PropsSelectInput) => {
    props = propsWithDefaultValues(props, defaultProps);
    const {
        isDisabled,
        isMultiple,
        label,
        displayError,
        options,
        onChange,
        classContainer,
        classLabel,
        isCreatable,
    } = props;
    let value;
    if (isMultiple) {
        value = options.filter(({ value: optionValue }) =>
            (props.value || []).includes(optionValue)
        );
    } else {
        value = options.find((item) => item.value === props.value);
    }

    let SelectComponent = Select;
    if (isCreatable) {
        SelectComponent = CreatableSelect;
    }

    return (
        <div
            data-role="view"
            className={classContainer}
            data-is-multiple={!!isMultiple}
            data-error={!!displayError}
        >
            <label className={classLabel}>{label}</label>
            <SelectComponent
                isMulti={isMultiple}
                isDisabled={isDisabled}
                value={value || null}
                options={options}
                onChange={onChange as any}
                classNamePrefix={
                    displayError ? 'select-input-error' : 'select-input'
                }
                data-error={!!displayError}
                components={dropDownIndicator}
            />
        </div>
    );
};

export default React.memo(SelectInput);
