import { SortDirection } from '../header-sort/props';
import { ColumnDateFilterData } from '../props';

interface PropsDateFilter {
    columnId: string;
    format?: string;
    filterData?: ColumnDateFilterData;
    isOpened: boolean;
    onToggleOpen: (columnId: string) => void;
    sortDirection: SortDirection | undefined;
    isSortable: boolean;
    isRightSide: boolean;
    onChangeSort: (
        columnId: string,
        newDirection: SortDirection,
        multiColumn: boolean
    ) => void;
    onChangeFilter: (
        columnId: string,
        newFilterData: ColumnDateFilterData | null
    ) => void;
}

export default PropsDateFilter;

export const defaultProps: Partial<PropsDateFilter> = {
    format: 'DD-MM-YYYYTHH:mm',
};
