import React, { useMemo } from 'react';
import { getValue } from '../../../utils';
import TextAreaInput from '../../native/html-input';
import { useAppContextObserver } from '../context';
import PropsHtmlInput from './props';

const HtmlInput = (props: PropsHtmlInput) => {
    const { onChangeValue, label, placeholder, dataSource, displayError } =
        props;

    const [{ isHidden, isDisabled, value }, getContext] = useAppContextObserver(
        (context) => {
            let { value } = props;
            if (dataSource) {
                value = context.form.getDataSourceValue(dataSource);
            }

            return {
                isHidden: getValue(props.isHidden, context),
                isDisabled: getValue(props.isDisabled, context),
                value,
            };
        }
    );

    const into = useMemo(() => {
        return document.getElementById(props.into || 'root');
    }, [props.into]);

    if (isHidden) {
        return null;
    }

    const _handleChangeValue = (value: any) => {
        if (dataSource) {
            getContext().form.setDataSourceValue(dataSource, value);
        }

        if (!onChangeValue) {
            return;
        }

        if (typeof onChangeValue === 'string') {
            (getContext().form as any).handlers[onChangeValue](value);
            return;
        }

        onChangeValue.call(getContext(), value);
    };

    return (
        <TextAreaInput
            into={into}
            isInEditMode={!isDisabled}
            label={label}
            disabled={isDisabled}
            placeholder={placeholder}
            value={value ?? ''}
            onChange={_handleChangeValue}
            displayError={!!displayError}
        />
    );
};

export default HtmlInput;
