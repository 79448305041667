import React, { useState } from 'react';
import HtmlEditor from './editor';
import PropsHtmlInput from './props';
import style from './style.module.scss';

const TextAreaInput = ({
    isInEditMode,
    label,
    value,
    onChange,
    into,
    placeholder,
}: PropsHtmlInput) => {
    const [showEditor, setShowEditor] = useState(false);

    const _handleShowEditor = () => {
        setShowEditor(true);
    };

    const _handleHideEditor = () => {
        setShowEditor(false);
    };

    return (
        <div className={style.container} id="textarea" data-role="view">
            <label className={style.label}>{label}</label>
            <div
                className={style.textarea}
                onClick={_handleShowEditor}
                dangerouslySetInnerHTML={{ __html: value ?? placeholder }}
            />
            {showEditor && (
                <HtmlEditor
                    into={into}
                    value={value}
                    onChange={onChange}
                    onClose={_handleHideEditor}
                />
            )}
        </div>
    );
};

export default React.memo(TextAreaInput);
