import React from 'react';
import PageHeaderItem from './page-header-item';
import PropsHeader from './props';
import style from './style.module.scss';

const Header = (props: PropsHeader) => {
    const { subtitle, title, items } = props;

    return (
        <div className={style.pageHeader}>
            {title && <div className={style.headerTitle}>{title}</div>}
            {subtitle && <div className={style.headerSubtitle}>{subtitle}</div>}
            {items &&
                items.map((item, index) => (
                    <PageHeaderItem {...item} key={index} />
                ))}
        </div>
    );
};

export default React.memo(Header);
