import deleteIcon from '../../../../../core/assets/icons/trash-outline.svg';
import { AppContext } from '../../../../../core/components/form/context/types';
import defaultHandlers from '../../../../../core/config-builder/forms/item/handlers';
import content from './content';
import handlers from './handlers';

const userListForm = {
    id: 'user-list-form',
    title: 'User Management',
    fields: {
        selection: 'none',
    },
    commands: [
        {
            id: 'deleteItem',
            text: 'Delete',
            onClick: 'onOpenDeleteItemsDialog',
            image: deleteIcon,
        },
    ],
    handlers(this: AppContext) {
        return {
            ...defaultHandlers.call(this),
            ...handlers.call(this),
        };
    },
    content,
};

export default userListForm;
