import { CSSProperties } from 'react';
import { DataSourcePath, HandlerValue } from '../context/types';
import style from './style.module.scss';

interface PropsText {
    id: string;
    onClick?: HandlerValue<(viewId: string) => void>;
    isHidden?: string | boolean | Function;
    style?: CSSProperties;
    className?: string;
    value?: string;
    dataSource?: DataSourcePath<string>;
}

export default PropsText;

export const defaultProps: Partial<PropsText> = {
    style: {},
    className: style.text,
};
