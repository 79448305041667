import React from 'react';
import Checkbox from '../../../../checkbox';
import { CheckBoxState } from '../../../../checkbox/props';
import useBooleanFilterState from './hooks';
import PropsColumnBooleanFilter, { values } from './props';
import style from './style.module.scss';

const Value = React.memo(({ value, label, onToggleItem, selection }: any) => {
    return (
        <div key={value} className={style.item}>
            <Checkbox
                state={
                    selection.isItemSelected(value) ? 'checked' : 'unchecked'
                }
                onToggle={onToggleItem(value)}
            />
            <span>{label}</span>
        </div>
    );
});

const AllValuesRow = React.memo(({ selection, onToggleAll }: any) => {
    let allCheckboxState: CheckBoxState = 'unchecked';

    if (selection.isAllItemsSelected()) {
        allCheckboxState = 'checked';
    } else if (selection.isInfinite() || selection.getNumberOfSelectedItems()) {
        allCheckboxState = 'intermediate';
    }

    return (
        <div key="all" className={style.item}>
            <Checkbox state={allCheckboxState} onToggle={onToggleAll} />
            <span>All</span>
        </div>
    );
});

const BooleanFilter = (props: PropsColumnBooleanFilter) => {
    const { isOpened, onToggleOpen, isRightSide, columnId } = props;
    const { isFiltered, selection, onToggleAll, onToggleItem, onApply } =
        useBooleanFilterState(props);

    return (
        <div className={style.enumFilter}>
            <div
                className={
                    isOpened ? style.currentValueOpened : style.currentValue
                }
                onClick={() => onToggleOpen(columnId)}
            >
                {!isFiltered && 'All'}
                {isFiltered && <span className={style.filtered}>Filtered</span>}
            </div>
            {isOpened && (
                <div
                    className={isOpened ? style.menuOpened : style.menu}
                    data-is-right-side={String(!!isRightSide)}
                >
                    <div className={style.items}>
                        <AllValuesRow
                            selection={selection}
                            onToggleAll={onToggleAll}
                        />
                        {values.map((value: any, index: number) => (
                            <Value
                                {...value}
                                key={index}
                                onToggleItem={onToggleItem}
                                selection={selection}
                            />
                        ))}
                    </div>
                    <div className={style.buttons}>
                        <div className={style.applyButton} onClick={onApply}>
                            Apply
                        </div>
                        <div
                            className={style.cancelButton}
                            onClick={() => onToggleOpen(columnId)}
                        >
                            Cancel
                        </div>
                    </div>
                </div>
            )}
        </div>
    );
};

export default React.memo(BooleanFilter);
