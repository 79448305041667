import { createHashHistory } from 'history';
import React, { useEffect } from 'react';
import ReactDOM from 'react-dom';
import { Router, useLocation, withRouter } from 'react-router-dom';
import { CustomAppConfig } from './config-builder/types';
import { GlobalStoreProvider } from './globalStore/definition';
import * as serviceWorkerRegistration from './serviceWorkerRegistration';
import './style/index.scss';

const history = createHashHistory();
window._AUTH_TOKEN = localStorage.getItem('authToken') ?? undefined;

const ScrollToTopClass = (props: any) => {
    const location = useLocation();
    useEffect(() => {
        window.scrollTo(0, 0);
    }, [location.pathname]);

    return props.children;
};

const ScrollToTop = withRouter(ScrollToTopClass);

let root: any;
export function renderApp(customAppConfig: CustomAppConfig) {
    const App = require('./containers/app').default;

    window._CUSTOM_APP_CONFIG = customAppConfig;

    root = ReactDOM.render(
        <Router history={history}>
            <GlobalStoreProvider>
                <ScrollToTop>
                    <App
                        config={customAppConfig}
                        // config={mergedConfig}
                    />
                </ScrollToTop>
            </GlobalStoreProvider>
        </Router>,
        document.getElementById('root'),
        root
    );

    // in development, set up HMR:
    if (module.hot) {
        module.hot.accept('./containers/app', () => {
            requestAnimationFrame(() => renderApp(customAppConfig));
        });
    }
}

serviceWorkerRegistration.register({});
