import React, { useCallback } from 'react';
import { Column } from '../../types';
import Cell from './cell';
import CellSelect from './cell-select';
import PropsTableRow, { CallbackRowParams, defaultProps } from './props';
import style from './style.module.scss';

const selectColumnStyle = { width: 50, maxWidth: 50 };
const Row = (props: PropsTableRow) => {
    const {
        tableId,
        isSelected,
        isSelectable,
        onChangeSelection,
        item,
        columns,
        pageReferenceValues,
        onClick,
        onUpdateItemField,
        page,
        pageSize,
        index,
        quickEditCell,
        onSetQuickEditCell,
        idField,
    } = props;
    let {
        className = defaultProps.className,
        style: customStyle = defaultProps.style,
    } = props;

    className = className || style.row;

    const globalRowIndex = page * pageSize + index;
    const pageRowIndex = index;

    const params: CallbackRowParams = {
        item,
        isSelected,
        isSelectable,
        columns,
        pageReferenceValues,
        globalRowIndex,
        pageRowIndex,
        page,
        pageSize,
    };

    if (typeof className === 'function') {
        className = className(Object.freeze(params));
    }

    if (typeof customStyle === 'function') {
        customStyle = customStyle(Object.freeze(params));
    }

    const onStartQuickEdit = useCallback(
        (cellId: string | null) => (e: any) => {
            e && e.stopPropagation();
            onSetQuickEditCell(cellId);
        },
        [onSetQuickEditCell]
    );

    const onCancelQuickEdit = useCallback(
        (cellId: string) => {
            if (quickEditCell === cellId) {
                onSetQuickEditCell(null);
            }
        },
        [onSetQuickEditCell, quickEditCell]
    );

    const onChange = useCallback(
        (column: Column) =>
            async (value: any): Promise<void> => {
                return onUpdateItemField({
                    item,
                    column,
                    value,
                    prevValue: value,
                });
            },
        [item, onUpdateItemField]
    );

    return (
        <tr
            key={item.id}
            className={className}
            onClick={onClick}
            style={customStyle}
        >
            {isSelectable && (
                <td key={`col_${item.id}_select`} style={selectColumnStyle}>
                    <CellSelect
                        isSelected={isSelected}
                        onToggle={(state) =>
                            onChangeSelection(item.id, state === 'checked')
                        }
                    />
                </td>
            )}
            {columns.map((column: Column, index: number) => {
                // FIXME: idField?
                const cellId = `${tableId}-cell-${item.id}-${column.id}`;

                return (
                    <Cell
                        isQuickEditActive={cellId === quickEditCell}
                        tableId={tableId}
                        key={index}
                        column={column}
                        cellId={cellId}
                        item={item}
                        pageReferenceValues={pageReferenceValues}
                        onCancelQuickEdit={onCancelQuickEdit}
                        onStartQuickEdit={onStartQuickEdit}
                        updateValue={onChange(column)}
                        itemIdField={idField}
                    />
                );
            })}
        </tr>
    );
};

export default React.memo(Row);
