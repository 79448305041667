import { CustomSiteConfig } from '../../../core/config-builder/types';
import gamequestion from './gamequestion';
import gamescoreboard from './gamescoreboard';
import gamestage from './gamestage';
import lead from './lead';
import survey from './survey';
import user from './user';

const kidum: CustomSiteConfig = {
    title: 'ממשק קידום',
    endpoints: {
        gamestage,
        gamescoreboard,
        gamequestion,
        lead,
        survey,
        user,
    },
};

export default kidum;
