import * as _ from 'lodash';
import copyIcon from '../../../assets/icons/copy-outline.svg';
import exportIcon from '../../../assets/icons/download-outline.svg';
import importIcon from '../../../assets/icons/push-outline.svg';
import refreshIcon from '../../../assets/icons/refresh-outline.svg';
import endpointConfigIcon from '../../../assets/icons/settings-outline.svg';
import deleteIcon from '../../../assets/icons/trash-outline.svg';
import { AppContext, HStack } from '../../../components/form/context/types';
import { ContextMenuItem } from '../../../components/form/data-table/props';
import {
    Column,
    ColumnColor,
    ColumnEnum,
    ColumnReference,
} from '../../../components/form/form-table-types';
import ViewTypes from '../../../components/form/ViewTypes';
import ListForm from '../../../components/list-form';
import { toTitleCase } from '../../../utils';
import {
    CustomEndpointConfig,
    LocalMetaInfoEndpoint,
    LocalMetaInfoProperty,
    LocalMetaInfoPropertyEnum,
    LocalMetaInfoPropertyReference,
} from '../../types';
import { sortIds } from '../../utils';
import configBuilderHandlers from './handlers';
import { CustomListFormConfig } from './types';

export function getListFormPropertyColumn(
    siteId: string,
    endpointId: string,
    propertyId: string,
    propertyMetaInfo: LocalMetaInfoProperty,
    columnCustomConfig?: Partial<Column>
): Column {
    const result: Column = {
        id: propertyId,
        title: propertyMetaInfo.name,
        fieldName: propertyId,
    } as any;

    switch (propertyMetaInfo.type) {
        case 'url':
            if (propertyMetaInfo.upload) {
                result.type = 'image';
            }
            break;
        case 'string':
            result.type = 'text';

            if (propertyMetaInfo.upload) {
                result.type = 'image';
            } else if (propertyMetaInfo.format === 'datetime') {
                result.type = 'date';
            } else if (propertyMetaInfo.format === 'reference') {
                const propertyMetaInfoReference =
                    propertyMetaInfo as LocalMetaInfoPropertyReference;
                const resultAlias = result as ColumnReference;

                result.type = 'reference';
                const isGlobalEndpoint = !propertyMetaInfoReference.siteId;
                if (isGlobalEndpoint) {
                    resultAlias.endpointId =
                        propertyMetaInfoReference.endpointId;
                } else {
                    resultAlias.siteId = propertyMetaInfoReference.siteId;
                    resultAlias.endpointId =
                        propertyMetaInfoReference.endpointId;
                }
                resultAlias.representationDataSource =
                    propertyMetaInfoReference.representationDataSource ??
                    ((item) => item.name || item.title);
                resultAlias.idField = propertyMetaInfoReference.idField;
                resultAlias.searchByField =
                    propertyMetaInfoReference.searchByField;
                resultAlias.sortListByField =
                    propertyMetaInfoReference.sortListByField;
                resultAlias.getLink = (itemId, item) => {
                    if (isGlobalEndpoint) {
                        return `/${resultAlias.endpointId}/item/${item[propertyId]}/info`;
                    }
                    return `/${siteId}/${resultAlias.endpointId}/item/${item[propertyId]}/info`;
                };
            } else if (propertyMetaInfo.format === 'enum') {
                const resultAlias = result as ColumnEnum;
                const propertyMetaInfoEnum =
                    propertyMetaInfo as LocalMetaInfoPropertyEnum;

                resultAlias.type = 'enum';
                const enumIds = Object.keys(propertyMetaInfoEnum.values);
                enumIds.sort();
                resultAlias.values = enumIds.map((value) => ({
                    value,
                    label: propertyMetaInfoEnum.values[value],
                }));
                result.width = 150;
            } else if (propertyMetaInfo.format === 'color') {
                const resultAlias = result as ColumnColor;
                resultAlias.type = 'color';
                result.width = 150;
            } else {
                result.maxWidth = 150;
                result.width = 150;
            }
            break;
        case 'number':
            result.type = 'number';
            result.maxWidth = 40;
            result.width = 40;
            break;
        case 'boolean':
            result.type = 'boolean';
            break;
        case 'array':
            result.type = 'array';
            break;
    }

    if (
        ['text', 'number', 'reference', 'enum', 'boolean', 'date'].includes(
            result.type
        )
    ) {
        result.updateInPlace = true;
    }

    return _.merge(result, columnCustomConfig || {});
}

export default function getListForm(
    siteId: string,
    endpointId: string,
    endpointMetaInfo: LocalMetaInfoEndpoint,
    endpointCustomConfig: CustomEndpointConfig | undefined
) {
    const { properties } = endpointMetaInfo;
    const order = endpointMetaInfo.propertiesOrder;
    const formCustomConfig: CustomListFormConfig | undefined =
        endpointCustomConfig?.forms?.list;
    const customHandlers = formCustomConfig?.handlers;
    const propertiesIds = sortIds(Object.keys(properties), order);

    const customColumns = formCustomConfig?.columns;
    const customColumnsOrder = formCustomConfig?.columnsOrder;
    const columnsOrder = sortIds(propertiesIds, customColumnsOrder);

    if (customColumns) {
        Object.keys(customColumns).forEach((columnId) => {
            if (!columnsOrder.includes(columnId)) {
                columnsOrder.push(columnId);
            }
        });
    }

    columnsOrder.forEach((columnId: string) => {
        const isExistInCustomColumns =
            customColumns &&
            Object.getOwnPropertyNames(customColumns).includes(columnId);

        const isExistInProperties = propertiesIds.includes(columnId);

        if (!isExistInCustomColumns && !isExistInProperties) {
            console.error(
                `CONFIG BUILDER (getListForm): Wrong column order data the property "${columnId}" doesn't exist in the list form: siteId=${siteId} endpointId=${endpointId}`,
                {
                    siteId,
                    endpointId,
                    columnId,
                    propertiesIds,
                    columnsOrder,
                }
            );
        }
    });

    const tableColumnsOrder = columnsOrder;

    const tableColumns = tableColumnsOrder
        .map((columnId: string) => {
            const propertyMetaInfo = properties[columnId];
            const isCustomColumn = !propertyMetaInfo;

            if (isCustomColumn) {
                const column = (formCustomConfig?.columns ?? {})[columnId];
                if (!formCustomConfig) {
                    throw new Error(
                        `No definition form columns ${siteId}/${endpointId}/${columnId}`
                    );
                }

                return {
                    id: columnId,
                    ...column,
                };
            }

            if (
                propertyMetaInfo.isHidden ||
                propertyMetaInfo.format === 'html'
            ) {
                return null;
            }

            const columnCustomConfig: undefined | Partial<Column> =
                formCustomConfig?.columns && formCustomConfig?.columns[columnId]
                    ? formCustomConfig?.columns[columnId]
                    : undefined;
            return getListFormPropertyColumn(
                siteId,
                endpointId,
                columnId,
                propertyMetaInfo,
                columnCustomConfig
            );
        })
        .filter((column) => !!column);

    const commandPanel: HStack = {
        id: `${endpointId}-list-table-command-panel`,
        type: ViewTypes.HStack,
        items: [
            {
                id: `createButton`,
                type: ViewTypes.Button,
                text: 'Create',
                onClick: 'onCreateNewItem',
            },
            {
                id: `searchInput`,
                type: ViewTypes.SearchInput,
                dataSource: 'url.queryParams.search.replace',
            },
        ],
    };

    let contextMenu: ContextMenuItem[] = [
        {
            id: 'delete',
            title: 'Delete',
            isHidden(this: AppContext) {
                const { currentUserData } = this;

                const roles = ['admin', /[a-zA-Z0-9]+Admin$/];

                if (!currentUserData || !currentUserData.roles) {
                    return true;
                }

                return !currentUserData.roles.some((role: string) =>
                    roles.some((expression: string | RegExp) => {
                        if (expression instanceof RegExp) {
                            return role.match(expression);
                        }

                        return role === expression;
                    })
                );
            },
            onClick: 'onDeleteItemFromContextMenu',
        },
        {
            id: 'copy',
            title: 'Copy',
            image: copyIcon,
            isHidden(this: AppContext) {
                const { currentUserData } = this;

                const roles = ['admin', /[a-zA-Z0-9]+Admin$/];

                if (!currentUserData || !currentUserData.roles) {
                    return true;
                }

                return !currentUserData.roles.some((role: string) =>
                    roles.some((expression: string | RegExp) => {
                        if (expression instanceof RegExp) {
                            return role.match(expression);
                        }

                        return role === expression;
                    })
                );
            },
            onClick(this: AppContext, row: any) {
                const { siteId, endpointId } = this.form.params;

                this.form.url.push({
                    path: `/${siteId}/${endpointId}/item`,
                    queryParams: {
                        copyFrom: row.id,
                    },
                });
            },
        },
    ];

    const customContextMenu = formCustomConfig?.contextMenu;

    if (customContextMenu) {
        contextMenu = contextMenu.map((item) => {
            const customItem = customContextMenu.find(
                (customItem) => customItem.id === item.id
            );

            if (!customItem) {
                return item;
            }

            return {
                ...item,
                ...customItem,
            };
        });

        contextMenu = [
            ...contextMenu,
            ...customContextMenu.filter(
                (customItem: any) =>
                    !contextMenu.some((item) => item.id === customItem.id)
            ),
        ];
    }

    const listFormContent = {
        id: `${endpointId}-root-group`,
        type: ViewTypes.VStack,
        items: [
            commandPanel,
            {
                id: `table`,
                type: ViewTypes.DataTable,
                siteId,
                endpointId,
                isSelectable: true,
                sortDataSource:
                    formCustomConfig?.sortDataSource ??
                    'url.queryParams.sort.replace',
                filterDataSource:
                    formCustomConfig?.filterDataSource ??
                    'url.queryParams.filter.replace',
                searchDataSource:
                    formCustomConfig?.searchDataSource ??
                    'url.queryParams.search.replace',
                selectionDataSource:
                    formCustomConfig?.selectionDataSource ?? 'selection',
                pageDataSource:
                    formCustomConfig?.pageDataSource ??
                    'url.queryParams.page.replace',
                pageSize: formCustomConfig?.pageSize ?? 15,
                rowStyle: formCustomConfig?.rowStyle ?? null,
                rowClassName: formCustomConfig?.rowClassName ?? null,
                onUpdateItemField: 'onUpdateItemField',
                onClickItem:
                    formCustomConfig?.onClickItem ??
                    function (this: AppContext, item: any) {
                        const defaultItemPath = `/${siteId}/${endpointId}/item/${item.id}/info`;
                        let path = defaultItemPath;
                        if (formCustomConfig?.getItemPath) {
                            path = formCustomConfig.getItemPath(
                                siteId,
                                endpointId,
                                item
                            );
                        }

                        this.form.url.push(path);
                    },
                contextMenu,
                columns: tableColumns,
                onBeforeLoadReferences:
                    formCustomConfig?.onBeforeLoadReferences ?? null,
            },
        ],
    };

    return {
        id: 'list_form',
        component: ListForm,
        title: formCustomConfig?.title ?? `${toTitleCase(endpointId)}`,
        fields: {
            selection: 'none',
        },
        defaultUrlQueryParams: formCustomConfig?.defaultUrlQueryParams,
        params: {
            siteId,
            endpointId,
        },
        commands: [
            {
                id: 'refresh',
                text: 'Refresh',
                onClick: 'onRefreshTable',
                image: refreshIcon,
            },
            {
                id: 'openConfig',
                text: 'Endpoint Config',
                image: endpointConfigIcon,
                isHidden: function (this: AppContext) {
                    const { currentUserData } = this;

                    const roles = ['admin', /[a-zA-Z0-9]+Admin$/];

                    if (!currentUserData || !currentUserData.roles) {
                        return true;
                    }

                    return !currentUserData.roles.some((role: string) =>
                        roles.some((expression: string | RegExp) => {
                            if (expression instanceof RegExp) {
                                return role.match(expression);
                            }

                            return role === expression;
                        })
                    );
                },
                onClick: 'onOpenEndpointConfigEditor',
            },
            {
                id: 'export',
                text: 'Export',
                image: exportIcon,
                onClick: 'onOpenExportDialog',
            },
            {
                id: 'import',
                text: 'Import',
                image: importIcon,
                onClick: 'onOpenImportDialog',
            },
            {
                id: 'deleteItem',
                text: 'Delete',
                onClick: 'onOpenDeleteItemsDialog',

                image: deleteIcon,
                isHidden: function (this: AppContext) {
                    const { currentUserData } = this;

                    const roles = ['admin', /[a-zA-Z0-9]+Admin$/];

                    if (!currentUserData || !currentUserData.roles) {
                        return true;
                    }

                    return !currentUserData.roles.some((role: string) =>
                        roles.some((expression: string | RegExp) => {
                            if (expression instanceof RegExp) {
                                return role.match(expression);
                            }

                            return role === expression;
                        })
                    );
                },
            },
        ],
        handlers(this: AppContext) {
            return {
                ...configBuilderHandlers.call(this),
                ...(customHandlers?.call(this) ?? {}),
            };
        },
        content: listFormContent,
    };
}
