export default function getTemplateCode({ siteId }) {
    return `

var scriptConsole;
var lib;
var asyncApi;

const initApi = (nativeApi) => {
    asyncApi = nativeApi;
    const wrapWithPromise = (func) => {
        return (...params) => {
            return new Promise((resolve, reject) => {
                func(...params, (error, response) => {
                    if (error) {
                        return reject(error);
                    }
                    return resolve(response);
                });
            });
        };
    };
    asyncApi.get = wrapWithPromise(nativeApi.get);
    asyncApi.post = wrapWithPromise(nativeApi.post);
    asyncApi.put = wrapWithPromise(nativeApi.put);
    asyncApi.delete = wrapWithPromise(nativeApi.delete);
    asyncApi.searchLedger = wrapWithPromise(nativeApi.searchLedger);
    asyncApi.searchTestLedger = wrapWithPromise(nativeApi.searchTestLedger);
};

const initEnvironment = (t) => {
    scriptConsole = t.console;
    lib = t.lib;

    initApi(t.api);
};


const main = (next) => {
    //Place your code here
    scriptConsole.log("WORKS");
    return next();
};

module.exports = function(job, next) {
    
    initEnvironment({
        api: api,
        console: job,
    });

    main(next);

    /////////////////////////////////////////////////////////////////////
    // HELP
    //
    // sanbox scripts don't support es2015 or es6 syntax, only vanilla js
    // promises are supported 
    // errors are not returned, if script errors then vanilla response is sent to client
    //
    // Available properties:
    //
    //  job.log("Push text to log");
    //  api
    //
    // Available plugins:
    //  
    //  this.lib.sms
    //  Example:  this.lib.sms.send(phone, message, callback)
    //
    //  this.lib.cache
    //  Examples:
    //      this.lib.cache.set('userId_verify', 'true', 1000 * 60 * 60) // 1 hour
    //      this.lib.cache.get('userId_verify')
    //
    //  Return function:
    //      next() - call it without arguments it returns native result
    //      next({"status":404, "message":"Not registered"}) - call it with an object and it will return json encoded response to client
    //
    //  API object:
    //      get - this.api.get(url, query, callback);
    //      post - this.api.post(url, query, postData, callback);
    //      put - this.api.put(url, query, postData, callback);
    //      delete - this.api.del(url, query, callback);
    //
    //  Example:
    //      return new Promise((resolve,reject) =>{
    //            this.api.get("/search/:ENDPOINT", { q: \`userId:"\${userId}"\`, size: 1}, function (error, response) {
    //                if (error) {
    //                    return null;
    //                }
    //      
    //                if (response) {
    //                    if (response.hits.total > 0) {
    //                        return response.hits.hits[0];
    //                    }
    //                }
    //      
    //                return null;
    //            });
    //      });
    
}
    
    `.trim();
}
