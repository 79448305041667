import lodash from 'lodash';
import { useCallback, useEffect, useMemo, useRef } from 'react';
import { addContextToHandlers, getValue } from '../../../utils';
import {
    getHandlerWithContext,
    useHandlersWithContext,
} from '../../../utils/context';
import Selection from '../../../utils/Selection';
import { SortItem } from '../../native/table/props';
import { useAppContextObserver } from '../context';
import { AppContext } from '../context/types';
import PropsDataTable from './props';

const addContextToItemsFunctions = (items: any[], context: AppContext) => {
    return (items || []).map((item) => addContextToHandlers(item, context));
};

const useDataTableState = (props: PropsDataTable) => {
    const {
        id,
        isSelectable,
        pageDataSource,
        selectionDataSource,
        sortDataSource,
        filterDataSource,
        searchDataSource,
    } = props;

    const [
        {
            selection,
            isHidden,
            page,
            rowClassName,
            rowStyle,
            filter,
            queryFilter,
            sort,
            searchPhrase,
        },
        getContext,
    ] = useAppContextObserver((context, prevState, prevValues) => {
        const selection: Selection | undefined = (() => {
            if (!isSelectable) {
                return;
            }

            if (props.selection) {
                return Selection.unserealizeFromObject(props.selection);
            }

            if (!selectionDataSource) {
                return;
            }

            const dataSourceValue = getValue(
                props.selectionDataSource,
                context,
                false
            );

            return Selection.unserealizeFromObject(dataSourceValue ?? null);
        })();

        const page = (() => {
            if (props.page || props.page === 0) {
                return props.page;
            }

            if (!pageDataSource) {
                return;
            }

            const pageValue = context.form.getDataSourceValue(pageDataSource);
            if (!pageValue) {
                return;
            }

            return Number(pageValue) - 1;
        })();

        const rowClassName = getValue(props.rowClassName, context, false);

        const rowStyle = getValue(props.rowStyle, context, false);

        const filter = (() => {
            if (!filterDataSource) {
                return props.filter;
            }

            return getValue(props.filterDataSource, context, false);
        })();

        let { queryFilter } = props;
        if (props.queryFilterDataSource) {
            //FIXME: Some race bug??
            const newQueryFilter = getValue(
                props.queryFilterDataSource,
                context
            );

            if (!prevValues) {
                queryFilter = newQueryFilter;
            } else {
                if (lodash.isEqual(newQueryFilter, prevValues.queryFilter)) {
                    queryFilter = prevValues.queryFilter;
                } else {
                    queryFilter = newQueryFilter;
                }
            }
        }

        const sort = (() => {
            if (props.sort) {
                return props.sort;
            }

            if (!sortDataSource) {
                return [];
            }

            const dataSourceValue = getValue(
                props.sortDataSource,
                context,
                false
            );
            if (!dataSourceValue) {
                return undefined;
            }

            return dataSourceValue;
        })();

        const searchPhrase = (() => {
            if (props.searchPhrase) {
                return props.searchPhrase;
            }

            if (!searchDataSource) {
                return undefined;
            }

            const dataSourceValue = getValue(
                props.searchDataSource,
                context,
                false
            );

            if (!dataSourceValue) {
                return '';
            }

            return dataSourceValue ?? '';
        })();

        return {
            selection,
            isHidden: getValue(props.isHidden, context),
            page,
            rowClassName,
            rowStyle,
            filter,
            queryFilter,
            sort,
            searchPhrase,
        };
    });

    const refTable = useRef<any>();

    const columns = useMemo(() => {
        return props.columns.map((column) =>
            addContextToHandlers(column, getContext())
        );
    }, [getContext, props.columns]);

    useEffect(() => {
        return () => {
            getContext().form.cleanViewCallbacks(id);
        };
    }, [id, getContext]);

    // : {
    //     onChangeFilter: (filter: FilterItem[]) => void;
    //     onClickItem: (item: any) => void;
    //     onBeforeLoadReferences: (params: LoadReferencesParams) => void;
    //     onMapItems: (items: any) => any;
    // }
    const handlersWithContext = useHandlersWithContext(props, getContext());

    const contextMenu = useMemo(() => {
        if (!props.contextMenu) {
            return [];
        }

        const context = getContext();
        return addContextToItemsFunctions(props.contextMenu, context).filter(
            (item: any) => !getValue(item.isHidden, context)
        );
    }, [props.contextMenu, getContext]);

    const onChangePage = useCallback(
        (newPage: number) => {
            if (pageDataSource) {
                getContext().form.setDataSourceValue(
                    pageDataSource,
                    Number(newPage) + 1
                );
            }

            if (!props.onChangePage) {
                return;
            }

            const handler = getHandlerWithContext(
                props.onChangePage,
                getContext(),
                false
            );
            if (handler) {
                handler(newPage);
            }
        },
        [getContext, pageDataSource, props.onChangePage]
    );

    const onChangeSelection = useCallback(
        (newSelection: Selection) => {
            const serializedSelection = newSelection.serializeToObject();

            if (selectionDataSource) {
                getContext().form.setDataSourceValue(
                    selectionDataSource,
                    serializedSelection
                );
            }

            if (!props.onChangeSelection) {
                return;
            }

            const handler = getHandlerWithContext(
                props.onChangeSelection,
                getContext(),
                false
            );

            if (handler) {
                handler(serializedSelection);
            }
        },
        [getContext, props.onChangeSelection, selectionDataSource]
    );

    const onChangeSort = useCallback(
        (newSort: SortItem[]) => {
            if (sortDataSource) {
                getContext().form.setDataSourceValue(sortDataSource, newSort);
            }

            if (!props.onChangeSort) {
                return;
            }

            const handler = getHandlerWithContext(
                props.onChangeSort,
                getContext(),
                false
            );

            if (handler) {
                handler(newSort);
            }
        },
        [getContext, sortDataSource, props.onChangeSort]
    );

    const onChangeFilter = useCallback(
        (newFilter: any) => {
            if (filterDataSource) {
                getContext().form.setDataSourceValue(
                    filterDataSource,
                    newFilter
                );
            }

            if (!handlersWithContext.onChangeFilter) {
                return;
            }

            handlersWithContext.onChangeFilter(newFilter);
        },
        [getContext, handlersWithContext, filterDataSource]
    );

    const update = useCallback((silent = true) => {
        if (!refTable.current) {
            return;
        }

        if (silent) {
            refTable.current.updateItems();
        } else {
            refTable.current.update();
        }
    }, []);

    useEffect(() => {
        getContext().form.setupViewCallbacks(id, {
            update,
        });
    }, [id, getContext, update]);

    const onBeforeUpdateItemField = useCallback(
        ({ column, item, value }: any) => {
            getContext().form.notify(
                {
                    text: 'Updating item...',
                },
                'UPDATING_ITEM'
            );
        },
        [getContext]
    );

    const onAfterUpdateItemField = useCallback(
        ({ column, item, value, error }: any) => {
            const context = getContext();
            if (error) {
                context.form.notify(
                    {
                        type: 'error',
                        text: 'Error update item...',
                        lifetimeMs: 3000,
                    },
                    'UPDATING_ITEM'
                );
            } else {
                context.form.notify(
                    {
                        text: 'Update item success',
                        lifetimeMs: 3000,
                    },
                    'UPDATING_ITEM'
                );
            }

            update(true);
        },
        [update, getContext]
    );

    return {
        columns,
        isHidden,
        handlersWithContext,
        page,
        onChangePage,
        selection,
        onChangeSelection,
        sort,
        onChangeSort,
        rowStyle,
        rowClassName,
        contextMenu,
        filter,
        onChangeFilter,
        queryFilter,
        onBeforeUpdateItemField,
        onAfterUpdateItemField,
        searchPhrase,
        refTable,
    };
};

export default useDataTableState;
