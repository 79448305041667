import { AppContext } from '../../../../../core/components/form/context/types';
import { CustomListFormConfig } from '../../../../../core/config-builder/forms/list/types';

const list: CustomListFormConfig = {
    columns: {
        systemProductId: {
            getLink(this: AppContext, itemId: string, item: any) {
                return `/pazamshop/ledger-product/${item.systemProductId}/info`;
            },
        },
    },
};

export default list;
