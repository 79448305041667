import Dialog from '../../../components/dialog';
import { DialogData } from '../../../components/form/context/types';
import ViewTypes from '../../../components/form/ViewTypes';
import handlers from './handlers';
import style from './style.module.scss';

const yesnoDialog: DialogData = {
    id: 'yesno',
    component: Dialog,
    className: style.dialog,
    handlers,
    content: {
        id: 'rootGroup',
        type: ViewTypes.VStack,
        items: [
            {
                id: 'text',
                type: ViewTypes.Text,
                style: {
                    fontSize: 20,
                    fontWeight: 500,
                    flex: 1,
                    direction: 'ltr',
                    textAlign: 'right',
                },
            },
            {
                id: 'footer',
                type: ViewTypes.HStack,
                items: [],
            },
        ],
    },
};

export default yesnoDialog;
