import copy from 'copy-to-clipboard';
import moment from 'moment';
import {
    AppContext,
    FormFunctionGetHandlers,
    FormHandlers,
} from '../../../../../core/components/form/context/types';
import apiClient from '../../../../../core/requests/api';

const convertDateFilterToQuery = (
    field: string,
    { startDate, endDate }: any
): string => {
    if (!startDate && !endDate) {
        return '';
    }

    const startMoment = moment(startDate);
    const endMoment = moment(endDate);

    if (startDate && endDate) {
        return `${field}:[${startMoment.toISOString()} TO ${endMoment.toISOString()}]`;
    }

    if (startDate) {
        return `${field}:[${startMoment.toISOString()} TO *]`;
    }

    return `${field}:[* TO ${endMoment.toISOString()}]`;
};

const handlers: FormFunctionGetHandlers = function (
    this: AppContext
): FormHandlers {
    const loadTopUsers = async (date: Date) => {
        this.form.topUsers = [];

        var startDate = new Date(date);
        startDate.setHours(0, 0, 0, 0);

        var endDate = new Date(date);
        endDate.setHours(23, 59, 59, 999);

        const dateFilter = convertDateFilterToQuery('successAt', {
            startDate,
            endDate,
        });
        let q = '';

        if (dateFilter) {
            q = dateFilter;
        }

        const queryParams = `sort=successAt:asc&q=${q}&size=500`;
        const response = await apiClient.callSearchRequest(
            'cbc',
            'wordlesession',
            queryParams
        );

        console.log(response);

        this.form.topUsers = response.hits.hits.map((item, index) => {
            return { index, id: item._id, ...item._source };
        });
    };

    const loadNumberOfPlayedGames = async () => {
        this.form.playedGamesNumber = '';

        const aggs = {
            aggs: {
                games_count: {
                    value_count: {
                        field: 'firstTryAt',
                    },
                },
            },
        };

        const { startDate, endDate } = this.form;
        const q = convertDateFilterToQuery('firstTryAt', {
            startDate,
            endDate,
        });
        const queryParams = `size=0&sort=distance:desc&aggs=${JSON.stringify(
            aggs
        )}&q=${q}`;
        const response = await apiClient.callSearchRequest(
            'cbc',
            'wordlesession',
            queryParams
        );

        this.form.playedGamesNumber = response.aggregations.games_count.value;
    };

    const loadNumberOfCompletedWords = async () => {
        this.form.numberOfCompletedWords = '';

        const aggs = {
            aggs: {
                games_score: {
                    sum: {
                        field: 'numberOfAttempts',
                    },
                },
            },
        };

        const { startDate, endDate } = this.form;
        const q = convertDateFilterToQuery('firstTryAt', {
            startDate,
            endDate,
        });
        const queryParams = `size=0&sort=distance:desc&aggs=${JSON.stringify(
            aggs
        )}&q=${q}`;
        const response = await apiClient.callSearchRequest(
            'cbc',
            'wordlesession',
            queryParams
        );

        this.form.numberOfCompletedWords = Number(
            response.aggregations.games_score.value
        ).toFixed();
    };
    const loadNumberOfWinningGames = async () => {
        this.form.successfulGamesNumber = '';

        const aggs = {
            aggs: {
                games_count: {
                    value_count: {
                        field: 'successAt',
                    },
                },
            },
        };

        const { startDate, endDate } = this.form;
        const q = convertDateFilterToQuery('firstTryAt', {
            startDate,
            endDate,
        });
        const queryParams = `size=0&sort=distance:desc&aggs=${JSON.stringify(
            aggs
        )}&q=${q}`;
        const response = await apiClient.callSearchRequest(
            'cbc',
            'wordlesession',
            queryParams
        );

        this.form.successfulGamesNumber =
            response.aggregations.games_count.value;
    };

    const loadNumberOfPlayers = async () => {
        this.form.numberOfPlayers = '';

        const aggs = {
            aggs: {
                players_count: {
                    cardinality: {
                        field: 'user.username',
                    },
                },
            },
        };

        const queryParams = `size=0&aggs=${JSON.stringify(aggs)}`;
        const response = await apiClient.callSearchRequest(
            'cbc',
            'wordlesession',
            queryParams
        );

        this.form.numberOfPlayers = response.aggregations.players_count.value;
    };

    const loadNumberOfShares = async () => {
        this.form.numberOfShares = '';
        const aggs = {
            aggs: {
                games_count: {
                    value_count: {
                        field: 'sharedAt',
                    },
                },
            },
        };

        const { startDate, endDate } = this.form;
        const q = convertDateFilterToQuery('sharedAt', {
            startDate,
            endDate,
        });
        const queryParams = `size=0&sort=distance:desc&aggs=${JSON.stringify(
            aggs
        )}&q=${q}`;
        const response = await apiClient.callSearchRequest(
            'cbc',
            'wordleshare',
            queryParams
        );

        this.form.numberOfShares = response.aggregations.games_count.value;
    };

    const loadAveragePlayTime = async () => {
        this.form.averageTryCount = '';
        this.form.averagePlayTime = '';
        const aggs = {
            aggs: {
                games_score: {
                    sum: {
                        field: 'numberOfAttempts',
                    },
                },
                games_count: {
                    value_count: {
                        field: 'firstTryAt',
                    },
                },
                games_time: {
                    sum: {
                        field: 'playTime',
                    },
                },
            },
        };

        const { startDate, endDate } = this.form;
        const q = convertDateFilterToQuery('successAt', {
            startDate,
            endDate,
        });
        const queryParams = `size=0&sort=distance:desc&aggs=${JSON.stringify(
            aggs
        )}&q=${q}`;
        const response = await apiClient.callSearchRequest(
            'cbc',
            'wordlesession',
            queryParams
        );

        this.form.averageTryCount = Number(
            response.aggregations.games_score.value /
                response.aggregations.games_count.value
        ).toFixed();

        function millisToMinutesAndSeconds(millis: any) {
            var minutes = Math.floor(millis / 60000);
            var seconds = ((millis % 60000) / 1000).toFixed(0);
            return (
                minutes + ':' + (parseInt(seconds) < 10 ? '0' : '') + seconds
            );
        }
        this.form.averagePlayTime = millisToMinutesAndSeconds(
            response.aggregations.games_time.value /
                response.aggregations.games_count.value
        );
    };

    const loadData = () => {
        loadNumberOfPlayedGames();
        loadNumberOfCompletedWords();
        loadNumberOfPlayers();
        loadNumberOfWinningGames();
        loadAveragePlayTime();
        loadNumberOfShares();
    };

    const onBeforeOpen = async () => loadData();

    const onChangeStartDate = (newStartDate: string) => {
        this.form.startDate = newStartDate;
        loadData();
    };

    const onChangeEndDate = async (newEndDate: string) => {
        this.form.endDate = newEndDate;
        await loadData();
    };

    const onOpenUserCard = async (item: any) => {
        const { items } = await apiClient.getItemsFromTable(
            'cbc',
            'flappyplayer',
            [
                {
                    direction: 'asc',
                    field: 'userId',
                },
            ],
            [
                {
                    field: 'userId',
                    include: [item.userId],
                },
            ],
            0,
            1,
            undefined
        );

        if (items.length < 0) {
            return;
        }

        this.form.url.push(`/user/common/item/${items[0].userId}/info`);
    };

    const onChangeDaySelection = async (item: any) => {
        loadTopUsers(item);
    };

    const copyPhone = async (item: any) => {
        const { items } = await apiClient.getItemsFromTable(
            'cbc',
            'profile',
            [
                {
                    direction: 'asc',
                    field: 'user.username',
                },
            ],
            [
                {
                    field: 'user.username',
                    include: [item.user.username],
                },
            ],
            0,
            1,
            undefined
        );
        copy(items[0].phone);

        this.form.notify({
            text: 'Phone copied!',
            lifetimeMs: 3000,
        });
    };
    return {
        onBeforeOpen,
        onChangeStartDate,
        onChangeEndDate,
        onOpenUserCard,
        onChangeDaySelection,
        copyPhone,
    };
};

export default handlers;
