import React from 'react';
import { AppContext } from '../../../../../../core/components/form/context/types';
import ViewTypes from '../../../../../../core/components/form/ViewTypes';
import {
    ItemFormFunctionGetHandlers,
    ItemFormHandlers,
} from '../../../../../../core/components/item-form/types';
import Map from './map';

const getLocationByAddress = async (
    address: string,
    googleMapApiKey: string
) => {
    const query = `https://maps.googleapis.com/maps/api/geocode/json?address=${encodeURIComponent(
        address
    )}&key=${encodeURIComponent(googleMapApiKey)}`;
    const result = await fetch(query).then((response) => response.json());

    if (result.status !== 'OK') {
        throw result.status;
    }

    return result.results[0].geometry.location;
};

const handlers: ItemFormFunctionGetHandlers = function (
    this: AppContext
): ItemFormHandlers {
    const onClickUpdateCoordinatesButton = async () => {
        const { city, subLocation } = this.form.object;

        if (!city && !subLocation) {
            this.form.object.mapCoords = '';
            return;
        }

        try {
            const location = await getLocationByAddress(
                `${city} ${subLocation}`,
                this.appConfig.params.googleMapApiKey
            );
            if (location) {
                this.form.object.mapCoords = `${location.lat},${location.lng}`;
            }
        } catch (e) {}
    };

    const getCities = async () => {
        const aggs = {
            aggs: {
                cities: {
                    terms: {
                        field: 'city',
                        size: 10000,
                        // "order": {"city": 'desc'}
                    },
                    aggs: {
                        score: {
                            value_count: {
                                field: 'city',
                            },
                        },
                    },
                },
            },
        };

        const queryParams = `size=0&aggs=${JSON.stringify(aggs)}`;
        const result = await this.api.get(
            `/policeradar/search/location?${queryParams}`
        );
        if (String(result.status) !== '200') {
            throw result.status;
        }

        const cities = (
            (result &&
                result.body &&
                result.body.aggregations &&
                result.body.aggregations.cities &&
                result.body.aggregations.cities.buckets) ||
            []
        ).map((item: any) => item.key);

        cities.sort();

        return cities;
    };

    const onCreateNewCity = async (newValue: any) => {
        const newValues = [
            ...this.form.views.city.values,
            {
                value: newValue,
                label: newValue,
            },
        ];

        newValues.sort((a, b) => {
            if (a.label > b.label) {
                return 1;
            }

            if (a.label === b.label) {
                return 0;
            }

            return -1;
        });
        this.form.views.city.values = newValues;

        this.form.views.city.value = newValue;
    };

    const onBeforeOpen = async () => {
        this.form.views.fieldsGroup.style = {
            ...this.form.views.fieldsGroup.style,
            marginTop: 30,
        };

        const cities = await getCities();
        this.form.views.city.values = cities.map((city: string) => ({
            value: city,
            label: city,
        }));

        this.form.views.fieldsGroup.items =
            this.form.views.fieldsGroup.items.map((item: any) => {
                if (item.id !== 'mapCoords') {
                    return item;
                }

                return {
                    id: 'mapCoordsContainer',
                    type: ViewTypes.HStack,
                    items: [
                        item,
                        {
                            id: 'updateCoordinatesButton',
                            type: ViewTypes.Button,
                            text: 'Update coordinates',
                            style: { marginRight: 15 },
                            onClick: 'onClickUpdateCoordinatesButton',
                            role: 'notActive',
                        },
                    ],
                };
            });

        this.form.views.fieldsGroup.items = [
            ...this.form.views.fieldsGroup.items,
            {
                id: 'googleMap',
                type: 'CustomComponent',
                dataSource: 'object.mapCoords',
                component: (props: any) => {
                    const coordinates = this.form.getDataSourceValue(
                        props.dataSource
                    ) as string;
                    const locationList = [];
                    let center;

                    if (coordinates) {
                        const lat = Number(coordinates.split(',')[0]);
                        const lng = Number(coordinates.split(',')[1]);

                        locationList.push({
                            lat,
                            lng,
                        });

                        center = { lat, lng };
                    }

                    const updateCoordinates = ({ index, lat, lng }: any) => {
                        this.form.object.mapCoords = `${lat},${lng}`;
                    };

                    return (
                        <Map
                            googleMapApiKey={
                                this.appConfig.params.googleMapApiKey
                            }
                            locationList={locationList as any}
                            center={center}
                            mapZoom={16}
                            isMarkersDraggable
                            onMarkerDragEnd={updateCoordinates}
                        />
                    );
                },
            },
        ];
    };

    return {
        onBeforeOpen,
        onClickUpdateCoordinatesButton,
        onCreateNewCity,
    };
};

export default handlers;
