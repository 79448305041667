import GoogleMap from 'google-map-react';
import React from 'react';
// import SectionHeader from "../section-header";
import Marker from './marker';
import PropsMap from './props';
import style from './style.module.scss';

const DEFAULT_CENTER = {
    lat: 31.7683,
    lng: 35.2137,
};

class Map extends React.Component<PropsMap, any> {
    constructor(props: PropsMap) {
        super(props);
        this.state = {
            draggableMarkerData: null,
            activeLocations: [],
            center: props.center || DEFAULT_CENTER,
            zoom: props.mapZoom || 15,
        };
    }

    componentWillReceiveProps(nextProps: PropsMap) {
        if (nextProps.center !== this.props.center) {
            this.setState({
                center: nextProps.center,
            });
        }

        if (nextProps.mapZoom !== this.props.mapZoom) {
            this.setState({
                zoom: nextProps.mapZoom,
            });
        }
    }

    _handleToggleActiveLocation = (locationIndex: number) => (e?: any) => {
        e.stopPropagation();

        const oldActiveLocations = this.state.activeLocations;
        let newActiveLocations = oldActiveLocations.slice();

        if (oldActiveLocations.includes(locationIndex)) {
            newActiveLocations = newActiveLocations.filter(
                (item: any) => item !== locationIndex
            );
        } else {
            newActiveLocations.push(locationIndex);
        }

        this.setState({
            activeLocations: newActiveLocations,
        });
    };

    _handleChangeMap = ({ zoom, center }: any) => {
        this.setState({
            center,
            zoom,
        });
    };

    _handleDragMarker = (childKey: any, childProps: any, mouse: any) => {
        this.setState({
            draggableMarkerData: {
                index: childKey,
                lat: mouse.lat,
                lng: mouse.lng,
            },
        });
    };

    _handleChildMouseUp = (childKey: any, childProps: any, mouse: any) => {
        this.setState({
            draggableMarkerData: null,
        });

        this.props.onMarkerDragEnd({
            index: childKey,
            lat: mouse.lat,
            lng: mouse.lng,
        });
    };

    render() {
        const { isLoading, isMarkersDraggable, locationList, googleMapApiKey } =
            this.props;
        const { activeLocations, center, zoom, draggableMarkerData } =
            this.state;
        if (isLoading) {
            return <div />;
        }

        return (
            <div
                className={style.mapContainer}
                key="loaded-map"
                data-role="view"
            >
                <div className={style.map}>
                    <GoogleMap
                        bootstrapURLKeys={{ key: googleMapApiKey }}
                        center={center}
                        zoom={zoom}
                        onChange={this._handleChangeMap}
                        draggable={!isMarkersDraggable && !draggableMarkerData}
                        onChildMouseDown={this._handleDragMarker}
                        onChildMouseUp={this._handleChildMouseUp}
                        onChildMouseMove={this._handleDragMarker}
                    >
                        {(locationList || []).map((item, index) => (
                            <Marker
                                key={index}
                                isActive={activeLocations.includes(index)}
                                lat={
                                    (draggableMarkerData &&
                                    draggableMarkerData.index === index
                                        ? draggableMarkerData.lat
                                        : item.lat) as any
                                }
                                lng={
                                    draggableMarkerData &&
                                    draggableMarkerData.index === index
                                        ? draggableMarkerData.lng
                                        : item.lng
                                }
                                name={item.name}
                                address={item.address}
                                description={item.info}
                                onClick={this._handleToggleActiveLocation(
                                    index
                                )}
                            />
                        ))}
                    </GoogleMap>
                </div>
            </div>
        );
    }
}

export default Map;
