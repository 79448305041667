import { AppContext } from '../../../../core/components/form/context/types';
import { CustomEndpointConfig } from '../../../../core/config-builder/types';

const vote: CustomEndpointConfig = {
    title: 'הצבעות',
    metaInfo: {
        propertiesOrder: ['songId', 'roundId', 'place', 'value', 'createdAt'],
        properties: {
            songId: {
                name: 'שם השיר',
            },
            roundId: {
                name: 'סבב הצבעה',
            },
            place: {
                name: 'מיקום',
            },
            value: {
                name: 'ערך הצבעה',
            },
            createdAt: {
                name: 'תאריך',
            },
        },
    },

    forms: {
        list: {
            // content of the list form
            defaultUrlQueryParams: {
                sort: [
                    {
                        columnId: 'createdAt',
                        direction: 'desc',
                    },
                ],
            },
            columnsOrder: ['songId', 'roundId', 'place', 'value', 'createdAt'],
            columns: {
                songId: {
                    title: 'שם השיר',
                    style: () => {
                        return {
                            fontWeight: 'bold',
                            width: '500px',
                            height: '45px',
                            lineHeight: '45px',
                        };
                    },
                },
                roundId: {
                    title: 'סבב הצבעה',
                },
                place: {
                    title: 'מיקום',
                },
                value: {
                    title: 'ערך הצבעה',
                },
                createdAt: {
                    title: 'תאריך',
                },
            },
            handlers(this: AppContext) {
                return {
                    onBeforeOpen: async () => {
                        this.form.commands = this.form.commands.filter(
                            (item) => item.id !== 'export'
                        );
                    },
                };
            },
            rowStyle(this: AppContext) {
                return {
                    background: 'white',
                };
            },
        },
    },
};

export default vote;
