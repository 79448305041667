import { CSSProperties } from 'react';

export default interface PropsNotification {
    onClose: () => void;
    type?: 'info' | 'warning' | 'error';
    style?: CSSProperties;
    text: string;
    icon?: string;
}

export const defaultProps: Partial<PropsNotification> = {
    type: 'info',
};
