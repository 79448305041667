import moment from 'moment';
import React from 'react';
import {
    AppContext,
    FormFunctionGetHandlers,
} from '../../../../components/form/context/types';
import apiClient from '../../../../requests/api';
import WSClient from '../../../../requests/WSClient';
import style from '../style.module.scss';
import ConfirmDialog from './confirm-dialog';
import getTemplateCode from './template';

const handlers: FormFunctionGetHandlers = function (this: AppContext) {
    const getParams = () => {
        const { siteId, endpointId } = this.form.url.params || {};
        const { event, type } = this.form.url.queryParams || {};
        const { environment } = this.form;

        return {
            environment,
            siteId: siteId === 'undefined' ? undefined : siteId,
            endpointId: endpointId === 'undefined' ? undefined : endpointId,
            scriptEventType: event,
            scriptType: type,
        };
    };

    const updateSiteIdInputValues = () => {
        const { appConfig } = this;
        this.form.views.siteIdInput.values = (appConfig.sites || []).map(
            (item: any) => ({
                label: item.id,
                value: item.id,
            })
        );
    };

    const updateEndpointIdInputValues = (siteId: string) => {
        const siteConfig = this.getSiteConfig(siteId);
        if (!siteConfig) {
            return;
        }
        this.form.views.endpointIdInput.values = siteConfig.endpoints.map(
            (item: any) => ({
                label: item.id,
                value: item.id,
            })
        );
    };

    const saveHistory = (
        environment: string,
        siteId: string | undefined,
        endpointId: string | undefined,
        scriptType: string | undefined,
        eventType: string,
        history: any
    ) => {
        if (!siteId || !endpointId) {
            return;
        }

        localStorage.setItem(
            `${environment}.${siteId}.${endpointId}.${scriptType}${eventType}.History`,
            JSON.stringify(history)
        );
    };

    const updateEditorTitle = () => {
        const { siteId, endpointId, scriptType, scriptEventType } = getParams();
        if (!siteId || !endpointId) {
            this.form.views.editor.title = `Script Editor`;
            return;
        }

        const siteConfig = this.getSiteConfig(siteId);
        const endpointConfig = this.getEndpointConfig(siteId, endpointId);

        if (!siteConfig || !endpointConfig) {
            return;
        }

        this.form.views.editor.title = `Script Editor: ${siteConfig.title}/${endpointConfig.title}/${scriptType}${scriptEventType}`;
    };

    const initConsoleLog = () => {
        const params = getParams();
        const { siteId, endpointId } = params;

        if (!siteId || !endpointId) {
            return;
        }

        this.form.consoleLog = [];
        this.form.wsClient = new WSClient(
            'wss://miniapps.pazamapp.com/v1/ws',
            siteId,
            window._AUTH_TOKEN!
        );
        this.form.wsClient.connect();
        this.form.wsClient.subscribeToConsole(
            `${siteId}${endpointId}`,
            (data: any) => {
                let message;
                if (data.status === 200 || data.status === '200') {
                    message = [
                        `Subscription to ${siteId}/${endpointId} is enabled`,
                    ];
                } else if (
                    data &&
                    data.document &&
                    data.document.type === 'log'
                ) {
                    if (data.document.endpoint !== endpointId) {
                        return;
                    }

                    message = data.document.data;
                }

                const newMessages = [...this.form.consoleLog, message];

                if (newMessages.length > 2000) {
                    newMessages.unshift();
                }

                console.group(`SITE LOG: ${siteId}/${endpointId}`);
                if (Array.isArray(message)) {
                    message.forEach((item) => console.log(item));
                } else {
                    console.log(message);
                }
                console.groupEnd();

                this.form.consoleLog = newMessages;
            }
        );
    };

    const closeConsoleSocket = () => {
        if (!this.form.wsClient) {
            return;
        }

        this.form.wsClient.close();
        this.form.wsClient = null;
    };

    const onBeforeClose = () => {
        closeConsoleSocket();
    };

    const onInsertTemplate = () => {
        const { siteId, endpointId } = getParams();
        if (!siteId || !endpointId) {
            return;
        }

        this.form.code = getTemplateCode({
            siteId: siteId || 'YOUR_SITE_ID',
        });
    };

    const getHistoryFromStore = (
        environment: string,
        siteId: string | undefined,
        endpointId: string | undefined,
        scriptType: string,
        eventType: string
    ) => {
        if (!siteId || !endpointId) {
            return [];
        }

        const stringValue =
            localStorage.getItem(
                `${environment}.${siteId}.${endpointId}.${scriptType}${eventType}.History`
            ) || '[]';
        return JSON.parse(stringValue);
    };

    const loadHistory = () => {
        const { siteId, endpointId, scriptType, scriptEventType } = getParams();
        if (!siteId || !endpointId) {
            this.form.views.historyGroup.items = [];
            return;
        }

        const { environment } = this.form;
        const items = getHistoryFromStore(
            environment,
            siteId,
            endpointId,
            scriptType,
            scriptEventType
        );

        this.form.views.historyGroup.items = items
            .slice()
            .reverse()
            .map((item: any, index: number) => ({
                id: `historyItem${index}`,
                type: 'CustomComponent',
                component: () => (
                    <div
                        className={style.historyItem}
                        onClick={handleClickHistoryItem(item)}
                    >
                        <span>
                            {moment(item.date).format('DD.MM.YYYY hh:mm:ss')}{' '}
                        </span>
                        &nbsp;
                        <span>{item.title}</span>
                    </div>
                ),
            }));

        // this.form.views.history.items = items.slice().reverse();
    };

    const loadScriptDataIfExist = async () => {
        const { siteId, endpointId, scriptType, scriptEventType } = getParams();

        this.form.views.tabContent.isLoading = true;
        this.form.code = '';
        this.form.originalCode = '';
        const { environment } = this.form;

        if (!siteId || !endpointId || !scriptEventType || !scriptType) {
            this.form.views.tabContent.isLoading = false;
            return;
        }

        try {
            this.form.isNewScript = false;
            const code = await apiClient.getScript(
                siteId,
                endpointId,
                scriptType + scriptEventType,
                environment
            );
            this.form.code = code;
            this.form.originalCode = code;

            this.form.isNewScript = true;
        } catch (e) {
            if (e && e.message === '404') {
                this.form.isNewScript = true;
                this.form.views.tabContent.isLoading = false;
            } else {
                console.log('Error', e);
                this.form.notify({
                    text: 'Connection error please reload the page',
                    type: 'error',
                });
            }
        }

        this.form.views.tabContent.isLoading = false;
    };

    const onInsertScriptFromHistory = (text: string) => async () => {
        this.form.code = text;
        this.form.closeDialog();
    };

    const handleClickHistoryItem = (item: any) => (e: any) => {
        e.stopPropagation();
        this.form.pushDialog('yesno', {
            text: 'Do you want to replace script code?',
            yesLabel: 'Replace',
            yesAction: onInsertScriptFromHistory(item.text),
            noLabel: 'Cancel',
        });
    };

    const onToggleHistory = () => {
        const isOpened = !this.form.isHistoryOpened;
        this.form.isHistoryOpened = isOpened;
        this.form.views.historyGroupContainer.className = isOpened
            ? style.historyGroupOpen
            : style.historyGroup;
    };

    const onChangeEnvironment = async () => {
        this.form.code = '';
        loadHistory();
        loadScriptDataIfExist();
    };

    const onChangeSiteId = async (siteId: string) => {
        this.form.setDataSourceValue(
            'url.params.endpointId.replace',
            'undefined'
        );
        this.form.code = '';
        this.form.views.editor.isDisabled = true;
        updateEditorTitle();
        updateEndpointIdInputValues(siteId);
    };

    const onChangeEndpointId = (endpointId: string) => {
        this.form.code = '';
        this.form.views.editor.isDisabled = false;
        updateEditorTitle();
        loadScriptDataIfExist();
    };

    const onDeleteScript = async () => {
        const { siteId, endpointId, scriptType, scriptEventType } = getParams();
        if (!siteId || !endpointId) {
            return;
        }

        this.form.views.tabContent.isLoading = true;
        const { environment } = this.form;

        await apiClient.deleteScript(
            siteId,
            endpointId,
            scriptType + scriptEventType,
            environment
        );
        this.form.closeDialog();
        await loadScriptDataIfExist();
    };

    const onDelete = async () => {
        this.form.pushDialog('yesno', {
            text: 'Delete script?',
            yesAction: onDeleteScript,
            yesLabel: 'Delete',
            noLabel: 'Cancel',
        });
    };

    const onChangeType = async () => {
        updateEditorTitle();
        loadHistory();
        await loadScriptDataIfExist();
    };

    const onChangeEventType = async () => {
        updateEditorTitle();
        loadHistory();
        await loadScriptDataIfExist();
    };

    const saveData = async () => {
        const { code } = this.form;
        const { siteId, endpointId, scriptEventType, scriptType } =
            getParams() || {};

        if (!siteId || !endpointId) {
            return;
        }

        if (!endpointId) {
            this.form.notify({
                text: 'Set endpoint id!',
                type: 'error',
                lifetimeMs: 3000,
            });
            return;
        }

        this.form.views.tabContent.isLoading = true;

        const { environment } = this.form;

        try {
            await apiClient.updateScript(
                siteId,
                endpointId,
                scriptType + scriptEventType,
                code,
                environment
            );
            const history = getHistoryFromStore(
                environment,
                siteId,
                endpointId,
                scriptType,
                scriptEventType
            );

            const date = new Date().toISOString();
            history.push({
                date,
                title: 'Before change',
                text: this.form.originalCode,
            });

            history.push({
                date,
                title: 'After change',
                text: code,
            });

            saveHistory(
                environment,
                siteId,
                endpointId,
                scriptType,
                scriptEventType,
                history
            );
            loadHistory();
        } catch (e) {
            this.form.notify({
                text: 'Save error',
                type: 'error',
            });
        }
        this.form.views.tabContent.isLoading = false;
    };

    const onSave = () => {
        const { environment } = this.form;
        if (environment === 'production') {
            this.form.pushDialog('confirm-production-endpoint-script-change', {
                onContinue: saveData.bind(this),
            });
        } else {
            saveData();
        }
    };

    const onBeforeOpen = async () => {
        this.registerCustomDialog(ConfirmDialog);
        this.form.environment = 'dev';

        initConsoleLog();
        updateEditorTitle();
        loadHistory();
        await loadScriptDataIfExist();

        const { siteId } = getParams();

        updateSiteIdInputValues();
        updateEndpointIdInputValues(siteId);
    };

    const isCodeEditorHidden = () => {
        const { siteId, endpointId, scriptEventType, scriptType, environment } =
            getParams();

        return (
            !siteId ||
            !endpointId ||
            !scriptEventType ||
            !scriptType ||
            !environment
        );
    };

    const isEditorStubHidden = () => {
        const { siteId, endpointId, scriptEventType, scriptType, environment } =
            getParams();

        return (
            siteId && endpointId && scriptEventType && scriptType && environment
        );
    };

    return {
        isCodeEditorHidden,
        isEditorStubHidden,

        onDelete,
        onToggleHistory,
        onChangeSiteId,
        onChangeEndpointId,
        onChangeType,
        onChangeEventType,
        onSave,
        onInsertScriptFromHistory,
        onBeforeOpen,
        onInsertTemplate,
        onBeforeClose,
        onChangeEnvironment,
    };
};

export default handlers;
