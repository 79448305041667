import { AppContext } from '../../../../core/components/form/context/types';
import { CustomEndpointConfig } from '../../../../core/config-builder/types';

const stage: CustomEndpointConfig = {
    metaInfo: {
        properties: {
            createAt: {
                name: 'Create Date',
            },
        },
    },
    forms: {
        list: {
            // content of the list form
            defaultUrlQueryParams: {
                sort: [{ columnId: 'createAt', direction: 'desc' }],
            },
            columnsOrder: [
                'createAt',
                'active',
                'title',
                'description',
                'type',
                'example',
                'openStageButton',
            ],
            columns: {
                openStageButton: {
                    id: 'openStageMedia',
                    type: 'button',
                    text: 'Stage Media',
                    onClick(this: AppContext, item: any) {
                        // Here formContext
                        this.form.url.push(
                            `/cbc/stagemedia/list?filter.stageId.include[]=${item.id}`
                        );
                    },
                },
            },
        },
    },
};

export default stage;
