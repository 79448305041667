import { ResponsiveLine } from '@nivo/line';
import moment from 'moment';
import React, { useEffect, useMemo, useState } from 'react';
import { useAppContextObserver } from '../../../components/form/context';
import DateInput from '../../../components/native/date-input';
import apiClient from '../../../requests/api';
import PropsInsertsChart from './props';
import style from './style.module.scss';

const convertDateFilterToQuery = (
    field: string,
    { startDate, endDate }: any
) => {
    if (!startDate && !endDate) {
        return '';
    }

    const startMoment = moment(startDate, 'YYYY-MM-DDTHH:mm:ss');
    const endMoment = moment(endDate, 'YYYY-MM-DDTHH:mm:ss');

    const dateQueryFormat = 'YYYY-MM-DDTHH:mm:ss';

    if (startDate && endDate) {
        return `${field}:[${startMoment.format(
            dateQueryFormat
        )} TO ${endMoment.format(dateQueryFormat)}]`;
    }

    if (startDate) {
        return `${field}:[${startMoment.format(dateQueryFormat)} TO *]`;
    }

    return `${field}:[* TO ${endMoment.format(dateQueryFormat)}]`;
};

const loadInsertsData = async (
    siteId: string,
    endpointId: string,
    startDate?: string,
    endDate?: string
) => {
    const aggs = {
        aggs: {
            records: {
                date_histogram: {
                    field: 'createdAt',
                    interval: 'day',
                    format: 'yyyy-MM-dd',
                },
            },
        },
    };

    // const { startDate, endDate } = this.form;
    const q = convertDateFilterToQuery('createdAt', {
        startDate: startDate && moment(startDate).format('YYYY-MM-DDTHH:mm:ss'),
        endDate: endDate && moment(endDate).format('YYYY-MM-DDTHH:mm:ss'),
    });

    const queryParams = `size=0&aggs=${JSON.stringify(aggs)}&q=${q}`;
    const response: any = await apiClient.callSearchRequest(
        siteId,
        endpointId,
        queryParams
    );

    return (response.aggregations as any).records.buckets.map((item: any) => {
        return {
            x: item.key_as_string,
            y: item.doc_count,
        };
    });
    // .filter((item: any) => item[1] > 0);

    // this.form.topTeams = topTeams;
};

const getTotal = async (
    siteId: string,
    endpointId: string,
    startDate: string,
    endDate: string
) => {
    const aggs = {
        aggs: {
            games_count: {
                value_count: {
                    field: 'createdAt',
                },
            },
        },
    };

    const q = convertDateFilterToQuery('createdAt', {
        startDate: startDate && moment(startDate).format('YYYY-MM-DDTHH:mm:ss'),
        endDate: endDate && moment(endDate).format('YYYY-MM-DDTHH:mm:ss'),
    });
    const queryParams = `size=0&aggs=${JSON.stringify(aggs)}&q=${q}`;
    const response: any = await apiClient.callSearchRequest(
        siteId,
        endpointId,
        queryParams
    );

    return (response.aggregations as any).games_count.value;
};

const InsertsChart = ({ siteId }: PropsInsertsChart) => {
    const [context] = useAppContextObserver((c) => c);
    const endpoints = useMemo(() => {
        const endpointsIds: string[] = context
            .getSiteConfig(siteId)
            .endpoints.map((item: any) => item.id);
        return endpointsIds;
    }, [context, siteId]);
    const [data, setData] = useState<any>([]);
    const [endpointsTotal, setEndpointsTotal] = useState<any>({});
    const [fromDate, setFromDate] = useState(() =>
        moment().startOf('month').toDate().toISOString()
    );
    const [toDate, setToDate] = useState(() =>
        moment().endOf('month').toDate().toISOString()
    );
    const [currentEndpointId, setCurrentEndpoint] = useState(
        () => endpoints[0]
    );

    useEffect(() => {
        const loadData = async (endpointId: string) => {
            const items = await loadInsertsData(
                siteId,
                endpointId,
                fromDate,
                toDate
            );
            setData([
                {
                    id: endpointId,
                    data: items,
                },
            ]);
        };
        loadData(currentEndpointId);
    }, [siteId, currentEndpointId, fromDate, toDate]);

    useEffect(() => {
        const loadData = async (endpointId: string) => {
            endpoints.map(async (endpointId) => {
                const total = await getTotal(
                    siteId,
                    endpointId,
                    fromDate,
                    toDate
                );

                setEndpointsTotal((prev: any) => ({
                    ...prev,
                    [endpointId]: total,
                }));
            });
        };
        loadData(currentEndpointId);
    }, [siteId, endpoints, fromDate, toDate, currentEndpointId]);

    const handleSelectEndpoint = (id: string) => (e: any) => {
        e.stopPropagation();
        setCurrentEndpoint(id);
    };

    const onChangeFrom = (newValue: any) => {
        setFromDate(newValue);
    };

    const onChangeTo = (newValue: any) => {
        setToDate(newValue);
    };

    return (
        <div className={style.chartContainer}>
            <div className={style.toptopRow}>
                <DateInput
                    id="from"
                    value={fromDate}
                    onChange={onChangeFrom}
                    label="From Date"
                />

                <DateInput
                    id="to"
                    value={toDate}
                    onChange={onChangeTo}
                    label="To Date"
                />
            </div>
            <div className={style.topRow}>
                <div className={style.currentChartTotalContainer}>
                    <div className={style.currentChartTotal}>
                        {endpointsTotal[currentEndpointId] ?? 0}
                    </div>
                    <div className={style.currentChartTotalLabel}>Inserts</div>
                </div>
                <div className={style.chart}>
                    <ResponsiveLine
                        data={data}
                        margin={{ top: 50, right: 110, bottom: 50, left: 60 }}
                        colors={['red']}
                        pointSize={10}
                        pointColor={{ theme: 'background' }}
                        pointBorderWidth={2}
                        pointBorderColor={{ from: 'serieColor' }}
                        pointLabel="y"
                        pointLabelYOffset={-12}
                        useMesh
                        legends={[
                            {
                                anchor: 'bottom-right',
                                direction: 'column',
                                justify: false,
                                translateX: 100,
                                translateY: 0,
                                itemsSpacing: 0,
                                itemDirection: 'left-to-right',
                                itemWidth: 80,
                                itemHeight: 20,
                                itemOpacity: 0.75,
                                symbolSize: 12,
                                symbolShape: 'circle',
                                symbolBorderColor: 'rgba(0, 0, 0, .5)',
                                effects: [
                                    {
                                        on: 'hover',
                                        style: {
                                            itemBackground:
                                                'rgba(0, 0, 0, .03)',
                                            itemOpacity: 1,
                                        },
                                    },
                                ],
                            },
                        ]}
                        xScale={{
                            type: 'time',
                            format: '%Y-%m-%d',
                            useUTC: false,
                            precision: 'day',
                        }}
                        xFormat="time:%Y-%m-%d"
                        yScale={{
                            type: 'linear',
                            // stacked: boolean('stacked', false),
                        }}
                        axisLeft={{
                            legend: 'Inserts',
                            legendOffset: 12,
                        }}
                        axisBottom={{
                            format: '%b %d',
                            tickValues: 'every 2 days',
                            legend: 'Date',
                            legendOffset: -22,
                        }}
                        curve="monotoneX"
                        enablePointLabel
                        // pointSymbol={CustomSymbol}
                        enableSlices={false}
                    />
                    {(!data ||
                        data.length === 0 ||
                        !data[0].data ||
                        data[0].data.length === 0) && (
                        <div className={style.dataStub}>No Data</div>
                    )}
                </div>
            </div>
            <div className={style.bottomRow}>
                {endpoints.map((endpointId) => {
                    const total = endpointsTotal[endpointId];
                    return (
                        <div
                            className={style.endpointTab}
                            onClick={handleSelectEndpoint(endpointId)}
                            data-active={endpointId === currentEndpointId}
                            key={endpointId}
                        >
                            <div className={style.endpointTotal}>
                                {total ?? 0}
                            </div>
                            <div className={style.endpointTitle}>
                                {endpointId}
                            </div>
                        </div>
                    );
                })}
            </div>
        </div>
    );
};

export default InsertsChart;
