export interface MediaPhotoItemData {
    id: string;
    isUploadError?: boolean;
    isUploading?: boolean;
    progress: number;
    URL: string;
    data?: {
        mime: string;
    };
}

export default interface PropsMediaPhotoItem {
    isInEditMode: boolean;
    isDraggable?: boolean;
    itemData: MediaPhotoItemData;
    onClick: () => void;
    onDelete?: () => void;
    onOpen: () => void;
    apiServerAddress?: string;
    width?: number | string;
    height?: number | string;
}

export const defaultProps: Partial<PropsMediaPhotoItem> = {
    isDraggable: true,
    width: 140,
    height: 140,
    apiServerAddress: '',
};
