import React from 'react';
import LoginForm from './form';
import { PropsFormColumn } from './props';
import style from './style.module.scss';

const FormColumn = (props: PropsFormColumn) => {
    const { title } = props;
    return (
        <div className={style.container}>
            <div className={style.formContainer}>
                <div className={style.loginTitles}>
                    <h2 className={style.headerTitle}>LOGIN TO</h2>
                    <h1 className={style.subTitle}>{title}</h1>
                </div>
                <LoginForm />
                <div className={style.footer}>
                    <div className={style.email}>
                        Any question? Please email us at suport@pazam.com
                    </div>
                    <div className={style.copyrights}>
                        © 2018. Powered by pazam.com.
                    </div>
                </div>
            </div>
        </div>
    );
};

export default React.memo(FormColumn);
