enum ViewTypes {
    VStack = 'VStack',
    HStack = 'HStack',
    Text = 'Text',
    DataTable = 'DataTable',
    TextInput = 'TextInput',
    NumberInput = 'NumberInput',
    DateInput = 'DateInput',
    BooleanInput = 'BooleanInput',
    TabsGroup = 'TabsGroup',
    MediaField = 'MediaField',
    ReferenceInput = 'ReferenceInput',
    EnumInput = 'EnumInput',
    SearchInput = 'SearchInput',
    Button = 'Button',
    CustomComponent = 'CustomComponent',
    MemoryTable = 'MemoryTable',
    UploadInput = 'UploadInput',
    HtmlInput = 'HtmlInput',
    Counter = 'Counter',
    List = 'List',
    Form = 'Form',
    ListForm = 'ListForm',
    ItemForm = 'ItemForm',
    TabPage = 'TabPage',
    IntervalInput = 'IntervalInput',
    UrlInputWithParams = 'UrlInputWithParams',
    AutoCompleteInput = 'AutoCompleteInput',
    ColorInput = 'ColorInput',
    InnerItems = 'InnerItems',
    Code = 'CodeEditor',
}

export default ViewTypes;
