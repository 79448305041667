import React from 'react';
import PropsFooter from './props';
import style from './style.module.scss';

const Footer = ({ onSave, onCancel }: PropsFooter) => {
    return (
        <div className={style.container}>
            <div className={style.activeButton} onClick={onSave}>
                Save
            </div>
            <div className={style.notActiveButton} onClick={onCancel}>
                Cancel
            </div>
            <div className={style.stub} />
        </div>
    );
};

export default React.memo(Footer);
