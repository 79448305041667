import { FileData } from '../../native/upload-view/props';
import { HandlerValue, ValueOrHandler } from '../context/types';

interface PropsUploadInput {
    id: string;
    isDisabled?: ValueOrHandler<boolean>;
    isHidden?: ValueOrHandler<boolean>;
    title?: string;
    fileType?: 'dataUrl' | 'text';
    onSelectFile: HandlerValue<(files: FileData[]) => void>;
    maxSizeKB: number;
    isLoading?: boolean;
    isSelected?: boolean;
    onDelete?: () => void;
    extensionsWithDot?: string[];
}

export const defaultProps: Partial<PropsUploadInput> = {
    title: 'Upload file',
    fileType: 'text',
};

export default PropsUploadInput;
