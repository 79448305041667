import { CustomEndpointConfig } from '../../../../core/config-builder/types';
import { getUserName } from '../../commonFunctions';

const surveyuseranswer: CustomEndpointConfig = {
    metaInfo: {
        properties: {
            userId: {
                type: 'string',
                format: 'reference',
                siteId: 'cbc',
                endpointId: 'profile',
                idField: 'userId',
                sortListByField: 'firstname',
                searchByField: ['email', 'firstname', 'lastname', 'phone'],
                representationDataSource: getUserName,
            },
            answerId: {
                type: 'string',
                format: 'reference',
                siteId: 'cbc',
                endpointId: 'surveyanswer',
                sortListByField: '_id',
                representationDataSource: 'id',
            },
            questionId: {
                type: 'string',
                format: 'reference',
                siteId: 'cbc',
                endpointId: 'surveyquestion',
                sortListByField: '_id',
                representationDataSource: 'id',
            },
        },
    },
};

export default surveyuseranswer;
