import { VStack } from '../../../../../components/form/context/types';
import ViewTypes from '../../../../../components/form/ViewTypes';
import style from './style.module.scss';

const content: VStack = {
    id: 'rootGroup',
    type: ViewTypes.VStack,
    items: [
        {
            id: 'dialogTitle',
            type: ViewTypes.Text,
            value: 'Confirm change',
            className: style.title,
        },
        {
            id: 'fieldsGroup',
            type: ViewTypes.VStack,
            className: style.fieldsGroup,
            items: [
                {
                    id: 'descriptionText',
                    type: ViewTypes.Text,
                    className: style.descriptionText,
                    dataSource: 'descriptionText',
                },
            ],
        },
        {
            id: 'footer',
            type: ViewTypes.HStack,
            items: [
                {
                    id: 'continueButton',
                    type: ViewTypes.Button,
                    text: 'Continue',
                    onClick: 'onContinue',
                    style: { marginLeft: 15 },
                },
                {
                    id: 'cancelButton',
                    type: ViewTypes.Button,
                    role: 'notActive',
                    text: 'Cancel',
                    onClick: 'onClose',
                },
            ],
        },
    ],
};

export default content;
