import React from 'react';
import Checkbox from '../../../../checkbox';
import PropsCellSelect from './props';
import style from './style.module.scss';

const CellSelect = ({ isSelected, onToggle }: PropsCellSelect) => {
    return (
        <div className={style.cell} onClick={(e) => e.stopPropagation()}>
            <Checkbox
                state={isSelected ? 'checked' : 'unchecked'}
                onToggle={onToggle}
            />
        </div>
    );
};

export default React.memo(CellSelect);
