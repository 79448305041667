import { VStack } from '../../../../../core/components/form/context/types';
import ViewTypes from '../../../../../core/components/form/ViewTypes';
import { getUserName } from '../../../commonFunctions';
import SelectCell from '../SelectCell';

const content: VStack = {
    id: 'rootGroup',
    type: ViewTypes.VStack,
    items: [
        {
            id: 'filterGroup',
            type: ViewTypes.HStack,
            style: { marginBottom: 30 },
            items: [
                {
                    id: 'startDate',
                    type: ViewTypes.DateInput,
                    label: 'תאריך התחלה',
                    // style: { width: 350, marginLeft: 10 },
                    showClearButton: true,
                    dataSource: 'startDate',
                    //showTimePicker: true,
                    onChangeValue: 'onChangeStartDate',
                },
                {
                    id: 'endDate',
                    type: ViewTypes.DateInput,
                    label: 'תאריך סוף',
                    // style: { width: 350, marginLeft: 10 },
                    showClearButton: true,
                    dataSource: 'endDate',
                    onChangeValue: 'onChangeEndDate',
                },
            ],
        },
        {
            id: 'countersGroup',
            type: ViewTypes.HStack,
            style: { marginBottom: 30 },
            items: [
                {
                    id: 'playedGamesNumber',
                    type: ViewTypes.Counter,
                    label: 'מס׳ משחקים',
                    style: { marginLeft: 10 },
                    dataSource: 'playedGamesNumber',
                },
                {
                    id: 'playersNumber',
                    type: ViewTypes.Counter,
                    label: 'מס׳ שחקנים',
                    style: { marginLeft: 10 },
                    dataSource: 'numberOfPlayers',
                },
                {
                    id: 'numberOfActiveUsers',
                    type: ViewTypes.Counter,
                    label: 'שחקנים פעילים',
                    style: { marginLeft: 10 },
                    dataSource: 'numberOfActiveUsers',
                },
                {
                    id: 'averagePlayTime',
                    type: ViewTypes.Counter,
                    label: 'זמן משחק ממוצע',
                    style: { marginLeft: 10 },
                    dataSource: 'averagePlayTime',
                },
                {
                    id: 'averageBottlesCollected',
                    type: ViewTypes.Counter,
                    label: 'בקבוקים שנאספו בממוצע',
                    dataSource: 'averageBottlesCollected',
                },
                {
                    id: 'totalBottlesCollected',
                    type: ViewTypes.Counter,
                    label: 'סהכ בקבוקים שנאספו',
                    dataSource: 'totalBottlesCollected',
                },
            ],
        },
        {
            id: 'tablesGroup',
            type: ViewTypes.VStack,
            items: [
                {
                    id: 'topTeamsTitle',
                    type: ViewTypes.Text,
                    style: { fontSize: 30, fontWeight: 'bold' },
                    value: 'שחקנים מובילים כללי',
                },
                {
                    id: 'topUsersTable',
                    type: ViewTypes.MemoryTable,
                    isSelectable: false,
                    dataSource: 'topUsers',
                    onClickItem: 'onOpenUserCard',
                    onChangeSort: 'onChangeTopUserSort',
                    sortDataSource: 'topUsersTableSort',
                    pageDataSource: 'url.queryParams.topUsersTotalPage.replace',
                    onBeforeLoadReferences: 'onBeforeLoadReferences',
                    columns: [
                        {
                            id: 'userId',
                            title: 'משתמש',
                            type: 'reference',
                            endpointId: 'user',
                            representationDataSource: getUserName,
                            idField: 'username',
                            fieldName: 'userId',
                            linkTarget: '_blank',
                        },
                        {
                            id: 'phone',
                            title: 'מספר טלפון',
                            type: 'button',
                            text: 'מספר טלפון',
                            onClick: 'copyPhone',
                        },
                        {
                            id: 'scores',
                            title: 'משחקים',
                            type: 'button',
                            text: 'משחקים',
                            onClick(item: any) {
                                const context: any = this;

                                // Here formContext
                                context.form.url.push(
                                    `/cbc/flappyscore/list?filter.userId.include[]=${item.userId}`
                                );
                            },
                        },
                        {
                            id: 'score',
                            title: 'תוצאה',
                            type: 'number',
                            fieldName: 'score',
                        },
                        {
                            id: 'time',
                            title: 'זמן',
                            type: 'number',
                            fieldName: 'time',
                        },
                        {
                            id: 'distance',
                            title: 'מרחק',
                            type: 'number',
                            fieldName: 'distance',
                        },
                        {
                            id: 'objects',
                            title: 'בקבוקים',
                            type: 'number',
                            fieldName: 'objects',
                        },
                    ],
                },
                {
                    id: 'topTeamsTitle',
                    type: ViewTypes.Text,
                    style: { fontSize: 30, fontWeight: 'bold' },
                    value: 'קבוצות מובילות',
                },
                {
                    id: 'topTeamsTable',
                    type: ViewTypes.MemoryTable,
                    isSelectable: false,
                    onClickItem: 'onSelectTopTeamRow',
                    sortDataSource: 'topTeamsTableSort',
                    onChangeSort: 'onChangeTopTeamsTableSort',
                    pageDataSource: 'url.queryParams.topTeamsPage.replace',
                    columns: [
                        {
                            id: 'isSelected',
                            fieldName: 'isSelected',
                            width: 30,
                            title: '',
                            type: 'boolean',
                            CellComponent: SelectCell,
                            onSelect: 'onSelectTopTeamRow',
                        },
                        {
                            id: 'teamId',
                            title: 'קבוצה',
                            type: 'reference',
                            siteId: 'cbc',
                            endpointId: 'flappyteam',
                            representationDataSource: 'title',
                            idField: '_id',
                            fieldName: 'teamId',
                        },
                        {
                            id: 'score',
                            title: 'תוצאה',
                            type: 'number',
                            fieldName: 'score',
                        },
                        {
                            id: 'time',
                            title: 'זמן',
                            type: 'number',
                            fieldName: 'time',
                        },
                        {
                            id: 'distance',
                            title: 'מרחק',
                            type: 'number',
                            fieldName: 'distance',
                        },
                        {
                            id: 'objects',
                            title: 'בקבוקים',
                            type: 'number',
                            fieldName: 'objects',
                        },
                    ],
                    dataSource: 'topTeams',
                },
                {
                    id: 'topUsersInTeamTitle',
                    type: ViewTypes.Text,
                    style: {
                        fontSize: 30,
                        fontWeight: 'bold',
                        marginTop: 30,
                        direction: 'ltr',
                        textAlign: 'right',
                    },
                    dataSource: 'topUsersInTeamTitle',
                },
                {
                    id: 'topUsersInTeamTable',
                    type: ViewTypes.MemoryTable,
                    isSelectable: false,
                    dataSource: 'topUsersInTeam',
                    onClickItem: 'onOpenUserCard',
                    sortDataSource: 'topUsersInTeamTableSort',
                    pageDataSource: 'url.queryParams.topUsersPage.replace',
                    onBeforeLoadReferences: 'onBeforeLoadReferences',
                    columns: [
                        {
                            id: 'teamId',
                            title: 'קבוצה',
                            type: 'reference',
                            siteId: 'cbc',
                            endpointId: 'flappyteam',
                            representationDataSource: 'title',
                            idField: '_id',
                            fieldName: 'teamId',
                        },
                        {
                            id: 'userId',
                            title: 'משתמש',
                            type: 'reference',
                            endpointId: 'user',
                            representationDataSource: getUserName,
                            idField: 'username',
                            fieldName: 'userId',
                            linkTarget: '_blank',
                        },
                        {
                            id: 'phone',
                            title: 'מספר טלפון',
                            type: 'button',
                            text: 'מספר טלפון',
                            onClick: 'copyPhone',
                        },
                        {
                            id: 'scores',
                            title: 'משחקים',
                            type: 'button',
                            text: 'משחקים',
                            onClick(item: any) {
                                const context: any = this;

                                // Here formContext
                                context.form.url.push(
                                    `/cbc/flappyscore/list?filter.userId.include[]=${item.userId}`
                                );
                            },
                        },
                        {
                            id: 'score',
                            title: 'תוצאה',
                            type: 'number',
                            fieldName: 'score',
                        },
                        {
                            id: 'time',
                            title: 'זמן',
                            type: 'number',
                            fieldName: 'time',
                        },
                        {
                            id: 'distance',
                            title: 'מרחק',
                            type: 'number',
                            fieldName: 'distance',
                        },
                        {
                            id: 'objects',
                            title: 'בקבוקים',
                            type: 'number',
                            fieldName: 'objects',
                        },
                    ],
                },
            ],
        },
    ],
};

export default content;
