import {
    AppContext,
    FormFunctionGetHandlers,
} from '../../../components/form/context/types';
import apiClient from '../../../requests/api';
import { sleep } from '../../../utils';
import Selection from '../../../utils/Selection';
import defaultHandlers from '../defaultHandlers';
import { DialogParamsDeleteItem } from './params';

const handlers: FormFunctionGetHandlers = function (this: AppContext) {
    const getParams = (): DialogParamsDeleteItem => {
        return this.form.params;
    };

    const onDeleteInfiniteSelection = async (
        siteId: string | undefined,
        endpointId: string,
        selection: Selection,
        filter: any[],
        searchPhrase?: string
    ) => {
        const { deleteFunction: customDeleteFunction } = getParams();
        let total = -1;
        let index = 0;

        this.form.progressText = 'Processing...';

        while (true) {
            const { items, total: remoteTotal } =
                await apiClient.getItemsFromTableSelection(
                    siteId,
                    endpointId,
                    [
                        {
                            field: '_id',
                            direction: 'asc',
                        },
                    ],
                    selection,
                    filter || [],
                    0,
                    50,
                    searchPhrase
                );

            if (remoteTotal === 0) {
                break;
            }

            if (total === -1) {
                total = remoteTotal;
            }

            for (let i = 0; i < items.length; i++) {
                const item = items[i];

                this.form.progressText = `Deleting ${index + 1}/${total}...`;

                try {
                    const deleteFunction =
                        customDeleteFunction ?? apiClient.deleteTableItem;
                    await deleteFunction(siteId, endpointId, item.id);
                } catch (e) {
                    if (e === 'Coupon not found') {
                        await sleep(2000);
                        continue;
                    }
                    throw e;
                }

                index++;
            }

            if (index + 1 > total) {
                break;
            }

            await sleep(1000);
        }

        await sleep(1000);
    };

    const deleteItems = async (
        siteId: string | undefined,
        endpointId: string,
        items: string[]
    ) => {
        const { deleteFunction: customDeleteFunction } = getParams();
        for (let i = 0; i < items.length; i++) {
            const itemId = items[i];

            this.form.progressText = `Deleting ${i + 1}/${items.length}...`;
            const deleteFunction =
                customDeleteFunction ?? apiClient.deleteTableItem;
            await deleteFunction(siteId, endpointId, itemId);
        }
    };

    const onDeleteSimpleSelection = async (
        siteId: string | undefined,
        endpointId: string,
        selection: Selection
    ) => {
        const items = selection.getSelectedItems();
        await deleteItems(siteId, endpointId, items);
    };

    const onApply = async () => {
        const {
            items,
            siteId,
            endpointId,
            filter,
            searchPhrase,
            onSuccess,
            selection: serializedSelection,
            onApply: customOnApply,
        } = getParams();

        if (customOnApply) {
            this.form.isLoading = true;
            await customOnApply();
            this.form.isLoading = false;
            return;
        }
        const selection = Selection.unserealizeFromObject(serializedSelection);

        this.form.isLoading = true;

        let isSuccess = true;
        try {
            if (items && items.length > 0) {
                await deleteItems(siteId, endpointId!, items);
            } else if (selection.isInfinite()) {
                await onDeleteInfiniteSelection(
                    siteId,
                    endpointId!,
                    selection,
                    filter ?? [],
                    searchPhrase
                );
            } else {
                await onDeleteSimpleSelection(siteId, endpointId!, selection);
            }

            await sleep(1500);
        } catch (e) {}

        this.form.closeDialog();
        this.form.isLoading = false;

        if (isSuccess) {
            this.form.notify({
                text: 'Success delete items!',
                lifetimeMs: 3000,
            });
            if (!onSuccess) {
                window.location.reload();
            } else {
                onSuccess();
            }
        } else {
            this.form.notify({ type: 'error', text: 'Error delete items' });
            console.error('Error delete items');
        }
    };

    const getTotal = async () => {
        const {
            siteId,
            endpointId,
            filter,
            searchPhrase,
            selection: serializedSelection,
        } = getParams();

        const selection = Selection.unserealizeFromObject(serializedSelection);

        const { total } = await apiClient.getItemsFromTableSelection(
            siteId,
            endpointId!,
            [
                {
                    field: '_id',
                    direction: 'asc',
                },
            ],
            selection,
            filter || [],
            0,
            0,
            searchPhrase
        );

        return total;
    };

    const onBeforeOpen = async () => {
        const {
            siteId,
            endpointId,
            getTotal: customGetTotal,
            items,
        } = getParams();

        const getTotalFunction = customGetTotal || getTotal;
        const total = items ? items.length : await getTotalFunction();

        this.form.views.text.value = `Are you sure to delete ${total} items from "${
            siteId ? siteId : ''
        }/${endpointId}"?`;
    };

    return {
        ...defaultHandlers.call(this),
        onApply,
        onBeforeOpen,
    };
};

export default handlers;
