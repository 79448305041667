import { ItemFormConfig } from '../../../../core/config-builder/forms/item/types';

const item: ItemFormConfig = {
    title: 'פריטים',
    isAllowed: ['admin', /[a-zA-Z0-9]+Admin$/],
    metaInfo: {
        properties: {
            title: {
                name: 'כותרת',
            },
            userId: {
                name: 'שם משתמש',
            },
            count: {
                name: 'כמות ',
            },
            createdAt: {
                name: 'נוצר ב',
            },
            listId: {
                name: 'רשימה',
            },
            done: {
                name: 'הושלם',
            },
        },
    },
};

export default item;
