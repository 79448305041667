import React from 'react';
import Checkbox from '../../../../checkbox';
import { CheckBoxState } from '../../../../checkbox/props';
import useEnumFilterState from './hooks';
import PropsColumnEnumFilter from './props';
import style from './style.module.scss';

const EnumFilter = (props: PropsColumnEnumFilter) => {
    const { columnId, values, isOpened, onToggleOpen, isRightSide } = props;
    const { isFiltered, selection, onToggleAll, onToggleItem, onApply } =
        useEnumFilterState(props);

    const _renderAllValuesRow = () => {
        let allCheckboxState: CheckBoxState = 'unchecked';

        if (selection.isAllItemsSelected()) {
            allCheckboxState = 'checked';
        } else if (
            selection.isInfinite() ||
            selection.getNumberOfSelectedItems()
        ) {
            allCheckboxState = 'intermediate';
        }

        return (
            <div key="all" className={style.item}>
                <Checkbox state={allCheckboxState} onToggle={onToggleAll} />
                <span>All</span>
            </div>
        );
    };

    const _renderValue = ({ value, label }: any) => {
        return (
            <div key={value} className={style.item}>
                <Checkbox
                    state={
                        selection.isItemSelected(value)
                            ? 'checked'
                            : 'unchecked'
                    }
                    onToggle={onToggleItem(value)}
                />
                <span>{label}</span>
            </div>
        );
    };

    return (
        <div className={style.enumFilter}>
            <div
                className={
                    isOpened ? style.currentValueOpened : style.currentValue
                }
                onClick={() => onToggleOpen(columnId)}
            >
                {!isFiltered && 'All'}
                {isFiltered && <span className={style.filtered}>Filtered</span>}
            </div>
            {isOpened && (
                <div
                    className={isOpened ? style.menuOpened : style.menu}
                    data-is-right-side={String(!!isRightSide)}
                >
                    <div className={style.items}>
                        {_renderAllValuesRow()}
                        {values.map(_renderValue)}
                    </div>
                    <div className={style.buttons}>
                        <div className={style.applyButton} onClick={onApply}>
                            Apply
                        </div>
                        <div
                            className={style.cancelButton}
                            onClick={() => onToggleOpen(columnId)}
                        >
                            Cancel
                        </div>
                    </div>
                </div>
            )}
        </div>
    );
};

export default React.memo(EnumFilter);
