import { useMemo, useState } from 'react';
import { FileData } from '../upload-view/props';
import PropsPhotoView from './props';

const usePhotoViewState = (props: PropsPhotoView) => {
    const { isProcessing, saveProgressItemId, errors, saveProgressPercent } =
        props;
    const [isLightboxOpen, setIsLightboxOpen] = useState(false);
    const [currentItem, setCurrentItem] = useState(0);

    const onSelectItem = (itemId: string) => {
        const index = props.items.findIndex((item) => item.id === itemId);
        setCurrentItem(index);
        setIsLightboxOpen(true);
    };

    const onCloseLightbox = () => {
        setCurrentItem(0);
        setIsLightboxOpen(false);
    };

    const onGotoPrevious = () => {
        setCurrentItem((prev) => prev - 1);
    };

    const onGoToNext = () => {
        setCurrentItem((prev) => prev + 1);
    };

    const onGoToImage = (index: number) => {
        setCurrentItem(index);
    };

    const onSave = () => {
        props.save();
    };

    const onAddItem = (files: FileData[]) => {
        props.items.forEach((item) => {
            props.deleteItem(item.id);
        });

        files.forEach((fileData) => {
            props.addItem(fileData.data);
        });

        if (props.saveOnSelect) {
            props.save();
        }
    };

    const onDeleteItem = (itemId: string) => () => {
        props.deleteItem(itemId);
        if (props.saveOnSelect) {
            props.save();
        }
    };

    const items = useMemo(() => {
        if (!isProcessing) {
            return props.items;
        }

        return props.items.map((item) => {
            const itemId = item.id;
            const uploadError = errors.find(
                (error) =>
                    error.state === 'uploadItems' && error.itemId === itemId
            );

            if (uploadError) {
                return {
                    ...item,
                    isUploadError: true,
                };
            }

            if (itemId !== saveProgressItemId) {
                return item;
            }

            return {
                ...item,
                isUploading: true,
                progress: saveProgressPercent,
            };
        });
    }, [
        isProcessing,
        props.items,
        errors,
        saveProgressItemId,
        saveProgressPercent,
    ]);

    const imageLinks = useMemo(() => {
        if (items.length === 0) {
            return [];
        }

        return items.map((obj) => {
            return {
                src: obj.URL,
                sources:
                    obj.ref === 'video'
                        ? [
                              {
                                  url: obj.URL,
                                  type: `video/${obj.ext}`,
                              },
                          ]
                        : undefined,
            };
        });
    }, [items]);

    const onToggleEditMode = () => {};

    return {
        isLightboxOpen,
        currentItem,
        items,
        imageLinks,
        onGoToNext,
        onGotoPrevious,
        onCloseLightbox,
        onGoToImage,
        onDeleteItem,
        onSelectItem,
        onToggleEditMode,
        onSave,
        onAddItem,
    };
};

export default usePhotoViewState;
