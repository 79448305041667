import { ContentState, convertToRaw, EditorState } from 'draft-js';
import draftToHtml from 'draftjs-to-html';
import htmlToDraft from 'html-to-draftjs';
import React, { useState } from 'react';
import ReactDOM from 'react-dom';
import { Editor } from 'react-draft-wysiwyg';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import propsWithDefaultValues from '../../../../utils/propsWithDefaultValues';
import Footer from './footer';
import PropsHtmlEditor, { defaultProps } from './props';
import style from './style.module.scss';

const getFileBase64 = (file: any, callback: any) => {
    var reader = new FileReader();
    reader.readAsDataURL(file);
    // Since FileReader is asynchronous,
    // we need to pass data back.
    reader.onload = () => callback(reader.result);
    // TODO: catch an error
    reader.onerror = (error) => {};
};

const uploadImageCallBack = (file: any): any => {
    return new Promise((resolve, reject) => {
        if (file) {
            const reader = new FileReader();
            reader.onload = (): any => {
                resolve({ data: { link: reader.result } });
            };
            reader.onerror = (er): any => {
                console.log('error: ', er);
            };
            reader.readAsDataURL(file);
        }
    });
};

const getEditorStateFromMarkup = (htmlMarkup: string): EditorState => {
    const blocksFromHTML = htmlToDraft(htmlMarkup);
    const state = ContentState.createFromBlockArray(
        blocksFromHTML.contentBlocks,
        blocksFromHTML.entityMap
    );
    return EditorState.createWithContent(state);
};

const HtmlEditor = (props: PropsHtmlEditor) => {
    props = propsWithDefaultValues(props, defaultProps);
    const { into, onClose } = props;

    const [editorState, setEditorState] = useState(() =>
        getEditorStateFromMarkup(props.value)
    );

    const onSave = () => {
        props.onChange(
            draftToHtml(convertToRaw(editorState.getCurrentContent()))
        );
        onClose();
    };

    const onCancel = () => {
        onClose();
    };

    return ReactDOM.createPortal(
        <div className={style.htmlEditorWrapper}>
            <Editor
                toolbar={{
                    options: [
                        'inline',
                        'blockType',
                        'fontSize',
                        'list',
                        'textAlign',
                        'link',
                        'embedded',
                        'emoji',
                        'image',
                        'remove',
                    ],
                    embedded: {
                        defaultSize: {
                            height: 'auto',
                            width: '100%',
                        },
                    },
                    image: {
                        uploadCallback: uploadImageCallBack,
                        previewImage: true,
                        image: {
                            defaultSize: {
                                height: 'auto',
                                width: '100%',
                            },
                        },
                    },
                    textAlign: { inDropdown: true },
                }}
                toolbarClassName={style.toolbar}
                wrapperClassName={style.htmlEditor}
                editorClassName={style.editor}
                editorState={editorState}
                onEditorStateChange={setEditorState}
                textAlignment="right"
            />
            <Footer onSave={onSave} onCancel={onCancel} />
        </div>,
        into
    );
};

export default React.memo(HtmlEditor);
