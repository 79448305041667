import {
    AppContext,
    FormFunctionGetHandlers,
    FormHandlers,
} from '../../../components/form/context/types';
import settingsHandlers from './settings/handlers';

const handlers: FormFunctionGetHandlers = function (
    this: AppContext
): FormHandlers {
    const onGoBack = () => {
        this.form.url.goBack();
    };

    return {
        ...settingsHandlers.call(this),
        onGoBack,
    };
};

export default handlers;
