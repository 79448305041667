import { useEffect, useRef } from 'react';
import { useTransition } from 'react-spring';
import { useGlobalStoreObserver } from '../../globalStore/definition';
import { useStateAsync, useUnmountedFlag } from '../../utils';
import PropsNotifications from './props';

const config = { tension: 125, friction: 20, precision: 0.1 };

const useNotificationsState = (props: PropsNotifications) => {
    const [{ items, hideNotification }] = useGlobalStoreObserver((s) => ({
        hideNotification: s.notifications.dispatch.hideNotification,
        items: s.notifications.state.items,
    }));

    const { isUnmounted } = useUnmountedFlag();

    const [refMap] = useStateAsync(isUnmounted, () => new WeakMap());
    const [cancelMap] = useStateAsync(isUnmounted, () => new WeakMap());
    const [animatedItems, setAnimatedItems] = useStateAsync(
        isUnmounted,
        [] as any[]
    );

    const addNextAnimatedItem = () => {
        setAnimatedItems((prevAnimatedItems: any) => {
            const nextAnimatedItems = prevAnimatedItems.map((item: any) => {
                return items.find((i) => i.id === item.id) || item;
            });

            for (const item of items) {
                if (nextAnimatedItems.find((i: any) => i.id === item.id)) {
                    continue;
                }

                nextAnimatedItems.push(item);
                break;
            }

            return nextAnimatedItems.filter((item: any) =>
                items.find((i) => i.id === item.id)
            );
        });
    };

    const refAddNextAnimatedItem = useRef(addNextAnimatedItem);
    refAddNextAnimatedItem.current = addNextAnimatedItem;

    useEffect(() => {
        refAddNextAnimatedItem.current();
    }, [items]);

    useEffect(() => {
        const removeItems = () => {
            setAnimatedItems((prevAnimatedItems: any[]) => {
                const ids = items.map((item) => item.id);
                return prevAnimatedItems.filter((item: any) =>
                    ids.includes(item.id)
                );
            });
        };

        removeItems();
    }, [items, setAnimatedItems]);

    const transitions = useTransition(animatedItems, (item: any) => item.id, {
        from: { opacity: 0, height: 0 },
        enter: (item: any) => async (next: any) => {
            setTimeout(() => addNextAnimatedItem(), 200);
            await next({ opacity: 1, height: refMap.get(item).offsetHeight });
        },
        leave: (item: any) => async (next: any, cancel: any) => {
            cancelMap.set(item, cancel);
            await next({ opacity: 0 });
            await next({ height: 0 });
        },
        config: (item: any, state: any) =>
            state === 'leave' ? [{ duration: 300 }, config, config] : config,
    } as any);

    const onCloseNotification = (notificationId: string) => () => {
        hideNotification({ id: notificationId });
    };

    return {
        transitions,
        onCloseNotification,
        refMap,
    };
};

export default useNotificationsState;
