import lottieNewBanner from './lottie-banner-new';
import lottieNewSplash from './lottie-splash-new';
import lottieNewSplashCutBottom from './lottie-splash-new-cut-bottom';
import lottieNewSplashCutTop from './lottie-splash-new-cut-top';
export interface TemplateData {
    id: string;
    name: string;
    htmlTemplate: string;
    defaultData?: any;
    dataSchema?: DataDescription;
    form?: any;
    previewSize: {
        width: number;
        height: number;
    };
}

export interface FieldDescription {
    id: string;
    type: 'string';
    name: string;
    isRequired?: boolean;
}

export type FilePath = string;

export interface DataDescription {
    requiredFiles?: FilePath[];
    fields?: FieldDescription[];
}

const templates: TemplateData[] = [
    lottieNewSplash,
    lottieNewBanner,
    lottieNewSplashCutTop,
    lottieNewSplashCutBottom,
];
export default templates;
