import {
    AppContext,
    FormFunctionGetHandlers,
} from '../../../../components/form/context/types';
import apiClient from '../../../../requests/api';
import {
    default as addJobDialog,
    default as AddJobDialog,
} from './add-job-dialog';
import JobDataDialog from './job-data-dialog';

const handlers: FormFunctionGetHandlers = function (this: AppContext) {
    const { appConfig } = this;

    const getParams = (): {
        siteId: string;
        endpointId: string;
        scriptId: string;
        isNew: boolean;
    } => {
        const { siteId, endpointId } = this.form.url.params;
        const { scriptId } = this.form.url.queryParams;
        return {
            siteId,
            endpointId,
            scriptId,
            isNew: false, // FIXME: Where is the value?
        };
    };

    const updateViews = () => {
        const { siteId, endpointId } = getParams();
        const siteConfig = this.getSiteConfig(siteId);

        const updateSiteIdInputValues = () => {
            this.form.views.siteIdInput.values = (appConfig.sites || []).map(
                (item: any) => ({
                    label: item.id,
                    value: item.id,
                })
            );
        };

        const updateEndpointIdInputValues = () => {
            if (!siteConfig) {
                return;
            }

            this.form.views.endpointIdInput.values = siteConfig.endpoints.map(
                (item: any) => ({
                    label: item.id,
                    value: item.id,
                })
            );
        };

        const updateScriptIdInputValues = async () => {
            let jobs = [];

            if (siteId && endpointId) {
                try {
                    jobs = await apiClient.getJobsScripts(siteId, endpointId);
                } catch (e) {}
            }

            this.form.views.scriptIdInput.values = [
                ...jobs.map((item: any) => ({
                    label: item,
                    value: item,
                })),
            ];
        };

        updateSiteIdInputValues();
        updateEndpointIdInputValues();
        updateScriptIdInputValues();
    };

    const onBeforeClose = () => {
        if (this.form.updateInterval) {
            clearInterval(this.form.updateInterval);
            this.form.updateInterval = null;
        }
    };

    const onChangeSiteId = async () => {
        const { isNew } = getParams();
        this.form.isNew = isNew;

        this.form.setDataSourceValue('url.params.endpointId', 'undefined');
        updateViews();
    };

    const onChangeEndpointId = async () => {
        const { isNew } = getParams();
        this.form.isNew = isNew;

        this.form.views.scriptIdInput.value = '';
        updateViews();
    };

    const updateActiveTasks = async () => {
        const { siteId, endpointId, scriptId } = getParams();

        if (!scriptId) {
            return;
        }

        const getJobsInQueue = async (type: string) => {
            const params = {
                types: [type],
                start: 0,
                end: 1000,
            };

            // await apiClient.getRepeatableJobs(siteId, endpointId, scriptId);

            return await apiClient.getJobsInQueue(
                siteId,
                endpointId,
                scriptId,
                params
            );
        };

        const types = [
            'completed',
            'failed',
            'delayed',
            'repeat',
            'active',
            'wait',
            'paused',
        ];

        let items: any[] = [];
        const jobsRequests = types.map((type) => ({
            type,
            promise: getJobsInQueue(type),
        }));

        const jobsRequestsResult = await Promise.all(
            jobsRequests.map((item) => item.promise)
        );
        jobsRequests.forEach(({ type }, index) => {
            const jobs = jobsRequestsResult[index];
            items = [
                ...items,
                ...jobs.map((item: any) => ({
                    ...item,
                    type,
                })),
            ];
        });

        this.form.items = items;
    };

    const onChangeScriptId = async () => {
        const { isNew } = getParams();
        this.form.isNew = isNew;

        updateViews();
        updateActiveTasks();
    };

    const onAddScriptToQueue = async () => {
        const { siteId, endpointId, scriptId } = getParams();

        const repeatOptions = {
            // cron: "* * * * *",
            // startDate: new Date
            count: 0,
            limit: 20,
            every: 6 * 10000,
        };

        const params = {
            // name: scriptId,
            // attempts: 1000,
            data: {
                id: String(Date.now()),
            },
            repeat: repeatOptions,
            removeOnComplete: false,
            removeOnFail: false,
            opts: {
                attempts: 4,
                removeOnComplete: false,
                removeOnFail: false,
            },
        };

        await apiClient.addJobScriptToQueue(
            siteId,
            endpointId,
            scriptId,
            params
        );
    };

    const updateNewFlag = () => {};

    const onBeforeOpen = async () => {
        this.registerCustomDialog(AddJobDialog);
        this.registerCustomDialog(JobDataDialog);
        updateViews();
        updateNewFlag();
        updateActiveTasks();

        this.form.updateInterval = setInterval(() => updateActiveTasks(), 2000);
    };

    const onChangeUrl = () => {
        updateViews();
    };

    const onAddJobInQueue = async ({
        siteId,
        endpointId,
        scriptId,
        data,
    }: any) => {
        try {
            await apiClient.addJobScriptToQueue(
                siteId,
                endpointId,
                scriptId,
                data
            );

            this.form.notify({
                text: 'The job added in the queue',
                lifetimeMs: 3000,
            });

            updateActiveTasks();
        } catch (e) {
            this.form.notify({
                type: 'error',
                lifetimeMs: 10000,
                text: "Can't add job in the queue",
            });
        }
    };

    const onOpenAddJobDialog = () => {
        const { siteId, endpointId, scriptId } = getParams();
        const getErrors = (): Record<
            string,
            { inputId: string; text: string }
        > | null => {
            const errors: Record<string, { inputId: string; text: string }> =
                {};

            if (!siteId) {
                errors.siteId = {
                    inputId: 'siteIdInput',
                    text: 'Site id required',
                };
            }

            if (!endpointId) {
                errors.endpointId = {
                    inputId: 'endpointIdInput',
                    text: 'Endpoint id required',
                };
            }

            if (!scriptId) {
                errors.scriptId = {
                    inputId: 'scriptIdInput',
                    text: 'Script id required',
                };
            }

            if (Object.getOwnPropertyNames(errors).length > 0) {
                return errors;
            }

            return null;
        };

        this.form.hideErrors('JOBS_SCHEDULE_OPEN_JOB_DIALOG');

        const errors = getErrors();
        if (errors) {
            this.form.displayErrors(errors, 'JOBS_SCHEDULE_OPEN_JOB_DIALOG');
            return;
        }

        this.form.pushDialog(addJobDialog.id, {
            siteId,
            endpointId,
            scriptId,
            onContinue: onAddJobInQueue,
        });
    };

    const onEmptyQueue = async () => {
        const { siteId, endpointId, scriptId } = getParams();

        const statuses = ['completed', 'wait', 'active', 'delayed', 'failed'];

        for (let i = 0; i < statuses.length; i++) {
            const status = statuses[i];
            try {
                await apiClient.cleanJobsQueue(
                    siteId,
                    endpointId,
                    scriptId,
                    0,
                    status
                );
            } catch (e) {}
        }

        const response = await apiClient.getRepeatableJobs(
            siteId,
            endpointId,
            scriptId
        );

        for (let i = 0; i < response.jobs.length; i++) {
            const job = response.jobs[i];
            await apiClient.removeRepeatableJobByKey(
                siteId,
                endpointId,
                scriptId,
                job.key
            );
        }

        this.form.notify({
            text: 'Clean success!',
            lifetimeMs: 3000,
        });
        updateActiveTasks();
    };

    const onClickRow = (item: any) => {
        const { siteId, endpointId, scriptId } = getParams();
        this.form.pushDialog('job-data-dialog', {
            siteId,
            endpointId,
            scriptId,
            jobId: item.id,
            job: item,
            onContinue: onAddJobInQueue,
        });
    };

    const onPause = async () => {
        const { siteId, endpointId, scriptId } = getParams();
        await apiClient.pauseJobsInQueue(siteId, endpointId, scriptId);
        this.form.notify({
            text: 'Jobs are paused',
            lifetimeMs: 2000,
        });
    };

    const onResume = async () => {
        const { siteId, endpointId, scriptId } = getParams();
        await apiClient.resumeJobsInQueue(siteId, endpointId, scriptId);
        this.form.notify({
            text: 'Jobs are resumed',
            lifetimeMs: 2000,
        });
    };

    return {
        onBeforeOpen,
        onBeforeClose,

        onChangeSiteId,
        onChangeEndpointId,
        onChangeScriptId,
        onChangeUrl,

        onAddScriptToQueue,
        onOpenAddJobDialog,
        onEmptyQueue,
        onClickRow,
        onPause,
        onResume,
    };
};

export default handlers;
