import CacheStorage from './CacheStorage';

const cache = new CacheStorage();

export interface RequestCallback {
    (response: any): any;
}

async function defaultCallback(
    response: any
): Promise<{ status: any; body: any }> {
    let body = null;
    try {
        body = await response.json();
    } catch (e) {}

    return {
        status: response.status,
        body,
    };
}

export async function callPostMethod(
    url: string,
    data: any,
    token?: string,
    contentType?: string,
    callback: RequestCallback = defaultCallback
): Promise<any> {
    const headers: any = {
        'content-type': contentType || 'application/json',
    };
    debugger;
    if (!token) {
        token = window._AUTH_TOKEN;
    }

    if (token) {
        headers.Authorization = `token ${token}`;
    }

    // Default options are marked with *
    return fetch(url, {
        body: contentType ? data : JSON.stringify(data), // must match 'Content-Type' header
        cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
        credentials: 'same-origin', // include, same-origin, *omit
        headers,
        method: 'POST', // *GET, POST, PUT, DELETE, etc.
        mode: 'cors', // no-cors, cors, *same-origin
        redirect: 'follow', // manual, *follow, error
        referrer: 'no-referrer', // *client, no-referrer
    }).then(callback); // parses response to JSON
}

export async function callDeleteMethod(
    url: string,
    token?: string,
    customHeaders = {},
    callback: RequestCallback = defaultCallback
): Promise<any> {
    const headers: any = {
        'content-type': 'application/json',
        ...customHeaders,
    };

    if (!token) {
        token = window._AUTH_TOKEN;
    }

    if (token) {
        headers.Authorization = `token ${token}`;
    }

    // Default options are marked with *
    return fetch(url, {
        cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
        credentials: 'same-origin', // include, same-origin, *omit
        headers,
        method: 'DELETE', // *GET, POST, PUT, DELETE, etc.
        mode: 'cors', // no-cors, cors, *same-origin
        redirect: 'follow', // manual, *follow, error
        referrer: 'no-referrer', // *client, no-referrer
    }).then(callback); // parses response to JSON
}

export async function callPutMethod(
    url: string,
    data: any,
    token?: string,
    callback: RequestCallback = defaultCallback,
    contentType?: string,
    preprocessData: (data: any) => any = (data) => JSON.stringify(data),
    customHeaders: any = {}
): Promise<any> {
    const headers = {
        'content-type': contentType || 'application/json',
        ...customHeaders,
    };

    if (!token) {
        token = window._AUTH_TOKEN;
    }

    if (token) {
        headers.Authorization = `token ${token}`;
    }
    // Default options are marked with *
    return fetch(url, {
        body: preprocessData(data), // must match 'Content-Type' header
        cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
        credentials: 'same-origin', // include, same-origin, *omit
        headers,
        method: 'PUT', // *GET, POST, PUT, DELETE, etc.
        mode: 'cors', // no-cors, cors, *same-origin
        redirect: 'follow', // manual, *follow, error
        referrer: 'no-referrer', // *client, no-referrer
    }).then(callback); // parses response to JSON
}

export async function callGetMethod(
    url: string,
    token?: string,
    customHeaders: any = {},
    callback: RequestCallback = defaultCallback
): Promise<any> {
    const headers = {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        ...customHeaders,
    };

    if (!token) {
        token = window._AUTH_TOKEN;
    }

    if (token) {
        headers.Authorization = `token ${token}`;
    }

    // Default options	 are marked with *
    const response = await fetch(url, {
        cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
        headers,
        method: 'GET', // *GET, POST, PUT, DELETE, etc.
    });

    return callback(response);
}

export async function callGetMethodCached(
    url: string,
    token?: string,
    customHeaders: any = {},
    callback: RequestCallback = defaultCallback
): Promise<any> {
    if (!token) {
        token = window._AUTH_TOKEN;
    }

    const cacheKey = url + (token || '') + JSON.stringify(customHeaders);
    let result = cache.get(cacheKey);

    if (result) {
        return JSON.parse(result);
    }

    result = await callGetMethod(url, token, customHeaders, callback);
    if (result.status === 200 || result.status === 201) {
        cache.put(cacheKey, JSON.stringify(result));
    }

    return result;
}

export const clearCache = () => {
    cache.clear();
};

const crud = {
    callPostMethod,
    callDeleteMethod,
    callGetMethod,
    callPutMethod,
    callGetMethodCached,
    clearCache,
};

export default crud;
