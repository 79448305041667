import { ItemFormConfig } from '../../../../core/config-builder/forms/item/types';
const contentarticle: ItemFormConfig = {
    title: 'כתבות',
    isAllowed: ['admin', /[a-zA-Z0-9]+Admin$/],
    metaInfo: {
        properties: {
            title: {
                name: 'כותרת',
            },
            subtitle: {
                name: 'כותרת משנית',
            },
            photo: {
                name: 'תמונה',
            },
            brand: {
                name: 'מותג',
            },
            active: {
                name: ' פורסם',
            },
            description: {
                name: 'תקציר',
            },
        },
    },
};

export default contentarticle;
