import CodeEditor from '../../../../components/form/code-editor';
import ViewTypes from '../../../../components/form/ViewTypes';
import handlers from './handlers';

export default {
    id: 'appConfigForm',
    type: 'Form',
    handlers,
    content: {
        id: 'configTabContent',
        type: ViewTypes.VStack,
        items: [
            {
                id: 'appConfigViewer',
                type: ViewTypes.CustomComponent,
                component: CodeEditor,
                dataSource: 'appConfig',
                language: 'json',
                isDisabled: true,
            },
        ],
    },
};
