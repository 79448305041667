import lodash from 'lodash';
import { AppContext } from '../../../../../components/form/context/types';
import { ItemFormFunctionGetHandlers } from '../../../../../components/item-form/types';

const handlers: ItemFormFunctionGetHandlers = function (this: AppContext) {
    const onBeforeOpen = async () => {
        const { params } = this.form;
        const { isNewProperty, schema } = params;

        this.form.dialogTitle = isNewProperty
            ? 'Create new property'
            : `Edit: ${schema.id}`;

        this.form.views.type.isDisabled = !isNewProperty;
        this.form.schema = schema || {};
        this.form.views.id.isHidden = !isNewProperty;

        this.form.views.itemType.isDisabled =
            !isNewProperty &&
            (schema.type === 'array' || schema.type === 'object');
        // this.form.views.type.values = this.form.views.type.values.filter(
        //     (item: any) => item.value !== 'object'
        // );

        this.form.isEnum = schema && schema.enum && schema.enum.length > 0;
    };

    const onClose = () => {
        this.form.closeDialog();
    };

    const checkFields = (
        schema: any
    ): Record<string, { text: string; inputId: string }> | undefined => {
        const { params, isEnum } = this.form;
        const { isNewProperty } = params;
        const errors: Record<string, { text: string; inputId: string }> = {};

        if (!schema.id) {
            errors.id = {
                text: 'Id is required',
                inputId: 'id',
            };
        } else if (schema.id.includes(' ')) {
            errors.id = {
                text: 'Id should be camelCase value',
                inputId: 'id',
            };
        }

        if (!schema.title) {
            errors.title = {
                text: 'Title is required',
                inputId: 'title',
            };
        }

        if (!schema.type) {
            errors.type = {
                text: 'Type is required',
                inputId: 'type',
            };
        }

        if (
            !schema.format &&
            schema.type !== 'object' &&
            schema.type !== 'array'
        ) {
            errors.format = {
                text: 'Format is required',
                inputId: 'format',
            };
        }

        if (
            !isNewProperty &&
            isEnum &&
            schema &&
            (!schema.enum || schema.enum.length === 0)
        ) {
            errors.enum = {
                text: 'Enum values are required',
                inputId: 'enum',
            };
        }

        if (Object.keys(errors).length === 0) {
            return;
        }

        return errors;
    };

    const onSave = () => {
        debugger;
        const { params, schema: data } = this.form;
        const errors = checkFields(data);

        this.form.hideErrors('PROPERTY_DIALOG_SAVE_ERROR_');
        if (errors) {
            this.form.displayErrors(errors, 'PROPERTY_DIALOG_SAVE_ERROR_');
            return;
        }

        params.onSave(data);
    };

    const isUploadHidden = () => {
        const { schema } = this.form;
        return !(
            (schema.format === 'file' || schema.format === 'url') &&
            schema.type === 'string'
        );
    };

    const onChangeId = (newId: string) => {
        const newTitle = lodash.upperFirst(lodash.lowerCase(newId));
        this.form.schema.title = newTitle;
    };

    const isItemsTypeHidden = () => {
        const { schema } = this.form;
        return schema.type !== 'array';
    };

    const isFormatHidden = () => {
        const { schema } = this.form;
        return schema.type == 'array' || schema.type == 'object';
    };

    const isItemPropertiesHidden = () => {
        const { schema } = this.form;
        if (schema.type === undefined) return true;

        return !(
            schema.type === 'object' ||
            (schema.type === 'array' &&
                schema.items &&
                schema.items.type === 'object')
        );
    };

    const setItemProperties = (properties: any) => {
        const { schema } = this.form;
        if (schema.items) {
            schema.items.properties = properties;
        }
    };

    return {
        onBeforeOpen,
        onSave,
        onClose,
        isUploadHidden,
        onChangeId,
        isItemsTypeHidden,
        isFormatHidden,
        isItemPropertiesHidden,
        setItemProperties,
    };
};

export default handlers;
