import {
    AppContext,
    FormFunctionGetHandlers,
} from '../../components/form/context/types';

function openInNewTab(url: string) {
    const win = window.open(url, '_blank') as any;
    win.focus();
}

const handlers: FormFunctionGetHandlers = function (this: AppContext) {
    const onOpenSiteConfigEditor = () => {
        const { siteId } = this.form.url.params;
        this.form.url.push(`/${siteId}/site-config-editor/settings`);
    };

    const onOpenLandingsList = () => {
        const { siteId } = this.form.url.params;
        this.form.url.push(`/${siteId}/landings/list`);
    };

    const openJavascriptSDK = () => {
        const { siteId } = this.form.url.params;
        openInNewTab(
            `https://miniapps.pazamapp.com/v1/api/${siteId}/download/javascript?token=${window._AUTH_TOKEN}`
        );
    };

    const openSiteApiDocumentation = () => {
        const { siteId } = this.form.url.params;
        openInNewTab(
            `https://miniapps.pazamapp.com/v1/api/${siteId}/download/docs?token=${window._AUTH_TOKEN}`
        );
    };

    const openMiniSiteApiDocumentation = () => {
        openInNewTab(
            `https://miniapps.pazamapp.com/v1/download/docs/?token=${window._AUTH_TOKEN}`
        );
    };

    return {
        onOpenSiteConfigEditor,
        onOpenLandingsList,
        openJavascriptSDK,
        openSiteApiDocumentation,
        openMiniSiteApiDocumentation,
    };
};

export default handlers;
