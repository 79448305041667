import copy from 'copy-to-clipboard';
import _ from 'lodash';
import {
    AppContext,
    FormFunctionGetHandlers,
} from '../../../../components/form/context/types';
import apiClient from '../../../../requests/api';
import { sleep } from '../../../../utils';

const ALLOWED_DELETE_ROLES = ['admin', 'landingsAdmin'];

const handlers: FormFunctionGetHandlers = function (this: AppContext) {
    const getLandingUrl = () => {
        let landingUrl = this.appConfig.params.landingsBaseUrl;
        if (!landingUrl.endsWith('/')) {
            landingUrl += '/';
        }
        landingUrl += this.form.object.path;
        return landingUrl;
    };

    const updateSiteIdInputValues = (inputId: string) => {
        const { appConfig } = this;
        this.form.views[inputId].values = (appConfig.sites || []).map(
            (item: any) => ({
                label: item.id,
                value: item.id,
            })
        );
    };

    const updateEndpointIdInputValues = (inputId: string, siteId: string) => {
        const siteConfig = this.getSiteConfig(siteId);
        if (!siteConfig) {
            return;
        }
        this.form.views[inputId].values = siteConfig.endpoints.map(
            (item: any) => ({
                label: item.id,
                value: item.id,
            })
        );
    };

    const loadObject = async () => {
        const { siteId, endpointId, objectId } = this.form.url.params;
        this.form.views.infoTabContentGroup.isLoading = true;
        const objectData = await apiClient.getItemFromTable(
            siteId,
            endpointId,
            objectId
        );

        if (objectData.data) {
            objectData.data = JSON.parse(objectData.data);
        } else {
            objectData.data = {};
        }

        this.form.object = objectData;
        this.form.views.infoTabContentGroup.isLoading = false;
    };

    const _initNewObjectForm = () => {
        this.form.object = {
            data: {
                css: '',
                html: '<html><body><h1>Add your content</h1></body></html>',
            },
        };

        updateSiteIdInputValues('siteIdInput');
        this.form.views.endpointIdInput.values = [];
    };

    const _initExistingObjectForm = async () => {
        await loadObject();
        updateSiteIdInputValues('siteIdInput');
        updateEndpointIdInputValues('endpointIdInput', this.form.object.siteId);
    };

    const onBeforeOpen = async () => {
        const isNewObject = !this.form.url.params.objectId;
        if (isNewObject) {
            _initNewObjectForm();
        } else {
            _initExistingObjectForm();
        }
    };

    const _displayErrors = (errors: any) => {
        _.forOwn(errors, ({ text, propertyName }, propertyId) => {
            _.forOwn(this.form.views, (view, viewId) => {
                if (view.dataSource === `object.${propertyId}`) {
                    view.displayError = true;

                    this.form.notify(
                        {
                            type: 'error',
                            text: `${propertyName}: ${text}`,
                        },
                        `requiredField.${propertyId}`
                    );
                }
            });
        });
    };

    const checkRequiredFields = async () => {
        const { name, path, data } = this.form.object;
        const errors: any = {};

        if (!name) {
            errors.name = {
                propertyName: 'Name',
                text: 'Property is required',
            };
        }

        if (!path) {
            errors.path = {
                propertyName: 'Path',
                text: 'Property is required',
            };
        }

        if (!data || !data.templateId) {
            errors['data.templateId'] = {
                propertyName: 'TemplateId',
                text: 'Property is required',
            };
        }

        if (Object.keys(errors).length === 0) {
            return false;
        }

        _displayErrors(errors);
        return true;
    };

    const onBeforeSave = () => {
        const { data } = this.form.object;
        this.form.object.data = JSON.stringify(data || {});
    };

    const onAfterSave = async (objectId: string) => {
        if (typeof this.form.object.data === 'object') debugger;
        this.form.object.data = JSON.parse(this.form.object.data || '{}');
        await sleep(3000);
        this.form.url.replace(`/landings/item/${objectId}/editor`);
    };

    const onSaveError = () => {
        this.form.object.data = JSON.parse(this.form.object.data || '{}');
    };

    const onDeleteSuccess = () => {
        this.form.url.goBack();
    };

    const onOpenDeleteItemDialog = () => {
        if (
            !this.currentUserData.roles.some((role) =>
                ALLOWED_DELETE_ROLES.includes(role)
            )
        ) {
            return;
        }

        const { objectId } = this.form;
        const { siteId, endpointId } = this.form.url.params;

        this.form.pushDialog('deleteItem', {
            siteId,
            endpointId,
            selection: {
                include: [objectId],
            },
            filter: null,
            onSuccess: onDeleteSuccess,
        });
    };

    const onChangeSiteId = () => {
        updateEndpointIdInputValues('endpointIdInput', this.form.object.siteId);
    };

    const onChangeEndpointId = () => {};

    const onGoBack = () => {
        this.form.url.goBack();
    };

    const onSave = async () => {
        const object = {
            ...this.form.object,
            data: JSON.stringify(this.form.object.data),
        };

        const hasErrors = await checkRequiredFields();
        if (hasErrors) {
            return;
        }

        this.form.views.infoTabContentGroup.isLoading = true;
        try {
            const objectId = await apiClient.createTableItem(
                undefined,
                'landings',
                object
            );
            await sleep(3000);
            this.form.notify({
                text: 'Success!',
            });

            this.form.url.replace(`/landings/item/${objectId}/editor`);
        } catch (e: any) {
            debugger;
            this.form.views.infoTabContentGroup.isLoading = false;
            this.form.notify({
                type: 'error',
                text: `Can't save: ${e?.description ?? e?.code ?? e.message}`,
                lifetimeMs: 3000,
            });
        }
    };

    const onCopyUrl = () => {
        copy(getLandingUrl());
        this.form.notify({
            text: 'Url is copied!',
            lifetimeMs: 3000,
        });
    };

    return {
        onSave,
        onBeforeOpen,
        onAfterSave,
        onSaveError,
        onOpenDeleteItemDialog,
        onBeforeSave,
        onChangeSiteId,
        onChangeEndpointId,
        onGoBack,
        onCopyUrl,
    };
};

export default handlers;
