import { AppContext } from '../../../../core/components/form/context/types';
import { CustomEndpointConfig } from '../../../../core/config-builder/types';

const song: CustomEndpointConfig = {
    title: 'שירים',
    metaInfo: {
        propertiesOrder: [
            'title',
            'artist',
            'url',
            'roundId',
            'order',
            'createdAt',
        ],
        properties: {
            title: {
                name: 'שם השיר',
            },
            artist: {
                name: 'שם האומן',
            },
            url: {
                name: 'לינק לשיר (in spotify -> song -> share -> spotify URI)',
            },
            roundId: {
                name: 'מספר סבב',
            },
            order: {
                name: 'סדר',
            },
            createdAt: {
                name: 'תאריך יצירה',
            },
        },
    },

    forms: {
        list: {
            // content of the list form
            defaultUrlQueryParams: {
                sort: [
                    {
                        columnId: 'createdAt',
                        direction: 'desc',
                    },
                ],
            },
            columnsOrder: [
                'title',
                'artist',
                'url',
                'roundId',
                'order',
                'createdAt',
            ],
            columns: {
                title: {
                    title: 'שם השיר',
                    style: () => {
                        return {
                            fontWeight: 'bold',
                            width: '500px',
                            height: '45px',
                            lineHeight: '45px',
                        };
                    },
                },
                artist: {
                    title: 'שם האומן',
                },
                url: {
                    title: 'לינק לשיר',
                },
                roundId: {
                    title: 'מספר סבב',
                },
                order: {
                    title: 'סדר',
                },
                createdAt: {
                    title: 'תאריך יצירה',
                },
            },
            handlers(this: AppContext) {
                return {
                    onBeforeOpen: async () => {
                        this.form.commands = this.form.commands.filter(
                            (item) => item.id !== 'export'
                        );
                    },
                };
            },
            rowStyle(this: AppContext) {
                return {
                    background: 'white',
                };
            },
        },
    },
};

export default song;
