import { CustomEndpointConfig } from '../../../../core/config-builder/types';

const campaigns: CustomEndpointConfig = {
    title: 'קריאייטיב',
    metaInfo: {
        propertiesOrder: [
            'situation',
            'bestTime',
            'adFormat',
            'copyWrite',
            'hasInteractiveElement',
            'interactiveElement',
            'elementsNeeded',
            'designReady',
            'clientApprovalStatus',
            'timed',
            'timedFor',
            'file',
            'aired',
            'createdAt',
            'updatedAt',
        ],
        properties: {
            situation: {
                name: 'סיטואציית צריכה',
            },
            bestTime: {
                name: 'מועד מתאים לפרסום',
            },
            adFormat: {
                name: 'פורמט פרסום',
            },
            copyWrite: {
                name: 'קופירייטינג',
            },
            hasInteractiveElement: {
                name: 'האם להשאיר מקום לאלמנט אינטראקטיבי?',
            },
            interactiveElement: {
                name: 'מה הוא האלמנט האינטראקטיבי?',
            },
            elementsNeeded: {
                name: 'אלמנטים נדרשים בעיצוב',
            },
            designReady: {
                name: 'האם עוצב',
            },
            clientApprovalStatus: {
                name: 'האם אושר ע"י הלקוח',
            },
            timed: {
                name: 'האם תוזמן',
            },
            timedFor: {
                name: 'למתי תוזמן?',
            },
            file: {
                name: 'קובץ מצורף',
            },
            aired: {
                name: 'האם פורסם?',
            },
            createdAt: {
                name: 'תאריך הכנסת הקריאייטיב למסמך',
            },
            updatedAt: {
                name: 'תאריך עדכון שורה אחרון',
            },
        },
    },

    forms: {
        list: {
            // content of the list form
            defaultUrlQueryParams: {
                sort: [
                    {
                        columnId: 'createdAt',
                        direction: 'desc',
                    },
                ],
            },
            columnsOrder: [
                'situation',
                'bestTime',
                'adFormat',
                'copyWrite',
                'hasInteractiveElement',
                'interactiveElement',
                'elementsNeeded',
                'designReady',
                'clientApprovalStatus',
                'timed',
                'timedFor',
                'file',
                'aired',
                'createdAt',
                'updatedAt',
            ],
            // columns: {
            // idea: {
            //     title: 'שם הרעיון',
            //     style: () => {
            //         return {
            //             fontWeight: 'bold',
            //             width: '500px',
            //             height: '45px',
            //             lineHeight: '45px',
            //         };
            //     },
            // },
            // platform: {
            //     title: 'פלטפורמה',
            //     isHidden: true,
            // },
            // priceRange: {
            //     title: 'טווח מחירים',
            // },
            // insight: {
            //     title: 'תובנה צבאית',
            //     isHidden: true,
            // },
            // hashtag: {
            //     title: 'האשטג תוכן הקמפיין',
            //     style: () => {
            //         return {
            //             width: '500px',
            //             height: '45px',
            //             'line-height': '45px',
            //         };
            //     },
            // },
            // shortDescription: {
            //     title: 'תיאור מקוצר',
            //     style: () => {
            //         return {
            //             width: '500px',
            //             height: '45px',
            //             'line-height': '45px',
            //         };
            //     },
            // },
            // description: {
            //     title: 'תיאור מפורט',
            //     isHidden: true,
            // },
            // extra: {
            //     title: 'אקסטרה לפעילות זו',
            //     isHidden: true,
            // },
            // customer: {
            //     title: 'לאיזה לקוח הוצג',
            //     style: () => {
            //         return { height: '45px', 'line-height': '45px' };
            //     },
            // },
            // presentedAt: {
            //     title: 'מתי הוצג ללקוח',
            //     style: () => {
            //         return { height: '45px', 'line-height': '45px' };
            //     },
            // },
            // sold: {
            //     title: 'האם נמכר?',
            // },
            // campaignType: {
            //     title: 'סוג הפעילות',
            // },
            // score: {
            //     title: 'ציון',
            //     style: () => {
            //         return { height: '45px', 'line-height': '45px' };
            //     },
            // },
            // serverPath: {
            //     title: 'שיוך בתיקיות',
            //     isHidden: true,
            // },
            // designs: {
            //     title: 'עיצובים',
            // },
            // createdAt: {
            //     title: 'תאריך הכנסת הקריאייטיב למסמך',
            // },
            //},
            // handlers() {
            //     return {
            //         async onBeforeOpen(this: AppContext) {
            //             if (
            //                 !this.currentUserData.roles.includes('admin') &&
            //                 !this.currentUserData.roles.includes(
            //                     'smirnoffAdmin'
            //                 )
            //             )
            //                 this.form.commands = this.form.commands.filter(
            //                     (item) => item.id !== 'export'
            //                 );
            //         },
            //     };
            // },
            rowStyle: () => {
                return {
                    background: 'white',
                };
            },
        },
    },
};

export default campaigns;
