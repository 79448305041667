import { AppContext, VStack } from '../../../../components/form/context/types';
import ViewTypes from '../../../../components/form/ViewTypes';
import LandingPreview from './preview';

const content: VStack = {
    id: 'editorTabContentGroup',
    type: ViewTypes.VStack,
    items: [
        {
            id: 'commandPanelEditor',
            type: ViewTypes.HStack,
            style: {
                position: 'relative',
                height: 'unset',
                width: 'unset',
                background: 'white',
                padding: 10,
                zIndex: 100,
                marginBottom: 0,
                paddingRight: 15,
                borderBottom: '1px solid #dedede',
            },
            items: [
                {
                    id: 'editButton',
                    type: ViewTypes.Button,
                    role: 'active',
                    text: 'Edit',
                    onClick: 'onToggleEditorEditMode',
                    isHidden(this: AppContext) {
                        return !!this.form.isEditorInEditMode;
                    },
                },
                {
                    id: 'saveButton',
                    type: ViewTypes.Button,
                    role: 'active',
                    text: 'Save',
                    onClick: 'onSave',
                    isHidden(this: AppContext) {
                        return !this.form.isEditorInEditMode;
                    },
                },
                {
                    id: 'openPageButton',
                    type: ViewTypes.Button,
                    role: 'notActive',
                    text: 'Open landing fullscreen',
                    onClick: 'onOpenLanding',
                },
            ],
        },
        {
            id: 'horizontalGroup',
            type: ViewTypes.HStack,
            style: { flex: 1 },
            items: [
                {
                    id: 'fieldsColumn',
                    type: ViewTypes.VStack,
                    isHidden(this: AppContext) {
                        return !this.form.isEditorInEditMode;
                    },
                    style: {
                        paddingTop: 10,
                        paddingRight: 10,
                        paddingLeft: 15,
                        width: 300,
                        borderLeft: '1px solid #eeeeee',
                    },
                    items: [
                        {
                            id: 'dataTitle',
                            type: ViewTypes.Text,
                            value: 'Variables',
                            style: {
                                fontWeight: 'bold',
                                fontSize: 20,
                                marginBottom: 20,
                            },
                        },
                        {
                            id: 'fieldsGroup',
                            type: ViewTypes.VStack,
                            items: [],
                        },
                    ],
                },
                {
                    id: 'landingPreviewContainer',
                    type: ViewTypes.TabsGroup,
                    activeTabIdSource: 'previewActiveTabId',
                    defaultTabId: 'mobile',
                    style: { flex: 1, height: '100%' },
                    tabs: [
                        {
                            id: 'mobile',
                            title: 'Mobile',
                            type: ViewTypes.TabPage,
                            style: { background: '#222222' },
                            content: {
                                id: 'mobileTabContainer',
                                type: ViewTypes.VStack,
                                style: {
                                    background: '#222222',
                                    flex: 1,
                                    width: '100%',
                                    height: '100%',
                                },
                                items: [
                                    {
                                        id: 'mobilePreview',
                                        isHidden: false,
                                        type: ViewTypes.CustomComponent,
                                        url: '',
                                        component: LandingPreview,
                                        width: 375,
                                        height: 667,
                                    },
                                ],
                            },
                        },
                    ],
                },
            ],
        },
    ],
};

export default content;
