import React from 'react';
import { getValue } from '../../../utils';
import PhotoGalleryView from '../../native/photo-gallery-view';
import PhotoView from '../../native/photo-view';
import { useAppContextObserver } from '../context';
import PropsMediaField from './props';
import style from './style.module.scss';

const MediaField = (props: PropsMediaField) => {
    let {
        id,
        placeholder,
        objectIdDataSource,
        saveOnSelect,
        maxSizeKB,
        isMultiple,
        title,
        extensionsWithDot,
    } = props;

    const [{ isHidden, isDisabled, mediaManager, objectId }] =
        useAppContextObserver((context) => {
            let { objectId } = props;
            if (objectIdDataSource) {
                objectId = context.form.getDataSourceValue(objectIdDataSource);
            }

            return {
                isHidden: getValue(props.isHidden, context),
                isDisabled: getValue(props.isDisabled, context),
                mediaManager: getValue(props.mediaManager as any, context),
                objectId,
                form: context.form,
            };
        });

    if (isHidden) {
        return null;
    }

    const PhotoViewComponent = isMultiple ? PhotoGalleryView : PhotoView;

    return (
        <div className={style.mediaField}>
            <PhotoViewComponent
                {...mediaManager}
                id={id}
                maxSizeKB={maxSizeKB}
                extensionsWithDot={extensionsWithDot}
                label={title}
                isInEditMode
                placeholderText={placeholder}
                saveOnSelect={saveOnSelect}
                showEditButtons
                isDisabled={isDisabled}
            />
        </div>
    );
};

export default React.memo(MediaField);
