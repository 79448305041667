import React from 'react';
import Comment from './comment';
import useCommentManagerState from './hooks';
import PropsCommentManager from './props';
import style from './style.module.scss';
const CommentManager = (props: PropsCommentManager) => {
    const {
        comments,
        sendComment,
        isLoading,
        commentText,
        setCommentText,
        isDisabled,
        setIsDisabled,
        deleteComment,
    } = useCommentManagerState(props);

    return (
        <div className={style.container}>
            <div className={style.title}>תגובות</div>
            <div className={style.comments}>
                {isLoading ? (
                    <div>טוען תגובות...</div>
                ) : comments.length > 0 ? (
                    comments.map((item: any, index: string) => {
                        return (
                            <Comment
                                commentText={item?.commentText}
                                commenterName={
                                    item?.user?.fullName ?? item.commenterName
                                }
                                profilePic={item?.profilePic}
                                index={index}
                                handleDelete={() => {
                                    deleteComment(item.itemId);
                                }}
                            ></Comment>
                        );
                    })
                ) : (
                    <div>אין תגובות</div>
                )}
            </div>
            <div className={style.commentBox}>
                <textarea
                    className={style.commentBoxActive}
                    value={commentText}
                    onChange={({ target: { value } }) => setCommentText(value)}
                    rows={10}
                    cols={10}
                    placeholder={''}
                />
                <button
                    className={
                        commentText.length > 0 && !isDisabled
                            ? style.commentBoxActiveButton
                            : style.commentBoxButton
                    }
                    onClick={() => {
                        if (!isDisabled) {
                            setIsDisabled(true);
                            sendComment();
                        }
                    }}
                >
                    שלח תגובה
                </button>
            </div>
        </div>
    );
};

export default React.memo(CommentManager);
