import Dialog from '../../../components/dialog';
import { DialogData } from '../../../components/form/context/types';
import ViewTypes from '../../../components/form/ViewTypes';
import handlers from './handlers';
import style from './style.module.scss';

const promptDialog: DialogData = {
    id: 'prompt',
    component: Dialog,
    className: style.dialog,
    handlers,
    fields: {
        inputValue: '',
    },
    content: {
        id: 'rootGroup',
        type: ViewTypes.VStack,
        items: [
            {
                id: 'text',
                type: ViewTypes.Text,
                style: {
                    fontSize: 20,
                    fontWeight: 500,
                    flex: 1,
                    direction: 'ltr',
                    textAlign: 'right',
                },
            },
            {
                id: 'input',
                type: ViewTypes.TextInput,
                ltr: true,
                label: '',
                dataSource: 'inputValue',
            },
            {
                id: 'footer',
                type: ViewTypes.HStack,
                items: [],
            },
        ],
    },
};

export default promptDialog;
