import React, { useCallback } from 'react';
import { getHandlerWithContext, getValue } from '../../../utils';
import { usePropsWithDefaultValues } from '../../../utils/propsWithDefaultValues';
import { useAppContextObserver } from '../context';
import PropsText, { defaultProps } from './props';
import style from './style.module.scss';

const Text = (props: PropsText) => {
    props = usePropsWithDefaultValues(props, defaultProps);
    const {
        id,
        dataSource,
        className = style.text,
        style: customStyle,
        onClick,
    } = props;

    const [{ isHidden, value }, getContext] = useAppContextObserver(
        (context) => {
            let value = props.value;
            if (dataSource) {
                value = context.form.getDataSourceValue(dataSource)!;
            }

            return {
                isHidden: getValue(props.isHidden, context),
                value,
            };
        }
    );

    const handleClick = useCallback(
        (e: any) => {
            e.stopPropagation();
            if (!onClick) {
                return;
            }

            const handler = getHandlerWithContext(onClick, getContext());
            handler!(id);
        },
        [getContext, id, onClick]
    );

    if (isHidden) {
        return null;
    }

    return (
        <div
            data-role="view"
            className={className}
            style={customStyle}
            data-view-type="Text"
            onClick={handleClick}
        >
            {value}
        </div>
    );
};

export default React.memo(Text);
