import { CustomEndpointConfig } from '../../../../core/config-builder/types';
import apiClient from '../../../../core/requests/api';
const submitWord = async (item: any) => {
    const items = await apiClient.createTableItem('cbc', 'wordlewords', {
        word: item.word,
    });
    if (items) {
        await apiClient.deleteTableItem('cbc', 'wordlesuggestion', item.id);
    }
};
const wordlesuggestion: CustomEndpointConfig = {
    metaInfo: {
        properties: {
            word: {
                name: 'word',
            },
        },
    },
    forms: {
        list: {
            defaultUrlQueryParams: {
                sort: [{ columnId: 'word', direction: 'desc' }],
            },
            columns: {
                addToWordList: {
                    id: 'addToWordList',
                    type: 'button',
                    text: 'הוסף לרשימת המילים',
                    onClick(item: any) {
                        submitWord(item);
                        setTimeout(() => {
                            this.form.views.table.weakCallbacks.update &&
                                this.form.views.table.weakCallbacks.update(
                                    true
                                );
                        }, 1000);
                    },
                },
            },
        },
    },
};

export default wordlesuggestion;
