import Form from '../components/form';
import { AppContext } from '../components/form/context/types';
import Sidebar from '../components/sidebar';
import LoginPage from '../containers/login';
import { convertQueryParamsToString } from '../utils';
import dialogs from './dialogs';
import defaultPages from './pages';
import getSitesConfig from './site';
import {
    CompiledAppConfig,
    CustomAppConfig,
    PageConfig,
    RemoteMetaInfo,
    SiteConfig,
} from './types';

function getEndpointFormPages(siteId: string, endpointId: string, forms: any) {
    const pages: any[] = [];

    const formsIds = Object.keys(forms);

    formsIds.forEach((formId) => {
        const formConfig = forms[formId];
        const { ...restFormProps } = formConfig;

        const routeData: Partial<PageConfig> = {
            id: `${siteId}-${endpointId}-${formId}`,
            showInSidebar: false,
            access: 'private',
            component: formConfig.component,
            props: {
                ...restFormProps,
                subtitle: formConfig.subtitle,
                title: formConfig.title,
                content: formConfig.content,
                siteId,
                endpointId,
                handlers: formConfig.handlers,
            },
        };

        if (
            formConfig.urlParamsTemplate &&
            Array.isArray(formConfig.urlParamsTemplate)
        ) {
            formConfig.urlParamsTemplate.forEach((template: string) => {
                pages.push({
                    ...routeData,
                    path: `/:siteId(${siteId})/:endpointId(${endpointId})/${formId}${
                        template || ''
                    }`,
                });
            });
        } else {
            pages.push({
                ...routeData,
                path: `/:siteId(${siteId})/:endpointId(${endpointId})/${formId}${
                    formConfig.urlParamsTemplate || ''
                }`,
            });
        }
    });

    return pages;
}

function getEndpointsPages(
    siteId: string,
    endpoints: any[],
    userData: UserData | undefined
) {
    if (!userData) {
        return [];
    }

    const pages: any = [];

    endpoints &&
        endpoints.forEach((endpointConfig) => {
            const { id: endpointId, forms } = endpointConfig;

            const endpointFormsPages = getEndpointFormPages(
                siteId,
                endpointId,
                endpointConfig.forms
            );
            const defaultFormData = forms[endpointConfig.defaultForm];
            const defaultUrlQuery = defaultFormData.defaultUrlQueryParams
                ? convertQueryParamsToString(
                      defaultFormData.defaultUrlQueryParams
                  )
                : '';

            const isVisible = Array.isArray(endpointConfig.isAllowed)
                ? getVisibleFunctionForRoles(endpointConfig.isAllowed)
                : endpointConfig.isAllowed;

            if (
                Array.isArray(endpointConfig.isAllowed) &&
                !isPageVisible(userData, endpointConfig.isAllowed as any)
            ) {
                return;
            }

            const group = {
                id: `endpoint-group-${endpointId}`,
                type: 'group',
                showInSidebar: true,
                isVisible,
                exactPath: true,
                path: `/:siteId(${siteId})/:endpointId(${endpointId})/`,
                sidebarMatchExact: false,
                sidebarMatch: `/${siteId}/${endpointId}/`,
                sidebarTo: `/${siteId}/${endpointId}/${endpointConfig.defaultForm}${defaultUrlQuery}`,
                component: endpointConfig.component,
                access: 'private',
                title: endpointConfig.title,
                rootPage: null,
                children: endpointFormsPages,
                refreshListFromSidebar: true,
            };

            pages.push(group);
        });

    return pages;
}

function isPageVisible(
    currentUserData: UserData,
    visibleForRoles: (string | RegExp)[]
): boolean {
    if (!visibleForRoles) return true;

    if (!currentUserData || !currentUserData.roles) {
        return false;
    }

    return currentUserData.roles.some((role: string) =>
        visibleForRoles.some((expression: string | RegExp) => {
            if (expression instanceof RegExp) {
                return role.match(expression);
            }

            return role === expression;
        })
    );
}

function getVisibleFunctionForRoles(
    roles: string[]
): (this: AppContext) => boolean {
    return function (this: AppContext): boolean {
        const { currentUserData } = this;

        if (!roles) return false;

        if (!currentUserData || !currentUserData.roles) {
            return false;
        }

        return currentUserData.roles.some((role: string) =>
            roles.some((expression: string | RegExp) => {
                if (expression instanceof RegExp) {
                    return role.match(expression);
                }

                return role === expression;
            })
        );
    };
}

function getPages(
    sites: SiteConfig[] | undefined,
    customPagesConfig: any[],
    userData: UserData | undefined
): PageConfig[] {
    if (!userData) {
        return [];
    }

    const pages: PageConfig[] = [...(defaultPages as any[])].filter((page) => {
        if (
            Array.isArray(page.isAllowed) &&
            !isPageVisible(userData, page.isAllowed as any)
        ) {
            return false;
        }

        return true;
    });

    (customPagesConfig || []).forEach((customConfig) => {
        if (
            Array.isArray(customConfig.isAllowed) &&
            !isPageVisible(userData, customConfig.isAllowed as any)
        ) {
            return;
        }

        pages.push({
            ...customConfig,
            component: customConfig.component || Form,
        });
    });

    sites &&
        sites.forEach((siteConfig: SiteConfig) => {
            const { id: siteId, endpoints } = siteConfig;
            const endpointsPages = getEndpointsPages(
                siteId,
                endpoints as any,
                userData
            );

            if (siteConfig.dashboard) {
                const dashboardConfig = siteConfig.dashboard;
                const siteId = siteConfig.id;

                const group: any = {
                    id: `sidebar-group-${siteId}`,
                    type: 'group',
                    showInSidebar: true,
                    access: 'private',
                    title: siteConfig.title,
                    exactPath: true,
                    path: `/:siteId(${siteId})/`,
                    sidebarMatchExact: false,
                    sidebarMatch: `/${siteId}/`,
                    sidebarTo: `/${siteId}/`,
                    component: dashboardConfig.component,
                    isAllowed: ['admin', `${siteId}Admin`, `${siteId}Editor`],
                    rootPage: dashboardConfig && {
                        ...dashboardConfig,
                        props: {
                            ...dashboardConfig,
                            siteId,
                        },
                    },
                    children: endpointsPages,
                };

                if (
                    isPageVisible(userData, [
                        'admin',
                        `${siteId}Admin`,
                        `${siteId}Editor`,
                    ])
                ) {
                    pages.push(group);
                }
            }
        });

    return pages;
}

export interface UserData {
    roles: string[];
    siteId: string[];
    username: string;
}

function getAppConfig(
    remoteMetaInfo: RemoteMetaInfo | null,
    customConfig: CustomAppConfig,
    userData: UserData | undefined
): CompiledAppConfig {
    const projectTitle = customConfig?.projectTitle ?? 'TITLE';

    const sites = remoteMetaInfo
        ? getSitesConfig(remoteMetaInfo, customConfig)
        : undefined;
    const customPagesConfig = customConfig?.pages ?? [];
    const pages = getPages(sites, customPagesConfig, userData);

    return {
        projectTitle,
        params: customConfig?.params ?? {},
        LoginForm: {
            component: LoginPage,
            title: projectTitle,
        },
        Sidebar: {
            component: Sidebar,
            items: pages
                .filter((item) => item.showInSidebar)
                .map((item) => ({
                    id: item.id,
                    title: item.title,
                    exact: item.sidebarMatchExact,
                    path: item.path,
                    to: item.sidebarTo,
                    match: item.sidebarMatch,
                    isVisible: item.isVisible,
                    refreshListFromSidebar: item.refreshListFromSidebar,
                    items: (item.children ?? [])
                        .filter((item) => item.showInSidebar)
                        .map((subitem) => ({
                            id: subitem.id,
                            title: subitem.title,
                            exact: subitem.sidebarMatchExact,
                            to: subitem.sidebarTo,
                            match: subitem.sidebarMatch,
                            isVisible: subitem.isVisible,
                            refreshListFromSidebar:
                                subitem.refreshListFromSidebar,
                        })),
                })),
        },

        pages,
        sites,
        dialogs: {
            ...dialogs,
            ...(customConfig.dialogs ?? {}),
        },
    };
}

export default getAppConfig;
