import React from 'react';
import { usePropsWithDefaultValues } from '../../../utils/propsWithDefaultValues';
import Table from '../../native/table';
import useDataTableState from './hooks';
import PropsDataTable, { defaultProps } from './props';

const DataTable = (props: PropsDataTable) => {
    props = usePropsWithDefaultValues(props, defaultProps);

    const { id } = props;
    const state = useDataTableState(props);
    const {
        columns,
        isHidden,
        handlersWithContext,
        page,
        onChangePage,
        sort,
        onChangeSort,
        selection,
        onChangeSelection,
        rowStyle,
        rowClassName,
        refTable,
        filter,
        onChangeFilter,
        queryFilter,
        onBeforeUpdateItemField,
        onAfterUpdateItemField,
        searchPhrase,
        contextMenu,
    } = state;

    const {
        siteId,
        endpointId,
        pageSize,
        defaultSort,
        isSelectable,
        searchFields,
    } = props;

    if (isHidden) {
        return null;
    }

    return (
        <Table
            id={id}
            ref={refTable as any}
            isSelectable={isSelectable}
            columns={columns}
            pageSize={pageSize!}
            selection={selection}
            page={page}
            onClickRow={handlersWithContext.onClickItem}
            onChangeSelection={onChangeSelection}
            sort={sort}
            onChangeSort={onChangeSort}
            rowStyle={rowStyle}
            rowClassName={rowClassName}
            onBeforeLoadReferences={handlersWithContext.onBeforeLoadReferences}
            siteId={siteId}
            endpointId={endpointId}
            filter={filter}
            defaultSort={defaultSort}
            searchPhrase={searchPhrase}
            onChangeFilter={onChangeFilter}
            onChangePage={onChangePage}
            contextMenu={contextMenu}
            queryFilter={queryFilter}
            onBeforeUpdateItemField={onBeforeUpdateItemField}
            onAfterUpdateItemField={onAfterUpdateItemField}
            onMapItems={handlersWithContext.onMapItems}
            searchFields={searchFields}
        />
    );
};

export default React.memo(DataTable);
