import React, { useCallback, useMemo } from 'react';
import { ViewItem } from '..';
import { getValue } from '../../../utils';
import TabsContainer from '../../native/tabs-container';
import { useAppContextObserver } from '../context';
import PropsTabsGroup from './props';

const TabsGroup = (props: PropsTabsGroup) => {
    const { tabs, defaultTabId, style: customStyle, onChangeTab } = props;

    const [{ isHidden, activeTabIdSource, activeTabId }, getContext] =
        useAppContextObserver((context) => {
            let { activeTabId } = props;
            // FIXME: optimize rendering
            let { activeTabIdSource } = props;
            if (activeTabIdSource && typeof activeTabIdSource === 'function') {
                activeTabIdSource = activeTabIdSource.call(context);
            }

            if (activeTabIdSource) {
                activeTabId =
                    context.form.getDataSourceValue(activeTabIdSource);
            }

            const result: any = {
                activeTabIdSource,
                activeTabId,
                isHidden: getValue(props.isHidden, context),
            };

            return result;
        });

    const displayTabs = useMemo(() => {
        return tabs
            .filter((item) => !getValue(item.isHidden, getContext()))
            .map((item) => ({
                id: item.id,
                title: getValue(item.title, getContext(), true),
            }));
    }, [getContext, tabs]);

    const handleChangeTab = useCallback(
        (tabId: string) => {
            if (activeTabIdSource) {
                getContext().form.setDataSourceValue(activeTabIdSource, tabId);
            }

            if (!onChangeTab) {
                return;
            }

            if (typeof onChangeTab === 'string') {
                (getContext().form.handlers as any)[onChangeTab](tabId);
                return;
            }

            onChangeTab.call(getContext(), tabId);
        },
        [getContext, activeTabIdSource, onChangeTab]
    );

    const handleRenderTabContent = useCallback(
        (activeTabId: string): JSX.Element | null => {
            const activeTab = tabs.find((item) => item.id === activeTabId);
            if (!activeTab) {
                return null;
            }

            return <ViewItem key={activeTab.id} node={activeTab} />;
        },
        [tabs]
    );

    if (isHidden) {
        return null;
    }

    return (
        <TabsContainer
            activeTabId={(activeTabId ?? defaultTabId)!}
            onChangeTab={handleChangeTab}
            renderContent={handleRenderTabContent}
            tabs={displayTabs}
            style={customStyle}
        />
    );
};

export default React.memo(TabsGroup);
