import { DialogData } from '../../../../../components/form/context/types';
import content from './content';
import handlers from './handlers';

const confirmDialog: DialogData = {
    id: 'confirm-production-endpoint-script-change',
    content,
    handlers,
};

export default confirmDialog;
