import React, { useEffect, useMemo, useRef } from 'react';
import { ViewItem } from '..';
import { getHandlerWithContext } from '../../../utils';
import { useAppContextObserver } from '../context';
import PropsTabPage from './props';
import style from './style.module.scss';

const TabPage = (props: PropsTabPage) => {
    const { id, content } = props;
    const [{ onBeforeOpen, onBeforeClose }] = useAppContextObserver(
        (context) => {
            return {
                onBeforeOpen: getHandlerWithContext(
                    props.onBeforeOpen,
                    context,
                    false
                ),
                onBeforeClose: getHandlerWithContext(
                    props.onBeforeClose,
                    context,
                    false
                ),
            };
        }
    );

    const refHandlers = useRef({ onBeforeClose, onBeforeOpen });
    refHandlers.current = { onBeforeClose, onBeforeOpen };

    useMemo(() => {
        if (refHandlers.current.onBeforeOpen) {
            refHandlers.current.onBeforeOpen!();
        }
    }, []);

    useEffect(() => {
        return () => {
            if (refHandlers.current.onBeforeClose) {
                refHandlers.current.onBeforeClose();
            }
        };
    }, []);

    return (
        <div className={style.tabPage} key={id} style={style}>
            <ViewItem node={content} />
        </div>
    );
};

export default React.memo(TabPage);
