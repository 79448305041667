import { AppContext } from '../../../../core/components/form/context/types';
import { CustomEndpointConfig } from '../../../../core/config-builder/types';
import { getUserName } from '../../commonFunctions';

const yesnoanswer: CustomEndpointConfig = {
    metaInfo: {
        properties: {
            answerCreatedAt: {
                name: 'Created At',
            },
            yesnoAnswerQuestionId: {
                name: 'Question',
                type: 'string',
                format: 'reference',
                siteId: 'cbc',
                endpointId: 'yesnoquestion',
                sortListByField: 'firstname',
                representationDataSource: 'text',
            },
            yesnoQuestionEmail: {
                name: 'Email',
            },
            yesnoAnswerUserId: {
                name: 'User',
                type: 'string',
                format: 'reference',
                siteId: 'cbc',
                endpointId: 'profile',
                idField: 'userId',
                sortListByField: 'firstname',
                searchByField: ['email', 'firstname', 'lastname', 'phone'],
                representationDataSource: getUserName,
            },
            yesnoAnswerValue: {
                name: 'Is Liked',
            },
        },
    },
    forms: {
        list: {
            columns: {
                yesnoAnswerQuestionId: {
                    title: 'Question',
                    type: 'reference',
                    siteId: 'cbc',
                    endpointId: 'yesnoquestion',
                    representationDataSource: 'text',
                    fieldName: '_id',
                    linkTarget: '_blank',
                },
                yesnoAnswerUserId: {
                    getLink: function (
                        this: AppContext,
                        itemId: string,
                        item: any
                    ) {
                        return `/cbc/profile/item/${item.profileId}/info`;
                    },
                },
            },
            handlers: function (this: AppContext) {
                const onMapItems = async ({ items }: any) => {
                    const userIds = items.map(
                        (item: any) => item.yesnoAnswerUserId
                    );
                    if (userIds.length === 0) {
                        return items;
                    }

                    const profiles = await this.api.getItemsByIdFromTable(
                        'cbc',
                        'profile',
                        userIds,
                        'userId'
                    );

                    return items.map((item: any) => {
                        return {
                            ...item,
                            profileId:
                                profiles[item.yesnoAnswerUserId] &&
                                profiles[item.yesnoAnswerUserId]?.id,
                        };
                    });
                };

                const onBeforeOpen = async () => {
                    this.form.views.table.onMapItems = onMapItems;
                };

                return {
                    onBeforeOpen,
                    onMapItems,
                };
            },
        },
    },
};

export default yesnoanswer;
