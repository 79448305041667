import { useMediaQuery } from '@react-hook/media-query';
import lodash from 'lodash';
import React, { CSSProperties, useMemo } from 'react';
import { ContextMenuTrigger } from 'react-contextmenu';
import {
    ColumnBoolean,
    ColumnProgress,
    ColumnReference,
    ColumnReferenceArray,
    TableItemId,
} from '../../../types';
import CellArray from '../cell-array';
import CellBoolean from '../cell-boolean';
import CellButton from '../cell-button';
import CellColor from '../cell-color';
import CellDate from '../cell-date';
import DateCellQuickEdit from '../cell-date-quick-edit';
import CellEmail from '../cell-email';
import CellEnum from '../cell-enum';
import CellImage from '../cell-image';
import CellNumber from '../cell-number';
import CellPhone from '../cell-phone';
import CellProgress from '../cell-progress';
import CellReference from '../cell-reference';
import ReferenceArrayCell from '../cell-reference-array';
import CellTags from '../cell-tags';
import CellText from '../cell-text';
import CellWhatsapp from '../cell-whatsapp';
import ReferenceCellQuickEdit from '../reference-cell-quick-edit';
import TextCellQuickEdit from '../text-cell-quick-edit';
import useCellState from './hooks';
import PropsCell from './props';
import QuickEditorTrigger from './quick-editor-trigger';
import QuickEditorWrapper from './quick-editor-wrapper';
import style from './style.module.scss';

const _getProgressCellProps = (props: PropsCell) => {
    const { item } = props;
    const column = props.column as ColumnProgress;
    return {
        totalValue: (item && lodash.get(item, column.totalValueFieldName)) || 0,
        currentValue:
            (item && lodash.get(item, column.currentValueFieldName)) || 0,
    };
};

const _getReferenceArrayCellProps = (
    { column, pageReferenceValues }: PropsCell,
    value: TableItemId[] | undefined
) => {
    const { siteId, endpointId, idField } = column as ColumnReferenceArray;

    const isPageReferencesLoading = !pageReferenceValues;
    const referenceItems =
        pageReferenceValues &&
        pageReferenceValues.filter(
            (item) =>
                ((!item.siteId && !siteId) || item.siteId === siteId) &&
                item.endpointId === endpointId &&
                item.idField === idField &&
                value instanceof Array &&
                value?.includes(item.reference)
        );

    const referenceValue = (referenceItems ?? []).map(
        (item) => item.referenceValue
    );

    return {
        isPageReferencesLoading,
        pageReferenceValues,
        referenceValue,
    };
};

const _getReferenceCellProps = (props: PropsCell) => {
    const { item, pageReferenceValues, column } = props;
    const { siteId, endpointId, idField } = column as ColumnReference;

    const value = lodash.get(item, column.fieldName);

    const isPageReferencesLoading =
        !pageReferenceValues || (pageReferenceValues.length === 0 && value);
    const referenceItem =
        pageReferenceValues &&
        pageReferenceValues.find(
            (item) =>
                ((!item.siteId && !siteId) || item.siteId === siteId) &&
                item.endpointId === endpointId &&
                item.idField === idField &&
                item.reference === value
        );

    const referenceValue = referenceItem ? referenceItem.referenceValue : null;

    return {
        isPageReferencesLoading,
        pageReferenceValues,
        referenceValue,
    };
};

const Cell = (props: PropsCell) => {
    const {
        tableId,
        item,
        updateValue,
        cellId,
        isQuickEditActive,
        onStartQuickEdit,
    } = props;
    const {
        value,
        isUpdateProcessing,
        onShowQuickEditMenu,
        onMouseEnterQuickEdit,
        onMouseLeaveQuickEdit,
        onUpdate,
        onCancelQuickEdit,
    } = useCellState(props);

    const isMobile = useMediaQuery('(max-width: 524px)');
    const updateInPlace = isMobile
        ? false
        : !!(props.column.updateInPlace ?? false);

    const column = useMemo(() => {
        return {
            ...props.column,
            updateInPlace,
        };
    }, [props.column, updateInPlace]);

    const { type: columnType } = column;

    const customStyle: CSSProperties = {};
    if (column.type === 'number') {
        customStyle.width = 100;
    }

    if (column.width) {
        customStyle.width = column.width;
    }
    if (column.maxWidth) {
        customStyle.maxWidth = column.maxWidth;
    }

    const cellProps: any = {};

    let CellComponent: React.ComponentType<any> | undefined;
    let CellQuickEditComponent: React.ComponentType<any> | undefined;

    switch (columnType) {
        case 'color':
            CellComponent = CellColor;
            break;
        case 'text':
            CellComponent = CellText;
            // CellQuickEditComponent = TextCellQuickEdit;
            break;
        case 'number':
            CellComponent = CellNumber;
            // CellQuickEditComponent = NumberCellQuickEdit;
            break;
        case 'boolean':
            CellComponent = CellBoolean;
            cellProps.onToggle = (column as ColumnBoolean).onToggle;
            break;
        case 'image':
            CellComponent = CellImage;
            break;
        case 'phone':
            CellComponent = CellPhone;
            CellQuickEditComponent = TextCellQuickEdit;
            break;
        case 'email':
            CellComponent = CellEmail;
            CellQuickEditComponent = TextCellQuickEdit;
            break;
        case 'enum':
            CellComponent = CellEnum;
            // CellQuickEditComponent = EnumCellQuickEdit;
            customStyle.overflow = 'visible';
            break;
        case 'button':
            CellComponent = CellButton;
            break;
        case 'date':
            CellComponent = CellDate;
            CellQuickEditComponent = DateCellQuickEdit;
            break;
        case 'progress':
            CellComponent = CellProgress;
            Object.assign(cellProps, _getProgressCellProps(props));
            break;
        case 'tags':
            CellComponent = CellTags;
            break;
        case 'reference':
            CellComponent = CellReference;
            CellQuickEditComponent = ReferenceCellQuickEdit;
            Object.assign(cellProps, _getReferenceCellProps(props));
            break;
        case 'referenceArray':
            CellComponent = ReferenceArrayCell;
            Object.assign(
                cellProps,
                _getReferenceArrayCellProps(props, value as TableItemId[])
            );
            break;
        case 'whatsapp': {
            CellComponent = CellWhatsapp;
            break;
        }
        case 'array': {
            CellComponent = CellArray;
            break;
        }
    }

    if (column.CellComponent) {
        CellComponent = column.CellComponent;
    }

    if (!CellComponent) {
        console.error("Can't find cell component for column", column);
        CellComponent = () => <div />;
    }

    if (column.CellQuickEditComponent) {
        CellQuickEditComponent = column.CellQuickEditComponent;
    }

    const contextMenuAttributes: any = {
        className: style.contextmenutrigger,
        'data-column-type': columnType,
        style: customStyle,
    };

    return (
        <ContextMenuTrigger
            renderTag="td"
            id={tableId}
            collect={() => ({ item })}
            attributes={contextMenuAttributes}
        >
            <QuickEditorTrigger
                updateInPlace={updateInPlace}
                onShowQuickEditMenu={onShowQuickEditMenu}
            >
                <CellComponent
                    column={column}
                    item={item}
                    cellId={cellId}
                    value={value}
                    tableId={tableId}
                    {...cellProps}
                    isQuickEditActive={isQuickEditActive}
                    updateValue={updateValue}
                    isUpdateProcessing={isUpdateProcessing}
                    isUpdateInPlaceProcessing={isUpdateProcessing}
                    updateInPlace={updateInPlace}
                />
                {isQuickEditActive && CellQuickEditComponent && (
                    <QuickEditorWrapper
                        cellId={cellId}
                        onMouseEnter={onMouseEnterQuickEdit}
                        onMouseLeave={onMouseLeaveQuickEdit}
                        isProcessing={isUpdateProcessing}
                        onStartQuickEdit={onStartQuickEdit}
                    >
                        <CellQuickEditComponent
                            cellId={cellId}
                            item={item}
                            value={value}
                            column={column}
                            WrapperComponent={QuickEditorWrapper}
                            onCancel={onCancelQuickEdit}
                            onUpdate={onUpdate}
                        />
                    </QuickEditorWrapper>
                )}
            </QuickEditorTrigger>
        </ContextMenuTrigger>
    );
};

export default React.memo(Cell);
