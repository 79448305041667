import React, { CSSProperties } from 'react';
import PropsCellArray from './props';
import style from './style.module.scss';

const CellArray = ({ column, value, item }: PropsCellArray) => {
    let calculatedStyle: CSSProperties | undefined;

    if (column.style && typeof column.style === 'function') {
        calculatedStyle = column.style!({ column, item, value });
    } else {
        calculatedStyle = column.style;
    }

    return (
        <div className={style.cellArrya} style={calculatedStyle}>
            {value && <span>{Array.isArray(value) && value.join(',')}</span>}
            {!value && <span>&nbsp;</span>}
        </div>
    );
};

export default React.memo(CellArray);
