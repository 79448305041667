import { Observable } from '../../../globalStore/definition';

export default interface PropsPublicRoute {
    component: React.FC<any>;
    redirectTo?: string;
    withParams?: boolean;
    redirect?: (store: Observable, location: any) => any;
    store: Observable;
    [key: string]: any;
}

export const defaultProps: Partial<PropsPublicRoute> = {
    redirectTo: '/',
    withParams: false,
};
