import { CustomEndpointConfig } from '../../../../core/config-builder/types';
import { getUserName } from '../../commonFunctions';

const flappyScore: CustomEndpointConfig = {
    metaInfo: {
        properties: {
            teamId: {
                endpointId: 'flappyteam',
            },
        },
    },
    forms: {
        list: {
            columns: {
                userId: {
                    type: 'reference',
                    endpointId: 'user',
                    idField: 'username',
                    fieldName: 'user.username',
                    linkTarget: '_blank',
                    representationDataSource: getUserName,
                },
            },
        },
    },
};

export default flappyScore;
