import { useMemo, useRef } from 'react';
import { usePrevious } from 'use-hooks';
import { AppContext, HandlerValue } from '../components/form/context/types';

export const getHandlerWithContext = <T>(
    value: HandlerValue<T>,
    context: AppContext,
    throwErrorIfNoValue = true
): T | undefined => {
    const handler = value;

    if (typeof handler === 'string') {
        const handlerFunction = context.form.handlers[handler];
        if (!handlerFunction) {
            if (throwErrorIfNoValue) {
                throw new Error(
                    `No such function in handlers module: ${handler}`
                );
            }
            return undefined;
        }
        return handlerFunction as T | undefined;
    }

    if (typeof handler === 'function') {
        if (!handler.bind) {
            throw new Error(
                `Can't bind the handler, use "function" instead of "=>"`
            );
        }
        return handler.bind(context);
    }

    if (throwErrorIfNoValue) {
        throw new Error('Property should be function or string');
    }

    return undefined;
};

export const useHandlerWithContext = (
    value: string | Function,
    context: AppContext,
    throwErrorIfNoValue = true,
    beforeReturn?: (handlerWithContext: any) => Function,
    beforeReturnDeps?: any[]
) => {
    const refBeforeReturn = useRef(beforeReturn);
    refBeforeReturn.current = beforeReturn;

    return useMemo(() => {
        const handler = getHandlerWithContext(
            value,
            context,
            throwErrorIfNoValue
        );
        if (refBeforeReturn.current) {
            return refBeforeReturn.current(handler);
        }

        return handler;
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [
        context,
        context?.form?.handlers,
        value,
        throwErrorIfNoValue,
        // eslint-disable-next-line react-hooks/exhaustive-deps
        ...(beforeReturnDeps ?? []),
    ]);
};

/**
 * Returns value from context. If value is a string and value or type
 * doesn't exist returns original value
 *
 * @param {string|function|boolean} resultValue
 * @param {FormContext} context
 */
export const getValue = <H>(
    value: string | boolean | undefined | HandlerValue<H>,
    context: AppContext,
    returnValueIfNotExist: boolean = true
): any => {
    let resultValue = value;
    let not = false;

    if (typeof resultValue === 'string' && resultValue.startsWith('!')) {
        not = true;
        resultValue = resultValue.slice(1);
    }

    if (typeof resultValue === 'string' || typeof resultValue === 'function') {
        const handler = getHandlerWithContext(resultValue, context, false);
        if (handler && typeof handler === 'function') {
            if (not) {
                return !handler();
            }

            const result = handler();
            if (result === undefined) {
                return null;
            }

            return result;
        }

        const dataSourceValue = context.form.getDataSourceValue(
            resultValue as string
        );
        if (dataSourceValue !== undefined) {
            return not ? !dataSourceValue : dataSourceValue;
        }
    }

    if (returnValueIfNotExist) {
        return resultValue;
    }

    return undefined;
};

export const useFormContextValue = (
    value: string | Function | boolean | undefined,
    context: AppContext,
    returnValueIfNotExist: boolean = true,
    beforeReturn?: (formContextValue: any) => any,
    beforeReturnCallbackDependencies?: any[]
) => {
    return useMemo(() => {
        const dataSourceValue = getValue(value, context, returnValueIfNotExist);
        if (beforeReturn) {
            return beforeReturn(dataSourceValue);
        }

        return dataSourceValue;
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [
        context,
        context.form,
        value,
        returnValueIfNotExist,
        // eslint-disable-next-line react-hooks/exhaustive-deps
        ...(beforeReturnCallbackDependencies ?? []),
    ]);
};

export const isHandler = (propertyId: string, property?: any): boolean =>
    propertyId.startsWith('on') || typeof property === 'function';

export type PickHandlers<Props> = {
    [P in keyof Props]: Extract<Props[P], Function | undefined>;
};

export type HandlerKeys<Props> = keyof PickHandlers<Props>;

export const getHandlersKeys = <Props>(props: Props): HandlerKeys<Props>[] => {
    const keys = Object.keys(props) as (keyof Props)[];
    return keys.filter((key) => isHandler(key as string, props[key]));
};

export const addContextToHandlers = (
    objectWithHandlers: any,
    context: AppContext
) => {
    const copyObjectWithHandlers = {
        ...objectWithHandlers,
    };

    Object.keys(copyObjectWithHandlers).forEach((propertyId) => {
        const property = copyObjectWithHandlers[propertyId];

        if (!property || !isHandler(propertyId)) {
            return;
        }

        copyObjectWithHandlers[propertyId] = getHandlerWithContext(
            property,
            context
        );
    });

    return copyObjectWithHandlers;
};

export const getHandlersWithContext = <Props>(
    props: Props,
    context: AppContext
): PickHandlers<Props> => {
    return Object.keys(props).reduce((acc: any, propertyId: string) => {
        if (isHandler(propertyId)) {
            const handler = (props as any)[propertyId as any] as any;
            if (handler) {
                acc[propertyId] = getHandlerWithContext(handler, context);
            }
        }

        return acc;
    }, {});
};

export const useHandlersWithContext = <Props>(
    props: Props,
    context: AppContext
): PickHandlers<Props> => {
    const handlers = getHandlersKeys(props).map((key) => props[key]);
    const prevHandlers = usePrevious(handlers);

    const isHandlersChanged =
        !prevHandlers ||
        handlers.some((value, index) => prevHandlers[index] !== value);

    const result = useMemo(() => {
        return getHandlersWithContext(props, context);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [context, isHandlersChanged]);

    return result;
};
