import lodash from 'lodash';
import { useEffect, useMemo, useRef, useState } from 'react';
import PropsCell from './props';

const useCellState = (props: PropsCell) => {
    const {
        cellId,
        item,
        column,
        isQuickEditActive,
        onStartQuickEdit,
        updateValue,
    } = props;

    const [isUpdateProcessing, setUpdateProcessing] = useState(false);
    const refQuickEditHideTimeout = useRef<NodeJS.Timeout | null>(null);
    const refMouseIn = useRef(false);

    const columnFieldName = column.fieldName;

    const value = useMemo(() => {
        let result: Function | any = item
            ? lodash.get(item, columnFieldName)
            : undefined;

        if (typeof result === 'function') {
            result = result({ column, item });
        }

        return result;
    }, [item, column, columnFieldName]);

    useEffect(() => {
        setUpdateProcessing(false);
    }, [isQuickEditActive]);

    const _clearHideTimeOut = () => {
        if (refQuickEditHideTimeout.current) {
            clearTimeout(refQuickEditHideTimeout.current!);
            refQuickEditHideTimeout.current = null;
        }
    };

    const onShowQuickEditMenu = () => {
        if (isQuickEditActive) {
            return;
        }

        _clearHideTimeOut();
        onStartQuickEdit(null);
    };

    const _hideWithDelay = () => {
        _clearHideTimeOut();
        refQuickEditHideTimeout.current = setTimeout(() => {
            if (refMouseIn.current) {
                return;
            }
            onCancelQuickEdit();
        }, 700);
    };

    const onHideQuickEditMenu = () => {
        if (!isQuickEditActive) {
            return;
        }
        _hideWithDelay();
    };

    const onCancelQuickEdit = () => {
        props.onCancelQuickEdit(cellId);
    };

    const onMouseEnterQuickEdit = () => {};

    const onMouseLeaveQuickEdit = () => {};

    const onUpdate = async (newValue: any) => {
        setUpdateProcessing(true);
        await updateValue(newValue);
        setUpdateProcessing(false);
        onCancelQuickEdit();
    };

    return {
        value,
        isUpdateProcessing,
        onShowQuickEditMenu,
        onHideQuickEditMenu,
        onUpdate,
        onMouseEnterQuickEdit,
        onMouseLeaveQuickEdit,
        onCancelQuickEdit,
    };
};

export default useCellState;
