import React, { useCallback, useEffect, useState } from 'react';
import { ColorResult, SketchPicker } from 'react-color';
import useOnclickOutside from 'react-cool-onclickoutside';
import { getValue } from '../../../utils/context';
import { useAppContextObserver } from '../context';
import PropsColorInput from './props';
import style from './style.module.scss';

const ColorInput = (props: PropsColorInput) => {
    const { dataSource, onChangeValue, label } = props;
    const [{ isHidden, displayError, isDisabled, value }, getContext] =
        useAppContextObserver((c) => {
            let { value } = props;
            if (dataSource) {
                value = c.form.getDataSourceValue(dataSource);
            }
            return {
                isHidden: getValue(props.isHidden, c),
                displayError: getValue(props.displayError, c),
                isDisabled: getValue(props.isDisabled, c),
                value,
            };
        });

    const [isOpened, setIsOpened] = useState(false);
    const [tempValue, setTempValue] = useState(() => value);

    useEffect(() => {
        setTempValue(value);
    }, [value]);

    const refPickerContainer = useOnclickOutside(() => {
        setIsOpened(false);
    });

    const handleChangeValue = (color: ColorResult) => {
        setTempValue(color.hex);
    };

    const onSave = (e: any) => {
        e.stopPropagation();

        setIsOpened(false);

        if (dataSource) {
            getContext().form.setDataSourceValue(dataSource, tempValue);
        }

        if (!onChangeValue) {
            return;
        }

        if (typeof onChangeValue === 'string') {
            (getContext().form.handlers as any)[onChangeValue](tempValue);
            return;
        }

        onChangeValue.call(getContext(), tempValue!);
    };

    const onToggleOpen = useCallback(() => {
        setIsOpened((prev) => !prev);
    }, []);

    if (isHidden) {
        return null;
    }

    return (
        <div
            className={style.colorInput}
            data-role="view"
            onClick={isDisabled ? undefined : onToggleOpen}
        >
            <label className={style.label}>{label}</label>
            <div
                className={style.input}
                data-opened={!!isOpened}
                data-display-error={!!displayError}
            >
                <div
                    className={style.colorPreview}
                    style={{ background: value }}
                />
                <div className={style.value}>{value ?? 'None'}</div>
            </div>
            {isOpened && (
                <div
                    className={style.pickeContainer}
                    onClick={(e) => e.stopPropagation()}
                    ref={refPickerContainer}
                >
                    <SketchPicker
                        color={tempValue}
                        onChange={handleChangeValue}
                    />
                    <button onClick={onSave}>Save</button>
                </div>
            )}
        </div>
    );
};

export default React.memo(ColorInput);
