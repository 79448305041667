import {
    AppContext,
    FormFunctionGetHandlers,
} from '../../../components/form/context/types';
import ViewTypes from '../../../components/form/ViewTypes';
import defaultHandlers from '../defaultHandlers';
import DialogParamsPropmpt from './params';

const handlers: FormFunctionGetHandlers = function (this: AppContext) {
    const getParams = (): DialogParamsPropmpt => {
        return this.form.params;
    };

    const onBeforeOpen = async () => {
        const {
            text,
            submitAction,
            submitLabel,
            cancelAction,
            cancelLabel,
            inputLabel,
        } = getParams();

        this.form.views.text.value = text;
        this.form.views.input.label = inputLabel;

        const onSubmit = async (e: any) => {
            if (!this.form.inputValue) {
                this.form.views.input.displayError = true;
                return;
            }

            this.form.views.rootGroup.isLoading = true;
            try {
                await submitAction(this.form.inputValue);
            } catch (e) {
            } finally {
                this.form.views.rootGroup.isLoading = false;
            }
        };

        const onCancel = () => {
            if (cancelAction) {
                cancelAction();
            } else {
                this.form.closeDialog();
            }
        };

        this.form.views.footer.items = [
            {
                id: 'submit',
                type: ViewTypes.Button,
                text: submitLabel || 'Submit',
                role: 'active',
                onClick: onSubmit,
                style: { marginLeft: 15 },
            },
            {
                id: 'no',
                type: ViewTypes.Button,
                text: cancelLabel || 'Cancel',
                role: 'notActive',
                onClick: onCancel,
            },
        ];
    };

    return {
        ...defaultHandlers.call(this),
        onBeforeOpen,
    };
};

export default handlers;
