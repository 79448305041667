import { AppContext } from '../../../../../core/components/form/context/types';
import {
    ListFormFunctionGetHandlers,
    ListFormHandlers,
} from '../../../../../core/components/list-form/types';
import { convertToQueryFilter } from '../../../../../core/components/native/table/filter';

const handers: ListFormFunctionGetHandlers = function (
    this: AppContext
): ListFormHandlers {
    const onDeleteSuccess = () => {
        this.form.selection = 'none';
        this.form.views.table.weakCallbacks.update &&
            this.form.views.table.weakCallbacks.update();
    };

    const onOpenDeleteItemsDialog = () => {
        const { selection } = this.form;
        const { search, filter: tableFilter } = this.form.url.queryParams;
        const { isAdminForm } = this.form.params;

        if (selection === 'none') {
            this.form.notify({
                text: 'No selected items',
                type: 'error',
                lifetimeMs: 2000,
            });
            return;
        }

        const filter = convertToQueryFilter(
            this.form.views.table.columns,
            tableFilter || []
        );

        this.form.pushDialog('deleteItem', {
            endpointId: 'user',
            selection,
            filter,
            searchPhrase: search,
            queryFilter: isAdminForm
                ? ['roles:*Admin roles:admin roles:*Editor roles:editor']
                : undefined,
            onSuccess: onDeleteSuccess,
        });
    };

    const onBeforeOpen = async () => {
        const sites = Object.getOwnPropertyNames(this.remoteMetaInfo.sites);
        const { isAdminForm } = this.form.params;

        if (isAdminForm) {
            this.form.views.createButton.isHidden = false;
            this.form.views.table.queryFilter = [
                'roles:*Admin roles:admin roles:*Editor roles:editor',
            ];
        } else {
            this.form.views.createButton.isHidden = true;
        }

        this.form.views.table.columns = [
            {
                id: 'registered',
                title: 'Registered At',
                fieldName: 'registered',
                type: 'date',
            },
            {
                id: 'siteId',
                title: 'Sites',
                fieldName: 'siteId',
                width: 200,
                type: 'enum',
                values: sites.map((value) => ({ value, label: value })),
            },
            {
                id: 'roles',
                title: 'Roles',
                fieldName: 'roles',
                type: 'enum',
                width: 200,
                values: [
                    {
                        value: 'admin',
                        label: 'admin',
                    },
                    ...sites.map((value) => ({
                        value: `${value}User`,
                        label: `${value}User`,
                    })),
                    ...sites.map((value) => ({
                        value: `${value}Editor`,
                        label: `${value}Editor`,
                    })),
                    ...sites.map((value) => ({
                        value: `${value}Admin`,
                        label: `${value}Admin`,
                    })),
                ],
            },
            {
                title: 'username',
                id: 'username',
                fieldName: 'username',
                type: 'text',
            },
            {
                title: 'Full Name',
                id: 'fullName',
                fieldName: 'fullName',
                type: 'text',
            },
        ];
    };

    const onCreateNewItem = () => {
        if (this.form.params.isAdminForm) {
            this.form.url.push('/user/system/item');
        } else {
            this.form.url.push('/user/common/item');
        }
    };

    return {
        onBeforeOpen,
        onCreateNewItem,
        onOpenDeleteItemsDialog,
    };
};

export default handers;
