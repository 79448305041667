import React from 'react';
import Checkbox from '../../../checkbox';
import { CheckBoxState } from '../../../checkbox/props';
import PropsSelectHeader from './props';
import style from './style.module.scss';

const SelectHeader = ({ selection, onToggleAllItems }: PropsSelectHeader) => {
    let state: CheckBoxState = 'unchecked';

    if (selection.isAllItemsSelected()) {
        state = 'checked';
    } else if (
        selection.isInfinite() ||
        selection.getNumberOfSelectedItems() > 0
    ) {
        state = 'intermediate';
    }
    return (
        <div className={style.cell}>
            <Checkbox state={state} onToggle={onToggleAllItems} />
        </div>
    );
};

export default React.memo(SelectHeader);
