import { DialogData } from '../../../../../components/form/context/types';
import content from './content';
import handlers from './handlers';

const addDialog: DialogData = {
    id: 'add-job-in-queue-dialog',
    content,
    fields: {
        repeatType: 'cron',
        data: {
            data: {},
            opts: {
                timeout: 30000,
                attempts: 1,
                delay: 0,
                removeOnComplete: true,
                removeOnFail: false,
                repeat: {
                    every: 30000,
                    count: 0,
                    limit: 1,
                    // cron: "* * * * *"
                },
            },
        },
    },
    handlers,
};

export default addDialog;
