import { useDebugValue, useEffect, useState } from 'react';
import { callGetMethod } from '../../../requests/crud';
import { saveFile } from '../../../utils';
import PropsMediaPhotoItem from './props';

const isLottie = (text: string): boolean => {
    try {
        const data = JSON.parse(text);
        return (
            data.hasOwnProperty('v') ||
            data.hasOwnProperty('layers') ||
            data.hasOwnProperty('animation')
        );
    } catch (e) {}
    return false;
};

const getMediaType = async (data: any, url: string): Promise<string> => {
    let mediaType = data && data.mime;

    if (mediaType) {
        if (mediaType.includes('image') || mediaType.includes('photo')) {
            mediaType = 'image';
        } else if (mediaType.includes('video')) {
            mediaType = 'video';
        }
    }

    const isDataUrl = url && url.startsWith('data:application/json');
    if (isDataUrl || (data && data.ext === 'json')) {
        const data = await callGetMethod(url);
        if (isLottie(data)) {
            return 'lottie';
        }
    }

    return mediaType;
};

const useComponentState = (props: PropsMediaPhotoItem) => {
    const {
        isInEditMode,
        itemData,
        onClick,
        onDelete,
        onOpen,
        isDraggable,
        width = 140,
        height = 140,
        apiServerAddress,
    } = props;
    const [mediaType, setMediaType] = useState<string>();
    const { isUploadError, isUploading, progress, URL, data } =
        itemData ?? ({} as any);

    const url = URL.startsWith('/') ? apiServerAddress + URL : URL;

    useDebugValue(`MEDIA TYPE ${mediaType}`);

    useEffect(() => {
        if (!data) {
            return;
        }
        getMediaType(data, url).then((mediaType) => setMediaType(mediaType));
    }, [data, url]);

    const onDownload = () => {
        saveFile('Video', URL);
    };

    return {
        isInEditMode,
        isUploading,
        progress,
        height,
        width,
        onClick,
        url,
        isUploadError,
        isDraggable,
        onOpen,
        onDelete,
        mediaType,
        onDownload,
    };
};

export default useComponentState;
