import React from 'react';
import CronBuilderNative from 'react-cron-builder';
import 'react-cron-builder/dist/bundle.css';
import { useAppContextObserver } from '../../../../../../components/form/context';
import { getHandlerWithContext, getValue } from '../../../../../../utils';
import PropsCronBuilder from './props';
import style from './style.module.scss';

const CronBuilder = (props: PropsCronBuilder) => {
    const { dataSource } = props;
    const [{ value, isHidden }, getContext] = useAppContextObserver(
        (context) => {
            return {
                isHidden: getValue(props.isHidden, context),
                value: context.form.getDataSourceValue(dataSource),
            };
        }
    );

    if (isHidden) {
        return null;
    }

    const handleChangeValue = (newValue: any) => {
        const { onChange, dataSource } = props;
        const context = getContext();
        if (dataSource) {
            context.form.setDataSourceValue(dataSource, newValue);
        }

        if (!onChange) {
            return;
        }

        const handler = getHandlerWithContext(onChange, context);
        handler!(newValue);
    };

    return (
        <div className={style.cronBuilder}>
            <CronBuilderNative
                cronExpression={value}
                onChange={handleChangeValue}
                showResult
            />
        </div>
    );
};

export default CronBuilder;
