import React, { useCallback } from 'react';
import { getHandlerWithContext, getValue } from '../../../utils';
import SelectInput from '../../native/select-input';
import { useAppContextObserver } from '../context';
import PropsEnumInput, { EnumItem } from './props';

const EnumInput = (props: PropsEnumInput) => {
    const [{ isHidden, isDisabled, displayError, values, value }, getContext] =
        useAppContextObserver((context) => {
            const values = Array.isArray(props.values)
                ? props.values
                : getValue(props.values, context);

            let value: string | number | (number | string)[] | undefined =
                props.value;
            if (props.dataSource) {
                value = context.form.getDataSourceValue(props.dataSource)!;
            }

            return {
                isHidden: getValue(props.isHidden, context),
                isDisabled: getValue(props.isDisabled, context),
                displayError: getValue(props.displayError, context),
                values,
                value,
            };
        });
    const { id, isMultiple, label, dataSource, isCreatable } = props;

    const onCreateNewValue = useCallback(
        (selectedValue: EnumItem) => {
            let value;

            // Fixme: add support to multiple items

            if (isMultiple) {
                // value = selectedValue ?? selectedValue.map(item => item.value) || [];
            } else {
                value = selectedValue ? selectedValue.value : selectedValue;
            }

            if (dataSource) {
                getContext().form.setDataSourceValue(dataSource, value);
            }

            if (!props.onCreateNewValue) {
                return;
            }

            const handler = getHandlerWithContext(
                props.onCreateNewValue,
                getContext()
            );
            handler!(value);
        },
        [getContext, dataSource, isMultiple, props.onCreateNewValue]
    );

    const onChangeValue = useCallback(
        (selectedValue: EnumItem | EnumItem[]) => {
            let value;

            if (isMultiple) {
                value =
                    (selectedValue &&
                        (selectedValue as EnumItem[]).map(
                            (item) => item.value
                        )) ||
                    [];
            } else {
                value = selectedValue
                    ? (selectedValue as EnumItem).value
                    : selectedValue;
            }

            if (dataSource) {
                getContext().form.setDataSourceValue(dataSource, value);
            }

            if (!props.onChangeValue) {
                return;
            }

            const handler = getHandlerWithContext(
                props.onChangeValue,
                getContext()
            );
            handler!(value);
        },
        [getContext, dataSource, isMultiple, props.onChangeValue]
    );

    const onChange = useCallback(
        (selectedValue: EnumItem) => {
            if ((selectedValue as any).__isNew__) {
                onCreateNewValue(selectedValue);
                return;
            }

            onChangeValue(selectedValue);
        },
        [onCreateNewValue, onChangeValue]
    );

    if (isHidden) {
        return null;
    }

    return (
        <SelectInput
            isCreatable={isCreatable}
            isMultiple={isMultiple}
            label={label}
            id={id}
            isDisabled={isDisabled}
            displayError={displayError}
            value={value! as any}
            options={values}
            onChange={onChange}
        />
    );
};

export default React.memo(EnumInput);
