import ViewTypes from '../../../../../../core/components/form/ViewTypes';
import { CustomItemFormConfig } from '../../../../../../core/config-builder/forms/item/types';
import handlers from './hadlers';

const form: CustomItemFormConfig = {
    handlers,
    views: {
        city: {
            isCreatable: true,
            type: ViewTypes.EnumInput,
            values: [],
            onCreateNewValue: 'onCreateNewCity',
        },
        subLocation: {},
        mapCoords: {},
    },
};

export default form;
