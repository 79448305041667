export default {
    title: 'לידים',
    metaInfo: {
        propertiesOrder: [
            'business',
            'businessIdentifier',
            'address',
            'phone',
            'area',
            'website',
            'instagram',
            'facebook',
            'category',
            'contact',
            'position',
            'contactNumber',
            'title',
            'description',
            'exceptions',
            'howTo',
            'validTo',
            'activated',
            'createdAt',
        ],
        properties: {
            business: { name: 'עם העסק' },
            logo: { name: 'לוגו' },
            phone: { name: 'טלפון' },
            address: { name: 'כתובת' },
            website: { name: 'אתר' },
            instagram: { name: 'אינסטגרם' },
            facebook: { name: 'פייסבוק' },
            category: { name: 'קטגוריה' },
            title: { name: 'הטבה' },
            description: { name: 'תיאור' },
            exceptions: { name: 'אותיות קטנות' },
            contact: { name: 'איש קשר' },
            position: { name: 'תפקיד' },
            contactNumber: { name: 'טלפון איש קשר' },
            agree: { name: 'מאושר', isHidden: true },
            businessIdentifier: { name: 'חפ עסק' },
            howTo: { name: 'צורת מימוש' },
            validTo: { name: 'תוקף' },
            activated: { name: 'פעיל' },
            area: { name: 'איזור' },
            createdAt: { name: 'נוצר ב' },
        },
    },
    forms: {
        list: {
            // content of the list form
            defaultUrlQueryParams: {
                sort: [
                    {
                        columnId: 'createdAt',
                        direction: 'desc',
                    },
                ],
            },
            columnsOrder: [
                'logo',
                'business',
                'category',
                'title',
                'activated',
                'validTo',
                'createdAt',
            ],
            columns: {
                business: { title: 'עם העסק' },
                logo: { title: 'לוגו' },
                phone: { isHidden: true },
                address: { isHidden: true },
                website: { isHidden: true },
                instagram: { isHidden: true },
                facebook: { isHidden: true },
                category: { title: 'קטגוריה' },
                title: { title: 'הטבה' },
                description: { isHidden: true },
                exceptions: { isHidden: true },
                contact: { isHidden: true },
                position: { isHidden: true },
                contactNumber: { isHidden: true },
                agree: { isHidden: true },
                businessIdentifier: { isHidden: true },
                howTo: { isHidden: true },
                validTo: { title: 'תוקף' },
                activated: { title: 'פעיל' },
                area: { isHidden: true },
                createdAt: { title: 'נוצר ב' },
            },
        },
    },
};
