import React, { Component } from 'react';
import PropTypes from 'prop-types';

import DateInput from '../../../../date-input';

import style from './style.module.scss';

class DateCellQuickEdit extends Component {
    constructor(props) {
        super(props);
        this.state = {
            value: props.value,
        };
    }

    UNSAFE_componentWillReceiveProps(nextProps) {
        if (nextProps.value !== this.props.value) {
            this.setState({
                value: nextProps.value,
            });
        }
    }

    _handleApply = (e) => {
        e.stopPropagation();
        const { value } = this.state;
        this.props.onUpdate(value);
    };

    _handleCancel = (e) => {
        e.stopPropagation();
        this.props.onCancel();
    };

    _handleChangeValue = (newValue) => {
        this.setState({
            value: newValue,
        });
    };

    render() {
        const { column, cellId } = this.props;
        const { value } = this.state;

        return (
            <div className={style.dateCellQuickEdit}>
                <div className={style.dateContainer}>
                    <DateInput
                        id={cellId + '-date'}
                        label={column.title}
                        // displayError={displayError}
                        // disabled={isDisabled}
                        // placeholder={placeholder}
                        value={value}
                        showTimePicker={true}
                        // showClearButton={showClearButton}
                        showDatePickerAs={'dropdown'}
                        onChange={this._handleChangeValue}
                    />
                </div>
                <button
                    className={style.applyButton}
                    onClick={this._handleApply}
                >
                    Apply
                </button>
                <button
                    className={style.cancelButton}
                    onClick={this._handleCancel}
                >
                    Cancel
                </button>
            </div>
        );
    }
}

DateCellQuickEdit.propTypes = {
    column: PropTypes.any,
    item: PropTypes.any,
    onUpdate: PropTypes.func.isRequired,
    onCancel: PropTypes.func.isRequired,
};

DateCellQuickEdit.defaultProps = {};

export default DateCellQuickEdit;
