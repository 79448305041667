import React, { CSSProperties } from 'react';
import PropsCellNumber from './props';
import style from './style.module.scss';
import UpdateInPlace from './update-in-place';

const CellNumber = ({
    item,
    column,
    value,
    updateValue,
    isUpdateInPlaceProcessing,
}: PropsCellNumber) => {
    const { updateInPlace } = column;

    let calculatedStyle: CSSProperties | undefined;

    if (column.style && typeof column.style === 'function') {
        calculatedStyle = column.style!({ column, item, value });
    } else {
        calculatedStyle = column.style;
    }

    value = value || 0;

    const UpdateInPlaceComponent =
        column.UpdateInPlaceComponent ?? UpdateInPlace;
    if (updateInPlace) {
        return (
            <UpdateInPlaceComponent
                value={value}
                column={column}
                isUpdateInPlaceProcessing={isUpdateInPlaceProcessing}
                item={item}
                updateValue={updateValue}
                updateInPlace={updateInPlace}
            />
        );
    }

    return (
        <div className={style.numberCell} style={calculatedStyle}>
            <span>{value}</span>
        </div>
    );
};

export default React.memo(CellNumber);
