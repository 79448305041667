import React from 'react';
import PropsCheckBox, { CheckBoxState } from './props';
import style from './style.module.scss';

const Checkbox = ({ state, isDisabled, onToggle }: PropsCheckBox) => {
    const _handleClick = (e: any) => {
        e.stopPropagation();

        if (isDisabled) return;

        const newState: CheckBoxState =
            state === 'checked' ? 'unchecked' : 'checked';
        onToggle(newState);
    };

    let boxClass = style.box;

    if (state === 'checked') {
        boxClass = style.boxCheck;
    } else if (state === 'intermediate') {
        boxClass = style.boxIntermediate;
    }

    return (
        <div
            className={style.container}
            onClick={_handleClick}
            data-is-disabled={!!isDisabled}
        >
            <div className={boxClass}>
                {state === 'checked' && (
                    <i className="ion ion-ios-checkmark " />
                )}
                {state === 'intermediate' && (
                    <i className="ion ion-ios-square" />
                )}
            </div>
        </div>
    );
};

export default React.memo(Checkbox);
