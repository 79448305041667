import { dataURLtoBlob, sleep } from '../utils';
import Selection from '../utils/Selection';
import {
    callDeleteMethod,
    callGetMethod,
    callGetMethodCached,
    callPostMethod,
    callPutMethod,
    clearCache,
} from './crud';

export class CustomError extends Error {
    code: number;
    description: string;

    constructor(
        message: string = '',
        code: number = 0,
        description: string = ''
    ) {
        super(message);

        this.code = code;
        this.description = description;
    }
}

export interface RawTableItem {
    _id: string;
    _source: any;
}

export interface TableItem {
    id: string;
    [propertyName: string]: any;
}

export type FieldName = string;
export interface FilterItemObject {
    field: FieldName;
    includeAll?: string[];
    excludeAll?: string[];
    include?: string[];
    exclude?: string[];
}

export type FilterItemRawString = string;

export type FilterItem = FilterItemObject | FilterItemRawString;

export interface SortItem {
    field: string;
    direction: string;
}

export interface ItemsByIds {
    [key: string]: TableItem;
}

export type TableItemId = string;

function getBaseUrl(): string {
    return window._CONFIG_PARAMS.baseApiUrl;
}

async function getCurrentUserData(token?: string): Promise<any> {
    const response = await callGetMethod(`${getBaseUrl()}/user`, token);

    if (String(response.status) !== '200') {
        throw new Error(response.status);
    }

    return response.body;
}

async function loginByEmail(
    email: string,
    password: string
): Promise<{ userData: any; token: string }> {
    const response = await callPostMethod(`${getBaseUrl()}/login`, {
        email,
        password,
    });

    if (String(response.status) !== '200') {
        if (
            String(response.status) === '403' &&
            response.body &&
            response.body.token
        ) {
            throw new Error(response.body);
        }

        throw new Error(response.body && response.body.message);
    }

    const result = response.body;
    return {
        userData: result.user,
        token: result.token,
    };
}

/**
 * Get item from table
 */
async function getItemsFromTable(
    siteId: string | undefined,
    endpointId: string,
    sorted: SortItem[],
    filter: FilterItem[],
    from: number,
    limit: number,
    searchPhrase?: string,
    searchFilter?: string,
    queryParams = ''
): Promise<{ total: number; items: TableItem[]; aggregations: any }> {
    let sort = '';
    if (sorted.length > 0) {
        sort = sortedToElasticSortParam(sorted);
    }

    const filterParam = filterToElasticQuery(filter);

    if (isNaN(from)) {
        from = 0;
    }

    let phraseParam = '';
    if (searchPhrase) {
        phraseParam = `&phrase=${encodeURIComponent(searchPhrase)}`;
    }

    if (!queryParams && !queryParams.trim()) {
        queryParams = '';
    } else {
        queryParams = `&${queryParams}`;
    }

    let url = `api/${siteId}/search/${endpointId}`;
    if (!siteId) {
        url = `search/${endpointId}`;
    }

    let phraseFields = '';

    if (searchFilter) {
        phraseFields = `&phrase_fields=${encodeURIComponent(searchFilter)}`;
    }

    const response = await callGetMethod(
        `${getBaseUrl()}/${url}?showall=y${queryParams}&from=${from}&size=${limit}${filterParam}${phraseParam}${phraseFields}${sort}`
    );
    if (String(response.status) !== '200') {
        throw new Error(response.message);
    }

    const result = response.body;

    return {
        total: result.hits.total,
        aggregations: result.aggregations,
        items: result.hits.hits.map((item: any) => {
            return {
                ...item._source,
                id: item._id,
                _id: item._id,
            };
        }),
    };
}

const filterToElasticQuery = (filter: FilterItem[]): string => {
    let filterParam = '';

    if (filter && filter.length > 0) {
        for (
            let filterItemIndex = 0;
            filterItemIndex < filter.length;
            filterItemIndex++
        ) {
            const value = filter[filterItemIndex];
            if (typeof value === 'string') {
                filterParam += ` ${value} `;
                continue;
            }

            const { field, include, exclude, includeAll, excludeAll } =
                value as FilterItemObject;

            if (include && include.length > 0) {
                const param = `${field}:("${include.join('" OR "')}")`;
                filterParam += ` ${param}`;
            }

            if (includeAll && includeAll.length > 0) {
                const param = `${field}:("${includeAll.join('" AND "')}")`;
                filterParam += ` ${param}`;
            }

            if (exclude && exclude.length > 0) {
                const param = `(${field}:(!"${exclude.join('" OR !"')}"))`;
                filterParam += ` ${param}`;
            }

            if (excludeAll && excludeAll.length > 0) {
                const param = `(${field}:(!"${excludeAll.join('" AND !"')}"))`;
                filterParam += ` ${param}`;
            }
        }

        filterParam = filterParam.trim();
        if (filterParam && filterParam.length > 0) {
            filterParam = `&q=${filterParam}`;
        }
    }

    return filterParam;
};

const sortedToElasticSortParam = (sorted: SortItem[]): string => {
    return `&sort=${sorted
        .map((item) => `${item.field}:${item.direction}`)
        .join(',')}`;
};

const getCsvFromTable = async (
    siteId: string | undefined,
    endpointId: string,
    sorted: SortItem[],
    filter: FilterItem[],
    from: number,
    limit: number,
    searchPhrase?: string,
    queryParams: string = ''
): Promise<string> => {
    let sort = '';
    if (sorted.length > 0) {
        sort = sortedToElasticSortParam(sorted);
    }

    const filterParam = filterToElasticQuery(filter);

    if (isNaN(from)) {
        from = 0;
    }

    let phraseParam = '';
    if (searchPhrase) {
        phraseParam = `&phrase=${encodeURIComponent(searchPhrase)}`;
    }

    if (!queryParams && !queryParams.trim()) {
        queryParams = '';
    } else {
        queryParams = `&${queryParams}`;
    }

    let url = `api/${siteId}/search/${endpointId}`;
    if (!siteId) {
        url = `search/${endpointId}`;
    }

    const result = await callGetMethod(
        `${getBaseUrl()}/${url}?showall=y${queryParams}&from=${from}&size=${limit}${filterParam}${phraseParam}${sort}`,
        undefined,
        {
            'Content-Type': 'text/csv',
            Accept: '*/*',
        },
        (response) => response.text()
    );

    return result;
};

export const getItemFromTable = async (
    siteId: string | undefined,
    endpointId: string,
    itemId: string
): Promise<TableItem> => {
    const { items } = await getItemsFromTable(
        siteId,
        endpointId,
        [
            {
                field: '_id',
                direction: 'asc',
            },
        ],
        [
            {
                field: '_id',
                includeAll: [itemId],
            },
        ],
        0,
        1
    );

    if (items.length > 0) {
        return items[0];
    }

    throw new Error('NotFound');
};

const getItemsByIdFromTable = async (
    siteId: string | undefined,
    endpointId: string,
    ids: string[],
    idField = '_id'
): Promise<ItemsByIds> => {
    if (ids.length === 0) {
        return {};
    }

    let url = `api/${siteId}/search/${endpointId}`;
    if (!siteId) {
        url = `search/${endpointId}`;
    }

    const response = await callGetMethod(
        `${getBaseUrl()}/${url}?showall=y&q=${encodeURIComponent(
            `${idField}:("${ids.join('" OR "')}")`
        )}`
    );
    if (String(response.status) !== '200') {
        throw new Error(response.message);
    }

    const result: ItemsByIds = {};
    response.body.hits.hits.forEach((item: any) => {
        let id = item._id;
        if (idField !== '_id') {
            id = item._source[idField];
        }

        result[id] = {
            id: item._id,
            _id: item._id,
            ...item._source,
        };
    });

    return result;
};

const logout = () => {};

const updateTableItemField = async (
    siteId: string | undefined,
    endpointId: string,
    itemId: string,
    fieldName: FieldName,
    newValue: any
): Promise<void> => {
    let url = `api/${siteId}/${endpointId}`;
    if (!siteId) {
        url = `${endpointId}`;
    }
    const result = await callPutMethod(
        `${getBaseUrl()}/${url}/${encodeURIComponent(itemId)}`,
        {
            [fieldName]: newValue,
        }
    );

    if (result.status !== 200) {
        throw new Error(result.message);
    }
};

const deleteMedia = async (
    siteId: string | undefined,
    endpointId: string,
    mediaId: string,
    attachmentId: string,
    objectFieldWithLink: FieldName
): Promise<void> => {
    let endpointUrl = `api/${siteId}/${endpointId}`;
    if (!siteId) {
        endpointUrl = `${endpointId}`;
    }

    let url = `${getBaseUrl()}/${endpointUrl}/${mediaId}/attachment/${attachmentId}`;
    if (objectFieldWithLink) {
        url += `?field=${objectFieldWithLink}`;
    }

    const result = await callDeleteMethod(url);
    if (result.status !== 200) {
        return result.message;
    }
};

export type DataUrl = string;

const uploadMedia = async (
    siteId: string | undefined,
    endpointId: string,
    objectId: string,
    image: DataUrl | Blob,
    objectFieldToPutLink: FieldName,
    onProgress: (completedPercent: number) => void,
    queryParams?: string
): Promise<{
    URL: string;
    created: string;
    ext: string;
    id: string;
    mime: string;
    modified: string;
    nameId: string;
}> => {
    let endPointUrl = `api/${siteId}/${endpointId}`;
    if (!siteId) {
        endPointUrl = `${endpointId}`;
    }

    return new Promise((resolve, reject) => {
        const request = new XMLHttpRequest();

        // AJAX request finished
        request.addEventListener('load', (e) => {
            const result = request.response;

            if (result.status !== 200) {
                throw new Error(result.message);
            }

            resolve(result.file);
        });

        request.addEventListener('error', (e) => {
            reject(e);
        });

        // Upload progress on request.upload
        request.upload.addEventListener('progress', (e) => {
            const percent_complete = (e.loaded / e.total) * 100;

            // Percentage of upload completed
            onProgress(percent_complete);
        });

        let url = `${getBaseUrl()}/${endPointUrl}/${objectId}/attachment`;
        if (!queryParams) {
            queryParams = '';
        }

        if (objectFieldToPutLink) {
            queryParams += queryParams ? '&' : '';
            queryParams += `field=${objectFieldToPutLink}`;
        }

        if (queryParams) {
            url += `?${queryParams}`;
        }

        request.open('post', url);
        // If server is sending a JSON response then set JSON response type
        request.responseType = 'json';
        request.setRequestHeader('cache-control', 'cache');
        request.setRequestHeader(
            'Authorization',
            `token ${window._AUTH_TOKEN}`
        );
        request.setRequestHeader('content-type', 'binary/octet-stream');
        request.setRequestHeader('reference-path', objectFieldToPutLink);
        // Send POST request to the server side script
        request.send(image instanceof Blob ? image : dataURLtoBlob(image));
    });
};

export interface UpdateData {
    [propertyName: string]: any;
}

export interface CreateData {
    [propertyName: string]: any;
}

const updateTableItem = async (
    siteId: string | undefined,
    endpointId: string,
    itemId: string,
    values: UpdateData
): Promise<void> => {
    let endpointUrl = `api/${siteId}/${endpointId}`;
    if (!siteId) {
        endpointUrl = `${endpointId}`;
    }

    const response = await callPutMethod(
        `${getBaseUrl()}/${endpointUrl}/${encodeURIComponent(itemId)}`,
        values
    );

    if (String(response.status) !== '200') {
        throw new Error(response.status);
    }

    return response.body;
};

const createTableItem = async (
    siteId: string | undefined,
    endpointId: string,
    data: CreateData,
    token?: string
): Promise<TableItemId> => {
    let endpointUrl = `api/${siteId}/${endpointId}`;
    if (!siteId) {
        endpointUrl = `${endpointId}`;
    }

    const response = await callPostMethod(
        `${getBaseUrl()}/${endpointUrl}`,
        data,
        token
    );

    if (String(response.status) !== '200') {
        throw new CustomError(
            response.status,
            response.body?.code,
            response.body?.message
        );
    }

    return response.body._id;
};

const deleteTableItem = async (
    siteId: string | undefined,
    endpointId: string,
    itemId: TableItemId,
    token?: string
): Promise<TableItemId> => {
    let endpointUrl = `api/${siteId}/${endpointId}`;
    if (!siteId) {
        endpointUrl = `${endpointId}`;
    }

    const response = await callDeleteMethod(
        `${getBaseUrl()}/${endpointUrl}/${itemId}`,
        token
    );

    if (String(response.status) !== '200') {
        throw new Error(response.status);
    }

    return response.body._id;
};

const getItemsFromTableSelection = async (
    siteId: string | undefined,
    endpointId: string,
    sorted: SortItem[],
    selection: Selection,
    filter: FilterItem[],
    from: number,
    limit: number,
    searchPhrase?: string,
    searchFilter?: string,
    queryParams = ''
): Promise<{ total: number; items: TableItem[]; aggregations: any }> => {
    if (!selection.isInfinite() && selection.getSelectedItems().length === 0) {
        return { items: [], total: 0, aggregations: undefined };
    }

    if (selection.isInfinite() && selection.getExcludedItems().length === 0) {
        return await getItemsFromTable(
            siteId,
            endpointId,
            sorted,
            [...filter],
            from,
            limit,
            searchPhrase,
            searchFilter,
            queryParams
        );
    }

    const includedItems = selection.isInfinite()
        ? []
        : selection.getSelectedItems();
    const excludedItems = selection.isInfinite()
        ? selection.getExcludedItems()
        : [];

    let requestFilter = [];
    if (includedItems.length > 0 || excludedItems.length > 0) {
        requestFilter.push({
            field: '_id',
            include: includedItems,
            exclude: excludedItems,
        });
    }

    if (filter.length > 0) {
        if (requestFilter.length > 0) {
            requestFilter.push('AND');
        }

        requestFilter = [...requestFilter, ...filter];
    }
    return await getItemsFromTable(
        siteId,
        endpointId,
        sorted,
        requestFilter,
        from,
        limit,
        searchPhrase,
        searchFilter,
        queryParams
    );
};

export interface RawTableSearchResult {
    hits: {
        total: number;
        hits: TableItem[];
    };
    aggregations?: any;
}

const callSearchRequest = async (
    siteId: string | undefined,
    endpointId: string,
    params?: string
): Promise<RawTableSearchResult> => {
    let url = `api/${siteId}/search/${endpointId}`;
    if (!siteId) {
        url = `search/${endpointId}`;
    }

    const response = await callGetMethod(
        `${getBaseUrl()}/${url}?${params ?? ''}`
    );
    if (String(response.status) !== '200') {
        throw new Error(response.message);
    }

    const result = response.body;
    return result;
};

const getScript = async (
    siteId: string,
    endpointId: string,
    eventId: string,
    environment: string
): Promise<string> => {
    const headers = {
        'Content-Type': 'application/javascript',
        Accept: 'application/javascript',
        'script-env': environment,
    };

    const response = await callGetMethod(
        `${getBaseUrl()}/api/${siteId}/${endpointId}/scripts/${eventId}`,
        undefined,
        headers,
        (response) => ({
            status: response.status,
            body: response.text(),
        })
    );

    if (String(response.status) !== '200') {
        throw new Error(response.status);
    }

    const result = response.body;
    return result;
};

const deleteScript = async (
    siteId: string,
    endpointId: string,
    eventId: string,
    environment: string
): Promise<void> => {
    clearCache();
    const url = `${getBaseUrl()}/api/${siteId}/${endpointId}/scripts/${eventId}`;

    const headers = {
        'script-env': environment,
    };

    const response = await callDeleteMethod(url, undefined, headers);

    if (String(response.status) !== '200') {
        throw new Error(response.status);
    }
};

export interface EndpointSchema {
    [propertyName: string]: any;
}

export interface EndpointData {
    anonymousType: string;
    constraint: {
        methods: string[];
    };
    createdDate: string;
    indexId: string;
    schema: any;
    typeId: string;
    updatedDate: string;
    id: string;
}

export interface EndpointsDataById {
    [endpointId: string]: EndpointData;
}

const getSiteEndpoints = async (siteId: string): Promise<EndpointsDataById> => {
    const response = await callGetMethodCached(
        `${getBaseUrl()}/sites/${siteId}?size=1000`
    );
    if (String(response.status) !== '200') {
        throw new Error(response.status);
    }

    return response.body.hits.hits.reduce((acc: any, item: RawTableItem) => {
        const source = item._source;
        const endpointId = source.typeId;
        acc[endpointId] = {
            ...source,
            id: endpointId,
            schema: JSON.parse(source.schema),
        };

        return acc;
    }, {});
};

export interface SiteSchema {
    [propertyName: string]: any;
}

export interface SiteData {
    [propertyName: string]: any;
}

export interface SitesDataById {
    [endpointId: string]: SiteData;
}

const getSites = async (full: boolean = false): Promise<SitesDataById> => {
    const response = await callGetMethodCached(
        `${getBaseUrl()}/sites?size=1000`
    );

    if (String(response.status) !== '200') {
        throw new Error(response.status);
    }

    const sitesPartial = response.body.hits.hits;
    const sites: SitesDataById = {};

    const endpointsPromises = [];

    for (let siteIndex = 0; siteIndex < sitesPartial.length; siteIndex++) {
        const item = sitesPartial[siteIndex];
        const siteId = item._id;

        if (full) {
            endpointsPromises.push(getSiteEndpoints(siteId));
        }

        sites[siteId] = {
            ...item._source,
        };
    }

    if (full) {
        const siteEndpoints = await Promise.all(endpointsPromises);
        siteEndpoints.forEach((endpoints, index) => {
            const siteId = sitesPartial[index]._id;
            sites[siteId].endpoints = endpoints;
        });
    }

    return sites;
};

const updateSiteSchema = async (data: any): Promise<void> => {
    clearCache();
    const response = await callPutMethod(`${getBaseUrl()}/schema`, data);

    if (String(response.status) !== '200') {
        throw new Error(response.status);
    }
};

const deleteSite = async (siteId: string): Promise<void> => {
    clearCache();
    const response = await callDeleteMethod(`${getBaseUrl()}/sites/${siteId}`);

    if (String(response.status) !== '200') {
        throw new Error(response.status);
    }
};

const deleteEndpoint = async (
    siteId: string,
    endpointId: string
): Promise<void> => {
    clearCache();
    const response = await callDeleteMethod(
        `${getBaseUrl()}/sites/${siteId}/endpoint/${endpointId}`
    );

    if (String(response.status) !== '200') {
        throw new Error(response.status);
    }
};

const updateUser = async (userId: string, values: UpdateData) => {
    const response = await callPostMethod(
        `${getBaseUrl()}/user/${encodeURIComponent(userId)}`,
        values
    );

    if (String(response.status) !== '200') {
        throw new Error(response.status);
    }

    return response.body;
};

const changePassword = async (
    newPassword: string,
    token: string,
    userId: string | undefined
) => {
    let url = `${getBaseUrl()}/user`;
    if (userId) {
        url += `/${userId}`;
    }

    const response = await callPostMethod(
        url,
        {
            password: newPassword,
        },
        token
    );

    if (String(response.status) !== '200') {
        throw new Error(response.status);
    }

    return response.body;
};

const createUser = async (values: any) => {
    const response = await callPostMethod(`${getBaseUrl()}/register`, values);

    if (String(response.status) !== '200' && response.status !== 201) {
        throw new Error(response.status);
    }

    return response.body.user;
};

const registerUserOnSites = async (
    userId: string,
    siteId: string,
    roles: string[]
) => {
    const response = await callPostMethod(
        `${getBaseUrl()}/register/site/${userId}`,
        {
            siteId,
            roles,
        }
    );

    if (String(response.status) !== '200') {
        throw new Error(response.status);
    }
};

const get = async (url: string): Promise<any> => {
    return await callGetMethod(`${getBaseUrl()}/api${url}`);
};

const update = async (url: string, data: UpdateData): Promise<any> => {
    const result = await callPutMethod(`${getBaseUrl()}/api${url}`, data);
    await sleep(1000);
    return result;
};

const remove = async (url: string): Promise<any> => {
    const result = await callDeleteMethod(`${getBaseUrl()}/api${url}`);
    await sleep(1000);
    return result;
};

const create = async (url: string, data: CreateData): Promise<any> => {
    const result = await callPostMethod(`${getBaseUrl()}/api${url}`, data);
    await sleep(1000);
    return result;
};

const getJobsScripts = async (siteId: string, endpointId: string) => {
    const url = `api/${siteId}/${endpointId}/jobs/files/list`;

    const response = await callGetMethod(`${getBaseUrl()}/${url}`);
    if (String(response.status) !== '200') {
        throw new Error(response.message);
    }

    return response.body.files;
};

const updateScript = async (
    siteId: string,
    endpointId: string,
    eventId: string,
    scriptText: string,
    environment: string
) => {
    clearCache();

    const headers = {
        'script-env': environment,
    };

    const response = await callPutMethod(
        `${getBaseUrl()}/api/${siteId}/${endpointId}/scripts/${eventId}`,
        scriptText,
        undefined,
        undefined,
        'application/javascript',
        (data) => data,
        headers
    );

    if (String(response.status) !== '200') {
        throw new Error(response.status);
    }

    const result = response.body;
    return result;
};

const deleteJobScript = async (
    siteId: string,
    endpointId: string,
    job: string
) => {
    clearCache();
    const url = `${getBaseUrl()}/api/${siteId}/${endpointId}/jobs/${job}`;

    const response = await callDeleteMethod(url);

    if (String(response.status) !== '200') {
        throw new Error(response.status);
    }
};

const updateJobScript = async (
    siteId: string,
    endpointId: string,
    job: string,
    scriptText: string
) => {
    clearCache();

    const response = await callPutMethod(
        `${getBaseUrl()}/api/${siteId}/${endpointId}/jobs/${job}`,
        scriptText,
        undefined,
        undefined,
        'application/javascript',
        (data) => data
    );

    if (String(response.status) !== '200') {
        throw new Error(response.status);
    }

    const result = response.body;
    return result;
};

const getJobScript = async (
    siteId: string,
    endpointId: string,
    job: string
) => {
    const headers = {
        'Content-Type': 'application/javascript',
        Accept: 'application/javascript',
    };

    const response = await callGetMethod(
        `${getBaseUrl()}/api/${siteId}/${endpointId}/jobs/${job}`,
        undefined,
        headers,
        (response) => ({
            status: response.status,
            body: response.text(),
        })
    );

    if (String(response.status) !== '200') {
        throw new Error(response.status);
    }

    const result = response.body;
    return result;
};

const getJobsInQueue = async (
    siteId: string,
    endpointId: string,
    fileId: string,
    params: any
) => {
    const url = `${getBaseUrl()}/api/${siteId}/${endpointId}/jobs/${fileId}/getJobs`;
    const response = await callPostMethod(url, params);

    if (String(response.status) !== '200') {
        throw new Error(response.status);
    }

    const result = response.body;
    return result.jobs.filter((item: any) => !!item);
};

const addJobScriptToQueue = async (
    siteId: string,
    endpointId: string,
    fileId: string,
    params: any
) => {
    const url = `${getBaseUrl()}/api/${siteId}/${endpointId}/jobs/${fileId}/add`;
    const response = await callPostMethod(url, params);

    if (String(response.status) !== '200') {
        throw new Error(response.status);
    }

    const result = response.body;
    return result;
};

const getJob = async (
    siteId: string,
    endpointId: string,
    fileId: string,
    jobId: string
) => {
    const url = `${getBaseUrl()}/api/${siteId}/${endpointId}/jobs/${fileId}/getJob`;
    const response = await callPostMethod(url, {
        jobId,
    });

    if (String(response.status) !== '200') {
        throw new Error(response.status);
    }

    const result = response.body;
    return result;
};

const getJobLogs = async (
    siteId: string,
    endpointId: string,
    fileId: string,
    jobId: string
) => {
    const url = `${getBaseUrl()}/api/${siteId}/${endpointId}/jobs/${fileId}/getJobLogs`;
    const response = await callPostMethod(url, {
        jobId,
        start: 0,
        end: 100,
    });

    if (String(response.status) !== '200') {
        throw new Error(response.status);
    }

    const result = response.body;
    return result;
};

const emptyJobsQueue = async (
    siteId: string,
    endpointId: string,
    fileId: string
) => {
    const url = `${getBaseUrl()}/api/${siteId}/${endpointId}/jobs/${fileId}/empty`;
    const response = await callPostMethod(url, {});

    if (String(response.status) !== '200') {
        throw new Error(response.status);
    }

    const result = response.body;
    return result;
};

const pauseJobsInQueue = async (
    siteId: string,
    endpointId: string,
    fileId: string
) => {
    const url = `${getBaseUrl()}/api/${siteId}/${endpointId}/jobs/${fileId}/pause`;
    const response = await callPostMethod(url, {});

    if (String(response.status) !== '200') {
        throw new Error(response.status);
    }

    const result = response.body;
    return result;
};

const resumeJobsInQueue = async (
    siteId: string,
    endpointId: string,
    fileId: string
) => {
    const url = `${getBaseUrl()}/api/${siteId}/${endpointId}/jobs/${fileId}/resume`;
    const response = await callPostMethod(url, {});

    if (String(response.status) !== '200') {
        throw new Error(response.status);
    }

    const result = response.body;
    return result;
};

const cleanJobsQueue = async (
    siteId: string,
    endpointId: string,
    fileId: string,
    grace: number,
    status: string
) => {
    const url = `${getBaseUrl()}/api/${siteId}/${endpointId}/jobs/${fileId}/clean`;
    const response = await callPostMethod(url, {
        grace,
        status,
    });

    if (String(response.status) !== '200') {
        throw new Error(response.status);
    }

    const result = response.body;
    return result;
};

const getRepeatableJobs = async (
    siteId: string,
    endpointId: string,
    fileId: string
) => {
    const url = `${getBaseUrl()}/api/${siteId}/${endpointId}/jobs/${fileId}/getRepeatableJobs`;
    const response = await callPostMethod(url, {
        start: 0,
        end: 100,
    });

    if (String(response.status) !== '200') {
        throw new Error(response.status);
    }

    const result = response.body;
    return result;
};

const removeRepeatableJobByKey = async (
    siteId: string,
    endpointId: string,
    fileId: string,
    key: string
) => {
    const url = `${getBaseUrl()}/api/${siteId}/${endpointId}/jobs/${fileId}/removeRepeatableByKey`;
    const response = await callPostMethod(url, {
        key,
    });

    if (String(response.status) !== '200') {
        throw new Error(response.status);
    }

    const result = response.body;
    return result;
};

const api = {
    getCurrentUserData,
    loginByEmail,
    getItemsFromTable,
    getItemFromTable,
    logout,
    getItemsByIdFromTable,
    updateTableItemField,
    deleteMedia,
    uploadMedia,
    updateTableItem,
    createTableItem,
    getCsvFromTable,
    deleteTableItem,
    getItemsFromTableSelection,
    callSearchRequest,

    getScript,
    deleteScript,
    updateScript,
    getSites,

    updateSiteSchema,
    deleteSite,
    deleteEndpoint,
    clearCache,

    updateUser,
    createUser,
    registerUserOnSites,

    changePassword,

    get,
    create,
    update,
    remove,

    getJobsScripts,
    getJobScript,
    updateJobScript,
    deleteJobScript,
    addJobScriptToQueue,
    getJobsInQueue,
    getJob,
    getJobLogs,
    emptyJobsQueue,

    getRepeatableJobs,
    removeRepeatableJobByKey,
    cleanJobsQueue,
    resumeJobsInQueue,
    pauseJobsInQueue,
};

export type ApiClient = typeof api;

export default api;
