import {
    AppContext,
    FormFunctionGetHandlers,
} from '../../../components/form/context/types';

const handlers: FormFunctionGetHandlers = function (this: AppContext) {
    const onBeforeOpen = async () => {};

    const onGoBack = () => {
        this.form.url.goBack();
    };

    return {
        onBeforeOpen,
        onGoBack,
    };
};

export default handlers;
