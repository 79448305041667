import { DataSourcePath, HandlerValue, ValueOrHandler } from '../context/types';
import style from './style.module.scss';

interface PropsBooleanInput {
    id: string;
    label: string;
    dataSource?: DataSourcePath<boolean>;
    onChangeValue?: HandlerValue<(newValue: boolean) => void>;
    classContainer?: string;
    classLabel?: string;
    displayError?: ValueOrHandler<boolean>;
    value?: boolean;
    isDisabled?: ValueOrHandler<boolean>;
    isHidden?: ValueOrHandler<boolean>;
}

export default PropsBooleanInput;

export const defaultProps: Partial<PropsBooleanInput> = {
    classContainer: style.inputContainer,
    classLabel: style.label,
};
