import React from 'react';
import Lightbox from 'react-images';
import Lottie from 'react-lottie';
import animationData from '../../../assets/animations/loading.json';
import stubIcon from '../../../assets/icons/blank-picture.svg';
import propsWithDefaultValues from '../../../utils/propsWithDefaultValues';
import MediaPhotoItem from '../media-photo-item';
import UploadInput from '../upload-view';
import Header from './header';
import usePhotoViewState from './hooks';
import PropsPhotoView, { defaultProps } from './props';
import style from './style.module.scss';

const lottieOptions = {
    loop: true,
    autoplay: true,
    animationData,
    rendererSettings: {
        preserveAspectRatio: 'xMidYMid slice',
    },
};

const PhotoView = (props: PropsPhotoView) => {
    props = propsWithDefaultValues(props, defaultProps);

    const {
        id,
        isLoading,
        label,
        undo,
        placeholderText,
        containerClass,
        displayError,
        maxSizeKB,
        saveOnSelect,
        isInEditMode,
        extensionsWithDot,
    } = props;

    const {
        isLightboxOpen,
        currentItem,
        items,
        imageLinks,
        onGoToNext: handleGoToNext,
        onGotoPrevious: handleGotoPrevious,
        onCloseLightbox: handleCloseLightbox,
        onGoToImage: handleGoToImage,
        onDeleteItem: handleDeleteItem,
        onSelectItem: handleSelectItem,
        onToggleEditMode: handleToggleEditMode,
        onSave: handleSave,
        onAddItem: handleAddItem,
    } = usePhotoViewState(props);

    return (
        <div className={containerClass}>
            <Header
                showButtons={!saveOnSelect}
                isInEditMode={isInEditMode}
                title={label}
                total={items.length}
                onUndo={undo}
                onToggleEditMode={handleToggleEditMode}
                onSave={handleSave}
            />
            <div className={style.content}>
                {!isLoading && isInEditMode && items.length === 0 && (
                    <UploadInput
                        id={`photo-view-input${id}`}
                        title={placeholderText}
                        onSelected={handleAddItem}
                        displayError={displayError}
                        maxSizeKB={maxSizeKB}
                        multiple={false}
                        extensionsWithDot={extensionsWithDot}
                    />
                )}
                {!isLoading && !isInEditMode && items.length === 0 && (
                    <div className={style.stub}>
                        <img src={stubIcon} alt="" />
                    </div>
                )}
                {!isLoading && items.length > 0 && (
                    <MediaPhotoItem
                        isInEditMode={!!isInEditMode}
                        isDraggable={false}
                        itemData={items[0]}
                        width="100%"
                        onDelete={handleDeleteItem(items[0].id)}
                        onOpen={() => handleSelectItem(items[0].id)}
                        onClick={() => handleSelectItem(items[0].id)}
                    />
                )}
                {isLoading && (
                    <div className={style.stub}>
                        <Lottie
                            options={lottieOptions}
                            height={100}
                            width={100}
                        />
                    </div>
                )}
            </div>
            {isLightboxOpen && (
                <Lightbox
                    images={imageLinks}
                    currentImage={currentItem}
                    isOpen={isLightboxOpen}
                    showThumbnails
                    onClickNext={handleGoToNext}
                    onClickPrev={handleGotoPrevious}
                    onClose={handleCloseLightbox}
                    onClickThumbnail={handleGoToImage}
                />
            )}
        </div>
    );
};

export default PhotoView;
