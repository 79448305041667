import React from 'react';
import PropsCellColor from './props';
import style from './style.module.scss';

const CellColor = ({ value }: PropsCellColor) => {
    return (
        <div className={style.colorCell}>
            <div
                style={{ backgroundColor: value ?? 'gray' }}
                className={style.color}
            />
            <div>{value}</div>
        </div>
    );
};

export default React.memo(CellColor);
