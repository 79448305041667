import React from 'react';
import Form from '../form';
import { VStack } from '../form/context/types';
import ViewTypes from '../form/ViewTypes';
import NativeDialog from '../native/dialog';
import PropsDialog from './props';

const defaultContent: VStack = {
    id: 'default',
    type: ViewTypes.VStack,
    items: [],
};

const Dialog = (props: PropsDialog) => {
    const {
        id,
        title,
        content,
        handlers,
        className,
        formComponent,
        params,
        baseContext,
        ...restProps
    } = props;

    let FormComponent = formComponent;
    if (!FormComponent) {
        FormComponent = Form;
    }

    return (
        <NativeDialog id={id} isOpened openedClass={className} title="">
            <FormComponent
                {...restProps}
                id={id}
                title={title}
                handlers={handlers}
                content={content ?? defaultContent}
                params={params}
                baseContext={baseContext}
            />
        </NativeDialog>
    );
};

export default React.memo(Dialog);
