export interface AccountState {
    isLoggedIn: boolean;
    isLoginProcessing: boolean;
    allowedSitesIds: string[];
    tokenForChangingPassword?: string | null;
    roles: string[];
}

export const initialState: AccountState = {
    isLoggedIn: false,
    isLoginProcessing: true,
    allowedSitesIds: [],
    tokenForChangingPassword: undefined,
    roles: [],
};
