import moment from 'moment';
import Selection, { SerializedSelection } from '../../../utils/Selection';
import { FilterItem } from './props';
import { Column } from './types';

// FIXME REFACTOR CODE
function mapToQueryFilter(
    columns: Column[],
    columnId: string,
    filterData: any
): FilterItem | undefined | null {
    const column = columns.find((item) => item.id === columnId) as Column;

    if (column.convertFilterToQuery) {
        return column.convertFilterToQuery(column, filterData);
    }

    switch (column.type) {
        case 'enum':
            return convertEnumFilterToQuery(column, filterData);
        case 'boolean':
            return convertBooleanFilterToQuery(column, filterData);
        case 'date':
            return convertDateFilterToQuery(column, filterData);
        case 'reference':
        case 'referenceArray':
            return convertReferenceFilterToQuery(column, filterData);
        case 'image':
            return convertImageFilterToQuery(column, filterData);
    }

    throw new Error(`Filter for the column is not implemented ${columnId}`);
}

export function convertToQueryFilter(
    columns: Column[],
    filter: any
): FilterItem[] {
    const result: FilterItem[] = [];
    const mappedFilter: FilterItem[] = Object.keys(filter)
        .map((columnId) => {
            const mapColumnFilterToQueryFilter = mapToQueryFilter;

            return mapColumnFilterToQueryFilter(
                columns,
                columnId,
                filter[columnId]
            );
        })
        .filter((item) => !!item) as any;

    mappedFilter.forEach((item, index) => {
        if (index > 0) {
            result.push('AND');
        }

        result.push(item);
    });

    return result;
}

function convertEnumFilterToQuery(
    column: Column,
    selectionObject: SerializedSelection
): FilterItem {
    const selection = Selection.unserealizeFromObject(selectionObject);
    const filter: FilterItem = {
        field: column.fieldName,
    };

    if (selection.isInfinite()) {
        filter.excludeAll = selection.getExcludedItems();
    } else {
        filter.include = selection.getSelectedItems();
    }

    return filter;
}

function convertBooleanFilterToQuery(
    column: Column,
    selectionObject: SerializedSelection
): FilterItem | null {
    const selection = Selection.unserealizeFromObject(selectionObject);

    if (selection.isInfinite()) {
        const items = selection.getExcludedItems();
        if (items.length === 0) {
            return null;
        }

        if (items.includes('true') && items.includes('false')) {
            return null;
        }

        if (items.includes('true')) {
            return `${column.fieldName}: true`;
        }

        return `(${column.fieldName}: false OR _missing_: ${column.fieldName})`;
    }

    const items = selection.getSelectedItems();
    if (items.length === 0) {
        return `${column.fieldName}: true AND (${column.fieldName}: false OR _missing_: ${column.fieldName})`;
    }

    if (items.includes('true') && items.includes('false')) {
        return null;
    }

    if (items.includes('true')) {
        return `${column.fieldName}: true`;
    }

    return `(${column.fieldName}: false OR _missing_: ${column.fieldName})`;
}

function convertImageFilterToQuery(
    column: Column,
    selectionObject: SerializedSelection
): FilterItem | null {
    const selection = Selection.unserealizeFromObject(selectionObject);

    if (selection.isInfinite()) {
        const items = selection.getExcludedItems();
        if (items.length === 0) {
            return null;
        }

        if (items.includes('filled') && items.includes('notFilled')) {
            return null;
        }

        if (items.includes('filled')) {
            return `_missing_: ${column.fieldName}`;
        }

        return `_exists_: ${column.fieldName}`;
    }

    const items = selection.getSelectedItems();
    if (items.length === 0) {
        return `_exists_: ${column.fieldName} AND _missing_: ${column.fieldName}`;
    }

    if (items.includes('filled') && items.includes('notFilled')) {
        return null;
    }

    if (items.includes('filled')) {
        return `_exists_: ${column.fieldName}`;
    }

    return `_missing_: ${column.fieldName}`;
}

function convertReferenceFilterToQuery(
    column: Column,
    filterData: SerializedSelection
): FilterItem {
    const selection = Selection.unserealizeFromObject(filterData);
    const filter: FilterItem = {
        field: column.fieldName,
    };

    if (selection.isInfinite()) {
        filter.excludeAll = selection.getExcludedItems();
    } else {
        filter.include = selection.getSelectedItems();
    }

    return filter;
}

export interface DateFilter {
    from: string;
    to: string;
}

function convertDateFilterToQuery(
    column: Column,
    { from: startDate, to: endDate }: DateFilter
): FilterItem {
    const field = column.fieldName;

    const startMoment = moment(startDate, 'DD-MM-YYYYTHH:mm:ss').utc();
    const endMoment = moment(endDate, 'DD-MM-YYYYTHH:mm:ss').utc();

    const dateQueryFormat = 'YYYY-MM-DDTHH:mm:ss';

    if (startDate && endDate) {
        return `${field}:[${startMoment.format(
            dateQueryFormat
        )} TO ${endMoment.format(dateQueryFormat)}]`;
    }

    if (startDate) {
        return `${field}:[${startMoment.format(dateQueryFormat)} TO *]`;
    }

    return `${field}:[* TO ${endMoment.format(dateQueryFormat)}]`;
}
