import { CustomEndpointConfig } from '../../../../core/config-builder/types';

const surveyanswer: CustomEndpointConfig = {
    metaInfo: {
        properties: {
            questionId: {
                endpointId: 'surveyquestion',
                representationDataSource: 'question',
            },
        },
    },
};

export default surveyanswer;
