import { AppContext } from '../../../components/form/context/types';
import { ItemFormFunctionGetHandlers } from '../../../components/item-form/types';
import apiClient from '../../../requests/api';

const handlers: ItemFormFunctionGetHandlers = function (this: AppContext) {
    const getParams = (): {
        siteId?: string;
        endpointId: string;
        objectId?: string;
        isDialog: boolean;
    } => {
        let endpointId;
        let siteId;
        let objectId;

        const isDialog = this.form.params?.isDialog ?? false;

        if (isDialog) {
            objectId = this.form.params?.objectId;
            siteId = this.form.params?.siteId;
            endpointId = this.form.params?.endpointId;
        } else {
            objectId = this.form.url.params.objectId;
            if (this.form.params && this.form.params.endpointId) {
                endpointId = this.form.params.endpointId;
                siteId = this.form.params.siteId;
            } else {
                siteId = this.form.url.params?.siteId;
                endpointId = this.form.url.params?.endpointId;
            }
        }

        return {
            objectId,
            siteId,
            endpointId,
            isDialog,
        };
    };

    const onLoadObject = async ({ siteId, endpointId, objectId }: any) => {
        return await apiClient.getItemFromTable(siteId, endpointId, objectId);
    };

    const reloadObject = async () => {
        const { siteId, endpointId, objectId } = getParams();
        if (!objectId) {
            this.form.object = undefined;
            return;
        }

        this.form.object = await onLoadObject({ siteId, endpointId, objectId });
    };

    const onBeforeOpen = async () => {};

    const onAfterSave = async (objectId: string): Promise<void> => {
        const { siteId, endpointId, isDialog } = getParams();

        if (!isDialog) {
            this.form.url.replace(
                `/${siteId}/${endpointId}/item/${objectId}/info`
            );
            return;
        }

        this.form.pushDialog(`item-dialog-${siteId}-${endpointId}`, {
            activeTabId: 'info',
            objectId,
        });
    };

    const onOpenEndpointConfigEditor = () => {
        const { siteId, endpointId } = getParams();
        this.form.url.push(
            `/${siteId}/${endpointId}/endpoint-config-editor/settings`
        );
    };

    const onDeleteSuccess = () => {
        const { isDialog } = getParams();

        if (!isDialog) {
            this.form.url.goBack();
            return;
        }

        window._refreshList && window._refreshList();
        this.form.closeDialog();
    };

    const onOpenDeleteItemDialog = () => {
        const { siteId, endpointId, objectId } = getParams();

        this.form.pushDialog('deleteItem', {
            siteId,
            endpointId,
            selection: {
                include: [objectId],
            },
            filter: null,
            onSuccess: onDeleteSuccess,
        });
    };

    return {
        onBeforeOpen,
        onLoadObject,
        onAfterSave,
        onOpenEndpointConfigEditor,
        onOpenDeleteItemDialog,
        getParams,
        reloadObject,
    };
};

export default handlers;
