import React from 'react';
import PropsStatistics from './props';
import StatisticItem from './statistic';
import style from './style.module.scss';

const Statistics = (props: PropsStatistics) => {
    const { statistics } = props;
    return (
        <div className={style.statisticsBlock}>
            {statistics.map((item, index) => (
                <StatisticItem
                    key={index}
                    title={item.title}
                    value={item.value}
                    color={item.color}
                />
            ))}
        </div>
    );
};

export default React.memo(Statistics);
