import { CustomSiteConfig } from '../../../core/config-builder/types';
import category from './category';
import cover from './cover';
import edition from './edition';
import product from './product';

const caveret: CustomSiteConfig = {
    title: 'ממשק כוורת',
    endpoints: {
        category,
        cover,
        edition,
        product,
    },
};

export default caveret;
