import apiClient from '../../../requests/api';
import style from './style.module.scss';

export type FieldName = string;

export type TableItemId = string;

export interface OpenItemFunction {
    (itemData: any): void;
}

interface PropsReferenceInput {
    idField?: string;
    searchByField?: FieldName | FieldName[];
    label: string;
    isMulti?: boolean;
    siteId?: string;
    endpointId: string;
    sortListByField?: FieldName;
    sortListDirection?: 'asc' | 'desc';
    representationDataSource: FieldName | FieldName[] | Function;
    isDisabled?: boolean;
    value: TableItemId | TableItemId[] | undefined; // FIXME:
    onChange: (value: string | string[]) => void; // FIXME:
    onOpenItem?: OpenItemFunction;
    displayError?: boolean;
    classContainer?: string;
    classInput?: string;
    classLabel?: string;
    getItemsFromTable?: Function;
    rawSearchFilter?: string;
    isCreatable?: boolean;
    onCreate?: () => void;
}

export const defaultProps = {
    idField: '_id',
    class: style.container,
    sortListDirection: 'asc',
    sortListByField: '_id',
    classContainer: style.referenceInputContainer,
    classInput: style.input,
    classLabel: style.label,
    getItemsFromTable: apiClient.getItemsFromTable,
};

export default PropsReferenceInput;
