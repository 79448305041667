import { CustomEndpointConfig } from '../../../../core/config-builder/types';
import forms from './forms';

const article: CustomEndpointConfig = {
    metaInfo: {
        properties: {},
    },
    forms,
};

export default article;
