import moment from 'moment';
import React, { CSSProperties } from 'react';
import PropsCellDate from './props';
import style from './style.module.scss';

const CellDate = ({ value, item, column }: PropsCellDate) => {
    let calculatedStyle: CSSProperties | undefined;

    if (column.style && typeof column.style === 'function') {
        calculatedStyle = column.style!({ column, item, value });
    } else {
        calculatedStyle = column.style;
    }

    return (
        <div className={style.cell} style={calculatedStyle}>
            {!value && <span>&nbsp;</span>}
            {value && (
                <span>
                    {moment(new Date(value)).format('HH:mm DD/MM/YYYY')}
                </span>
            )}
        </div>
    );
};

export default React.memo(CellDate);
