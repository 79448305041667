import { AppContext, DialogData } from '../../components/form/context/types';
import {
    CompiledAppConfig,
    RemoteMetaInfo,
    SiteConfig,
} from '../../config-builder/types';

interface DialogHistoryItem {
    context: AppContext;
    id: string;
    params?: any;
}

interface CustomDialogs {
    [key: string]: DialogData;
}

export interface AppState {
    config: CompiledAppConfig | null;
    isCurrentSiteLoading: boolean;
    currentSiteId: string | null;
    currentSiteMeta: SiteConfig | null;
    currentSiteApiClient: null;
    remoteMetaInfo: RemoteMetaInfo;
    openedDialogId: string | null;
    openedDialogParams: null;
    customDialogs: CustomDialogs;
    dialogsHistory: DialogHistoryItem[];
    openedDialogContext: AppContext | null;
    sitesMetaInfo?: any; // FIXME add type
    isSidebarOpened: boolean;
}

export const initialState: AppState = {
    config: null,
    isCurrentSiteLoading: false,
    currentSiteId: null,
    currentSiteMeta: null,
    currentSiteApiClient: null,
    remoteMetaInfo: { sites: {} },
    openedDialogId: null,
    openedDialogParams: null,
    customDialogs: {},
    dialogsHistory: [],
    openedDialogContext: null,
    isSidebarOpened: false,
};
