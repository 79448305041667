export default interface PropsHtmlEditor {
    isInEditMode?: boolean;
    value: string;
    onChange: (newValue: string) => void;
    onClose: () => void;
    into: any;
}

export const defaultProps: Partial<PropsHtmlEditor> = {
    value: '',
};
