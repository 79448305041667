import moment from 'moment';
import {
    AppContext,
    FormFunctionGetHandlers,
    FormHandlers,
} from '../../../../core/components/form/context/types';
import apiClient from '../../../../core/requests/api';

const _convertDateFilterToQuery = (
    field: string,
    { startDate, endDate }: any
) => {
    if (!startDate && !endDate) {
        return '';
    }

    const dateQueryFormat = 'YYYY-MM-DDTHH:mm:ss';

    let startMoment;
    if (startDate) {
        startMoment = moment(startDate, 'YYYY-MM-DDTHH:mm:ss').format(
            dateQueryFormat
        );
    }

    let endMoment;
    if (endDate) {
        endMoment = moment(endDate, 'YYYY-MM-DDTHH:mm:ss').format(
            dateQueryFormat
        );
    }

    if (startMoment && endMoment) {
        return `${field}:[${startMoment} TO ${endMoment}]`;
    }

    if (startMoment) {
        return `${field}:[${startMoment} TO *]`;
    }

    return `${field}:[* TO ${endMoment}]`;
};

const handlers: FormFunctionGetHandlers = function (
    this: AppContext
): FormHandlers {
    const getDefaultRound = async () => {
        const { endDate } = this.form;
        const dateFilter = _convertDateFilterToQuery('endAt', { endDate });
        const queryParams = `sort=createdAt:desc&size=1&active:true AND ${dateFilter}`;
        const response = await apiClient.callSearchRequest(
            'songvote',
            'round',
            queryParams
        );

        if (response.hits.hits.length > 0) {
            return response.hits.hits[0]._id;
        }
    };

    const loadTopSongs = async (roundId: string) => {
        this.form.topUsersInTeam = [];

        const sortBy = {
            field: 'value',
            direction: 'desc',
        };

        const aggs = {
            aggs: {
                songs: {
                    terms: {
                        field: 'songId',
                        size: 100,
                        order: { [sortBy.field]: sortBy.direction },
                    },
                    aggs: {
                        value: {
                            sum: {
                                field: 'value',
                            },
                        },
                    },
                },
            },
        };

        const { endDate } = this.form;
        const dateFilter = _convertDateFilterToQuery('createdAt', { endDate });
        let q = `roundId:"${roundId}"`;
        if (dateFilter) {
            q += ` AND ${dateFilter}`;
        }

        const queryParams = `size=0&sort=value:desc&aggs=${JSON.stringify(
            aggs
        )}&q=${q}`;
        const response = await apiClient.callSearchRequest(
            'songvote',
            'vote',
            queryParams
        );

        const rows = response.aggregations.songs.buckets.map((item: any) => {
            return {
                roundId,
                songId: item.key,
                value: item.value.value,
            };
        });

        this.form.topSongs = rows;
    };

    const loadNumberOfVotes = async () => {
        this.form.playedGamesNumber = '';

        const aggs = {
            aggs: {
                votes_count: {
                    value_count: {
                        field: 'userId',
                    },
                },
            },
        };

        const { endDate, roundId } = this.form;
        const dateFilter = _convertDateFilterToQuery('createdAt', { endDate });
        const q = `${dateFilter} AND roundId:"${roundId}"`;
        const queryParams = `size=0&sort=songId:desc&aggs=${JSON.stringify(
            aggs
        )}&q=${q}`;

        const response = await apiClient.callSearchRequest(
            'songvote',
            'vote',
            queryParams
        );

        this.form.numberOfVotes = response.aggregations.votes_count.value;
    };

    const loadData = () => {
        loadNumberOfVotes();
        loadTopSongs(this.form.roundId);
    };

    const onBeforeOpen = async () => {
        this.form.roundId = await getDefaultRound();
        await loadData();
    };

    const onChangeEndDate = async (newEndDate: string) => {
        this.form.endDate = newEndDate;
        await loadData();
    };

    const onChangeRound = async (roundId: string) => {
        this.form.roundId = roundId;
        await loadData();
    };

    return {
        onBeforeOpen,
        onChangeEndDate,
        onChangeRound,
    };
};

export default handlers;
