import Dialog from '../../../../../core/components/dialog';
import { DialogData } from '../../context/types';
import EditDialog from './edit-dialog';
import style from './style.module.scss';

const dialogData: DialogData = {
    id: 'url-input-with-minisites-dialog',
    component: Dialog,
    className: style.dialog,
    formComponent: EditDialog,
};

export default dialogData;
