import { Form } from '../../../../../core/components/form/context/types';
import ViewTypes from '../../../../../core/components/form/ViewTypes';
import content from './content';
import handlers from './handlers';

const wordGameTab: Form = {
    id: 'wordGameTab',
    type: ViewTypes.Form,
    fields: {
        playedGamesNumber: '',
        numberOfPlayers: '',
        numberOfActiveUsers: '',
        numberOfCompletedWords: '',
        topUsers: [],
        topUsersTableSort: [{ columnId: 'successAt', direction: 'asc' }],
        topUsersTitle: 'שחקנים מובילים ',
    },
    content,
    handlers,
};

export default wordGameTab;
