import React, { useReducer, useRef } from 'react';
import { useHistory } from 'react-router-dom';
import {
    ContextObservableCallbacks,
    createObserverHook,
    useObservableCallbacksCreator,
} from '../utils/store';
import account from './account';
import app from './app';
import loginPage from './loginPage';
import notifications from './notifications';
import { initStore, StoreInstance } from './types';

interface StandardTypes {
    routerHistory: {
        dispatch: {
            replace: (path: string) => void;
            push: (path: string) => void;
            goBack: () => void;
        };
    };
}

const globalStoreDefinition = {
    account,
    app,
    notifications,
    loginPage,
};

type GlobalStoreDefinition = typeof globalStoreDefinition;

export type Observable = StoreInstance<GlobalStoreDefinition> & StandardTypes;

const GlobalStoreContext = React.createContext<
    ContextObservableCallbacks<Observable>
>(undefined as any);
GlobalStoreContext.displayName = 'GlobalStore';

export const useGlobalStoreObserver =
    createObserverHook<Observable>(GlobalStoreContext);

const reducer = (
    state: Observable,
    callback: (state: Observable) => Observable
): Observable => {
    return callback(state);
};

export const GlobalStoreProvider = React.memo((props: any) => {
    const refDispatch = useRef<any>();
    const refLastStore = useRef<Observable>();
    const refHistory = useRef<any>();

    const history = useHistory();
    refHistory.current = history;

    const [state, dispatch] = useReducer(reducer, undefined, () =>
        initStore(
            globalStoreDefinition,
            refDispatch,
            refLastStore as any,
            refHistory
        )
    );

    refDispatch.current = dispatch;
    refLastStore.current = state;

    const callbacks = useObservableCallbacksCreator(state);

    return (
        <GlobalStoreContext.Provider value={callbacks}>
            {props.children}
        </GlobalStoreContext.Provider>
    );
});
