import React from 'react';
import SearchInput from '../../search-input';
import PropsPageHeaderItem from './props';
import style from './style.module.scss';

const PageHeaderItem = ({
    type,
    element,
    onSearch,
    value,
}: PropsPageHeaderItem) => {
    switch (type) {
        case 'search':
            return <SearchInput onSearch={onSearch} value={value} />;

        case 'element':
            return <> {element}</>;

        case 'divider':
            return <div className={style.divider} />;

        case 'spacer':
            return <div className={style.spacer} />;

        default:
            throw new Error(`Item isn't implemented: ${type}`);
    }
};

export default React.memo(PageHeaderItem);
