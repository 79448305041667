export default interface PropsHeader {
    showButtons?: boolean;
    isInEditMode?: boolean;
    title: string;
    total: number;
    onToggleEditMode: () => void;
    onUndo: () => void;
    onSave: () => void;
    updatedAt?: string;
}

export const defaultProps: Partial<PropsHeader> = {
    isInEditMode: true,
    showButtons: false,
};
