import React from 'react';
import { getHandlerWithContext, getValue } from '../../../utils';
import propsWithDefaultValues from '../../../utils/propsWithDefaultValues';
import NativeUploadView from '../../native/upload-view';
import { FileData } from '../../native/upload-view/props';
import { useAppContextObserver } from '../context';
import PropsUploadInput, { defaultProps } from './props';

const UploadInput = (props: PropsUploadInput) => {
    props = propsWithDefaultValues(props, defaultProps);
    const [{ isHidden, isDisabled }, getContext] = useAppContextObserver(
        (context) => {
            return {
                isHidden: getValue(props.isHidden, context),
                isDisabled: getValue(props.isDisabled, context),
            };
        }
    );
    const { onSelectFile } = props;
    const {
        id,
        title,
        fileType,
        maxSizeKB,
        isLoading,
        isSelected,
        onDelete,
        extensionsWithDot,
    } = props;

    if (isHidden) {
        return null;
    }

    const handleSelectFile = (files: FileData[]) => {
        if (!props.onSelectFile) {
            return;
        }

        const onSelectFile = getHandlerWithContext(
            props.onSelectFile,
            getContext(),
            false
        );
        onSelectFile!.call(getContext(), files);
    };

    return (
        <NativeUploadView
            id={id}
            title={title}
            type={fileType!}
            onSelected={handleSelectFile}
            maxSizeKB={maxSizeKB}
            isLoading={isLoading}
            isSelected={isSelected}
            onDelete={onDelete}
            isDisabled={isDisabled}
            extensionsWithDot={extensionsWithDot}
        />
    );
};

export default React.memo(UploadInput);
