import React, { useEffect, useState } from 'react';
import { ReactComponent as DeleteIcon } from '../../../../../../core/assets/icons/remove.svg';
import Input from '../../../../../../core/components/native/input';
import SelectInput from '../../../../../../core/components/native/select-input';
import PropsEditDialog from './props';
import style from './style.module.scss';

const EditDialog = ({ params }: PropsEditDialog) => {
    const { onClose, onSave, url, paramsOptions } = params!;
    const [urlParams, setUrlParams] = useState<
        { key: string; value: string }[]
    >([]);
    const [options, setOptions] = useState<{ value: string; label: string }[]>(
        []
    );

    useEffect(() => {
        const [, queryParams] = (url || '').split('?');
        const searchParams = new URLSearchParams(queryParams);
        const urlParams: { key: string; value: string }[] = [];

        for (const [key, value] of searchParams as any) {
            urlParams.push({
                key,
                value,
            });
        }
        setUrlParams(urlParams);
    }, [url]);

    const handleSave = () => {
        const [firstPart] = (url || '').split('?');
        const newParams = new URLSearchParams();

        urlParams.forEach(({ key, value }) => {
            if (!key) {
                return;
            }

            newParams.append(key, value);
        });

        const newUrl = firstPart + '?' + newParams.toString();
        onSave(newUrl);
    };

    const handleAdd = () => {
        setUrlParams((prev) => [...prev, { key: '', value: '' }]);
    };

    const handleDeleteRow = (deletedIndex: number) => () => {
        setUrlParams((prev) =>
            prev.filter((item, index) => index !== deletedIndex)
        );
    };

    useEffect(() => {
        const newOptions = (paramsOptions || []).map((item) => ({
            value: item.key,
            label: item.key,
        }));

        setOptions(newOptions);
    }, [paramsOptions]);

    const _handleChangeKey =
        (index: number) =>
        ({ value, __isNew__ }: any) => {
            if (__isNew__) {
                setOptions([...options, { value, label: value }]);
            }

            setUrlParams((prev: any[]) => {
                return prev.map((item, itemIndex) => {
                    if (itemIndex === index) {
                        const result = {
                            ...item,
                            key: value,
                        };
                        const defaultValue = (paramsOptions || []).find(
                            (item) => item.key === value
                        )?.value;
                        if (defaultValue) {
                            result.value = defaultValue;
                        }
                        return result;
                    }

                    return item;
                });
            });
        };

    return (
        <div className={style.editDialog}>
            <h3>Edit params</h3>
            <div className={style.content}>
                <div
                    className={style.items}
                    data-no-items={urlParams.length === 0}
                >
                    {urlParams.map((item, index) => {
                        return (
                            <div key={index} className={style.row}>
                                <div className={style.keyInputContainer}>
                                    <SelectInput
                                        label="Key"
                                        value={item.key}
                                        onChange={_handleChangeKey(index)}
                                        isCreatable={true}
                                        options={options}
                                    />
                                </div>
                                <Input
                                    id="value-input"
                                    label="Value"
                                    value={item.value}
                                    onChange={(e: any) => {
                                        const value = e.target.value;
                                        setUrlParams((prev) =>
                                            prev.map((item, itemIndex) => {
                                                if (itemIndex === index) {
                                                    return {
                                                        ...item,
                                                        value,
                                                    };
                                                }

                                                return item;
                                            })
                                        );
                                    }}
                                />
                                <DeleteIcon
                                    onClick={handleDeleteRow(index)}
                                    className={style.deleteButton}
                                />
                            </div>
                        );
                    })}
                    <div className={style.addButtonContainer}>
                        {' '}
                        <button onClick={handleAdd}>Add a parameter</button>
                    </div>
                </div>
            </div>
            <div className={style.footer}>
                <button className={style.cancelButton} onClick={onClose}>
                    Cancel
                </button>
                <button className={style.applyButton} onClick={handleSave}>
                    Apply
                </button>
            </div>
        </div>
    );
};

export default EditDialog;
