import Form from '../../components/form';
import ItemForm from '../../components/item-form';
import { PageConfig } from '../types';
import config from './endpoint-config-editor';
import landingItem from './landing-item';
import landingsList from './landings-list';
import siteConfigEditor from './site-config-editor';
import ledgerProduct from './site-config-editor/products/product-page';

const pages: PageConfig[] = [
    {
        id: 'endpointConfigEditor',
        type: 'page',
        access: 'private',
        title: 'Endpoint Config Editor',
        showInSidebar: false,
        props: config,
        exactPath: true,
        path: '/:siteId/:endpointId/endpoint-config-editor/:activeTabId',
    },
    {
        id: 'siteConfigEditor',
        type: 'page',
        access: 'private',
        props: siteConfigEditor,
        showInSidebar: false,
        title: 'Site Config Editor',
        path: '/:siteId/site-config-editor/:activeTabId',
    },
    {
        id: 'ledgerProduct',
        type: 'page',
        access: 'private',
        props: ledgerProduct,
        component: ItemForm,
        showInSidebar: false,
        title: 'Ledger Product',
        path: '/:siteId/ledger-product/:objectId/:tabId',
    },
    {
        id: 'ledgerProductNew',
        type: 'page',
        access: 'private',
        props: ledgerProduct,
        component: ItemForm,
        showInSidebar: false,
        title: 'Create a ledger product',
        path: '/:siteId/ledger-product',
    },
    {
        id: 'landingsList',
        type: 'page',
        access: 'private',
        props: landingsList,
        showInSidebar: true,
        title: 'Landings',
        exactPath: true,
        path: '/landings',
        sidebarMatchExact: false,
        sidebarMatch: ['/landings/', '/landings/item/:itemId/:tabId'],
        sidebarTo: '/landings/',
        component: Form,
        isAllowed: ['admin', 'landings_admin'],
    },
    {
        id: 'landingsItem',
        type: 'page',
        access: 'private',
        props: landingItem,
        showInSidebar: false,
        title: 'Landings Item',
        component: Form,
        path: '/:endpointId(landings)/item/:objectId/:tabId',
        isAllowed: ['admin', 'landings_admin'],
    },
    {
        id: 'landingsItem',
        type: 'page',
        access: 'private',
        showInSidebar: false,
        title: 'Landings Item',
        component: Form,
        props: landingItem,
        path: '/:endpointId(landings)/item',
        isAllowed: ['admin', 'landings_admin'],
    },
];

export default pages;
