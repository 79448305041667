import { Line } from 'rc-progress';
import React from 'react';
import { SortableHandle } from 'react-sortable-hoc';
import propsWithDefaultValues from '../../../utils/propsWithDefaultValues';
import useComponentState from './hooks';
import PropsMediaPhotoItem, { defaultProps } from './props';
import style from './style.module.scss';

const DragHandle = SortableHandle(() => (
    <span className={style.handle}>
        <i className="ion ion-ios-reorder" />
    </span>
));

const VIDEO_EXTENSIONS = [
    'mp4',
    'm4v',
    'mkv',
    '3gp',
    'webm',
    'mov',
    'avi',
    'wmv',
    'mpg',
];

const lineStyle = { width: 74 };

const MediaPhotoItem = (props: PropsMediaPhotoItem) => {
    props = propsWithDefaultValues(props, defaultProps);
    const {
        isInEditMode,
        isUploading,
        progress,
        height,
        width,
        onClick,
        url,
        isUploadError,
        isDraggable,
        onOpen,
        onDelete,
        mediaType,
        onDownload,
    } = useComponentState(props);

    return (
        <div className={style.mediaPhotoItem}>
            <div
                className={isUploading ? style.itemUploading : style.item}
                style={{ height, width }}
            >
                {(!mediaType ||
                    mediaType === 'image' ||
                    mediaType === 'photo') && (
                    <img
                        className={style.photo}
                        src={url}
                        onClick={isInEditMode ? () => {} : onOpen}
                        alt=""
                    />
                )}
                {mediaType === 'video' && (
                    <div className={style.videoContent}>
                        <video controls>
                            {VIDEO_EXTENSIONS.map((extension, index) => (
                                <source
                                    key={index}
                                    src={url}
                                    type={`video/${extension}`}
                                />
                            ))}
                            Your browser does not support the video tag.
                        </video>
                    </div>
                )}
                {isUploading && (
                    <div className={style.uploading}>
                        <span>Uploading</span>
                        <Line
                            percent={progress}
                            strokeWidth={5}
                            strokeColor="#43bf60"
                            trailColor="#ececec"
                            trailWidth={5}
                            style={lineStyle}
                        />
                    </div>
                )}
                {isUploadError && (
                    <div className={style.uploading}>
                        <span>Upload error</span>
                        <Line
                            percent={progress}
                            strokeWidth={5}
                            strokeColor="#f46f50"
                            trailColor="#ececec"
                            trailWidth={5}
                            style={lineStyle}
                        />
                    </div>
                )}
                {isInEditMode && !isUploading && (
                    <div className={style.overlay}>
                        {isDraggable && <DragHandle />}
                        <span className={style.showButton} onClick={onOpen}>
                            <i className="ion ion-ios-eye" />
                        </span>
                        {mediaType === 'video' && (
                            <span
                                className={style.downloadButton}
                                onClick={onDownload}
                            >
                                <i className="ion ion-ios-cloud-download" />
                            </span>
                        )}
                        {onDelete && (
                            <div
                                className={style.deleteButton}
                                onClick={onDelete}
                            >
                                Delete
                            </div>
                        )}
                    </div>
                )}
            </div>
        </div>
    );
};

export default React.memo(MediaPhotoItem);
