import lodash from 'lodash';
import DeepProxy from '../../../lib/DeepProxy';
import { ChangedContextEnvironment, updateRawAppContext } from './Context';
import { AppContext, RawAppContext } from './types';

const getFullPath = (path: string[], key: string): string => {
    if (!path || (Array.isArray(path) && path.length === 0)) {
        return key;
    }

    if (!key) {
        return path.join('.');
    }

    return `${path.join('.')}.${key}`;
};

export default function Proxy(context: RawAppContext): AppContext {
    const contextHandlers = {
        set(
            this: {
                path: string[];
                proxiedContext: AppContext;
                handlers: any;
                nest: (value: any) => any;
            },
            target: any,
            key: any,
            value: any
        ) {
            const fullPath = getFullPath(this.path, key);
            if (key === '___rawContext___') {
                return false;
            }

            if (!fullPath.startsWith('form.')) {
                return false;
            }

            const [, ...restPathItems] = this.path ?? [];
            const restPath = getFullPath(restPathItems, key);

            this.proxiedContext.form.setDataSourceValue(restPath, value);

            return true;
        },

        get(
            this: {
                path: string[];
                proxiedContext: AppContext;
                handlers: any;
                nest: (value: any) => any;
            },
            target: any,
            key: any,
            receiver: any
        ) {
            if (typeof key === 'symbol') {
                return target[key];
            }

            const fullPath = getFullPath(this.path, key);

            if (key === '___isProxy___') {
                return true;
            }

            if (key === '___updateContextProps___') {
                return (
                    changedEnvironment: ChangedContextEnvironment,
                    options: any
                ) => {
                    // Update context

                    updateRawAppContext(context, changedEnvironment, options);
                };
            }

            if (key === '___rawContext___') {
                return context;
            }

            if (fullPath.startsWith('form.handlers')) {
                return this.handlers;
            }

            if (
                this.path.length === 3 &&
                this.path[0] === 'form' &&
                this.path[1] === 'views' &&
                key === 'weakCallbacks'
            ) {
                const viewId = this.path[2];
                return (context.form.__callbacks__ || {})[viewId];
            }

            const val = Reflect.get(target, key, receiver);
            if (key === 'appConfig' || key === 'constructor') {
                return val;
            }

            if (!val || Array.isArray(val)) {
                return val;
            }

            try {
                if (val.___isProxy___) {
                    return val;
                }
            } catch (e) {}

            if (lodash.isPlainObject(val)) {
                return this.nest(val);
            }

            if (lodash.isFunction(val)) {
                // val.bind(this.proxiedContext);
                return this.nest(val);
            }

            return val;
        },
    };

    const contextParams: {
        proxiedContext: AppContext;
        handlers: any;
    } = {} as any;
    const proxiedContext = DeepProxy(
        context,
        contextHandlers,
        undefined,
        contextParams
    );

    contextParams.proxiedContext = proxiedContext;
    contextParams.handlers = context.form.handlers
        ? context.form.handlers.call(proxiedContext)
        : {};
    return proxiedContext;
}
