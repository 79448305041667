import { ControlledEditor } from '@monaco-editor/react';
import React from 'react';
import { AutoSizer } from 'react-virtualized';
import propsWithDefaultValues from '../../../utils/propsWithDefaultValues';
import useCodeEditorState from './hooks';
import PropsCodeEditor, { defaultProps } from './props';
import style from './style.module.scss';

const CodeEditor = (props: PropsCodeEditor) => {
    props = propsWithDefaultValues(props, defaultProps);
    const {
        isHidden,
        isExpanded,
        isDisabled,
        onClickCommand,
        onToggleExpand,
        value,
        onChangeValue,
    } = useCodeEditorState(props);

    const { title, language, commands } = props;

    if (isHidden) {
        return null;
    }

    return (
        <div
            className={isExpanded ? style.codeEditorExpanded : style.codeEditor}
        >
            {isExpanded && <div className={style.title}>{title}</div>}
            <div
                className={style.commands}
                data-is-dark={typeof value == 'object' ? false : true}
            >
                {title && <div className={style.title}>{title}</div>}
                {commands &&
                    commands.map((command, index) => (
                        <button key={index} onClick={onClickCommand(command)}>
                            {command.title}
                        </button>
                    ))}
            </div>
            <div
                className={style.aceEditorContainer}
                data-is-disabled={!!isDisabled}
                data-is-dark={typeof value == 'object' ? false : true}
            >
                <AutoSizer>
                    {({ width, height }) => (
                        <ControlledEditor
                            key={`${width} ${height}`}
                            theme={
                                typeof value == 'object'
                                    ? 'vs-light'
                                    : 'vs-dark'
                            }
                            value={
                                typeof value == 'object'
                                    ? JSON.stringify(value, null, '\t')
                                    : value
                            }
                            language={
                                typeof value == 'object' ? 'json' : language
                            }
                            options={{
                                fontSize: 14,
                                automaticLayout: true,
                                minimap: false,
                                readOnly: isDisabled,
                            }}
                            onChange={onChangeValue}
                            width={width}
                            height={height}
                        />
                    )}
                </AutoSizer>
                {isExpanded && (
                    <button
                        className={style.expandButton}
                        onClick={onToggleExpand}
                    >
                        <i className="ion ion-ios-contract" />
                        <span>Shrink</span>
                    </button>
                )}
                {!isExpanded && (
                    <button
                        className={style.expandButton}
                        onClick={onToggleExpand}
                    >
                        <i className="ion ion-ios-expand" />
                        <span>Full Screen</span>
                    </button>
                )}
            </div>
        </div>
    );
};

export default React.memo(CodeEditor);
