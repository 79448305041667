import { VStack } from '../../../../components/form/context/types';
import ViewTypes from '../../../../components/form/ViewTypes';
import style from './style.module.scss';

const settingsRootGroup: VStack = {
    id: 'settingsRootGroup',
    type: ViewTypes.VStack,
    style: { padding: 10, flex: 1 },
    items: [
        {
            id: 'settingsFieldsGroup',
            type: ViewTypes.VStack,
            style: { padding: 10, flex: 1 },
            items: [
                {
                    id: 'siteId',
                    type: ViewTypes.TextInput,
                    label: 'Site ID',
                    ltr: true,
                    dataSource: 'siteData.siteId',
                },
                {
                    isHidden: true,
                    id: 'endpointId',
                    type: ViewTypes.TextInput,
                    label: 'Endpoint ID',
                    ltr: true,
                    dataSource: 'endpointId',
                },
                {
                    id: 'usersRegister',
                    label: 'Users can register',
                    type: ViewTypes.BooleanInput,
                    dataSource: 'siteData.isUsersCanRegister',
                },
                {
                    id: 'registrationTypes',
                    type: ViewTypes.EnumInput,
                    label: 'Registration type',
                    dataSource: 'siteData.registrationTypes',
                    isMultiple: true,
                    onChangeValue: 'onChangeRegistrationTypes',
                    values: [
                        {
                            value: 'jwt',
                            label: 'jwt',
                        },
                    ],
                },
                {
                    isHidden: true,
                    id: 'jwtTokenGroup',
                    type: ViewTypes.HStack,
                    style: { marginBottom: 10 },
                    items: [
                        {
                            id: 'JWTSecret',
                            label: 'JWT Secret',
                            type: ViewTypes.TextInput,
                            ltr: true,
                            dataSource: 'siteData.JWTSecret',
                        },
                        {
                            id: 'renewJwtTokenButton',
                            type: ViewTypes.Button,
                            role: 'notActive',
                            text: 'Generate',
                            style: { marginRight: 15 },
                            onClick: 'onGenerateNewJwtToken',
                        },
                    ],
                },
            ],
        },
        {
            id: 'settingsFooter',
            type: ViewTypes.HStack,
            className: style.footer,
            items: [
                {
                    id: 'saveButton',
                    type: ViewTypes.Button,
                    role: 'active',
                    text: 'Save',
                    onClick: () => {},
                },
                {
                    id: 'cancelButton',
                    type: ViewTypes.Button,
                    role: 'notActive',
                    text: 'Cancel',
                    onClick: 'onGoBack',
                },
                {
                    id: 'spacer1',
                    type: ViewTypes.HStack,
                    style: { flex: 1 },
                    items: [],
                },
                {
                    id: 'addEndpointButton',
                    type: ViewTypes.Button,
                    role: 'notActive',
                    text: 'Add Endpoint',
                    onClick: 'onAddEndpoint',
                },
                {
                    id: 'deleteButton',
                    type: ViewTypes.Button,
                    role: 'notActive',
                    text: 'Delete',
                    onClick: 'onDelete',
                },
            ],
        },
    ],
};

export default settingsRootGroup;
