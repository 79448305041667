import React from 'react';
import style from './style.module.scss';

interface PropsDialog {
    id: string;
    openedClass?: string;
    closedClass?: string;
    isOpened: boolean;
    onClickBackdrop?: () => void;
    title: string;
    contentClass?: string;
    children?: React.ReactNode;
}

export const defaultProps: Partial<PropsDialog> = {
    openedClass: style.dialogOpened,
    closedClass: style.dialogClosed,
    contentClass: style.content,
};

export default PropsDialog;
