import { CustomEndpointConfig } from '../../../../core/config-builder/types';

export function getUserName(profileData: any) {
    if (!profileData) {
        return 'NOT FOUND';
    }

    if (profileData.firstName) {
        if (!profileData.lastName) {
            return profileData.firstname;
        }

        return `${profileData.firstName} ${profileData.lastName}`;
    }

    if (profileData.email) {
        return profileData.email;
    }

    if (profileData.phone) {
        return profileData.phone;
    }

    return profileData.username;
}

const missingLocation: CustomEndpointConfig = {
    title: 'מיקומים חסרים',
    metaInfo: {
        properties: {
            userId: {
                type: 'string',
                format: 'reference',
                endpointId: 'user',
                idField: '_id',
                sortListByField: 'firstname',
                searchByField: ['email', 'firstName', 'lastName', 'phone'],
                representationDataSource: getUserName,
            },
        },
    },
};

export default missingLocation;
