import React from 'react';
import ButtonCellProps from './props';
import style from './style.module.scss';

const CellButton = ({ item, column }: ButtonCellProps) => {
    const handleClick = (e: any) => {
        e.stopPropagation();
        column.onClick(item);
    };

    return (
        <div className={style.buttonCell}>
            <button
                className={column.className ?? style.button}
                onClick={handleClick}
            >
                {column.text}
            </button>
        </div>
    );
};

export default React.memo(CellButton);
