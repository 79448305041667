export default {
    endpoints: {
        test: {
            forms: {
                list: {
                    contextMenu: [
                        // {
                        //     id: 'copy',
                        //     title: 'Copy',
                        //     onClick: function (row) {
                        //         const { siteId, endpointId } = this.form.params;
                        //         this.form.url.push({
                        //             path: `/${siteId}/${endpointId}/item`,
                        //             queryParams: {
                        //                 copyFrom: row.id,
                        //                 copyProperties: ['words1'],
                        //             },
                        //         });
                        //     },
                        // },
                    ],
                },
            },
        },
    },
};
