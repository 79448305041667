import { NotificationData } from '../../components/form/context/types';
import { Observable } from '../definition';

const actions = {
    pushNotification: (params: { data: NotificationData; id?: string }) => {
        return async (store: Observable) => {
            let { id, data } = params;
            if (!id) {
                id = String(Date.now());
            }

            store.notifications.dispatch.hideItem({ id });

            store.notifications.dispatch.pushItem({
                id,
                data: {
                    ...data,
                    id,
                },
            });

            if (data.lifetimeMs) {
                setTimeout(() => {
                    store.notifications.dispatch.hideNotification({ id: id! });
                }, data.lifetimeMs);
            }
        };
    },

    hideNotification: (data: { id: string | RegExp }) => {
        return async (store: Observable) => {
            store.notifications.dispatch.hideItem(data);
        };
    },
};

export default actions;
