import { escapeRegExp } from 'lodash';
import { toEditorState, toString } from 'rc-editor-mention';
import { useCallback, useMemo, useState } from 'react';
import { getValue } from '../../../utils/context';
import { useAppContextObserver } from '../context';
import PropsAutoCompleteInput from './props';
import translations from './translations';

const originSuggestions = translations.map((item) => item.translation).sort();

const getDisplayName = (variableName: string) => {
    const translationData = translations.find(
        (item) => item.value === variableName
    );
    if (!translationData) {
        return variableName;
    }

    return translationData.translation;
};

const getVariableName = (displayName: string): string | undefined => {
    let translationData = translations.find(
        (item) => item.translation === displayName
    );
    if (translationData) {
        return translationData.value;
    }

    translationData = translations.find((item) => item.value === displayName);
    if (translationData) {
        return translationData.value;
    }
};

const convertToHandlebars = (inputString: string): string => {
    if (!inputString) {
        return '';
    }

    const sortedSuggestions = originSuggestions.slice().sort().reverse();

    const regExString = sortedSuggestions
        .map((suggestion) => `\\#(${escapeRegExp(suggestion)})`)
        .join('|');

    const regex = new RegExp(`(${regExString})`, 'g');
    const matches = inputString.match(regex);
    let result = inputString;

    matches &&
        matches.forEach((matchedString: string) => {
            const displayName = matchedString.replace('#', '').trim();

            const variableName = getVariableName(displayName);
            if (!variableName) {
                return;
            }

            result = result.replaceAll(matchedString, `{{${variableName}}}`);
        });

    return result;
};

const convertFromHandlebarsToDisplayString = (inputString: string): string => {
    const regex = new RegExp('\\{\\{[\\w\\d\\.\\s]*\\}\\}', 'g');
    const matches = inputString.match(regex);
    let result = inputString;

    matches &&
        matches.forEach((matchedString: string) => {
            const variableName = matchedString
                .replace('{{', '')
                .replace('}}', '')
                .trim();

            result = result.replaceAll(
                matchedString,
                `#${getDisplayName(variableName)}`
            );
        });

    return result;
};

function useAutoCompleteInputState(props: PropsAutoCompleteInput) {
    const [{ value, isHidden, isDisabled, displayError }, getContext] =
        useAppContextObserver((c) => {
            let { value } = props;
            if (props.dataSource) {
                value = c.form.getDataSourceValue(props.dataSource);
            }

            return {
                value,
                isHidden: getValue(props.isHidden, c),
                displayError: getValue(props.displayError, c),
                isDisabled: getValue(props.isDisabled, c),
            };
        });
    const { dataSource } = props;
    const [suggestions, setSuggestions] = useState(() => originSuggestions);

    const initialValue = useMemo(() => {
        const displayString = convertFromHandlebarsToDisplayString(value ?? '');
        return toEditorState(displayString);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const [editorState, setEditorState] = useState(initialValue);

    const onChangeValue = useCallback(
        (editorState: any) => {
            setEditorState(editorState);

            const displayString = toString(editorState);
            const value = convertToHandlebars(displayString);

            if (dataSource) {
                getContext().form.setDataSourceValue(dataSource, value);
            }

            if (!props.onChangeValue) {
                return;
            }

            if (typeof props.onChangeValue === 'string') {
                (getContext().form.handlers as any)[props.onChangeValue](value);
                return;
            }

            props.onChangeValue.call(getContext(), value);
        },
        [getContext, dataSource, props.onChangeValue]
    );

    const onSearchChange = useCallback((value: string) => {
        if (!value) {
            setSuggestions(originSuggestions);
            return;
        }

        const searchValue = value.toLowerCase();
        const filtered = originSuggestions.filter(
            (suggestion) => suggestion.toLowerCase().indexOf(searchValue) !== -1
        );
        setSuggestions(filtered);
    }, []);

    return {
        onChangeValue,
        isHidden,
        displayError,
        isDisabled,
        value,
        suggestions,
        onSearchChange,
        editorState,
        initialValue,
    };
}

export default useAutoCompleteInputState;
