import escapeHtml from 'escape-html';
import isHtml from 'is-html';
import Papa from 'papaparse';
import React from 'react';
import {
    AppContext,
    FormFunctionGetHandlers,
} from '../../../components/form/context/types';
import {
    Column,
    ColumnReference,
    ColumnText,
} from '../../../components/form/form-table-types';
import { FileData } from '../../../components/native/upload-view/props';
import apiClient from '../../../requests/api';
import { sleep } from '../../../utils';
import Selection from '../../../utils/Selection';
import { mapRemoteEndpointMetaInfoToLocal } from '../../endpoint';
import { getListFormPropertyColumn } from '../../forms/list';
import defaultHandlers from '../defaultHandlers';

function getDelimiter(rawData: string): string | undefined {
    const lines = rawData.split('\n');
    if (lines.length > 0) {
        if (lines[0].includes(';')) {
            return ';';
        }

        if (lines[0].includes(',')) {
            return ',';
        }
    }

    return undefined;
}

const getMissedReferences = async (
    siteId: string,
    endpointId: string,
    values: string[]
) => {
    const items = await apiClient.getItemsByIdFromTable(
        siteId,
        endpointId,
        values
    );

    return values.filter((value) => !items[value]);
};

async function parseDataToJson(columns: any[], rawData: any) {
    const parseConfig = {
        skipEmptyLines: true,
        delimiter: getDelimiter(rawData),
    };

    const { data: parsedData } = Papa.parse(rawData, parseConfig);
    const headers: string[] = parsedData[0] as any;
    const items = parsedData.filter(
        (item: any, index: number) => index > 0 && item
    );

    const result = [];

    const errors: { error: string; column: any; value?: any }[] = [];
    columns.forEach((column) => {
        const isExist = headers.includes(column.id);

        if (column.isRequired && !isExist) {
            errors.push({
                error: 'ColumnNotExist',
                column,
            });
        }
    });

    const referencesToCheck: any = {};

    for (let rowIndex = 0; rowIndex < items.length; rowIndex++) {
        const item: any = items[rowIndex];
        const newRow: any = {};

        if (item.length === 0 || !item[0]) {
            continue;
        }

        for (let columnIndex = 0; columnIndex < headers.length; columnIndex++) {
            const columnId = headers[columnIndex];
            if (!columnId) {
                continue;
            }

            const columnData = columns.find((column) => column.id === columnId);
            let value = item[columnIndex];

            if (columnData.type === 'reference' && value) {
                if (!referencesToCheck[columnId]) {
                    referencesToCheck[columnId] = {
                        siteId: columnData.siteId,
                        endpointId: columnData.endpointId,
                        column: columnData,
                        values: [],
                    };
                }

                const isValueAdded =
                    referencesToCheck[columnId].values.includes(value);
                if (!isValueAdded) {
                    referencesToCheck[columnId].values.push(value);
                }
            }

            if (columnData.type === 'boolean') {
                if (String(value).toLowerCase() === 'true') {
                    value = true;
                } else {
                    value = false;
                }
            }

            // TODO: check if HTML and convert
            if (columnData.type === 'number') {
                value = !value ? 0 : Number(value);
            } else if (columnData.format === 'html') {
                if (value && !isHtml(value)) {
                    value = `<p>${escapeHtml(value)}</p>`;
                }
            }

            newRow[columnId] = value;
        }

        newRow.id = rowIndex;
        result.push(newRow);
    }

    const ids = Object.getOwnPropertyNames(referencesToCheck);
    for (let i = 0; i < ids.length; i++) {
        const id = ids[i];
        const { siteId, endpointId, values, column } = referencesToCheck[id];

        const missedValues = await getMissedReferences(
            siteId,
            endpointId,
            values
        );

        missedValues.forEach((value) => {
            errors.push({
                error: 'ReferenceDoesntExist',
                column,
                value,
            });
        });
    }

    return {
        items: result,
        headers,
        errors,
    };
}

const rowToObject = (rowData: any) => {
    const result = {
        ...rowData,
    };

    delete result.METHOD;
    delete result.ID;

    return result;
};

const handlers: FormFunctionGetHandlers = function (this: AppContext) {
    const _setupTableColumns = () => {
        const { siteId, endpointId } = this.form.params;
        const remoteSiteSchema = this.getSiteRemoteSchema(siteId);
        const remoteMetaInfo = this.getEndpointRemoteSchema(siteId, endpointId);

        const endpointsIds = Object.getOwnPropertyNames(
            remoteSiteSchema!.endpoints
        );
        const localEndpointMetaInfo = mapRemoteEndpointMetaInfoToLocal(
            siteId,
            endpointId,
            remoteMetaInfo!,
            undefined,
            endpointsIds
        );

        const defaultColumns: Column[] = [
            {
                id: 'METHOD',
                fieldName: 'METHOD',
                title: 'METHOD',
                isSortable: false,
                type: 'text',
            },
            {
                id: 'ID',
                fieldName: 'ID',
                title: 'ID',
                isSortable: false,
                type: 'text',
            },
        ];

        const columns = Object.getOwnPropertyNames(
            localEndpointMetaInfo.properties
        ).reduce(
            (acc: Column[], propertyId: string, index: number): Column[] => {
                if (propertyId === 'files') {
                    return acc;
                }

                const propertyData =
                    localEndpointMetaInfo.properties[propertyId];
                const column = getListFormPropertyColumn(
                    siteId,
                    endpointId,
                    propertyId,
                    propertyData
                );

                if (column.type === 'reference') {
                    const columnReference = column as ColumnReference;
                    columnReference.style = function ({
                        value,
                        referenceValue,
                    }: any) {
                        if (value && !referenceValue) {
                            return {
                                color: 'red',
                                fontWeight: 'bold',
                            };
                        }

                        return {};
                    };
                }

                if (column.type === 'text') {
                    const columnText = column as ColumnText;
                    columnText.CellComponent = ({
                        column,
                        item,
                        value,
                    }: any) => {
                        const method =
                            item && item.METHOD && item.METHOD.toLowerCase();
                        if (method === 'create' && !value) {
                            return <div>{column.defaultValue}</div>;
                        }

                        return <div>{value}</div>;
                    };
                }

                // column.defaultValue = propertyData.defaultValue;
                acc.push(column);
                return acc;
            },
            defaultColumns
        );

        this.form.columns = columns;
        this.form.views.table.columns = columns;
    };

    const onBeforeOpen = async () => {
        _setupTableColumns();

        this.form.views.rootGroup.isLoading = false;
        this.form.errors = [];
        this.form.items = [];
        this.form.views.table.dataSource = 'items';
        this.form.selection = null;

        this.form.views.uploadView.isHidden = false;
    };

    const onSelectFile = async (files: FileData[]) => {
        const data = files[0].data;
        const { columns } = this.form;

        this.form.views.rootGroup.isLoading = true;
        let parsingResult;

        this.form.notify(
            {
                text: 'Opening the file...',
            },
            'IMPORT_PARSING'
        );

        // try {
        parsingResult = await parseDataToJson(columns, data);
        // } catch (e) {
        //     this.form.hideNotification('IMPORT_PARSING');
        //     this.form.notify({
        //         text: "Can't parse the file",
        //         type: 'error',
        //     });
        //     console.log('Parse file error: ', e);
        //     debugger;
        //     return;
        // }

        this.form.hideNotification('IMPORT_PARSING');
        this.form.notify({
            text: 'The file is opened',
            lifetimeMs: 2000,
        });

        const { items, errors } = parsingResult;

        this.form.views.rootGroup.isLoading = false;

        this.form.views.errorsGroup.isHidden = errors.length === 0;
        this.form.views.errorsText.value = `Found ${errors.length} errors`;

        this.form.errors = errors;
        this.form.items = items;
        this.form.views.uploadView.isHidden = true;
        this.form.views.tableContainer.isHidden = false;
        this.form.selection = null;
    };

    const onApply = async () => {
        const { errors, items } = this.form;
        const { siteId, endpointId } = this.form.params;

        const selection = Selection.unserealizeFromObject(this.form.selection);

        this.form.views.rootGroup.isLoading = true;
        this.form.progressText = `Importing 0/${items.length}`;

        if (
            errors.filter(
                (item: any) => !['ReferenceDoesntExist'].includes(item.error)
            ).length > 0
        ) {
            alert(JSON.stringify(errors));
            this.form.views.rootGroup.isLoading = false;
            return;
        }

        const selectedRows = items.filter((item: any, index: any) =>
            selection.isItemSelected(index)
        );

        let errorsCount = 0;

        for (let rowIndex = 0; rowIndex < selectedRows.length; rowIndex++) {
            this.form.views.rootGroup.progressText = `Importing ${
                rowIndex + 1
            }/${selectedRows.length}`;
            const row = selectedRows[rowIndex];
            const { ID: objectId } = row;
            const method = row.METHOD.toLowerCase();

            const objectData = rowToObject(row);

            if (method === 'create') {
                try {
                    await apiClient.createTableItem(
                        siteId,
                        endpointId,
                        objectData
                    );
                } catch (e) {
                    errorsCount++;
                    console.error(`Error: create table item: ${objectId}`, {
                        error: e,
                        objectId,
                        objectData,
                    });
                    this.form.notify({
                        type: 'error',
                        text: `Can't create the item: ${objectId}`,
                    });
                    break;
                }
                continue;
            }

            if (method === 'update') {
                try {
                    await apiClient.updateTableItem(
                        siteId,
                        endpointId,
                        objectId,
                        objectData
                    );
                } catch (e) {
                    errorsCount++;
                    console.error(
                        `Can't update the object: ${objectId}`,
                        objectData
                    );
                    if (e.message === '404') {
                        this.form.notify({
                            type: 'error',
                            text: `Can't update not existing object: ${objectId}`,
                        });
                    } else {
                        this.form.notify({
                            type: 'error',
                            text: `Can't update the object: ${objectId}`,
                        });
                    }
                }
                continue;
            }

            if (method === 'delete') {
                try {
                    await apiClient.deleteTableItem(
                        siteId,
                        endpointId,
                        objectId
                    );
                } catch (e) {
                    if (e.message !== '404') {
                        errorsCount++;
                        console.error(`Can't delete the object: ${objectId}`);
                        this.form.notify({
                            type: 'error',
                            text: `Can't delete the object: ${objectId}`,
                        });
                    }
                }
                continue;
            }

            throw new Error(`No such method: ${method}`);
        }

        if (errorsCount > 0) {
            this.form.views.rootGroup.isLoading = false;
            return;
        }

        await sleep(2000);
        this.form.views.rootGroup.isLoading = false;
        this.form.closeDialog();

        this.form.notify({ text: 'Import success!', lifetimeMs: 2000 });
        if (window._refreshList) {
            window._refreshList();
        } else {
            window.location.reload();
        }
    };

    const onOpenErrors = () => {
        alert(JSON.stringify(this.form.errors));
    };

    return {
        ...defaultHandlers.call(this),
        onBeforeOpen,
        onApply,
        onSelectFile,
        onOpenErrors,
    };
};

export default handlers;
