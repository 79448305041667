import { CSSProperties } from 'react';
import { AppContext } from '../../../../core/components/form/context/types';
import { CustomEndpointConfig } from '../../../../core/config-builder/types';

const orders: CustomEndpointConfig = {
    title: 'הזמנות',
    metaInfo: {
        propertiesOrder: [
            'orderNumber',
            'productsId',
            'orderStatus',
            'firstName',
            'lastName',
            'phone',
            'email',
            'billingAddress',
            'shippingFirstName',
            'shippingLastName',
            'shippingPhone',
            'shippingAddress',
            'shippingSameAsBilling',
            'updatedAt',
            'createdAt',
        ],
        properties: {
            orderNumber: {
                name: 'מספר הזמנה',
            },
            firstName: {
                name: 'שם פרטי',
            },
            lastName: {
                name: 'שם משפחה',
            },
            phone: {
                name: 'טלפון',
            },
            billingAddress: {
                name: 'כתובת',
            },
            shippingAddress: {
                name: 'כתובת - משלוח',
            },
            shippingFirstName: {
                name: 'שם פרטי - משלוח',
            },
            shippingLastName: {
                name: 'שם משפחה - משלוח',
            },
            shippingPhone: {
                name: 'טלפון - משלוח',
            },
            shippingSameAsBilling: {
                name: 'כתובת משלוח זהה למזמין',
            },
            productsId: {
                name: 'שם המוצר',
            },
            orderStatus: {
                name: 'סטטוס הזמנה',
            },
            updatedAt: {
                name: 'תאריך עדכון',
            },
            createdAt: {
                name: 'תאריך רכישה',
            },
            userId: {
                isHidden: true,
            },
        },
    },
    forms: {
        list: {
            defaultUrlQueryParams: {
                sort: [
                    {
                        columnId: 'createdAt',
                        direction: 'desc',
                    },
                ],
                filter: {
                    orderStatus: {
                        include: [
                            'Waiting for approval',
                            'Sent to customer',
                            'Waiting for delivery',
                            'Contact info error',
                            'Returned to sender',
                        ],
                    },
                },
            },
            columnsOrder: [
                'orderNumber',
                'lastName',
                'firstName',
                'productsId',
                'orderStatus',
                'updatedAt',
                'createdAt',
            ],
            columns: {
                orderNumber: {
                    title: 'מס הזמנה',
                },
                firstName: {
                    title: 'שם פרטי',
                },
                lastName: {
                    title: 'שם משפחה',
                },
                phone: {
                    isHidden: true,
                },
                shippingAddress: {
                    isHidden: true,
                },
                billingAddress: {
                    isHidden: true,
                },
                shippingFirstName: {
                    isHidden: true,
                },
                shippingLastName: {
                    isHidden: true,
                },
                shippingPhone: {
                    isHidden: true,
                },
                shippingSameAsBilling: {
                    isHidden: true,
                },
                email: {
                    isHidden: true,
                },
                userId: {
                    isHidden: true,
                },
                orderStatus: {
                    title: 'סטטוס הזמנה',
                    updateInPlace: true,
                    style(
                        this: AppContext,
                        { value, column }: any
                    ): CSSProperties {
                        switch (value) {
                            case 'Waiting for payment': {
                                return { color: 'orange' };
                            }
                            case 'Waiting for approval': {
                                return { color: '#43bf60' };
                            }
                            case 'Sent to customer':
                            case 'Waiting for delivery': {
                                return { color: '#3333FF' };
                            }
                            case 'Order completed': {
                                return { color: 'unset' };
                            }
                            default: {
                                return { color: '#FF6666' };
                            }
                        }
                    },
                },
                productsId: {
                    title: 'שם המוצר',
                },
                updatedAt: {
                    title: 'תאריך עדכון',
                },
                createdAt: {
                    title: 'תאריך רכישה',
                },
            },
        },
        item: {
            views: {
                shippingFirstName: {
                    isHidden(this: AppContext) {
                        return this.form.object.shippingSameAsBilling;
                    },
                },
                shippingLastName: {
                    isHidden(this: AppContext) {
                        return this.form.object.shippingSameAsBilling;
                    },
                },
                shippingPhone: {
                    isHidden(this: AppContext) {
                        return this.form.object.shippingSameAsBilling;
                    },
                },
                shippingAddress: {
                    isHidden(this: AppContext) {
                        return this.form.object.shippingSameAsBilling;
                    },
                },
            },
        },
    },
};

export default orders;
