import Form from '../../../components/form';
import { AppContext } from '../../../components/form/context/types';
import ViewTypes from '../../../components/form/ViewTypes';
import EditorForm from './editor';
import InfoForm from './info';

export default {
    id: 'landing-item-form',
    component: Form,
    type: 'Form',
    title: 'Landing',
    commands: [
        {
            id: 'backButton',
            text: 'Back',
            onClick: 'onGoBack',
            isHidden: true,
        },
    ],
    content: {
        id: 'rootGroup',
        type: ViewTypes.VStack,
        items: [
            {
                id: 'content',
                type: ViewTypes.VStack,
                items: [
                    {
                        id: 'tabs',
                        type: ViewTypes.TabsGroup,
                        activeTabIdSource: 'url.params.tabId.replace',
                        defaultTabId: 'info',
                        tabs: [
                            {
                                id: 'info',
                                title: 'Info',
                                type: ViewTypes.TabPage,
                                isHidden: false,
                                content: InfoForm,
                            },
                            {
                                id: 'editor',
                                title: 'Editor',
                                type: ViewTypes.TabPage,
                                isHidden(this: AppContext) {
                                    return !this.form.url.params.objectId;
                                },
                                content: EditorForm,
                            },
                            {
                                id: 'media',
                                title: 'Media',
                                isHidden: true,
                                type: ViewTypes.TabPage,
                                content: {
                                    id: 'mediaTabContentGroup',
                                    type: ViewTypes.VStack,
                                    items: [],
                                },
                            },
                        ],
                    },
                ],
            },
        ],
    },
};
