import * as _ from 'lodash';
import endpointConfigIcon from '../../../assets/icons/settings-outline.svg';
import deleteIcon from '../../../assets/icons/trash-outline.svg';
import {
    AppContext,
    HStack,
    MediaField,
    View,
    VStack,
} from '../../../components/form/context/types';
import ViewTypes from '../../../components/form/ViewTypes';
import ItemForm from '../../../components/item-form';
import { toTitleCase } from '../../../utils';
import {
    LocalMetaInfoEndpoint,
    LocalMetaInfoProperty,
    LocalMetaInfoPropertyEnum,
    LocalMetaInfoPropertyReference,
} from '../../types';
import { sortIds } from '../../utils';
import configBuilderHandlers from './handlers';
import { CustomItemFormConfig, ItemFormConfig } from './types';

const VIDEO_EXTENSIONS = [
    'mp4',
    'm4v',
    '3gp',
    'mkv',
    'webm',
    'mov',
    'avi',
    'wmv',
    'mpg',
];

function getPropertyField(
    siteId: string,
    propertyId: string,
    propertyMetaInfo: LocalMetaInfoProperty,
    customViewConfig: Partial<View> | undefined
): View {
    const result: any = {
        id: propertyId,
        label: propertyMetaInfo.name || (propertyId && toTitleCase(propertyId)),
        dataSource: `object.${propertyId}`,
    };

    switch (propertyMetaInfo.type) {
        case 'string':
            result.type = ViewTypes.TextInput;
            if (propertyMetaInfo.format === 'color') {
                result.type = ViewTypes.ColorInput;
            } else if (propertyMetaInfo.format === 'textarea') {
                result.isMultiLine = true;
            } else if (propertyMetaInfo.format === 'url') {
                result.ltr = true;
            } else if (propertyMetaInfo.format === 'html') {
                result.type = ViewTypes.HtmlInput;
            } else if (propertyMetaInfo.format === 'datetime') {
                result.type = ViewTypes.DateInput;
            } else if (propertyMetaInfo.format === 'enum') {
                const propertyMetaInfoEnum =
                    propertyMetaInfo as LocalMetaInfoPropertyEnum;
                result.type = ViewTypes.EnumInput;
                result.values = propertyMetaInfoEnum.values;
                const enumIds = Object.keys(propertyMetaInfoEnum.values);
                enumIds.sort();
                result.values = enumIds.map((value) => ({
                    value,
                    label: propertyMetaInfoEnum.values[value],
                }));
            } else if (propertyMetaInfo.format === 'reference') {
                const propertyMetaInfoReference =
                    propertyMetaInfo as LocalMetaInfoPropertyReference;
                result.type = ViewTypes.ReferenceInput;
                result.siteId = propertyMetaInfoReference.siteId;
                result.endpointId = propertyMetaInfoReference.endpointId;
                result.representationDataSource =
                    propertyMetaInfoReference.representationDataSource;
                result.sortListByField =
                    propertyMetaInfoReference.sortListByField;
                result.idField = propertyMetaInfoReference.idField;
                result.searchByField = propertyMetaInfoReference.searchByField;
            } else if (propertyMetaInfo.format === 'checkbox') {
                result.type = ViewTypes.BooleanInput;
                result.sourceFormat = 'boolean';
            }
            if (propertyMetaInfo.format === 'number') {
                result.type = ViewTypes.NumberInput;
                result.ltr = true;
            }
            break;
        case 'number':
            result.type = ViewTypes.NumberInput;
            result.ltr = true;
            break;
        case 'boolean':
            result.type = ViewTypes.BooleanInput;
            result.sourceFormat = 'boolean';
            break;
        case 'array':
            result.type = ViewTypes.List;
            break;
        case 'object':
            result.type = ViewTypes.Code;
            result.isDisabled = true;
            break;
    }

    if (!customViewConfig) {
        return result;
    }

    return _.merge(result, customViewConfig);
}

export default function getItemForm(
    siteId: string,
    endpointId: string,
    endpointMetaInfo: LocalMetaInfoEndpoint,
    customConfig: CustomItemFormConfig | undefined
): ItemFormConfig {
    const { properties } = endpointMetaInfo;
    const customViewsConfig = customConfig?.views;
    const customTabsConfig = customConfig?.tabs;
    const customHandlers = customConfig?.handlers;

    const propertiesIds = sortIds(
        Object.keys(properties),
        endpointMetaInfo.propertiesOrder
    );

    const formVariables: any = {
        object: null,
        areFieldsBlocked: false,
    };

    const commandPanel: HStack = {
        id: 'commandPanel',
        type: ViewTypes.HStack,
        style: {
            position: 'absolute',
            height: 'unset',
            width: 'unset',
            background: 'white',
            padding: 10,
            zIndex: 1000,
            top: 0,
            left: 0,
            right: 0,
        },
        items: [
            {
                id: 'createButton',
                type: ViewTypes.Button,
                role: 'active',
                text: 'Create',
                onClick: 'onSave',
                isHidden(this: AppContext) {
                    const { objectId } = (
                        this.form.handlers as any
                    ).getParams();
                    return !!objectId;
                },
            },
            {
                id: 'saveButton',
                type: ViewTypes.Button,
                role: 'active',
                text: 'Save',
                onClick: 'onSave',
                isHidden(this: AppContext) {
                    const { objectId } = (
                        this.form.handlers as any
                    ).getParams();
                    return !objectId;
                },
            },
        ],
    };

    const fields: any = {};
    formVariables.object = fields;
    const infoFields: View[] = [];

    propertiesIds.forEach((propertyId) => {
        const customPropertyMetaInfo = customConfig && customConfig?.properties;
        let propertyMetaInfo = properties[propertyId];

        if (!propertyMetaInfo) {
            propertyMetaInfo = customPropertyMetaInfo;
        } else if (customPropertyMetaInfo) {
            propertyMetaInfo = {
                ...propertyMetaInfo,
                ...customPropertyMetaInfo,
            };
        }

        if (!propertyMetaInfo) {
            console.error(
                `CONFIG BUILDER (getItemForm): The property doesn't exist: ${propertyId}`,
                {
                    siteId,
                    endpointId,
                    endpointMetaInfo,
                    customPropertyMetaInfo,
                    propertyId,
                    properties,
                    customConfig,
                }
            );
            return;
        }

        if (propertyMetaInfo.isHidden || propertyMetaInfo.upload) {
            return;
        }

        const propertyField = getPropertyField(
            siteId,
            propertyId,
            propertyMetaInfo,
            customViewsConfig && customViewsConfig[propertyId]
        );

        if (!propertyField) {
            return;
        }

        if (['createdAt', 'updatedAt'].includes(propertyId)) {
            (propertyField as any).isDisabled = true;
            (propertyField as any).isHidden = function (
                this: AppContext
            ): boolean {
                return !(this.form.object && this.form.object.id);
            };
        }

        fields[propertyId] = propertyMetaInfo.defaultValue;
        infoFields.push(propertyField);
    });

    const mediaPropertiesIds = propertiesIds.filter((propertyId) => {
        const propertyMetaInfo = properties[propertyId];
        if (!propertyMetaInfo) {
            return false;
        }

        return !propertyMetaInfo.isHidden && propertyMetaInfo.upload;
    });

    formVariables.mediaPropertiesIds = mediaPropertiesIds;

    const getMediaFields = (customProperties?: any): MediaField[] =>
        mediaPropertiesIds
            .map((propertyId) => {
                formVariables[`${propertyId}Disabled`] = true;
                const propertyMetaInfo = properties[propertyId];

                const allowedExtensionsWithDot =
                    propertyMetaInfo.allowedExtensionsWithDot || [];
                let maxSizeKB = 5 * 1024;
                const isVideo = allowedExtensionsWithDot.some((extension) =>
                    VIDEO_EXTENSIONS.includes(extension)
                );

                if (isVideo) {
                    maxSizeKB = 10 * 1024;
                }

                const maxSizeText =
                    maxSizeKB < 1024
                        ? `${maxSizeKB}KB`
                        : `${maxSizeKB / 1024}MB`;

                const defaultFileTypes = [
                    '.jpg',
                    '.png',
                    '.svg',
                    '.webp',
                    '.jpeg',
                    '.gif',
                ];

                let extensionsWithDot =
                    allowedExtensionsWithDot &&
                    allowedExtensionsWithDot.length > 0
                        ? allowedExtensionsWithDot
                        : defaultFileTypes;

                return {
                    id: propertyId,
                    type: ViewTypes.MediaField,
                    title: propertyMetaInfo.name,
                    objectIdDataSource: 'objectId',
                    siteId,
                    endpointId,
                    saveOnSelect: true,
                    fieldName: propertyId,
                    maxSizeKB,
                    placeholder: `File type: ${extensionsWithDot.join(
                        ', '
                    )}. Max size: ${maxSizeText}`,
                    isDisabled: `${propertyId}Disabled`,
                    mediaManager: `mediaManagers.${propertyId}`,
                    extensionsWithDot,
                    ...(((customViewsConfig && customViewsConfig[propertyId]) ||
                        {}) as any),
                    ...((customProperties || {}) as any),
                };
            })
            .filter((field) => !!field);

    const mediaTabContent: VStack = {
        id: 'mediaTabContentGroup',
        type: ViewTypes.VStack,
        style: { padding: 15 },
        items: getMediaFields(),
    };

    const newItemMediaFields: VStack = {
        id: 'newItemMediaFields',
        type: ViewTypes.VStack,
        style: { padding: 15 },
        isHidden(this: AppContext) {
            if (this.form.object) {
                return !!this.form.object.id;
            }

            return true;
        },
        items: getMediaFields({ copyFromObjectId: 'url.queryParams.copyFrom' }),
    };

    const infoTabContent: VStack = {
        id: 'infoTabContentGroup',
        type: ViewTypes.VStack,
        style: { padding: 15, position: 'relative', paddingTop: 0 },
        isLoading: 'isLoading',
        items: [
            commandPanel,
            {
                id: 'fieldsGroup',
                type: ViewTypes.VStack,
                style: {
                    paddingTop: 70,
                    paddingBottom: 30,
                    paddingRight: 10,
                    flex: 1,
                    overflowY: 'auto',
                },
                items: [
                    {
                        id: 'infoFieldsGroup',
                        type: ViewTypes.VStack,
                        style: { position: 'relative' },
                        isLoading(this: AppContext): boolean {
                            return !!this.form.areFieldsBlocked;
                        },
                        items: [
                            ...infoFields,
                            {
                                id: 'infoProgressStub',
                                type: ViewTypes.VStack,
                                isLoading: true,
                                isHidden: true,
                                style: {
                                    opacity: 0.9,
                                    position: 'absolute',
                                    zIndex: 100,
                                    width: '100%',
                                    height: '100%',
                                    top: 0,
                                    left: 0,
                                    right: 0,
                                    bottom: 0,
                                },
                                items: [],
                            },
                            newItemMediaFields,
                        ],
                    },
                ],
            },
        ],
    };

    const tabs: any[] = [
        {
            id: 'info',
            title: 'Info',
            type: ViewTypes.TabPage,
            content: infoTabContent,
        },
        {
            id: 'media',
            title: `Media - (${mediaTabContent.items.length})`,
            type: ViewTypes.TabPage,
            content: mediaTabContent,
            isHidden(this: AppContext) {
                const { objectId } = (this.form.handlers as any).getParams();

                const numberOfMediaFields =
                    this.form.views?.mediaTabContentGroup?.items?.length ?? 0;
                return !objectId || numberOfMediaFields === 0;
            },
        },
    ];

    return {
        id: 'form',
        component: ItemForm,
        subtitle:
            customConfig?.subtitle ?? `${toTitleCase(endpointId)} Details`,
        commands: [
            {
                id: 'openConfig',
                text: 'Endpoint Config',
                onClick: 'onOpenEndpointConfigEditor',
                image: endpointConfigIcon,
                isHidden(this: AppContext) {
                    const { currentUserData } = this;

                    const roles = ['admin', /[a-zA-Z0-9]+Admin$/];

                    if (!currentUserData || !currentUserData.roles) {
                        return true;
                    }

                    return !currentUserData.roles.some((role: string) =>
                        roles.some((expression: string | RegExp) => {
                            if (expression instanceof RegExp) {
                                return role.match(expression);
                            }

                            return role === expression;
                        })
                    );
                },
            },
            {
                id: 'openDeleteItemDialog',
                text: 'Delete',
                onClick: 'onOpenDeleteItemDialog',
                image: deleteIcon,
                isHidden(this: AppContext) {
                    const { currentUserData } = this;

                    const roles = ['admin', /[a-zA-Z0-9]+Admin$/];

                    if (!currentUserData || !currentUserData.roles) {
                        return true;
                    }

                    return !currentUserData.roles.some((role: string) =>
                        roles.some((expression: string | RegExp) => {
                            if (expression instanceof RegExp) {
                                return role.match(expression);
                            }

                            return role === expression;
                        })
                    );
                },
            },
            {
                id: 'closeDialog',
                text: 'Close',
                isHidden(this: AppContext): boolean {
                    return !this.form.params?.isDialog;
                },
                onClick(this: AppContext) {
                    this.form.closeDialog();
                },
            },
        ],
        params: {
            siteId,
            endpointId,
            fields,
        },
        urlParamsTemplate: ['/:objectId/:tabId', ''],
        fields: formVariables,
        handlers() {
            return {
                ...configBuilderHandlers.call(this),
                ...((customHandlers && customHandlers.call(this)) || {}),
            };
        },
        content: {
            id: `rootGroup`,
            type: ViewTypes.VStack,
            items: [
                {
                    id: 'content',
                    type: ViewTypes.VStack,
                    items: [
                        {
                            id: 'tabs',
                            type: ViewTypes.TabsGroup,
                            defaultTabId: 'info',
                            activeTabIdSource(this: AppContext): string {
                                const { isDialog } = this.form.params ?? {};
                                if (!isDialog) {
                                    return 'url.params.tabId.replace';
                                }

                                return 'activeTabId';
                            },
                            tabs: customTabsConfig
                                ? tabs.concat(customTabsConfig)
                                : tabs,
                        },
                    ],
                },
            ],
        },
    };
}
