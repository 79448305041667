import React from 'react';
import PropsHeader, { TabData } from './props';
import style from './style.module.scss';

const Header = (props: PropsHeader) => {
    const { activeTabId, tabs } = props;
    const onChangeTab = (tabId: string) => (e: any) => {
        e && e.stopPropagation();
        props.onChangeTab(tabId);
    };

    return (
        <div className={style.header}>
            {tabs.map((item: TabData, index) => (
                <div
                    key={index}
                    className={
                        activeTabId === item.id ? style.activeItem : style.item
                    }
                    onClick={onChangeTab(item.id)}
                >
                    <span>{item.title}</span>
                </div>
            ))}
        </div>
    );
};

export default React.memo(Header);
