import React, { useEffect, useLayoutEffect } from 'react';
import { useGlobalStoreObserver } from '../../globalStore/definition';
import Content from './content';
import PropsApp from './props';
import style from './style.module.scss';

const App = (props: PropsApp) => {
    const { config } = props;
    const [{ loginSuccess, logoutSuccess, updateAppConfig, loginByToken }] =
        useGlobalStoreObserver((store) => {
            const { logoutSuccess, loginSuccess, loginByToken } =
                store.account.dispatch;
            const { updateAppConfig } = store.app.dispatch;

            return {
                logoutSuccess,
                loginSuccess,
                loginByToken,
                updateAppConfig,
            };
        });

    useLayoutEffect(() => {
        updateAppConfig({
            userData: null,
            customConfig: config,
        });
    }, [updateAppConfig, config]);

    useEffect(() => {
        if (window._AUTH_TOKEN) {
            loginByToken({ authToken: window._AUTH_TOKEN });
        } else {
            logoutSuccess();
        }
    }, [loginByToken, logoutSuccess]);

    return (
        <div className={style.app}>
            <Content />
            {process.env.NODE_ENV === 'development' && (
                <div className={style.development}>DEVELOPMENT</div>
            )}
        </div>
    );
};

export default App;
