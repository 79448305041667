import { AppContext } from '../../../../core/components/form/context/types';
import { CustomEndpointConfig } from '../../../../core/config-builder/types';

const embassador: CustomEndpointConfig = {
    title: 'שגרירים',
    metaInfo: {
        // TODO: fix error when prop does not exist
        propertiesOrder: [
            'firstName',
            'lastName',
            'talent',
            'team',
            'responsability',
            'managedBy',
            'lastTalk',
            'idfUnit',
            'base',
            'releaseDate',
            'birthday',
            'pazamStatus',
            'comments',
            'updatedAt',
        ],
        properties: {
            firstName: {
                name: 'שם פרטי',
            },
            lastName: {
                name: 'שם משפחה',
            },
            talent: {
                name: 'כישרון מיוחד',
            },
            team: {
                name: 'צוות',
            },
            responsability: {
                name: 'אחריות על',
            },
            managedBy: {
                name: 'מנהל אחראי',
            },
            lastTalk: {
                name: 'מועד שיחה אחרונה',
            },
            idfUnit: {
                name: 'יחידה וגדוד',
            },
            base: {
                name: 'משרת בבסיס',
            },
            releaseDate: {
                name: 'משתחרר בתאריך',
            },
            birthday: {
                name: 'יום הולדת',
            },
            pazamStatus: {
                name: 'פז״מ',
            },
            comments: {
                name: 'הערות',
            },
            updatedAt: {
                name: 'תאריך עדכון אחרון',
            },
        },
    },
    forms: {
        list: {
            // content of the list form
            defaultUrlQueryParams: {
                sort: [
                    {
                        columnId: 'updatedAt',
                        direction: 'desc',
                    },
                ],
            },
            columnsOrder: [
                'firstName',
                'lastName',
                'talent',
                'team',
                'responsability',
                'managedBy',
                'lastTalk',
                'idfUnit',
                'base',
                'releaseDate',
                'birthday',
                'pazamStatus',
                'comments',
                'updatedAt',
            ],
            columns: {
                firstName: {
                    title: 'שם פרטי',
                },
                lastName: {
                    title: 'שם משפחה',
                },
                talent: {
                    title: 'כישרון מיוחד',
                },
                team: {
                    title: 'צוות',
                },
                responsability: {
                    title: 'אחריות על',
                },
                managedBy: {
                    title: 'מנהל אחראי',
                },
                lastTalk: {
                    title: 'מועד שיחה אחרונה',
                },
                idfUnit: {
                    title: 'יחידה וגדוד',
                },
                base: {
                    title: 'משרת בבסיס',
                },
                releaseDate: {
                    title: 'משתחרר בתאריך',
                },
                birthday: {
                    title: 'יום הולדת',
                },
                pazamStatus: {
                    title: 'פז״מ',
                },
                comments: {
                    title: 'הערות',
                },
                updatedAt: {
                    title: 'תאריך עדכון אחרון',
                },
            },
            handlers(this: AppContext) {
                return {
                    onBeforeOpen: async () => {
                        if (
                            !this.currentUserData.roles.includes('admin') &&
                            !this.currentUserData.roles.includes('officeAdmin')
                        )
                            this.form.commands = this.form.commands.filter(
                                (item) => item.id !== 'export'
                            );
                    },
                };
            },
            rowStyle: () => {
                return {
                    background: 'white',
                };
            },
        },
    },
};

export default embassador;
