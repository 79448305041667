import {
    AppContext,
    FormFunctionGetHandlers,
} from '../../../../components/form/context/types';

const handlers: FormFunctionGetHandlers = function (this: AppContext) {
    const onBeforeOpen = async () => {
        this.form.appConfig = JSON.stringify(this.appConfig, null, 2);
    };

    return {
        onBeforeOpen,
    };
};

export default handlers;
