import React, { useCallback } from 'react';
import { getValue } from '../../../utils';
import ReferenceInputNative from '../../native/reference-input';
import { useAppContextObserver } from '../context';
import PropsReferenceInput from './props';

const ReferenceInput = (props: PropsReferenceInput) => {
    const {
        label,
        dataSource,
        siteId,
        endpointId,
        idField,
        representationDataSource,
        sortListByField,
        sortListDirection,
        searchByField,
        getItemsFromTable,
        onChangeValue,
        onOpen,
        isMulti,
        isCreatable,
        creationParams,
    } = props;

    const [{ isHidden, isDisabled, value, rawSearchFilter }, getContext] =
        useAppContextObserver((context) => {
            let value: string | string[] = props.value!;
            if (dataSource) {
                value = context.form.getDataSourceValue(dataSource)!;
            }

            let { rawSearchFilter } = props;
            if (rawSearchFilter) {
                rawSearchFilter = getValue(rawSearchFilter, context);
            }

            return {
                isHidden: getValue(props.isHidden, context),
                isDisabled: getValue(props.isDisabled, context),
                value,
                rawSearchFilter,
            };
        });

    const handleChangeValue = useCallback(
        (value: string | string[]) => {
            if (dataSource) {
                getContext().form.setDataSourceValue(dataSource, value);
            }

            if (!onChangeValue) {
                return;
            }

            if (typeof onChangeValue === 'string') {
                (getContext().form.handlers[onChangeValue] as any)(value);
                return;
            }

            onChangeValue.call(getContext(), value);
        },
        [getContext, dataSource, onChangeValue]
    );

    const handleOpen = useCallback(
        (itemId: string) => {
            onOpen?.call(getContext(), itemId);
        },
        [getContext, onOpen]
    );

    if (isHidden) {
        return null;
    }

    const onCreate = () => {
        getContext().form.pushDialog(`${siteId}-${endpointId}-create-dialog`, {
            siteId,
            endpointId,
            creationParams,
            onSuccess: (objectId: string) => {
                let newValue: string | string[] = objectId;
                if (isMulti) {
                    newValue = [...((value ?? []) as string[]), objectId];
                }
                handleChangeValue(newValue);
            },
        });
    };

    return (
        <ReferenceInputNative
            isDisabled={isDisabled}
            label={label}
            siteId={siteId}
            endpointId={endpointId}
            idField={idField}
            searchByField={searchByField}
            value={value}
            representationDataSource={representationDataSource}
            sortListByField={sortListByField}
            sortListDirection={sortListDirection}
            onChange={handleChangeValue}
            onOpenItem={handleOpen}
            getItemsFromTable={getItemsFromTable}
            rawSearchFilter={rawSearchFilter as string}
            isMulti={isMulti}
            isCreatable={isCreatable}
            onCreate={onCreate}
        />
    );
};

export default React.memo(ReferenceInput);
