import React, { useMemo } from 'react';
import { getValue } from '../../../utils';
import Header from '../../native/page-header';
import Button from '../button';
import { useAppContextObserver } from '../context';
import PropsFormHeader from './props';
import style from './style.module.scss';

const FormHeader = (props: PropsFormHeader) => {
    const [{ title, subtitle, commands }] = useAppContextObserver((context) => {
        const title = getValue(context.form?.title, context, true);
        const subtitle = getValue(context.form?.subtitle, context, true);

        return {
            title,
            subtitle,
            commands: context.form.commands,
        };
    });

    const items = useMemo(() => {
        const items: any[] = (commands || []).map((command) => ({
            type: 'element',
            element: (
                <Button
                    id={command.id}
                    key={command.id}
                    role="notActive"
                    text={command.text}
                    isHidden={command.isHidden}
                    onClick={command.onClick}
                    image={command.image}
                    className={style.commandButton}
                />
            ),
        }));

        items.unshift({ type: 'spacer' });

        return items;
    }, [commands]);

    return <Header subtitle={subtitle} title={title} items={items} />;
};

export default React.memo(FormHeader);
