import moment from 'moment';
import React, { useEffect, useState } from 'react';
import PropsIntervalInput from './props';
import style from './style.module.scss';

const IntervalInput = (props: PropsIntervalInput) => {
    const { displayError, isDisabled, onChange } = props;
    const [value, setValue] = useState(() => moment.duration(props.value));

    useEffect(() => {
        setValue(moment.duration(props.value));
    }, [props.value]);

    const _handleChangePart = (part: string) => (e: any) => {
        const seconds = value.seconds();
        const minutes = value.minutes();
        const hours = value.hours();
        const days = value.days();
        // let weeks = value.weeks();
        const months = value.months();
        const years = value.years();

        const partValue = Number(e.target.value);
        const data = {
            seconds,
            minutes,
            hours,
            days,
            months,
            years,
            [part]: partValue,
        };

        const newValue = moment.duration(data);
        onChange(newValue.toISOString());
    };

    return (
        <div className={style.intervalInput}>
            <label>Seconds</label>
            <input
                disabled={isDisabled}
                type="number"
                value={value.seconds()}
                className={displayError ? style.inputError : style.input}
                min={0}
                onInput={_handleChangePart('seconds')}
            />
            <label>Minutes</label>
            <input
                disabled={isDisabled}
                type="number"
                value={value.minutes()}
                className={displayError ? style.inputError : style.input}
                min={0}
                onInput={_handleChangePart('minutes')}
            />
            <label>Hours</label>
            <input
                disabled={isDisabled}
                type="number"
                value={value.hours()}
                className={displayError ? style.inputError : style.input}
                min={0}
                onInput={_handleChangePart('hours')}
            />
            <label>Days</label>
            <input
                disabled={isDisabled}
                type="number"
                value={value.days()}
                className={displayError ? style.inputError : style.input}
                min={0}
                onInput={_handleChangePart('days')}
            />
            {/* <label>Weeks</label> */}
            {/* <input */}
            {/*	disabled={isDisabled} */}
            {/*	type='number' */}
            {/*	value={value.weeks()} */}
            {/*	className={style.input} */}
            {/*	min={0} */}
            {/*	onInput={this._handleChangePart('weeks')} */}
            {/* /> */}
            <label>Months</label>
            <input
                disabled={isDisabled}
                type="number"
                value={value.months()}
                className={displayError ? style.inputError : style.input}
                min={0}
                onInput={_handleChangePart('months')}
            />
            <label>Years</label>
            <input
                disabled={isDisabled}
                type="number"
                value={value.years()}
                className={displayError ? style.inputError : style.input}
                min={0}
                onInput={_handleChangePart('years')}
            />
        </div>
    );
};

export default IntervalInput;
