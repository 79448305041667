import React, { Component } from 'react';
import Dialog from '../../../components/native/dialog';
import Input from '../../../components/native/input';
import style from './style.module.scss';

class ChangePasswordDialog extends Component {
    constructor(props) {
        super(props);

        this.state = {
            newPassword: '',
            retypePassword: '',
            errors: undefined,
        };
    }

    _handleChangePassword = (field) => (e) => {
        e.stopPropagation();
        this.setState({
            [field]: e.target.value,
        });
    };

    _handleSubmit = () => {
        this.setState({ errors: null });
        const errors = {};
        const { password, retype } = this.state;
        const { token } = this.props;

        if (password !== retype) {
            errors.password = {
                text: "Passwords aren't equal",
            };
            errors.retype = {
                text: "Passwords aren't equal",
            };
        } else if (password.length < 6) {
            errors.password = {
                text: 'The password is too short',
            };
        }

        if (Object.getOwnPropertyNames(errors).length > 0) {
            this.setState({ errors });
            return;
        }

        this.setState({ isProcessing: true });

        try {
            this.props.changePassword(password, token);
            this.props.onClose();
        } catch (e) {
        } finally {
            this.setState({ isProcessing: false });
        }
    };

    render() {
        const { onCancel } = this.props;
        const { password, retype, errors } = this.state;
        const passwordError = errors && errors.password;
        const retypeError = errors && errors.retype;

        return (
            <Dialog
                id="changePasswordDialog"
                title="Change password"
                isOpened
                openedClass={style.changePasswordDialog}
            >
                <div className={style.content}>
                    <Input
                        label="New password"
                        type="password"
                        value={password}
                        onChange={this._handleChangePassword('password')}
                        displayError={passwordError}
                        errorText={passwordError && passwordError.text}
                    />
                    <Input
                        label="Retype password"
                        type="password"
                        value={retype}
                        onChange={this._handleChangePassword('retype')}
                        displayError={passwordError}
                        errorText={retypeError && retypeError.text}
                    />

                    <div className={style.buttonsGroup}>
                        <button
                            className={style.submitButton}
                            onClick={this._handleSubmit}
                        >
                            Change Password
                        </button>
                        {onCancel && (
                            <button
                                className={style.closeButton}
                                onClick={onCancel}
                            >
                                Cancel
                            </button>
                        )}
                    </div>
                </div>
            </Dialog>
        );
    }
}

export default ChangePasswordDialog;
