import React from 'react';
import { usePropsWithDefaultValues } from '../../../utils/propsWithDefaultValues';
import { useAppContextObserver } from '../context';
import PropsCounter, { defaultProps } from './props';
import style from './style.module.scss';

const Counter = (props: PropsCounter) => {
    props = usePropsWithDefaultValues(props, defaultProps);
    const { label, description, className, style: customStyle } = props;

    const [{ value }] = useAppContextObserver((c) => {
        let value = props.value;
        if (props.dataSource) {
            value = c.form.getDataSourceValue(props.dataSource)!;
        }

        return {
            value,
        };
    });

    return (
        <div className={className} style={customStyle}>
            <div className={style.label}>{label}</div>
            {description && (
                <div className={style.description}>{description}</div>
            )}
            <div className={style.value}>{value}</div>
        </div>
    );
};

export default React.memo(Counter);
