import React from 'react';
import PropsLogoutButton from './props';
import style from './style.module.scss';

const LogOut = ({ onClick }: PropsLogoutButton) => {
    return (
        <button onClick={onClick} className={style.logOutButton}>
            Log out
        </button>
    );
};

export default React.memo(LogOut);
