import {
    AppContext,
    FormFunctionGetHandlers,
} from '../../../../../components/form/context/types';

const handlers: FormFunctionGetHandlers = function (this: AppContext) {
    const onBeforeOpen = async () => {
        const { params } = this.form;
        const { operation } = params;

        this.form.descriptionText = `Do you	want to ${operation} the script on PRODUCTION site?`;
    };

    const onClose = () => {
        this.form.closeDialog();
    };

    const onContinue = () => {
        const { params } = this.form;
        const { onContinue } = params;
        onContinue();
        this.form.closeDialog();
    };

    return {
        onBeforeOpen,
        onClose,
        onContinue,
    };
};

export default handlers;
