import { DataSourcePath, ValueOrHandler } from '../context/types';

interface PropsUrlInputWithParams {
    id: string;
    label: string;
    placeholder?: string;
    isHidden?: ValueOrHandler<boolean>;
    isDisabled?: ValueOrHandler<boolean>;
    value?: string | undefined;
    dataSource?: DataSourcePath<string>;
    displayError?: boolean;
    onChangeValue?: (newValue: string) => void;
    paramsOptions?: { key: string; value?: string }[];
    showMinisiteOptions?: ValueOrHandler<boolean>;
}

export const defaultProps: Partial<PropsUrlInputWithParams> = {
    showMinisiteOptions: true,
};

export default PropsUrlInputWithParams;
