import PropsMediaManagerView from '../media-manager-view';
import style from './style.module.scss';

export default interface PropsPhotoView extends PropsMediaManagerView {
    id: string;
    label: string;
    placeholderText?: string;
    isInEditMode?: boolean;
    showEditButtons: boolean;
    saveOnSelect?: boolean;
    containerClass?: string;
    displayError?: boolean;
    items: any[];
    errors: any[];
    isLoading?: boolean;

    maxSizeKB: number;
    extensionsWithDot: string[];
    // isProcessing?: boolean;
    // saveProgressPercent: number;
    // saveProgressItemId?: string;
    // errors: null[];
    // save: null;
    // addItem: null;
    // isLoading?: boolean;
    // undon: null;
    addItem: (data: string) => void;
    deleteItem: (itemId: string) => void;
    undo: () => void;
}

export const defaultProps: Partial<PropsPhotoView> = {
    isInEditMode: true,
    placeholderText: 'Drag & Drop to upload',
    saveOnSelect: true,
    containerClass: style.container,
    displayError: false,
};
