import {
    AppContext,
    VStack,
} from '../../../../../core/components/form/context/types';
import ViewTypes from '../../../../../core/components/form/ViewTypes';

const content: VStack = {
    id: 'rootGroup',
    type: ViewTypes.VStack,
    items: [
        {
            id: `user-list-table-command-panel`,
            type: ViewTypes.HStack,
            items: [
                {
                    id: `createButton`,
                    type: ViewTypes.Button,
                    text: 'Create',
                    onClick: 'onCreateNewItem',
                },
                {
                    id: `search`,
                    type: ViewTypes.SearchInput,
                    dataSource: 'url.queryParams.search.replace',
                },
            ],
        },
        {
            id: 'table',
            type: ViewTypes.DataTable,
            endpointId: 'user',
            pageSize: 15,
            sortDataSource: 'url.queryParams.sort.replace',
            filterDataSource: 'url.queryParams.filter.replace',
            searchDataSource: 'url.queryParams.search.replace',
            selectionDataSource: 'selection',
            pageDataSource: 'url.queryParams.page.replace',
            isSelectable: true,
            onClickItem(this: AppContext, item: any) {
                if (this.form.params.isAdminForm) {
                    this.form.url.push(`/user/system/item/${item.id}/info`);
                } else {
                    this.form.url.push(`/user/common/item/${item.id}/info`);
                }
            },
            columns: [],
        },
    ],
};

export default content;
