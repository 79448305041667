import React, {
    useCallback,
    useEffect,
    useMemo,
    useReducer,
    useState,
} from 'react';
import { useLocation, useRouteMatch } from 'react-router-dom';
import {
    Observable,
    useGlobalStoreObserver,
} from '../../../globalStore/definition';
import { useUnmountedFlag } from '../../../utils';
import {
    ContextObservableCallbacks,
    createObserverHook,
    useObservableCallbacksCreator,
} from '../../../utils/store';
import PropsForm from '../props';
import createRawAppContext from './Context';
import Proxy from './Proxy';
import { AppContext, RawAppContext } from './types';

function createAppContext(
    props: any,
    store: Observable,
    urlData: { url: string; path: string; queryParams: any; urlParams: any },
    forceUpdateForm: () => void,
    baseContext: RawAppContext | undefined,
    reload: () => void
): AppContext {
    const context = createRawAppContext(
        props,
        store,
        urlData,
        forceUpdateForm,
        baseContext,
        reload
    );

    return Proxy(context);
}

export default createAppContext;

export function useAppContextCreator(props: PropsForm) {
    const { isUnmounted } = useUnmountedFlag(`FORM ${props.id}`);
    const [timestamp, setTimestamp] = useState(() => Date.now());
    const [, dispatchUpdate] = useReducer((prev) => {
        return prev + 1;
    }, 0);

    const forceRender = useCallback(() => {
        if (isUnmounted()) {
            return;
        }

        dispatchUpdate();
    }, [dispatchUpdate, isUnmounted]);

    const location = useLocation();
    const match = useRouteMatch();

    const urlParams = match?.params ?? {};
    const queryParams = location?.search;
    const path = match?.path;
    const url = match?.url;

    const urlData = useMemo(() => {
        return {
            url,
            urlParams,
            queryParams,
            path,
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [
        path,
        url,
        JSON.stringify(urlParams),
        JSON.stringify(queryParams),
        timestamp,
    ]);

    // console.log('USE LOCATION ' + props.id, { urlData, match });

    const [store] = useGlobalStoreObserver((s) => s);

    const context = useMemo(() => {
        return createAppContext(
            props as any,
            store,
            urlData,
            forceRender,
            props.baseContext,
            () => {
                setTimestamp(Date.now() + Math.random() * 100);
            }
        );
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [timestamp]);

    useEffect(() => {
        context.___updateContextProps___(
            { store, urlData },
            { createViews: false }
        );
        forceRender();
    }, [store, urlData, context, forceRender]);

    useEffect(() => {
        context.___updateContextProps___(
            { formData: props as any },
            { createViews: true }
        );
        forceRender();
    }, [props.content]);

    useEffect(() => {
        context.form._updateUrl(context, path, url, urlParams, queryParams);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [context, path, url, queryParams, urlParams.toString()]);

    const observableCallbacks: any = useObservableCallbacksCreator(context, [
        context.version,
    ]);
    observableCallbacks.context = context;
    return observableCallbacks;
}

export const ReactAppContext = React.createContext<
    ContextObservableCallbacks<AppContext>
>(undefined as any);

export const useAppContextObserver =
    createObserverHook<AppContext>(ReactAppContext);
