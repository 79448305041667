import {
    AppContext,
    FormFunctionGetHandlers,
} from '../../components/form/context/types';

const handlers: FormFunctionGetHandlers = function (this: AppContext) {
    const onClose = () => {
        this.form.closeDialog();
    };

    return {
        onClose,
    };
};

export default handlers;
