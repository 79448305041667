import React, { useCallback } from 'react';
import { getHandlerWithContext, getValue } from '../../../utils';
import { usePropsWithDefaultValues } from '../../../utils/propsWithDefaultValues';
import { useAppContextObserver } from '../context';
import PropsButton, { defaultProps } from './props';

const Button = (props: PropsButton) => {
    props = usePropsWithDefaultValues(props, defaultProps);
    const [{ isHidden, isDisabled, text }, getContext] = useAppContextObserver(
        (c) => {
            const isHidden = getValue(props.isHidden, c);

            return {
                isHidden,
                isDisabled: getValue(props.isDisabled, c),
                text: getValue(props.text, c, !isHidden),
            };
        }
    );
    const { id, role, style: customStyle, image, className } = props;

    const onClick = useCallback(
        (e: any) => {
            e.stopPropagation();

            if (!props.onClick) {
                return;
            }

            const handler = getHandlerWithContext(props.onClick, getContext());
            handler!(id);
        },
        [getContext, id, props.onClick]
    );

    if (isHidden) {
        return null;
    }

    return (
        <button
            disabled={isDisabled}
            id={id}
            onClick={onClick}
            className={className}
            style={customStyle}
            data-role="view"
            button-role={role}
            data-has-image={!!image}
        >
            {text && <div>{text}</div>}
            {image && <img alt="" src={image} />}
        </button>
    );
};

export default React.memo(Button);
