import { useMemo } from 'react';

export default function propsWithDefaultValues<Props>(
    props: Props,
    defaultValues: Partial<Props>
): Props {
    const values = Object.getOwnPropertyNames(defaultValues).reduce(
        (acc: any, property: string) => {
            if ((props as any)[property] === undefined) {
                acc[property] = (defaultValues as any)[property];
            }

            return acc;
        },
        {}
    );
    return {
        ...props,
        ...values,
    };
}

export function usePropsWithDefaultValues<Props>(
    props: Props,
    defaultValues: Partial<Props>
): Props {
    return useMemo(() => {
        return propsWithDefaultValues(props, defaultValues);
    }, [props, defaultValues]);
}
