import {
    AppContext,
    FormFunctionGetHandlers,
} from '../../../../components/form/context/types';
import apiClient from '../../../../requests/api';
import {
    addDefaultValuesToEndpointData,
    getEndpointDataFromMetaInfo,
    getSiteDataFromMetaInfo,
    getSiteUpdateObject,
} from '../../utils';
import propertyDialog from './property-dialog';

const handlers: FormFunctionGetHandlers = function (this: AppContext) {
    const getParams = () => {
        const { siteId, endpointId } = this.form.url.params || {};
        const { event, type } = this.form.url.queryParams || {};
        return {
            siteId,
            endpointId,
            scriptEventType: event || 'Update',
            scriptType: type || 'pre',
        };
    };

    const updateSiteIdInputValues = (inputId: string) => {
        const { appConfig } = this;
        this.form.views[inputId].values = (appConfig.sites ?? []).map(
            (item: any) => ({
                label: item.id,
                value: item.id,
            })
        );
    };

    const updateEndpointIdInputValues = (inputId: string, siteId: string) => {
        const siteConfig = this.getSiteConfig(siteId);
        if (!siteConfig) {
            return;
        }
        this.form.views[inputId].values = siteConfig.endpoints.map(
            (item: any) => ({
                label: item.id,
                value: item.id,
            })
        );
    };

    const initDataFromConfig = () => {
        const { siteId, endpointId } = getParams();

        this.form.endpointData = {
            description: '',
            schema: {
                properties: [],
            },
        };

        if (!siteId && !endpointId) {
            return;
        }

        const siteMetaInfo = this.remoteMetaInfo.sites[siteId];
        if (!siteMetaInfo) {
            return;
        }

        const endpointData = getEndpointDataFromMetaInfo(
            siteMetaInfo,
            endpointId
        );
        const endpointSchema = endpointData.schema;

        const { properties } = endpointSchema;
        this.form.endpointData = {
            ...endpointData,
            schema: {
                ...endpointSchema,
                properties: Object.keys(properties).map((propertyId) => {
                    const property = properties[propertyId];
                    return {
                        id: propertyId,
                        ...property,
                        required: (endpointSchema.required || []).includes(
                            propertyId
                        ),
                    };
                }),
            },
        };
    };

    const onBeforeOpen = async () => {
        initDataFromConfig();
        this.registerCustomDialog(propertyDialog);
        const { siteId } = getParams();
        updateSiteIdInputValues('siteIdInput');
        updateEndpointIdInputValues('endpointIdInput', siteId);
    };

    const onChangeSiteId = async (siteId: string) => {
        const firstEndpointId = Object.keys(
            this.remoteMetaInfo.sites[siteId].endpoints
        )[0];
        this.form.setDataSourceValue(
            'url.params.endpointId.replace',
            firstEndpointId
        );
        updateEndpointIdInputValues('endpointIdInput', siteId);
    };

    const onChangeEndpointId = (endpointId: string) => {};

    const handleAddProperty = (data: any) => {
        this.form.endpointData.schema.properties = [
            ...this.form.endpointData.schema.properties,
            data,
        ];

        this.form.closeDialog();
    };

    const onAddProperty = () => {
        this.form.pushDialog('propertyDialog', {
            isNewProperty: true,
            onSave: handleAddProperty,
        });
    };

    const handleChangeProperty = (data: any) => {
        this.form.endpointData.schema.properties =
            this.form.endpointData.schema.properties.map((item: any) => {
                if (item.id !== data.id) {
                    return item;
                }
                return data;
            });
        this.form.closeDialog();
    };

    const onOpenPropertyDialog = (item: any) => {
        this.form.pushDialog('propertyDialog', {
            isNewProperty: false,
            schema: item,
            onSave: handleChangeProperty,
        });
    };

    const onSave = async () => {
        const { siteId } = getParams();
        const siteMetaInfo = this.remoteMetaInfo.sites[siteId];
        const endpointData = {
            ...this.form.endpointData,
            schema: {
                ...this.form.endpointData.schema,
                properties: this.form.endpointData.schema.properties.reduce(
                    (acc: any, propertyData: any) => {
                        const data = {
                            ...propertyData,
                            required: undefined,
                        };
                        if (!data.enum || data.enum.length === 0) {
                            delete data.enum;
                        }
                        delete data.id;
                        acc[propertyData.id] = data;
                        return acc;
                    },
                    {}
                ),
                required: this.form.endpointData.schema.properties
                    .filter((item: any) => item.required)
                    .map((item: any) => item.id),
            },
        };
        addDefaultValuesToEndpointData(endpointData);
        const updateObject = getSiteUpdateObject(
            getSiteDataFromMetaInfo(siteMetaInfo),
            endpointData
        );
        this.form.views.fieldsGroup.isLoading = true;
        try {
            await apiClient.updateSiteSchema(updateObject);
            this.form.notify({
                text: 'Success',
                lifetimeMs: 3000,
            });
            window.location.reload();
        } catch (e) {
            this.form.views.fieldsGroup.isLoading = false;
            this.form.notify({
                type: 'error',
                text: 'Save error',
                lifetimeMs: 3000,
            });
        }
    };

    const onDeleteProperty = (item: any) => {
        this.form.endpointData.schema.properties =
            this.form.endpointData.schema.properties.filter(
                (propertyData: any) => propertyData.id !== item.id
            );
    };

    const onDeleteEndpoint = () => {
        const { siteId, endpointId } = getParams();
        const deleteEndpoint =
            (siteId: string, endpointId: string) => async () => {
                this.form.views.fieldsGroup.isLoading = true;
                try {
                    await apiClient.deleteEndpoint(siteId, endpointId);
                    this.form.notify({
                        text: 'Delete success',
                        lifetimeMs: 3000,
                    });
                    this.form.url.replace(`/${siteId}`);
                    window.location.reload();
                } catch (e) {
                    this.form.notify({
                        type: 'error',
                        text: 'Delete error',
                        lifetimeMs: 3000,
                    });
                }
            };
        this.form.pushDialog('yesno', {
            text: `Are you sure you want to delete "${siteId}/${endpointId}" endpoint?`,
            yesLabel: 'Delete',
            yesAction: deleteEndpoint(siteId, endpointId),
            noLabel: 'Cancel',
        });
    };

    return {
        onBeforeOpen,
        onChangeSiteId,
        onChangeEndpointId,
        onAddProperty,
        onOpenPropertyDialog,
        onSave,
        onDeleteProperty,
        onDeleteEndpoint,
    };
};

export default handlers;
