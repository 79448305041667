import {
    AppContext,
    VStack,
} from '../../../../../components/form/context/types';
import ViewTypes from '../../../../../components/form/ViewTypes';
import CronBuilder from './cron-builder';
import style from './style.module.scss';

const content: VStack = {
    id: 'rootGroup',
    type: ViewTypes.VStack,
    items: [
        {
            id: 'dialogTitle',
            type: ViewTypes.Text,
            value: 'Add job script to queue',
            className: style.title,
        },
        {
            id: 'fieldsGroup',
            type: ViewTypes.VStack,
            className: style.fieldsGroup,
            items: [
                {
                    id: 'attempts',
                    type: ViewTypes.NumberInput,
                    label: 'Attempts',
                    dataSource: 'data.opts.attempts',
                },
                {
                    id: 'delay',
                    type: ViewTypes.NumberInput,
                    label: 'Delay',
                    dataSource: 'data.opts.delay',
                },
                {
                    id: 'timeout',
                    type: ViewTypes.NumberInput,
                    label: 'Timeout (milliseconds)',
                    dataSource: 'data.opts.timeout',
                },
                {
                    id: 'removeOnComplete',
                    type: ViewTypes.BooleanInput,
                    label: 'Remove on complete',
                    dataSource: 'data.opts.removeOnComplete',
                },
                {
                    id: 'removeOnFail',
                    type: ViewTypes.BooleanInput,
                    label: 'Remove on fail',
                    dataSource: 'data.opts.removeOnFail',
                },
                {
                    id: 'repeatType',
                    type: ViewTypes.EnumInput,
                    label: 'Repeat',
                    dataSource: 'repeatType',
                    values: [
                        {
                            label: 'Cron',
                            value: 'cron',
                        },
                        {
                            label: 'Every Milliseconds',
                            value: 'every',
                        },
                    ],
                },
                {
                    id: 'cronGroup',
                    type: ViewTypes.VStack,
                    isHidden(this: AppContext) {
                        return this.form.repeatType !== 'cron';
                    },
                    items: [
                        {
                            id: 'repeatStartDate',
                            type: ViewTypes.DateInput,
                            label: 'Start date',
                            dataSource: 'data.opts.repeat.startDate',
                        },
                        {
                            id: 'repeatEndDate',
                            type: ViewTypes.DateInput,
                            label: 'End date',
                            dataSource: 'data.opts.repeat.endDate',
                        },
                        {
                            id: 'cronInput',
                            type: ViewTypes.TextInput,
                            label: 'Cron raw',
                            dataSource: 'data.opts.repeat.cron',
                        },
                        {
                            id: 'cronBuilder',
                            type: ViewTypes.CustomComponent,
                            component: CronBuilder,
                            dataSource: 'data.opts.repeat.cron',
                            onChange: 'onChangeCron',
                        },
                    ],
                },
                {
                    id: 'everyGroup',
                    type: ViewTypes.VStack,
                    isHidden(this: AppContext) {
                        return this.form.repeatType !== 'every';
                    },
                    items: [
                        {
                            id: 'every',
                            type: ViewTypes.NumberInput,
                            label: 'Every (Millisecond)',
                            dataSource: 'data.opts.repeat.every',
                        },
                        {
                            id: 'count',
                            type: ViewTypes.NumberInput,
                            label: 'Count',
                            dataSource: 'data.opts.repeat.count',
                        },
                        {
                            id: 'limit',
                            type: ViewTypes.NumberInput,
                            label: 'Limit',
                            dataSource: 'data.opts.repeat.limit',
                        },
                    ],
                },
            ],
        },
        {
            id: 'footer',
            type: ViewTypes.HStack,
            items: [
                {
                    id: 'applyButton',
                    type: ViewTypes.Button,
                    text: 'Apply',
                    onClick: 'onContinue',
                    style: { marginLeft: 15 },
                },
                {
                    id: 'cancelButton',
                    type: ViewTypes.Button,
                    role: 'notActive',
                    text: 'Cancel',
                    onClick: 'onClose',
                },
            ],
        },
    ],
};

export default content;
