import React from 'react';
import { Redirect, Route } from 'react-router-dom';
import propsWithDefaultValues from '../../../utils/propsWithDefaultValues';
import PropsPublicRoute, { defaultProps } from './props';

const PublicRoute = (props: PropsPublicRoute) => {
    props = propsWithDefaultValues(props, defaultProps);
    const {
        component: Component,
        withParams,
        redirect,
        store,
        ...rest
    } = props;
    let { redirectTo } = props;
    const { isLoggedIn } = store.account.state;
    return (
        <Route
            {...rest}
            render={(props) => {
                if (withParams) {
                    redirectTo += rest.location.search;
                }

                if (isLoggedIn) {
                    return (
                        <Redirect
                            to={{
                                pathname: redirectTo,
                                state: { from: props.location },
                            }}
                        />
                    );
                }

                const redirectData =
                    redirect && redirect(store, props.location);

                if (redirectData) {
                    return <Redirect to={redirectData} />;
                }

                return <Component {...rest} {...props} />;
            }}
        />
    );
};

export default PublicRoute;
