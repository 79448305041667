import { VStack } from '../../../../../components/form/context/types';
import ViewTypes from '../../../../../components/form/ViewTypes';
import style from './style.module.scss';

const content: VStack = {
    id: 'rootGroup',
    type: ViewTypes.VStack,
    items: [
        {
            id: 'dialogTitle',
            type: ViewTypes.Text,
            value: 'Job',
            className: style.title,
        },
        {
            id: 'fieldsGroup',
            type: ViewTypes.VStack,
            className: style.fieldsGroup,
            items: [
                {
                    id: 'id',
                    type: ViewTypes.TextInput,
                    label: 'Id',
                    isDisabled: true,
                    dataSource: 'params.job.id',
                },
                {
                    id: 'name',
                    type: ViewTypes.TextInput,
                    label: 'Name',
                    isDisabled: true,
                    dataSource: 'params.job.name',
                },
                {
                    id: 'attemptsMade',
                    type: ViewTypes.NumberInput,
                    label: 'Attempts made',
                    isDisabled: true,
                    dataSource: 'params.job.attemptsMade',
                },
                {
                    id: 'delay',
                    type: ViewTypes.NumberInput,
                    label: 'Delay',
                    isDisabled: true,
                    dataSource: 'params.job.delay',
                },
                {
                    id: 'progress',
                    type: ViewTypes.NumberInput,
                    label: 'Progress',
                    isDisabled: true,
                    dataSource: 'params.job.progress',
                },
                {
                    id: 'processedOn',
                    type: ViewTypes.DateInput,
                    label: 'Processed On',
                    isDisabled: true,
                    dataSource: 'params.job.processedOn',
                },
                {
                    id: 'finishedOn',
                    type: ViewTypes.DateInput,
                    label: 'Finished On',
                    isDisabled: true,
                    dataSource: 'params.job.finishedOn',
                },
                {
                    id: 'failedReason',
                    type: ViewTypes.TextInput,
                    label: 'Failed reason',
                    isDisabled: true,
                    isMultiLine: true,
                    dataSource: 'params.job.failedReason',
                },
                {
                    id: 'logsTitle',
                    type: ViewTypes.Text,
                    value: 'Logs',
                    className: style.title,
                },
                {
                    id: 'logsGroup',
                    type: ViewTypes.VStack,
                    items: [],
                },
            ],
        },
        {
            id: 'footer',
            type: ViewTypes.HStack,
            items: [
                {
                    id: 'cancelButton',
                    type: ViewTypes.Button,
                    role: 'notActive',
                    text: 'Cancel',
                    onClick: 'onClose',
                },
            ],
        },
    ],
};

export default content;
