import React, { useCallback } from 'react';
import { getValue } from '../../../utils/context';
import SearchInputNative from '../../native/search-input';
import { useAppContextObserver } from '../context';
import PropsSearchInput from './props';

const SearchInput = (props: PropsSearchInput) => {
    const { placeholder, dataSource, onSearch } = props;
    const [{ value, isHidden, isDisabled }, getContext] = useAppContextObserver(
        (context) => {
            let { value } = props;
            if (dataSource) {
                value = context.form.getDataSourceValue(dataSource);
            }

            return {
                value,
                isHidden: getValue(props.isHidden, context),
                isDisabled: getValue(props.isDisabled, context),
            };
        }
    );

    const handleSearch = useCallback(
        (value: string) => {
            if (dataSource) {
                getContext().form.setDataSourceValue(dataSource, value);
            }

            if (!onSearch) {
                return;
            }

            if (typeof onSearch === 'string') {
                (getContext().form.handlers as any)[onSearch](value);
                return;
            }

            onSearch.call(getContext(), value);
        },
        [getContext, dataSource, onSearch]
    );

    if (isHidden) {
        return null;
    }

    return (
        <SearchInputNative
            // disabled={isDisabled}
            placeholder={placeholder}
            value={value ?? ''}
            onSearch={handleSearch}
            isDisabled={isDisabled}
        />
    );
};

export default React.memo(SearchInput);
