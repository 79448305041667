import ViewTypes from '../ViewTypes';
import { ContainerView, TabPage, TabsGroup, View, ViewId } from './types';

export default function getViewsTreeItems(
    node: View,
    output?: {
        views: Record<ViewId, View>;
    }
): { views: Record<ViewId, View> } {
    if (!output) {
        output = {
            views: {},
        };
    }

    if (!node) {
        return output!;
    }

    switch (node.type) {
        case ViewTypes.HStack:
        case ViewTypes.VStack:
            (node as ContainerView).items.forEach((item) => {
                getViewsTreeItems(item, output);
                output!.views[item.id] = item;
            });
            break;

        case ViewTypes.TabsGroup:
            (node as TabsGroup).tabs.forEach((item) => {
                getViewsTreeItems(item as any, output);
                getViewsTreeItems(item.content, output);
            });
            break;

        case ViewTypes.TabPage:
            getViewsTreeItems((node as TabPage).content, output);
            break;
        default:
            break;
    }

    output.views[node.id] = node;
    return output;
}

export function updateViewsTree(
    node: View,
    updater: (view: View) => View
): View {
    if (!node) {
        return node;
    }

    switch (node.type) {
        case ViewTypes.HStack:
        case ViewTypes.VStack: {
            const updatedItems: View[] = (node as ContainerView).items.map(
                (item) => updateViewsTree(item, updater)
            );

            const isItemsChanded =
                updatedItems.length > 0 &&
                (node as ContainerView).items.some(
                    (item, index) => updatedItems[index] !== item
                );

            if (isItemsChanded) {
                node = {
                    ...node,
                    items: updatedItems,
                } as any;
            }

            break;
        }

        case ViewTypes.TabsGroup: {
            const updatedTabs = (node as TabsGroup).tabs.map((item) => {
                const updatedContent = updateViewsTree(item.content, updater);
                return updateViewsTree(
                    updatedContent
                        ? { ...item, content: updatedContent }
                        : item,
                    updater
                );
            });

            const isTabsChanged =
                updatedTabs.length > 0 &&
                (node as TabsGroup).tabs.some(
                    (item, index) => updatedTabs[index] !== item
                );

            if (isTabsChanged) {
                node = {
                    ...node,
                    tabs: updatedTabs,
                } as any;
            }
            break;
        }

        case ViewTypes.TabPage: {
            const updatedContent = updateViewsTree(
                (node as TabPage).content,
                updater
            );
            if (updatedContent !== (node as TabPage).content) {
                node = {
                    ...node,
                    content: updatedContent,
                } as any;
            }
            break;
        }
        default:
            break;
    }

    return updater(node);
}
