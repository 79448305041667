export function sortIds(
    ids: string[],
    order?: 'asc' | 'desc' | 'remote' | string[]
): string[] {
    let result: string[];
    order = order || 'remote';

    if (typeof order === 'string') {
        result = ids.slice();
        if (order === 'asc') {
            result.sort();
        } else if (order === 'desc') {
            result.reverse();
        }
    } else if (Array.isArray(order)) {
        result = order.slice();
        ids.forEach((id) => {
            if (!result.includes(id)) {
                result.push(id);
            }
        });
    } else {
        throw new Error('Wrong order type');
    }

    return result;
}

export const getFileExtension = (name: string): string => {
    const splitted = name.split('.');
    return splitted[splitted.length - 1]?.toLowerCase();
};
