import { CustomEndpointConfig } from '../../../../core/config-builder/types';

const gazarteavotes: CustomEndpointConfig = {
    metaInfo: {
        properties: {
            gazarteavotesCreatedAt: {
                name: 'Created At',
            },
            gazarteavotesUpdatedAt: {
                name: 'Updated At',
            },
            gazarteaprofileId: {
                name: 'Profile',
                type: 'string',
                format: 'reference',
                siteId: 'cbc',
                endpointId: 'gazarteaprofile',
                idField: '_id',
                sortListByField: '_id',
                searchByField: ['_id'],
                representationDataSource: '_id',
            },
            gazarteavotesVoterProfileId: {
                name: 'Voter',
                type: 'string',
                format: 'reference',
                siteId: 'cbc',
                endpointId: 'gazarteaprofile',
                idField: '_id',
                sortListByField: '_id',
                searchByField: ['_id'],
                representationDataSource: '_id',
            },
            gazarteavotesValue: {
                name: 'Is Liked',
            },
        },
        propertiesOrder: [
            'gazarteavotesCreatedAt',
            'gazarteavotesUpdatedAt',
            'gazarteavotesValue',
            'gazarteaprofileId',
            'gazarteavotesVoterProfileId',
        ],
    },
    forms: {
        item: {
            views: {
                gazarteaprofileCreatedAt: {
                    isDisabled: true,
                },

                gazarteaprofileUpdatedAt: {
                    isDisabled: true,
                },
            },
        },
    },
};

export default gazarteavotes;
