import { CustomSiteConfig } from '../../../core/config-builder/types';
import dashboard from './dashboard';
import flappyplayer from './flappyplayer';
import flappyscore from './flappyscore';
import food from './food';
import foodmedia from './foodmedia';
import gazarteaprofile from './gazarteaprofile';
import gazarteavotes from './gazarteavotes';
import order from './order';
import points from './points';
import prize from './prize';
import profile from './profile';
import redeem from './redeem';
import shiftzur from './shiftzur';
import stage from './stage';
import stagemedia from './stagemedia';
import surveyanswer from './surveyanswer';
import surveyquestion from './surveyquestion';
import surveyuseranswer from './surveyuseranswer';
import wordlesuggestion from './wordlesuggestion';
import yesnoanswer from './yesnoanswer';
import yesnoquestion from './yesnoquestion';

const cbc: CustomSiteConfig = {
    title: 'ממשק פיוזטי',
    dashboard,
    endpointsOrder: [
        'profile',
        'order',
        'redeem',
        'carousel',
        'prize',
        'instagram',
        'shiftzur',
        'food',
        'foodmedia',
        'stage',
        'stagemedia',
        'stagemedialike',
        'points',
        'yesnoquestion',
        'yesnoanswer',
        'gazarteaprofile',
        'gazarteavotes',
        'wordlesuggestion',
    ],
    endpoints: {
        foodmedia,
        surveyquestion,
        surveyuseranswer,
        surveyanswer,
        redeem,
        food,
        prize,
        shiftzur,
        flappyplayer,
        flappyscore,
        profile,
        order,
        stagemedia,
        points,
        stage,
        yesnoquestion,
        yesnoanswer,
        gazarteaprofile,
        gazarteavotes,
        wordlesuggestion,
    },
};

export default cbc;
