import { Observable } from '../../../globalStore/definition';

export default interface PropsPrivateRoute {
    component: React.FC<any>;
    redirectTo?: string;
    withParams?: boolean;
    redirect?: (store: Observable, location: any) => any;
    store: Observable;
    [key: string]: any;
}

export const defaultProps: Partial<PropsPrivateRoute> = {
    redirectTo: '/login',
    withParams: false,
};
