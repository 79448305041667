import { AppContext } from '../../../../../../core/components/form/context/types';
import {
    ListFormFunctionGetHandlers,
    ListFormHandlers,
} from '../../../../../../core/components/list-form/types';

const handlers: ListFormFunctionGetHandlers = function (
    this: AppContext
): ListFormHandlers {
    const onToggleActiveFlag = async (item: any) => {
        this.form.notify(
            {
                text: 'Updating...',
            },
            'STAGEMEDIA_ACTIVE_UPDATE'
        );

        try {
            await this.api.update(`/cbc/stagemedia/${item.id}`, {
                active: !item.active,
            });
        } catch (e) {
            this.form.notify(
                {
                    type: 'error',
                    text: 'Some error happened',
                },
                'STAGEMEDIA_ACTIVE_UPDATE'
            );

            if (this.form.views.table.weakCallbacks.update) {
                this.form.views.table.weakCallbacks.update();
            }
            return;
        }

        this.form.notify(
            {
                text: 'Success!',
                lifetimeMs: 3000,
            },
            'STAGEMEDIA_ACTIVE_UPDATE'
        );
        if (this.form.views.table.weakCallbacks.update) {
            this.form.views.table.weakCallbacks.update();
        }
    };

    const onFilterByUser = (item: any) => {
        // Here formContext
        this.form.url.push(
            `/cbc/stagemedia/list?filter.stageId.include[]=${item.stageId}&filter.userId.include[]=${item.userId}`
        );
    };

    return {
        onFilterByUser,
        onToggleActiveFlag,
    };
};

export default handlers;
