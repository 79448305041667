import { AppContext } from '../../../../core/components/form/context/types';
import { CustomEndpointConfig } from '../../../../core/config-builder/types';

const viralscript: CustomEndpointConfig = {
    title: 'מהלכים ויראלים',
    metaInfo: {
        propertiesOrder: [
            'title',
            'idea',
            'author',
            'productMatch',
            'productionPrice',
            'produced',
            'comments',
            'exampleUrl',
            'productUrl',
            'seenBy',
            'score',
            'createdAt',
        ],
        properties: {
            title: {
                name: 'שם הרעיון',
            },
            idea: {
                name: 'הרעיון',
            },
            author: {
                name: 'היוצר',
            },
            productMatch: {
                name: 'לאיזה מוצר מתאים',
            },
            productionPrice: {
                name: 'עלות הפקה',
            },
            produced: {
                name: 'האם הופק',
            },
            comments: {
                name: 'הערות',
            },
            exampleUrl: {
                name: 'קישור לדוגמה',
            },
            productUrl: {
                name: 'קישור לביצוע',
            },
            seenBy: {
                name: 'לאיזה לקוח הוצג',
            },
            score: {
                name: 'ציון',
            },
            createdAt: {
                name: 'תאריך הכנסת הקריאייטיב',
            },
        },
    },

    forms: {
        item: {
            properties: {},
        },
        list: {
            // content of the list form
            defaultUrlQueryParams: {
                sort: [
                    {
                        columnId: 'createdAt',
                        direction: 'desc',
                    },
                ],
            },
            columnsOrder: [
                'title',
                'idea',
                'author',
                'productMatch',
                'productionPrice',
                'produced',
                'comments',
                'exampleUrl',
                'productUrl',
                'seenBy',
                'score',
                'createdAt',
            ],
            columns: {
                title: {
                    title: 'שם הרעיון',
                },
                idea: {
                    title: 'הרעיון',
                    style: () => {
                        return { height: '45px', 'line-height': '45px' };
                    },
                    width: 500,
                    maxWidth: 1000,
                },
                author: {
                    title: 'היוצר',
                },
                productMatch: {
                    title: 'לאיזה מוצר מתאים',
                },
                productionPrice: {
                    title: 'עלות הפקה',
                },
                produced: {
                    title: 'האם הופק',
                },
                comments: {
                    title: 'הערות',
                    isHidden: true,
                },
                exampleUrl: {
                    title: 'קישור לדוגמה',
                    isHidden: true,
                },
                productUrl: {
                    title: 'קישור לביצוע',
                    isHidden: true,
                },
                seenBy: {
                    title: 'לאיזה לקוח הוצג',
                    isHidden: true,
                },
                score: {
                    title: 'ציון',
                },
                createdAt: {
                    title: 'תאריך הכנסת הקריאייטיב',
                },
            },
            handlers(this: AppContext) {
                return {
                    onBeforeOpen: async () => {
                        if (
                            !this.currentUserData.roles.includes('admin') &&
                            !this.currentUserData.roles.includes('officeAdmin')
                        )
                            this.form.commands = this.form.commands.filter(
                                (item) => item.id !== 'export'
                            );
                    },
                };
            },
            rowStyle: () => {
                return {
                    background: 'white',
                };
            },
        },
    },
};

export default viralscript;
