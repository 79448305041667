import React from 'react';
import activeSortAsc from '../../../../../../assets/icons/active-sort-arrow-asc.svg';
import activeSortDesc from '../../../../../../assets/icons/active-sort-arrow-desc.svg';
import notActiveSortAsc from '../../../../../../assets/icons/not-active-sort-arrow-asc.svg';
import notActiveSortDesc from '../../../../../../assets/icons/not-active-sort-arrow-desc.svg';
import PropsHeaderSort from './props';
import style from './style.module.scss';

const HeaderSort = ({
    columnId,
    sortDirection,
    onChangeSort,
}: PropsHeaderSort) => {
    if (sortDirection === 'asc') {
        return (
            <div
                className={style.headerSort}
                onClick={(e) => onChangeSort(columnId, 'desc', e.shiftKey)}
            >
                <img src={activeSortAsc} alt="" />
                <img src={notActiveSortDesc} alt="" />
            </div>
        );
    }

    if (sortDirection === 'desc') {
        return (
            <div
                className={style.headerSort}
                onClick={(e) => onChangeSort(columnId, 'asc', e.shiftKey)}
            >
                <img src={notActiveSortAsc} alt="" />
                <img src={activeSortDesc} alt="" />
            </div>
        );
    }

    return (
        <div
            className={style.headerSort}
            onClick={(e) => onChangeSort(columnId, 'asc', e.shiftKey)}
        >
            <img src={notActiveSortAsc} alt="" />
            <img src={notActiveSortDesc} alt="" />
        </div>
    );
};

export default React.memo(HeaderSort);
