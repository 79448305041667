import React from 'react';
import { Link } from 'react-router-dom';
import { getRepresentationForItem } from '../../../../../../utils';
import PropsCellReferenceArray from './props';
import style from './style.module.scss';

const ReferenceArrayCell = ({
    isPageReferencesLoading,
    column,
    item,
    referenceValue,
}: PropsCellReferenceArray) => {
    const { getLink, representationDataSource } = column;

    if (isPageReferencesLoading) {
        return <div className={style.cell}>Loading...</div>;
    }

    if (
        referenceValue === undefined ||
        referenceValue === null ||
        referenceValue.length === 0
    ) {
        return <div className={style.cell}>None</div>;
    }

    return (
        <div className={style.cell}>
            {referenceValue && referenceValue.length === 1 && getLink && (
                <span onClick={(e) => e.stopPropagation()}>
                    <Link to={getLink(referenceValue[0].id)}>
                        {referenceValue.map((item: any) =>
                            getRepresentationForItem(
                                item,
                                representationDataSource
                            )
                        )}
                    </Link>
                </span>
            )}
            {referenceValue && referenceValue.length > 1 && getLink && (
                <span onClick={(e) => e.stopPropagation()}>
                    <Link to={getLink(referenceValue)}>Multiple</Link>
                </span>
            )}
            {referenceValue &&
                referenceValue.length > 0 &&
                !getLink &&
                referenceValue
                    .map((item: any) =>
                        getRepresentationForItem(item, representationDataSource)
                    )
                    .join(', ')}
        </div>
    );
};

export default ReferenceArrayCell;
