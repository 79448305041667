import React from 'react';
import Lottie from 'react-lottie';
import animationData from '../../../../../../../assets/animations/loading.json';
import PropsQuickEditorWrapper from './props';
import style from './style.module.scss';

const lottieOptions = {
    loop: true,
    autoplay: true,
    animationData: animationData,
    rendererSettings: {
        preserveAspectRatio: 'xMidYMid slice',
    },
};

const QuickEditorWrapper = (
    props: React.PropsWithChildren<PropsQuickEditorWrapper>
) => {
    const {
        children,
        onMouseEnter,
        onMouseLeave,
        isProcessing,
        onStartQuickEdit,
        cellId,
    } = props;

    return (
        <div
            className={style.quickEditorWrapper}
            // style={{
            //     x: '50%',
            //     y: 0,
            // }}
            onMouseEnter={onMouseEnter}
            onMouseLeave={onMouseLeave}
            onClick={(e) => {
                e.stopPropagation();
                if (e.altKey) {
                    onStartQuickEdit(cellId);
                }
            }}
        >
            <div className={style.bubble}>
                <div className={style.content}>{children}</div>
                {isProcessing && (
                    <div className={style.processingStub}>
                        <Lottie
                            options={lottieOptions}
                            height={100}
                            width={100}
                        />
                    </div>
                )}
            </div>
        </div>
    );
};

export default React.memo(QuickEditorWrapper);
