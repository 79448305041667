import Dialog from '../../../components/dialog';
import { DialogData } from '../../../components/form/context/types';
import ViewTypes from '../../../components/form/ViewTypes';
import handlers from './handlers';
import style from './style.module.scss';

const deleteItem: DialogData = {
    id: 'deleteItem',
    component: Dialog,
    className: style.deleteDialog,
    handlers,
    content: {
        id: 'rootGroup',
        type: ViewTypes.VStack,
        items: [
            {
                id: 'text',
                type: ViewTypes.Text,
                value: 'Are you sure to delete items?',
                style: {
                    fontSize: 20,
                    fontWeight: 500,
                    flex: 1,
                    direction: 'ltr',
                },
            },
            {
                id: 'footer',
                type: ViewTypes.HStack,
                items: [
                    {
                        id: 'applyButton',
                        type: ViewTypes.Button,
                        text: 'Apply',
                        onClick: 'onApply',
                        style: { marginLeft: 15 },
                    },
                    {
                        id: 'cancelButton',
                        type: ViewTypes.Button,
                        role: 'notActive',
                        text: 'Cancel',
                        onClick: 'onClose',
                    },
                ],
            },
        ],
    },
};

export default deleteItem;
