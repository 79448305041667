import { useEffect, useState } from 'react';
import apiClient from '../../requests/api';
import { useAppContextObserver } from '../form/context';

const useCommentManagerState = (props: any) => {
    const { minisite, itemEndPoint, commentEndPointItemProperty } = props;

    const [comments, setComments] = useState<any>([]);
    const [isLoading, setIsLoading] = useState<any>(true);
    const [isDisabled, setIsDisabled] = useState<any>(false);
    const [commentText, setCommentText] = useState('');
    const [token, setToken] = useState('');
    const [{ objectId }, getContext] = useAppContextObserver((context: any) => {
        return context.form;
    });

    useEffect(() => {
        const login = async () => {
            const { token } = await apiClient.loginByEmail(
                'commenter@pazam.mobi',
                'KVOi4yZuL9etTlZDzg3U'
            );
            setToken(token);
        };
        login();
        loadComments();
    }, []);

    const loadComments = async () => {
        let q = `${commentEndPointItemProperty}:${objectId}`;

        const queryParams = `sort=createdAt:asc&q=${q}&size=500`;
        const response = await apiClient.callSearchRequest(
            minisite,
            itemEndPoint,
            queryParams
        );

        setComments(
            response.hits.hits.map((item) => {
                return { ...item._source, itemId: item._id };
            })
        );
        setIsLoading(false);
    };

    const sendComment = async () => {
        try {
            if (token) {
                const itemId = await apiClient.createTableItem(
                    minisite,
                    itemEndPoint,
                    {
                        commentText,
                        [`${commentEndPointItemProperty}`]: objectId,
                        profilePic:
                            'https://api.pazamapp.com/parse/files/pazam/cd899ce6502ccbccc77d7c9f1dd34deb_file_1643292462459.png',
                    },
                    token
                );

                setComments([
                    ...comments,
                    {
                        commentText,
                        commenterName: 'פז״מוזאורוס',
                        profilePic:
                            'https://api.pazamapp.com/parse/files/pazam/cd899ce6502ccbccc77d7c9f1dd34deb_file_1643292462459.png',
                        itemId,
                    },
                ]);
                setToken(token);
                setCommentText('');
                setIsDisabled(false);
            }
        } catch {
            setIsDisabled(false);
            return;
        }
    };

    const deleteComment = async (objectId: string) => {
        try {
            if (token) {
                await apiClient.deleteTableItem(
                    minisite,
                    itemEndPoint,
                    objectId,
                    token
                );
                const filteredComments = comments.filter((comment: any) => {
                    return comment.itemId !== objectId;
                });

                setComments(filteredComments);
            }
        } catch {
            return;
        }
    };

    return {
        comments,
        sendComment,
        isLoading,
        commentText,
        setCommentText,
        isDisabled,
        setIsDisabled,
        deleteComment,
    };
};

export default useCommentManagerState;
