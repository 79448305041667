import { CSSProperties } from 'react';
import { ValueOrHandler, View } from '../context/types';
import style from './style.module.scss';

interface PropsHStack {
    id: string;
    items: View[];
    style?: CSSProperties;
    className?: string;
    isHidden?: ValueOrHandler<boolean>;
    isLoading?: ValueOrHandler<boolean>;
    progressText?: string;
}

export default PropsHStack;

export const defaultProps: Partial<PropsHStack> = {
    className: style.horizontalGroup,
    isLoading: false,
};
