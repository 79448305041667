import React from 'react';
import propsWithDefaultValues from '../../../../../../utils/propsWithDefaultValues';
import DateInput from '../../../../date-input';
import HeaderSort from '../header-sort';
import useDateFilterState from './hooks';
import PropsDateFilter, { defaultProps } from './props';
import style from './style.module.scss';

const DateFilter = (props: PropsDateFilter) => {
    props = propsWithDefaultValues(props, defaultProps);
    const {
        isOpened,
        onToggleOpen,
        sortDirection,
        isSortable,
        isRightSide,
        onChangeSort,
        columnId,
    } = props;

    const {
        isFiltered,
        filteredValue,
        startMoment,
        endMoment,
        menuClass,
        onClear,
        onApply,
        onChangeField,
    } = useDateFilterState(props);

    return (
        <div
            className={style.dateFilter}
            data-is-right-side={String(!!isRightSide)}
        >
            <div className={style.currentValueContainer}>
                {isSortable && (
                    <HeaderSort
                        columnId={columnId}
                        sortDirection={sortDirection}
                        onChangeSort={onChangeSort}
                    />
                )}
                <div
                    className={
                        isOpened ? style.currentValueOpened : style.currentValue
                    }
                    onClick={() => onToggleOpen(columnId)}
                >
                    {isFiltered && (
                        <span className={style.filtered}>{filteredValue}</span>
                    )}
                    {!isFiltered && 'All'}
                </div>
            </div>
            {isOpened && (
                <div
                    className={menuClass}
                    data-is-right-side={String(!!isRightSide)}
                >
                    <div className={style.dates}>
                        <DateInput
                            id="start-date-filter"
                            label="Start date"
                            value={startMoment}
                            onChange={onChangeField('from')}
                        />
                        <DateInput
                            id="end-date-filter"
                            label="End date"
                            value={endMoment}
                            onChange={onChangeField('to')}
                        />
                    </div>

                    <div className={style.buttons}>
                        <div className={style.applyButton} onClick={onApply}>
                            Apply
                        </div>
                        <div
                            className={style.cancelButton}
                            onClick={() => onToggleOpen(columnId)}
                        >
                            Cancel
                        </div>
                        <div className={style.cancelButton} onClick={onClear}>
                            Clear
                        </div>
                    </div>
                </div>
            )}
        </div>
    );
};

export default React.memo(DateFilter);
