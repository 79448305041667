import ViewTypes from '../../../../components/form/ViewTypes';
import handlers from './handlers';
import style from './style.module.scss';

export default {
    id: 'settingsTabForm',
    type: 'Form',
    handlers,
    content: {
        id: 'rootGroup',
        type: ViewTypes.VStack,
        items: [
            {
                id: 'tabContentGroup',
                type: ViewTypes.VStack,
                style: { padding: 15, flex: 1 },
                items: [
                    {
                        id: 'commandPanel',
                        type: ViewTypes.HStack,
                        style: { zIndex: 1500, flexWrap: 'wrap' },
                        items: [
                            {
                                id: 'siteIdInput',
                                type: ViewTypes.EnumInput,
                                style: {
                                    zIndex: 1500,
                                    maxWidth: 240,
                                    minWidth: 240,
                                    marginLeft: 10,
                                },
                                dataSource: 'url.params.siteId.replace',
                                label: 'Site',
                                onChangeValue: 'onChangeSiteId',
                                values: [],
                            },
                            {
                                id: 'endpointIdInput',
                                type: ViewTypes.EnumInput,
                                label: 'Endpoint',
                                style: {
                                    zIndex: 1000,
                                    maxWidth: 240,
                                    minWidth: 240,
                                    marginLeft: 30,
                                },
                                dataSource: 'url.params.endpointId.replace',
                                onChangeValue: 'onChangeEndpointId',
                                values: [],
                            },
                        ],
                    },
                    {
                        id: 'fieldsGroup',
                        type: ViewTypes.VStack,
                        className: style.content,
                        items: [
                            {
                                id: 'title',
                                type: ViewTypes.TextInput,
                                label: 'Title',
                                ltr: true,
                                isMultiLine: false,
                                dataSource: 'endpointData.schema.title',
                            },
                            {
                                id: 'anonymousType',
                                type: ViewTypes.EnumInput,
                                label: 'Anonymous permissions',
                                dataSource: 'endpointData.anonymousType',
                                values: [
                                    {
                                        value: 'block',
                                        label: 'block',
                                    },
                                    {
                                        value: 'read',
                                        label: 'read',
                                    },
                                    {
                                        value: 'write',
                                        label: 'write',
                                    },
                                    {
                                        value: 'readwrite',
                                        label: 'readwrite',
                                    },
                                ],
                            },
                            {
                                id: 'userPermissions',
                                type: ViewTypes.EnumInput,
                                label: 'User permissions',
                                isMultiple: true,
                                dataSource: 'endpointData.constraintMethods',
                                values: [
                                    {
                                        value: 'get',
                                        label: 'get',
                                    },
                                    {
                                        value: 'post',
                                        label: 'post',
                                    },
                                    {
                                        value: 'put',
                                        label: 'put',
                                    },
                                    {
                                        value: 'delete',
                                        label: 'delete',
                                    },
                                ],
                            },
                            {
                                id: 'libs',
                                type: ViewTypes.EnumInput,
                                label: 'Scripting Extensions',
                                isMultiple: true,
                                dataSource: 'endpointData.libs',
                                values: [
                                    {
                                        value: 'nayax',
                                        label: 'nayax',
                                    },
                                    {
                                        value: 'sms',
                                        label: 'sms',
                                    },
                                    {
                                        value: 'cache',
                                        label: 'cache',
                                    },
                                    {
                                        value: 'email',
                                        label: 'email',
                                    },
                                    {
                                        value: 'pazam',
                                        label: 'pazam',
                                    },
                                ],
                            },
                            {
                                id: 'description',
                                type: ViewTypes.TextInput,
                                label: 'Description',
                                ltr: true,
                                isMultiLine: true,
                                dataSource: 'endpointData.schema.description',
                            },
                            {
                                id: 'propertiesTablePanel',
                                type: ViewTypes.HStack,
                                className: style.propertiesTablePanel,
                                items: [
                                    {
                                        id: 'propertiesTableTitle',
                                        type: ViewTypes.Text,
                                        value: 'Properties',
                                        className: style.propertiesTableTitle,
                                    },
                                    {
                                        id: 'addPropertyButton',
                                        type: ViewTypes.Button,
                                        onClick: 'onAddProperty',
                                        text: 'Add Property',
                                    },
                                ],
                            },
                            {
                                id: 'propertiesTable',
                                type: ViewTypes.MemoryTable,
                                pageSize: 1000,
                                onClickItem: 'onOpenPropertyDialog',
                                dataSource: 'endpointData.schema.properties',
                                columns: [
                                    {
                                        id: 'id',
                                        fieldName: 'id',
                                        type: 'text',
                                        title: 'Id',
                                        width: 200,
                                    },
                                    {
                                        id: 'required',
                                        fieldName: 'required',
                                        type: 'boolean',
                                        title: 'Required',
                                        width: 200,
                                    },
                                    {
                                        id: 'title',
                                        fieldName: 'title',
                                        type: 'text',
                                        title: 'Title',
                                        width: 200,
                                    },
                                    {
                                        id: 'type',
                                        fieldName: 'type',
                                        type: 'text',
                                        title: 'Type',
                                        width: 200,
                                    },
                                    {
                                        id: 'format',
                                        fieldName: 'format',
                                        type: 'text',
                                        title: 'Format',
                                        width: 200,
                                    },
                                    {
                                        id: 'default',
                                        fieldName: 'default',
                                        type: 'text',
                                        title: 'Default Value',
                                    },
                                    {
                                        id: 'delete',
                                        fieldName: 'delete',
                                        type: 'button',
                                        title: '',
                                        text: 'Delete',
                                        onClick: 'onDeleteProperty',
                                    },
                                ],
                            },
                        ],
                    },
                    {
                        id: 'footer',
                        type: ViewTypes.HStack,
                        className: style.commandPanel,
                        items: [
                            {
                                id: 'saveButton',
                                type: ViewTypes.Button,
                                role: 'active',
                                text: 'Save',
                                onClick: 'onSave',
                            },
                            {
                                id: 'cancelButton',
                                type: ViewTypes.Button,
                                role: 'notActive',
                                text: 'Cancel',
                                onClick: 'onGoBack',
                            },
                            {
                                id: 'spacer1',
                                type: ViewTypes.HStack,
                                style: { flex: 1 },
                                items: [],
                            },
                            {
                                id: 'deleteButton',
                                type: ViewTypes.Button,
                                role: 'notActive',
                                text: 'Delete',
                                onClick: 'onDeleteEndpoint',
                            },
                        ],
                    },
                ],
            },
        ],
    },
};
