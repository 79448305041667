import { AppContext } from '../../../components/form/context/types';
import { convertToQueryFilter } from '../../../components/native/table/filter';
import apiClient from '../../../requests/api';
import { _ListFormHandlers } from './types';

const handlers = function (this: AppContext): _ListFormHandlers {
    const onDeleteSuccess = () => {
        this.form.selection = 'none';

        this.form.views.table.weakCallbacks.update &&
            this.form.views.table.weakCallbacks.update();
    };

    const onOpenDeleteItemsDialog = () => {
        const { selection } = this.form;
        const { search, filter: tableFilter } = this.form.url.queryParams;
        const { siteId, endpointId } = this.form.url.params;

        if (selection === 'none') {
            this.form.notify({
                text: 'No selected items',
                type: 'error',
                lifetimeMs: 2000,
            });
            return;
        }

        const filter = convertToQueryFilter(
            this.form.views.table.columns,
            tableFilter || []
        );

        this.form.pushDialog('deleteItem', {
            siteId,
            endpointId,
            selection,
            filter,
            searchPhrase: search,
            onSuccess: onDeleteSuccess,
        });
    };

    const onOpenExportDialog = () => {
        const { selection } = this.form;
        const { siteId, endpointId } = this.form.url.params;
        const { search, filter: tableFilter } = this.form.url.queryParams;

        if (selection === 'none') {
            this.form.notify({
                text: 'No selected items',
                type: 'error',
                lifetimeMs: 2000,
            });
            return;
        }

        const filter = convertToQueryFilter(
            this.form.views.table.columns,
            tableFilter || []
        );

        this.form.pushDialog('export', {
            siteId,
            endpointId,
            selection,
            filter,
            searchPhrase: search,
        });
    };

    const onOpenImportDialog = () => {
        const { siteId, endpointId } = this.form.url.params;

        this.form.pushDialog('import', {
            siteId,
            endpointId,
        });
    };

    const onOpenEndpointConfigEditor = () => {
        const { siteId, endpointId } = this.form.url.params;
        this.form.url.push(
            `/${siteId}/${endpointId}/endpoint-config-editor/settings`
        );
    };

    const onCreateNewItem = () => {
        const { siteId, endpointId } = this.form.params;
        this.form.url.push(`/${siteId}/${endpointId}/item`);
    };

    const onDeleteItemFromContextMenu = (item: any) => {
        const { siteId, endpointId } = this.form.url.params;

        this.form.pushDialog('deleteItem', {
            siteId,
            endpointId,
            selection: { include: [item.id] },
            onSuccess: onDeleteSuccess,
        });
    };

    const onUpdateItemField = async ({
        item,
        column,
        value,
        prevValue,
    }: any) => {
        const { siteId, endpointId } = this.form.url.params;
        const data = {
            [column.fieldName]: value,
        };

        await apiClient.updateTableItem(siteId, endpointId, item.id, data);
    };

    const onRefreshTable = () => {
        this.form.views.table.weakCallbacks.update &&
            this.form.views.table.weakCallbacks.update(true);
    };

    window._refreshList = () => {
        this.form &&
            this.form.views &&
            this.form.views.table &&
            this.form.views.table.weakCallbacks.update &&
            this.form.views.table.weakCallbacks.update(false);
    };

    const onBeforeClose = () => {
        window._refreshList = undefined;
    };

    return {
        onOpenDeleteItemsDialog,
        onOpenExportDialog,
        onOpenImportDialog,
        onOpenEndpointConfigEditor,
        onCreateNewItem,
        onDeleteItemFromContextMenu,
        onUpdateItemField,
        onRefreshTable,
        onBeforeClose,
    };
};

export default handlers;
