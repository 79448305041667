import { AppContext } from '../../../../core/components/form/context/types';

export default {
    title: 'סבבים',
    metaInfo: {
        propertiesOrder: ['name', 'startAt', 'endAt', 'active'],
        properties: {
            name: {
                name: 'שם הסבב',
            },
            startAt: {
                name: 'תאריך התחלה',
            },
            endAt: {
                name: 'תאריך סיום',
            },
            active: {
                name: 'פעיל',
            },
        },
    },

    forms: {
        list: {
            // content of the list form
            defaultUrlQueryParams: {
                sort: [
                    {
                        columnId: 'startAt',
                        direction: 'desc',
                    },
                ],
            },
            columnsOrder: ['name', 'startAt', 'endAt', 'active'],
            columns: {
                name: {
                    title: 'שם הסבב',
                },
                startAt: {
                    title: 'תאריך התחלה',
                },
                endAt: {
                    title: 'תאריך סיום',
                },
                active: {
                    title: 'פעיל',
                },
            },
            handlers(this: AppContext) {
                return {
                    onBeforeOpen: async () => {
                        this.form.commands = this.form.commands.filter(
                            (item) => item.id !== 'export'
                        );
                    },
                };
            },
            rowStyle(this: AppContext) {
                return {
                    background: 'white',
                };
            },
        },
    },
};
