import { AppContext } from '../../../../core/components/form/context/types';
import CellText from '../../../../core/components/native/table/body/row/cell-text';
import { CustomEndpointConfig } from '../../../../core/config-builder/types';
import { getUserName } from '../../commonFunctions';

const categories = [
    'לוחמה',
    'תומכים וג׳וב',
    'סוגרים בבסיס',
    'ברים',
    'ים',
    'לאמיצים בלבד',
    'רק לבנות',
];

categories.sort();

const yesnoquestion: CustomEndpointConfig = {
    metaInfo: {
        properties: {
            questionCreatedAt: {
                name: 'Created At',
            },
            yesnoQuestionUserId: {
                name: 'User',
                type: 'string',
                format: 'reference',
                siteId: 'cbc',
                endpointId: 'profile',
                idField: 'userId',
                sortListByField: 'firstname',
                searchByField: ['email', 'firstname', 'lastname', 'phone'],
                representationDataSource: getUserName,
            },
            categories: {
                type: 'string',
                format: 'enum',
                values: categories.reduce((acc, item) => {
                    acc[item] = item;
                    return acc;
                }, {} as any),
            },
        },
    },
    forms: {
        list: {
            columns: {
                categories: {
                    type: 'enum',
                    updateInPlace: false,
                    CellComponent: CellText,
                },
                yesnoQuestionUserId: {
                    getLink: function (
                        this: AppContext,
                        itemId: string,
                        item: any
                    ) {
                        return `/cbc/profile/item/${item.profileId}/info`;
                    },
                },
            },
            handlers: function (this: AppContext) {
                const onMapItems = async ({ items }: any) => {
                    const userIds = items.map(
                        (item: any) => item.yesnoQuestionUserId
                    );
                    if (userIds.length === 0) {
                        return items;
                    }

                    const profiles = await this.api.getItemsByIdFromTable(
                        'cbc',
                        'profile',
                        userIds,
                        'userId'
                    );

                    return items.map((item: any) => {
                        return {
                            ...item,
                            profileId:
                                profiles[item.yesnoQuestionUserId] &&
                                profiles[item.yesnoQuestionUserId]?.id,
                        };
                    });
                };

                const onBeforeOpen = async () => {
                    this.form.views.table.onMapItems = onMapItems;
                };

                return {
                    onBeforeOpen,
                    onMapItems,
                };
            },
        },
        item: {
            views: {
                categories: {
                    dataSource: 'data.categories',
                    isMulti: true,
                    isMultiple: true,
                },
            },
            handlers: function (this: AppContext) {
                const onBeforeOpen = async () => {
                    const objectId = this.form.url.params.objectId;

                    if (objectId) {
                        this.form.data = {
                            categories: (this.form.object.categories ?? '')
                                .split(',')
                                .map((item: any) => item.trim()),
                        };
                    } else {
                        this.form.data = {
                            categories: [],
                        };
                    }
                };

                const onBeforeSave = async () => {
                    this.form.object.categories = this.form.data.categories
                        .map((item: string) => item.trim())
                        .join(', ');
                };
                return {
                    onBeforeOpen,
                    onBeforeSave,
                };
            },
        },
    },
};

export default yesnoquestion;
