import _ from 'lodash';
import { LocalMetaInfoEndpoint } from '../../config-builder/types';
import apiClient from '../../requests/api';
import { sleep } from '../../utils';
import { AppContext, Url } from '../form/context/types';
import MediaManager from '../form/media-manager';
import { ItemFormHandlers } from './types';

const handlers = function (
    this: AppContext,
    { customHandlers }: { customHandlers: ItemFormHandlers }
): ItemFormHandlers {
    /// ////////////////////////////////////////////////////////////////////////
    // Private functions

    const getParams = (): {
        objectId?: string;
        siteId?: string;
        endpointId: string;
        isDialog?: boolean;
    } => {
        // FIXME: Fix types for handlers
        return (customHandlers as any).getParams() as any;
    };

    const removeMediaFields = (data: any) => {
        if (!this.form.mediaPropertiesIds) {
            return;
        }
        const mediaFields = this.form.mediaPropertiesIds;

        mediaFields.forEach((fieldId: string) => {
            delete data[fieldId];
        });

        delete data.files;
    };

    const _initNewObjectForm = async () => {
        const { siteId, endpointId, isDialog } = getParams();

        this.form.isLoading = false;
        this.form.siteId = siteId;
        this.form.endpointId = endpointId;
        this.form.objectId = null;

        let copyFrom: string | undefined;
        let copyProperties: string[];

        if (isDialog) {
            copyFrom = this.form.params.copyFrom;
            copyProperties = this.form.params.copyProperties;
        } else {
            copyFrom = this.form.url.queryParams.copyFrom;
            copyProperties = this.form.url.queryParams.copyProperties;
        }

        if (copyFrom) {
            const originalObject: any = await (
                this.form.handlers as any
            ).onLoadObject({
                siteId,
                endpointId,
                objectId: copyFrom,
            });

            let data: any = {};
            if (copyProperties && copyProperties.length > 0) {
                copyProperties.forEach((propertyId: string) => {
                    data[propertyId] = originalObject[propertyId];
                });
            } else {
                data = originalObject;
            }

            delete data.id;

            removeMediaFields(data);
            this.form.object = data;

            this.form.mediaPropertiesIds &&
                this.form.mediaPropertiesIds.forEach(
                    (mediaPropertyId: string) => {
                        this.form.mediaManagers[mediaPropertyId].copyFrom(
                            siteId,
                            endpointId,
                            copyFrom,
                            this.form.mediaManagers[mediaPropertyId].isMultiple
                        );
                    }
                );
        }
    };

    const _initExistingObjectForm = async () => {
        const { objectId, siteId, endpointId } = getParams();

        this.form.isLoading = true;
        this.form.progressText = 'Loading data...';
        this.form.siteId = siteId;
        this.form.endpointId = endpointId;
        this.form.objectId = objectId;
        this.form.object = null;
        this.form.isInEditMode = false;

        const loadedObject = await (this.form.handlers as any).onLoadObject({
            siteId,
            endpointId,
            objectId,
        });

        this.form.object = loadedObject;
        this.form.isInEditMode = false;
        this.form.isLoading = false;
    };

    const _checkRequiredFieldsBeforeSave = (
        object: any,
        metaInfo: LocalMetaInfoEndpoint
    ) => {
        const errors: any = {};

        metaInfo &&
            metaInfo.properties &&
            _.forOwn(metaInfo.properties, (propertyData, propertyId) => {
                if (!propertyData.isRequired) {
                    return;
                }

                if (!object[propertyId]) {
                    errors[propertyId] = {
                        text: 'Property is required',
                        propertyName: propertyData.name,
                    };
                }
            });

        if (Object.keys(errors).length > 0) {
            return errors;
        }

        return null;
    };

    const _hideErrors = () => {
        _.forOwn(this.form.views, (view, viewId) => {
            if (view.dataSource) {
                view.displayError = false;
            }
        });
    };

    const _displayErrors = (errors: any) => {
        _.forOwn(errors, ({ text, propertyName }, propertyId) => {
            _.forOwn(this.form.views, (view, viewId) => {
                if (view.dataSource === `object.${propertyId}`) {
                    view.displayError = true;

                    this.form.notify(
                        {
                            type: 'error',
                            text: `${propertyName}: ${text}`,
                        },
                        `requiredField.${propertyId}`
                    );
                }
            });
        });
    };

    /// ////////////////////////////////////////////////////////////////////////
    // Exported functions

    const onChangeUrl = async (newUrl: Url, oldUrl: Url) => {
        // form context
        if (oldUrl.params.objectId === newUrl.params.objectId) {
            return;
        }

        if (newUrl.params.objectId !== oldUrl.params.objectId) {
            await onBeforeOpen();
        }
    };

    const onCheckRequiredFields = async () => {
        const { siteId, endpointId } = getParams();
        const { object } = this.form;

        const endpointMetaInfo = this.getEndpointMetaInfo(siteId!, endpointId);
        const errors = _checkRequiredFieldsBeforeSave(object, endpointMetaInfo);
        if (errors) {
            _displayErrors(errors);
            return true;
        }

        return false;
    };

    const onFetch = async () => {
        const { object } = this.form;
        let { siteId, endpointId, objectId } = getParams();

        if (!objectId) {
            objectId = await apiClient.createTableItem(
                siteId,
                endpointId,
                object
            );
        } else {
            const data = {
                ...object,
            };

            removeMediaFields(data);

            await apiClient.updateTableItem(siteId, endpointId, objectId, {
                ...data,
                id: undefined,
            });
        }

        return objectId;
    };

    const uploadNewItemMedia = async (objectId: string) => {
        const mediaFields = this.form.mediaPropertiesIds;
        if (!mediaFields) {
            return;
        }

        for (let index = 0; index < mediaFields.length; index++) {
            const propertyId = mediaFields[index];
            this.form.notify(
                {
                    text: `Updating media (${index + 1}/${
                        mediaFields.length
                    })...`,
                },
                `SAVE_ITEM_MEDIA_${propertyId}`
            );

            const saveMediaField = this.form.mediaManagers[propertyId].save;
            await saveMediaField(objectId);
            this.form.hideNotification(`SAVE_ITEM_MEDIA_${propertyId}`);
        }
    };

    const onSave = async () => {
        this.form.hideNotification(/^requiredField\./);
        _hideErrors();

        let stop = false;
        let { objectId } = getParams();
        const isNew = !objectId;

        if (this.form.handlers.onBeforeSave) {
            stop = await (this.form.handlers as any).onBeforeSave();
            if (stop) {
                return;
            }
        }

        let stopAfterCheck = false;
        if (customHandlers && customHandlers.onCheckRequiredFields) {
            stopAfterCheck = await customHandlers.onCheckRequiredFields.call(
                this
            );
        } else {
            stopAfterCheck = await onCheckRequiredFields();
        }

        if (stopAfterCheck) {
            return;
        }

        this.form.areFieldsBlocked = true;
        this.form.notify({ text: 'Saving...' }, 'SAVE_ITEM');

        try {
            if (customHandlers && customHandlers.onFetch) {
                objectId = (await customHandlers.onFetch.call(this)) as any;
            } else {
                objectId = await onFetch();
            }

            await sleep(1000);
        } catch (e) {
            console.log('ERROR SAVE ITEM', e);
            this.form.areFieldsBlocked = false;
            this.form.hideNotification('SAVE_ITEM');
            this.form.notify(
                { type: 'error', text: 'Error, try again' },
                'SAVE_ITEM'
            );

            if (this.form.handlers.onSaveError) {
                (this.form.handlers as any).onSaveError(e);
            }
            return;
        }

        this.form.isLoading = true;
        this.form.hideNotification('SAVE_ITEM');
        if (isNew) {
            await uploadNewItemMedia(objectId!);
        }

        this.form.notify(
            { text: 'Saved successfully!', lifetimeMs: 5000 },
            'SAVE_ITEM_SUCCESS'
        );

        this.form.isLoading = false;

        if (this.form.handlers.onAfterSave) {
            await (this.form.handlers as any).onAfterSave(
                objectId,
                this.form.object
            );
        }

        this.form.areFieldsBlocked = false;
    };

    const _initMediaManagers = () => {
        const { siteId, endpointId, objectId } = getParams();

        if (!this.form.mediaPropertiesIds) {
            // FIXME:
            return;
        }

        this.form.mediaManagers = this.form.mediaPropertiesIds.reduce(
            (
                acc: any,
                mediaPropertyId: string,
                index: number,
                collection: any[]
            ) => {
                acc[mediaPropertyId] = new MediaManager(
                    this,
                    siteId,
                    endpointId,
                    objectId!,
                    mediaPropertyId,
                    undefined,
                    false,
                    `SAVE_ITEM_MEDIA_${mediaPropertyId}`,
                    ({ saveProgressPercent, saveProgressTotal }: any) => {
                        return `Updating media (${index + 1}/${
                            collection.length
                        }): ${saveProgressPercent}%`;
                    }
                );
                return acc;
            },
            {}
        );
    };

    const onBeforeOpen = async () => {
        _initMediaManagers();
        const callCustomHandler = () => {
            customHandlers &&
                customHandlers.onBeforeOpen &&
                customHandlers.onBeforeOpen.call(this);
        };

        this.form.save = onSave;

        // form context
        const { objectId } = getParams();

        if (!objectId) {
            await _initNewObjectForm();
            callCustomHandler();
            return;
        }

        await _initExistingObjectForm();
        callCustomHandler();
    };

    const onGetTitle = (): string => {
        const { objectId, endpointId } = getParams();
        if (!objectId) {
            return `CREATE A NEW ${endpointId}`;
        }

        if (!this.form.object) {
            return '';
        }

        return this.form.object.title || this.form.object.name;
    };

    return {
        onBeforeOpen,
        onChangeUrl,
        onSave,
        onCheckRequiredFields,
        onGetTitle,
    };
};

export default handlers;
