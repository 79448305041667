import { useMediaQuery } from '@react-hook/media-query';
import React from 'react';
import Pager from '../../pager';
import PropsFooter from './props';
import style from './style.module.scss';

const Footer = ({
    numberOfPages,
    page,
    onChangePage,
    total,
    pageSize,
}: PropsFooter) => {
    const isMobile = useMediaQuery('(max-width: 524px)');
    const numberOfItemsOnPage = Math.min(pageSize, total - page * pageSize);

    if (total === 0) {
        return <div />;
    }

    return (
        <div className={style.footer}>
            <Pager
                activePage={page + 1}
                displayRange={isMobile ? 5 : 10}
                numberOfPages={numberOfPages}
                pageSize={15}
                handleChangePage={(page) => {
                    onChangePage(page - 1);
                }}
            />
            <div className={style.text}>
                Showing you {numberOfItemsOnPage} out of {total}.
            </div>
        </div>
    );
};

export default React.memo(Footer);
