import { VStack } from '../../../../components/form/context/types';
import ViewTypes from '../../../../components/form/ViewTypes';
import templates from '../templates';

const content: VStack = {
    id: 'infoTabContentGroup',
    type: ViewTypes.VStack,
    style: {
        padding: 15,
        position: 'relative',
        paddingTop: 0,
    },
    items: [
        {
            id: 'commandPanel',
            type: ViewTypes.HStack,
            style: {
                justifyContent: 'space-between',
                position: 'absolute',
                height: 'unset',
                width: 'unset',
                background: 'white',
                padding: 10,
                zIndex: 100,
                top: 0,
                left: 0,
                right: 0,
            },
            items: [
                {
                    id: 'saveButton',
                    type: ViewTypes.Button,
                    role: 'active',
                    onClick: 'onSave',
                    text: 'Save',
                },
                {
                    id: 'copyUrlButton',
                    type: ViewTypes.Button,
                    role: 'notActive',
                    onClick: 'onCopyUrl',
                    text: 'Copy URL',
                    isHidden: '!url.params.objectId',
                },
            ],
        },
        {
            id: 'fieldsGroup',
            type: ViewTypes.VStack,
            style: {
                paddingTop: 70,
                paddingBottom: 30,
                paddingRight: 10,
            },
            items: [
                {
                    id: 'name',
                    label: 'שם הדף',
                    type: ViewTypes.TextInput,
                    dataSource: 'object.name',
                    ltr: true,
                },
                {
                    id: 'path',
                    label: 'כתובת',
                    type: ViewTypes.TextInput,
                    dataSource: 'object.path',
                    ltr: true,
                    placeholder: '{שם חברה} - {סוג הפרסומת} - {שם הפרסומת}',
                },
                {
                    id: 'siteIdInput',
                    label: 'Site ID',
                    type: ViewTypes.EnumInput,
                    dataSource: 'object.siteId',
                    onChangeValue: 'onChangeSiteId',
                    values: [],
                    isHidden: true,
                },
                {
                    id: 'endpointIdInput',
                    label: 'Endpoint ID',
                    type: ViewTypes.EnumInput,
                    dataSource: 'object.endpointId',
                    onChangeValue: 'onChangeEndpointId',
                    values: [],
                    isHidden: true,
                },
                {
                    id: 'templateId',
                    label: 'תבנית',
                    type: ViewTypes.EnumInput,
                    dataSource: 'object.data.templateId',
                    values: templates.map((item) => ({
                        value: item.id,
                        label: item.name,
                    })),
                },
                {
                    id: 'description',
                    label: 'תיאור',
                    type: ViewTypes.TextInput,
                    dataSource: 'object.description',
                    isMultiLine: true,
                },
            ],
        },
    ],
};

export default content;
