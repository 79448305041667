import { useMediaQuery } from '@react-hook/media-query';
import moment from 'moment';
import React, { useState } from 'react';
import calendarIcon from '../../../assets/icons/calendar.svg';
import {
    DatetimePicker,
    DatetimePickerTrigger,
} from '../../../lib/rc-datetime-picker-cjs';
import propsWithDefaultValues from '../../../utils/propsWithDefaultValues';
import Popup from '../popup';
import PropsDateInput, { defaultProps } from './props';
import style from './style.module.scss';

const DatetimePickerTriggerComponent: React.ComponentType<any> =
    DatetimePickerTrigger;
const DatetimePickerComponent: React.ComponentType<any> = DatetimePicker;

const formatDate = (date: string | any): string => {
    if (!date) {
        return '';
    }

    return moment(date).format('DD/MM/YY HH:mm');
};

const DateInput = (props: PropsDateInput) => {
    props = propsWithDefaultValues(props, defaultProps);
    const {
        id,
        classContainer,
        classLabel,
        label,
        value,
        onChange,
        disabled,
        displayError,
        showClearButton,
        classInput,
        showTimePicker,
        renderDialogInto,
    } = props;
    const [isDateDialogOpened, setIsDateDialogOpened] = useState(false);

    let { showDatePickerAs } = props;
    const isMobile = useMediaQuery('(max-width: 524px)');
    if (showDatePickerAs === 'auto') {
        if (isMobile) {
            showDatePickerAs = 'dialog';
        } else {
            showDatePickerAs = 'dropdown';
        }
    }

    const handleToggleDateDialog = () => {
        setIsDateDialogOpened((prev) => !prev);
    };

    const handleClear = (e: any) => {
        e.stopPropagation();

        onChange(undefined);
    };

    const renderDropdownPicker = () => {
        return (
            <DatetimePickerTriggerComponent
                className="custom"
                moment={(value && moment(value)) || moment()}
                showTimePicker={showTimePicker}
                onChange={onChange}
                closeOnSelectDay
                disabled={disabled}
            >
                <input
                    autoComplete="off"
                    className={classInput}
                    id={`${id}_date_input`}
                    type="text"
                    value={formatDate(value)}
                    data-error={!!displayError}
                    disabled={!!disabled}
                    onChange={() => {}}
                />
                {showClearButton && (
                    <button className={style.clearButton} onClick={handleClear}>
                        <i className="ion ion-ios-close" />
                    </button>
                )}
            </DatetimePickerTriggerComponent>
        );
    };

    const handleClickBackdrop = (e: any) => {
        // const {onClickBackdrop} = this.props;
        //
        e.stopPropagation();
        if (e.target.className === 'backdrop') {
            setIsDateDialogOpened(false);
        }
    };

    const handleChangeDate = (newValue: any) => {
        setIsDateDialogOpened(false);
        onChange(newValue);
    };

    const renderPickerDialog = () => {
        return (
            <div className={style.datePicker}>
                <input
                    autoComplete="off"
                    className={classInput}
                    id={`${id}_date_input`}
                    type="text"
                    value={formatDate(value)}
                    data-error={!!displayError}
                    onClick={handleToggleDateDialog}
                    disabled={!!disabled}
                />
                <Popup
                    id={`value-input-date${id}`}
                    isOpened={isDateDialogOpened}
                    into={renderDialogInto || 'body'}
                >
                    <div className="backdrop" onClick={handleClickBackdrop}>
                        <div className={style.datePicker}>
                            <DatetimePickerComponent
                                className="customDialog"
                                moment={(value && moment(value)) || moment()}
                                showTimePicker
                                onChange={handleChangeDate}
                            />
                        </div>
                    </div>
                </Popup>
            </div>
        );
    };

    return (
        <div className={classContainer} data-role="view">
            <img className={style.calendarIcon} src={calendarIcon} alt="" />
            <label className={classLabel}>{label}</label>
            {showDatePickerAs !== 'dialog' && renderDropdownPicker()}
            {showDatePickerAs === 'dialog' && renderPickerDialog()}
        </div>
    );
};

export default React.memo(DateInput);
