import React from 'react';
import Lightbox from 'react-images';
import Items from '../media-scroll';
import UploadView from '../upload-view';
import Header from './header';
import usePhotoGalleryViewState from './hooks';
import PropsPhotoGalleryView from './props';
import style from './style.module.scss';

const PhotoGalleryView = (props: PropsPhotoGalleryView) => {
    // FIXME:
    const {
        label,
        items,
        addItem,
        deleteItem,
        showEditButtons,
        undo,
        isProcessing,
    } = props;

    const {
        isLightboxOpen,
        isInEditMode,
        currentItem,
        processText,
        imageLinks,
        _handleSortEnd,
        _handleToggleEditMode,
        _handleSelectItem,
        _handleCloseLightbox,
        _handleGotoPrevious,
        _handleGoToNext,
        _handleGoToImage,
        _handleSave,
    } = usePhotoGalleryViewState(props);

    return (
        <div className={style.container}>
            <Header
                showButtons={showEditButtons}
                isInEditMode={isInEditMode ?? false}
                title={label}
                total={items.length}
                onUndo={undo}
                onToggleEditMode={_handleToggleEditMode}
                onSave={_handleSave}
            />
            <div className={style.content}>
                {!isInEditMode && items.length === 0 && (
                    <div className={style.emptyStub}>
                        <span>NO ITEMS</span>
                    </div>
                )}
                {isInEditMode && (
                    <UploadView
                        id="upload-view"
                        multiple
                        onSelected={addItem}
                    />
                )}
                <Items
                    isInEditMode={!isProcessing && isInEditMode}
                    items={items}
                    axis="x"
                    lockAxis="x"
                    onSortEnd={_handleSortEnd}
                    onDeleteItem={deleteItem}
                    onOpenItem={_handleSelectItem}
                    // onAddItem={addItem}
                    pressDelay={200}
                    helperClass="moving"
                    useDragHandle
                    uploadProgress={undefined}
                />
            </div>
            {isLightboxOpen && (
                <Lightbox
                    images={imageLinks}
                    currentImage={currentItem}
                    isOpen={isLightboxOpen}
                    showThumbnails
                    onClickNext={_handleGoToNext}
                    onClickPrev={_handleGotoPrevious}
                    onClose={_handleCloseLightbox}
                    onClickThumbnail={_handleGoToImage}
                />
            )}
            {isProcessing && (
                <div className={style.processing}>{processText}</div>
            )}
        </div>
    );
};

export default React.memo(PhotoGalleryView);
