import { CustomEndpointConfig } from '../../../../core/config-builder/types';
import { getUserName } from '../../commonFunctions';

const order: CustomEndpointConfig = {
    metaInfo: {
        properties: {
            cardId: {
                type: 'string',
                format: 'text',
            },
            userId: {
                type: 'string',
                siteId: 'cbc',
                endpointId: 'profile',
                idField: 'userId',
                sortListByField: 'firstname',
                searchByField: ['email', 'firstname', 'lastname', 'phone'],
                representationDataSource: getUserName,
            },
        },
    },
};

export default order;
