import {
    AppContext,
    FormFunctionGetHandlers,
} from '../../../../../components/form/context/types';
const handlers: FormFunctionGetHandlers = function (this: AppContext) {
    const onBeforeOpen = async () => {};

    const onClose = () => {
        this.form.closeDialog();
    };

    const getData = () => {
        const { data, repeatType } = this.form;

        const { repeat } = data.opts;

        if (repeatType === 'cron') {
            const result = {
                data: {},
                ...data,
                opts: {
                    ...data.opts,
                    repeat: {
                        cron: repeat.cron,
                    },
                },
            };

            if (repeat.startDate) {
                result.opts.repeat.startDate = repeat.startDate;
            }

            if (repeat.endDate) {
                result.opts.repeat.endDate = repeat.endDate;
            }

            return result;
        }

        let opts = {
            data: {},
            ...data,
            opts: {
                ...data.opts,
            },
        };

        if (repeat) {
            opts.opts.repeat = repeat;
        }

        return opts;
    };

    const checkData = (
        data: any
    ): Record<string, { inputId: string; text: string }> | null => {
        const errors: Record<string, { inputId: string; text: string }> = {};

        /*if (data.opts.repeat.every === 0) {
            errors['repeat.every'] = {
                inputId: 'every',
                text: 'Every field is required',
            };
        }*/

        if (Object.getOwnPropertyNames(errors).length > 0) {
            return errors;
        }

        return null;
    };

    const onContinue = () => {
        const { params } = this.form;
        const { siteId, endpointId, scriptId } = params;

        const data = getData();
        const errors = checkData(data);

        this.form.hideErrors('ADD_JOB_DIALOG_MISSING_VALUE_');

        if (errors) {
            this.form.displayErrors(errors, 'ADD_JOB_DIALOG_MISSING_VALUE_');
            return;
        }

        params.onContinue({
            siteId,
            endpointId,
            scriptId,
            data,
        });
        this.form.closeDialog();
    };

    const onChangeCron = (value: any) => {
        this.form.data.opts.repeat.cron = value;
    };

    return {
        onBeforeOpen,
        onClose,
        onContinue,
        onChangeCron,
    };
};

export default handlers;
