import { CustomEndpointConfig } from '../../../../core/config-builder/types';

const application: CustomEndpointConfig = {
    title: 'לידים',
    metaInfo: {
        properties: {
            name: {
                name: 'שם מלא',
                isRequired: true,
            },
            phone: {
                name: 'טלפון',
            },
            userId: {
                name: 'משתמש',
                isHidden: true,
            },
            bio: {
                name: 'ביו',
            },
            jobId: {
                name: 'עבודה',
                representationDataSource: ['advertiserName', 'itemTitle'],
            },
            city: {
                name: 'עיר מגורים',
            },
            createdAt: {
                name: 'נוצר ב',
            },
        },
    },
    forms: {
        item: {
            views: {
                name: {
                    label: 'שם מלא',
                },
                phone: {
                    label: 'טלפון',
                },
                userId: {
                    label: 'משתמש',
                },
                jobId: {
                    label: 'עבודה',
                },
                bio: {
                    label: 'ביו',
                },
                city: {
                    label: 'עיר מגורים',
                },
                createdAt: {
                    label: 'נוצר ב',
                },
            },
        },
    },
};

export default application;
