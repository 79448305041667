import React from 'react';
import editIcon from '../../../../assets/icons/edit.svg';
import PropsHeader from './props';
import style from './style.module.scss';

const Header = (props: PropsHeader) => {
    const {
        isInEditMode,
        showButtons,
        title,
        total,
        onToggleEditMode,
        onUndo,
        onSave,
    } = props;
    return (
        <div className={style.header}>
            <span className={style.title}>{title}</span>
            {showButtons && !isInEditMode && (
                <div className={style.editButton} onClick={onToggleEditMode}>
                    <span>Edit</span>
                    <img className={style.editIcon} src={editIcon} alt="" />
                </div>
            )}
            {showButtons && isInEditMode && (
                <div className={style.saveButton} onClick={onSave}>
                    Save Change
                </div>
            )}
            {showButtons && isInEditMode && (
                <div className={style.undoButton} onClick={onUndo}>
                    Undo
                </div>
            )}
            <span className={style.total}>Total: {total} images</span>
        </div>
    );
};

export default React.memo(Header);
