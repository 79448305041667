import {
    useCallback,
    useDebugValue,
    useEffect,
    useImperativeHandle,
    useRef,
    useState,
} from 'react';
import { usePrevious } from 'use-hooks';
import { useUnmountedFlag } from '../../../utils';
import {
    ComputedStatistic,
    computeStatistics,
    deletePredefinedTableItemFields,
    getNewSort,
    getNumberOfPages,
    mergeFilters,
    sortItems,
    useColumnsWithDefaultProperties,
    useEscapeListener,
} from './common';
import { Selection, sleep } from './dependencies';
import {
    ColumnDateFilterData,
    ColumnReferenceFilterData,
    FilterData,
} from './header/column-filter/props';
import { ColumnsFilter } from './header/props';
import {
    FetchParams,
    PropsTableCustom,
    PropsTableDefault,
    SortItem,
    UpdateItemFieldParams,
} from './props';
import loadReferences from './referencesManagement';
import { Column, PageReferenceValue } from './types';

const updateTableItem = async (
    searchItems: any,
    updateItem: any,
    siteId: string | undefined,
    endpointId: string,
    itemId: string,
    fieldName: string,
    value: any
) => {
    const { items } = await searchItems(
        siteId,
        endpointId,
        [],
        [
            {
                field: '_id',
                includeAll: [itemId],
            },
        ],
        0,
        1
    );

    if (!items || items.length === 0) {
        throw new Error(`Can't get the item ${itemId}`);
    }

    const oldItem: any = items[0];
    const data = {
        ...deletePredefinedTableItemFields(oldItem),
        [fieldName]: value,
    };

    // FIXME: Why we don't use "idField"?
    await updateItem(siteId, endpointId, itemId, data);
    await sleep(1000);
};

const getDisplayPageItems = (
    items: any,
    columns: Column[],
    sort: SortItem[],
    filter: ColumnsFilter,
    page: number,
    pageSize: number
): any[] => {
    const sortedItems = sortItems(items, columns, sort);

    const filteredItems = sortedItems.filter((item) => {
        const filterColumns: string[] = Object.keys(filter);
        for (
            let filterIndex = 0;
            filterIndex < filterColumns.length;
            filterIndex++
        ) {
            const columnId: string = filterColumns[filterIndex]!;
            const filterData = filter[columnId];

            const column = columns.find((c) => c.id === columnId);
            if (!column) {
                throw new Error(`The column does'not exist ${columnId}`);
            }

            const value = item[column.fieldName];
            switch (column.type) {
                case 'date': {
                    if (!filterData) {
                        break;
                    }

                    const dateFilterData = filterData as ColumnDateFilterData;
                    if (dateFilterData.from && dateFilterData.to) {
                        return (
                            dateFilterData.from <= value &&
                            value <= dateFilterData.to
                        );
                    }
                    if (dateFilterData.from) {
                        return dateFilterData.from <= value;
                    }
                    if (dateFilterData.to) {
                        return value <= dateFilterData.to;
                    }

                    break;
                }

                case 'enum':
                case 'reference': {
                    return Selection.unserealizeFromObject(
                        filterData as ColumnReferenceFilterData
                    ).isItemSelected(value);
                }
                default:
                    break;
            }
        }

        return true;
    });

    return filteredItems.filter(
        (item, index) =>
            index >= page * pageSize && index < pageSize * (page + 1)
    );
};

const useTableState = (
    props: PropsTableDefault & PropsTableCustom,
    ref: any
) => {
    const {
        defaultSort,
        searchItems,
        siteId,
        endpointId,
        pageSize,
        onBeforeFetch,
        queryParams,
        onBeforeLoadReferences,
        searchPhrase,
        searchFields,
        queryFilter,
        idField,
        onBeforeUpdateItemField,
        onAfterUpdateItemField,
        onMapItems,
        updateItem,
    } = props;

    const isLocalItems = !props.endpointId;
    const { isUnmounted, executeIfNotUnmounted } =
        useUnmountedFlag('TABLE IS UNMOUNTED');
    const refIsSilentUpdate = useRef(false);

    const [isLoading, setIsLoading] = useState(() =>
        props.isLoading !== undefined ? props.isLoading : false
    );

    // useEffect(() => {
    //     if (props.isLoading !== undefined) {
    //         setIsLoading(props.isLoading);
    //     }
    // }, [props.isLoading]);

    const [currentPageItems, setCurrentPageItems] = useState<any[]>(() => []);
    const [statistics, setStatistics] = useState<ComputedStatistic[]>();
    const [pageReferenceValues, setPageReferenceValues] = useState<
        PageReferenceValue[] | undefined
    >(undefined);
    const [quickEditCell, setQuickEditCell] = useState<string | null>(null);
    const [timestamp, setTimestamp] = useState(() => Date.now());
    const forceFetchRemoteItems = (isSilent?: boolean) => {
        setTimestamp(Date.now());
    };

    const [page, setPage] = useState(() => props.page ?? 0);
    const prevPage = usePrevious(page);
    const isPageChanged = prevPage !== page;
    if (isPageChanged) {
        refIsSilentUpdate.current = false;
    }

    const [total, setTotal] = useState(1);
    const [sort, setSort] = useState(
        () =>
            (!props.sort || props.sort.length === 0
                ? props.defaultSort
                : props.sort) ?? []
    ); // WHY??
    const [filter, setFilter] = useState<ColumnsFilter>(
        () => props.filter ?? {}
    );
    const [selection, setSelection] = useState<Selection>(
        () => props.selection ?? Selection.unserealizeFromObject('none')
    );

    const columns = useColumnsWithDefaultProperties(props.columns);
    const numberOfPages = getNumberOfPages(total, pageSize);

    useEscapeListener(setQuickEditCell);

    /// ////////////////////////////////////////////////////////////////////////
    // Handle sorting/filtering events

    const {
        onChangeSelection: propsOnChangeSelection,
        onChangePage: propsOnChangePage,
        onChangeSort: propsOnChangeSort,
        onChangeFilter: propsOnChangeFilter,
    } = props;

    const onChangeSelection = useCallback(
        (itemId: string | undefined, isSelected: boolean) => {
            const newSelection = selection.copy();

            if (itemId) {
                if (isSelected) {
                    newSelection.selectItem(itemId);
                } else {
                    newSelection.unselectItem(itemId);
                }
            } else {
                if (isSelected) {
                    newSelection.selectAll();
                } else {
                    newSelection.unselectAll();
                }
            }

            if (propsOnChangeSelection) {
                propsOnChangeSelection(newSelection);
                return;
            }

            setSelection(newSelection);
        },
        [propsOnChangeSelection, selection]
    );

    const onChangePage = useCallback(
        (page: number) => {
            if (propsOnChangePage) {
                propsOnChangePage(page);
                return;
            }

            setPage(page);
        },
        [propsOnChangePage]
    );

    const onChangeSort = useCallback(
        (columnId: string, direction: 'asc' | 'desc', shiftKey: boolean) => {
            const newSort = getNewSort(sort, columnId, direction, shiftKey);

            if (propsOnChangeSort) {
                propsOnChangeSort(newSort);
            } else {
                setSort(newSort);
            }
        },
        [propsOnChangeSort, sort]
    );

    const onChangeFilter = useCallback(
        (columnId: string, filterData: FilterData | undefined) => {
            const newFilter: ColumnsFilter = {
                ...(filter ?? {}),
            };

            if (!filterData) {
                delete newFilter[columnId];
            } else {
                newFilter[columnId] = filterData;
            }

            if (propsOnChangeFilter) {
                propsOnChangeFilter(newFilter, filter, columnId, filterData);
                return;
            }

            setFilter(newFilter);
        },
        [filter, propsOnChangeFilter]
    );

    const onUpdateItemField = useCallback(
        async (params: UpdateItemFieldParams): Promise<void> => {
            const { item, column, value } = params;

            onBeforeUpdateItemField && onBeforeUpdateItemField(params);

            try {
                await updateTableItem(
                    searchItems,
                    updateItem,
                    siteId,
                    endpointId!,
                    item.id,
                    column.fieldName,
                    value
                );

                onAfterUpdateItemField && onAfterUpdateItemField(params);
            } catch (e) {
                onAfterUpdateItemField &&
                    onAfterUpdateItemField({ ...params, error: e });
            }
        },
        [
            siteId,
            endpointId,
            onBeforeUpdateItemField,
            onAfterUpdateItemField,
            searchItems,
            updateItem,
        ]
    );

    /// ////////////////////////////////////////////////////////////////////////
    // Trigger fetch on changes
    useEffect(() => {
        const updateCurrenPageReferences = async () => {
            setPageReferenceValues(undefined);
            if (onBeforeLoadReferences) {
                await onBeforeLoadReferences({
                    items: currentPageItems,
                    total,
                });
            }

            const values = await loadReferences(
                currentPageItems,
                columns,
                searchItems as any
            );

            executeIfNotUnmounted(() => setPageReferenceValues(values));
        };

        executeIfNotUnmounted(() => updateCurrenPageReferences());
    }, [
        currentPageItems,
        columns,
        searchItems,
        total,
        executeIfNotUnmounted,
        siteId,
        endpointId,
        idField,
        onBeforeLoadReferences,
    ]);

    useEffect(() => {
        if (!isLocalItems) {
            return;
        }

        const updateLocalItems = async () => {
            if (!refIsSilentUpdate.current) {
                setIsLoading(false);
            }

            const currentPageItems = getDisplayPageItems(
                props.items,
                columns,
                sort,
                filter,
                page,
                pageSize
            );

            if (isUnmounted()) {
                return;
            }

            const mappedItems = onMapItems
                ? await onMapItems({ items: currentPageItems, total })
                : currentPageItems;
            setCurrentPageItems(mappedItems);
            setTotal(currentPageItems.length);
        };

        updateLocalItems();
    }, [
        isLocalItems,
        props.items,
        onBeforeLoadReferences,
        onMapItems,
        columns,
        sort,
        filter,
        page,
        pageSize,
        isUnmounted,
        total,
    ]);

    useEffect(() => {
        if (isLocalItems) {
            return;
        }

        const fetchItemsFromRemoteServer = async () => {
            if (!refIsSilentUpdate.current) {
                setIsLoading(true);
            }

            const queryFilter = mergeFilters(
                columns,
                props.queryFilter,
                filter
            );

            const fetchParams: FetchParams = {
                siteId,
                endpointId,
                sort: sort?.map((item) => ({
                    field: columns!.find(
                        (column: Column) => column.id === item.columnId
                    )!.fieldName,
                    direction: item.direction,
                })),
                filter: queryFilter,
                from: page * pageSize,
                limit: pageSize,
                searchPhrase,
                searchFields,
                queryParams,
            };

            if (onBeforeFetch) {
                const state = {
                    siteId,
                    endpointId,
                    pageSize,
                    page,
                    columns,
                    sort,
                    filter,
                    searchPhrase,
                    searchFields,
                    queryParams,
                };

                onBeforeFetch(state, fetchParams);
            }

            if (!props.searchItems) {
                debugger;
            }
            const {
                items,
                total: newTotal,
                aggregations,
            } = await props.searchItems(
                fetchParams.siteId,
                fetchParams.endpointId,
                fetchParams.sort,
                fetchParams.filter,
                fetchParams.from,
                fetchParams.limit,
                fetchParams.searchPhrase,
                fetchParams.searchFields,
                fetchParams.queryParams
            );

            if (isUnmounted()) {
                return;
            }

            let newStatistics: ComputedStatistic[] | undefined;
            if (props.statistics) {
                newStatistics = computeStatistics(
                    aggregations,
                    props.statistics
                );
            }

            if (!refIsSilentUpdate.current) {
                setPageReferenceValues(undefined);
            }

            const newNumberOfPages = Math.max(
                1,
                Math.ceil(newTotal / pageSize)
            );

            const isResetToFirstPage = newNumberOfPages < page + 1;
            if (isResetToFirstPage) {
                if (props.onChangePage) {
                    setTimeout(() => {
                        executeIfNotUnmounted(() => {
                            props.onChangePage && props.onChangePage(0);
                        });
                    }, 1);
                    return;
                }

                setPage(0);
                setIsLoading(false);
                return;
            }

            const mappedItems = onMapItems
                ? await onMapItems({ items, total: newTotal })
                : items;

            executeIfNotUnmounted(() => {
                setCurrentPageItems(mappedItems);
                setTotal(newTotal);
                setStatistics(newStatistics);
                setIsLoading(false);
            });
        };

        // Debounce refreshs
        const timeout = setTimeout(() => {
            executeIfNotUnmounted(() => fetchItemsFromRemoteServer());
        }, 300);

        return () => {
            clearTimeout(timeout);
        };
    }, [
        isLocalItems,
        timestamp,
        siteId,
        endpointId,
        searchPhrase,
        onBeforeFetch,
        onBeforeLoadReferences,
        filter,
        queryParams,
        queryFilter,
        page,
        executeIfNotUnmounted,
        onMapItems,
        columns,
        sort,
        pageSize,
        searchFields,
        isUnmounted,
    ]);

    useImperativeHandle(ref, () => ({
        updateItems: () => {
            refIsSilentUpdate.current = true;
            forceFetchRemoteItems();
        },
        update: () => {
            refIsSilentUpdate.current = false;
            forceFetchRemoteItems();
        },
    }));

    useEffect(() => {
        setSelection(
            props.selection ?? Selection.unserealizeFromObject('none')
        );
    }, [JSON.stringify(props.selection)]);

    useEffect(() => {
        setSort(
            (!props.sort || props.sort.length === 0
                ? props.defaultSort
                : props.sort) ?? []
        );
    }, [JSON.stringify(props.sort), JSON.stringify(props.defaultSort)]);

    useEffect(() => {
        setFilter(props.filter ?? {});
    }, [JSON.stringify(props.filter)]);

    useEffect(() => {
        setPage(props.page);
    }, [props.page]);

    const result = {
        isLoading,
        items: currentPageItems,
        statistics,
        pageReferenceValues,
        quickEditCell,
        page,
        total,
        sort: (sort.length === 0 ? defaultSort : sort) ?? [],
        filter,
        selection,
        numberOfPages,
        columns,

        onChangePage,
        onChangeSelection,
        onChangeFilter,
        onChangeSort,
        onSetQuickEditCell: setQuickEditCell,
        onUpdateItemField,
    };

    useDebugValue(result);
    return result;
};

export default useTableState;
