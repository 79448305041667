import apiClient from '../../requests/api';
import { Observable } from '../definition';

function mapToDisplayError(text: string) {
    switch (text) {
        case 'Invalid username or password.':
        case 'User not found':
            return {
                field: 'login',
                text: 'Invalid username or password.',
            };
        default:
            return { field: 'global', text };
    }
}

const actions = {
    loginByEmail: (data: {
        userName: string;
        password: string;
        rememberMe: boolean;
    }) => {
        return async (store: Observable) => {
            store.loginPage.dispatch.loginRequest();
            try {
                const { token, userData } = await apiClient.loginByEmail(
                    data.userName,
                    data.password
                );
                store.loginPage.dispatch.loginSuccess();

                console.log('LOGIN', {
                    rememberMe: data.rememberMe,
                    token,
                    userData,
                });
                store.account.dispatch.loginSuccess({
                    rememberMe: data.rememberMe,
                    token,
                    userData,
                });
                store.routerHistory.dispatch.push('/');
            } catch (e) {
                if (e && e.message === '403' && e.token) {
                    store.account.dispatch.requiredPasswordChange(e.token);
                } else {
                    store.loginPage.dispatch.loginError({
                        errors: [mapToDisplayError(e)],
                    });
                }
            }
        };
    },
};

export default actions;
