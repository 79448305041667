import { UserData } from '../../components/form/context/types';
import { AccountState, initialState } from './state';

const accountReducers = {
    loginByTokenRequest:
        () =>
        (state: AccountState): AccountState => {
            return {
                ...state,
                isLoggedIn: false,
                isLoginProcessing: true,
            };
        },

    loginByTokenSuccess: ({ userData }: { userData: UserData }) => {
        return (state: AccountState): AccountState => {
            return {
                ...state,
                ...userData,
                isLoggedIn: true,
                isLoginProcessing: false,
            };
        };
    },

    setUserData: ({ userData }: { userData: UserData }) => {
        return (state: AccountState): AccountState => {
            return {
                ...state,
                ...userData,
            };
        };
    },

    loginByTokenError:
        () =>
        (state: AccountState): AccountState => {
            return initialState;
        },

    logoutSuccess:
        () =>
        (state: AccountState): AccountState => {
            return {
                ...initialState,
                isLoginProcessing: false,
            };
        },

    setTokenForChangingPassword:
        ({ token }: { token: string | null }) =>
        (state: AccountState): AccountState => {
            return {
                ...initialState,
                tokenForChangingPassword: token,
            };
        },

    changePasswordRequest:
        () =>
        (state: AccountState): AccountState => {
            return state;
        },

    changePasswordSuccess:
        () =>
        (state: AccountState): AccountState => {
            return state;
        },

    changePasswordError:
        ({ e }: { e: Error }) =>
        (state: AccountState): AccountState => {
            return state;
        },
};

export default accountReducers;
