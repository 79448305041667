import { VStack } from '../../../../../core/components/form/context/types';
import ViewTypes from '../../../../../core/components/form/ViewTypes';

const content: VStack = {
    id: 'rootGroup',
    type: ViewTypes.VStack,
    style: { flex: 1 },
    items: [
        {
            id: 'totalLabel',
            type: ViewTypes.Text,
            value: 'סיכום:',
            style: { fontSize: 22, fontWeight: 'bold' },
        },
        {
            id: 'totalCountersGroup',
            type: ViewTypes.HStack,
            style: { marginBottom: 80, flex: 1 },
            items: [
                {
                    id: 'totalPlayers',
                    type: ViewTypes.Counter,
                    label: 'מס׳ שחקנים',
                    style: { marginLeft: 10 },
                    dataSource: 'totalPlayers',
                },
                {
                    id: 'totalSwipes',
                    type: ViewTypes.Counter,
                    label: 'סוייפים',
                    dataSource: 'totalSwipes',
                    style: { marginLeft: 10 },
                },
                {
                    id: 'totalSwipesPerPlayer',
                    type: ViewTypes.Counter,
                    label: 'ממוצע סוייפ פר משתמש',
                    style: { marginLeft: 10 },
                    dataSource: 'totalSwipesPerPlayer',
                },
                {
                    id: 'totalQuestions',
                    type: ViewTypes.Counter,
                    label: 'מספר משפטים שנשלחו',
                    dataSource: 'totalQuestions',
                    style: { marginLeft: 10 },
                },
            ],
        },
        {
            id: 'filteredLabel',
            type: ViewTypes.Text,
            value: 'לפי תאריך:',
            style: { fontSize: 22, fontWeight: 'bold' },
        },
        {
            id: 'filterGroup',
            type: ViewTypes.HStack,
            style: { marginBottom: 30 },
            items: [
                {
                    id: 'startDate',
                    type: ViewTypes.DateInput,
                    label: 'תאריך התחלה',
                    // style: { width: 350, marginLeft: 10 },
                    showClearButton: true,
                    dataSource: 'startDate',
                    showTimePicker: false,
                    onChangeValue: 'onChangeStartDate',
                },
                {
                    id: 'endDate',
                    type: ViewTypes.DateInput,
                    label: 'תאריך סוף',
                    // style: { width: 350, marginLeft: 10 },
                    showClearButton: true,
                    dataSource: 'endDate',
                    onChangeValue: 'onChangeEndDate',
                },
            ],
        },
        {
            id: 'filteredCountersGroup',
            type: ViewTypes.HStack,
            style: { marginBottom: 300, flex: 1 },
            items: [
                {
                    id: 'players',
                    type: ViewTypes.Counter,
                    label: 'מס׳ שחקנים',
                    style: { marginLeft: 10 },
                    dataSource: 'activePlayers',
                },
                {
                    id: 'swipes',
                    type: ViewTypes.Counter,
                    label: 'סוייפים',
                    dataSource: 'swipes',
                    style: { marginLeft: 10 },
                },
                {
                    id: ' ',
                    type: ViewTypes.Counter,
                    label: 'ממוצע סוייפ פר משתמש',
                    style: { marginLeft: 10 },
                    dataSource: 'swipesPerPlayer',
                },
                {
                    id: 'questions',
                    type: ViewTypes.Counter,
                    label: 'מספר משפטים שנשלחו',
                    dataSource: 'questions',
                    style: { marginLeft: 10 },
                },
            ],
        },
    ],
};

export default content;
