import Handlerbars from 'handlebars';
import JSZip from 'jszip';
import {
    AppContext,
    FormFunctionGetHandlers,
    UploadInput,
} from '../../../../components/form/context/types';
import ViewTypes from '../../../../components/form/ViewTypes';
import { FileData } from '../../../../components/native/upload-view/props';
import apiClient from '../../../../requests/api';
import { dataURLtoBlob } from '../../../../utils';
import templates from '../templates';
import style from './style.module.scss';

const getHtml = (templateId: string, values: any) => {
    const templateData = templates.find((item) => item.id === templateId);
    const templateSource = templateData?.htmlTemplate;
    const templateFunction = Handlerbars.compile(templateSource);

    return templateFunction(values);
};

const handlers: FormFunctionGetHandlers = function (this: AppContext) {
    const getLandingUrl = () => {
        let landingUrl = this.appConfig.params.landingsBaseUrl;
        if (!landingUrl.endsWith('/')) {
            landingUrl += '/';
        }
        landingUrl += this.form.object.path;
        return landingUrl;
    };

    const onSelectFile = async (files: FileData[]) => {
        const data = files[0].data;
        const zip = new JSZip();
        await zip.loadAsync(dataURLtoBlob(data));

        let stopUpload = false;
        const { requiredFiles } = this.form.template.dataSchema;

        if (requiredFiles) {
            requiredFiles.forEach((filePath: string) => {
                filePath = filePath.replace(/^\/+/g, '');
                const isFileExist = zip.files[filePath];
                if (!isFileExist) {
                    this.form.notify({
                        type: 'error',
                        lifetimeMs: 5000,
                        text: `The file "${filePath}" is required`,
                    });
                    stopUpload = true;
                }
            });
        }

        if (stopUpload) {
            return;
        }

        this.form.views.filesInput.isSelected = true;
        this.form.zipData = data;
    };

    const loadObject = async (objectId: string) => {
        const objectData = await apiClient.getItemFromTable(
            undefined,
            'landings',
            objectId
        );

        if (objectData.data) {
            objectData.data = JSON.parse(objectData.data);
        } else {
            objectData.data = {};
        }

        if (!objectData.data.values) {
            objectData.data.values = {};
        }

        this.form.object = objectData;

        const template = templates.find(
            (item) => item.id === objectData.data.templateId
        );

        this.form.template = template;

        const fieldsViews: any[] = [];

        template?.dataSchema?.fields?.forEach((item) => {
            fieldsViews.push({
                id: item.id,
                type: ViewTypes.TextInput,
                label: item.name,
                // onChangeValue: function (newValue: string) {
                //     if (!this.form.object.data.values) {
                //         this.form.object.data.values = {};
                //     }
                //     this.form.object.data.values[item.id] = newValue;
                //     onSave();
                // },
                dataSource: `object.data.values.${item.id}`,
            });
        });

        this.form.mediaManagers = {};

        if ((template?.dataSchema?.requiredFiles?.length ?? 0) > 0) {
            fieldsViews.push({
                id: 'requiredFilesGroup',
                type: ViewTypes.VStack,
                style: { height: 'unset', marginBottom: 10 },
                items: [
                    {
                        id: 'requiredFilesTitle',
                        type: ViewTypes.Text,
                        value: 'Required files',
                        style: { fontWeight: 'bold' },
                    },
                    {
                        id: 'requiredFilesItems',
                        type: ViewTypes.VStack,
                        style: {
                            border: '2px dashed #43bf60',
                            flex: '0 auto',
                            height: 'unset',
                            borderRadius: 4,
                            padding: 5,
                        },
                        items: template?.dataSchema?.requiredFiles?.map(
                            (item, index) => ({
                                id: `requiredFile${index}`,
                                type: ViewTypes.Text,
                                value: item,
                                style: { direction: 'ltr', textAlign: 'right' },
                            })
                        ),
                    },
                ],
            });

            const uploadInput: UploadInput = {
                id: 'filesInput',
                type: ViewTypes.UploadInput,
                title: 'ZIP file with required data',
                onSelectFile,
                fileType: 'dataUrl',
                extensionsWithDot: ['.zip'],
                maxSizeKB: 1000000,
                onDelete: () => {
                    this.form.zipData = null;
                    this.form.views.filesInput.isSelected = false;
                },
            };

            fieldsViews.push({
                id: 'uploadInputContainer',
                type: ViewTypes.VStack,
                className: style.uploadInputContainer,
                items: [uploadInput],
            });
        }

        this.form.views.fieldsGroup.items = fieldsViews;
        if (template) {
            this.form.views.mobile.title = `${template.previewSize.width}x${template.previewSize.height}`;
            this.form.views.mobilePreview.width = template.previewSize.width;
            this.form.views.mobilePreview.height = template.previewSize.height;
        }
    };

    const onBeforeOpen = async () => {
        const { objectId } = this.form.url.params;
        if (!objectId) {
            this.form.views.blockText.isHidden = false;
        }

        await loadObject(objectId);

        this.form.views.mobilePreview.url = getLandingUrl();
    };

    const onToggleEditorEditMode = () => {
        this.form.isEditorInEditMode = !this.form.isEditorInEditMode;
        loadObject(this.form.url.params.objectId);
    };

    const onOpenLanding = () => {
        let landingUrl = this.appConfig.params.landingsBaseUrl;
        if (!landingUrl.endsWith('/')) {
            landingUrl += '/';
        }
        landingUrl += this.form.object.path;

        const win: any = window.open(landingUrl, '_blank');
        win.focus();
    };

    const onGoBack = () => {
        this.form.url.goBack();
    };

    const onSave = async () => {
        const { objectId } = this.form.url.params;
        const object = this.form.object as any;
        const { templateId, values } = object.data;
        const html = getHtml(templateId, values);

        const objectData = {
            ...object,
            data: JSON.stringify({ ...object.data, html }),
        };

        this.form.views.horizontalGroup.isLoading = true;

        if (this.form.zipData) {
            this.form.views.filesInput.isLoading = true;
            await apiClient.uploadMedia(
                undefined,
                'landings',
                objectId,
                this.form.zipData,
                'media',
                () => {}
            );
            this.form.views.filesInput.isLoading = false;
        }

        delete objectData['_id'];

        await apiClient.updateTableItem(
            undefined,
            'landings',
            objectId,
            objectData
        );

        this.form.views.horizontalGroup.isLoading = false;
        this.form.notify({
            text: 'Success!',
            lifetimeMs: 3000,
        });

        this.form.isEditorInEditMode = false;
    };

    return {
        onBeforeOpen,
        onToggleEditorEditMode,
        onOpenLanding,
        onGoBack,
        onSave,
    };
};

export default handlers;
