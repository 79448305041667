import { CustomListFormConfig } from '../../../../../../core/config-builder/forms/list/types';
import handlers from './handlers';

const list: CustomListFormConfig = {
    // content of the list form
    // content of the list form
    defaultUrlQueryParams: {
        sort: [{ columnId: 'createAt', direction: 'desc' }],
    },
    handlers,
    columns: {
        active: {
            onToggle: 'onToggleActiveFlag',
        },
        // ActivateStageMedia: {
        // id: 'ActivateStageMedia',
        // type: 'button',
        //     text: 'Activate',
        //     onClick: 'onActivateRow'
        // },
        FilterByUserAndStage: {
            id: 'FilterByUserAndStage',
            type: 'button',
            text: 'By User',
            onClick: 'onFilterByUser',
        },
    },
};

export default list;
