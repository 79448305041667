import { CustomSiteConfig } from '../../../core/config-builder/types';
import item from './item';

const lostandfound: CustomSiteConfig = {
    title: 'המציאון',
    endpoints: {
        item,
    },
};

export default lostandfound;
