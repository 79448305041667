import { useCallback, useEffect, useMemo, useRef } from 'react';
import {
    addContextToHandlers,
    getHandlerWithContext,
    getValue,
} from '../../../utils';
import Selection from '../../../utils/Selection';
import { SortItem } from '../../native/table/props';
import { useAppContextObserver } from '../context';
import PropsMemoryTable from './props';

const useMemoryTableState = (props: PropsMemoryTable) => {
    const {
        id,
        isSelectable,
        pageDataSource,
        selectionDataSource,
        sortDataSource,
        dataSource,
    } = props;

    const [
        {
            selection,
            isHidden,
            page,
            rowClassName,
            rowStyle,
            sort,
            items,
            onClickItem,
            onMapItems,
        },
        getContext,
    ] = useAppContextObserver((context) => {
        const selection = (() => {
            if (!isSelectable) {
                return;
            }

            if (props.selection) {
                return props.selection;
            }

            if (!selectionDataSource) {
                return;
            }

            const dataSourceValue = getValue(
                props.selectionDataSource,
                context,
                false
            );

            return Selection.unserealizeFromObject(dataSourceValue ?? null);
        })();

        const page = (() => {
            if (props.page || props.page === 0) {
                return props.page;
            }

            if (!pageDataSource) {
                return;
            }

            const pageValue = context.form.getDataSourceValue(pageDataSource);
            if (!pageValue) {
                return;
            }

            return Number(pageValue) - 1;
        })();

        const rowClassName = getValue(props.rowClassName, context, false);

        const rowStyle = getValue(props.rowStyle, context, false);

        const sort = (() => {
            if (props.sort) {
                return props.sort;
            }

            if (!sortDataSource) {
                return [];
            }

            const dataSourceValue = getValue(
                props.sortDataSource,
                context,
                false
            );
            if (!dataSourceValue) {
                return undefined;
            }

            return dataSourceValue;
        })();

        let { items } = props;
        if (dataSource) {
            items = context.form.getDataSourceValue(dataSource)!;
        }

        const onClickItem = getHandlerWithContext(
            props.onClickItem,
            context,
            false
        );

        const onMapItems = getHandlerWithContext(
            props.onMapItems,
            context,
            false
        );

        return {
            selection,
            isHidden: getValue(props.isHidden, context),
            page,
            rowClassName,
            rowStyle,
            sort,
            items,
            onClickItem,
            onMapItems,
        };
    });

    const refTable = useRef<any>();

    const columns = useMemo(() => {
        return props.columns.map((column) =>
            addContextToHandlers(column, getContext())
        );
    }, [getContext, props.columns]);

    useEffect(() => {
        return () => {
            getContext().form.cleanViewCallbacks(id);
        };
    }, [id, getContext]);

    const onChangePage = useCallback(
        (newPage: number) => {
            if (pageDataSource) {
                getContext().form.setDataSourceValue(
                    pageDataSource,
                    Number(newPage) + 1
                );
            }

            if (!props.onChangePage) {
                return;
            }

            const handler = getHandlerWithContext(
                props.onChangePage,
                getContext(),
                false
            );
            if (handler) {
                handler(newPage);
            }
        },
        [getContext, pageDataSource, props.onChangePage]
    );

    const onChangeSelection = useCallback(
        (newSelection: Selection) => {
            const serializedSelection = newSelection.serializeToObject();

            if (selectionDataSource) {
                getContext().form.setDataSourceValue(
                    selectionDataSource,
                    serializedSelection
                );
            }

            if (!props.onChangeSelection) {
                return;
            }

            const handler = getHandlerWithContext(
                props.onChangeSelection,
                getContext(),
                false
            );
            if (handler) {
                handler!(serializedSelection);
            }
        },
        [getContext, props.onChangeSelection, selectionDataSource]
    );

    const update = useCallback((silent = true) => {
        if (!refTable.current) {
            return;
        }

        if (silent) {
            refTable.current.updateItems();
        } else {
            refTable.current.update();
        }
    }, []);

    useEffect(() => {
        getContext().form.setupViewCallbacks(id, {
            update,
        });
    }, [id, getContext, update]);

    const onBeforeUpdateItemField = useCallback(
        ({ column, item, value }: any) => {
            getContext().form.notify(
                {
                    text: 'Updating item...',
                },
                'UPDATING_ITEM'
            );
        },
        [getContext]
    );

    const onAfterUpdateItemField = useCallback(
        ({ column, item, value, error }: any) => {
            const context = getContext();
            if (error) {
                context.form.notify(
                    {
                        type: 'error',
                        text: 'Error update item...',
                        lifetimeMs: 3000,
                    },
                    'UPDATING_ITEM'
                );
            } else {
                context.form.notify(
                    {
                        text: 'Update item success',
                        lifetimeMs: 3000,
                    },
                    'UPDATING_ITEM'
                );
            }

            update(true);
        },
        [update, getContext]
    );

    const onChangeSort = useCallback(
        (newSort: SortItem[]) => {
            if (sortDataSource) {
                getContext().form.setDataSourceValue(sortDataSource, newSort);
            }

            if (!props.onChangeSort) {
                return;
            }

            const handler = getHandlerWithContext(
                props.onChangeSort,
                getContext(),
                false
            );

            if (handler) {
                handler(newSort);
            }
        },
        [getContext, sortDataSource, props.onChangeSort]
    );

    return {
        columns,
        isHidden,
        page,
        onChangePage,
        selection,
        onChangeSelection,
        sort,
        onChangeSort,
        rowStyle,
        rowClassName,
        onBeforeUpdateItemField,
        onAfterUpdateItemField,
        items,
        onClickItem,
        onMapItems,
    };
};

export default useMemoryTableState;
