import React from 'react';
import noPhoto from '../../../../../../assets/no-photo.jpg';
import PropsCellImage from './props';
import style from './style.module.scss';

const CellImage = ({ value }: PropsCellImage) => {
    return (
        <div className={style.imageCell}>
            <img
                alt=""
                src={
                    (value
                        ? `${value}?token=${window._AUTH_TOKEN}&resize=54,30`
                        : value) || noPhoto
                }
                onError={(e: any) => {
                    e.target.onerror = null;
                    e.target.src = noPhoto;
                }}
            />
        </div>
    );
};

export default React.memo(CellImage);
