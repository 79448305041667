import { ReactComponent as DeleteIcon } from 'core/assets/icons/remove.svg';
import { useAppContextObserver } from 'core/components/form/context';
import Input from 'core/components/native/input';
import React, { useEffect, useState } from 'react';
import style from './style.module.scss';

const ObjectFields = ({ params }: any) => {
    const [{ isHidden, setItemProperties }, getContext] = useAppContextObserver(
        (context: any) => {
            const isHidden = context.form.handlers.isItemPropertiesHidden();
            const setItemProperties = context.form.handlers.setItemProperties;
            return { isHidden, setItemProperties };
        }
    );

    const [objectParams, setObjectParams] = useState<
        { key: string; value: string }[]
    >([]);

    const handleAdd = (item: any) => {
        setObjectParams((prev) => [...prev, { key: '', value: '' }]);
    };

    const handleDeleteRow = (deletedIndex: number) => () => {
        setObjectParams((prev) => {
            let clone = [...prev];
            delete clone[deletedIndex];
            return clone;
        });
    };

    useEffect(() => {
        if (objectParams.length > 0) {
            var mapped = objectParams.map((item) => ({
                [item.key]: item.value,
            }));
            var newObj = Object.assign({}, ...mapped);
            setItemProperties(newObj);
        } else {
            setItemProperties({});
        }
    }, [objectParams]);

    return (
        <div className={style.container} hidden={isHidden}>
            <div className={style.content}>
                <div
                    className={style.items}
                    data-no-items={Object.keys(objectParams).length === 0}
                >
                    {objectParams.map((field, index) => {
                        return (
                            <div key={index} className={style.row}>
                                <div className={style.keyInputContainer}>
                                    <Input
                                        id="key-input"
                                        label="Key"
                                        value={field.key}
                                        onChange={(e: any) => {
                                            const value = e.target.value;
                                            setObjectParams((prev) => {
                                                let clone = [...prev];
                                                clone[index].key = value;
                                                return clone;
                                            });
                                        }}
                                    />
                                </div>
                                <Input
                                    id="value-input"
                                    label="Value"
                                    value={field.value}
                                    onChange={(e: any) => {
                                        const value = e.target.value;
                                        setObjectParams((prev) => {
                                            let clone = [...prev];
                                            clone[index].value = value;
                                            return clone;
                                        });
                                    }}
                                />
                                <DeleteIcon
                                    onClick={handleDeleteRow(index)}
                                    className={style.deleteButton}
                                />
                            </div>
                        );
                    })}
                    <div className={style.addButtonContainer}>
                        {' '}
                        <button onClick={handleAdd}>Add a parameter</button>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default ObjectFields;
