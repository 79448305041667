import { AppContext } from '../../../../components/form/context/types';
import ViewTypes from '../../../../components/form/ViewTypes';
import handlers from './handlers';

const jobsScheduleForm = {
    id: 'jobsScheduleForm',
    type: ViewTypes.Form,
    handlers,
    fields: {
        items: [],
        selection: 'none',
    },
    content: {
        id: 'tabContent',
        type: ViewTypes.HStack,
        style: {
            padding: 0,
            paddingTop: 0,
            flex: 1,
            overflow: 'hidden',
            height: '100%',
            width: '100%',
        },
        // isSplit: true,
        items: [
            {
                id: 'tabContentGroup',
                type: ViewTypes.VStack,
                style: { padding: 15, flex: 1 },
                items: [
                    {
                        id: 'fieldsGroup',
                        type: ViewTypes.VStack,
                        items: [
                            {
                                id: 'paramsGroup',
                                type: ViewTypes.HStack,
                                style: { zIndex: 1500, flexWrap: 'wrap' },
                                items: [
                                    {
                                        id: 'siteIdInput',
                                        type: ViewTypes.EnumInput,
                                        style: {
                                            zIndex: 1500,
                                            maxWidth: 240,
                                            minWidth: 240,
                                            marginLeft: 10,
                                        },
                                        dataSource: 'url.params.siteId.replace',
                                        label: 'Site',
                                        onChangeValue: 'onChangeSiteId',
                                        values: [],
                                        displayError(this: AppContext) {
                                            const siteId =
                                                this.form.getDataSourceValue(
                                                    'url.params.siteId'
                                                );
                                            return (
                                                !siteId ||
                                                siteId === 'undefined' ||
                                                !this.form.views.siteIdInput.values.some(
                                                    (item: any) =>
                                                        item.value === siteId
                                                )
                                            );
                                        },
                                    },
                                    {
                                        id: 'endpointIdInput',
                                        type: ViewTypes.EnumInput,
                                        label: 'Endpoint',
                                        style: {
                                            zIndex: 1000,
                                            maxWidth: 240,
                                            minWidth: 240,
                                            marginLeft: 30,
                                        },
                                        dataSource:
                                            'url.params.endpointId.replace',
                                        onChangeValue: 'onChangeEndpointId',
                                        values: [],
                                        displayError(this: AppContext) {
                                            const endpointId =
                                                this.form.getDataSourceValue(
                                                    'url.params.endpointId'
                                                );
                                            return (
                                                !endpointId ||
                                                endpointId === 'undefined' ||
                                                !this.form.views.endpointIdInput.values.some(
                                                    (item: any) =>
                                                        item.value ===
                                                        endpointId
                                                )
                                            );
                                        },
                                    },
                                    {
                                        id: 'scriptIdInput',
                                        type: ViewTypes.EnumInput,
                                        label: 'Script',
                                        style: {
                                            zIndex: 1000,
                                            maxWidth: 240,
                                            minWidth: 240,
                                            marginLeft: 30,
                                        },
                                        dataSource:
                                            'url.queryParams.scriptId.replace',
                                        onChangeValue: 'onChangeScriptId',
                                        values: [],
                                        displayError(this: AppContext) {
                                            const scriptId =
                                                this.form.getDataSourceValue(
                                                    'url.queryParams.scriptId'
                                                );
                                            return (
                                                !scriptId ||
                                                scriptId === 'undefined' ||
                                                !this.form.views.scriptIdInput.values.some(
                                                    (item: any) =>
                                                        item.value === scriptId
                                                )
                                            );
                                        },
                                    },
                                    {
                                        id: 'commandPanelSpacer',
                                        type: ViewTypes.HStack,
                                        style: { flex: 1 },
                                        items: [],
                                    },
                                    {
                                        id: 'emptyButton',
                                        type: ViewTypes.Button,
                                        role: 'notActive',
                                        text: 'Empty Queue',
                                        onClick: 'onEmptyQueue',
                                    },
                                    {
                                        id: 'pauseButton',
                                        type: ViewTypes.Button,
                                        role: 'notActive',
                                        text: 'Pause Queue',
                                        onClick: 'onPause',
                                    },
                                    {
                                        id: 'resumeButton',
                                        type: ViewTypes.Button,
                                        role: 'notActive',
                                        text: 'Resume Queue',
                                        onClick: 'onResume',
                                    },
                                    {
                                        id: 'scheduleButton',
                                        type: ViewTypes.Button,
                                        role: 'active',
                                        text: 'Add job',
                                        onClick: 'onOpenAddJobDialog',
                                    },
                                ],
                            },
                            {
                                id: 'paramsGroup',
                                type: ViewTypes.HStack,
                                style: { zIndex: 1500, flexWrap: 'wrap' },
                                isHidden: '!isNew',
                                items: [
                                    {
                                        id: 'newJobNameInput',
                                        type: ViewTypes.TextInput,
                                        label: 'Name',
                                        isHidden: 'isNew',
                                        dataSource: 'newJobName',
                                    },
                                ],
                            },
                            {
                                id: 'jobsTable',
                                type: ViewTypes.MemoryTable,
                                pageSize: 15,
                                // onClickItem: "onOpenPropertyDialog",
                                isSelectable: true,
                                selectionDataSource: 'selection',
                                dataSource: 'items',
                                onClickItem: 'onClickRow',
                                columns: [
                                    {
                                        id: 'id',
                                        fieldName: 'id',
                                        type: 'text',
                                        title: 'Id',
                                    },
                                    {
                                        id: 'name',
                                        fieldName: 'name',
                                        type: 'text',
                                        title: 'Name',
                                    },
                                    {
                                        id: 'status',
                                        fieldName: 'type',
                                        type: 'text',
                                        title: 'Status',
                                    },
                                    {
                                        id: 'attemptsMade',
                                        fieldName: 'attemptsMade',
                                        type: 'text',
                                        title: 'Attempts Made',
                                    },
                                    {
                                        id: 'finishedOn',
                                        fieldName: 'finishedOn',
                                        type: 'date',
                                        title: 'Finished On',
                                    },
                                    {
                                        id: 'processedOn',
                                        fieldName: 'processedOn',
                                        type: 'date',
                                        title: 'Processed On',
                                    },
                                    {
                                        id: 'progress',
                                        fieldName: 'progress',
                                        type: 'number',
                                        title: 'Progress',
                                    },
                                    {
                                        id: 'delay',
                                        fieldName: 'delay',
                                        type: 'number',
                                        title: 'Delay',
                                    },
                                    {
                                        id: 'optsDelay',
                                        fieldName: 'opts.delay',
                                        type: 'number',
                                        title: 'Current Delay',
                                    },
                                    {
                                        id: 'returnValue',
                                        fieldName: 'returnValue',
                                        type: 'text',
                                        title: 'Return Value',
                                    },
                                    {
                                        id: 'timestamp',
                                        fieldName: 'timestamp',
                                        type: 'date',
                                        title: 'Timestamp',
                                    },
                                    {
                                        id: 'failedReason',
                                        fieldName: 'failedReason',
                                        type: 'text',
                                        title: 'Failed Reason',
                                    },
                                    // {
                                    //     id: 'opts.attempts',
                                    //     fieldName: 'opts.attempts',
                                    //     type: 'number',
                                    //     title: 'Opts Attempts',
                                    // },
                                    // {
                                    //     id: 'opts.delay',
                                    //     fieldName: 'opts.delay',
                                    //     type: 'number',
                                    //     title: 'Opts Delay',
                                    // },
                                    // {
                                    //     id: 'opts.removeOnComplete',
                                    //     fieldName: 'opts.removeOnComplete',
                                    //     type: 'boolean',
                                    //     title: 'Opts RemoveOnComplete',
                                    // },
                                    // {
                                    //     id: 'opts.removeOnFail',
                                    //     fieldName: 'opts.removeOnFail',
                                    //     type: 'boolean',
                                    //     title: 'Opts RemoveOnFail',
                                    // },
                                ],
                            },
                        ],
                    },
                ],
            },
        ],
    },
};

export default jobsScheduleForm;
