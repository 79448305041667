import copy from 'copy-to-clipboard';
import moment from 'moment';
import {
    AppContext,
    FormFunctionGetHandlers,
    FormHandlers,
} from '../../../../../core/components/form/context/types';
import apiClient from '../../../../../core/requests/api';

const DEFAULT_TOP_USERS_IN_TEAMS_TITLE = 'משתמשים מובילים בקבוצת ';

const convertDateFilterToQuery = (
    field: string,
    { startDate, endDate }: any
): string => {
    if (!startDate && !endDate) {
        return '';
    }

    const startMoment = moment(startDate, 'YYYY-MM-DDTHH:mm:ss');
    const endMoment = moment(endDate, 'YYYY-MM-DDTHH:mm:ss');

    const dateQueryFormat = 'YYYY-MM-DDTHH:mm:ss';

    if (startDate && endDate) {
        return `${field}:[${startMoment.format(
            dateQueryFormat
        )} TO ${endMoment.format(dateQueryFormat)}]`;
    }

    if (startDate) {
        return `${field}:[${startMoment.format(dateQueryFormat)} TO *]`;
    }

    return `${field}:[* TO ${endMoment.format(dateQueryFormat)}]`;
};

let mappedVillans: any[] = [];

const handlers: FormFunctionGetHandlers = function (
    this: AppContext
): FormHandlers {
    const collectVillans = async () => {
        const evilQuery = 'isEvil:true';

        const findallEvilUsersQueryParams = `size=1000&q=${evilQuery}`;
        const evilUsers = await apiClient.callSearchRequest(
            'cbc',
            'flappyplayer',
            findallEvilUsersQueryParams
        );

        mappedVillans = evilUsers.hits.hits.map((item: any) => {
            return item._source.user.username;
        });
    };
    const loadTopUsersTotal = async () => {
        this.form.topUsers = [];

        const sort = this.form.topUsersTableSort ?? [];
        const sortBy = {
            field: 'objects',
            direction: 'desc',
        };

        if (sort.length > 0) {
            const sortItem = sort[0];
            sortBy.field = sortItem.columnId;
            sortBy.direction = sortItem.direction;
        }

        const aggs = {
            aggs: {
                users: {
                    terms: {
                        field: 'user.username',
                        size: 10,
                        order: { [sortBy.field]: sortBy.direction },
                    },
                    aggs: {
                        score: {
                            max: {
                                field: 'finalScore',
                            },
                        },
                        distance: {
                            max: {
                                field: 'finalDistance',
                            },
                        },
                        time: {
                            max: {
                                field: 'finalTime',
                            },
                        },
                        objects: {
                            max: {
                                field: 'finalObjects',
                            },
                        },
                    },
                },
            },
        };

        const { startDate, endDate } = this.form;
        const dateFilter = convertDateFilterToQuery('createdDate', {
            startDate,
            endDate,
        });
        let q = `${dateFilter}`;
        if (mappedVillans.length > 0) {
            q += ` AND user.username:(NOT ${mappedVillans.join(' NOT ')})`;
        }

        const queryParams = `size=0&aggs=${JSON.stringify(aggs)}&q=${q}`;
        const response = await apiClient.callSearchRequest(
            'cbc',
            'flappyscore',
            queryParams
        );

        const rows = response.aggregations.users.buckets.map((item: any) => {
            return {
                userId: item.key,
                distance: item.distance.value,
                time: item.time.value,
                score: item.score.value,
                objects: item.objects.value,
            };
        });

        this.form.topUsers = rows;
    };

    const loadTopTeams = async () => {
        this.form.topTeams = [];
        const sort = this.form.topTeamsTableSort;
        const sortBy = {
            field: 'score',
            direction: 'desc',
        };

        if (sort.length > 0) {
            const sortItem = sort[0];
            sortBy.field = sortItem.columnId;
            sortBy.direction = sortItem.direction;
        }

        const aggs = {
            aggs: {
                teams: {
                    terms: {
                        field: 'teamId',
                        size: 20,
                        order: { [sortBy.field]: sortBy.direction },
                    },
                    aggs: {
                        score: {
                            sum: {
                                field: 'finalScore',
                            },
                        },
                        distance: {
                            sum: {
                                field: 'finalDistance',
                            },
                        },
                        time: {
                            sum: {
                                field: 'finalTime',
                            },
                        },
                        objects: {
                            sum: {
                                field: 'finalObjects',
                            },
                        },
                    },
                },
            },
        };

        const { startDate, endDate } = this.form;
        const q = convertDateFilterToQuery('createdDate', {
            startDate,
            endDate,
        });

        const queryParams = `size=0&sort=distance:desc&aggs=${JSON.stringify(
            aggs
        )}&q=${q}`;
        const response = await apiClient.callSearchRequest(
            'cbc',
            'flappyscore',
            queryParams
        );

        const topTeams = response.aggregations.teams.buckets.map(
            (item: any, index: number) => {
                return {
                    isSelected: index === 0,
                    teamId: item.key,
                    distance: item.distance.value,
                    time: item.time.value,
                    score: item.score.value,
                    objects: item.objects.value,
                };
            }
        );

        this.form.topTeams = topTeams;
    };

    const loadUsersOfTeam = async (teamId: string) => {
        this.form.topUsersInTeam = [];

        const sort = this.form.topUsersInTeamTableSort;
        const sortBy = {
            field: 'score',
            direction: 'desc',
        };

        if (sort.length > 0) {
            const sortItem = sort[0];
            sortBy.field = sortItem.columnId;
            sortBy.direction = sortItem.direction;
        }

        const aggs = {
            aggs: {
                users: {
                    terms: {
                        field: 'user.username',
                        size: 10,
                        order: { [sortBy.field]: sortBy.direction },
                    },
                    aggs: {
                        score: {
                            max: {
                                field: 'finalScore',
                            },
                        },
                        distance: {
                            max: {
                                field: 'finalDistance',
                            },
                        },
                        time: {
                            max: {
                                field: 'finalTime',
                            },
                        },
                        objects: {
                            max: {
                                field: 'finalObjects',
                            },
                        },
                    },
                },
            },
        };

        const { startDate, endDate } = this.form;
        const dateFilter = convertDateFilterToQuery('createdDate', {
            startDate,
            endDate,
        });
        let q = `teamId:"${teamId}"`;
        if (dateFilter) {
            q += ` AND ${dateFilter}`;
        }

        if (mappedVillans.length > 0) {
            q += ` AND user.username:(NOT ${mappedVillans.join(' NOT ')})`;
        }

        const queryParams = `size=0&sort=distance:desc&aggs=${JSON.stringify(
            aggs
        )}&q=${q}`;
        const response = await apiClient.callSearchRequest(
            'cbc',
            'flappyscore',
            queryParams
        );

        const rows = response.aggregations.users.buckets.map((item: any) => {
            return {
                teamId,
                userId: item.key,
                distance: item.distance.value,
                time: item.time.value,
                score: item.score.value,
                objects: item.objects.value,
            };
        });

        this.form.topUsersInTeam = rows;
    };

    const loadNumberOfPlayedGames = async () => {
        this.form.playedGamesNumber = '';

        const aggs = {
            aggs: {
                games_count: {
                    value_count: {
                        field: 'user.id',
                    },
                },
            },
        };

        const { startDate, endDate } = this.form;
        const q = convertDateFilterToQuery('createdDate', {
            startDate,
            endDate,
        });
        const queryParams = `size=0&sort=distance:desc&aggs=${JSON.stringify(
            aggs
        )}&q=${q}`;
        const response = await apiClient.callSearchRequest(
            'cbc',
            'flappyscore',
            queryParams
        );

        this.form.playedGamesNumber = response.aggregations.games_count.value;
    };

    const loadNumberOfPlayers = async () => {
        this.form.numberOfPlayers = '';

        const aggs = {
            aggs: {
                players_count: {
                    value_count: {
                        field: 'user.id',
                    },
                },
            },
        };

        const queryParams = `size=0&aggs=${JSON.stringify(aggs)}`;
        const response = await apiClient.callSearchRequest(
            'cbc',
            'flappyplayer',
            queryParams
        );

        this.form.numberOfPlayers = response.aggregations.players_count.value;
    };

    const loadAveragePlayTime = async () => {
        this.form.averagePlayTime = '';
        const aggs = {
            aggs: {
                avg_play_time: {
                    avg: {
                        field: 'finalTime',
                    },
                },
            },
        };
        const { startDate, endDate } = this.form;
        const q = convertDateFilterToQuery('createdDate', {
            startDate,
            endDate,
        });
        const queryParams = `size=0&sort=distance:desc&aggs=${JSON.stringify(
            aggs
        )}&q=${q} AND finalTime:[1 TO *]`;
        const response = await apiClient.callSearchRequest(
            'cbc',
            'flappyscore',
            queryParams
        );
        this.form.averagePlayTime = Math.round(
            (response.aggregations.avg_play_time.value * 100) / 100
        );
    };

    const loadAverageBottlesCollected = async () => {
        this.form.averageBottlesCollected = '';
        const aggs = {
            aggs: {
                avg_bottles_collected: {
                    avg: {
                        field: 'finalObjects',
                    },
                },
            },
        };
        const { startDate, endDate } = this.form;
        const q = convertDateFilterToQuery('createdDate', {
            startDate,
            endDate,
        });
        const queryParams = `size=0&sort=distance:desc&aggs=${JSON.stringify(
            aggs
        )}&q=${q} AND finalTime:[1 TO *]`;
        const response = await apiClient.callSearchRequest(
            'cbc',
            'flappyscore',
            queryParams
        );
        this.form.averageBottlesCollected = Math.round(
            (response.aggregations.avg_bottles_collected.value * 100) / 100
        );
    };
    const loadNumberOfActiveUsers = async () => {
        this.form.numberOfActiveUsers = '';

        const aggs = {
            aggs: {
                active_users_count: {
                    cardinality: {
                        field: 'user.id',
                        precision_threshold: 1,
                    },
                },
            },
        };

        const { startDate, endDate } = this.form;
        const q = convertDateFilterToQuery('createdDate', {
            startDate,
            endDate,
        });
        const queryParams = `size=0&sort=distance:desc&aggs=${JSON.stringify(
            aggs
        )}&q=${q}`;
        const response = await apiClient.callSearchRequest(
            'cbc',
            'flappyscore',
            queryParams
        );

        this.form.numberOfActiveUsers =
            response.aggregations.active_users_count.value;
    };

    const updateTopUsersInTeamTitle = async (teamId: string) => {
        this.form.topUsersInTeamTitle = DEFAULT_TOP_USERS_IN_TEAMS_TITLE;
        const teamData = await apiClient.getItemFromTable(
            'cbc',
            'flappyteam',
            teamId
        );
        this.form.topUsersInTeamTitle = `${DEFAULT_TOP_USERS_IN_TEAMS_TITLE} (${teamData.title})`;
    };

    const loadTeamsData = async () => {
        this.form.topUsersInTeam = [];
        await loadTopTeams();
        const teams = this.form.topTeams;
        if (teams.length === 0) {
            return;
        }
        const { teamId } = teams[0];
        await updateTopUsersInTeamTitle(teamId);
        await loadUsersOfTeam(teamId);
    };

    const loadData = async () => {
        await collectVillans();
        loadNumberOfPlayedGames();
        loadNumberOfPlayers();
        loadNumberOfActiveUsers();
        loadTeamsData();
        loadAveragePlayTime();
        loadAverageBottlesCollected();
        loadTopUsersTotal();
        totalBottlesCollected();
    };

    const onBeforeOpen = async () => loadData();

    const onChangeStartDate = (newStartDate: string) => {
        this.form.startDate = newStartDate;
        loadData();
    };

    const onChangeEndDate = async (newEndDate: string) => {
        this.form.endDate = newEndDate;
        await loadData();
    };

    const onSelectTopTeamRow = async (selectedRow: any) => {
        const selectedTeamId = selectedRow.teamId;
        this.form.topTeams = this.form.topTeams.map((row: any) => ({
            ...row,
            isSelected: row.teamId === selectedTeamId,
        }));

        await updateTopUsersInTeamTitle(selectedTeamId);
        await loadUsersOfTeam(selectedTeamId);
    };

    const onOpenUserCard = async (item: any) => {
        const { items } = await apiClient.getItemsFromTable(
            'cbc',
            'profile',
            [
                {
                    direction: 'asc',
                    field: 'user.id',
                },
            ],
            [
                {
                    field: 'user.username',
                    include: [item.userId],
                },
            ],
            0,
            1,
            undefined
        );

        if (items.length < 0) {
            return;
        }

        this.form.url.push(`/cbc/profile/item/${items[0].id}/info`);
    };

    const onChangeTopTeamsTableSort = (newSort: any) => {
        loadTeamsData();
    };

    const onBeforeLoadReferences = async ({ items }: any) => {
        const userIds = items.map((item: any) => item.userId);
        if (userIds.length === 0) {
            return;
        }

        const profiles = await apiClient.getItemsByIdFromTable(
            'cbc',
            'profile',
            userIds,
            'user.username'
        );

        items.forEach((item: any) => {
            item.profileId = profiles[item.userId] && profiles[item.userId].id;
        });
    };

    const copyPhone = async (item: any) => {
        console.log(item);
        const { items } = await apiClient.getItemsFromTable(
            'cbc',
            'profile',
            [
                {
                    direction: 'asc',
                    field: 'user.id',
                },
            ],
            [
                {
                    field: 'user.username',
                    include: [item.userId],
                },
            ],
            0,
            1,
            undefined
        );
        copy(items[0].phone);

        this.form.notify({
            text: 'Phone copied!',
            lifetimeMs: 3000,
        });
    };

    const onChangeTopUserSort = () => {
        loadTopUsersTotal();
    };

    const totalBottlesCollected = async () => {
        this.form.totalBottlesCollected = '';
        const aggs = {
            aggs: {
                total_bottles_collected: {
                    sum: {
                        field: 'finalObjects',
                    },
                },
            },
        };
        const { startDate, endDate } = this.form;
        const q = convertDateFilterToQuery('createdDate', {
            startDate,
            endDate,
        });
        const queryParams = `size=0&sort=distance:desc&aggs=${JSON.stringify(
            aggs
        )}&q=${q} AND finalTime:[1 TO *]`;
        const response = await apiClient.callSearchRequest(
            'cbc',
            'flappyscore',
            queryParams
        );
        this.form.totalBottlesCollected =
            response.aggregations.total_bottles_collected.value;
    };

    return {
        onChangeTopUserSort,
        copyPhone,
        onBeforeOpen,
        onChangeStartDate,
        onChangeEndDate,
        onSelectTopTeamRow,
        onOpenUserCard,
        onChangeTopTeamsTableSort,
        onBeforeLoadReferences,
    };
};

export default handlers;
