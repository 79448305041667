import fileDialog from 'file-dialog';
import { useReducer, useRef, useState } from 'react';
import { getFileExtension } from '../../../config-builder/utils';
import { useGlobalStoreObserver } from '../../../globalStore/definition';
import { blobToDataURL } from '../../../utils';
import PropsUploadInput, { FileData } from './props';

const useUploadInputState = (props: PropsUploadInput) => {
    const { multiple, extensionsWithDot } = props;
    const [isDragOver, setIsDragOver] = useState(false);
    const [value, setValue] = useState('');
    const refFileUploadInput = useRef<HTMLInputElement>(null);
    const [, forceUpdate] = useReducer((x) => x + 1, 0);
    const [pushNotification] = useGlobalStoreObserver(
        (store) => store.notifications.dispatch.pushNotification
    );

    const onDragOver = () => {
        setIsDragOver(true);
    };

    const onDragLeave = () => {
        setIsDragOver(true);
    };

    const onChangeImage = (e: any) => {
        e.preventDefault();
        onSelectFiles(e.target.files);
        // Array.from(e.target.files).forEach((file: any) => onSelectFiles(file));
    };

    const forceUpdateInput = () => {
        setValue('');
        forceUpdate();
    };

    const onSelectFiles = async (files: FileList) => {
        forceUpdateInput();

        const filteredFiles: File[] = [];

        for (let fileIndex = 0; fileIndex < files.length; fileIndex++) {
            const file = files[fileIndex];

            if (props.maxSizeKB && file.size > props.maxSizeKB * 1024) {
                pushNotification({
                    data: {
                        lifetimeMs: 5000,
                        type: 'error',
                        text: `Max size for the file "${file.name}" is  ${props.maxSizeKB}KB`,
                    },
                });
                continue;
            }

            const extension = getFileExtension(file.name);
            if (!extensionsWithDot?.includes(`.${extension}`)) {
                pushNotification({
                    data: {
                        lifetimeMs: 5000,
                        type: 'error',
                        text: `File type is not allowed "${file.name}"`,
                    },
                });
                continue;
            }

            filteredFiles.push(file);
        }

        if (filteredFiles.length === 0) {
            return;
        }

        const filesData: FileData[] = await Promise.all(
            filteredFiles.map(async (file) => {
                if (props.type === 'dataUrl') {
                    return {
                        file,
                        data: await blobToDataURL(file),
                    };
                }

                if (props.type === 'text') {
                    return { file, data: await file.text() };
                }

                throw new Error(`Type is not implemented: ${props.type}`);
            })
        );

        props.onSelected(filesData);
    };

    const onDrop = (files: FileList | null) => {
        setIsDragOver(true);
        if (!files) {
            return;
        }

        onSelectFiles(files);
    };

    const onClickBrowseButton = async () => {
        const files = await fileDialog({
            multiple: multiple,
            accept: extensionsWithDot!.join(','),
        });

        if (files && files.length > 0) {
            onSelectFiles(files);
        }
    };

    return {
        isDragOver,
        value,
        refFileUploadInput,
        onDragOver,
        onDragLeave,
        onDrop,
        onChangeImage,
        onClickBrowseButton,
    };
};

export default useUploadInputState;
