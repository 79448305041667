import { CustomSiteConfig } from '../../../core/config-builder/types';
import category from './category';
import comment from './comment';
import contact from './contact';
import item from './item';

const lostandfound: CustomSiteConfig = {
    title: 'המציאון החדש',
    endpoints: {
        item,
        category,
        comment,
        contact,
    },
};

export default lostandfound;
