import commentManager from 'core/components/comment-manager';
import { VStack } from 'core/components/form/context/types';
import ViewTypes from 'core/components/form/ViewTypes';
import { CustomEndpointConfig } from 'core/config-builder/types';

const commentManagerTabContent: VStack = {
    id: 'commentManagerTabContent',
    type: ViewTypes.VStack,
    style: { padding: 15, position: 'relative', paddingTop: 0 },
    items: [
        {
            id: 'chart',
            type: ViewTypes.CustomComponent,
            component: commentManager,
            minisite: 'wiki',
            itemEndPoint: 'comment',
            commentEndPointItemProperty: 'articleId',
        },
    ],
};
const article: CustomEndpointConfig = {
    title: 'כתבות',
    forms: {
        item: {
            tabs: [
                {
                    id: 'commentManager',
                    type: ViewTypes.TabPage,
                    title: 'Comment Manager',
                    content: commentManagerTabContent,
                },
            ],
        },
    },
};

export default article;
