import { NotificationData } from '../../components/notifications/props';
import { initialState, NotificationsState } from './state';

const reducers = {
    resetData:
        () =>
        (state: NotificationsState): NotificationsState => {
            return initialState;
        },

    hideItem:
        (data: { id: RegExp | string }) =>
        (state: NotificationsState): NotificationsState => {
            return {
                ...state,
                items: state.items.filter((item) => {
                    if (data.id instanceof RegExp) {
                        return !data.id.test(item.id);
                    }
                    return item.id !== data.id;
                }),
            };
        },

    pushItem:
        (notificationData: { id?: string; data: NotificationData }) =>
        (state: NotificationsState): NotificationsState => {
            const { items } = state;

            const notification: NotificationData = {
                ...notificationData.data,
                id: notificationData.id ?? `${Date.now()}`,
            };

            let newItems: NotificationData[] = [];

            const isExist = items.find((item) => item.id === notification.id);

            if (isExist) {
                newItems = items.map((item) => {
                    if (item.id === notification.id) {
                        return notification;
                    }
                    return item;
                });
            } else {
                newItems = state.items.slice();
                newItems.push(notification);
            }

            return {
                ...state,
                items: newItems,
            };
        },
};

export default reducers;
