import { DataSourcePath } from '../context/types';
import style from './style.module.scss';

interface PropsCounter {
    style?: any;
    className?: any;
    label: string;
    description?: string;
    value?: string;
    dataSource?: DataSourcePath<string>;
}

export default PropsCounter;

export const defaultProps: Partial<PropsCounter> = {
    style: {},
    className: style.counter,
};
