import React from 'react';
import { animated } from 'react-spring';
import propsWithDefaultValues from '../../../utils/propsWithDefaultValues';
import PropsNotification, { defaultProps } from './props';
import style from './style.module.scss';

const Notification = React.forwardRef((props: PropsNotification, ref) => {
    props = propsWithDefaultValues(props, defaultProps);
    const { style: customStyle, icon, text, type: notificationType } = props;

    const onClose = (e: any) => {
        e.stopPropagation();
        props.onClose();
    };

    return (
        <animated.div
            className={style.notificationContainer}
            style={customStyle}
        >
            <div
                className={style.notification}
                data-type={notificationType}
                ref={ref as any}
            >
                <div className={style.content} ref={ref as any}>
                    {icon && icon}
                    <div className={style.text}>{text}</div>
                </div>
                <button className={style.closeButton} onClick={onClose}>
                    <i className="ion ion-ios-close" />
                </button>
            </div>
        </animated.div>
    );
});

export default React.memo(Notification);
