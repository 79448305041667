import { ItemFormConfig } from '../../../../core/config-builder/forms/item/types';
const comment: ItemFormConfig = {
    title: 'תגובות',
    isAllowed: ['admin', /[a-zA-Z0-9]+Admin$/],
    forms: {
        list: {
            columns: {
                userId: {
                    id: 'username',
                    title: 'User',
                    type: 'reference',
                    endpointId: 'user',
                    idField: 'username',
                    fieldName: 'user.username',
                },
            },
        },
    },
};

export default comment;
