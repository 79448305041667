import { AppContext } from '../../../../../components/form/context/types';
import ViewTypes from '../../../../../components/form/ViewTypes';
import ItemForm from '../../../../../components/item-form';
import handlers from './handlers';

const form = {
    component: ItemForm,
    handlers,
    fields: {
        object: {
            name: '',
            description: '',
            siteId: '',
            productType: 'default',
            subsrcriptionInterval: '',
            price: 0,
            productStatus: 'enabled',
            infoText: '',
            firstIntervalFree: false,
        },
    },
    title: 'Ledger Product3',
    commands: [
        {
            id: 'backButton',
            text: 'Back',
            onClick(this: AppContext) {
                this.form.url.goBack();
            },
        },
    ],
    content: {
        id: 'rootGroup',
        type: ViewTypes.VStack,
        items: [
            {
                id: 'tabs',
                type: ViewTypes.TabsGroup,
                activeTabId: 'info',
                tabs: [
                    {
                        id: 'info',
                        title: 'Info',
                        type: ViewTypes.TabPage,
                        content: {
                            id: 'infoTabContentGroup',
                            type: ViewTypes.VStack,
                            style: {
                                padding: 15,
                                position: 'relative',
                                paddingTop: 0,
                            },
                            items: [
                                {
                                    id: 'commandPanel',
                                    type: ViewTypes.HStack,
                                    style: {
                                        position: 'absolute',
                                        height: 'unset',
                                        width: 'unset',
                                        left: 0,
                                        right: 0,
                                        background: 'white',
                                        padding: 10,
                                        zIndex: 100,
                                    },
                                    items: [
                                        {
                                            id: 'createButton',
                                            type: ViewTypes.Button,
                                            role: 'active',
                                            text: 'Create',
                                            onClick: 'onSave',
                                            isHidden(this: AppContext) {
                                                const { objectId } = (
                                                    this.form.handlers as any
                                                ).getParams();
                                                return !!objectId;
                                            },
                                        },
                                        {
                                            id: 'saveButton',
                                            type: ViewTypes.Button,
                                            role: 'active',
                                            text: 'Save',
                                            onClick: 'onSave',
                                            isHidden(this: AppContext) {
                                                const { objectId } = (
                                                    this.form.handlers as any
                                                ).getParams();
                                                return !objectId;
                                            },
                                        },
                                    ],
                                },
                                {
                                    id: 'fieldsGroup',
                                    type: ViewTypes.VStack,
                                    style: {
                                        paddingTop: 70,
                                        paddingBottom: 30,
                                        paddingRight: 10,
                                    },
                                    items: [
                                        {
                                            id: 'name',
                                            label: 'Name',
                                            type: ViewTypes.TextInput,
                                            dataSource: 'object.name',
                                        },
                                        {
                                            id: 'description',
                                            label: 'Description',
                                            type: ViewTypes.TextInput,
                                            isMultiLine: true,
                                            dataSource: 'object.description',
                                        },
                                        {
                                            id: 'productStatus',
                                            label: 'Product status',
                                            type: ViewTypes.EnumInput,
                                            dataSource: 'object.productStatus',
                                            values: [
                                                {
                                                    value: 'enabled',
                                                    label: 'Enabled',
                                                },
                                                {
                                                    value: 'disabled',
                                                    label: 'Disabled',
                                                },
                                            ],
                                        },
                                        {
                                            id: 'productType',
                                            label: 'ProductType',
                                            type: ViewTypes.EnumInput,
                                            dataSource: 'object.productType',
                                            values: [
                                                {
                                                    value: 'default',
                                                    label: 'Default',
                                                },
                                                {
                                                    value: 'subscription',
                                                    label: 'Subscription',
                                                },
                                            ],
                                        },
                                        {
                                            id: 'subscriptionInterval',
                                            label: 'Subscription Interval',
                                            type: ViewTypes.IntervalInput,
                                            isHidden(this: AppContext) {
                                                return (
                                                    this.form.object
                                                        .productType !==
                                                    'subscription'
                                                );
                                            },
                                            dataSource:
                                                'object.subscriptionInterval',
                                        },
                                        {
                                            id: 'firstIntervalFree',
                                            label: 'First interval free',
                                            type: ViewTypes.BooleanInput,
                                            isHidden(this: AppContext) {
                                                return (
                                                    this.form.object
                                                        .productType !==
                                                    'subscription'
                                                );
                                            },
                                            dataSource:
                                                'object.firstIntervalFree',
                                        },
                                        {
                                            id: 'price',
                                            label: 'Price',
                                            type: ViewTypes.NumberInput,
                                            dataSource: 'object.price',
                                        },
                                        {
                                            id: 'infoText',
                                            label: 'Info text',
                                            type: ViewTypes.TextInput,
                                            dataSource: 'object.infoText',
                                        },
                                    ],
                                },
                            ],
                        },
                    },
                    {
                        id: 'media',
                        title: 'Media',
                        isHidden: true,
                        type: ViewTypes.TabPage,
                        content: {
                            id: 'mediaTabContentGroup',
                            type: ViewTypes.VStack,
                            items: [],
                        },
                    },
                ],
            },
        ],
    },
};

export default form;
