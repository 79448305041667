import CodeEditor from '../../../../components/form/code-editor';
import { AppContext, Form } from '../../../../components/form/context/types';
import ViewTypes from '../../../../components/form/ViewTypes';
import style from '../style.module.scss';
import handlers from './handlers';

const scriptTabForm: Form = {
    id: 'scriptTabForm',
    type: ViewTypes.Form,
    handlers,
    fields: {
        code: '',
        environment: 'dev',
    },
    content: {
        id: 'tabContent',
        type: ViewTypes.HStack,
        style: {
            padding: 0,
            paddingTop: 0,
            flex: 1,
            overflow: 'hidden',
            height: '100%',
            width: '100%',
        },
        // isSplit: true,
        items: [
            {
                id: 'tabContentGroup',
                type: ViewTypes.VStack,
                style: { padding: 15, flex: 1 },
                items: [
                    {
                        id: 'fieldsGroup',
                        type: ViewTypes.VStack,
                        items: [
                            {
                                id: 'paramsGroup',
                                type: ViewTypes.HStack,
                                style: { zIndex: 1500, flexWrap: 'wrap' },
                                items: [
                                    {
                                        id: 'environmentInput',
                                        type: ViewTypes.EnumInput,
                                        // style: {
                                        //     zIndex: 1500,
                                        //     maxWidth: 240,
                                        //     minWidth: 240,
                                        //     marginLeft: 10,
                                        // },
                                        dataSource: 'environment',
                                        label: 'Environment',
                                        onChangeValue: 'onChangeEnvironment',
                                        displayError: '!environment',
                                        values: [
                                            {
                                                value: 'dev',
                                                label: 'Development',
                                            },
                                            {
                                                value: 'production',
                                                label: 'Production',
                                            },
                                        ],
                                    },
                                    {
                                        id: 'siteIdInput',
                                        type: ViewTypes.EnumInput,
                                        // style: {
                                        //     zIndex: 1500,
                                        //     maxWidth: 240,
                                        //     minWidth: 240,
                                        //     marginLeft: 10,
                                        // },
                                        dataSource: 'url.params.siteId.replace',
                                        displayError(this: AppContext) {
                                            const siteId =
                                                this.form.getDataSourceValue(
                                                    'url.params.siteId'
                                                );
                                            return (
                                                !siteId ||
                                                siteId === 'undefined'
                                            );
                                        },
                                        label: 'Site',
                                        onChangeValue: 'onChangeSiteId',
                                        values: [],
                                    },
                                    {
                                        id: 'endpointIdInput',
                                        type: ViewTypes.EnumInput,
                                        label: 'Endpoint',
                                        // style: {
                                        //     zIndex: 1000,
                                        //     maxWidth: 240,
                                        //     minWidth: 240,
                                        //     marginLeft: 30,
                                        // },
                                        dataSource:
                                            'url.params.endpointId.replace',
                                        onChangeValue: 'onChangeEndpointId',
                                        displayError(this: AppContext) {
                                            const endpointId =
                                                this.form.getDataSourceValue(
                                                    'url.params.endpointId'
                                                );
                                            return (
                                                !endpointId ||
                                                endpointId === 'undefined'
                                            );
                                        },
                                        values: [],
                                    },
                                    {
                                        id: 'scriptType',
                                        type: ViewTypes.EnumInput,
                                        // style: {
                                        //     zIndex: 1500,
                                        //     maxWidth: 200,
                                        //     minWidth: 200,
                                        //     marginLeft: 10,
                                        // },
                                        dataSource:
                                            'url.queryParams.type.replace',
                                        label: 'Type',
                                        onChangeValue: 'onChangeType',
                                        displayError(this: AppContext) {
                                            const scriptType =
                                                this.form.getDataSourceValue(
                                                    'url.queryParams.type'
                                                );
                                            return (
                                                !scriptType ||
                                                scriptType === 'undefined'
                                            );
                                        },
                                        values: [
                                            {
                                                value: 'pre',
                                                label: 'pre',
                                            },
                                            {
                                                value: 'post',
                                                label: 'post',
                                            },
                                        ],
                                    },
                                    {
                                        id: 'eventType',
                                        type: ViewTypes.EnumInput,
                                        label: 'Event',
                                        // style: {
                                        //     zIndex: 1000,
                                        //     maxWidth: 200,
                                        //     minWidth: 200,
                                        // },
                                        dataSource:
                                            'url.queryParams.event.replace',
                                        onChangeValue: 'onChangeEventType',
                                        displayError(this: AppContext) {
                                            const eventType =
                                                this.form.getDataSourceValue(
                                                    'url.queryParams.event'
                                                );
                                            return (
                                                !eventType ||
                                                eventType === 'undefined'
                                            );
                                        },
                                        values: [
                                            {
                                                value: 'Update',
                                                label: 'update',
                                            },
                                            {
                                                value: 'Delete',
                                                label: 'delete',
                                            },
                                            {
                                                value: 'Create',
                                                label: 'create',
                                            },
                                            {
                                                value: 'Search',
                                                label: 'search',
                                            },
                                            {
                                                value: 'Read',
                                                label: 'read',
                                            },
                                        ],
                                    },
                                ],
                            },
                            {
                                id: 'editor',
                                type: ViewTypes.CustomComponent,
                                component: CodeEditor,
                                dataSource: 'code',
                                isHidden: 'isCodeEditorHidden',
                                consoleLogDataSource: 'consoleLog',
                                commands: [
                                    {
                                        id: 'insertTemplate',
                                        title: 'Insert template',
                                        onClick: 'onInsertTemplate',
                                    },
                                    {
                                        id: 'save',
                                        title: 'Save',
                                        onClick: 'onSave',
                                    },
                                ],
                            },
                            {
                                id: 'editorStub',
                                type: ViewTypes.VStack,
                                isHidden: 'isEditorStubHidden',
                                style: {
                                    flexDirection: 'column',
                                    alignItems: 'center',
                                    justifyContent: 'center',
                                    background: 'black',
                                },
                                items: [
                                    {
                                        id: 'editorStubText',
                                        type: ViewTypes.Text,
                                        value: 'Please select all parameters',
                                        style: {
                                            background: '#282822',
                                            padding: 20,
                                            borderRadius: 5,
                                            textAlign: 'center',
                                            color: 'white',
                                        },
                                    },
                                ],
                            },
                        ],
                    },
                    {
                        id: 'commandPanel',
                        type: ViewTypes.HStack,
                        className: style.commandPanel,
                        items: [
                            {
                                id: 'saveButton',
                                type: ViewTypes.Button,
                                role: 'active',
                                text: 'Save',
                                onClick: 'onSave',
                            },
                            {
                                id: 'cancelButton',
                                type: ViewTypes.Button,
                                role: 'notActive',
                                text: 'Cancel',
                                onClick: 'onGoBack',
                            },
                            {
                                id: 'spacer1',
                                type: ViewTypes.HStack,
                                style: { flex: 1 },
                                items: [],
                            },
                            {
                                id: 'deleteButton',
                                type: ViewTypes.Button,
                                role: 'notActive',
                                text: 'Delete',
                                onClick: 'onDelete',
                            },
                            {
                                id: 'historyButton',
                                type: ViewTypes.Button,
                                role: 'notActive',
                                text: 'Show history',
                                onClick: 'onToggleHistory',
                            },
                        ],
                    },
                ],
            },
            {
                id: 'historyGroupContainer',
                type: ViewTypes.VStack,
                className: style.historyGroup,
                items: [
                    {
                        id: 'historyGroupTitle',
                        type: ViewTypes.Text,
                        value: 'History',
                        className: style.historyTitle,
                    },
                    {
                        id: 'historyGroup',
                        type: ViewTypes.VStack,
                        style: { flexDirection: 'column-reverse' },
                        items: [],
                    },
                ],
            },
        ],
    },
};

export default scriptTabForm;
