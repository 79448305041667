import { CSSProperties } from 'react';
import Selection, { SerializedSelection } from '../../../utils/Selection';
import { CallbackRowParams } from '../../native/table/body/row/props';
import { LoadReferencesParams, SortItem } from '../../native/table/props';
import { DataSourcePath, HandlerValue, ValueOrHandler } from '../context/types';

export default interface PropsMemoryTable {
    id: string;
    isHidden?: ValueOrHandler<boolean>;
    isSelectable: boolean;
    dataSource?: DataSourcePath<any[]>;
    items?: any[];
    columns: any[];
    sort?: SortItem[];
    sortDataSource?: DataSourcePath<SortItem[]>;
    onClickItem?: HandlerValue<(item: any) => void>;
    onMapItems?: HandlerValue<
        (params: { items: any[]; total: number }) => Promise<any[]>
    >;
    onChangeSort?: HandlerValue<(newSort: SortItem[]) => void>;
    onChangePage?: HandlerValue<(page: number) => void>;
    selection?: Selection;
    onChangeSelection?: HandlerValue<(selection: SerializedSelection) => void>;
    selectionDataSource?: DataSourcePath<SerializedSelection>;
    defaultSort?: any;
    page?: number;
    pageSize?: number;
    pageDataSource?: DataSourcePath<number>;
    rowClassName?: HandlerValue<(params: CallbackRowParams) => string>;
    rowStyle?: HandlerValue<(params: CallbackRowParams) => CSSProperties>;
    onBeforeLoadReferences?: HandlerValue<
        (params: LoadReferencesParams) => void
    >;
}

export const defaultProps: Partial<PropsMemoryTable> = {
    pageSize: 10,
    defaultSort: [],
};
