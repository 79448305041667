import React, { useState } from 'react';
import Input from '../input';
import PropsList from './props';
import style from './style.module.scss';

const List = (props: PropsList) => {
    const { label, value, isDisabled, placeholder, displayError } = props;
    const [inputValue, setInputValue] = useState('');

    const onKeyPress = (e: any) => {
        if (e.key !== 'Enter') {
            return;
        }

        e.stopPropagation();
        e.preventDefault();
        const inputValue = e.target.value;
        const newValue = [...props.value, inputValue];

        setInputValue('');

        props.onChange({
            value: newValue,
            type: 'add',
            newItem: inputValue,
        });
    };

    const onDeleteItem = (deletedIndex: number) => (e: any) => {
        e.stopPropagation();
        props.onChange({
            value: props.value.filter(
                (_, index: number) => index !== deletedIndex
            ),
            type: 'delete',
            index: deletedIndex,
        });
    };

    const onChange = (e: any) => {
        setInputValue(e.target.value);
    };

    return (
        <div className={style.list} data-role="view">
            <div className={style.header}>
                <Input
                    id="list-input"
                    label={label}
                    value={inputValue}
                    onKeyPress={onKeyPress}
                    onChange={onChange}
                    disabled={isDisabled ?? false}
                    placeholder={placeholder}
                    displayError={displayError}
                />
            </div>
            <div className={style.items}>
                {value.map((item, index) => (
                    <div className={style.item} key={index}>
                        <div className={style.value}>{item}</div>
                        {!isDisabled && (
                            <button
                                onClick={onDeleteItem(index)}
                                className={style.itemButton}
                            >
                                Delete
                            </button>
                        )}
                    </div>
                ))}
            </div>
        </div>
    );
};

export default React.memo(List);
