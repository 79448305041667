import React from 'react';
import PropsStatisticItem from './props';
import style from './style.module.scss';

const StatisticItem = (props: PropsStatisticItem) => {
    const { title, value, color } = props;
    return (
        <div className={style.statisticBlock}>
            <div className={style.title}>{title}</div>
            <div className={style.statistic} style={{ color }}>
                {value}
            </div>
        </div>
    );
};

export default React.memo(StatisticItem);
