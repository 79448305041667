import { CustomSiteConfig } from '../../../core/config-builder/types';
import lead from './lead';

const alproducts: CustomSiteConfig = {
    title: 'א.ל אימפריה',
    endpoints: {
        lead,
    },
};

export default alproducts;
