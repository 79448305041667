import passwordGenerator from 'generate-password-browser';
import { AppContext } from '../../../../../core/components/form/context/types';
import {
    ItemFormFunctionGetHandlers,
    ItemFormHandlers,
} from '../../../../../core/components/item-form/types';
import apiClient from '../../../../../core/requests/api';

const handlers: ItemFormFunctionGetHandlers = function (
    this: AppContext
): ItemFormHandlers {
    const getSiteRoles = (siteId: string) => {
        return [`${siteId}User`, `${siteId}Editor`, `${siteId}Admin`];
    };

    const getRoles = (
        siteIds?: string[]
    ): { value: string; label: string }[] => {
        if (siteIds && !Array.isArray(siteIds)) {
            siteIds = [siteIds];
        }

        let sites: string[] = [];
        // if (this.remoteMetaInfo.sites) {
        // 	sites = Object.getOwnPropertyNames(this.remoteMetaInfo.sites);
        // }

        if (siteIds) {
            sites = siteIds;
        }

        let roles = [
            {
                value: 'admin',
                label: 'admin',
            },
            {
                value: 'landings_admin',
                label: 'landingsAdmin',
            },
        ];

        sites.forEach((siteId) => {
            roles = [
                ...roles,
                ...getSiteRoles(siteId).map((value) => ({
                    value,
                    label: value,
                })),
            ];
        });

        return roles;
    };

    const onBeforeOpen = async () => {
        const sites = Object.getOwnPropertyNames(this.remoteMetaInfo.sites);
        const { objectId } = this.form.url.params;
        const isObjectExist = !!objectId;

        this.form.views.siteId.values = sites.map((value) => ({
            value,
            label: value,
        }));

        if (isObjectExist) {
            const isAdminUser = !!this.form.object.roles.find((role: string) =>
                role.toLowerCase().includes('admin')
            );
            const isCurrentUserGlobalAdmin = this.currentUserData.roles.some(
                (role: string) => role === 'admin'
            );

            this.form.subtitle = 'User Details';
            this.form.title = this.form.object.username;
            this.form.views.passwordGroup.isHidden = true;
            this.form.views.siteId.isMultiple = true;
            this.form.views.siteId.onChangeValue = 'onChangeExistingUserSiteId';
            this.form.views.roles.isDisabled = false;

            this.form.views.passwordChangeRequired.isHidden = !isAdminUser;
            this.form.views.changePasswordDialog.isHidden =
                !isAdminUser && !isCurrentUserGlobalAdmin;
            this.form.views.roles.values = getRoles(this.form.object.siteId);

            if (this.form.url.params.usersSection === 'common') {
                this.form.views.saveButton.isHidden = true;
                this.form.views.username.isDisabled = true;
                this.form.views.email.isDisabled = true;
                this.form.views.roles.isDisabled = true;
                this.form.views.passwordGroup.isHidden = true;
                this.form.views.passwordChangeRequired.isDisabled = true;
                this.form.views.changePasswordButton.isHidden = true;
                this.form.views.changePasswordDialog.isHidden = true;
            }
        } else {
            this.form.subtitle = '';
            this.form.title = 'Create new user';
            this.form.views.passwordGroup.isHidden = false;
            this.form.views.siteId.isMultiple = false;
            this.form.views.siteId.onChangeValue = 'onChangeNewUserSiteId';
            this.form.views.roles.isDisabled = false;

            this.form.views.roles.values = getRoles();
            this.form.views.changePasswordDialog.isHidden = true;
            this.form.views.passwordChangeRequired.isHidden =
                !this.form.url.params &&
                this.form.url.params.usersSection === 'system';
        }
    };

    const onGenerateNewPassword = () => {
        this.form.object.password = passwordGenerator.generate({
            length: 12,
            numbers: true,
            uppercase: true,
            symbols: false,
            excludeSimilarCharacters: true,
        });
    };

    const onCheckRequiredFields = async (): Promise<boolean> => {
        const { object } = this.form;
        const { id, password, username, email, roles } = object;
        const isNewUser = !id;

        let stop = false;

        this.form.hideNotification(/CHECK_USER_FIELDS_*/g);

        const notifyAboutError = (fieldId: string, text: string) => {
            this.form.notify(
                {
                    type: 'error',
                    text,
                },
                `CHECK_USER_FIELDS_${fieldId}`
            );
        };

        if (isNewUser && !password) {
            stop = true;
            this.form.views.password.displayError = true;
            notifyAboutError('password', 'Password is required');
        }

        if (!email) {
            stop = true;
            this.form.views.email.displayError = true;
            notifyAboutError('email', 'Email is required');
        }

        if (!username) {
            stop = true;
            this.form.views.username.displayError = true;
            notifyAboutError('username', 'User name is required');
        }

        if (!roles || (roles && roles.length === 0)) {
            stop = true;
            this.form.views.roles.displayError = true;
            notifyAboutError('roles', 'Roles are required');
        }

        return stop;
    };

    const onFetch = async () => {
        const { object } = this.form;
        let { objectId } = this.form;

        const { roles } = object;
        const siteId = Array.isArray(object.siteId)
            ? object.siteId
            : [object.siteId];

        if (!objectId) {
            const data = {
                ...object,
                siteId: undefined,
            };

            if (object.siteId && object.siteId.length > 0) {
                data.siteId = object.siteId;
            }

            const userData = await apiClient.createUser(data);
            objectId = userData.id;
        } else {
            await apiClient.updateUser(objectId, {
                ...object,
                id: undefined,
                files: undefined, // HACK: hide files object
            });
        }

        await apiClient.registerUserOnSites(objectId, siteId, roles);

        return objectId;
    };

    const isSystemUser = (object: any) => {
        return object.roles.some(
            (role: string) =>
                role.toLowerCase().endsWith('admin') ||
                role.toLowerCase().endsWith('editor')
        );
    };

    const onAfterSave = async (userId: string, object: any): Promise<void> => {
        if (isSystemUser(object)) {
            this.form.url.replace(`/user/system/item/${userId}/info`);
        } else {
            this.form.url.replace(`/user/common/item/${userId}/info`);
        }
    };

    const onChangeNewUserSiteId = () => {
        const { siteId } = this.form.object;

        this.form.object.roles = [];
        this.form.views.roles.isDisabled = false;
        this.form.views.roles.values = getRoles([siteId]);
    };

    const onChangeExistingUserSiteId = () => {
        let { siteId } = this.form.object;

        if (siteId && !Array.isArray(siteId)) {
            siteId = [siteId];
        }

        const newRoles = this.form.object.roles.filter((roleId: string) =>
            siteId.some((id: string) => roleId.startsWith(id))
        );
        this.form.object.roles = newRoles;

        this.form.views.roles.isDisabled = false;
        this.form.views.roles.values = getRoles(siteId);
    };

    const onOpenChangePasswordDialog = () => {
        this.form.views.changePasswordDialog.token = window._AUTH_TOKEN;
        this.form.views.changePasswordDialog.userId = this.form.objectId;
        this.form.views.changePasswordDialog.isOpened = true;
    };

    const onCloseChangePasswordDialog = () => {
        this.form.views.changePasswordDialog.isOpened = false;
    };

    return {
        onBeforeOpen,
        onGenerateNewPassword,
        onCheckRequiredFields,
        onFetch,
        onAfterSave,
        onChangeNewUserSiteId,
        onChangeExistingUserSiteId,
        onOpenChangePasswordDialog,
        onCloseChangePasswordDialog,
    };
};

export default handlers;
