import { AppContext } from '../../../../core/components/form/context/types';
import { CustomEndpointConfig } from '../../../../core/config-builder/types';
import handlers from './handlers';

const lead: CustomEndpointConfig = {
    title: 'לידים',
    metaInfo: {
        properties: {
            whatsapp: {
                name: 'Whatsapp',
            },
        },
    },
    forms: {
        list: {
            handlers,
            // content of the list form
            defaultUrlQueryParams: {
                sort: [
                    {
                        columnId: 'createdAt',
                        direction: 'desc',
                    },
                ],
            },
            columnsOrder: [
                'fullName',
                'phone',
                'products',
                'customers',
                'unit',
                'source',
                'transaction',
                'value',
                'status',
                'createdAt',
                'whatsapp',
            ],
            columns: {
                fullName: {
                    title: 'שם מלא',
                },
                phone: {
                    title: 'טלפון',
                },
                products: {
                    title: 'מוצרים',
                },
                customers: {
                    title: 'מס חיילים',
                },
                unit: {
                    title: 'יחידה',
                },
                createdAt: {
                    title: 'תאריך',
                },
                status: {
                    title: 'סטטוס פניה',
                    updateInPlace: true,
                    style(this: AppContext, { value, column }: any) {
                        switch (value) {
                            case 'ממתין': {
                                return { color: 'red', fontWeight: 'bold' };
                            }
                            case 'טופל': {
                                return { color: '#43bf60' };
                            }
                            default:
                                break;
                        }
                        return {};
                    },
                },
                source: {
                    title: 'מקור הליד',
                },
                value: {
                    title: 'סכום חשבונית',
                    isHidden: true,
                },
                transaction: {
                    title: 'מספר חשבונית',
                    isHidden: true,
                },
                whatsapp: {
                    id: 'whatsapp',
                    type: 'button',
                    title: 'יצירת קשר',
                    text: 'העתקה',
                    onClick: 'onClickWhatsapp',
                },
            },
        },
    },
};

export default lead;
