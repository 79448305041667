import { CustomEndpointConfig } from '../../../../core/config-builder/types';

const termspage: CustomEndpointConfig = {
    metaInfo: {
        properties: {},
    },
    forms: {
        list: {},
    },
};

export default termspage;
