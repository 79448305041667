import { CSSProperties } from 'react';
import { Column, PageReferenceValue } from '../../types';
import style from './style.module.scss';

export interface CallbackRowParams {
    item: any;
    isSelected: boolean;
    isSelectable: boolean;
    columns: Column[];
    pageReferenceValues: PageReferenceValue[] | undefined;
    globalRowIndex: number;
    pageRowIndex: number;
    page: number;
    pageSize: number;
}

interface PropsTableRow {
    idField: string;
    tableId: string;
    isSelected: boolean;
    isSelectable: boolean;
    onChangeSelection: (itemId: string, isSelected: boolean) => void;
    item: any;
    columns: Column[];
    onUpdateItemField: (params: {
        item: any;
        column: Column;
        value: any;
        prevValue: any;
    }) => Promise<void>;
    page: number;
    pageSize: number;
    index: number;
    quickEditCell: any;
    onSetQuickEditCell: (cellId: string | null) => void;
    className?: string | ((params: Readonly<CallbackRowParams>) => string);
    style?:
        | CSSProperties
        | ((params: Readonly<CallbackRowParams>) => CSSProperties);
    onClick: (e: any) => void;
    pageReferenceValues: PageReferenceValue[] | undefined;
    contextMenu: any;
}

export default PropsTableRow;

export const defaultProps = {
    className: style.row,
    style: {},
};
