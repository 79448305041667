import React, { useEffect, useRef } from 'react';
import { useUnmountedFlag } from '../../../utils';
import propsWithDefaultValues from '../../../utils/propsWithDefaultValues';
import PropsSearchInput, { defaultProps } from './props';
import style from './style.module.scss';

const SearchInput = (props: PropsSearchInput) => {
    props = propsWithDefaultValues(props, defaultProps);
    const { debounceMs } = props;
    const refSearchInput = useRef<any>();
    const { executeIfNotUnmounted } = useUnmountedFlag();
    const { placeholder, value, onSearch } = props;

    const refTimeout = useRef<any>();

    const handleSearch = () => {
        const value = refSearchInput.current?.value ?? '';
        if (debounceMs) {
            clearTimeout(refTimeout.current);
            refTimeout.current = setTimeout(() => {
                executeIfNotUnmounted(() => onSearch(value));
            }, debounceMs);
            return;
        }

        onSearch(value);
    };

    useEffect(() => {
        return () => clearTimeout(refTimeout.current);
    }, []);

    const handleKeyUp = (e: any) => {
        if (e.key === 'Enter') {
            handleSearch();
        }
    };

    return (
        <div className={style.searchContainer}>
            <i className="ion ion-ios-search" onClick={handleSearch} />
            <input
                ref={refSearchInput}
                className={style.searchInput}
                onKeyUp={handleKeyUp}
                onChange={handleSearch}
                type="search"
                placeholder={placeholder}
                defaultValue={value}
                disabled={props.isDisabled}
            />
        </div>
    );
};

export default React.memo(SearchInput);
