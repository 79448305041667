import { CustomEndpointConfig } from '../../../../core/config-builder/types';
import forms from './forms';

const products: CustomEndpointConfig = {
    title: 'מוצרים',
    metaInfo: {
        properties: {
            systemProductId: {
                type: 'string',
                format: 'reference',
                endpointId: 'product',
                // siteId: null,
                representationDataSource: 'name',
                sortListByField: 'name',
            },
        },
    },
    forms,
};

export default products;
