import React from 'react';
import Form from '../form';
import PropsDashboard from './props';

const FormComponent = Form as any;

const Dashboard = (props: PropsDashboard) => {
    const { id, content, handlers, ...restBaseFormProps } = props;
    return (
        <FormComponent
            {...restBaseFormProps}
            key={id}
            content={content}
            handlers={handlers}
        />
    );
};

export default React.memo(Dashboard);
