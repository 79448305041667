import { AppContext } from '../../../../core/components/form/context/types';
import MediaManager from '../../../../core/components/form/media-manager';
import ViewTypes from '../../../../core/components/form/ViewTypes';
import { CustomEndpointConfig } from '../../../../core/config-builder/types';

const job: CustomEndpointConfig = {
    title: 'עבודות',
    metaInfo: {
        propertiesOrder: [
            'active',
            'advertiserId',
            'advertiserName',
            'advertiserEmail',
            'listTitle',
            'itemTitle',
            'listDescription',
            'itemDescription',
            'listStores',
            'itemStores',
            'salary',
            'shifts',
            'moreInfo',
            'requirements',
            'logo',
            'logoBackgroundColor',
            'coverPhoto',
            'active',
        ],
        properties: {
            active: {
                name: 'פעיל',
            },
            advertiserId: {
                name: 'לקוח',
            },
            advertiserEmail: {
                name: 'מייל לשליחת ליד',
            },
            listTitle: {
                name: 'כותרת חיצונית',
            },
            listDescription: {
                name: 'תיאור חיצוני',
            },
            listStores: {
                name: 'מיקום חיצוני',
            },
            itemTitle: {
                name: 'כותרת פנימית',
            },
            itemDescription: {
                name: 'תיאור פנימי',
            },
            moreInfo: {
                name: 'מידע נוסף',
            },
            jobNumber: {
                name: 'מספר משרה',
            },
            soldierTime: {
                name: 'יציאות',
            },
            logoBackgroundColor: {
                name: 'צבע מאחורי הלוגו',
            },
            requirements: {
                name: 'דרישות תפקיד',
                isRequired: true,
            },
            shifts: {
                name: 'משמרות',
            },
            salary: {
                name: 'משכורת',
            },
            createdAt: {
                name: 'עודכן ב',
            },
            itemStores: {
                name: 'מיקום פנימי',
            },
            advertiserName: {
                name: 'שם הלקוח',
            },
            coverPhoto: {
                name: 'תמונת רקע',
            },
            logo: {
                name: 'לוגו',
            },
        },
    },
    forms: {
        list: {
            columnsOrder: [
                'logo',
                'advertiserId',
                'jobNumber',
                'listTitle',
                'createdAt',
            ],
            columns: {
                listDescription: {
                    isHidden: true,
                },
                listStores: {
                    isHidden: true,
                },
                itemTitle: {
                    isHidden: true,
                },
                itemDescription: {
                    isHidden: true,
                },
                moreInfo: {
                    isHidden: true,
                },
                soldierTime: {
                    isHidden: true,
                },
                logoBackgroundColor: {
                    isHidden: true,
                },
                requirements: {
                    isHidden: true,
                },
                shifts: {
                    isHidden: true,
                },
                salary: {
                    isHidden: true,
                },
                itemStores: {
                    isHidden: true,
                },
                advertiserName: {
                    isHidden: true,
                },
                coverPhoto: {
                    isHidden: true,
                },
            },
        },
        item: {
            views: {
                advertiserId: {
                    onChangeValue: 'onChangeAdvertiser',
                },
                listTitle: {
                    onChangeValue: 'onChangeListTitle',
                },
                listDescription: {
                    onChangeValue: 'onChangeListDescription',
                },
                listStores: {
                    onChangeValue: 'onChangeListStores',
                },
                moreInfo: {
                    label: 'מידע נוסף',
                    type: ViewTypes.List,
                    dataSource: 'data.moreInfo',
                },
                soldierTime: {
                    label: 'יציאות',
                    type: ViewTypes.List,
                    dataSource: 'data.soldierTime',
                },
            },
            handlers: function (this: AppContext) {
                const onChangeAdvertiser = async (
                    advertiserId: string | undefined
                ) => {
                    if (!advertiserId) {
                        return;
                    }

                    const data = await this.api.getItemFromTable(
                        'jobsearch',
                        'advertiser',
                        advertiserId
                    );

                    this.form.object.advertiserEmail = data.email;
                    this.form.object.advertiserName = data.name;
                    this.form.object.logoBackgroundColor =
                        data.logoBackgroundColor;

                    const logoMediaManager: MediaManager =
                        this.form.mediaManagers.logo;

                    const coverMediaManager: MediaManager =
                        this.form.mediaManagers.coverPhoto;

                    await Promise.all([
                        logoMediaManager.copyFrom(
                            'jobsearch',
                            'advertiser',
                            'logo',
                            advertiserId
                        ),
                        coverMediaManager.copyFrom(
                            'jobsearch',
                            'advertiser',
                            'coverPhoto',
                            advertiserId
                        ),
                    ]);

                    this.form.notify({
                        text: 'The data updated from the advertiser!',
                        lifetimeMs: 3000,
                    });
                };

                const onBeforeOpen = async () => {
                    this.form.data = {
                        moreInfo: JSON.parse(
                            this.form.object?.moreInfo ?? '[]'
                        ),
                        soldierTime: JSON.parse(
                            this.form.object?.soldierTime ?? '[]'
                        ),
                    };

                    // this.form.views.advertiser.onChangeValue = onChangeAdvertiser;
                };

                const onChangeListTitle = (newValue: string) => {
                    this.form.object.itemTitle = newValue;
                };

                const onChangeListDescription = (newValue: string) => {
                    this.form.object.itemDescription = newValue;
                };

                const onChangeListStores = (newValue: string) => {
                    this.form.object.itemStores = newValue;
                };

                const onBeforeSave = async () => {
                    this.form.object.soldierTime = JSON.stringify(
                        this.form.data.soldierTime ?? []
                    );
                    this.form.object.moreInfo = JSON.stringify(
                        this.form.data.moreInfo ?? []
                    );
                };

                return {
                    onBeforeOpen,
                    onChangeAdvertiser,
                    onChangeListTitle,
                    onChangeListDescription,
                    onChangeListStores,
                    onBeforeSave,
                };
            },
        },
    },
};

export default job;
