export default {
    title: 'דיווחים',
    forms: {
        list: {
            // content of the list form
            defaultUrlQueryParams: {
                sort: [
                    {
                        columnId: 'createdAt',
                        direction: 'desc',
                    },
                ],
            },
        },
        item: {
            views: {
                updatedAt: {
                    isHidden: true,
                },
                createdAt: {
                    isHidden: true,
                },
            },
        },
    },
};
