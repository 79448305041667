import { ItemFormConfig } from '../../../../core/config-builder/forms/item/types';
const list: ItemFormConfig = {
    title: 'רשימות',
    isAllowed: ['admin', /[a-zA-Z0-9]+Admin$/],
    metaInfo: {
        properties: {
            title: {
                name: 'כותרת',
            },
            userId: {
                name: 'שם משתמש',
            },
            createdAt: {
                name: 'נוצר ב',
            },
        },
    },
};

export default list;
