import deleteIcon from '../../../../../core/assets/icons/trash-outline.svg';
import { AppContext } from '../../../../../core/components/form/context/types';
import ItemForm from '../../../../../core/components/item-form';
import defaultHandlers from '../../../../../core/config-builder/forms/item/handlers';
import content from './content';
import handlers from './handlers';

const userItemForm = {
    id: 'user-item-form',
    component: ItemForm,
    fields: {
        object: {
            username: '',
            siteId: [],
            roles: [],
        },
    },
    commands: [
        {
            id: 'openDeleteItemDialog',
            text: 'Delete',
            onClick: 'onOpenDeleteItemDialog',
            image: deleteIcon,
        },
    ],
    handlers(this: AppContext) {
        return {
            ...defaultHandlers.call(this),
            ...handlers.call(this),
        };
    },
    content,
};

export default userItemForm;
