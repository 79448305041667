import { CustomEndpointConfig } from '../../../../core/config-builder/types';
import forms from './forms';

const comment: CustomEndpointConfig = {
    metaInfo: {
        properties: {
            parentId: {
                endpointId: 'comment',
                representationDataSource: 'comment',
            },
        },
    },
    forms,
};

export default comment;
