import {
    FormFunctionGetHandlers,
    RawAppContext,
    ValueOrHandler,
    View,
} from './context/types';
import style from './style.module.scss';

export default interface PropsForm {
    id: string;
    title?: ValueOrHandler<string>;
    subtitle?: string;
    content: View;
    handlers?: FormFunctionGetHandlers;
    baseContext?: RawAppContext;
    refContext?: (context: RawAppContext) => void;
    params?: any;
    contentClass?: string;
    commands?: any[]; //FIXME:
    fields?: Record<string, any>;
}

export const defaultProps: Partial<PropsForm> = {
    contentClass: style.content,
};
