import { AppContext } from '../../../../core/components/form/context/types';
import { CustomEndpointConfig } from '../../../../core/config-builder/types';

const creative: CustomEndpointConfig = {
    title: 'קריאייטיב',
    metaInfo: {
        propertiesOrder: [
            'idea',
            'platform',
            'priceRange',
            'insight',
            'hashtag',
            'shortDescription',
            'description',
            'extra',
            'customer',
            'sold',
            'presentedAt',
            'campaignType',
            'score',
            'serverPath',
            'designs',
            'createdAt',
        ],
        properties: {
            idea: {
                name: 'שם הרעיון',
            },
            platform: {
                name: 'פלטפורמה',
            },
            priceRange: {
                name: 'טווח מחירים',
            },
            insight: {
                name: 'תובנה צבאית',
            },
            hashtag: {
                name: 'האשטג תוכן הקמפיין',
            },
            shortDescription: {
                name: 'תיאור מקוצר',
            },
            description: {
                name: 'תיאור מפורט',
            },
            extra: {
                name: 'אקסטרה לפעילות זו',
            },
            customer: {
                name: 'לאיזה לקוח הוצג',
            },
            presentedAt: {
                name: 'מתי הוצג ללקוח',
            },
            sold: {
                name: 'האם נמכר?',
            },
            campaignType: {
                name: 'סוג הפעילות',
            },
            score: {
                name: 'ציון',
            },
            serverPath: {
                name: 'שיוך בתיקיות',
            },
            designs: {
                name: 'עיצובים',
            },
            createdAt: {
                name: 'תאריך הכנסת הקריאייטיב למסמך',
            },
        },
    },

    forms: {
        item: {
            properties: {},
        },
        list: {
            // content of the list form
            defaultUrlQueryParams: {
                sort: [
                    {
                        columnId: 'createdAt',
                        direction: 'desc',
                    },
                ],
            },
            columnsOrder: [
                'idea',
                'platform',
                'priceRange',
                'insight',
                'hashtag',
                'shortDescription',
                'description',
                'extra',
                'customer',
                'sold',
                'presentedAt',
                'campaignType',
                'score',
                'serverPath',
                'designs',
                'createdAt',
            ],
            columns: {
                idea: {
                    title: 'שם הרעיון',
                    style: () => {
                        return {
                            fontWeight: 'bold',
                            width: '500px',
                            height: '45px',
                            lineHeight: '45px',
                        };
                    },
                },
                platform: {
                    title: 'פלטפורמה',
                    isHidden: true,
                },
                priceRange: {
                    title: 'טווח מחירים',
                },
                insight: {
                    title: 'תובנה צבאית',
                    isHidden: true,
                },
                hashtag: {
                    title: 'האשטג תוכן הקמפיין',
                    style: () => {
                        return {
                            width: '500px',
                            height: '45px',
                            'line-height': '45px',
                        };
                    },
                },
                shortDescription: {
                    title: 'תיאור מקוצר',
                    style: () => {
                        return {
                            width: '500px',
                            height: '45px',
                            'line-height': '45px',
                        };
                    },
                },
                description: {
                    title: 'תיאור מפורט',
                    isHidden: true,
                },
                extra: {
                    title: 'אקסטרה לפעילות זו',
                    isHidden: true,
                },
                customer: {
                    title: 'לאיזה לקוח הוצג',
                    style: () => {
                        return { height: '45px', 'line-height': '45px' };
                    },
                },

                presentedAt: {
                    title: 'מתי הוצג ללקוח',
                    style: () => {
                        return { height: '45px', 'line-height': '45px' };
                    },
                },
                sold: {
                    title: 'האם נמכר?',
                },
                campaignType: {
                    title: 'סוג הפעילות',
                },
                score: {
                    title: 'ציון',
                    style: () => {
                        return { height: '45px', 'line-height': '45px' };
                    },
                },
                serverPath: {
                    title: 'שיוך בתיקיות',
                    isHidden: true,
                },
                designs: {
                    title: 'עיצובים',
                },
                createdAt: {
                    title: 'תאריך הכנסת הקריאייטיב למסמך',
                },
            },
            handlers() {
                return {
                    async onBeforeOpen(this: AppContext) {
                        if (
                            !this.currentUserData.roles.includes('admin') &&
                            !this.currentUserData.roles.includes('officeAdmin')
                        )
                            this.form.commands = this.form.commands.filter(
                                (item) => item.id !== 'export'
                            );
                    },
                };
            },
            rowStyle: () => {
                return {
                    background: 'white',
                };
            },
        },
    },
};

export default creative;
