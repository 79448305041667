import { DialogData } from '../../../../../components/form/context/types';
import content from './content';
import handlers from './handlers';

const ledgerItemDialog: DialogData = {
    id: 'ledger-item-dialog',
    content,
    fields: {
        activeTabId: 'info',
        logs: [],
    },
    handlers,
};

export default ledgerItemDialog;
