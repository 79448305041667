import commentManager from 'core/components/comment-manager';
import { VStack } from 'core/components/form/context/types';
import ViewTypes from 'core/components/form/ViewTypes';
import { ItemFormConfig } from '../../../../core/config-builder/forms/item/types';

const commentManagerTabContent: VStack = {
    id: 'commentManagerTabContent',
    type: ViewTypes.VStack,
    style: { padding: 15, position: 'relative', paddingTop: 0 },
    items: [
        {
            id: 'chart',
            type: ViewTypes.CustomComponent,
            component: commentManager,
            minisite: 'lostandfoundv2',
            itemEndPoint: 'comment',
            commentEndPointItemProperty: 'itemId',
        },
    ],
};

const item: ItemFormConfig = {
    title: 'פריטים',
    forms: {
        list: {
            // content of the list form
            defaultUrlQueryParams: {
                sort: [
                    {
                        columnId: 'createdAt',
                        direction: 'desc',
                    },
                ],
            },
        },
        item: {
            tabs: [
                {
                    id: 'commentManager',
                    type: ViewTypes.TabPage,
                    title: 'Comment Manager',
                    content: commentManagerTabContent,
                },
            ],
        },
    },
};

export default item;
