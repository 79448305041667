import { useEffect, useMemo, useRef, useState } from 'react';
import Selection, {
    SerializedSelection,
} from '../../../../../../utils/Selection';
import { FilterItem } from '../../../props';
import PropsColumnReferenceFilter from './props';

const INITIAL_ITEMS = [
    {
        id: 'all',
    },
];

const _getSelectionFromFilterData = (
    filterData: SerializedSelection
): Selection => {
    if (filterData) {
        return Selection.unserealizeFromObject(filterData);
    }

    const selection = new Selection();
    selection.selectAll();

    return selection;
};

function useItemsFilterState(props: PropsColumnReferenceFilter) {
    const {
        siteId,
        endpointId,
        sortListDirection,
        representationDataSource,
        searchByField,
        sortListByField,
        searchItems,
        rawSearchFilter,
        columnId,
    } = props;

    const [selection, setSelection] = useState(() =>
        _getSelectionFromFilterData(props.filterData)
    );
    const [items, setItems] = useState<any[]>(INITIAL_ITEMS);
    const [total, setTotal] = useState(10);
    const [searchPhrase, setSearchPhrase] = useState('');
    const refLoader = useRef(null);

    const sortField: string = useMemo(() => {
        if (sortListByField) {
            return sortListByField;
        }

        const isString =
            typeof representationDataSource === 'string' ||
            (representationDataSource as any) instanceof String;
        if (isString) {
            return representationDataSource as string;
        }

        return '_id';
    }, [sortListByField, representationDataSource]);

    useEffect(() => {
        setSelection(_getSelectionFromFilterData(props.filterData));
    }, [props.filterData, props.isOpened]);

    useEffect(() => {
        setSelection(_getSelectionFromFilterData(props.filterData));
        setSearchPhrase('');
        setTotal(10);
        setItems(INITIAL_ITEMS);
    }, [props.isOpened, props.filterData]);

    const onApply = () => {
        props.onToggleOpen(columnId);
        props.onChangeFilter(columnId, selection.serializeToObject());
    };

    const onChangeFilter = (e: any) => {
        const newSearchPhrase = e.target.value.trim();
        if (searchPhrase === newSearchPhrase) {
            return;
        }

        setItems(INITIAL_ITEMS);
        setTotal(100);
        setSearchPhrase(newSearchPhrase);

        refLoader.current &&
            (refLoader.current! as any).resetLoadMoreRowsCache(true);
    };

    const onToggleItem = (itemId: string) => (newState: string) => {
        const newSelection = selection.copy();

        if (newState === 'checked') {
            newSelection.selectItem(itemId);
        } else {
            newSelection.unselectItem(itemId);
        }

        setSelection(newSelection);
    };

    const onToggleAll = (newState: string) => {
        const newSelection = selection.copy();

        if (newState === 'checked') {
            newSelection.selectAll();
        } else {
            newSelection.unselectAll();
        }

        setSelection(newSelection);
    };

    const _isRowLoaded = ({ index }: any) => {
        const item = items[index];

        return !(!item || item.isLoading);
    };

    const _getItemId = (itemData: any) => {
        const { idField } = props;
        if (idField === '_id') {
            return itemData.id;
        }

        return itemData[idField];
    };

    const oldSelection = _getSelectionFromFilterData(props.filterData);
    const isFiltered = !(!oldSelection || oldSelection.isAllItemsSelected());

    const _loadMoreRows = async ({
        startIndex: startDisplayIndex,
        stopIndex: stopDisplayIndex,
    }: {
        startIndex: number;
        stopIndex: number;
    }) => {
        const startIndex = startDisplayIndex === 0 ? 0 : startDisplayIndex - 1;
        const stopIndex = stopDisplayIndex === 0 ? 0 : stopDisplayIndex - 1;

        let filter: FilterItem[] = [];
        let searchParam;

        if (searchByField && searchPhrase) {
            if (Array.isArray(searchByField)) {
                filter = [
                    (searchByField! as string[])
                        .map(
                            (fieldId: string) =>
                                `${fieldId}:*${searchPhrase.trim()}*`
                        )
                        .join(' OR '),
                ];
            } else {
                filter = [`${searchByField}:*${searchPhrase.trim()}*`];
            }
        } else if (typeof representationDataSource === 'string') {
            filter = [`${representationDataSource}:*${searchPhrase.trim()}*`];
        } else if (!searchByField && searchPhrase) {
            searchParam = searchPhrase;
            filter = [];
        }

        const newItemsBefore = items.slice();
        for (let i = startDisplayIndex; i <= stopDisplayIndex; i++) {
            if (i === 0) {
                continue;
            }

            newItemsBefore[i] = { isLoading: true };
        }

        setItems(newItemsBefore);
        if (rawSearchFilter && rawSearchFilter.length > 0) {
            if (filter.length > 0) {
                filter.push('AND');
            }

            rawSearchFilter.forEach((item) => filter.push(item));
        }

        const { total, items: loadedItems } = await searchItems(
            siteId,
            endpointId,
            [
                {
                    field: sortField, // FIXME: Need to check
                    direction: sortListDirection ?? 'asc',
                },
            ],
            filter,
            startIndex,
            stopIndex - startIndex + 1,
            searchParam
        );

        const newItemsAfter = items.slice();
        newItemsAfter.length = total + 1;
        for (let itemIndex = 0; itemIndex < loadedItems.length; itemIndex++) {
            const absolutePosition = startIndex + itemIndex + 1;

            newItemsAfter[absolutePosition] = loadedItems[itemIndex];
        }

        setItems(newItemsAfter);
        setTotal(total + 1);
    };

    return {
        isFiltered,
        refLoader,
        selection,
        searchPhrase,
        total,
        items,
        onApply,
        onChangeFilter,
        onToggleAll,
        onToggleItem,
        _isRowLoaded,
        _getItemId,
        _loadMoreRows,
    };
}

export default useItemsFilterState;
