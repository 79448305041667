import {
    AppContext,
    FormFunctionGetHandlers,
    FormHandlers,
    Url,
} from '../../../../components/form/context/types';

const handlers: FormFunctionGetHandlers = function (
    this: AppContext
): FormHandlers {
    const getParams = () => {
        const { siteId } = this.form.url.params;
        return { siteId };
    };

    const updateTableQueryFilter = () => {
        const { siteId } = getParams();
        this.form.views.table.queryFilter = [`siteId:"${siteId}"`];
    };

    const onBeforeOpen = async () => {
        updateTableQueryFilter();
    };

    const onChangeUrl = (newUrl: Url, oldUrl: Url) => {
        updateTableQueryFilter();
    };

    const onCreateNewItem = () => {
        const { siteId } = getParams();

        this.form.url.push(`/${siteId}/ledger-product`);
    };

    const onClickTableItem = (item: any) => {
        const { siteId } = getParams();
        const productId = item.id;

        this.form.url.push(`/${siteId}/ledger-product/${productId}/info`);
    };

    const onGoBack = () => {
        this.form.url.goBack();
    };

    const onOpenDeleteItemsDialog = () => {
        const onDeleteSuccess = () => {
            this.form.views.table.weakCallbacks.update();
            this.form.selection = 'none';
        };

        const { siteId } = getParams();
        const { selection } = this.form;
        if (!selection) {
            return;
        }

        const { search } = this.form.url.queryParams;

        if (selection === 'none') {
            this.form.notify({
                text: 'No selected items',
                type: 'error',
                lifetimeMs: 2000,
            });
            return;
        }

        const filter = [`siteId:"${siteId}"`];

        this.form.pushDialog('deleteItem', {
            endpointId: 'product',
            siteId: undefined,
            selection,
            filter,
            searchPhrase: search,
            queryFilter: [`siteId:"${siteId}"`],
            onSuccess: onDeleteSuccess,
        });
    };

    return {
        onBeforeOpen,
        onChangeUrl,
        onCreateNewItem,
        onClickTableItem,
        onGoBack,
        onOpenDeleteItemsDialog,
    };
};

export default handlers;
