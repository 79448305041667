import { useContext, useLayoutEffect, useMemo, useRef, useState } from 'react';

export interface ContextSelector<OutputValues, Observable> {
    (
        state: Observable,
        prevState: Observable | undefined,
        prevValues: OutputValues | undefined
    ): OutputValues;
}

export interface ContextObserver<Observable> {
    (state: Observable, prevState: Observable | undefined): void;
}

export interface ContextObservableCallbacks<Observable> {
    getCurrentState: () => Observable;
    startListen: (listener: ContextObserver<Observable>) => any;
    stopListen: (listener: any) => void;
}

export const createObserverHook = <Observable>(
    reactContext: React.Context<ContextObservableCallbacks<Observable>>
): (<OutputValues>(
    selector: ContextSelector<OutputValues, Observable>
) => [OutputValues, () => Observable]) => {
    return <OutputValues>(
        selector: ContextSelector<OutputValues, Observable>
    ): [OutputValues, () => Observable] => {
        const {
            getCurrentState,
            startListen,
            stopListen,
        }: ContextObservableCallbacks<Observable> = useContext(reactContext);

        const [currentState, setCurrentState] = useState<OutputValues>(() => {
            const currentState = getCurrentState();
            return selector!(currentState, undefined, undefined);
        });

        const refSelector = useRef(selector);
        refSelector.current = selector;

        useLayoutEffect(() => {
            const listener = startListen((newState, prevState) => {
                setCurrentState((prevValues: any) => {
                    const nextValues: any = refSelector.current(
                        newState,
                        prevState,
                        prevValues
                    );
                    if (nextValues === prevValues) {
                        return prevValues;
                    }

                    if (!prevValues) {
                        return nextValues;
                    }

                    if (Array.isArray(nextValues)) {
                        if ((prevValues as any).length !== nextValues.length) {
                            return nextValues;
                        }

                        if (
                            (prevValues as any).some(
                                (prevItem: any, index: number) =>
                                    prevItem !== nextValues[index]
                            )
                        ) {
                            return nextValues;
                        }

                        return prevValues;
                    }

                    const prevKeys = Object.getOwnPropertyNames(prevValues);
                    const nextKeys = Object.getOwnPropertyNames(nextValues);
                    if (prevKeys.length !== nextKeys.length) {
                        return nextValues;
                    }

                    if (
                        prevKeys.some(
                            (prevKey) =>
                                prevValues[prevKey] !== nextValues[prevKey]
                        )
                    ) {
                        return nextValues;
                    }

                    return prevValues;
                });
            });

            return () => {
                stopListen(listener);
            };
        }, [startListen, stopListen]);

        return [currentState, getCurrentState];
    };
};

export const useObservableCallbacksCreator = <Observable>(
    observable: Observable,
    dependencies?: any[]
): ContextObservableCallbacks<Observable> => {
    interface Vars {
        observers: ContextObserver<Observable>[];
        observable: Observable;
    }

    const refVars = useRef<Vars>({
        observers: [] as any,
        observable,
    });

    refVars.current.observable = observable;

    useLayoutEffect(() => {
        refVars.current.observers.forEach((observer, index) => {
            observer(observable, undefined);
        });
    }, [observable, ...(dependencies ?? [])]);

    return useMemo(() => {
        const startListen = (observer: ContextObserver<Observable>) => {
            refVars.current.observers.push(observer);
        };

        const stopListen = (listener: ContextObserver<Observable>) => {
            const listenerIndex = refVars.current.observers.findIndex(
                (item) => item === listener
            );
            if (listenerIndex !== -1) {
                refVars.current.observers.splice(listenerIndex, 1);
            }
        };

        const getCurrentState = () => {
            return refVars.current.observable;
        };

        return { getCurrentState, startListen, stopListen };
    }, []);
};
