import content from './content';
import handlers from './handlers';

export default {
    id: 'job-data-dialog',
    content,
    fields: {
        activeTabId: 'info',
        logs: [],
    },
    handlers,
};
