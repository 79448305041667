import React from 'react';
import useComponentState from './hooks';
import PropsReferenceInput, { defaultProps } from './props';
import style from './style.module.scss';

const AsyncSelect: any = require('react-select/lib/Async').default;

const ReferenceInput = (props: PropsReferenceInput) => {
    const {
        classContainer = defaultProps.classContainer,
        isDisabled,
        isMulti,
        label,
        displayError,
        rawSearchFilter,
        endpointId,
        isCreatable,
        onCreate,
    } = props;

    const { onChange, selectedOption, onLoadOptions } =
        useComponentState(props);

    const customStyles = {
        option: (provided: any, state: any) => ({
            ...provided,
            fontSize: 16,
        }),
    };

    return (
        <div className={classContainer} data-role="view">
            <label className={style.label}>{label}</label>
            <AsyncSelect
                key={`key-${rawSearchFilter}`}
                placeholder=""
                isSearchable
                isDisabled={isDisabled}
                value={selectedOption}
                isMulti={isMulti}
                cacheOptions
                loadOptions={onLoadOptions}
                defaultOptions
                onChange={onChange}
                styles={customStyles}
                classNamePrefix={
                    displayError ? 'reference-input-error' : 'reference-input'
                }
            />
            {isCreatable && (
                <button className={style.createButton} onClick={onCreate}>
                    Create {endpointId}
                </button>
            )}
        </div>
    );
};

export default React.memo(ReferenceInput);
