import {
    AppContext,
    FormFunctionGetHandlers,
} from '../../../../../../core/components/form/context/types';

const handlers: FormFunctionGetHandlers = function (this: AppContext) {
    const onBeforeOpen = async () => {
        this.form.views.createButton.isHidden = false;
    };
    return {
        onBeforeOpen,
    };
};

export default handlers;
