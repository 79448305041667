import React from 'react';
import Header from './header';
import PropsTabsContainer from './props';
import style from './style.module.scss';

const TabsContainer = (props: PropsTabsContainer) => {
    const {
        activeTabId,
        tabs,
        onChangeTab,
        renderContent,
        style: customStyle,
    } = props;
    return (
        <div className={style.tabsContainer} style={customStyle}>
            <Header
                activeTabId={activeTabId}
                onChangeTab={onChangeTab}
                tabs={tabs}
            />
            <div className={style.content}>{renderContent(activeTabId)}</div>
        </div>
    );
};

export default TabsContainer;
