import React from 'react';
import { getHandlerWithContext, getValue } from '../../../utils/context';
import IntervalInputNative from '../../native/interval-input';
import { useAppContextObserver } from '../context';
import PropsIntervalInput from './props';
import style from './style.module.scss';

const IntervalInput = (props: PropsIntervalInput) => {
    const { label, dataSource, displayError } = props;

    const [{ isHidden, isDisabled, value }, getContext] = useAppContextObserver(
        (context) => {
            let { value } = props;
            if (dataSource) {
                value = context.form.getDataSourceValue(dataSource);
            }

            return {
                isHidden: getValue(props.isHidden ?? false, context),
                isDisabled: getValue(props.isDisabled ?? false, context),
                value,
            };
        }
    );

    const handleChangeValue = (value: string | undefined) => {
        if (dataSource) {
            getContext().form.setDataSourceValue(dataSource, value);
        }

        if (!props.onChangeValue) {
            return;
        }

        const handler = getHandlerWithContext(
            props.onChangeValue,
            getContext()
        );
        handler!(value);
    };

    if (isHidden) {
        return null;
    }

    return (
        <div className={style.intervalInputContainer} data-role="view">
            <label className={style.label}>{label}</label>
            <IntervalInputNative
                displayError={displayError}
                isDisabled={isDisabled}
                // placeholder={placeholder}
                value={value}
                onChange={handleChangeValue}
            />
        </div>
    );
};

export default React.memo(IntervalInput);
