import { CustomEndpointConfig } from '../../../../core/config-builder/types';
import { getUserName } from '../../commonFunctions';

const favorites: CustomEndpointConfig = {
    title: 'מועדפים',
    metaInfo: {
        properties: {
            locationId: {
                siteId: 'policeradar',
                endpointId: 'location',
                sortListByField: 'city',
                representationDataSource: (item: any) =>
                    `${item.city} ${item.subLocation}`,
            },
            userId: {
                endpointId: 'user',
                sortListByField: 'username',
                searchByField: ['username', 'email', 'phone', 'name'],
                representationDataSource: getUserName,
            },
        },
    },
};

export default favorites;
