export interface SerializedSelectionObject {
    include?: string[];
    excludeAll?: string[];
}

export type SerializedSelection = null | SerializedSelectionObject | 'none';

class Selection {
    private isInfiniteSelection: boolean;

    private selectedItems: string[];

    private total: number;

    private excludedItems: string[];

    private isMultiSelect: boolean;

    equal(b: Selection): boolean {
        return (
            b.isInfiniteSelection === this.isInfiniteSelection &&
            b.total === this.total &&
            b.isMultiSelect === this.isMultiSelect &&
            b.selectedItems.length === this.selectedItems.length &&
            b.selectedItems.every((id) => this.selectedItems.includes(id)) &&
            b.excludedItems.length === this.excludedItems.length &&
            b.excludedItems.every((id) => this.excludedItems.includes(id))
        );
    }

    constructor(total: number = -1, multiSelect: boolean = true) {
        this.isInfiniteSelection = false;
        this.selectedItems = [];
        this.excludedItems = [];
        this.total = total;
        this.isMultiSelect = multiSelect;
    }

    serializeToObject(): SerializedSelection {
        if (this.isInfiniteSelection && this.excludedItems.length === 0) {
            return null;
        }

        if (!this.isInfiniteSelection) {
            if (this.selectedItems.length === 0) {
                return 'none';
            }
            return {
                include: this.selectedItems,
            };
        }

        return {
            excludeAll: this.excludedItems,
        };
    }

    static unserealizeFromObject(
        data: null | undefined | SerializedSelection
    ): Selection {
        const selection = new Selection();
        if (!data) {
            selection.isInfiniteSelection = true;
            return selection;
        }

        if (data === 'none') {
            selection.isInfiniteSelection = false;
            selection.selectedItems = [];
            return selection;
        }

        if (data.excludeAll && data.excludeAll.length > 0) {
            selection.excludedItems = data.excludeAll;
            selection.isInfiniteSelection = true;
            return selection;
        }

        if (data.include && data.include.length > 0) {
            selection.isInfiniteSelection = false;
            selection.selectedItems = data.include || [];
            return selection;
        }

        // selection._excludedItems = data.excludeAll;
        return selection;
    }

    setTotal = (total: number) => {
        this.total = total;
    };

    copy = () => {
        const newSelection = new Selection();
        newSelection.isInfiniteSelection = this.isInfiniteSelection;
        newSelection.selectedItems = this.selectedItems.slice();
        newSelection.excludedItems = this.excludedItems.slice();
        newSelection.total = this.total;
        newSelection.isMultiSelect = this.isMultiSelect;

        return newSelection;
    };

    selectAll = () => {
        if (!this.isMultiSelect) {
            return;
        }
        this.isInfiniteSelection = true;
        this.excludedItems = [];
        this.selectedItems = [];
    };

    unselectAll = () => {
        this.isInfiniteSelection = false;
        this.excludedItems = [];
        this.selectedItems = [];
    };

    unselectItem = (itemId: string) => {
        if (this.isInfiniteSelection) {
            if (!this.excludedItems.includes(itemId)) {
                this.excludedItems.push(itemId);
            }
        } else {
            this.selectedItems = this.selectedItems.filter(
                (id) => id !== itemId
            );
        }
    };

    selectItem = (itemId: string) => {
        if (this.isInfiniteSelection) {
            if (this.excludedItems.includes(itemId)) {
                this.excludedItems = this.excludedItems.filter(
                    (id) => id !== itemId
                );
            }
        } else if (!this.isMultiSelect) {
            this.selectedItems = [itemId];
        } else if (!this.selectedItems.includes(itemId)) {
            this.selectedItems.push(itemId);
        }
    };

    isAllItemsSelected = () => {
        // if (this._total === -1) {
        // 	throw 'Selection total not set';
        // }

        return (
            (this.isInfiniteSelection && this.excludedItems.length === 0) ||
            (!this.isInfiniteSelection &&
                this.total === this.selectedItems.length)
        );
    };

    isAtLeastOneItemSelected = () => {
        // if (this._total === -1) {
        // 	throw 'Selection total not set';
        // }

        return (
            (this.isInfiniteSelection &&
                this.excludedItems.length < this.total) ||
            (!this.isInfiniteSelection && this.selectedItems.length > 0)
        );
    };

    isInfinite = () => {
        return this.isInfiniteSelection;
    };

    getNumberOfSelectedItems = () => {
        return this.selectedItems.length;
    };

    getSelectedItems = () => {
        return this.selectedItems;
    };

    getExcludedItems = () => {
        return this.excludedItems;
    };

    isItemSelected = (itemId: string) => {
        if (this.isInfiniteSelection) {
            return !this.excludedItems.includes(itemId);
        }
        return this.selectedItems.includes(itemId);
    };
}

export default Selection;
