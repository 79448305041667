import React from 'react';
import { AutoSizer, InfiniteLoader, List } from 'react-virtualized';
import { getRepresentationForItem } from '../../../../../../utils';
import propsWithDefaultValues from '../../../../../../utils/propsWithDefaultValues';
import Checkbox from '../../../../checkbox';
import { CheckBoxState } from '../../../../checkbox/props';
import useItemsFilterState from './hooks';
import PropsColumnReferenceFilter, { defaultProps } from './props';
import style from './style.module.scss';

const ItemsFilter = (props: PropsColumnReferenceFilter) => {
    props = propsWithDefaultValues(props, defaultProps);
    const { isOpened, isRightSide, onToggleOpen, relativePositionX, columnId } =
        props;
    const {
        isFiltered,
        refLoader,
        selection,
        searchPhrase,
        total,
        items,
        onApply,
        onChangeFilter,
        onToggleAll,
        onToggleItem,
        _getItemId,
        _isRowLoaded,
        _loadMoreRows,
    } = useItemsFilterState(props);

    const _rowRenderer = ({ index, key, style: elementStyle }: any) => {
        const { representationDataSource } = props;

        const item = items[index] as any;

        if (!item || item.isLoading) {
            return (
                <div
                    key={key}
                    style={elementStyle}
                    className={style.itemLoading}
                />
            );
        }

        if (item.id === 'all') {
            let allCheckboxState: CheckBoxState = 'unchecked';

            if (selection.isAllItemsSelected()) {
                allCheckboxState = 'checked';
            } else if (
                selection.isInfinite() ||
                selection.getNumberOfSelectedItems()
            ) {
                allCheckboxState = 'intermediate';
            }

            return (
                <div style={elementStyle} key={key} className={style.item}>
                    <Checkbox state={allCheckboxState} onToggle={onToggleAll} />
                    <span>All</span>
                </div>
            );
        }

        const text = getRepresentationForItem(item, representationDataSource);

        return (
            <div style={elementStyle} key={key} className={style.item}>
                <Checkbox
                    state={
                        selection.isItemSelected(_getItemId(item))
                            ? 'checked'
                            : 'unchecked'
                    }
                    onToggle={onToggleItem(_getItemId(item))}
                />
                <span>{text}</span>
            </div>
        );
    };

    let menuClass = isOpened ? style.menuOpened : style.menu;
    if (relativePositionX < 440) {
        menuClass = isOpened ? style.menuOpenedLeft : style.menuLeft;
    }

    return (
        <div className={style.itemsFilter}>
            <div
                className={
                    isOpened ? style.currentValueOpened : style.currentValue
                }
                onClick={() => onToggleOpen(columnId)}
            >
                {!isFiltered && 'All'}
                {isFiltered && <span className={style.filtered}>Filtered</span>}
            </div>
            {isOpened && (
                <div
                    className={menuClass}
                    data-is-right-side={String(!!isRightSide)}
                >
                    <div className={style.searchContainer}>
                        <div className={style.search}>
                            <i className="ion ion-ios-search" />
                            <input
                                type="search"
                                placeholder="Search..."
                                value={searchPhrase}
                                onChange={onChangeFilter}
                                onKeyUp={onChangeFilter}
                            />
                        </div>
                    </div>

                    <div className={style.items}>
                        <InfiniteLoader
                            ref={refLoader}
                            isRowLoaded={_isRowLoaded}
                            loadMoreRows={_loadMoreRows}
                            rowCount={total}
                            selection={selection}
                            searchPhrase={searchPhrase}
                        >
                            {({ onRowsRendered, registerChild }: any) => (
                                <AutoSizer
                                    searchPhrase={searchPhrase}
                                    disableHeight
                                >
                                    {({ width }: any) => (
                                        <List
                                            ref={registerChild}
                                            className={style.items}
                                            height={300}
                                            onRowsRendered={onRowsRendered}
                                            rowCount={total}
                                            rowHeight={35}
                                            rowRenderer={_rowRenderer}
                                            width={width}
                                            selection={selection}
                                            searchPhrase={searchPhrase}
                                        />
                                    )}
                                </AutoSizer>
                            )}
                        </InfiniteLoader>
                    </div>
                    <div className={style.statistic}>
                        {selection.isAllItemsSelected() &&
                            `Selected items: All`}
                        {!selection.isAllItemsSelected() &&
                            !selection.isInfinite() &&
                            `Selected items: ${
                                selection.getSelectedItems().length
                            }`}
                        {!selection.isAllItemsSelected() &&
                            selection.isInfinite() &&
                            `Selected items: ${
                                total - selection.getExcludedItems().length
                            }`}
                    </div>
                    <div className={style.buttons}>
                        <div className={style.applyButton} onClick={onApply}>
                            Apply
                        </div>
                        <div
                            className={style.cancelButton}
                            onClick={() => onToggleOpen(columnId)}
                        >
                            Cancel
                        </div>
                    </div>
                </div>
            )}
        </div>
    );
};

export default React.memo(ItemsFilter);
