import React, { useLayoutEffect, useMemo } from 'react';
import {
    matchPath,
    Route,
    Switch,
    useLocation,
    withRouter,
} from 'react-router-dom';
import Dialog from '../../../components/dialog';
import Form from '../../../components/form';
import ViewTypes from '../../../components/form/ViewTypes';
import Notifications from '../../../components/notifications';
import { PageConfig } from '../../../config-builder/types';
import {
    Observable,
    useGlobalStoreObserver,
} from '../../../globalStore/definition';
import PrivateRoute from '../private-route';
import PublicRoute from '../public-route';
import LoadingStub from './loading-stub';
import style from './style.module.scss';

const getPathData = (location: any, allowedSitesIds: string[]) => {
    const matchSite: any = matchPath(location.pathname, {
        path: `/:siteId(${(allowedSitesIds ?? []).join('|')})`,
        exact: false,
        strict: false,
    });

    const siteId =
        (matchSite && matchSite.params && matchSite.params.siteId) || null;

    return {
        siteId,
        endpointId: null,
    };
};

const getRoutesFromPages = (pages: PageConfig[]) => {
    //FIXME: add type to parameters
    let routes: any[] = [];

    pages.forEach((page) => {
        if (page.type === 'group') {
            const childRoutes = getRoutesFromPages(page.children ?? []);
            routes = [...routes, ...childRoutes];

            if (page.rootPage) {
                routes.push({
                    ...page.rootPage,
                    exact: page.exactPath,
                    path: page.path,
                    id: page.id,
                    showInSidebar: page.showInSidebar,
                });
            }
        } else {
            routes.push({
                ...page,
                exact: page.exactPath,
            });
        }
    });

    routes.unshift({
        id: 'rootDashboard',
        path: '/',
        exact: true,
        redirect: function (store: Observable, currentLocation: any) {
            if (pages) {
                const firstPage = pages.find((item) => item.sidebarTo);
                if (firstPage) {
                    return {
                        pathname: firstPage.sidebarTo,
                        state: { from: currentLocation },
                    };
                }
            }
        },
        props: {},
        content: {
            id: 'rootContent',
            type: ViewTypes.VStack,
            items: [],
        },
    });

    return routes;
};

const Content = (props: any) => {
    const [store] = useGlobalStoreObserver((s) => s);
    const location = useLocation();

    const currentUserData = store.account.state;
    const { isLoggedIn, isLoginProcessing, allowedSitesIds } = currentUserData;
    const { dialogsHistory, customDialogs, config } = store.app.state;

    const { changeCurrentSite } = store.app.dispatch;

    const { siteId } = useMemo(() => {
        const { siteId, endpointId } = getPathData(location, allowedSitesIds);

        return {
            siteId,
            endpointId,
        };
    }, [location, allowedSitesIds]);

    useLayoutEffect(() => {
        changeCurrentSite({ siteId });
    }, [siteId, changeCurrentSite]);

    const routes = useMemo(() => {
        if (!config) {
            return [];
        }
        return getRoutesFromPages(config.pages);
    }, [config]);

    if (
        (!config?.pages && isLoggedIn) ||
        !config ||
        (!isLoggedIn && isLoginProcessing)
    ) {
        return (
            <div className={style.content}>
                <LoadingStub />
            </div>
        );
    }

    const { isLoaded, Sidebar, LoginForm } = config!;

    const dialogs = {
        ...config!.dialogs,
        ...customDialogs,
    };
    const SidebarComponent = config.Sidebar?.component;

    return (
        <div className={style.content}>
            {isLoggedIn && SidebarComponent && Sidebar.items && (
                <SidebarComponent items={Sidebar.items} />
            )}
            <Switch>
                <PublicRoute
                    path="/login"
                    isLoggedIn={isLoggedIn}
                    isLoginProcessing={isLoginProcessing}
                    component={LoginForm.component as any}
                    config={config}
                    title={LoginForm.title}
                    store={store}
                />
                {isLoaded &&
                    routes.map((route: any) => (
                        <PrivateRoute
                            {...route.props}
                            id={route.id}
                            key={route.id}
                            exact={route.exact}
                            path={route.path}
                            component={route.component ?? Form}
                            store={store}
                            redirect={route.redirect}
                            isLoggedIn={isLoggedIn}
                            isLoginProcessing={isLoginProcessing}
                        />
                    ))}
                {!isLoaded && (
                    <PrivateRoute
                        component={LoadingStub}
                        isLoggedIn={isLoggedIn}
                        store={store}
                        isLoginProcessing={isLoginProcessing}
                    />
                )}
            </Switch>
            {dialogsHistory &&
                dialogsHistory.length > 0 &&
                dialogsHistory.map((item, index) => {
                    const openedDialog = dialogs[item.id];
                    const OpenedDialogComponent =
                        openedDialog?.component ?? Dialog;

                    return (
                        <Route
                            path={routes.map((item) => item.path)}
                            exact={true}
                            children={
                                <OpenedDialogComponent
                                    {...openedDialog}
                                    baseContext={item.context}
                                    id={item.id}
                                    params={item.params}
                                    key={`dialog-${index}`}
                                />
                            }
                        />
                    );
                })}
            <Notifications />
        </div>
    );
};

export default withRouter(Content);
