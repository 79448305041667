import { ItemFormConfig } from 'core/config-builder/forms/item/types';

const linktree: ItemFormConfig = {
    title: 'לינקים',
    isAllowed: ['admin', /[a-zA-Z0-9]+Admin$/],
    metaInfo: {
        properties: {
            title: {
                name: 'כותרת',
            },
            url: {
                name: 'לינק',
            },
        },
    },
};

export default linktree;
