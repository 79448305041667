import React from 'react';
import { ViewItem } from '..';
import { getValue } from '../../../utils/context';
import propsWithDefaultValues from '../../../utils/propsWithDefaultValues';
import { useAppContextObserver } from '../context';
import ProcessingStub from '../processing-stub';
import PropsVStack, { defaultProps } from './props';

const VStack = (props: PropsVStack) => {
    props = propsWithDefaultValues(props, defaultProps);
    let { id, style: customStyle, className, progressText, items } = props;

    const [{ isHidden, isLoading }] = useAppContextObserver((context) => {
        const isHidden = getValue(props.isHidden, context);
        return {
            isHidden,
            isLoading: getValue(props.isLoading, context, !isHidden),
        };
    });

    if (isHidden) {
        return null;
    }

    return (
        <div
            id={id}
            className={className}
            style={customStyle}
            data-view-type="VStack"
        >
            {isLoading && <ProcessingStub progressText={progressText} />}
            {!isLoading &&
                items.map((item, index) => (
                    <ViewItem key={item.id ?? index} node={item} />
                ))}
        </div>
    );
};

export default React.memo(VStack);
