import Form from '../../../components/form';
import ViewTypes from '../../../components/form/ViewTypes';
import handlers from './handlers';
import ledger from './ledger';
import products from './products';
import settings from './settings/content';
import style from './style.module.scss';

const form = {
    component: Form,
    className: style.dialog,
    handlers,
    fields: {
        appConfig: {},
    },
    title: 'Site Config Editor',
    commands: [
        {
            id: 'backButton',
            text: 'Back',
            onClick: 'onGoBack',
        },
    ],
    content: {
        id: 'rootGroup',
        type: ViewTypes.VStack,
        items: [
            {
                id: 'content',
                type: ViewTypes.VStack,
                items: [
                    {
                        id: 'tabs',
                        type: ViewTypes.TabsGroup,
                        activeTabIdSource: 'url.params.activeTabId.replace',
                        tabs: [
                            {
                                id: 'settings',
                                type: ViewTypes.TabPage,
                                title: 'Settings',
                                content: settings,
                            },
                            {
                                id: 'ledger',
                                type: ViewTypes.TabPage,
                                title: 'Ledger',
                                content: ledger,
                            },
                            {
                                id: 'products',
                                type: ViewTypes.TabPage,
                                title: 'Products',
                                content: products,
                            },
                        ],
                    },
                ],
            },
        ],
    },
};

export default form;
