import { DataSourcePath, HandlerValue, ValueOrHandler } from '../context/types';

export interface CodeEditorCommand {
    title: string;
    onClick: HandlerValue<() => void>;
}

export default interface PropsCodeEditor {
    isHidden?: ValueOrHandler<boolean>;
    isDisabled?: ValueOrHandler<boolean>;
    title?: string;
    value?: string;
    onChange?: (newValue: string) => void;
    dataSource?: DataSourcePath<string>;
    language?: string;
    commands?: CodeEditorCommand[];
    isExpanded?: boolean;
    onToggleFullScreen?: () => void;
}

export const defaultProps: Partial<PropsCodeEditor> = {
    value: '',
    isDisabled: false,
    title: '',
    language: 'javascript',
};
