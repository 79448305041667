import { CustomSiteConfig } from '../../../core/config-builder/types';
import creative from './creative';
import embassador from './embassador';
import termspage from './termspage';
import viralscript from './viralscript';

const office: CustomSiteConfig = {
    title: 'משרד פז״מ',
    endpoints: {
        creative,
        embassador,
        viralscript,
        termspage,
    },
};

export default office;
