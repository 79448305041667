import React, { Component } from 'react';
import PropTypes from 'prop-types';
import ReferenceInput from '../../../../reference-input';

import style from './style.module.scss';

class ReferenceCellQuickEdit extends Component {
    constructor(props) {
        super(props);
        this.state = {
            value: props.value,
        };
    }

    UNSAFE_componentWillReceiveProps(nextProps) {
        if (nextProps.value !== this.props.value) {
            this.setState({
                value: nextProps.value,
            });
        }
    }

    _handleApply = (e) => {
        e.stopPropagation();
        const { value } = this.state;
        this.props.onUpdate(value);
    };

    _handleCancel = (e) => {
        e.stopPropagation();
        this.props.onCancel();
    };

    _handleChange = (value) => {
        this.setState({
            value,
        });
    };

    render() {
        const { column } = this.props;
        const { value } = this.state;

        return (
            <div className={style.referenceCellQuickEdit}>
                <ReferenceInput
                    idField={column.idField}
                    searchByField={column.searchByField}
                    label={column.title}
                    siteId={column.siteId}
                    endpointId={column.endpointId}
                    sortListByField={column.sortListByField}
                    representationDataSource={column.representationDataSource}
                    value={value}
                    onChange={this._handleChange}
                />
                <button
                    className={style.applyButton}
                    onClick={this._handleApply}
                >
                    Apply
                </button>
                <button
                    className={style.cancelButton}
                    onClick={this._handleCancel}
                >
                    Cancel
                </button>
            </div>
        );
    }
}

ReferenceCellQuickEdit.propTypes = {
    column: PropTypes.any,
    item: PropTypes.any,
    onUpdate: PropTypes.func.isRequired,
    onCancel: PropTypes.func.isRequired,
};

ReferenceCellQuickEdit.defaultProps = {};

export default ReferenceCellQuickEdit;
