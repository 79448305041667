export default function getTemplateCode({ siteId }) {
    return `

module.exports = function(next) {
    
    var self = this;

    const init = () => {
        //check if user is site admin
        if(this.user.roles.indexOf('admin') != -1 || this.user.roles.indexOf('${siteId}Admin') != -1) {
            return next();//return native result
        }

        //return only current user items
        this.request.query.q = this.request.query.q + \` AND user.id:"\${this.user.id}"\`;
        next();
    }

    init();

    /////////////////////////////////////////////////////////////////////
    // HELP
    //
    // sanbox scripts don't support es2015 or es6 syntax, only vanilla js
    // promises are supported 
    // errors are not returned, if script errors then vanilla response is sent to client
    //
    // Available properties:
    //
    //  this.request - your able to modify request information before it is sent to db, protect endpoint from misuse.
    //  this.request.query.q - elastic query q string
    //  this.request.body - POST/PUT query in the body
    //      
    //  this.user
    //  this.user.id
    //  this.user.token
    //
    //  this.response - available only in the post-request script context (pre/post)
    //  this.response.hits - elastic search result
    //
    // Available plugins:
    //  
    //  this.lib.sms
    //  Example:  this.lib.sms.send(phone, message, callback)
    //
    //  this.lib.cache
    //  Examples:
    //      this.lib.cache.set('userId_verify', 'true', 1000 * 60 * 60) // 1 hour
    //      this.lib.cache.get('userId_verify')
    //
    //  Return function:
    //      next() - call it without arguments it returns native result
    //      next({"status":404, "message":"Not registered"}) - call it with an object and it will return json encoded response to client
    //
    //  API object:
    //      get - this.api.get(url, query, callback);
    //      post - this.api.post(url, query, postData, callback);
    //      put - this.api.put(url, query, postData, callback);
    //      delete - this.api.del(url, query, callback);
    //
    //  Example:
    //      return new Promise((resolve,reject) =>{
    //            this.api.get("/search/:ENDPOINT", { q: \`userId:"\${userId}"\`, size: 1}, function (error, response) {
    //                if (error) {
    //                    return null;
    //                }
    //      
    //                if (response) {
    //                    if (response.hits.total > 0) {
    //                        return response.hits.hits[0];
    //                    }
    //                }
    //      
    //                return null;
    //            });
    //      });
    
}
    
    `.trim();
}
