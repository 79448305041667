import { ItemFormConfig } from '../../../../core/config-builder/forms/item/types';

const contentactivity: ItemFormConfig = {
    title: 'תגובות',
    isAllowed: ['admin', /[a-zA-Z0-9]+Admin$/, /[a-zA-Z0-9]+Editor$/],
    metaInfo: {
        properties: {
            fullName: {
                name: 'שם מלא',
            },
            phone: {
                name: 'טלפון',
            },
            city: {
                name: 'עיר',
            },
            createdAt: {
                name: 'נוצר ב',
            },
            address: {
                name: 'כתובת',
            },
            floor: {
                name: 'קומה ',
            },
            apartment: {
                name: 'דירה',
            },
            comment: {
                name: 'פרטים נוספים',
            },
            message: {
                name: 'תגובה ',
            },
            brand: {
                name: ' מותג',
            },
            entrence: {
                name: 'כניסה',
            },
        },
    },
};

export default contentactivity;
