import React, { CSSProperties } from 'react';
import propsWithDefaultValues from '../../../utils/propsWithDefaultValues';
import PropsInput, { defaultProps } from './props';

const PlainInput = React.memo((props: any) => <input {...props} />);
const TextAreaInput = React.memo((props: any) => <textarea {...props} />);

const Input = (props: PropsInput) => {
    props = propsWithDefaultValues(props, defaultProps);
    const {
        id,
        displayError,
        label,
        isMultiLine,
        required,
        type,
        onChange,
        placeholder,
        disabled,
        onKeyPress,
        ltr,
        autocomplete,
        value,
        classContainer,
        classInput,
        classLabel,
        ...restProps
    } = props;

    const styleData: CSSProperties = {};
    if (ltr) {
        styleData.direction = 'ltr';
        styleData.textAlign = 'right';
    }

    let InputComponent = PlainInput;
    if (isMultiLine) {
        InputComponent = TextAreaInput;
    }

    return (
        <div
            className={classContainer}
            data-role="view"
            data-is-multiline={!!isMultiLine}
        >
            <label className={classLabel}>{label}</label>
            <InputComponent
                {...restProps}
                className={classInput}
                data-error={displayError ? 'true' : 'false'}
                onChange={onChange}
                type={type}
                placeholder={placeholder}
                required={required}
                disabled={disabled}
                onKeyPress={onKeyPress}
                style={styleData}
                autoComplete={autocomplete}
                value={value}
            />
        </div>
    );
};

export default React.memo(Input);
