import React, { useState } from 'react';
import ReferenceInput from '../../../../../../core/components/native/reference-input';
import PropsEditDialog from './props';
import style from './style.module.scss';

function getBaseUrl(): string {
    return (global as any)._CONFIG_PARAMS.serverURL;
}

const EditDialog = ({ params }: PropsEditDialog) => {
    const [value, setValue] = useState<any>();

    const { onClose, onSave } = params!;
    const handleSave = () => {
        onSave(`${getBaseUrl()}/minisite/${value}/auth`);
    };

    return (
        <div className={style.editDialog}>
            <h3>Select Minisite</h3>
            <div className={style.content}>
                <ReferenceInput
                    label="Minisite"
                    idField="objectId"
                    value={value}
                    onChange={setValue}
                    siteId="classes"
                    endpointId="Minisite"
                    representationDataSource="name"
                    sortListByField="name"
                    sortListDirection="asc"
                />
            </div>
            <div className={style.footer}>
                <button className={style.applyButton} onClick={handleSave}>
                    Apply
                </button>
                <button className={style.cancelButton} onClick={onClose}>
                    Cancel
                </button>
            </div>
        </div>
    );
};

export default EditDialog;
