import { CustomEndpointConfig } from '../../../../core/config-builder/types';
import { getUserName } from '../../commonFunctions';

const endpointConfig: CustomEndpointConfig = {
    metaInfo: {
        properties: {
            teamId: {
                endpointId: 'flappyteam',
            },
            userId: {
                type: 'string',
                format: 'reference',
                siteId: 'cbc',
                endpointId: 'profile',
                idField: 'userId',
                sortListByField: 'firstname',
                searchByField: ['email', 'firstname', 'lastname', 'phone'],
                representationDataSource: getUserName,
            },
        },
    },
    forms: {
        list: {
            // content of the list form
            columnsOrder: [
                'userId',
                'teamId',
                'name',
                'isEvil',
                'openProductsButton',
            ],
            columns: {
                openProductsButton: {
                    id: 'openProductsButton',
                    type: 'button',
                    text: 'Scores',
                    onClick(item: any) {
                        const context: any = this;
                        // Here formContext
                        context.form.url.push(
                            `/cbc/flappyscore/list?filter.userId.include[]=${item.user.username}`
                        );
                    },
                },
            },
        },
    },
};

export default endpointConfig;
