interface PropsSearchInput {
    placeholder?: string;
    value: string;
    onSearch: (value: string) => void;
    isDisabled?: boolean;
    debounceMs?: number;
}

export const defaultProps: Partial<PropsSearchInput> = {
    placeholder: 'Search...',
    debounceMs: 300,
};

export default PropsSearchInput;
