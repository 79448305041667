import actions from './actions';
import reducers from './reducers';
import { initialState } from './state';

const definition = {
    actions,
    reducers,
    state: initialState,
};

export default definition;
