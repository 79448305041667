import passwordGenerator from 'generate-password-browser';
import {
    AppContext,
    FormFunctionGetHandlers,
} from '../../../../components/form/context/types';
import ViewTypes from '../../../../components/form/ViewTypes';
import apiClient from '../../../../requests/api';
import { sleep } from '../../../../utils';
import {
    getDefaultEndpointData,
    getEndpointDataFromMetaInfo,
    getSiteDataFromMetaInfo,
    getSiteUpdateObject,
} from '../../utils';

const handlers: FormFunctionGetHandlers = function (this: AppContext) {
    const getParams = () => {
        const { siteId } = this.form.url.params;
        return { siteId };
    };

    const loadData = async (siteId: string) => {
        const metaInfo = this.remoteMetaInfo.sites[siteId];
        if (!metaInfo) {
            this.form.views.settingsFieldsGroup.items = [
                {
                    id: 'NotFound',
                    type: ViewTypes.Text,
                    value: 'NOT FOUND',
                },
            ];
            return;
        }

        this.form.siteData = getSiteDataFromMetaInfo(metaInfo);
        this.form.views.settingsRootGroup.isLoading = false;
    };

    const initNewSite = async () => {
        const { views } = this.form;

        this.form.title = 'Create new site';
        views.siteId.isDisabled = false;
        views.endpointId.isHidden = false;

        views.saveButton.text = 'Create';
        views.deleteButton.isHidden = true;
        views.addEndpointButton.isHidden = true;
        views.saveButton.onClick = onCreate;

        this.form.siteData = {};
    };

    const initExistingSite = async () => {
        const { siteId } = getParams();

        const { views } = this.form;

        views.siteId.isDisabled = true;
        views.endpointId.isHidden = true;
        views.saveButton.text = 'Save';
        views.deleteButton.isHidden = false;
        views.addEndpointButton.isHidden = false;
        views.saveButton.onClick = onSave;

        await loadData(siteId);

        const isJwtIncluded = (
            this.form.siteData.registrationTypes || []
        ).includes('jwt');
        this.form.views.jwtTokenGroup.isHidden = !isJwtIncluded;
    };

    const initViews = async () => {
        const { siteId } = getParams();

        if (siteId === 'new') {
            await initNewSite();
        } else {
            await initExistingSite();
        }
    };

    const onBeforeOpen = async () => {
        initViews();
    };

    // FIXME: Add types
    const onChangeUrl = (newUrl: any, oldUrl: any) => {
        if (newUrl.params.siteId !== oldUrl.params.siteId) {
            initViews();
        }
    };

    const onSave = async () => {
        const { siteId } = getParams();
        const metaInfo = this.remoteMetaInfo.sites[siteId];

        const updateData = getSiteUpdateObject(
            this.form.siteData,
            getEndpointDataFromMetaInfo(
                metaInfo,
                Object.keys(metaInfo.endpoints)[0] as any
            ) as any
        );

        this.form.views.settingsFieldsGroup.isLoading = true;
        try {
            await apiClient.updateSiteSchema(updateData);
            await sleep(2000);
            this.form.views.settingsFieldsGroup.isLoading = false;
            await sleep(100);
            this.form.notify({
                text: 'Success!',
                lifetimeMs: 3000,
            });

            window.location.reload();
        } catch (e) {
            this.form.notify({
                type: 'error',
                text: 'Save error',
                lifetimeMs: 3000,
            });
        }

        this.form.views.settingsFieldsGroup.isLoading = false;
    };

    const checkData = () => {
        const hideOldNotifications = () => {
            this.form.hideNotification(/SITE_CONFIG_ERROR_/g);
            ['siteId', 'endpointId'].forEach((fieldId) => {
                this.form.views[fieldId].displayError = false;
            });
        };

        hideOldNotifications();

        const idFormat = /^[a-z0-9]+$/;
        const notifyError = (field: string, text: string) => {
            this.form.views[field].displayError = true;
            this.form.notify(
                {
                    type: 'error',
                    text,
                },
                `SITE_CONFIG_ERROR_${field}`
            );
        };

        let result = true;
        const { siteData, endpointId } = this.form;
        const { siteId } = siteData;

        if (!endpointId) {
            result = false;
            notifyError('endpointId', 'Endpoint Id is required');
        } else if (!idFormat.test(endpointId)) {
            result = false;
            notifyError('endpointId', 'Wrong endpoint id format');
        }

        if (!siteId) {
            result = false;
            notifyError('siteId', 'Site Id is required');
        } else if (!idFormat.test(siteId)) {
            result = false;
            notifyError('siteId', 'Wrong site id format');
        }

        return result;
    };

    const onCreate = async () => {
        const { siteId } = this.form.siteData;

        if (!checkData()) {
            return;
        }

        const updateData = getSiteUpdateObject(
            this.form.siteData,
            getDefaultEndpointData(this.form.endpointId)
        );

        this.form.views.settingsFieldsGroup.isLoading = true;
        try {
            await apiClient.updateSiteSchema(updateData);
            await sleep(3000);
            this.form.views.settingsFieldsGroup.isLoading = false;
            this.form.notify({
                text: 'Success!',
                lifetimeMs: 3000,
            });
            this.form.url.replace(`/${siteId}/site-config-editor/settings`);
            await sleep(100);
            window.location.reload();
        } catch (e) {
            this.form.notify({
                type: 'error',
                text: 'Save error',
                lifetimeMs: 3000,
            });
        }

        this.form.views.settingsFieldsGroup.isLoading = false;
    };

    const onDelete = () => {
        const { siteId } = getParams();

        const deleteSite = (siteId: string) => async () => {
            this.form.views.isLoading = true;

            try {
                await apiClient.deleteSite(siteId);
                this.form.notify({
                    text: 'Delete site success',
                    lifetimeMs: 3000,
                });

                this.form.views.isLoading = false;
                this.form.url.replace(`/`);
                await sleep(500);
                window.location.reload();
            } catch (e) {
                this.form.views.isLoading = false;
                this.form.notify({
                    type: 'error',
                    text: 'Error delete site',
                    lifetimeMs: 3000,
                });
            }
        };

        this.form.pushDialog('yesno', {
            text: `Are you sure you want to delete "${siteId}" site?`,
            yesAction: deleteSite(siteId),
            yesLabel: 'Delete',
            noLabel: 'Cancel',
        });
    };

    const addEndpoint = async (endpointId: string) => {
        if (!/^[a-z0-9]+$/.test(endpointId)) {
            this.form.notify({
                type: 'error',
                text: 'Wrong endpoint id',
                lifetimeMs: 3000,
            });
            return;
        }

        const { siteId } = getParams();
        const siteMetaInfo = this.remoteMetaInfo.sites[siteId];

        const updateObject = getSiteUpdateObject(
            getSiteDataFromMetaInfo(siteMetaInfo),
            getDefaultEndpointData(endpointId)
        );

        this.form.views.isLoading = true;

        try {
            await apiClient.updateSiteSchema(updateObject);
            await sleep(5000);
            this.updateAppConfigFromRemoteMetainfo();
            await sleep(5000);
            this.form.notify({
                text: 'Add endpoint success',
                lifetimeMs: 3000,
            });

            this.form.closeDialog();
            this.form.url.replace(
                `/${siteId}/${endpointId}/endpoint-config-editor/settings`
            );
        } catch (e) {
            this.form.views.isLoading = false;
            this.form.notify({
                type: 'error',
                text: 'Error add endpoint',
                lifetimeMs: 3000,
            });
        }
    };

    const onAddEndpoint = () => {
        this.form.pushDialog('prompt', {
            text: 'Add new endpoint',
            inputLabel: 'endpointId',
            submitAction: addEndpoint,
        });
    };

    const onGenerateNewJwtToken = () => {
        this.form.siteData.JWTSecret = passwordGenerator.generate({
            length: 20,
            numbers: true,
            uppercase: true,
            symbols: true,
            excludeSimilarCharacters: true,
        });
    };

    const onChangeRegistrationTypes = () => {
        const isJwtIncluded = (
            this.form.siteData.registrationTypes || []
        ).includes('jwt');
        this.form.views.jwtTokenGroup.isHidden = !isJwtIncluded;
    };

    return {
        onBeforeOpen,
        onSave,
        onDelete,
        onAddEndpoint,
        onChangeUrl,
        onGenerateNewJwtToken,
        onChangeRegistrationTypes,
    };
};

export default handlers;
