import { VStack } from '../../../../../core/components/form/context/types';
import ViewTypes from '../../../../../core/components/form/ViewTypes';

const content: VStack = {
    id: 'rootGroup',
    type: ViewTypes.VStack,
    items: [
        {
            id: 'filterGroup',
            type: ViewTypes.HStack,
            style: { marginBottom: 30 },
            items: [
                {
                    id: 'startDate',
                    type: ViewTypes.DateInput,
                    label: 'תאריך התחלה',
                    // style: { width: 350, marginLeft: 10 },
                    showClearButton: true,
                    dataSource: 'startDate',
                    showTimePicker: false,
                    onChangeValue: 'onChangeStartDate',
                },
                {
                    id: 'endDate',
                    type: ViewTypes.DateInput,
                    label: 'תאריך סוף',
                    // style: { width: 350, marginLeft: 10 },
                    showClearButton: true,
                    dataSource: 'endDate',
                    onChangeValue: 'onChangeEndDate',
                },
            ],
        },
        // {
        //     id: 'countersGroup',
        //     type: ViewTypes.HStack,
        //     style: { marginBottom: 30 },
        //     items: [
        //         {
        //             id: 'playedGamesNumber',
        //             type: ViewTypes.Counter,
        //             label: 'Played Games (boards)',
        //             style: { marginLeft: 10 },
        //             dataSource: 'playedGamesNumber',
        //         },
        //         {
        //             id: 'playersNumber',
        //             type: ViewTypes.Counter,
        //             label: 'Players',
        //             style: { marginLeft: 10 },
        //             dataSource: 'numberOfPlayers',
        //         },
        //         {
        //             id: 'numberOfActiveUsers',
        //             type: ViewTypes.Counter,
        //             label: 'Active Users',
        //             style: { marginLeft: 10 },
        //             dataSource: 'numberOfActiveUsers',
        //         },
        //         {
        //             id: 'numberOfCompletedWords',
        //             type: ViewTypes.Counter,
        //             label: 'Number of completed words',
        //             dataSource: 'numberOfCompletedWords',
        //         },
        //     ],
        // },
        {
            id: 'tablesGroup',
            type: ViewTypes.VStack,
            items: [
                {
                    id: 'topUsersInTitle',
                    type: ViewTypes.Text,
                    style: {
                        fontSize: 30,
                        fontWeight: 'bold',
                        marginTop: 30,
                        direction: 'ltr',
                        textAlign: 'right',
                    },
                    dataSource: 'topUsersTitle',
                },
                {
                    id: 'topUsersTable',
                    type: ViewTypes.MemoryTable,
                    isSelectable: false,
                    dataSource: 'topUsers',
                    onClickItem: 'onOpenUserCard',
                    sortDataSource: 'topUsersTableSort',
                    pageDataSource: 'url.queryParams.topUsersPage.replace',
                    columns: [
                        {
                            id: 'profileId',
                            title: 'מספר משתמש',
                            fieldName: 'profileId',
                            type: 'text',
                        },
                        {
                            id: 'isLikedSum',
                            title: 'מספר לייקים',
                            type: 'number',
                            fieldName: 'isLikedSum',
                        },
                        {
                            id: 'openProductsButton',
                            type: 'button',
                            text: 'משתמש',
                            onClick(item: any) {
                                const context: any = this;
                                // Here formContext
                                context.form.url.push(
                                    `/cbc/gazarteaprofile/item/${item.profileId}/info`
                                );
                            },
                        },
                    ],
                },
            ],
        },
    ],
};

export default content;
