import { toTitleCase } from '../utils';
import { getDashboard } from './dashboard';
import getEndpointsConfig from './endpoint';
import {
    CustomAppConfig,
    CustomSiteConfig,
    RemoteMetaInfo,
    SiteConfig,
} from './types';

function getSiteConfig(
    siteId: string,
    remoteMetaInfo: RemoteMetaInfo,
    customSiteConfig: CustomSiteConfig | undefined
): SiteConfig {
    const siteMetaInfo = remoteMetaInfo.sites[siteId];
    const dashboardConfig = customSiteConfig && customSiteConfig.dashboard;

    const siteTitle =
        (customSiteConfig && customSiteConfig.title) || toTitleCase(siteId);

    return {
        id: siteId,
        path: `/${siteId}`,
        title: siteTitle,
        // RootForm: SiteRootFormComponent,
        endpoints: siteMetaInfo
            ? getEndpointsConfig(
                  siteId,
                  siteMetaInfo.endpoints,
                  customSiteConfig
              )
            : undefined,
        dashboard: getDashboard(siteId, siteTitle, dashboardConfig),
    };
}

function getSitesConfig(
    remoteMetaInfo: any,
    customAppConfig: CustomAppConfig
): SiteConfig[] {
    let sitesIds = Object.keys(remoteMetaInfo.sites);

    const order = customAppConfig.sitesOrder || 'asc';

    if (typeof order === 'string') {
        if (order === 'asc') {
            sitesIds.sort();
        } else {
            sitesIds.reverse();
        }
    } else if (Array.isArray(order)) {
        sitesIds = order;
    }

    return sitesIds.map((siteId: string) => {
        const siteCustomConfig =
            customAppConfig &&
            customAppConfig.sites &&
            customAppConfig.sites[siteId];
        return getSiteConfig(siteId, remoteMetaInfo, siteCustomConfig);
    });
}

export default getSitesConfig;
