import { CustomSiteConfig } from '../../../core/config-builder/types';
import receipt from './receipt';

const lottery: CustomSiteConfig = {
    title: 'Lottery',
    endpoints: {
        receipt,
    },
};

export default lottery;
