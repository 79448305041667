import {
    AppContext,
    FormFunctionGetHandlers,
} from '../../../../../components/form/context/types';
import ViewTypes from '../../../../../components/form/ViewTypes';
import apiClient from '../../../../../requests/api';

const handlers: FormFunctionGetHandlers = function (this: AppContext) {
    const onBeforeOpen = async () => {
        const { params } = this.form;
        const { siteId, endpointId, scriptId, jobId } = params;
        const response = await apiClient.getJobLogs(
            siteId,
            endpointId,
            scriptId,
            jobId
        );

        this.form.views.logsGroup.items = response.logs.map(
            (text: string, index: number) => ({
                id: `log-${index}`,
                type: ViewTypes.TextInput,
                label: '',
                isDisabled: true,
                isMultiLine: true,
                value: text,
            })
        );
    };

    const onClose = () => {
        this.form.closeDialog();
    };

    return {
        onBeforeOpen,
        onClose,
    };
};

export default handlers;
