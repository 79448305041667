import React from 'react';
import { useAppContextObserver } from '../../../../../../core/components/form/context';
import ChangePasswordDialog from '../../../../../../core/containers/login/change-password-dialog';
import { getHandlerWithContext } from '../../../../../../core/utils';

const ChangePasswordDialogWrapper = (props: any) => {
    let { isOpened, userId, token, context, onClose } = props;
    const [{}, getContext] = useAppContextObserver((context: any) => {
        return {};
    });

    const _handleChangePassword = async (
        newPassword: string,
        token: string
    ) => {
        const context = getContext();
        const { form } = context;
        const onClose = getHandlerWithContext(props.onClose, context);

        try {
            await context.api.changePassword(newPassword, token, userId);
            form.notify(
                {
                    text: 'The password is changed!',
                },
                'CHANGE_PASSWORD'
            );
            onClose();
        } catch (e) {
            form.notify(
                {
                    type: 'error',
                    text: 'Some error happened!',
                },
                'CHANGE_PASSWORD'
            );
            props.onClose();
        }
    };

    onClose = getHandlerWithContext(onClose, getContext());

    if (!isOpened) {
        return null;
    }

    return (
        <ChangePasswordDialog
            userId={userId}
            token={token}
            changePassword={_handleChangePassword}
            onClose={onClose}
            onCancel={onClose}
        />
    );
};

export default ChangePasswordDialogWrapper;
