import { getUserName } from 'app/config/commonFunctions';
import { VStack } from '../../../../../core/components/form/context/types';
import ViewTypes from '../../../../../core/components/form/ViewTypes';

const content: VStack = {
    id: 'rootGroup',
    type: ViewTypes.VStack,
    items: [
        {
            id: 'filterGroup',
            type: ViewTypes.HStack,
            style: { marginBottom: 30 },
            items: [
                {
                    id: 'startDate',
                    type: ViewTypes.DateInput,
                    label: 'תאריך התחלה',
                    // style: { width: 350, marginLeft: 10 },
                    showClearButton: true,
                    dataSource: 'startDate',
                    onChangeValue: 'onChangeStartDate',
                },
                {
                    id: 'endDate',
                    type: ViewTypes.DateInput,
                    label: 'תאריך סוף',
                    // style: { width: 350, marginLeft: 10 },
                    showClearButton: true,
                    dataSource: 'endDate',
                    onChangeValue: 'onChangeEndDate',
                },
            ],
        },
        {
            id: 'countersGroup',
            type: ViewTypes.HStack,
            style: { marginBottom: 30 },
            items: [
                {
                    id: 'playedGamesNumber',
                    type: ViewTypes.Counter,
                    label: 'משחקים',
                    style: { marginLeft: 10 },
                    dataSource: 'playedGamesNumber',
                },
                {
                    id: 'successfulGamesNumber',
                    type: ViewTypes.Counter,
                    label: 'שחקנים נצחו',
                    style: { marginLeft: 10 },
                    dataSource: 'successfulGamesNumber',
                },

                {
                    id: 'averagePlayTime',
                    type: ViewTypes.Counter,
                    label: 'זמן משחק ממוצע (משחק מנצח)',
                    style: { marginLeft: 10 },
                    dataSource: 'averagePlayTime',
                },
                {
                    id: 'averageTryCount',
                    type: ViewTypes.Counter,
                    label: 'מספר נסיונות ממוצע',
                    style: { marginLeft: 10 },
                    dataSource: 'averageTryCount',
                },
                {
                    id: 'numberOfCompletedWords',
                    type: ViewTypes.Counter,
                    label: 'מספר השימושים בשפת הTea',
                    style: { marginLeft: 10 },
                    dataSource: 'numberOfCompletedWords',
                },
                {
                    id: 'playersNumber',
                    type: ViewTypes.Counter,
                    label: 'מספר שחקנים סה"כ',
                    style: { marginLeft: 10 },
                    dataSource: 'numberOfPlayers',
                },
            ],
        },
        {
            id: 'tablesGroup',
            type: ViewTypes.VStack,
            items: [
                {
                    id: 'topUsersInTitle',
                    type: ViewTypes.Text,
                    style: {
                        fontSize: 30,
                        fontWeight: 'bold',
                        marginTop: 30,
                        direction: 'ltr',
                        textAlign: 'right',
                    },
                    dataSource: 'topUsersTitle',
                },
                {
                    id: 'wordleWords',
                    type: ViewTypes.ReferenceInput,
                    siteId: 'cbc',
                    endpointId: 'wordlewords',
                    label: 'יום',
                    dataSource: 'selectedWordleWord',
                    representationDataSource: function (item: any) {
                        let liveAtDate = new Date(item.liveAt);
                        let word = item.word.replaceAll('װ', 'TEA');

                        return `${('0' + liveAtDate.getDate()).slice(-2)}-${(
                            '0' +
                            (liveAtDate.getMonth() + 1)
                        ).slice(-2)}-${liveAtDate.getFullYear()}    ${word}`;
                    },
                    sortListDirection: 'desc',
                    rawSearchFilter: function (item: any) {
                        if (this.form.startDate && this.form.endDate) {
                            return `_exists_:liveAt AND liveAt:[${this.form.startDate.toISOString()} TO ${this.form.endDate.toISOString()}]`;
                        }

                        return `_exists_:liveAt`;
                    },
                    onChangeValue: 'onChangeDaySelection',
                    idField: 'liveAt',
                    sortListByField: 'liveAt',
                },
                {
                    id: 'topUsersTable',
                    type: ViewTypes.MemoryTable,
                    isSelectable: false,
                    dataSource: 'topUsers',
                    pageSize: 500,
                    onClickItem: 'onOpenUserCard',
                    sort: [{ columnId: 'successTime', direction: 'desc' }],
                    pageDataSource: 'url.queryParams.topUsersPage.replace',
                    columns: [
                        {
                            id: 'index',
                            title: 'שעת מציאה',
                            type: 'text',
                            fieldName: 'index',
                        },
                        {
                            id: 'successTime',
                            title: 'שעת מציאה',
                            type: 'date',
                            fieldName: 'successAt',
                        },
                        {
                            id: 'userId',
                            title: 'שם מלא',
                            fieldName: 'user.username',
                            idField: 'username',
                            type: 'reference',
                            endpointId: 'user',
                            siteId: '',
                            representationDataSource: getUserName,
                        },
                        {
                            id: 'phone',
                            title: 'טלפון',
                            type: 'button',
                            text: 'מספר טלפון',
                            onClick: 'copyPhone',
                        },
                    ],
                },
            ],
        },
    ],
};

export default content;
