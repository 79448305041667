import {
    AppContext,
    FormFunctionGetHandlers,
    FormHandlers,
    Url,
} from '../../../../components/form/context/types';
import { convertToQueryFilter } from '../../../../components/native/table/filter';
import LedgerItemDialog from './ledger-item-dialog';

const handlers: FormFunctionGetHandlers = function (
    this: AppContext
): FormHandlers {
    const getParams = () => {
        const { siteId } = this.form.url.params;
        return { siteId };
    };

    const updateTableQueryFilter = () => {
        const { siteId } = getParams();
        this.form.views.table.queryFilter = [`siteId:"${siteId}"`];
    };

    const onBeforeOpen = async () => {
        updateTableQueryFilter();
        this.registerCustomDialog(LedgerItemDialog);
    };

    const onChangeUrl = (newUrl: Url, oldUrl: Url): void => {
        updateTableQueryFilter();
    };

    const onClickItem = (item: any) => {
        this.form.pushDialog(LedgerItemDialog.id, { itemId: item.id });
    };

    const onDeleteSuccess = () => {
        this.form.selection = 'none';
        this.form.views.table.weakCallbacks.update &&
            this.form.views.table.weakCallbacks.update();
    };

    const onOpenDeleteItemsDialog = () => {
        const { siteId } = getParams();
        const { selection } = this.form;
        const { search, filter: tableFilter } = this.form.url.queryParams;

        if (selection === 'none') {
            this.form.notify({
                text: 'No selected items',
                type: 'error',
                lifetimeMs: 2000,
            });
            return;
        }

        const filter = convertToQueryFilter(
            this.form.views.table.columns,
            tableFilter || []
        );

        this.form.pushDialog('deleteItem', {
            endpointId: 'ledger',
            selection,
            filter,
            searchPhrase: search,
            queryFilter: [`siteId:"${siteId}"`],
            onSuccess: onDeleteSuccess,
        });
    };

    return {
        onBeforeOpen,
        onChangeUrl,
        onClickItem,
        onOpenDeleteItemsDialog,
    };
};

export default handlers;
