import React, { CSSProperties } from 'react';
import EmailCellProps from './props';
import style from './style.module.scss';

const CellEmail = ({ value, item, column }: EmailCellProps) => {
    let calculatedStyle: CSSProperties | undefined;

    if (column.style && typeof column.style === 'function') {
        calculatedStyle = column.style!({ column, item, value });
    } else {
        calculatedStyle = column.style;
    }

    return (
        <div className={style.emailCell}>
            <a
                className={style.linkCell}
                href={`mailto:${value}`}
                style={calculatedStyle}
            >
                {value}
            </a>
        </div>
    );
};

export default React.memo(CellEmail);
