import Dialog from '../../../components/dialog';
import { DialogData } from '../../../components/form/context/types';
import ViewTypes from '../../../components/form/ViewTypes';
import handlers from './handlers';
import style from './style.module.scss';

const importDialog: DialogData = {
    id: 'import',
    component: Dialog,
    className: style.dialog,
    handlers,
    fields: {
        page: '1',
        selection: 'none',
    },
    content: {
        id: 'rootGroup',
        type: ViewTypes.VStack,
        items: [
            {
                id: 'title',
                type: ViewTypes.Text,
                style: {
                    fontSize: 30,
                    fontWeight: 'bold',
                    textAlign: 'right',
                    marginBottom: 15,
                },
                value: 'Import',
            },
            {
                id: 'content',
                type: ViewTypes.VStack,
                style: {
                    overflow: 'auto',
                    flex: 1,
                    marginBottom: 15,
                    alignItems: 'center',
                    justifyContent: 'center',
                },
                items: [
                    {
                        id: 'uploadView',
                        type: ViewTypes.UploadInput,
                        isHidden: false,
                        extensionsWithDot: ['.csv'],
                        maxSizeKB: 10000,
                        onSelectFile: 'onSelectFile',
                    },
                    {
                        id: 'tableContainer',
                        type: ViewTypes.VStack,
                        className: style.tableContainer,
                        isHidden: true,
                        items: [
                            {
                                id: 'table',
                                type: ViewTypes.MemoryTable,
                                dataSource: 'items',
                                columns: [],
                                isSelectable: true,
                                selectionDataSource: 'selection',
                                pageDataSource: 'page',
                            },
                        ],
                    },
                ],
            },
            {
                id: 'errorsGroup',
                type: ViewTypes.HStack,
                isHidden: true,
                style: {
                    border: '1px solid red',
                    borderRadius: '5px',
                    padding: '4px',
                    paddingRight: '15px',
                },
                items: [
                    {
                        id: 'errorsText',
                        type: ViewTypes.Text,
                        value: 'Found n errors',
                        onClick: 'onOpenErrors',
                        style: { color: 'red', fontWeight: 'bold', flex: 1 },
                    },
                ],
            },
            {
                id: 'footer',
                type: ViewTypes.HStack,
                items: [
                    {
                        id: 'applyButton',
                        type: ViewTypes.Button,
                        text: 'Apply',
                        onClick: 'onApply',
                        style: { marginLeft: 15 },
                    },
                    {
                        id: 'cancelButton',
                        type: ViewTypes.Button,
                        role: 'notActive',
                        text: 'Cancel',
                        onClick: 'onClose',
                    },
                ],
            },
        ],
    },
};

export default importDialog;
