import { CustomSiteConfig } from '../../../core/config-builder/types';
import article from './article';
import author from './author';
import category from './category';
import comment from './comment';
import like from './like';
const wiki: CustomSiteConfig = {
    title: 'מדריכים וכתבות',
    dashboard: { commands: [] },
    endpoints: {
        comment,
        author,
        like,
        category,
        article,
    },
};

export default wiki;
