import React, { useCallback } from 'react';
import { getHandlerWithContext, getValue } from '../../../utils/context';
import { usePropsWithDefaultValues } from '../../../utils/propsWithDefaultValues';
import Checkbox from '../../native/checkbox';
import { CheckBoxState } from '../../native/checkbox/props';
import { useAppContextObserver } from '../context';
import PropsBooleanInput, { defaultProps } from './props';

const BooleanInput = (props: PropsBooleanInput) => {
    props = usePropsWithDefaultValues(props, defaultProps);

    const [{ isHidden, displayError, isDisabled, value }, getContext] =
        useAppContextObserver((c) => {
            let { value } = props;
            if (props.dataSource) {
                value = c.form.getDataSourceValue(props.dataSource);
            }

            return {
                isHidden: getValue(props.isHidden, c),
                displayError: getValue(props.displayError, c),
                isDisabled: getValue(props.isDisabled, c),
                value,
            };
        });

    const { dataSource, classContainer, classLabel, label } = props;

    const onChangeValue = useCallback(
        (newState: CheckBoxState) => {
            const value: boolean = newState === 'checked';

            if (dataSource) {
                getContext().form.setDataSourceValue(dataSource, value);
            }

            if (!props.onChangeValue) {
                return;
            }

            const handler = getHandlerWithContext(
                props.onChangeValue,
                getContext(),
                true
            );
            handler!(value);
        },
        [getContext, props.onChangeValue, dataSource]
    );

    if (isHidden) {
        return null;
    }

    const checkboxState: CheckBoxState = value ? 'checked' : 'unchecked';

    return (
        <div data-role="view" className={classContainer}>
            <Checkbox
                state={checkboxState}
                isDisabled={isDisabled}
                onToggle={onChangeValue}
            />
            <label className={classLabel} data-error={!!displayError}>
                {label}
            </label>
        </div>
    );
};

export default React.memo(BooleanInput);
