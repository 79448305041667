import React, { CSSProperties, useEffect, useState } from 'react';
import ComponentProps from './props';
import style from './style.module.scss';

const UpdateInPlace = (props: ComponentProps) => {
    const { isUpdateInPlaceProcessing, column, item, updateValue } = props;
    const [value, setValue] = useState(props.value);

    const _handleKeyPress = (e: any) => {
        if (e.key === 'Enter') {
            e.stopPropagation();
            e.preventDefault();
            const inputValue = e.target.value;
            const value = Number(inputValue);
            updateValue(value);
        } else {
            setValue(e.target.value);
        }
    };

    useEffect(() => {
        setValue(props.value);
    }, [props.value]);

    let calculatedStyle: CSSProperties | undefined;

    if (column.style && typeof column.style === 'function') {
        calculatedStyle = column.style!({ column, item, value });
    } else {
        calculatedStyle = column.style;
    }
    const _handleChange = (e: any) => {
        setValue(e.target.value);
    };

    return (
        <div className={style.textCell} onClick={(e) => e.stopPropagation()}>
            <input
                type="number"
                value={value}
                disabled={!!isUpdateInPlaceProcessing}
                className={style.input}
                style={calculatedStyle}
                onKeyPress={_handleKeyPress}
                onChange={_handleChange}
                onBlur={() => setValue(props.value)}
                data-processing={!!isUpdateInPlaceProcessing}
            />
        </div>
    );
};

export default React.memo(UpdateInPlace);
