import { CustomSiteConfig } from '../../../core/config-builder/types';
import dashboard from './dashboard';
import round from './round';
import song from './song';
import vote from './vote';

const songvote: CustomSiteConfig = {
    title: 'המצעד',
    dashboard,
    endpoints: {
        vote,
        song,
        round,
    },
};
export default songvote;
