import React, { useCallback } from 'react';
import { getValue } from '../../../utils/context';
import Input from '../../native/input';
import { useAppContextObserver } from '../context';
import PropsTextInput from './props';

const TextInput = (props: PropsTextInput) => {
    const {
        dataSource,
        onChangeValue,
        id,
        label,
        placeholder,
        isMultiLine,
        ltr,
    } = props;

    const [
        { isHidden, isDisabled, displayError, dataSourceValue },
        getContext,
    ] = useAppContextObserver((context) => {
        return {
            isHidden: getValue(props.isHidden, context),
            displayError: getValue(props.displayError, context),
            isDisabled: getValue(props.isDisabled, context),
            dataSourceValue: dataSource
                ? context.form.getDataSourceValue(dataSource)
                : undefined,
        };
    });

    let value = props.value;
    if (dataSource) {
        value = dataSourceValue;
    }

    const handleChangeValue = useCallback(
        (e: any) => {
            const { value } = e.target;

            if (dataSource) {
                getContext().form.setDataSourceValue(dataSource, value);
            }

            if (!onChangeValue) {
                return;
            }

            if (typeof onChangeValue === 'string') {
                (getContext().form.handlers as any)[onChangeValue](value);
                return;
            }

            onChangeValue.call(getContext(), value);
        },
        [getContext, dataSource, onChangeValue]
    );

    if (isHidden) {
        return null;
    }

    return (
        <Input
            id={id}
            ltr={ltr}
            isMultiLine={isMultiLine}
            label={label}
            disabled={isDisabled}
            placeholder={placeholder}
            value={value ?? ''}
            onChange={handleChangeValue}
            displayError={displayError}
        />
    );
};

export default React.memo(TextInput);
