import React from 'react';
import FileDrop from 'react-file-drop';
import propsWithDefaultValues from '../../../utils/propsWithDefaultValues';
import ProcessingStub from '../../form/processing-stub';
import useUploadInputState from './hooks';
import PropsUploadInput, { defaultProps } from './props';
import style from './style.module.scss';

const inputStyle = { display: 'none' };
const UploadInput = (props: PropsUploadInput) => {
    props = propsWithDefaultValues(props, defaultProps);
    const {
        isDragOver,
        value,
        refFileUploadInput,
        onDragLeave,
        onDragOver,
        onDrop,
        onChangeImage,
        onClickBrowseButton,
    } = useUploadInputState(props);

    const {
        title,
        multiple,
        icon,
        displayError,
        id,
        isLoading,
        isSelected,
        onDelete,
        extensionsWithDot,
    } = props;
    let uploadViewClass = style.uploadView;

    if (isDragOver) {
        uploadViewClass = style.uploadViewHover;
    } else if (displayError) {
        uploadViewClass = style.uploadViewError;
    }

    if (isSelected) {
        return (
            <div className={style.selectedStub}>
                <span>Selected</span>
                {onDelete && <button onClick={onDelete}>Delete</button>}
            </div>
        );
    }

    return (
        <FileDrop
            onDrop={onDrop}
            onFrameDragEnter={onDragOver}
            onFrameDragLeave={onDragLeave}
            className={uploadViewClass}
        >
            <input
                id={id}
                value={value}
                key={id}
                multiple={multiple}
                type="file"
                ref={refFileUploadInput}
                style={inputStyle}
                onChange={onChangeImage}
                accept={extensionsWithDot!.join(',')}
            />
            <div onClick={onClickBrowseButton} className={style.content}>
                {!isLoading && (
                    <>
                        <img className={style.icon} src={icon} alt="" />
                        <div className={style.text}>
                            {title}
                            {extensionsWithDot && extensionsWithDot.length > 0 && (
                                <>
                                    <br />
                                    <span className={style.extensions}>
                                        {extensionsWithDot?.join(', ')}
                                    </span>
                                </>
                            )}
                        </div>
                        <div className={style.browseButton}>Browse</div>
                    </>
                )}
                {isLoading && <ProcessingStub />}
            </div>
        </FileDrop>
    );
};

export default React.memo(UploadInput);
