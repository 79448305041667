interface WebSocketEvent {
    data:
        | string
        | {
              reqid: string;
          };
}

interface ListenerCallback {
    (message: string): void;
}

interface Subscriptions {
    [subscriptionId: string]: {
        console?: boolean;
        listener: ListenerCallback;
        query?: string;
    };
}

export default class WSClient {
    private authToken: string;

    private lastRequestId: number;

    private requestsQueue: any[];

    private siteId: string;

    private subscriptions: Subscriptions;

    private wsServerAddress: string;

    private isConnectionOpened: boolean;

    private ws: WebSocket | null = null;

    constructor(wsServerAddress: string, siteId: string, authToken: string) {
        this.lastRequestId = 0;
        this.requestsQueue = [];
        this.authToken = authToken;
        this.siteId = siteId;
        this.subscriptions = {};
        this.wsServerAddress = wsServerAddress;
        this.isConnectionOpened = false;
    }

    private handleMessageFromServer = (event: WebSocketEvent) => {
        if (event.data === 'ping') {
            return this.ws!.send('pong');
        }
        try {
            const message = JSON.parse(event.data as string);

            if (message.reqid && message.reqid.startsWith('subscribe')) {
                const [, subscriptionId] = message.reqid.split('-');
                const subscription = this.subscriptions[subscriptionId];

                if (subscription.listener) {
                    subscription.listener(message);
                }

                return undefined;
            }

            console.log('WS PARSED MESSAGE', message);
        } catch (e) {
            console.log('WS PARSE MESSAGE ERROR');
        }

        if (
            event.data &&
            (event.data as any).reqid &&
            (event.data as any).reqid.startsWith()
        ) {
            console.log('WS MESSAGE', event);
        }
    };

    ping = () => {
        this.ws!.send('ping');
    };

    isSubscribed = (subscriptionId: string) => {
        return !!this.subscriptions[subscriptionId];
    };

    connect = async () => {
        return new Promise<void>((resolve) => {
            this.ws = new WebSocket(
                `${this.wsServerAddress}/${this.siteId}?token=${this.authToken}`
            );

            this.ws.addEventListener('message', this.handleMessageFromServer);
            this.ws.addEventListener(
                'close',
                this.handleCloseConnection as any
            );
            this.ws.addEventListener('error', this.handleErrorConnection);

            this.ws.addEventListener('open', (e) => {
                this.handleOpenConnection();
                resolve();
            });
        });
    };

    private activateSubscriptions = () => {
        Object.getOwnPropertyNames(this.subscriptions).forEach(
            (subscriptionId) => {
                const subscription = this.subscriptions[subscriptionId];
                if (subscription.query) {
                    this.subscribeToQuery(
                        subscriptionId,
                        subscription.query,
                        subscription.listener
                    );
                } else if (subscription.console) {
                    this.subscribeToConsole(
                        subscriptionId,
                        subscription.listener
                    );
                }
            }
        );
    };

    private handleOpenConnection = () => {
        console.log('WS connection is opened!');
        this.isConnectionOpened = true;
        this.activateSubscriptions();
    };

    private handleCloseConnection = (renewConnection: boolean = true) => {
        console.log('WS connection is closed!');

        this.ws!.removeEventListener(
            'close',
            this.handleCloseConnection as any
        );
        this.ws!.removeEventListener('error', this.handleErrorConnection);
        this.ws!.removeEventListener('message', this.handleMessageFromServer);
        this.ws!.removeEventListener('open', this.handleOpenConnection);
        this.ws = null;

        this.isConnectionOpened = false;

        if (renewConnection) {
            setTimeout(() => {
                this.connect();
            }, 300);
        }
    };

    private handleErrorConnection = () => {
        console.log('WS connection error!');
    };

    close = () => {
        this.handleCloseConnection();
        if (this.ws) {
            this.ws.close();
        }
        this.subscriptions = {};
    };

    getRequestId = () => {
        this.lastRequestId += 1;
        return this.lastRequestId;
    };

    subscribeToQuery = (
        subscriptionId: string,
        elasticQuery: string,
        listener: ListenerCallback
    ) => {
        const body = {
            action: 'subscribe',
            reqid: `subscribe-${subscriptionId}-${this.getRequestId()}`,
            query: elasticQuery,
        };

        this.subscriptions[subscriptionId] = {
            listener,
            query: elasticQuery,
        };

        try {
            this.ws!.send(JSON.stringify(body));
        } catch (e) {}
    };

    subscribeToConsole = (
        subscriptionId: string,
        listener: ListenerCallback
    ) => {
        const body = {
            action: 'subscribe',
            reqid: `subscribe-${subscriptionId}-${this.getRequestId()}`,
            console: true,
        };

        this.subscriptions[subscriptionId] = {
            listener,
            console: true,
        };

        try {
            this.ws!.send(JSON.stringify(body));
        } catch (e) {}
    };

    request = async () => {};
}
