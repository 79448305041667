import { AppContext } from '../../../components/form/context/types';
import ViewTypes from '../../../components/form/ViewTypes';
import handlers from './handlers';
import style from './style.module.scss';

const ALLOWED_DELETE_ROLES = ['admin', 'landingsAdmin'];

const list = {
    className: style.dialog,
    title: 'Landings Editor',
    handlers,
    fields: {
        appConfig: {},
        selection: 'none',
    },
    commands: [
        {
            id: 'deleteItem',
            text: 'Delete',
            onClick: 'onOpenDeleteItemsDialog',
            isHidden: function (this: AppContext) {
                return !ALLOWED_DELETE_ROLES.some((role) =>
                    this.currentUserData.roles.includes(role)
                );
            },
        },
    ],
    content: {
        id: 'rootGroup',
        type: ViewTypes.VStack,
        items: [
            {
                id: `command-panel`,
                type: ViewTypes.HStack,
                items: [
                    {
                        id: `list-table-create-button`,
                        type: ViewTypes.Button,
                        text: 'Create',
                        onClick(this: AppContext) {
                            this.form.url.push(`/landings/item`);
                        },
                    },
                    {
                        id: `list-table-search-field`,
                        type: ViewTypes.SearchInput,
                        dataSource: 'url.queryParams.search.replace',
                    },
                ],
            },
            {
                id: 'settingsFieldsGroup',
                type: ViewTypes.VStack,
                style: { padding: 10, flex: 1 },
                items: [
                    {
                        id: 'table',
                        type: ViewTypes.DataTable,
                        endpointId: 'landings',
                        isSelectable: true,
                        sortDataSource: 'url.queryParams.sort.replace',
                        filterDataSource: 'url.queryParams.filter.replace',
                        searchDataSource: 'url.queryParams.search.replace',
                        selectionDataSource: 'selection',
                        pageDataSource: 'url.queryParams.page.replace',
                        pageSize: 15,
                        sort: [{ columnId: 'createdAt', direction: 'desc' }],
                        items: [],
                        onClickItem(this: AppContext, item: any) {
                            this.form.url.push(
                                `/landings/item/${item.id}/info`
                            );
                        },
                        columns: [
                            {
                                id: 'name',
                                fieldName: 'name',
                                type: 'text',
                                title: 'Name',
                            },
                            {
                                id: 'path',
                                fieldName: 'path',
                                type: 'text',
                                title: 'Path',
                            },
                            {
                                id: 'description',
                                fieldName: 'description',
                                type: 'text',
                                title: 'Description',
                            },
                            {
                                id: 'createdAt',
                                fieldName: 'createdDate',
                                type: 'date',
                                title: 'createdAt',
                            },
                            // {
                            // 	id: "attachedToMiniapps",
                            // 	fieldName: "attachedToMiniapps",
                            // 	type: "text",
                            // 	title: "Attached To"
                            // }
                        ],
                    },
                ],
            },
        ],
    },
};

export default list;
