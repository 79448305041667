import React from 'react';
import defaultProfile from '../../../assets/images/male-pazam-default.jpeg';
import style from './style.module.scss';
const Comment = (props: {
    commenterName: string;
    commentText: string;
    profilePic: string;
    handleDelete: Function;
    index: string;
}) => {
    const { commentText, commenterName, profilePic, handleDelete, index } =
        props;
    return (
        <div className={style.container}>
            <div
                className={style.deleteContainer}
                onClick={() => handleDelete()}
            >
                <div className={style.deleteButton}>x</div>
            </div>

            <div className={style.coverPhotoContainer}>
                <div className={style.coverPhoto}>
                    <img
                        id={`profilePic${index}`}
                        src={profilePic}
                        alt=""
                        onError={(e) => {
                            let img = document?.getElementById(
                                `profilePic${index}`
                            ) as HTMLImageElement;
                            img.src = defaultProfile;
                        }}
                    />
                </div>
            </div>
            <div className={style.commentWrapper}>
                <div className={style.commentContainer}>
                    <div
                        style={{ fontSize: '13px' }}
                    >{` ${commenterName}: `}</div>
                    <div>{commentText}</div>
                </div>
            </div>
        </div>
    );
};

export default React.memo(Comment);
