import { CustomSiteConfig } from '../../../core/config-builder/types';
import contentactivity from './contentactivity';
import contentarticle from './contentarticle';
import contentimages from './contentimages';
import linktree from './linktree';
const ibbls: CustomSiteConfig = {
    title: 'IBBLS',
    dashboard: { commands: [] },
    endpoints: {
        contentactivity,
        contentarticle,
        linktree,
        contentimages,
    },
};

export default ibbls;
