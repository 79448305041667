import { DialogData } from '../../components/form/context/types';
import { CompiledAppConfig, RemoteMetaInfo } from '../../config-builder/types';
import { AppState, initialState } from './state';

const reducers = {
    resetData:
        () =>
        (state: AppState): AppState => {
            return initialState;
        },

    resetCurrentSiteData:
        () =>
        (state: AppState): AppState => {
            return {
                ...state,
                isCurrentSiteLoading: false,
                currentSiteId: initialState.currentSiteId,
                currentSiteMeta: initialState.currentSiteMeta,
                currentSiteApiClient: initialState.currentSiteApiClient,
            };
        },

    resetSitesMetaInfo:
        (data: { remoteMetaInfo: RemoteMetaInfo }) =>
        (state: AppState): AppState => {
            return {
                ...state,
                sitesMetaInfo: {
                    ...data.remoteMetaInfo,
                },
            };
        },

    setRemoteMetaInfo:
        (data: { remoteMetaInfo: RemoteMetaInfo }) =>
        (state: AppState): AppState => {
            return {
                ...state,
                remoteMetaInfo: data.remoteMetaInfo,
            };
        },

    updateAppConfigSuccess:
        (data: { config: CompiledAppConfig }) =>
        (state: AppState): AppState => {
            return {
                ...state,
                config: data.config,
            };
        },

    siteMetaRequest:
        (data: { siteId: string }) =>
        (state: AppState): AppState => {
            return {
                ...state,
                currentSiteId: data.siteId,
                isCurrentSiteLoading: true,
            };
        },

    siteMetaSuccess:
        ({
            siteId,
            metaInfo,
            apiClient,
        }: {
            siteId: string;
            metaInfo: any;
            apiClient: any;
        }) =>
        (state: AppState): AppState => {
            return {
                ...state,
                currentSiteId: siteId,
                currentSiteMeta: metaInfo,
                currentSiteApiClient: apiClient,
                isCurrentSiteLoading: false,
            };
        },

    siteMetaError:
        () =>
        (state: AppState): AppState => {
            return {
                ...state,
                // FIXME: handle errors
                isCurrentSiteLoading: true,
            };
        },

    replaceDialog:
        (data: { id: string; params: any }) =>
        (state: AppState): AppState => {
            const dialogsHistory = state.dialogsHistory.slice();
            dialogsHistory.pop();
            const context = { form: {} } as any;
            dialogsHistory.push({
                context,
                id: data.id,
                params: data.params,
            });

            return {
                ...state,
                openedDialogId: data.id,
                openedDialogParams: data.params,
                dialogsHistory,
                openedDialogContext: context,
            };
        },

    pushDialog:
        (data: { id: string; params: any }) =>
        (state: AppState): AppState => {
            const dialogsHistory = state.dialogsHistory.slice();
            const context = { form: {} } as any;
            dialogsHistory.push({
                context,
                id: data.id,
                params: data.params,
            });

            return {
                ...state,
                openedDialogId: data.id,
                openedDialogParams: data.params,
                openedDialogContext: context,
                dialogsHistory,
            };
        },

    closeDialog:
        () =>
        (state: AppState): AppState => {
            const dialogsHistory = state.dialogsHistory.slice();
            dialogsHistory.pop();
            let data;
            if (dialogsHistory.length > 0) {
                data = dialogsHistory[dialogsHistory.length - 1];
            }

            return {
                ...state,
                openedDialogId: data ? data.id : null,
                openedDialogParams: data ? data.params : null,
                openedDialogContext: data ? data.context : null,
                dialogsHistory,
            };
        },

    registerCustomDialog:
        ({ data }: { data: DialogData }) =>
        (state: AppState): AppState => {
            if (!data) return { ...state };

            const dialogId = data.id;
            return {
                ...state,
                customDialogs: {
                    ...state.customDialogs,
                    [dialogId]: data,
                },
            };
        },

    toggleSidebar:
        () =>
        (state: AppState): AppState => {
            return {
                ...state,
                isSidebarOpened: !state.isSidebarOpened,
            };
        },
};

export default reducers;
