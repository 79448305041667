import React from 'react';
import PropsQuickEditorTrigger from './props';

// class SomeComponetn extends Component {
// bbb =() => {
//     this.props
// }
// }

const QuickEditorTrigger = ({
    updateInPlace,
    children,
    onShowQuickEditMenu,
}: PropsQuickEditorTrigger) => {
    if (!updateInPlace) {
        return <div>{children}</div>;
    }

    return (
        <div
            onDoubleClick={(e) => {
                e.stopPropagation();
                onShowQuickEditMenu();
            }}
            onClick={(e) => {
                if (e.altKey) {
                    e.stopPropagation();
                    onShowQuickEditMenu();
                }
            }}
        >
            {children}
        </div>
    );
};

export default QuickEditorTrigger;
