import React from 'react';
import style from './style.module.scss';

const SelectCell = (props: any) => {
    const onToggle = (item: any) => (e: any) => {
        e.stopPropagation();
        const { column } = props;
        column.onSelect(item);
    };

    const { value, item } = props;
    const iconClass = value
        ? 'ion ion-ios-radio-button-on'
        : 'ion ion-ios-radio-button-off';

    return (
        <div className={style.cell} onClick={onToggle(item)}>
            <i className={iconClass} onClick={onToggle(item)} />
        </div>
    );
};

export default React.memo(SelectCell);
