import {
    CustomEndpointConfig,
    EndpointFormsConfig,
    LocalMetaInfoEndpoint,
} from '../types';
import getItemForm from './item';
import getListForm from './list';

export default function getEndpointForms(
    siteId: string,
    endpointId: string,
    endpointMetaInfo: LocalMetaInfoEndpoint,
    endpointCustomConfig: CustomEndpointConfig | undefined
): EndpointFormsConfig {
    return {
        list: getListForm(
            siteId,
            endpointId,
            endpointMetaInfo,
            endpointCustomConfig
        ),
        choose: {},
        item: getItemForm(
            siteId,
            endpointId,
            endpointMetaInfo,
            endpointCustomConfig?.forms?.item
        ),
    };
}
