import React from 'react';
import Pagination from 'react-js-pagination';
import ArrowLeft from '../../../assets/images/left.svg';
import ArrowRight from '../../../assets/images/right.svg';
import PropsPager from './props';
import style from './style.module.scss';

const Pager = (props: PropsPager) => {
    const {
        activePage,
        pageSize,
        numberOfPages,
        displayRange,
        handleChangePage,
    } = props;
    return (
        <Pagination
            activePage={activePage}
            itemsCountPerPage={pageSize}
            totalItemsCount={numberOfPages * pageSize}
            pageRangeDisplayed={displayRange}
            onChange={handleChangePage}
            innerClass={style.pagingInner}
            activeClass={style.pagingActive}
            activeLinkClass={style.pagingActiveLink}
            itemClass={style.pagingItem}
            itemClassFirst={style.pagingItemFirst}
            itemClassPrev={style.pagingItemPrev}
            itemClassNext={style.pagingItemNext}
            itemClassLast={style.pagingItemLast}
            disabledClass={style.pagingDisabled}
            nextPageText={<img src={ArrowLeft} alt="" />}
            lastPageText="אחרון"
            firstPageText="ראשון"
            prevPageText={<img src={ArrowRight} alt="" />}
        />
    );
};

export default React.memo(Pager);
