export function getUserName(profileData: any): string {
    if (!profileData) {
        return 'NOT FOUND';
    }

    if (profileData.firstname) {
        if (!profileData.lastname) {
            return profileData.firstname;
        }

        return `${profileData.firstname} ${profileData.lastname}`;
    }
    if (profileData.fullName) {
        return profileData.fullName;
    }

    if (profileData.email) {
        return profileData.email;
    }

    if (profileData.phone) {
        return profileData.phone;
    }

    return profileData.username;
}
