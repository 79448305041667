import lodash from 'lodash';
import qs from 'qs';
import { useCallback, useEffect, useRef, useState } from 'react';
import extensionToMimeMap from './extensionToMime.json';

export {
    addContextToHandlers,
    getHandlerWithContext,
    getValue,
    isHandler,
} from './context';

export function dataURLtoBlob(dataurl: string): Blob {
    const arr: string[] = dataurl.split(',');

    const mime = (arr[0] as any).match(/:(.*?);/)[1];

    const bstr = atob(arr[1]);
    let n = bstr.length;
    const u8arr = new Uint8Array(n);
    while (n--) {
        u8arr[n] = bstr.charCodeAt(n);
    }
    return new Blob([u8arr], { type: mime });
}

export async function blobToDataURL(blob: Blob): Promise<string> {
    return new Promise((resolve) => {
        const a = new FileReader();
        a.onload = function (e) {
            resolve(e?.target?.result as string);
        };
        a.readAsDataURL(blob);
    });
}

export function IsJsonString(str: string): boolean {
    try {
        JSON.parse(str);
    } catch (e) {
        return false;
    }
    return true;
}

export function saveBlobAsFile(fileName: string, blobToSave: Blob) {
    function destroyClickedElement(event: any) {
        document.body.removeChild(event.target);
    }

    const textToSaveAsURL = window.URL.createObjectURL(blobToSave);

    const downloadLink = document.createElement('a');
    downloadLink.download = fileName;
    downloadLink.innerHTML = 'Download File';
    downloadLink.href = textToSaveAsURL;
    downloadLink.onclick = destroyClickedElement;
    downloadLink.style.display = 'none';
    document.body.appendChild(downloadLink);

    downloadLink.click();
}

export function saveTextAsFile(
    fileName: string,
    type: string,
    textToSave: string
) {
    function destroyClickedElement(event: any) {
        document.body.removeChild(event.target);
    }

    const textToSaveAsBlob = new Blob([textToSave], { type });
    const textToSaveAsURL = window.URL.createObjectURL(textToSaveAsBlob);

    const downloadLink = document.createElement('a');
    downloadLink.download = fileName;
    downloadLink.innerHTML = 'Download File';
    downloadLink.href = textToSaveAsURL;
    downloadLink.onclick = destroyClickedElement;
    downloadLink.style.display = 'none';
    document.body.appendChild(downloadLink);

    downloadLink.click();
}

export function saveFile(fileName: string, linkToSave: string) {
    function destroyClickedElement(event: any) {
        document.body.removeChild(event.target);
    }
    const downloadLink = document.createElement('a');
    downloadLink.download = fileName;
    downloadLink.innerHTML = 'Download File';
    downloadLink.href = linkToSave;
    downloadLink.onclick = destroyClickedElement;
    downloadLink.style.display = 'none';
    document.body.appendChild(downloadLink);

    downloadLink.click();
}

export async function sleep(milliseconds: number): Promise<void> {
    return new Promise((resolve) => {
        setTimeout(resolve, milliseconds);
    });
}

export function toTitleCase(str: string): string {
    const strings = str.toLowerCase().split(' ');
    for (let i = 0; i < strings.length; i++) {
        strings[i] = strings[i].charAt(0).toUpperCase() + strings[i].slice(1);
    }
    return strings.join(' ');
}

export function getDialogIdFromPath(path: string): string | null {
    const regex = /.*\/modal\/(\w+)\?{0,1}.*$/;

    const result = regex.exec(path);
    if (result === null || !result[1]) {
        return null;
    }

    return result[1] ?? null;
}

export function isDialogOpened(path: string, dialogId: string): boolean {
    return getDialogIdFromPath(path) === dialogId;
}

export function appendDialogIdToPath(path: string, dialogId: string): string {
    if (isDialogOpened(path, dialogId)) {
        return path;
    }

    if (!path.includes('?')) {
        if (!path.endsWith('/')) {
            path += '/';
        }

        path += `modal/${dialogId}`;

        return path;
    }

    const pathParts = path.split('?');

    if (!pathParts[0].endsWith('/')) {
        pathParts[0] += '/';
    }

    pathParts[0] += `modal/${dialogId}`;

    return pathParts.join('?');
}

export function removeDialogIdFromPath(path: string): string {
    return path.replace(/\/modal\/\w+/gi, '');
}

export function closeDialog(propsWithRouter: any, dialogId: string): void {
    const currentPath = propsWithRouter.location.pathname;
    if (!isDialogOpened(currentPath, dialogId)) {
        return;
    }

    propsWithRouter.history.replace(removeDialogIdFromPath(currentPath));
}

export function replaceDialog(
    propsWithRouter: any,
    dialogId: string,
    closePrevDialog = true
): void {
    const currentPath = propsWithRouter.location.pathname;
    if (!closePrevDialog && getDialogIdFromPath(currentPath)) {
        return;
    }

    const pathWithoutDialog = removeDialogIdFromPath(currentPath);
    propsWithRouter.history.push(
        appendDialogIdToPath(pathWithoutDialog, dialogId)
    );
}

export function getRepresentationForItem(
    data: any,
    representationDataSource: string | string[] | Function
): string {
    if (!data) {
        return 'None';
    }

    let label: string;

    if (Array.isArray(representationDataSource)) {
        const labelItems: string[] = [];

        representationDataSource.forEach((fieldName) => {
            labelItems.push(data[fieldName]);
        });

        label = labelItems.join(' ').trim();
    } else if (typeof representationDataSource === 'function') {
        label = representationDataSource(data);
    } else {
        label = data[representationDataSource];
    }

    return label ?? 'None';
}

export function convertQueryParamsToString(queryParams: any): string {
    const result = qs.stringify(queryParams, {
        allowDots: true,
        arrayFormat: 'indices',
    });
    if (!result) {
        return result;
    }

    return `?${result}`;
}

export function escapeRegExp(string: string): string {
    return string.replace(/[.*+?^${}()|[\]\\]/g, '\\$&'); // $& means the whole matched string
}

export function getExtensionFromDataUrl(data: string): string {
    data = data.toLowerCase();
    const [mimePart] = data.split(';');
    const [, mime] = mimePart.split(':');
    const [, extension] = mime.split('/');

    return (
        Object.getOwnPropertyNames(extensionToMimeMap).find(
            (key) => (extensionToMimeMap as any)[key] === mime
        ) || extension
    );
}

export function getExtension(filename: string): string | undefined {
    return filename.split('.').pop();
}

export function useEffectWithOldValues(
    callback: (oldValues: any[] | undefined) => void,
    dependencies: any[]
) {
    const refOldValues = useRef<any[] | undefined>();

    useEffect(() => {
        callback(refOldValues.current);
        refOldValues.current = dependencies;
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, dependencies);
}

export function useDeepComparingEffectMulti(
    callback: (changedKeys: string[], oldValues: any | undefined) => void,
    deepDependencies: any
) {
    const refCallback = useRef(callback);
    refCallback.current = callback;

    const refOldValuesDeep = useRef<any | undefined>();

    const changedKeys = Object.getOwnPropertyNames(deepDependencies).filter(
        (key) => {
            if (!refOldValuesDeep.current) {
                return true;
            }

            return !lodash.isEqual(
                deepDependencies[key],
                refOldValuesDeep.current![key]
            );
        }
    );

    const changeFlags = Object.getOwnPropertyNames(deepDependencies).map((id) =>
        changedKeys.includes(id)
    );

    useEffect(() => {
        refCallback.current(changedKeys, refOldValuesDeep.current);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, changeFlags);

    refOldValuesDeep.current = deepDependencies;
}

export function useDeepComparingEffect<T>(
    callback: (isEqual: boolean, oldValue: T | undefined) => void,
    dependency: T
) {
    const refCallback = useRef(callback);
    const refOldValue = useRef<T | undefined>();
    const isEqual = lodash.isEqual(dependency, refOldValue.current);

    useEffect(() => {
        refCallback.current(isEqual, refOldValue.current);
    }, [isEqual]);

    refOldValue.current = dependency;
}

export function useUnmountedFlag(debugText?: string) {
    const refIsUnmounted = useRef(false);

    useEffect(() => {
        return () => {
            refIsUnmounted.current = true;
            if (window.debug && debugText) {
                console.log(`UNMOUNTED: ${debugText}`);
            }
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const executeIfNotUnmounted = useCallback((callback: () => void) => {
        if (refIsUnmounted.current) {
            return;
        }
        callback();
    }, []);

    const isUnmounted = useCallback((): boolean => {
        return refIsUnmounted.current;
    }, []);

    return { isUnmounted, executeIfNotUnmounted };
}

export const useComponentWillMount = (callback: Function) => {
    const willMount = useRef(true);

    if (willMount.current) callback();

    willMount.current = false;
};

export function useStateAsync<S>(
    isUnmounted: () => boolean,
    initialState: S | (() => S)
) {
    const [value, setValue] = useState(initialState);
    const setter: React.Dispatch<React.SetStateAction<S>> = useCallback(
        (newValue: React.SetStateAction<S>) => {
            if (isUnmounted()) {
                return;
            }
            setValue(newValue);
        },
        [isUnmounted]
    );

    return [value, setter as any];
}

export const mapExtensionToMime = (extensionWithDot: string): string => {
    return (extensionToMimeMap as any)[extensionWithDot.toLowerCase() as any];
};
