import { VStack } from '../../../../../core/components/form/context/types';
import ViewTypes from '../../../../../core/components/form/ViewTypes';
import { getUserName } from '../../../commonFunctions';

const content: VStack = {
    id: 'rootGroup',
    type: ViewTypes.VStack,
    items: [
        {
            id: 'filterGroup',
            type: ViewTypes.HStack,
            style: { marginBottom: 30 },
            items: [
                {
                    id: 'startDate',
                    type: ViewTypes.DateInput,
                    label: 'תאריך התחלה',
                    // style: { width: 350, marginLeft: 10 },
                    showClearButton: true,
                    dataSource: 'startDate',
                    showTimePicker: false,
                    onChangeValue: 'onChangeStartDate',
                },
                {
                    id: 'endDate',
                    type: ViewTypes.DateInput,
                    label: 'תאריך סוף',
                    // style: { width: 350, marginLeft: 10 },
                    showClearButton: true,
                    dataSource: 'endDate',
                    onChangeValue: 'onChangeEndDate',
                },
            ],
        },
        {
            id: 'countersGroup',
            type: ViewTypes.HStack,
            style: { marginBottom: 30 },
            items: [
                {
                    id: 'playedGamesNumber',
                    type: ViewTypes.Counter,
                    label: 'מס׳ משחקים',
                    style: { marginLeft: 10 },
                    dataSource: 'playedGamesNumber',
                },
                {
                    id: 'playersNumber',
                    type: ViewTypes.Counter,
                    label: 'מס׳ שחקנים',
                    style: { marginLeft: 10 },
                    dataSource: 'numberOfPlayers',
                },
                {
                    id: 'numberOfActiveUsers',
                    type: ViewTypes.Counter,
                    label: 'משתמשים פעילים',
                    style: { marginLeft: 10 },
                    dataSource: 'numberOfActiveUsers',
                },
                {
                    id: 'numberOfCompletedWords',
                    type: ViewTypes.Counter,
                    label: 'מספר השימושים בשפת הTea',
                    dataSource: 'numberOfCompletedWords',
                },
            ],
        },
        {
            id: 'tablesGroup',
            type: ViewTypes.VStack,
            items: [
                {
                    id: 'topUsersInTitle',
                    type: ViewTypes.Text,
                    style: {
                        fontSize: 30,
                        fontWeight: 'bold',
                        marginTop: 30,
                        direction: 'ltr',
                        textAlign: 'right',
                    },
                    dataSource: 'topUsersTitle',
                },
                {
                    id: 'topUsersTable',
                    type: ViewTypes.MemoryTable,
                    isSelectable: false,
                    dataSource: 'topUsers',
                    onClickItem: 'onOpenUserCard',
                    sortDataSource: 'topUsersTableSort',
                    pageDataSource: 'url.queryParams.topUsersPage.replace',
                    columns: [
                        {
                            id: 'userId',
                            fieldName: 'userId',
                            type: 'reference',
                            endpointId: 'user',
                            sortListByField: 'firstname',
                            searchByField: [
                                'email',
                                'firstName',
                                'lastName',
                                'phone',
                            ],
                            representationDataSource: getUserName,
                        },
                        {
                            id: 'score',
                            title: 'תוצאה',
                            type: 'number',
                            fieldName: 'score',
                        },
                        {
                            id: 'scoreSum',
                            title: 'סכום כל התוצאות',
                            type: 'number',
                            fieldName: 'scoreSum',
                        },
                    ],
                },
            ],
        },
    ],
};

export default content;
