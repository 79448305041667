import { useEffect, useState } from 'react';
import Selection from '../../../../../../utils/Selection';
import { ColumnBooleanFilterData } from '../props';
import PropsColumnBooleanFilter, { values } from './props';

const getSelectionFromProps = (
    filterData: ColumnBooleanFilterData | undefined,
    values: any[]
): Selection => {
    if (filterData) {
        return Selection.unserealizeFromObject(filterData);
    }

    const selection = new Selection(values.length);
    selection.selectAll();
    return selection;
};

function useBooleanFilterState(props: PropsColumnBooleanFilter) {
    const { columnId } = props;
    const [selection, setSelection] = useState(() =>
        getSelectionFromProps(props.filterData, values)
    );

    useEffect(() => {
        setSelection(getSelectionFromProps(props.filterData, values));
    }, [props.filterData]);

    const onToggleItem = (itemId: string) => (newState: string) => {
        const newSelection = selection.copy();

        if (newState === 'checked') {
            newSelection.selectItem(itemId);
        } else {
            newSelection.unselectItem(itemId);
        }

        setSelection(newSelection);
    };

    const onToggleAll = (newState: string) => {
        const newSelection = selection.copy();

        if (newState === 'checked') {
            newSelection.selectAll();
        } else {
            newSelection.unselectAll();
        }

        setSelection(newSelection);
    };

    const onApply = () => {
        props.onToggleOpen(columnId);
        props.onChangeSelection(columnId, selection.serializeToObject());
    };

    const oldSelection = getSelectionFromProps(props.filterData, values);
    const isFiltered = !(!oldSelection || oldSelection.isAllItemsSelected());

    return {
        isFiltered,
        selection,
        onToggleItem,
        onToggleAll,
        onApply,
    };
}

export default useBooleanFilterState;
