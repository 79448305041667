import moment, { Moment } from 'moment';
import { useEffect, useState } from 'react';
import propsWithDefaultValues from '../../../../../../utils/propsWithDefaultValues';
import { ColumnDateFilterData } from '../props';
import PropsDateFilter, { defaultProps } from './props';
import style from './style.module.scss';

const emptyFilterData: ColumnDateFilterData = { from: '', to: '' };

function getFilteredValue(filterData: ColumnDateFilterData | undefined) {
    const oldStartDate = filterData?.from ?? '';
    const oldEndDate = filterData?.to ?? '';
    let filteredValue = '';

    if (oldStartDate || oldEndDate) {
        const oldStartDateStr =
            (oldStartDate &&
                moment(oldStartDate, 'DD-MM-YYYYTHH:mm').format(
                    'DD/MM/YYTHH:mm'
                )) ||
            '';
        const oldEndDateStr =
            (oldEndDate &&
                moment(oldEndDate, 'DD-MM-YYYYTHH:mm').format(
                    'DD/MM/YYTHH:mm'
                )) ||
            '';
        filteredValue = `${oldStartDateStr} - ${oldEndDateStr}`;

        if (!oldStartDate) {
            filteredValue = `To ${oldEndDateStr}`;
        } else if (!oldEndDate) {
            filteredValue = `From ${oldStartDate}`;
        }
    }

    return filteredValue;
}

function useDateFilterState(props: PropsDateFilter) {
    props = propsWithDefaultValues(props, defaultProps);
    const { isOpened, columnId } = props;

    const [errors, setErrors] = useState<any[]>([]);
    const [filterData, setFilterData] = useState<ColumnDateFilterData>(
        () => props.filterData || emptyFilterData
    );

    useEffect(() => {
        setFilterData(props.filterData || emptyFilterData);
    }, [props.filterData, isOpened]);

    const onClear = () => {
        setFilterData(emptyFilterData);
    };

    const onChangeField = (field: 'from' | 'to') => (value: Moment) => {
        setFilterData((prev) => ({
            ...prev,
            [field]: value ? value.format('DD-MM-YYYY HH:mm') : value,
        }));
    };

    const onApply = () => {
        const errors: any[] = [];
        // const errors = this._checkFields();

        setErrors(errors);

        if (errors.length === 0) {
            const isFiltered = filterData.from || filterData.to;

            props.onToggleOpen(columnId);
            props.onChangeFilter(columnId, isFiltered ? filterData : null);
        }
    };

    const menuClass = isOpened ? style.menuOpened : style.menu;

    const startMoment = filterData.from
        ? moment(filterData.from, 'DD-MM-YYYYTHH:mm')
        : null;
    const endMoment = filterData.to
        ? moment(filterData.to, 'DD-MM-YYYYTHH:mm')
        : null;

    const isFiltered = props.filterData?.from || props.filterData?.to;
    const filteredValue = getFilteredValue(props.filterData);

    return {
        isFiltered,
        errors,
        menuClass,
        startMoment,
        endMoment,
        filteredValue,
        onChangeField,
        onApply,
        onClear,
    };
}

export default useDateFilterState;
