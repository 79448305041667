import Mention from 'rc-editor-mention';
import React from 'react';
import useAutoCompleteInputState from './hooks';
import Item from './item';
import './mentions.scss';
import PropsAutoCompleteInput from './props';
import style from './style.module.scss';

const AutoCompleteInput = (props: PropsAutoCompleteInput) => {
    const { isMultiLine, label, placeholder } = props;
    const {
        onChangeValue,
        isHidden,
        suggestions,
        onSearchChange,
        initialValue,
    } = useAutoCompleteInputState(props);

    if (isHidden) {
        return null;
    }

    return (
        <div
            className={style.autoCompleteInput}
            data-role="view"
            data-is-multiline={!!isMultiLine}
        >
            <label className={style.label}>{label}</label>
            <Mention
                onSearchChange={onSearchChange}
                suggestions={suggestions}
                prefix="#"
                notFoundContent=""
                tag={Item}
                mode="immutable"
                placeholder={placeholder}
                multiLines={isMultiLine}
                onChange={onChangeValue}
                defaultValue={initialValue}
            />
        </div>
    );
};

export default React.memo(AutoCompleteInput);
