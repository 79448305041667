import React, { CSSProperties } from 'react';
import { Link } from 'react-router-dom';
import { getRepresentationForItem } from '../../../../../../utils';
import PropsCellReference from './props';
import style from './style.module.scss';

const CellReference = (props: PropsCellReference) => {
    const { isPageReferencesLoading, value, item, referenceValue, column } =
        props;
    const { getLink, representationDataSource, linkTarget = '_self' } = column;

    const text = getRepresentationForItem(
        referenceValue,
        representationDataSource
    );

    let calculatedStyle: CSSProperties | undefined;

    if (column.style && typeof column.style === 'function') {
        calculatedStyle = column.style!({ column, item, value });
    } else {
        calculatedStyle = column.style;
    }

    let link: string | undefined;
    if (getLink && !isPageReferencesLoading && referenceValue) {
        link = getLink(item.id, item);
    }

    return (
        <div className={style.referenceCell} style={calculatedStyle}>
            {isPageReferencesLoading && 'Loading...'}
            {!isPageReferencesLoading &&
                !referenceValue &&
                value &&
                `NotFound: ${value}`}
            {!isPageReferencesLoading && referenceValue && !link && text}
            {!isPageReferencesLoading && referenceValue && link && (
                <span onClick={(e) => e.stopPropagation()}>
                    <Link target={linkTarget} to={link}>
                        {text}
                    </Link>
                </span>
            )}
        </div>
    );
};

export default React.memo(CellReference);
