import { View } from '../../../../../core/components/form/context/types';
import ViewTypes from '../../../../../core/components/form/ViewTypes';
import content from './content';
import handlers from './handlers';

var startDate = new Date();
startDate.setDate(1);
startDate.setMonth(startDate.getMonth() - 1);

var currentMonth = new Date();
var lastDayOfMonth = new Date(
    currentMonth.getFullYear(),
    currentMonth.getMonth(),
    0
);

const flappyGameTab: View = {
    id: 'flappyGameTab',
    type: ViewTypes.Form,
    fields: {
        playedGamesNumber: '',
        numberOfPlayers: '',
        numberOfActiveUsers: '',
        topTeams: [],
        topTeamsTableSort: [{ columnId: 'objects', direction: 'desc' }],
        topUsersInTeam: [],
        topUsersInTeamTableSort: [{ columnId: 'objects', direction: 'desc' }],
        startDate: startDate,
        endDate: lastDayOfMonth,
        topUsersInTeamTitle: 'משתמשים מובילים בקבוצת ',
    },
    content,
    handlers,
};

export default flappyGameTab;
