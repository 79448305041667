import { ItemFormConfig } from '../../../../core/config-builder/forms/item/types';

const comment: ItemFormConfig = {
    title: 'תגובות',
    forms: {
        list: {
            // content of the list form
            defaultUrlQueryParams: {
                sort: [
                    {
                        columnId: 'createdAt',
                        direction: 'desc',
                    },
                ],
            },
        },
    },
};

export default comment;
