import { CustomSiteConfig } from '../../../core/config-builder/types';
import article from './article';
import category from './category';
import comment from './comment';

const magazine: CustomSiteConfig = {
    title: 'Magazine',
    endpoints: {
        article,
        comment,
        category,
    },
};

export default magazine;
