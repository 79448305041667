import { ItemFormConfig } from '../../../../core/config-builder/forms/item/types';

const category: ItemFormConfig = {
    title: 'קטגוריה',
    forms: {
        list: {
            // content of the list form
            defaultUrlQueryParams: {
                sort: [
                    {
                        columnId: 'createdAt',
                        direction: 'desc',
                    },
                ],
            },
        },
    },
};

export default category;
