import { FieldName } from '../../../../reference-input/props';
import { FilterItem, FunctionSearchItems } from '../../../props';
import { SortDirection } from '../header-sort/props';
import { ColumnReferenceFilterData } from '../props';

interface PropsColumnReferenceFilter {
    columnId: string;
    siteId: string | undefined;
    endpointId: string;
    idField: string;
    isOpened: boolean;
    onToggleOpen: (columnId: string) => void;
    relativePositionX: number;
    isRightSide: boolean;
    filterData: ColumnReferenceFilterData;
    onChangeFilter: (
        columnId: string,
        newSelection: ColumnReferenceFilterData
    ) => void;
    sortListDirection: SortDirection | undefined;
    representationDataSource: FieldName | FieldName[] | Function; // FIXME: add definition
    searchByField: FieldName | FieldName[] | undefined;
    sortListByField: FieldName | undefined;
    onChangeSort: (
        columnId: string,
        newSort: SortDirection,
        multiple: boolean
    ) => void;
    searchItems: FunctionSearchItems;
    rawSearchFilter?: FilterItem[];
}

export default PropsColumnReferenceFilter;

export const defaultProps: Partial<PropsColumnReferenceFilter> = {
    sortListDirection: 'asc',
};
