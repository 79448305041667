import {
    AppContext,
    FormFunctionGetHandlers,
} from '../../../../core/components/form/context/types';
import apiClient from '../../../../core/requests/api';

const handlers: FormFunctionGetHandlers = function (this: AppContext) {
    const onSelectLeadRow = async (selectedRow: any) => {
        return apiClient.updateTableItem('alproducts', 'lead', selectedRow.id, {
            status: 'inProgress',
        });
    };

    const onClickWhatsapp = (item: any) => {
        // Here formContext
        let { phone } = item;
        if (phone.charAt(0) === '0') phone = phone.slice(1);

        // onSelectLeadRow(item);

        const copyText = `https://wa.me/972${phone}?text=אני נציג של חברת א.ל שיווק – A.L האימפריה לחיילים!
השארת לנו פרטים דרך פז"מ,
אצלנו החיילים תמיד במקום הראשון!

רק אוודא את פרטי ההזמנה שלך:
*שם המזמין*: ${item.fullName}
*שם היחידה*: ${item.unit}
*המוצרים שבחרתם*: ${item.products}
*מספר החיילים בהזמנה*: ${item.customers}
            
במידה ויש טעות אנא תקנו במידה ולא כתבו ״מאושר״`;

        const textField = document.createElement('textarea');
        textField.innerText = encodeURI(copyText);
        const parentElement = document.getElementById('root')!;
        parentElement.appendChild(textField);
        textField.select();
        document.execCommand('copy');
        parentElement.removeChild(textField);

        this.form.notify(
            {
                text: 'הודעת וואטסאפ הועתקה',
                lifetimeMs: 3000,
            },
            'ALPRODUCTS_LEAD_COPY'
        );
    };

    return {
        onSelectLeadRow,
        onClickWhatsapp,
    };
};

export default handlers;
