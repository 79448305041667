import { VStack } from '../../../../../components/form/context/types';
import ViewTypes from '../../../../../components/form/ViewTypes';
import ObjectFields from './object-fields';
import style from './style.module.scss';

const content: VStack = {
    id: 'rootGroup',
    type: ViewTypes.VStack,
    items: [
        {
            id: 'dialogTitle',
            type: ViewTypes.Text,
            dataSource: 'dialogTitle',
            className: style.title,
        },
        {
            id: 'fieldsGroup',
            type: ViewTypes.VStack,
            className: style.fieldsGroup,
            items: [
                {
                    id: 'id',
                    type: ViewTypes.TextInput,
                    isHidden: false,
                    label: 'ID',
                    ltr: true,
                    dataSource: 'schema.id',
                    onChangeValue: 'onChangeId',
                },
                {
                    id: 'title',
                    label: 'Title',
                    type: ViewTypes.TextInput,
                    ltr: true,
                    dataSource: 'schema.title',
                },
                {
                    id: 'type',
                    label: 'Type',
                    type: ViewTypes.EnumInput,
                    dataSource: 'schema.type',
                    values: [
                        {
                            label: 'array',
                            value: 'array',
                        },
                        {
                            label: 'boolean',
                            value: 'boolean',
                        },
                        {
                            label: 'integer',
                            value: 'integer',
                        },
                        {
                            label: 'number',
                            value: 'number',
                        },
                        {
                            label: 'object',
                            value: 'object',
                        },
                        {
                            label: 'string',
                            value: 'string',
                        },
                    ],
                },
                {
                    id: 'itemType',
                    label: 'Items Type',
                    type: ViewTypes.EnumInput,
                    isHidden: 'isItemsTypeHidden',
                    dataSource: 'schema.items.type',
                    values: [
                        {
                            label: 'boolean',
                            value: 'boolean',
                        },
                        {
                            label: 'integer',
                            value: 'integer',
                        },
                        {
                            label: 'number',
                            value: 'number',
                        },
                        {
                            label: 'object',
                            value: 'object',
                        },
                        {
                            label: 'string',
                            value: 'string',
                        },
                        {
                            label: 'array',
                            value: 'array',
                        },
                    ],
                },
                {
                    id: 'format',
                    label: 'Format',
                    type: ViewTypes.EnumInput,
                    dataSource: 'schema.format',
                    isHidden: 'isFormatHidden',
                    values: [
                        {
                            label: 'color',
                            value: 'color',
                        },
                        {
                            label: 'date',
                            value: 'date',
                        },
                        {
                            label: 'datetime',
                            value: 'datetime',
                        },
                        {
                            label: 'datetime-local',
                            value: 'datetime-local',
                        },
                        {
                            label: 'email',
                            value: 'email',
                        },
                        {
                            label: 'month',
                            value: 'month',
                        },
                        {
                            label: 'number',
                            value: 'number',
                        },
                        {
                            label: 'range',
                            value: 'range',
                        },
                        {
                            label: 'tel',
                            value: 'tel',
                        },
                        {
                            label: 'text',
                            value: 'text',
                        },
                        {
                            label: 'textarea',
                            value: 'textarea',
                        },
                        {
                            label: 'html',
                            value: 'html',
                        },
                        {
                            label: 'time',
                            value: 'time',
                        },
                        {
                            label: 'url',
                            value: 'url',
                        },
                        {
                            label: 'week',
                            value: 'week',
                        },
                        {
                            label: 'checkbox',
                            value: 'checkbox',
                        },
                        {
                            label: 'file',
                            value: 'file',
                        },
                    ],
                },
                {
                    id: 'object',
                    type: ViewTypes.CustomComponent,
                    component: ObjectFields,
                    label: 'object',
                    isHidden: 'isItemPropertiesHidden',
                    dataSource: 'schema.item.properties',
                },
                {
                    id: 'private',
                    type: ViewTypes.BooleanInput,
                    label: 'Private',
                    dataSource: 'schema.private',
                },
                {
                    id: 'anonymousCreate',
                    type: ViewTypes.BooleanInput,
                    label: 'Anonymous can create',
                    dataSource: 'schema.anonymousCreate',
                },
                {
                    id: 'required',
                    type: ViewTypes.BooleanInput,
                    label: 'Required',
                    dataSource: 'schema.required',
                },
                {
                    id: 'defaultValue',
                    label: 'Default value',
                    type: ViewTypes.TextInput,
                    ltr: true,
                    dataSource: 'schema.default',
                },
                {
                    id: 'settingsOptionsTitle',
                    type: ViewTypes.Text,
                    isHidden: 'isUploadHidden',
                    value: 'Options',
                    className: style.optionsTitle,
                },
                {
                    id: 'upload',
                    type: ViewTypes.BooleanInput,
                    isHidden: 'isUploadHidden',
                    label: 'Upload',
                    dataSource: 'schema.options.upload',
                },
                {
                    id: 'allowed',
                    type: ViewTypes.EnumInput,
                    isHidden: 'isUploadHidden',
                    isMultiple: true,
                    label: 'Allowed extensions',
                    // description:
                    // 'List of allowed file extensions. Only valid if this is a upload field (type: string, format: url, options.upload: true)',
                    dataSource: 'schema.options.allowed',
                    values: [
                        'jpg',
                        'jpeg',
                        'png',
                        'gif',
                        'webp',
                        'tif',
                        'bmp',
                        'psd',
                        'zip',
                        'tar',
                        'rar',
                        'gz',
                        'bz2',
                        '7z',
                        'mp4',
                        'm4v',
                        'mid',
                        'mkv',
                        '3gp',
                        'webm',
                        'mov',
                        'avi',
                        'wmv',
                        'mpg',
                        'mp3',
                        'm4a',
                        'ogg',
                        'flac',
                        'wav',
                        'pdf',
                        'epub',
                        'rtf',
                        'woff',
                        'woff2',
                        'ttf',
                        'otf',
                        'json',
                        'lottie',
                        'svg',
                    ]
                        .sort()
                        .map((value) => ({ value, label: value })),
                },
                {
                    id: 'description',
                    type: ViewTypes.TextInput,
                    label: 'Description',
                    isMultiLine: true,
                    ltr: true,
                    dataSource: 'schema.description',
                },
                {
                    id: 'enum',
                    type: ViewTypes.List,
                    label: 'Enum',
                    dataSource: 'schema.enum',
                },
            ],
        },
        {
            id: 'footer',
            type: ViewTypes.HStack,
            items: [
                {
                    id: 'applyButton',
                    type: ViewTypes.Button,
                    text: 'Save',
                    onClick: 'onSave',
                    style: { marginLeft: 15 },
                },
                {
                    id: 'cancelButton',
                    type: ViewTypes.Button,
                    role: 'notActive',
                    text: 'Cancel',
                    onClick: 'onClose',
                },
            ],
        },
    ],
};

export default content;
