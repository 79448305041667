import { CustomEndpointConfig } from '../../../../core/config-builder/types';
import forms from './forms';

const locationConfig: CustomEndpointConfig = {
    title: 'מיקומים',
    metaInfo: {
        propertiesOrder: ['city', 'subLocation', 'mapCoords'],
        representationDataSource: (item: any) =>
            `${item.city} ${item.subLocation}`,
    },
    forms,
};

export default locationConfig;
