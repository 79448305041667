import { useEffect, useState } from 'react';
import PropsItems from './props';

export const useItemsState = (props: PropsItems) => {
    const [items, setItems] = useState(() => props.items.slice().reverse());

    useEffect(() => {
        setItems(props.items.slice().reverse());
    }, [props.items]);

    const onOpenItem = (itemId: string) => () => {
        props.onOpenItem(itemId);
    };

    const onDeleteItem = (itemId: string) => () => {
        props.onDeleteItem(itemId);
    };

    return {
        items,
        onOpenItem,
        onDeleteItem,
    };
};
