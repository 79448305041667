import React from 'react';
import { SortableContainer, SortableElement } from 'react-sortable-hoc';
import Item from '../media-photo-item';
import { MediaPhotoItemData } from '../media-photo-item/props';
import { useItemsState } from './hooks';
import PropsItems from './props';
import style from './style.module.scss';

const SortableItem = SortableElement((props: any) => <Item {...props} />);

const Items = (props: PropsItems) => {
    const { isInEditMode, itemSize } = props;
    const { items, onOpenItem, onDeleteItem } = useItemsState(props);

    return (
        <div className={style.items}>
            {items.map((item: MediaPhotoItemData, index) => {
                const itemId = item.id;
                return (
                    <SortableItem
                        itemData={item as any}
                        itemSize={itemSize}
                        isInEditMode={isInEditMode}
                        key={`item-${index}`}
                        index={index}
                        disabled={!isInEditMode}
                        onDelete={onDeleteItem(itemId)}
                        onOpen={onOpenItem(itemId)}
                        onClick={
                            isInEditMode
                                ? onDeleteItem(itemId)
                                : onOpenItem(itemId)
                        }
                    />
                );
            })}
        </div>
    );
};

export default SortableContainer(Items);
