import { UserData } from '../../components/form/context/types';
import apiClient from '../../requests/api';
import { Observable } from '../definition';

const actions = {
    loginByToken: (data: { authToken: string }) => {
        return async (store: Observable) => {
            store.account.dispatch.loginByTokenRequest();
            try {
                const userData = await apiClient.getCurrentUserData(
                    data.authToken
                );
                store.account.dispatch.loginSuccess({
                    rememberMe: true,
                    token: data.authToken,
                    userData,
                });
            } catch (e) {
                if (e && e.message === '403' && e.token) {
                    store.account.dispatch.requiredPasswordChange(e.token);
                } else if (e && e.message === '403') {
                    store.account.dispatch.logout({ redirectToLogin: true });
                    store.account.dispatch.loginByTokenError();
                } else {
                    alert('Problem with connection. Please reload page');
                }
            }
        };
    },

    requiredPasswordChange: (data: { token: string }) => {
        return async (store: Observable) => {
            store.account.dispatch.logout({ redirectToLogin: false });
            store.account.dispatch.setTokenForChangingPassword({
                token: data.token,
            });
            store.routerHistory.dispatch.replace('/login?change_password');
        };
    },

    logout: (data: { redirectToLogin?: boolean }) => {
        return async (store: Observable) => {
            window._AUTH_TOKEN = undefined;
            localStorage.removeItem('authToken');
            apiClient.logout();
            store.account.dispatch.logoutSuccess();
            store.app.dispatch.resetData();
            store.loginPage.dispatch.resetData();
            store.notifications.dispatch.resetData();
            store.app.dispatch.updateAppConfig({
                userData: null,
                customConfig: window._CUSTOM_APP_CONFIG,
            });

            if (data?.redirectToLogin ?? true) {
                store.routerHistory.dispatch.push('/login');
            }
        };
    },

    loginSuccess: (data: {
        rememberMe: boolean;
        token: string;
        userData: UserData;
    }) => {
        return async (store: Observable) => {
            let { token, rememberMe, userData } = data;
            if (rememberMe) {
                localStorage.setItem('authToken', token);
            } else {
                localStorage.removeItem('authToken');
            }

            window._AUTH_TOKEN = token;

            try {
                const sites = await apiClient.getSites();
                userData.allowedSitesIds = Object.keys(sites);
                store.account.dispatch.loginByTokenSuccess({ userData });
                store.app.dispatch.updateAppConfig({
                    userData,
                    customConfig: window._CUSTOM_APP_CONFIG,
                });
            } catch (e) {
                console.log('LOGIN ERROR', e);
                debugger;
                store.account.dispatch.logout({ redirectToLogin: true });

                store.notifications.dispatch.pushNotification({
                    data: {
                        text: 'Some error ' + JSON.stringify(e),
                        type: 'error',
                    },
                });
                store.loginPage.dispatch.loginError({ errors: [] });
            }
        };
    },

    changePassword: (data: { newPassword: string; token: string }) => {
        return async (store: Observable) => {
            const { newPassword, token } = data;
            store.account.dispatch.changePasswordRequest();
            try {
                await apiClient.changePassword(newPassword, token, undefined);
                store.account.dispatch.setTokenForChangingPassword({
                    token: null,
                });
                store.account.dispatch.changePasswordSuccess();
                store.notifications.dispatch.pushNotification({
                    data: {
                        text: 'The password is changed! Try to login',
                        lifetimeMs: 3000,
                    },
                    id: 'CHANGE_PASSWORD',
                });

                store.routerHistory.dispatch.replace('/login');
            } catch (e) {
                store.account.dispatch.changePasswordError(e);
            }
        };
    },
};

export default actions;
