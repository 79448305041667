import icon from '../../../assets/icons/upload_image_icon.svg';

export type DataUrl = string;

export interface FileData {
    data: string | DataUrl;
    file: File;
}

export default interface PropsUploadInput {
    id: string;
    title?: string;
    onSelected: (files: FileData[]) => void;
    icon?: string;
    type?: 'text' | 'dataUrl';
    maxSizeKB?: number;
    displayError?: boolean;
    isLoading?: boolean;
    isSelected?: boolean;
    onDelete?: () => void;
    multiple?: boolean;
    isDisabled?: boolean; // FIXME: Add support
    extensionsWithDot?: string[];
}

export const defaultProps: Partial<PropsUploadInput> = {
    title: 'Drag & Drop \n to upload or',
    icon,
    type: 'dataUrl',
    maxSizeKB: 300,
    displayError: false,
    extensionsWithDot: [
        '.svg',
        '.png',
        '.jpg',
        '.jpeg',
        '.bmp',
        '.webp',
        '.gif',
    ],
};
