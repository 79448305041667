import React, { CSSProperties, useRef } from 'react';
import { ColumnEnum, ColumnReference, ColumnReferenceArray } from '../../types';
import BooleanFilter from './boolean-filter';
import DateFilter from './date-filter';
import EnumFilter from './enum-filter';
import HeaderSort from './header-sort';
import ItemsFilter from './items-filter';
import PropsColumnFilter, {
    ColumnDateFilterData,
    ColumnEnumFilterData,
    ColumnReferenceFilterData,
} from './props';

const enumFilterValues = [
    {
        value: 'filled',
        label: 'Filled',
    },
    { value: 'notFilled', label: 'Not filled' },
];

const ColumnFilter = (props: PropsColumnFilter) => {
    const {
        sortDirection,
        column,
        isOpened,
        onToggleOpen,
        onChangeSort,
        onChangeFilter,
        filterData,
        isRightSide,
        searchItems,
    } = props;

    const refElement = useRef<HTMLTableDataCellElement | null>(null);

    const columnStyle: CSSProperties = {};
    if (column.width) {
        columnStyle.width = column.width;
        if (column.type === 'date') {
            columnStyle.width = 150;
        }
    }

    const _getRelativePosition = () => {
        if (!refElement.current) {
            return 0;
        }

        const element: any = refElement.current;

        const trX = element.parentElement.getBoundingClientRect().right;
        const tdX = element.getBoundingClientRect().right;

        return trX - tdX;
    };

    const _renderFilter = () => {
        const element = (
            <HeaderSort
                columnId={column.id}
                sortDirection={sortDirection}
                onChangeSort={onChangeSort}
            />
        );

        const filterProps = {
            isOpened,
            onToggleOpen,
            sortDirection,
            onChangeSort,
        };

        const columnType = column.type;

        const relativePositionX = _getRelativePosition();

        switch (columnType) {
            case 'enum': {
                return (
                    <EnumFilter
                        {...filterProps}
                        columnId={column.id}
                        values={(column as ColumnEnum).values}
                        onChangeSelection={onChangeFilter}
                        // FIXME:
                        // isSortable={
                        //     column.isSortable === undefined
                        //         ? true
                        //         : column.isSortable
                        // }
                        filterData={filterData as ColumnEnumFilterData}
                        // sortDirection={sortDirection}
                        isRightSide={isRightSide}
                    />
                );
            }
            case 'boolean': {
                return (
                    <BooleanFilter
                        columnId={column.id}
                        isOpened={isOpened}
                        onToggleOpen={onToggleOpen}
                        onChangeSelection={onChangeFilter}
                        // FIXME:
                        // isSortable={
                        //     column.isSortable === undefined
                        //         ? true
                        //         : column.isSortable
                        // }
                        filterData={filterData as ColumnEnumFilterData}
                        // sortDirection={sortDirection}
                        isRightSide={isRightSide}
                    />
                );
            }
            case 'date':
                return (
                    <DateFilter
                        columnId={column.id}
                        isOpened={isOpened}
                        onToggleOpen={onToggleOpen}
                        isSortable={
                            column.isSortable === undefined
                                ? true
                                : column.isSortable
                        }
                        onChangeFilter={onChangeFilter}
                        filterData={filterData as ColumnDateFilterData}
                        sortDirection={sortDirection}
                        isRightSide={isRightSide}
                        onChangeSort={onChangeSort}
                    />
                );
            case 'reference':
            case 'referenceArray': {
                const columnReference: ColumnReference | ColumnReferenceArray =
                    column as ColumnReference;
                return (
                    <ItemsFilter
                        columnId={column.id}
                        isOpened={isOpened}
                        idField={columnReference.idField ?? '_id'}
                        siteId={columnReference.siteId}
                        endpointId={columnReference.endpointId}
                        searchItems={searchItems}
                        sortListByField={columnReference.sortListByField}
                        sortListDirection={filterProps.sortDirection}
                        onToggleOpen={onToggleOpen}
                        onChangeSort={onChangeSort}
                        searchByField={columnReference.searchByField}
                        representationDataSource={
                            columnReference.representationDataSource ||
                            (columnReference.CellComponent &&
                                (columnReference.CellComponent as any)
                                    .representationDataSource) // FIXME: Add to types
                        }
                        filterData={filterData as ColumnReferenceFilterData}
                        onChangeFilter={onChangeFilter}
                        relativePositionX={relativePositionX}
                        isRightSide={isRightSide}
                        rawSearchFilter={columnReference.rawSearchFilter}
                    />
                );
            }
            case 'image':
                return (
                    <EnumFilter
                        {...filterProps}
                        columnId={column.id}
                        values={enumFilterValues}
                        onChangeSelection={onChangeFilter}
                        // FIXME:
                        // isSortable={
                        //     column.isSortable === undefined
                        //         ? true
                        //         : column.isSortable
                        // }
                        filterData={filterData as ColumnEnumFilterData}
                        // sortDirection={sortDirection}
                        isRightSide={isRightSide}
                    />
                );

            case 'progress':
            case 'button':
                return <div />;
        }

        return element;
    };

    return (
        <td
            key={`col_filter_${column.id}`}
            style={columnStyle}
            ref={refElement}
        >
            {_renderFilter()}
        </td>
    );
};

export default React.memo(ColumnFilter);
