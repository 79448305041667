import {
    AppContext,
    FormFunctionGetHandlers,
} from '../../../components/form/context/types';
import ViewTypes from '../../../components/form/ViewTypes';
import defaultHandlers from '../defaultHandlers';
import DialogParamsYesNo from './params';

const handlers: FormFunctionGetHandlers = function (this: AppContext) {
    const onBeforeOpen = async () => {
        const {
            text,
            yesAction,
            yesLabel,
            noAction,
            noLabel,
        }: DialogParamsYesNo = this.form.params;
        this.form.views.text.value = text;

        this.form.views.footer.items = [
            {
                id: 'yes',
                type: ViewTypes.Button,
                text: yesLabel || 'Yes',
                role: 'active',
                onClick: async (e: any) => {
                    this.form.views.rootGroup.isLoading = true;
                    try {
                        await yesAction();
                        this.form.closeDialog();
                    } catch (e) {}

                    this.form.views.rootGroup.isLoading = false;
                },
                style: { marginLeft: 15 },
            },
            {
                id: 'no',
                type: ViewTypes.Button,
                text: noLabel || 'No',
                role: 'notActive',
                style: { width: 100 },
                onClick: () => {
                    if (noAction) {
                        noAction();
                    } else {
                        this.form.closeDialog();
                    }
                },
            },
        ];
    };

    return {
        ...defaultHandlers.call(this),
        onBeforeOpen,
    };
};

export default handlers;
