import FormConfig from '../../../../core/components/form/types';
import ViewTypes from '../../../../core/components/form/ViewTypes';
import flappyGameForm from './flappy-game';
import gazartea from './gazartea';
import wordGameForm from './word-game';
import wordle from './wordle';
import yesnoGameForm from './yesno-game';

const form: FormConfig = {
    id: 'cbc-dashboard',
    title: 'CBC Dashboard',
    fields: {
        activeTabId: 'wordGame',
    },
    content: {
        id: 'rootGroup',
        type: ViewTypes.TabsGroup,
        style: { height: '100%' },
        activeTabIdSource: 'activeTabId',
        tabs: [
            {
                id: 'flappyGame',
                type: ViewTypes.TabPage,
                title: 'העפתי ת׳פקק',
                content: flappyGameForm,
            },
            {
                id: 'wordGame',
                type: ViewTypes.TabPage,
                title: 'ערבבתי',
                content: wordGameForm,
            },
            {
                id: 'yesnoGame',
                type: ViewTypes.TabPage,
                title: 'מה הסיכוי?',
                content: yesnoGameForm,
            },
            {
                id: 'gazartea',
                type: ViewTypes.TabPage,
                title: 'גזרתי',
                content: gazartea,
            },
            {
                id: 'wordle',
                type: ViewTypes.TabPage,
                title: 'ניחשתי',
                content: wordle,
            },
        ],
    },
};

export default form;
