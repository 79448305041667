import { CustomEndpointConfig } from '../../../../core/config-builder/types';
import { getUserName } from '../../commonFunctions';
import forms from './forms';

const stagemedia: CustomEndpointConfig = {
    metaInfo: {
        properties: {
            userId: {
                type: 'string',
                format: 'reference',
                siteId: 'cbc',
                endpointId: 'profile',
                idField: 'userId',
                sortListByField: 'firstname',
                searchByField: ['email', 'firstname', 'lastname', 'phone'],
                representationDataSource: getUserName,
            },
        },
    },
    forms,
};

export default stagemedia;
