import lodash, { cloneDeep } from 'lodash';
import qs from 'qs';
import { generatePath } from 'react-router-dom';
import {
    EndpointConfig,
    RemoteMetaInfoEndpoint,
    RemoteMetaInfoSite,
    SiteConfig,
} from '../../../config-builder/types';
import { Observable } from '../../../globalStore/definition';
import apiClient from '../../../requests/api';
import { convertQueryParamsToString } from '../../../utils';
import shallowUpdate from './shallowUpdate';
import {
    AppContext,
    DataSourcePath,
    DialogData,
    DisplayErrors,
    Form,
    InputWithDisplayError,
    NotificationData,
    QueryParams,
    RawAppContext,
    UpdateUrlData,
    Url,
} from './types';
import getViewsTreeItems, { updateViewsTree } from './Views';

function getDataSourceValue<ValueType>(
    this: RawAppContext,
    dataSource: DataSourcePath<ValueType>
): ValueType | undefined | null {
    if ((dataSource as string).startsWith('url.')) {
        dataSource = (dataSource as string)
            .replace('.replace', '')
            .replace('.push', '');
    }

    const propertyNotFound = '___PROPERTYNOTFOUND___';
    const result = lodash.get(
        this.form,
        dataSource as string,
        propertyNotFound
    );

    if (result === propertyNotFound) {
        return undefined;
    }

    if (result === undefined) {
        return null;
    }

    return result;
}

function parseQueryParams(queryParamsText: string): QueryParams {
    if (!queryParamsText) {
        return {};
    }

    return (
        qs.parse(queryParamsText.replace(/^\?/gm, ''), {
            allowDots: true,
            // arrayFormat: 'indices',
        }) || {}
    );
}

function _handleSetUrl(
    this: RawAppContext,
    store: Observable,
    dataSource: string,
    value: any
) {
    const { url } = this.form;
    const { matchedPath, params, queryParams } = url;
    let newParams = params;
    let newQueryParams = queryParams;
    let queryParamsString = convertQueryParamsToString(queryParams);

    const isReplace = dataSource.endsWith('.replace');
    dataSource = dataSource
        .replace('url.', '')
        .replace('.replace', '')
        .replace('.push', '');

    if (dataSource.startsWith('params.')) {
        const paramName = dataSource.replace('params.', '');
        newParams = {
            ...params,
            [paramName]: value,
        };
    } else if (dataSource.startsWith('queryParams.')) {
        // FIXME: add multiple support
        const paramName = dataSource.replace('queryParams.', '');
        newQueryParams = {
            ...queryParams,
            [paramName]: value,
        };

        Object.keys(newQueryParams).forEach((queryParamName) => {
            const value = newQueryParams[queryParamName];
            if (value === undefined) {
                delete newQueryParams[queryParamName];
            }
        });

        queryParamsString = convertQueryParamsToString(newQueryParams);
    }

    const cleanedParams: any = {};
    Object.getOwnPropertyNames(newParams).forEach((key) => {
        const value = newParams[key];
        if (!value) {
            return;
        }

        cleanedParams[key] = value;
    });

    const newUrl = generatePath(matchedPath, cleanedParams) + queryParamsString; // + queryParams.toString(); FIXME: add query params support

    this.form.url = {
        ...url,
        // FIXME: matched path can be not updated yet
        value: newUrl,
        params: newParams,
        queryParams: newQueryParams,
    };

    if (isReplace) {
        store.routerHistory.dispatch.replace(newUrl);
    } else {
        store.routerHistory.dispatch.replace(newUrl);
    }
}

function setFormDataSourceValue(
    this: RawAppContext,
    store: Observable,
    dataSource: string,
    value: any
): void {
    const isUrlParamsChange = dataSource.startsWith('url.');
    if (isUrlParamsChange) {
        _handleSetUrl.call(this, store, dataSource, value);
        return;
    }

    let newForm;
    if (dataSource.startsWith('views.')) {
        const [, viewId, ...restPath] = dataSource.split('.');
        const newRootView = updateViewsTree(this.form.rootView, (view) => {
            if (view.id !== viewId) {
                return view;
            }

            return shallowUpdate(view, restPath.join('.'), value, true);
        });

        const result = getViewsTreeItems(newRootView);
        newForm = {
            ...this.form,
            rootView: newRootView,
            views: result.views,
        };
    } else {
        newForm = shallowUpdate(this.form, dataSource, value, true);
    }

    newForm.formVersion += 1;
    this.form = newForm;
    this.version += 1;
    this.form.update();
}

function pushUrl(store: Observable, url: string | UpdateUrlData): void {
    if (!lodash.isPlainObject(url)) {
        store.routerHistory.dispatch.push(url as string);
        return;
    }

    const { path, queryParams } = url as UpdateUrlData;
    let urlText = path;
    if (queryParams) {
        urlText += convertQueryParamsToString(queryParams);
    }
    store.routerHistory.dispatch.push(urlText);
}

function replaceUrl(store: Observable, url: string | UpdateUrlData) {
    if (!lodash.isPlainObject(url)) {
        store.routerHistory.dispatch.replace(url as string);
        return;
    }

    const { path, queryParams } = url as UpdateUrlData;
    let urlText = path;
    if (queryParams) {
        urlText += convertQueryParamsToString(queryParams);
    }
    store.routerHistory.dispatch.replace(urlText);
}

function updateUrl(
    this: RawAppContext,
    proxiedContext: AppContext,
    path: string,
    url: string,
    urlParams: any,
    queryParams: any
) {
    const oldUrl: Url = this.form.url;
    const newUrl: Url = {
        ...this.form.url,
        matchedPath: path,
        value: url,
        params: urlParams || {},
        queryParams: parseQueryParams(queryParams) || {},
    };

    this.form.setDataSourceValue('url', newUrl);

    if (proxiedContext?.form?.handlers) {
        const { onChangeUrl } = proxiedContext.form.handlers;
        onChangeUrl && onChangeUrl.call(proxiedContext, newUrl, oldUrl);
    }
}

function _getSiteConfig(
    this: RawAppContext,
    siteId: string
): SiteConfig | undefined {
    const { appConfig } = this;
    return (appConfig.sites || []).find((site) => site.id === siteId);
}

function _getSiteRemoteSchema(
    this: RawAppContext,
    siteId: string
): RemoteMetaInfoSite | undefined {
    if (!siteId) {
        return;
    }

    return this.remoteMetaInfo.sites[siteId];
}

function _getEndpointRemoteSchema(
    this: RawAppContext,
    siteId: string,
    endpointId: string
): RemoteMetaInfoEndpoint | undefined {
    if (!siteId && !endpointId) {
        return;
    }
    return this.remoteMetaInfo.sites[siteId].endpoints[endpointId];
}

function _getEndpointConfig(
    this: RawAppContext,
    siteId: string,
    endpointId: string
): EndpointConfig | undefined {
    const siteConfig: any = _getSiteConfig.call(this, siteId);
    if (!siteConfig) {
        return;
    }

    return siteConfig.endpoints.find(
        (endpoint: EndpointConfig) => endpoint.id === endpointId
    );
}

function _getEndpointMetaInfo(
    this: RawAppContext,
    siteId: string,
    endpointId: string
): any {
    const endpointConfig = _getEndpointConfig.call(this, siteId, endpointId);
    if (!endpointConfig) {
        return;
    }
    return endpointConfig.metaInfo;
}

function hideErrors(
    this: RawAppContext,
    notificationPrefix: string,
    viewsIds?: string[]
): void {
    this.form.hideNotification(new RegExp(`^${notificationPrefix}`));

    Object.getOwnPropertyNames(this.form.views).forEach((viewId) => {
        if (viewsIds && !viewsIds.includes(viewId)) {
            const view = this.form.views[viewId] as InputWithDisplayError;

            if (view) {
                this.form.setDataSourceValue(
                    `views.${viewId}.displayError`,
                    false
                );
            }
            return;
        }

        this.form.setDataSourceValue(`views.${viewId}.displayError`, false);
    });
}

function displayErrors(
    this: RawAppContext,
    errors: DisplayErrors,
    notificationPrefix: string
): void {
    Object.getOwnPropertyNames(errors).forEach((id) => {
        const { inputId, text } = errors[id];

        const view = this.form.views[inputId] as InputWithDisplayError;
        if (!view) {
            console.error(`DISPLAY ERROR: Can't find the input: ${inputId}`);
        } else {
            if (view) {
                this.form.setDataSourceValue(
                    `views.${inputId}.displayError`,
                    true
                );
            }
        }

        this.form.notify(
            {
                type: 'error',
                text,
                lifetimeMs: 3000,
            },
            `${notificationPrefix}${id}`
        );
    });
}

function setupViewCallbacks(
    this: RawAppContext,
    viewId: string,
    callbacks: any
): void {
    const { form } = this;
    if (!form.__callbacks__) {
        form.__callbacks__ = {};
    }

    if (!form.__callbacks__[viewId]) {
        form.__callbacks__[viewId] = {};
    }

    Object.assign(form.__callbacks__[viewId], callbacks);
}

function cleanViewCallbacks(this: RawAppContext, viewId: string): void {
    const { form } = this;
    if (form.__callbacks__ && form.__callbacks__[viewId]) {
        form.__callbacks__[viewId] = {};
    }
}

export interface ChangedContextEnvironment {
    store?: Observable;
    urlData?: {
        url: string;
        path: string;
        queryParams: any;
        urlParams: any;
    };
    formData?: Form;
    forceUpdateForm?: () => void;
    reload?: () => void;
}

export function updateRawAppContext(
    baseContext: RawAppContext,
    {
        store,
        urlData,
        formData,
        forceUpdateForm,
        reload,
    }: ChangedContextEnvironment,
    { createViews }: { createViews: boolean }
): RawAppContext {
    const context: RawAppContext = baseContext!;
    context.version = (context.version ?? 0) + 1;

    if (store) {
        const currentUserData = store.account.getState();
        context.currentUserData = currentUserData as any; //FIXME:

        const { remoteMetaInfo, config: appConfig } = store.app.getState();
        context.appConfig = appConfig!;
        context.remoteMetaInfo = remoteMetaInfo;
    }

    if (!context.api) {
        context.api = {
            ...apiClient,
            get: apiClient.get,
            update: apiClient.update,
            delete: apiClient.remove,
            create: apiClient.create,
        };
    }

    const isFirsFormInitialization = !context.form;

    if (formData) {
        const {
            id,
            content,
            handlers,
            params,
            title,
            subtitle,
            commands,
            fields,
        } = formData;
        context.id = id;

        let views = baseContext?.form?.views;

        if (createViews) {
            const result = getViewsTreeItems(content);
            views = result.views;
        }

        if (!views || !content) {
            debugger;
        }
        // context.content = formData.content;
        context.form = {
            ...context.form,
            ...(baseContext.form ?? {}),
            id,
            formVersion: 0,
            title,
            subtitle,
            commands: commands ?? [],
            isLoading: false,
            rootView: views[content.id],
            views,
            handlers: handlers,
            params,
        };

        if (isFirsFormInitialization) {
            Object.keys(fields ?? {}).forEach((field) => {
                context.form[field] = fields![field];
            });
        }
    }

    if (urlData) {
        const { path, url, urlParams, queryParams } = urlData;
        if (path === '/') debugger;
        context.form.url = {
            ...(context.form.url ?? {}),
            matchedPath: path,
            value: url,
            params: urlParams ?? {},
            queryParams: parseQueryParams(queryParams) ?? {},
        };
    }

    if (store) {
        if (!context.form.url.push)
            context.form.url.push = pushUrl.bind(context, store) as any;
        if (!context.form.url.goBack)
            context.form.url.goBack = store.routerHistory.dispatch.goBack;

        if (!context.form.url.replace)
            context.form.url.replace = replaceUrl.bind(context, store) as any;
    }

    if (!context.getEndpointMetaInfo)
        context.getEndpointMetaInfo = _getEndpointMetaInfo.bind(context);

    if (!context.getEndpointConfig)
        context.getEndpointConfig = _getEndpointConfig.bind(context);

    if (!context.getSiteConfig)
        context.getSiteConfig = _getSiteConfig.bind(context);

    if (!context.getEndpointRemoteSchema)
        context.getEndpointRemoteSchema =
            _getEndpointRemoteSchema.bind(context);

    if (!context.getSiteRemoteSchema)
        context.getSiteRemoteSchema = _getSiteRemoteSchema.bind(context);

    if (!context.logout && store) {
        context.logout = () =>
            store?.account.dispatch.logout({ redirectToLogin: true });
    }

    if (store && !context.updateAppConfigFromRemoteMetainfo)
        context.updateAppConfigFromRemoteMetainfo = () => {
            store.app.dispatch.updateAppConfig({
                userData: context.currentUserData,
                customConfig: window._CUSTOM_APP_CONFIG,
            });
        };

    if (context.form.update !== forceUpdateForm && forceUpdateForm) {
        context.form.update = forceUpdateForm;
    }

    if (context.form.reload !== reload && reload) {
        context.form.reload = reload;
    }

    if (!context.form.notify && store) {
        context.form.notify = (data: NotificationData, id?: string) =>
            store.notifications.dispatch.pushNotification({ data, id });
    }

    if (!context.form.hideNotification && store) {
        context.form.hideNotification = (id: string | RegExp) =>
            store.notifications.dispatch.hideNotification({ id });
    }

    if (!context.form.getDataSourceValue) {
        context.form.getDataSourceValue = getDataSourceValue.bind(
            context
        ) as any;
    }

    if (!context.form.setDataSourceValue && store) {
        context.form.setDataSourceValue = setFormDataSourceValue.bind(
            context,
            store
        ) as any;
    }

    if (!context.form.replaceDialog && store) {
        context.form.replaceDialog = (dialogId: string, params: any) =>
            store.app.dispatch.replaceDialog({ id: dialogId, params });
    }

    if (!context.form.pushDialog && store) {
        context.form.pushDialog = (id: string, params: any) =>
            store.app.dispatch.pushDialog({ id, params });
    }

    if (!context.form.closeDialog && store) {
        context.form.closeDialog = store.app.dispatch.closeDialog;
    }

    if (!context.form._updateUrl) {
        context.form._updateUrl = updateUrl.bind(context);
    }

    if (!context.registerCustomDialog && store) {
        context.registerCustomDialog = (data: DialogData) =>
            store.app.dispatch.registerCustomDialog({ data });
    }

    if (!context.form.displayErrors) {
        context.form.displayErrors = displayErrors.bind(context);
    }
    if (!context.form.hideErrors) {
        context.form.hideErrors = hideErrors.bind(context);
    }
    if (!context.form.setupViewCallbacks) {
        context.form.setupViewCallbacks = setupViewCallbacks.bind(context);
    }

    if (!context.form.cleanViewCallbacks) {
        context.form.cleanViewCallbacks = cleanViewCallbacks.bind(context);
    }

    return context;
}

export default function createRawAppContext(
    props: any,
    store: Observable,
    urlData: { url: string; path: string; queryParams: any; urlParams: any },
    forceUpdateForm: () => void,
    baseContext: RawAppContext | undefined,
    reload: () => void
) {
    return updateRawAppContext(
        (baseContext ?? { version: 0 }) as RawAppContext,
        {
            store,
            urlData,
            forceUpdateForm,
            formData: { ...props, content: cloneDeep(props.content) },
            reload,
        },
        { createViews: !baseContext || !baseContext.form.views }
    );
}
