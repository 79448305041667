import { CustomSiteConfig } from '../../../core/config-builder/types';
import campaigns from './campaigns';

const office: CustomSiteConfig = {
    title: 'סמירנוף',
    endpoints: {
        campaigns,
    },
};

export default office;
