import Form from '../../../core/components/form';
import ItemForm from '../../../core/components/item-form';
import ListForm from '../../../core/components/list-form';
import { PageConfig } from '../../../core/types';
import userItemConfig from './users/item';
import userListConfig from './users/list';

const pages: PageConfig[] = [
    {
        id: 'sidebar-group-user',
        type: 'group',
        showInSidebar: true,
        access: 'private',
        title: 'Users',
        exactPath: true,
        path: '/:endpointId(user)/',
        sidebarMatchExact: false,
        sidebarMatch: '/user/',
        sidebarTo: '/user/common/list',
        component: Form,
        isAllowed: ['admin', /[a-zA-Z0-9]+Admin$/],
        children: [
            {
                type: 'group',
                id: 'user-system-group',
                access: 'private',
                showInSidebar: true,
                title: 'system users',
                exactPath: false,
                path: '/:endpointId(user)/:usersSection(system)/',
                sidebarMatchExact: false,
                sidebarMatch: '/user/system',
                sidebarTo: '/user/system/list',

                isAllowed: ['admin', /[a-zA-Z0-9]+Admin$/],
                rootPage: null,
                children: [
                    {
                        id: 'user-system-list',
                        type: 'page',
                        access: 'private',
                        exactPath: true,
                        path: '/:endpointId(user)/:usersSection(system)/list',
                        component: ListForm,
                        showInSidebar: true,
                        props: {
                            ...userListConfig,
                            params: {
                                isAdminForm: true,
                            },
                        },
                    },
                    {
                        id: 'user-system-item-new',
                        type: 'page',
                        access: 'private',
                        exactPath: true,
                        path: '/:endpointId(user)/:usersSection(system)/item',
                        component: ItemForm,
                        props: {
                            ...userItemConfig,
                            params: {
                                isAdminForm: true,
                            },
                        },
                    },
                    {
                        id: 'user-system-item-exist',
                        type: 'page',
                        access: 'private',
                        exactPath: true,
                        path: '/:endpointId(user)/:usersSection(system)/item/:objectId/:tabId',
                        component: ItemForm,
                        props: {
                            ...userItemConfig,
                            params: {
                                isAdminForm: true,
                            },
                        },
                    },
                ],
            },
            {
                type: 'group',
                id: 'user-common-group',
                access: 'private',
                showInSidebar: true,
                title: 'common users',
                exactPath: false,
                path: '/:endpointId(user)/:usersSection(common)/',
                sidebarMatchExact: false,
                sidebarMatch: '/user/common',
                sidebarTo: '/user/common/list',
                rootPage: null,
                children: [
                    {
                        id: 'user-common-list',
                        type: 'page',
                        access: 'private',
                        exactPath: true,
                        path: '/:endpointId(user)/:usersSection(common)/list',
                        component: ListForm,
                        props: {
                            ...userListConfig,
                            params: {
                                isAdminForm: false,
                            },
                        },
                    },
                    {
                        id: 'user-common-item-exist',
                        type: 'page',
                        access: 'private',
                        exactPath: true,
                        path: '/:endpointId(user)/:usersSection(common)/item/:objectId/:tabId',
                        component: ItemForm,
                        props: {
                            ...userItemConfig,
                            params: {
                                isAdminForm: false,
                            },
                        },
                    },
                    {
                        id: 'user-common-item-new',
                        type: 'page',
                        access: 'private',
                        exactPath: true,
                        path: '/:endpointId(user)/:usersSection(common)/item',
                        component: ItemForm,
                        props: {
                            ...userItemConfig,
                            params: {
                                isAdminForm: false,
                            },
                        },
                    },
                ],
            },
        ],
    },
];

export default pages;
