import { AppContext } from 'core/components/form/context/types';
import Dashboard from '../../components/dashboard';
import ViewTypes from '../../components/form/ViewTypes';
import InsertsChart from './endpoint-inserts-chart';
import handlers from './handlers';

export function getDashboard(
    siteId: string,
    siteTitle: string,
    customDashboardConfig: any
) {
    const defaultContent = {
        id: 'rootGroup',
        type: ViewTypes.VStack,
        style: { alignItems: 'center', justifyContent: 'center' },
        items: [
            {
                id: 'chart',
                type: ViewTypes.CustomComponent,
                component: InsertsChart,
                siteId,
            },
        ],
    };

    return {
        ...(customDashboardConfig || {}),
        component: Dashboard,
        title:
            (customDashboardConfig && customDashboardConfig.title) ||
            `${siteTitle} Dashboard`,
        params: {
            ...((customDashboardConfig && customDashboardConfig.params) || {}),
            siteId,
        },
        commands: [
            {
                id: 'openConfig',
                text: 'Edit Site Config',
                onClick: 'onOpenSiteConfigEditor',
                isHidden: function (this: AppContext) {
                    const { currentUserData } = this;

                    const roles = ['admin', /[a-zA-Z0-9]+Admin$/];

                    if (!currentUserData || !currentUserData.roles) {
                        return true;
                    }

                    return !currentUserData.roles.some((role: string) =>
                        roles.some((expression: string | RegExp) => {
                            if (expression instanceof RegExp) {
                                return role.match(expression);
                            }

                            return role === expression;
                        })
                    );
                },
            },
            {
                id: 'openApiDocumentation',
                text: 'Site API documentation',
                onClick: 'openSiteApiDocumentation',
            },
            {
                id: 'openApiDocumentation',
                text: 'Minisite API documentation',
                onClick: 'openMiniSiteApiDocumentation',
            },
            {
                id: 'openJavascriptSDK',
                text: 'Javascript SDK',
                onClick: 'openJavascriptSDK',
            },
            ...((customDashboardConfig && customDashboardConfig.commands) ||
                []),
        ],
        content:
            (customDashboardConfig && customDashboardConfig.content) ||
            defaultContent,
        handlers() {
            return {
                ...handlers.call(this),
                ...((customDashboardConfig &&
                    customDashboardConfig.handlers &&
                    customDashboardConfig.handlers.call(this)) ||
                    {}),
            };
        },
    };
}
