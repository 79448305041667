import React from 'react';
import propsWithDefaultValues from '../../../utils/propsWithDefaultValues';
import Table from '../../native/table';
import useMemoryTableState from './hooks';
import PropsMemoryTable, { defaultProps } from './props';

const MemoryTable = (props: PropsMemoryTable) => {
    props = propsWithDefaultValues(props, defaultProps);
    const { id } = props;
    const {
        columns,
        items,
        isHidden,
        page,
        onChangePage,
        sort,
        onChangeSort,
        selection,
        onChangeSelection,
        rowStyle,
        rowClassName,
        onClickItem,
        onMapItems,
    } = useMemoryTableState(props);

    const { pageSize, isSelectable } = props;

    if (isHidden) {
        return null;
    }

    return (
        <Table
            id={id}
            isSelectable={isSelectable}
            columns={columns}
            pageSize={pageSize!}
            selection={selection}
            page={page}
            onClickRow={onClickItem!}
            onChangeSelection={onChangeSelection}
            onChangePage={onChangePage}
            items={items}
            sort={sort}
            onChangeSort={onChangeSort}
            rowStyle={rowStyle}
            rowClassName={rowClassName}
            onMapItems={onMapItems!}
        />
    );
};

export default MemoryTable;
