import { CustomEndpointConfig } from '../../../../core/config-builder/types';

const article: CustomEndpointConfig = {
    title: 'מאמרים',
    metaInfo: {
        propertiesOrder: [
            'title',
            'subtitle',
            'author',
            'minutes',
            'photo',
            'active',
            'readMore',
            'createdAt',
        ],
        properties: {
            title: {
                name: 'כותרת',
            },
            author: {
                name: 'כותב המאמר',
            },
            subtitle: {
                name: 'תת כותרת',
            },
            minutes: {
                name: 'מס דקות קריאה',
            },
            active: {
                name: 'פעיל',
            },
            photo: {
                name: 'תמונה ראשית',
            },
            readMore: {
                name: 'כתובת קרא עוד',
            },
            createdAt: {
                name: 'תאריך יצירה',
            },
        },
    },
    forms: {
        list: {
            // handlers,
            // content of the list form
            defaultUrlQueryParams: {
                sort: [
                    {
                        columnId: 'createdAt',
                        direction: 'desc',
                    },
                ],
            },
            columnsOrder: [
                'title',
                'subtitle',
                'author',
                'minutes',
                'photo',
                'readMore',
                'active',
                'createdAt',
            ],
            columns: {
                title: {
                    title: 'כותרת',
                },
                author: {
                    title: 'כותב המאמר',
                },
                subtitle: {
                    title: 'תת כותרת',
                },
                minutes: {
                    title: 'מס דקות קריאה',
                },
                active: {
                    title: 'פעיל',
                },
                readMore: {
                    title: 'כתובת קרא עוד',
                },
                photo: {
                    title: 'תמונה ראשית',
                },
                createdAt: {
                    title: 'תאריך יצירה',
                },
            },
        },
    },
};

export default article;
