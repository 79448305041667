import { useEffect, useMemo, useState } from 'react';
import { getRepresentationForItem } from '../../../utils';
import PropsReferenceInput, { defaultProps, FieldName } from './props';

async function _getValueItem(
    siteId: string | undefined,
    endpointId: string,
    itemId: string,
    idField: FieldName,
    searchByField: FieldName | FieldName[] | undefined,
    getItemsFromTable: Function,
    representationDataSource: any
): Promise<{ value: string; label: string; itemData: any }> {
    let data;

    try {
        const { items } = await getItemsFromTable(
            siteId,
            endpointId,
            {
                direction: 'asc',
                field: idField,
            },
            [
                {
                    field: idField,
                    includeAll: [itemId],
                },
            ],
            0,
            1
        );

        if (items.length === 0) {
            throw new Error('404');
        }

        data = items[0];
    } catch (e) {
        if (e.message === '404') {
            return {
                value: itemId,
                label: 'NOT FOUND',
                itemData: null,
            };
        }
    }

    return {
        value: itemId,
        label: getRepresentationForItem(data, representationDataSource),
        itemData: data,
    };
}

async function _getValueData(
    siteId: string | undefined,
    endpointId: string,
    value: any,
    idField: FieldName,
    searchByField: FieldName | FieldName[] | undefined,
    getItemsFromTable: Function,
    representationDataSource: FieldName | FieldName[] | Function
): Promise<any | any[]> {
    if (!Array.isArray(value)) {
        return _getValueItem(
            siteId,
            endpointId,
            value,
            idField,
            searchByField,
            getItemsFromTable,
            representationDataSource
        );
    }

    const items = [];
    for (let i = 0; i < value.length; i++) {
        const item = await _getValueItem(
            siteId,
            endpointId,
            value[i],
            idField,
            searchByField,
            getItemsFromTable,
            representationDataSource
        );
        items.push(item);
    }

    return items;
}

interface Option {
    value: string;
    label: string;
    itemData: any | undefined;
}

const useComponentState = (props: PropsReferenceInput) => {
    const {
        siteId,
        endpointId,
        value,
        sortListByField,
        representationDataSource,
        getItemsFromTable = defaultProps.getItemsFromTable,
        sortListDirection = defaultProps.sortListDirection,
        searchByField,
        idField = defaultProps.idField,
        rawSearchFilter,
    } = props;
    const [selectedOption, setSelectedOption] = useState<Option>();

    const onChange = (selectedOption: any) => {
        setSelectedOption(selectedOption);

        let value: string[] | string | undefined = undefined;
        if (selectedOption) {
            if (Array.isArray(selectedOption)) {
                value = selectedOption.map((item) => item.value);
            } else {
                value = selectedOption.value;
            }
        }

        props.onChange(value as any);
    };

    useEffect(() => {
        if (!value) {
            setSelectedOption(undefined);
            return;
        }

        _getValueData(
            siteId,
            endpointId,
            value,
            idField,
            searchByField,
            getItemsFromTable,
            representationDataSource
        ).then((optionData: any) => {
            setSelectedOption(optionData);
        });
    }, [
        value,
        siteId,
        endpointId,
        representationDataSource,
        idField,
        searchByField,
        getItemsFromTable,
    ]);

    const sortField = useMemo(() => {
        if (sortListByField) {
            return sortListByField;
        }

        const isString =
            typeof representationDataSource === 'string' ||
            representationDataSource instanceof String;
        if (isString) {
            return representationDataSource;
        }

        return '_id';
    }, [sortListByField, representationDataSource]);

    const onLoadOptions = (inputValue: any, callback: Function) => {
        let filter: any[] = [];
        let searchPhrase = '';

        if (searchByField && inputValue) {
            if (Array.isArray(searchByField)) {
                filter = [
                    searchByField
                        .map((fieldId) => `${fieldId}:*${inputValue.trim()}*`)
                        .join(' OR '),
                ];
            } else {
                filter = [`${searchByField}:*${inputValue.trim()}*`];
            }
        } else if (
            typeof representationDataSource === 'string' &&
            inputValue &&
            inputValue.trim()
        ) {
            filter = [`${representationDataSource}:*${inputValue.trim()}*`];
        } else if (!searchByField && inputValue && inputValue.trim()) {
            searchPhrase = inputValue;
            filter = [];
        }

        if (rawSearchFilter) {
            if (filter.length > 0) {
                filter.push(' AND ');
            }
            filter.push(rawSearchFilter);
        }

        getItemsFromTable(
            siteId,
            endpointId,
            [
                {
                    field: sortField,
                    direction: sortListDirection,
                },
            ],
            filter,
            0,
            50,
            searchPhrase,
            rawSearchFilter
        ).then(({ items, total }: { items: any[]; total: number }) => {
            callback(
                items.map((item) => ({
                    value: idField === '_id' ? item.id : item[idField],
                    label: getRepresentationForItem(
                        item,
                        representationDataSource
                    ),
                }))
            );
        });
    };

    const onOpenItem = () => {
        if (!props.onOpenItem || !selectedOption) {
            return;
        }

        props.onOpenItem(selectedOption.itemData);
    };

    return {
        sortField,
        selectedOption,
        onChange,
        onLoadOptions,
        onOpenItem,
    };
};

export default useComponentState;
