import React, { useCallback } from 'react';
import { getHandlerWithContext, getValue } from '../../../utils/context';
import Input from '../../native/input';
import { useAppContextObserver } from '../context';
import PropsNumberInput from './props';

const NumberInput = (props: PropsNumberInput) => {
    const { dataSource, id, label, placeholder, onChangeValue } = props;
    const [{ value, isHidden, isDisabled }, getContext] = useAppContextObserver(
        (context) => {
            let { value } = props;
            if (dataSource) {
                value = context.form.getDataSourceValue(dataSource)!;
            }

            return {
                value,
                isHidden: getValue(props.isHidden, context),
                isDisabled: getValue(props.isDisabled, context),
            };
        }
    );

    const handleChangeValue = useCallback(
        (e: any) => {
            const context = getContext();
            const value = Number(e.target.value);

            if (dataSource) {
                context.form.setDataSourceValue(dataSource, value);
            }

            if (!onChangeValue) {
                return;
            }

            const handler = getHandlerWithContext(onChangeValue, context);
            handler!(value);
        },
        [getContext, dataSource, onChangeValue]
    );

    if (isHidden) {
        return null;
    }

    return (
        <Input
            type="number"
            id={id}
            label={label}
            disabled={isDisabled}
            placeholder={placeholder}
            value={value}
            ltr
            onChange={handleChangeValue}
        />
    );
};

export default React.memo(NumberInput);
