import moment from 'moment';
import {
    AppContext,
    FormFunctionGetHandlers,
    FormHandlers,
} from '../../../../../core/components/form/context/types';
import apiClient from '../../../../../core/requests/api';

const convertDateFilterToQuery = (
    field: string,
    { startDate, endDate }: any
): string => {
    if (!startDate && !endDate) {
        return '';
    }

    const startMoment = moment(startDate, 'YYYY-MM-DDTHH:mm:ss');
    const endMoment = moment(endDate, 'YYYY-MM-DDTHH:mm:ss');

    const dateQueryFormat = 'YYYY-MM-DDTHH:mm:ss';

    if (startDate && endDate) {
        return `${field}:[${startMoment.format(
            dateQueryFormat
        )} TO ${endMoment.format(dateQueryFormat)}]`;
    }

    if (startDate) {
        return `${field}:[${startMoment.format(dateQueryFormat)} TO *]`;
    }

    return `${field}:[* TO ${endMoment.format(dateQueryFormat)}]`;
};

const getNumberOfSwipes = async (startDate?: any, endDate?: any) => {
    const q = convertDateFilterToQuery('answerCreatedAt', {
        startDate,
        endDate,
    });
    const queryParams = `size=0&q=${q}`;
    const response = await apiClient.callSearchRequest(
        'cbc',
        'yesnoanswer',
        queryParams
    );

    return response.hits.total;
};

const getNumberOfSentQuestions = async (startDate?: any, endDate?: any) => {
    const q = convertDateFilterToQuery('questionCreatedAt', {
        startDate,
        endDate,
    });
    const queryParams = `size=0&q=${q}`;
    const response = await apiClient.callSearchRequest(
        'cbc',
        'yesnoquestion',
        queryParams
    );

    return response.hits.total;
};

const getNumberOfUsers = async (startDate?: any, endDate?: any) => {
    const aggs = {
        aggs: {
            active_users_count: {
                cardinality: {
                    field: 'yesnoAnswerUserId',
                    precision_threshold: 1,
                },
            },
        },
    };

    const q = convertDateFilterToQuery('answerCreatedAt', {
        startDate,
        endDate,
    });
    const queryParams = `size=0&aggs=${JSON.stringify(aggs)}&q=${q}`;
    const response = await apiClient.callSearchRequest(
        'cbc',
        'yesnoanswer',
        queryParams
    );

    return response.aggregations.active_users_count.value;
};

const handlers: FormFunctionGetHandlers = function (
    this: AppContext
): FormHandlers {
    const loadTotalData = async () => {
        this.form.totalSwipes = '';
        const totalSwipes = await getNumberOfSwipes();
        this.form.totalSwipes = totalSwipes;

        this.form.totalPlayers = '';
        const totalPlayers = await getNumberOfUsers();
        this.form.totalPlayers = totalPlayers;

        this.form.totalQuestions = '';
        const totalQuestions = await getNumberOfSentQuestions();
        this.form.totalQuestions = totalQuestions;

        this.form.totalSwipesPerPlayer = Number(
            totalPlayers > 0 ? totalSwipes / totalPlayers : 0
        ).toFixed(2);
    };

    const loadFilteredData = async () => {
        const { startDate, endDate } = this.form;

        this.form.swipes = '';
        const swipes = await getNumberOfSwipes(startDate, endDate);
        this.form.swipes = swipes;

        this.form.activePlayers = '';
        const activePlayers = await getNumberOfUsers(startDate, endDate);
        this.form.activePlayers = activePlayers;

        this.form.questions = '';
        const questions = await getNumberOfSentQuestions(startDate, endDate);
        this.form.questions = questions;

        this.form.swipesPerPlayer = Number(
            activePlayers > 0 ? swipes / activePlayers : 0
        ).toFixed(2);
    };

    const loadData = async () => {
        loadTotalData();
        loadFilteredData();
    };

    const onBeforeOpen = async () => {
        this.form.endDate = moment().format('YYYY-MM-DDTHH:mm:ss');
        this.form.startDate = moment()
            .subtract(30, 'days')
            .format('YYYY-MM-DDTHH:mm:ss');

        loadData();
    };

    const onChangeStartDate = (newStartDate: string) => {
        this.form.startDate = newStartDate;
        loadData();
    };

    const onChangeEndDate = async (newEndDate: string) => {
        this.form.endDate = newEndDate;
        await loadData();
    };

    return {
        onBeforeOpen,
        onChangeStartDate,
        onChangeEndDate,
    };
};

export default handlers;
