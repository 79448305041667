import React from 'react';
import Form from '../form';
import PropsListForm from './props';

const ListForm = (props: PropsListForm) => {
    const { id, content, handlers, ...restBaseFormProps } = props;
    return (
        <Form
            {...restBaseFormProps}
            id={id}
            key={id}
            content={content}
            handlers={handlers}
        />
    );
};

export default React.memo(ListForm);
