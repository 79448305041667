export interface CacheDataWithKeys {
    [key: string]: any;
}

class CacheStorage {
    static EXPIRE_TIME_MS = 5 * 60 * 1000;

    constructor() {
        this.cleanData();
        setInterval(this.cleanData, 60 * 1000);
    }

    private cleanData = (): void => {
        const cacheData = this.getCacheData();

        const keys = Object.keys(cacheData);
        for (let i = 0; i < keys.length; i += 1) {
            const key = keys[i];
            const keyData = cacheData[key];

            if (keyData === undefined) {
                continue;
            }

            if (Date.now() - keyData.time > CacheStorage.EXPIRE_TIME_MS) {
                delete cacheData[keyData];
            }
        }

        this.setCacheData(cacheData);
    };

    private getCacheData = (): any => {
        const cacheData = sessionStorage.getItem('_HATASH_CACHE') || '{}';
        return JSON.parse(cacheData);
    };

    private setCacheData = (data: any): void => {
        sessionStorage.setItem('_HATASH_CACHE', JSON.stringify(data));
    };

    clear = (): void => {
        sessionStorage.removeItem('_HATASH_CACHE');
    };

    put = (key: string, data: any): void => {
        const cacheData = this.getCacheData();

        const now = new Date();
        now.setMinutes(now.getMinutes() + 5);

        cacheData[key] = {
            time: now,
            data,
        };

        this.setCacheData(cacheData);
    };

    putMultiple = (dataWithKeys: CacheDataWithKeys): void => {
        const cacheData = this.getCacheData();

        const now = new Date();
        now.setMinutes(now.getMinutes() + 5);

        Object.getOwnPropertyNames(dataWithKeys).forEach((key) => {
            cacheData[key] = {
                time: now,
                data: dataWithKeys[key],
            };
        });

        this.setCacheData(cacheData);
    };

    get = (key: string): any => {
        const cacheData = this.getCacheData();
        const itemData = cacheData[key];

        if (itemData && +new Date(itemData.time) <= +new Date()) {
            return null;
        }

        return itemData && itemData.data;
    };

    invalidateKeysStartWith = (templates: string): void => {
        const cacheData = this.getCacheData();

        const keys = Object.keys(cacheData);
        for (let keyIndex = 0; keyIndex < keys.length; keyIndex += 1) {
            const key = keys[keyIndex];

            for (
                let templateIndex = 0;
                templateIndex < templates.length;
                templateIndex += 1
            ) {
                const template = templates[templateIndex];

                if (key.startsWith(template)) {
                    delete cacheData[key];
                    break;
                }
            }
        }

        this.setCacheData(cacheData);
    };
}

export default CacheStorage;
