import Dialog from '../../../components/dialog';
import { DialogData } from '../../../components/form/context/types';
import ViewTypes from '../../../components/form/ViewTypes';
import handlers from './handlers';

const exportDialog: DialogData = {
    id: 'export',
    component: Dialog,
    handlers,
    content: {
        id: 'rootGroup',
        type: ViewTypes.VStack,
        items: [
            {
                id: 'title',
                type: ViewTypes.Text,
                style: {
                    fontSize: 30,
                    fontWeight: 'bold',
                    textAlign: 'right',
                    marginBottom: 15,
                },
                value: 'Export',
            },
            {
                id: 'fieldsGroup',
                type: ViewTypes.VStack,
                style: { overflow: 'auto', flex: 1, marginBottom: 15 },
                items: [],
            },
            {
                id: 'footer',
                type: ViewTypes.HStack,
                items: [
                    {
                        id: 'applyButton',
                        type: ViewTypes.Button,
                        text: 'Apply',
                        onClick: 'onApply',
                        style: { marginLeft: 15 },
                    },
                    {
                        id: 'cancelButton',
                        type: ViewTypes.Button,
                        role: 'notActive',
                        text: 'Cancel',
                        onClick: 'onClose',
                    },
                ],
            },
        ],
    },
};

export default exportDialog;
