import { useEffect, useMemo } from 'react';
import { convertToQueryFilter } from './filter';
import { ColumnsFilter } from './header/props';
import { FilterItem, SortItem, Statistic } from './props';
import { Column, ColumnReference } from './types';

const addDefaultPropertiesToColumn = (column: Column): Column => {
    if (!['reference', 'referenceArray'].includes(column.type)) {
        return column;
    }

    return {
        ...column,
        idField: !(column as ColumnReference).idField
            ? '_id'
            : (column as ColumnReference).idField,
    } as ColumnReference;
};

export const useColumnsWithDefaultProperties = (
    columns: Column[]
): Column[] => {
    return useMemo(
        () =>
            columns
                .map(addDefaultPropertiesToColumn)
                .filter((item) => !item.isHidden),
        [columns]
    );
};

export const getNumberOfPages = (total: number, pageSize: number): number => {
    return Math.max(1, Math.ceil(total / pageSize));
};

export const useNumberOfPages = (
    isLocalItems: boolean,
    pageSize: number,
    total?: number,
    items?: any[]
): number => {
    if (!isLocalItems) {
        return getNumberOfPages(total!, pageSize);
    }

    return getNumberOfPages(items!.length, pageSize);
};

export const sortItems = (
    items: any[],
    columns: Column[],
    sort: SortItem[]
): any[] => {
    items = items.slice();

    return items.sort((row1, row2) => {
        for (
            let sortColumnIndex = 0;
            sortColumnIndex < sort.length;
            sortColumnIndex++
        ) {
            const { columnId, direction } = sort[sortColumnIndex];

            const column = columns.find((item) => item.id === columnId);
            if (!column) {
                throw new Error(`Wrong sort object for colum: ${columnId}`);
            }

            const value1 = row1[column.fieldName];
            const value2 = row2[column.fieldName];

            let compareResult = 0;
            switch (column.type) {
                case 'text':
                    compareResult =
                        value1 < value2 ? -1 : value1 > value2 ? 1 : 0;
                    break;
                default:
                    break;
            }

            if (compareResult !== 0) {
                return direction === 'asc' ? compareResult : -compareResult;
            }
        }

        return 0;
    });
};

export interface ComputedStatistic {
    value: any;
    title: string;
    color: string;
}

export const computeStatistics = (
    aggregations: any,
    statistics: Statistic[]
): ComputedStatistic[] | undefined => {
    if (!aggregations) {
        return;
    }

    return statistics.map(({ title, color, getValue }: Statistic) => ({
        value: getValue(aggregations),
        title,
        color,
    }));
};

export function useEscapeListener(setQuickEditCell: (cell: any) => void) {
    useEffect(() => {
        const onPressEscapeKey = (e: any) => {
            if (e.key === 'Escape') {
                setQuickEditCell(null);
            }
        };

        window.addEventListener('keydown', onPressEscapeKey);
        return () => {
            window.removeEventListener('keydown', onPressEscapeKey);
        };
    }, [setQuickEditCell]);
}

export const mergeFilters = (
    columns: Column[],
    externalFilter: FilterItem[],
    internalFilter: ColumnsFilter
): FilterItem[] => {
    const result = convertToQueryFilter(columns, internalFilter);

    if (!externalFilter || externalFilter.length === 0) {
        return result;
    }

    if (result.length > 0) {
        result.push('AND');
    }

    return [...result, ...externalFilter];
};

export const getNewSort = (
    sort: SortItem[],
    columnId: string,
    direction: 'asc' | 'desc',
    shiftKey: boolean
) => {
    let newSort: SortItem[] = [];
    if (shiftKey) {
        newSort = sort.slice();
        if (newSort.find((item) => item.columnId === columnId)) {
            newSort = newSort.map((item) => {
                if (item.columnId !== columnId) {
                    return item;
                }

                return {
                    columnId: item.columnId,
                    direction,
                };
            });
        } else {
            newSort.push({
                columnId,
                direction,
            });
        }
    } else {
        newSort.push({
            columnId,
            direction,
        });
    }

    return newSort;
};

export const deletePredefinedTableItemFields = (item: any): any => {
    const result = {
        ...item,
    };
    delete result.files;
    delete result.user;
    delete result.id;
    return result;
};
