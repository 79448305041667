import React from 'react';
import { ContextMenu as ReactContextMenu } from 'react-contextmenu';
import Popup from '../popup';
import MenuItem from './menu-item';
import PropsContextMenu, { ContextMenuItem } from './props';
import style from './style.module.scss';

const ContextMenu = ({ tableId, contextMenu }: PropsContextMenu) => {
    const handleClickAction =
        (menuItem: ContextMenuItem) => (e: any, data: any) => {
            const { item }: { item: any } = data;
            e.stopPropagation();
            e.preventDefault();

            menuItem.onClick(item);
        };

    return (
        <Popup id="tablecontextmenu" isOpened={true}>
            <ReactContextMenu id={tableId} className={style.contextmenu}>
                {contextMenu &&
                    contextMenu.map((menuItem, index) => {
                        return (
                            <MenuItem
                                key={index}
                                title={menuItem.title}
                                onClick={handleClickAction(menuItem)}
                            />
                        );
                    })}
            </ReactContextMenu>
        </Popup>
    );
};

export default React.memo(ContextMenu);
