import { CustomSiteConfig } from '../../../core/config-builder/types';
import lead from './lead';

const galitz: CustomSiteConfig = {
    title: 'גליץ',
    endpoints: {
        lead,
    },
};

export default galitz;
