import apiClient from '../../../requests/api';

import noPhoto from '../../../assets/no-photo.jpg';
import activeSortAsc from '../../../assets/icons/active-sort-arrow-asc.svg';
import activeSortDesc from '../../../assets/icons/active-sort-arrow-desc.svg';
import notActiveSortAsc from '../../../assets/icons/not-active-sort-arrow-asc.svg';
import notActiveSortDesc from '../../../assets/icons/not-active-sort-arrow-desc.svg';

export { default as Checkbox } from '../checkbox';
export { default as Selection } from '../../../utils/Selection';
export { getRepresentationForItem } from '../../../utils';
export { default as DateInput } from '../../../components/native/date-input';
export { default as Input } from '../input';
export { default as ReferenceInput } from '../../../components/native/reference-input';
export { default as EnumInput } from '../select-input';
export { default as Pager } from '../pager';
export { default as ContextMenu } from '../context-menu';
export { sleep } from '../../../utils';

export const searchItems = apiClient.getItemsFromTable;

export const updateItem = async (siteId, endpointId, itemId, values) => {
    await apiClient.updateTableItem(siteId, endpointId, itemId, values);
};

export const assets = {
    noPhoto,
    activeSortAsc,
    activeSortDesc,
    notActiveSortAsc,
    notActiveSortDesc,
};
