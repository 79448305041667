import React from 'react';
import Checkbox from '../../../../checkbox';
import PropsCellBoolean from './props';
import style from './style.module.scss';

const CellBoolean = (props: PropsCellBoolean) => {
    const { value, column, updateValue } = props;
    const { updateInPlace } = column;
    return (
        <div className={style.booleanCell}>
            <Checkbox
                isDisabled={!updateInPlace}
                state={value ? 'checked' : 'unchecked'}
                onToggle={(newValue: any) =>
                    updateValue(newValue === 'checked')
                }
            />
        </div>
    );
};

export default React.memo(CellBoolean);
