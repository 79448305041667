import { CustomSiteConfig } from '../../../core/config-builder/types';
import item from './item';
import owner from './owner';

const multifileupload: CustomSiteConfig = {
    title: 'לוח קפה טורקי',
    endpoints: {
        verify: { isAllowed: ['admin'] },
        authenticate: { isAllowed: ['admin'] },
        owner,
        item,
    },
};

export default multifileupload;
