const getHistoryKey = (
    siteId: string,
    endpointId: string,
    jobId: string
): string => {
    return `jobs_history.${siteId}.${endpointId}.${jobId}`;
};

export const getHistoryFromStore = (
    jobName: string,
    siteId: string,
    endpointId: string
) => {
    const key = getHistoryKey(siteId, endpointId, jobName);
    const stringValue = window.localStorage.getItem(key) || '[]';
    return JSON.parse(stringValue);
};

export const saveHistory = (
    jobName: string,
    siteId: string,
    endpointId: string,
    history: string
) => {
    const key = getHistoryKey(siteId, endpointId, jobName);
    window.localStorage.setItem(key, JSON.stringify(history));
};
