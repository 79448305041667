import noScroll from 'disable-scroll';
import { useEffect, useLayoutEffect, useState } from 'react';
import ReactDOM from 'react-dom';
import { usePrevious } from 'use-hooks';
import propsWithDefaultValues from '../../../utils/propsWithDefaultValues';
import PropsPopup, { defaultProps } from './props';

const lockList = new Set();

const _updateScrollLock = () => {
    const isNotScrollable = lockList.size > 0;
    if (isNotScrollable) {
        noScroll.on(document.scrollingElement, {
            disableKeys: false,
        });
    } else {
        noScroll.off();
    }
};

const Popup: React.FunctionComponent<PropsPopup> = (props) => {
    props = propsWithDefaultValues(props, defaultProps);
    const { isOpened, id, into, children } = props;
    const prevIsOpened = usePrevious(isOpened);
    const [root, setRoot] = useState<HTMLElement | null>(null);

    if (prevIsOpened !== isOpened) {
        if (isOpened) {
            lockList.add(id);
        } else {
            lockList.delete(id);
        }

        _updateScrollLock();
    }

    useEffect(() => {
        if (isOpened) {
            lockList.add(id);
        } else {
            lockList.delete(id);
        }

        _updateScrollLock();

        if (into === 'body') {
            setRoot(document.body);
        } else {
            setRoot(document.getElementById(into!));
        }

        return () => {
            lockList.delete(id);
            _updateScrollLock();
        };
    }, [id, into, isOpened]);

    useLayoutEffect(() => {
        if (into === 'body') {
            setRoot(document.body);
        } else {
            setRoot(document.getElementById(into!));
        }
    }, [isOpened, into]);

    if (!isOpened || !root) {
        return null;
    }

    return ReactDOM.createPortal(children, root);
};

export default Popup;
