import React from 'react';
import useNotificationsState from './hooks';
import Notification from './notification';
import PropsNotifications from './props';
import style from './style.module.scss';

const Notifications = React.memo((props: PropsNotifications) => {
    const { transitions, onCloseNotification, refMap } =
        useNotificationsState(props);

    return (
        <div className={style.notifications}>
            {transitions.map(({ key, item, props: { ...style } }) => (
                <Notification
                    {...item}
                    key={key}
                    style={style}
                    ref={(ref) => {
                        ref && refMap.set(item, ref);
                    }}
                    onClose={onCloseNotification((item as any).id)}
                />
            ))}
        </div>
    );
});

export default Notifications;
