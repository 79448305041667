import { CustomEndpointConfig } from '../../../../core/config-builder/types';
import { getUserName } from '../../commonFunctions';

const foodmedia: CustomEndpointConfig = {
    title: 'Food media',
    metaInfo: {
        properties: {
            createdAt: {
                name: 'Created At',
            },
            userId: {
                type: 'string',
                format: 'reference',
                siteId: 'cbc',
                endpointId: 'profile',
                idField: 'userId',
                sortListByField: 'firstname',
                searchByField: ['email', 'firstname', 'lastname', 'phone'],
                representationDataSource: getUserName,
            },
        },
    },
};

export default foodmedia;
