import Form from '../../../components/form';
import ViewTypes from '../../../components/form/ViewTypes';
import appConfig from './app-config';
import handlers from './handlers';
import jobsSchedule from './jobs-schedule';
import jobsScripts from './jobs-scripts';
import scripts from './scripts';
import settings from './settings';
import style from './style.module.scss';

export default {
    component: Form,
    className: style.dialog,
    handlers,
    fields: {},
    title: 'Endpoint Config Editor',
    commands: [
        {
            id: 'backButton',
            text: 'Back',
            onClick: 'onGoBack',
        },
    ],
    content: {
        id: 'rootGroup',
        type: ViewTypes.VStack,
        items: [
            {
                id: 'content',
                type: ViewTypes.VStack,
                items: [
                    {
                        id: 'tabs',
                        type: ViewTypes.TabsGroup,
                        activeTabIdSource: 'url.params.activeTabId.replace',
                        tabs: [
                            {
                                id: 'settings',
                                type: ViewTypes.TabPage,
                                title: 'Settings',
                                content: settings,
                            },
                            {
                                id: 'scripts',
                                type: ViewTypes.TabPage,
                                title: 'Scripts',
                                content: scripts,
                            },
                            {
                                id: 'jobsScripts',
                                type: ViewTypes.TabPage,
                                title: 'Jobs',
                                content: jobsScripts,
                            },
                            {
                                id: 'jobs-schedule',
                                type: ViewTypes.TabPage,
                                title: 'Queue',
                                content: jobsSchedule,
                            },
                            {
                                id: 'config',
                                type: ViewTypes.TabPage,
                                title: 'Config',
                                content: appConfig,
                            },
                        ],
                    },
                ],
            },
        ],
    },
};
