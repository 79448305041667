import style from './style.module.scss';

export interface Option {
    value: string;
    label: string;
}

export default interface PropsSelectInput {
    id?: string;
    label: string;
    isCreatable?: boolean;
    isDisabled?: boolean;
    isMultiple?: boolean;
    value: string | string[];
    options: Option[];
    onChange: (option: Option) => void;
    classContainer?: string;
    classLabel?: string;
    displayError?: boolean;
}

export const defaultProps: Partial<PropsSelectInput> = {
    classContainer: style.inputContainer,
    classLabel: style.label,
};
