import raw from 'raw.macro';
import { TemplateData } from '..';

const htmlTemplate = raw('./tempalte.hbs');

const lottieNewSplash: TemplateData = {
    id: 'lottieNewSplashCutBottom',
    name: 'מעברון חיתוך תחתות',
    htmlTemplate,
    defaultData: {
        background: 'black',
    },
    dataSchema: {
        fields: [
            {
                id: 'background',
                name: 'Background Color',
                type: 'string',
            },
        ],
        requiredFiles: ['/data.json'],
    },
    previewSize: {
        width: 320,
        height: 640,
    },
};

export default lottieNewSplash;
