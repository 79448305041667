import { AppContext } from '../../../../core/components/form/context/types';
import { CustomEndpointConfig } from '../../../../core/config-builder/types';

const endpointConfig: CustomEndpointConfig = {
    title: 'חודשים',
    metaInfo: {
        propertiesOrder: ['title', 'startDate', 'endDate'],
    },
    forms: {
        list: {
            // content of the list form
            columnsOrder: [
                'title',
                'startDate',
                'endDate',
                'openProductsButton',
            ],
            columns: {
                openProductsButton: {
                    id: 'openProductsButton',
                    type: 'button',
                    text: 'מוצרים',
                    onClick(this: AppContext, item: any) {
                        this.form.url.push(
                            `/caveret/product/list?filter.editionId.include[]=${item.id}`
                        );
                    },
                },
                openCoversButton: {
                    id: 'openCoversButton',
                    type: 'button',
                    text: 'קאברים',
                    onClick(this: AppContext, item: any) {
                        this.form.url.push(
                            `/caveret/cover/list?filter.editionId.include[]=${item.id}`
                        );
                    },
                },
                openSiteButton: {
                    id: 'openSiteButton',
                    type: 'button',
                    text: 'פתיחת אתר בחודש זה',
                    onClick(this: AppContext, item: any) {
                        window.open(
                            `https://miniapps.pazamapp.com/caveret/#/?editionId=${item.id}`
                        );
                    },
                },
            },
        },
    },
};

export default endpointConfig;
