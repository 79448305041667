import { useEffect, useLayoutEffect, useState } from 'react';
import { useGlobalStoreObserver } from '../../../../globalStore/definition';
import PropsLogingForm from './props';

const useLoginFormState = (props: PropsLogingForm) => {
    const [{ isProcessing, resetData, loginByEmail }] = useGlobalStoreObserver(
        (store) => {
            const isProcessing =
                'processing' === store.loginPage.state.loginState;
            const { resetData, loginByEmail } = store.loginPage.dispatch;

            return {
                isProcessing,
                resetData,
                loginByEmail,
            };
        }
    );

    const [errors, setErrors] = useState<any[]>([]);
    const [rememberMe, setRememberMe] = useState(true);
    const [login, setLogin] = useState('');
    const [password, setPassword] = useState('');

    useLayoutEffect(() => {
        resetData();
    }, [resetData]);

    useEffect(() => {
        setErrors(errors);
    }, [errors]);

    const onChangePassword = (e: any) => {
        setPassword(e.target.value);
    };

    const onChangeLogin = (e: any) => {
        setLogin(e.target.value);
    };

    const checkFields = ({ login, password }: any) => {
        login = login.trim();

        const errors = [];

        if (!login || !login.trim()) {
            errors.push({
                field: 'login',
                text: 'email is required',
            });
        }

        if (!password) {
            errors.push({
                field: 'password',
                text: 'Password is required',
            });
        } else if (password.length < 5) {
            errors.push({
                field: 'password',
                text: 'Password should be at least 5 characters',
            });
        }

        return errors;
    };

    const onLogin = () => {
        const errors = checkFields({ login, password });
        setErrors(errors);

        //FIXME: hack state doesn't work
        // this.forceUpdate();

        if (errors.length > 0) {
            return;
        }

        loginByEmail({ userName: login, password, rememberMe });
    };

    const onPasswordKeyPress = (e: any) => {
        if (e.key === 'Enter') {
            onLogin();
        }
    };

    const onClickRememberMe = () => {
        setRememberMe((prev) => !prev);
    };

    const emailError = errors.find((item) => item.field === 'login');
    const passwordError = errors.find((item) => item.field === 'password');

    return {
        isProcessing,
        login,
        password,
        rememberMe,
        emailError,
        passwordError,
        onLogin,
        onPasswordKeyPress,
        onClickRememberMe,
        onChangeLogin,
        onChangePassword,
    };
};

export default useLoginFormState;
