import content from './content';
import handlers from './handlers';

export default {
    id: 'landing-editor-tab',
    type: 'Form',
    fields: {
        isEditorInEditMode: false,
    },
    content,
    handlers,
};
