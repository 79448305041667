import { CustomSiteConfig } from '../../../core/config-builder/types';
import advertiser from './advertiser';
import application from './application';
import job from './job';

const jobsearch: CustomSiteConfig = {
    title: 'גובניק',
    endpoints: {
        job,
        verify: { title: 'לא לגעת', isAllowed: ['never'] }, //FIXME: Hack
        advertiser,
        application,
    },
};

export default jobsearch;
