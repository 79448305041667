import lodash from 'lodash';
import { FilterItem, SortItem } from '../../../requests/api';
import { FieldName, TableItemId } from '../reference-input/props';
import {
    Column,
    ColumnBase,
    ColumnReference,
    ColumnReferenceArray,
    PageReferenceValue,
} from './types';

export interface ReferenceValue {
    idField: FieldName;
    reference: TableItemId | TableItemId[] | undefined;
    siteId: string | null;
    endpointId: string;
}

function extractArrayReferences(
    references: ReferenceValue[],
    item: any,
    column: ColumnReference | ColumnReferenceArray
) {
    const { endpointId } = column;
    const siteId = column.siteId || null;

    let fieldReferences: TableItemId | TableItemId[] = item[column.fieldName];
    const idField = column.idField ?? '_id';

    if (!fieldReferences) {
        return;
    }

    if (typeof fieldReferences === 'string') {
        fieldReferences = [fieldReferences];
    }

    fieldReferences.forEach((reference) => {
        const isAlreadyExist = references.find(
            (item) =>
                item.reference === reference &&
                item.idField === idField &&
                item.siteId === siteId &&
                item.endpointId === endpointId
        );

        if (isAlreadyExist) {
            return;
        }

        references.push({
            idField,
            reference,
            siteId,
            endpointId,
        });
    });
}

function _getReference(
    item: any,
    column: ColumnReference | ColumnReferenceArray
): TableItemId | TableItemId[] | undefined {
    return lodash.get(item, column.fieldName);
}

const extractReference = (
    references: ReferenceValue[],
    item: any,
    column: ColumnReference | ColumnReferenceArray
) => {
    const { endpointId } = column;
    const siteId = column.siteId || null;

    const getReference = column.getReference || _getReference;
    const reference = getReference(item, column);
    const idField = column.idField ?? '_id';

    if (!reference) {
        return;
    }

    const isAlreadyExist = references.find(
        (item) =>
            item.reference === reference &&
            item.siteId === siteId &&
            item.endpointId === endpointId &&
            item.idField === idField
    );

    if (isAlreadyExist) {
        return;
    }

    references.push({
        siteId,
        endpointId,
        idField,
        reference,
    });
};

interface ReferencesGroup {
    siteId: string | null;
    endpointId: string;
    idField: FieldName;
    items: any[];
}

const getReferencesToLoad = (items: any[], columns: Column[]) => {
    const referenceArrayColumns = columns.filter(
        (column: ColumnBase) => column.type === 'referenceArray'
    ) as ColumnReferenceArray[];

    const referenceColumns = columns.filter(
        (colum: ColumnBase) => colum.type === 'reference'
    ) as ColumnReference[];

    const references: ReferenceValue[] = [];

    items.forEach((item) => {
        referenceArrayColumns.forEach((column) =>
            extractArrayReferences(references, item, column)
        );
        referenceColumns.forEach((column) =>
            extractReference(references, item, column)
        );
    });

    const groupedReferences: ReferencesGroup[] = [];

    references.forEach((item) => {
        const { siteId, endpointId, idField, reference } = item;

        let group = groupedReferences.find(
            (item) =>
                item.siteId === siteId &&
                item.endpointId === endpointId &&
                item.idField === idField
        );

        if (!group) {
            group = {
                siteId,
                endpointId,
                idField,
                items: [],
            };
            groupedReferences.push(group);
        }

        if (group.items.includes(reference)) {
            return;
        }

        group.items.push(item);
    });

    return groupedReferences;
};

async function loadReferences(
    items: any[],
    columns: Column[],
    onSearchItems: (
        siteId: string | undefined,
        endpointId: string,
        sorted: SortItem[],
        filter: FilterItem[],
        from: number,
        limit: number,
        searchPhrase?: string,
        searchFilter?: FieldName[],
        queryParams?: string
    ) => { items: any[] }
): Promise<PageReferenceValue[]> {
    const result: PageReferenceValue[] = [];
    const groupedReferences = getReferencesToLoad(items, columns);

    for (
        let groupIndex = 0;
        groupIndex < groupedReferences.length;
        groupIndex++
    ) {
        const group = groupedReferences[groupIndex];
        const { siteId, endpointId, idField } = group;

        const referencesForLoad = group.items.map((item) => item.reference);

        try {
            const { items: loadedItems } = await onSearchItems(
                siteId ?? undefined,
                endpointId,
                [
                    {
                        direction: 'asc',
                        field: idField,
                    },
                ],
                [
                    {
                        field: idField,
                        include: referencesForLoad,
                    },
                ],
                0,
                referencesForLoad.length
            );

            loadedItems.forEach((item: any) => {
                const reference = item[idField === '_id' ? 'id' : idField];
                result.push({
                    siteId,
                    endpointId,
                    idField,
                    reference,
                    referenceValue: item,
                });
            });
        } catch (e) {
            console.log('Error', e);
        }
    }

    return result;
}

export default loadReferences;
