import React from 'react';
import PropsCellTags from './props';
import style from './style.module.scss';

const getTextWidth: any = require('text-width').default;

const getDisplayTags = (tags: string[], width: number) => {
    if (tags.length === 0) {
        return tags;
    }

    const displayTags = tags.slice();

    while (displayTags.length > 0) {
        const tagsText = displayTags.map((tag) => `#${tag}`).join(', ');
        const textWidth = getTextWidth(tagsText, {
            family: 'Aran',
            size: 14,
            weight: 500,
        });

        if (textWidth <= width) {
            return displayTags;
        }

        displayTags.pop();
    }

    return [tags[0]];
};

const CellTags = ({ item, value, column }: PropsCellTags) => {
    const tags = value;
    if (!tags || tags.length === 0) {
        return <div className={style.tagsCell} />;
    }

    const displayTags = getDisplayTags(tags, column.width || 100);

    return (
        <div className={style.tagsCell}>
            <div className={style.displayTags}>
                {displayTags.map((tag, index) => (
                    <span className={style.tag} key={index}>
                        <span className={style.hash}>#</span>
                        {tag}
                        {index !== tags.length - 1 && ','}
                    </span>
                ))}
            </div>
            {displayTags.length < tags.length && (
                <span>+{tags.length - displayTags.length}</span>
            )}
        </div>
    );
};

export default React.memo(CellTags);
