import { CustomSiteConfig } from '../../../core/config-builder/types';
import favorites from './favorites';
import location from './location';
import missinglocation from './missinglocation';
import report from './report';

const policeradar: CustomSiteConfig = {
    title: 'גלאי משטרה צבאית',
    endpoints: {
        favorites,
        location,
        report,
        missinglocation,
    },
};

export default policeradar;
