import { useEffect, useState } from 'react';
import { getHandlerWithContext, getValue } from '../../../utils';
import { useAppContextObserver } from '../context';
import PropsCodeEditor, { CodeEditorCommand } from './props';

const useCodeEditorState = (props: PropsCodeEditor) => {
    const { dataSource, onChange } = props;
    const [{ isHidden, isDisabled, value }, getContext] = useAppContextObserver(
        (c) => {
            let value = props.value;
            if (props.dataSource) {
                value = c.form.getDataSourceValue(props.dataSource);
            }

            return {
                isHidden: getValue(props.isHidden, c),
                isDisabled: getValue(props.isDisabled, c),
                value,
            };
        }
    );
    const [isExpanded, setExpanded] = useState(() => !!props.isExpanded);

    useEffect(() => {
        setExpanded(!!props.isExpanded);
    }, [props.isExpanded]);

    const onToggleExpand = (e: any) => {
        e.stopPropagation();

        if (props.onToggleFullScreen) {
            props.onToggleFullScreen();
            return;
        }

        setExpanded(!isExpanded);
    };

    const onChangeValue = (e: any, newValue: string | undefined) => {
        if (dataSource) {
            getContext().form.setDataSourceValue(dataSource, newValue ?? '');
        }

        if (!onChange) {
            return;
        }

        const handler = getHandlerWithContext(onChange, getContext());
        handler!(newValue ?? '');
    };

    const onClickCommand = (command: CodeEditorCommand) => (e: any) => {
        e.stopPropagation();
        const handler = getHandlerWithContext(command.onClick, getContext());
        handler!();
    };

    return {
        isHidden,
        isExpanded,
        isDisabled,
        onClickCommand,
        onToggleExpand,
        value,
        onChangeValue,
    };
};

export default useCodeEditorState;
