import { AppContext } from '../../../../core/components/form/context/types';
import { CustomEndpointConfig } from '../../../../core/config-builder/types';

const endpointConfig: CustomEndpointConfig = {
    title: 'איש קשר',
    metaInfo: {
        propertiesOrder: ['createdAt', 'assignedTo', 'token'],
    },
    forms: {
        list: {
            // content of the list form
            columnsOrder: [
                'createdAt',
                'assignedTo',
                'token',
                'openItemsButton',
            ],
            columns: {
                openItemsButton: {
                    id: 'openItemsButton',
                    type: 'button',
                    text: 'Items',
                    onClick(this: AppContext, item: any) {
                        this.form.url.push(
                            `/multifileupload/item/list?filter.ownerId.include[]=${item.id}`
                        );
                    },
                },
            },
        },
    },
};

export default endpointConfig;
